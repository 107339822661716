import { Injectable } from '@angular/core';
import { TransitionContext } from '../models/transition-context.model';


@Injectable()
export class TransitionsService {
    private readonly transitions = [
        'draft',
        'questions'
    ];

    public isEditableByProjectHolder(marking: string, transitionContexts: TransitionContext[]): boolean {
        const isInArray = this.transitions.includes(marking);
        if (!isInArray) {
            return false;
        }

        return transitionContexts.slice(-1)[0].newMarking === marking;
    }
}
