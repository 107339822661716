import { Component } from '@angular/core';
import { AdminProjectDatasource } from '../../../../core/datasources/admin-project.datasource';
import { FolderService } from '../../../../core/services/folder.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../../../core/services/loading.service';

@Component({
    selector: 'app-admin-dashboard-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent {
    public dataSource: AdminProjectDatasource;
    public displayedColumns = ['no', 'title', 'state', 'actions'];

    constructor(
        private folderService: FolderService,
        private router: Router,
        public loading: LoadingService
    ) {
        this.dataSource = new AdminProjectDatasource(this.folderService);
        this.dataSource.loadProjects(1, 5);
    }

    gotoProjects(): void {
        void this.router.navigateByUrl('/admin/projects');
    }
}
