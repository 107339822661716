import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { TenderSession } from '../../../../core/models/tender-session.model';
import { map } from 'rxjs/operators';
import { UserTenderSessionResource } from '../../../../core/models/user-tender-session-resource.model';
import { UserTenderSessionResourceService } from '../../../../core/services/user-tender-session-resource.service';
import { IriUtil } from '../../../../core/utils/iri.util';
import { Router } from '@angular/router';
import { COMPUTOR_TYPES } from '../../../../core/services/folder.service';

@Component({
    selector: 'app-dashboard-program',
    templateUrl: './program.component.html',
    styleUrls: ['./program.component.scss'],
})
export class ProgramComponent implements OnInit {

    public userTenderSessionResource$: Observable<TenderSession[]>;

    constructor(
        private userTenderSessionResourceService: UserTenderSessionResourceService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.userTenderSessionResource$ = this.userTenderSessionResourceService.findAll(COMPUTOR_TYPES.program).pipe(
            map((data: UserTenderSessionResource) => data['hydra:member'].sort((a, b) => (b.isCurrent ? 1 : 0) - (a.isCurrent ? 1 : 0)))
        );
    }

    gotoNewProgram(tenderSession: TenderSession): void {
        void this.router.navigateByUrl('/sessions/' + IriUtil.extractId(tenderSession['@id']) + '/programs/new');
    }
}
