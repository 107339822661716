<div class="media-groups">
    <ng-container *ngIf="form.get('mediaObjects').value.length !== 0; else noItem">
      <mat-list>
        <mat-list-item *ngFor="let mediaObject of form.get('mediaObjects').value; let i = index; last as last">
          <p matLine>{{ mediaObject.originalName }}</p>
          <button mat-icon-button (click)="downloadMediaObject(mediaObject)">
            <mat-icon>cloud_download</mat-icon>
          </button>

          <button mat-icon-button type="button" appConfirmDialog (confirmClick)="deleteMediaObject(mediaObject, i)">
            <mat-icon>delete_outline</mat-icon>
          </button>

          <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
        </mat-list-item>
      </mat-list>
    </ng-container>
</div>

<input #fileInput
       type="file"
       class="file-input hidden"
       multiple="multiple"
       (change)="onNewFileSelected($event)" />

<!--ROLE_USER only can add docs-->
<button mat-mini-fab color="primary" class="card-content-action" *appIsNotGranted="'ROLE_OFEN'; exceptions 'ROLE_STAFF_PKW'" (click)="addMediaObject()">
  <mat-icon>add</mat-icon>
</button>

<ng-template #noItem>
  <p>{{ 'message.no_document' | translate }}</p>
</ng-template>
