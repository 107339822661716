<app-card classes="p-5">
    <div class="text-center">
        <h1>{{ 'title.register' | translate }}</h1>
    </div>
    <form [formGroup]="form" class="w-100">
        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="standard">
                    <mat-label>{{ 'word.first_name' | translate }}</mat-label>
                    <input matInput formControlName="firstName" maxlength="200" #firstname>
                    <mat-hint align="end">{{ firstname.value.length }} / {{ 200 }}</mat-hint>

                    <mat-error *ngIf="form.get('firstName').hasError('required')">
                        {{ 'validator.field.not_blank' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('firstName').hasError('maxlength')">
                        {{ 'validator.field.too_long' | translate: '{ max: 200 }' }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="standard">
                    <mat-label>{{ 'word.last_name' | translate }}</mat-label>
                    <input matInput formControlName="lastName" maxlength="200" #lastname>
                    <mat-hint align="end">{{ lastname.value.length }} / {{ 200 }}</mat-hint>

                    <mat-error *ngIf="form.get('lastName').hasError('required')">
                        {{ 'validator.field.not_blank' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('lastName').hasError('maxlength')">
                        {{ 'validator.field.too_long' | translate: '{ max: 200 }' }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="standard">
                    <mat-label>{{ 'word.email' | translate }}</mat-label>
                    <input matInput formControlName="email" autocomplete="email" maxlength="200" #email>
                    <mat-hint align="end">{{ email.value.length }} / {{ 200 }}</mat-hint>

                    <mat-error *ngIf="form.get('email').hasError('email')">
                        {{ 'validator.email.not_valid' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('email').hasError('required')">
                        {{ 'validator.email.not_blank' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('email').hasError('maxlength')">
                        {{ 'validator.field.too_long' | translate: '{ max: 200 }' }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="standard">
                    <mat-label>{{ 'word.password' | translate }}</mat-label>
                    <input matInput [type]="this.hidePassword ? 'password' : 'text'" formControlName="password"
                           autocomplete="new-password" maxlength="200" minlength="6" #password>
                    <button (click)="this.hidePassword = !this.hidePassword" mat-icon-button matSuffix type="button">
                        <mat-icon>
                            {{ this.hidePassword ? 'visibility_off' : 'visibility' }}
                        </mat-icon>
                    </button>
                    <mat-hint align="end">{{ password.value.length }} / {{ 200 }}</mat-hint>

                    <mat-error *ngIf="form.get('password').hasError('required')">
                        {{ 'validator.password.not_blank' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('password').hasError('maxlength')">
                        {{ 'validator.field.too_long' | translate: '{ max: 200 }' }}
                    </mat-error>
                    <mat-error *ngIf="form.get('password').hasError('minlength')">
                        {{ 'validator.field.too_short' | translate: '{ min: 6 }' }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
    <mat-card-actions>
        <button [loading]="loading.loading$ | async" appMatButtonLoading mat-flat-button color="accent"
                class="mat-button mr-1" (click)="register()">{{ 'action.register' | translate }}</button>
        <button [disabled]="loading.loading$ | async" mat-stroked-button color="default" class="mat-button"
                (click)="login()">{{ 'action.i_have_account' | translate }}</button>
    </mat-card-actions>
</app-card>
