import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Step } from '../models/step.model';
import { FolderProjectResource } from '../models/folder-project-resource.model';
import { FolderProgramResource } from '../models/folder-program-resource.model';
import { ResourceUtil } from './resourceUtil';
import { ReportColumn } from '../models/report-column.model';
import { IriUtil } from './iri.util';
import { TenderSession } from '../models/tender-session.model';

export type AnyFolderResource = FolderProjectResource|FolderProgramResource;

@Injectable({
    providedIn: 'root'
})
export class AdminNavUtil {
    constructor(private router: Router) { }

    public showFolder(folder: AnyFolderResource, tenderSession: TenderSession): void {
        void this.router.navigate([
            'sessions',
            IriUtil.extractId(tenderSession['@id']),
            'folders',
            folder.data.id
        ]);
    }

    public showOrEditFolderStep(step: Step, folder: AnyFolderResource): void {
        const type = ResourceUtil.isProject(folder) ? 'projects' : 'programs';

        if (step.marking === 'validation_2') {
            void this.router.navigate([
                'admin',
                type,
                folder.data.id,
                'validation2',
                step.id,
            ]);

            return;
        }


        if (step['@permissions'].view && !step['@permissions'].edit) {
            void this.router.navigate([
                'admin',
                type,
                folder.data.id,
                'states',
                step.id,
            ]);
        }
    }


    public showOrEditFolderReport(column: ReportColumn, step: Step, folder: AnyFolderResource): void {
        const type = ResourceUtil.isProject(folder) ? 'projects' : 'programs';

        if (step['@permissions'].edit) {
            void this.router.navigate([
                'admin',
                type,
                folder.data.id,
                'reports',
                column['@id'],
                'validation2',
                step.id
            ]);

            return;
        }

        if (step['@permissions'].view && ('submitted' === step['@columnName'] || 'opened' === step['@columnName'])) {
            void this.router.navigate([
                'admin',
                type,
                folder.data.id,
                'reports',
                column['@id'],
                'state',
                step.id
            ]);

            return;
        }

        void this.router.navigate([
            'admin',
            type,
            folder.data.id,
            'reports',
            column['@id'],
            'validation2',
            step.id
        ]);
    }
}
