import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Person } from '../../core/models/person.model';
import { ArrayUtil } from '../../core/utils/array.util';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Paginator } from '../../core/models/paginator.model';
import { AuthorizationChecker } from '../../core/security/authorization-checker';
import { UserService } from '../../core/services/user.service';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-admin-managers-selection',
    templateUrl: './admin-managers-selection.component.html',
    styleUrls: ['./admin-managers-selection.component.scss']
})
export class AdminManagersSelectionComponent implements OnInit, OnDestroy {
    @Input()
    managersForm: FormGroup;

    // search controls for users selection AN = analysis / MO = monitoring
    public GS_AN_SEARCH = new FormControl();
    public GS_MO_SEARCH = new FormControl();
    public OF_AN_SEARCH = new FormControl();
    public OF_MO_SEARCH = new FormControl();
    // Full users array (not filtered)
    public GS_ANALYSIS_USERS: Person[] = [];
    public GS_MONITORING_USERS: Person[] = [];
    public OFEN_ANALYSIS_USERS: Person[] = [];
    public OFEN_MONITORING_USER: Person[] = [];
    // samed but prefixed with F : Filtered (the ones we show)
    public F_GS_ANALYSIS_USERS: Person[];
    public F_GS_MONITORING_USERS: Person[];
    public F_OFEN_ANALYSIS_USERS: Person[];
    public F_OFEN_MONITORING_USER: Person[];

    destroySubject = new Subject();

    constructor(
        private authorizationChecker: AuthorizationChecker,
        private userService: UserService
    ) {
    }

    ngOnInit(): void {
        this.getAllUsers();
        this.subscribeToSearch();

        if (!this.hasRoleStaffPkw()) {
            this.managersForm.disable();
        }
    }

    ngOnDestroy(): void {
        this.destroySubject.next(true);
        this.destroySubject.complete();
    }

    public hasRoleStaffPkw(): boolean {
        return this.authorizationChecker.checkIsGranted('ROLE_STAFF_PKW');
    }

    private searchForUsers(fullUsersArray: Person[], value: string): Person[] {
        if (!value || value === '') {
            return fullUsersArray;
        }
        return ArrayUtil.filterObjectArray(value, fullUsersArray, ['firstname', 'lastname']) as Person[];
    }

    private subscribeToSearch(): void {
        // subscribe to users search in selects
        this.GS_AN_SEARCH.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(value => {
            this.F_GS_ANALYSIS_USERS = this.searchForUsers(this.GS_ANALYSIS_USERS, value);
        });
        this.GS_MO_SEARCH.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(value => {
            this.F_GS_MONITORING_USERS = this.searchForUsers(this.GS_MONITORING_USERS, value);
        });
        this.OF_AN_SEARCH.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(value => {
            this.F_OFEN_ANALYSIS_USERS = this.searchForUsers(this.OFEN_ANALYSIS_USERS, value);
        });
        this.OF_MO_SEARCH.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(value => {
            this.F_OFEN_MONITORING_USER = this.searchForUsers(this.OFEN_MONITORING_USER, value);
        });
    }

    private getAllUsers(): void {
        // fetch users by roles from API
        this.userService.getUserListByRole(environment.gsAnalysisRole, 'false').subscribe((users: Paginator) => {
            this.GS_ANALYSIS_USERS = users['hydra:member'] as Person[];
            this.GS_MONITORING_USERS = this.GS_ANALYSIS_USERS;
            this.F_GS_ANALYSIS_USERS = this.GS_ANALYSIS_USERS;
            this.F_GS_MONITORING_USERS = this.GS_MONITORING_USERS;
        });
        this.userService.getUserListByRole(environment.ofenAnalysisRole, 'false').subscribe((users: Paginator) => {
            this.OFEN_ANALYSIS_USERS = users['hydra:member'] as Person[];
            this.OFEN_MONITORING_USER = this.OFEN_ANALYSIS_USERS;
            this.F_OFEN_ANALYSIS_USERS = this.OFEN_ANALYSIS_USERS;
            this.F_OFEN_MONITORING_USER = this.OFEN_MONITORING_USER;
        });
        this.userService.getUserListByRole(environment.corneliaRole, 'false').subscribe((users: Paginator) => {
            this.GS_ANALYSIS_USERS = [...this.GS_ANALYSIS_USERS, ...users['hydra:member'] as Person[]] as Person[];
            this.F_GS_ANALYSIS_USERS = this.GS_ANALYSIS_USERS;
        });
    }
}
