<div class="row" [formGroup]="form" style="min-width: 40vw;">
    <div class="col-md-12">
        <h1 class="mat-dialog-title">{{'action.add_new_remark' | translate}}</h1>
    </div>
    <div class="col-md-12">
        <mat-form-field>
            <mat-label>{{ 'word.description' | translate }}</mat-label>
            <textarea matInput formControlName="text" required></textarea>
        </mat-form-field>
    </div>
    <div class="col-md-12 d-flex justify-content-end">
        <button mat-flat-button mat-dialog-close class="cancel-button mr-2">{{ 'action.close' | translate }}</button>
        <button mat-flat-button (click)="addRemark()" color="primary" appMatButtonLoading [loading]="loading.loading$ | async">{{ 'action.save' | translate }}</button>
    </div>
</div>
