<app-admin-folder-milestone id="section1" [tenderSession]="folderProgramResource.data.tenderSession"
                            [folder]="folderProgramResource" [showingFolder]="true"></app-admin-folder-milestone>

<!-- Project holder -->
<app-card cardNumber="2" cardTitle="title.project_holder">
    <div class="contact">
        <form [formGroup]="folderHolderForm" (keydown.enter)="$event.preventDefault()">
            <div class="row">
                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.company_name' | translate }}"
                               formControlName="companyName"
                               type="text" autocomplete="off"
                               maxlength="{{ lengthValidators.genString }}">
                        <mat-error
                                *ngIf="folderHolderForm.get('companyName').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.last_name' | translate }}"
                               formControlName="lastName"
                               type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                        <mat-error *ngIf="folderHolderForm.get('lastName').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.first_name' | translate }}"
                               formControlName="firstName"
                               type="text" autocomplete="off"
                               maxlength="{{ lengthValidators.genString }}">
                        <mat-error *ngIf="folderHolderForm.get('firstName').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.function' | translate }}"
                               formControlName="function"
                               type="text" autocomplete="off"
                               maxlength="{{ lengthValidators.genString }}">
                        <mat-error *ngIf="folderHolderForm.get('function').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>


                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.email' | translate }}" formControlName="email"
                               type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                        <mat-error *ngIf="folderHolderForm.get('email').hasError('pattern')">
                            {{ 'validator.email.not_valid' | translate }}
                        </mat-error>
                        <mat-error *ngIf="folderHolderForm.get('email').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.mobile_phone' | translate }}"
                               formControlName="mobilePhone" type="text" autocomplete="off"
                               maxlength="{{ lengthValidators.genString }}">
                        <mat-error *ngIf="folderHolderForm.get('mobilePhone').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.phone' | translate }}" formControlName="phone"
                               type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                        <mat-error *ngIf="folderHolderForm.get('phone').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</app-card>

<!-- Measures -->
<app-card cardNumber="3" cardTitle="title.measures_evolution">
    <!--  General evolution -->
    <div class="table-scroll general-measure-wrapper">
        <div class="table-responsive">
            <table class="table col-md-12">
                <thead class="col-md-12">
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                        <th class="min-w-120" *ngIf="step['@permissions'].view" rowspan="2">
                            {{ 'workflow.' + step.columnName | translate }}
                        </th>
                    </ng-container>
                    <ng-container
                            *ngFor="let step of folderProgramResource.reportSteps; let first = first; let last = last; let index = index">
                        <th class="min-w-120 report-header report-first report-last" *ngIf="step['@permissions'].view"
                            [attr.colspan]="countColspan(step.steps)">
                            {{ step['@columnName'] | translate }}
                        </th>
                    </ng-container>
                </tr>
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <th class="min-w-120 report-header" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ 'workflow.' + step['@columnName'] | translate }}
                            </th>
                        </ng-container>
                    </ng-container>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th>{{ 'word.requested_contribution' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ step.dataset.requestedContribution | currency | default:'-' }}
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ step.dataset.requestedContribution | currency | default:'-' }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.third_party_subsidies' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ step.computedValues.thirdPartySubsidies | currency | default:'-' }}
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ step.computedValues.thirdPartySubsidies | currency | default:'-' }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.fixed_subsidies' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ step.dataset.fixedSubsidies | currency | default:'-' }}
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ step.dataset.fixedSubsidies | currency | default:'-' }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.variable_subsidies' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ step.dataset.variableSubsidies | currency | default:'-' }}
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ step.dataset.variableSubsidies | currency | default:'-' }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.initiated_savings' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            -
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ step.dataset.initiatedSavings | positive | number | default:0 }} {{ 'word.kwh' | translate }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.performed_savings' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            -
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ step.dataset.performedSavings | positive | number | default:0 }} {{ 'word.kwh' | translate }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.annual_saving' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ step.computedValues.annualSaving | positive | number }} {{ 'word.kwh' | translate }}
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ step.computedValues.annualSaving | positive | number }} {{ 'word.kwh' | translate }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.total_saving' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ step.computedValues.totalSaving | positive | number }} {{ 'word.kwh' | translate }}
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ step.computedValues.totalSaving | positive | number }} {{ 'word.kwh' | translate }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.cost_utility_ratio' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ step.computedValues.costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ step.computedValues.costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
<!--                <tr>-->
<!--                    <th>{{ 'word.bill' | translate }}</th>-->
<!--                    <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">-->
<!--                        <td class="blank-case" *ngIf="step['@permissions'].view"></td>-->
<!--                    </ng-container>-->
<!--                    <ng-container *ngFor="let column of folderProgramResource.reportSteps;">-->
<!--                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">-->
<!--                            <td class="" *ngIf="step['@permissions'].view" [class.report-first]="first"-->
<!--                                [class.report-last]="last">-->
<!--                                {{ isLastViewableReport(column, step) ? (step.computedValues.invoices | currency) : '-' }}-->
<!--                            </td>-->
<!--                        </ng-container>-->
<!--                    </ng-container>-->
<!--                </tr>-->

                <tr>
                    <th>{{ 'word.bill_fix' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                        <td class="blank-case" *ngIf="step['@permissions'].view"></td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProgramResource.reportSteps;">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="" *ngIf="step['@permissions'].view" [class.report-first]="first"
                                [class.report-last]="last">
                                {{ isLastViewableReport(column, step) ? (step.computedValues.invoicesFix | currency) : '-' }}

                            </td>
                        </ng-container>
                    </ng-container>
                </tr>

                <tr>
                    <th>{{ 'word.bill_variable' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                        <td class="blank-case" *ngIf="step['@permissions'].view"></td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProgramResource.reportSteps;">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="" *ngIf="step['@permissions'].view" [class.report-first]="first"
                                [class.report-last]="last">


                                {{ isLastViewableReport(column, step) ? (step.computedValues.invoicesVariable| currency) : '-' }}

                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr class="last-row">
                    <th>{{ 'word.balance' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                        <td class="blank-case icon show-icon current-step" *ngIf="step['@permissions'].view"
                            [class.edit-icon]="step['@permissions'].edit" (click)="showOrEditFolderStep()"></td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="icon show-icon current-step vertical-separator" *ngIf="step['@permissions'].view"
                                [class.edit-icon]="step['@permissions'].edit" [class.report-first]="first"
                                [class.report-last]="last" (click)="showOrEditReport(column, step)">

                                <span>{{ isLastViewableReport(column, step) ? (step.computedValues.balance | currency) : '-' }}</span>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div>
        <ng-container *ngFor="let measure of measures; let index = index">
            <h3 class="mb-1">{{ 'word.measure_n' | translate:{count: index + 1} }}</h3>
            <div class="table-scroll">
                <table class="table table-responsive w-100">
                    <thead>
                    <tr>
                        <th class="w-25">{{ '' }}</th>
                        <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                            <th class="min-w-120" *ngIf="step['@permissions'].view" rowspan="2">
                                {{ 'workflow.' + step.columnName | translate }}
                            </th>
                        </ng-container>
                        <ng-container *ngFor="let step of folderProgramResource.reportSteps">
                            <th class="min-w-120 report-header report-first report-last"
                                *ngIf="step['@permissions'].view" [attr.colspan]="countColspan(step.steps)">
                                {{ step['@columnName'] }}
                            </th>
                        </ng-container>
                    </tr>

                    <tr>
                        <th class="w-25">{{ '' }}</th>
                        <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <th class="min-w-120" *ngIf="step['@permissions'].view" [class.report-first]="first"
                                    [class.report-last]="last">
                                    {{ 'workflow.' + step['@columnName'] | translate }}
                                </th>
                            </ng-container>
                        </ng-container>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{{ 'word.technical_orientation' | translate }}</td>
                        <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                <ng-container *ngIf="step.measures[index].technicalOrientation">
                                    {{ step.measures[index].technicalOrientation.name | default:'-' }}
                                </ng-container>
                                <ng-container *ngIf="!step.measures[index].technicalOrientation">
                                    -
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    <ng-container *ngIf="step.measures[index].technicalOrientation">
                                        {{ step.measures[index].technicalOrientation.name | default:'-' }}
                                    </ng-container>
                                    <ng-container *ngIf="!step.measures[index].technicalOrientation">
                                        -
                                    </ng-container>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.description' | translate }}</td>
                        <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                            <td *ngIf="step['@permissions'].view && step.measures[index]"
                                [innerHTML]="step.measures[index].description | nl2br | default:'-'">
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProgramResource.reportSteps;">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first"
                                    [class.report-last]="last"
                                    [innerHTML]="step.measures[index].description | nl2br | default:'-'"
                                >
                                    {{ step.measures[index].description | nl2br | default:'-' }}
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.duration_of_use' | translate }}</td>
                        <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                {{ 'word.n_years' | translate:{count: step.measures[index].durationOfUse | default:'-'} }}
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProgramResource.reportSteps;">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    {{ 'word.n_years' | translate:{count: step.measures[index].durationOfUse | default:'-'} }}
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.annual_saving' | translate }}</td>
                        <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                {{ step.measures[index].annualSavings | positive | number | default: '-': ('word.kwh' | translate) }}
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProgramResource.reportSteps;">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    {{ step.measures[index].annualSavings | positive | number | default: '-': ('word.kwh' | translate) }}
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.total_saving' | translate }}</td>
                        <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                {{ step.measures[index].totalSavings | positive | number |  default: '-': ('word.kwh' | translate) }}
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProgramResource.reportSteps;">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    {{ step.measures[index].totalSavings | positive | number |  default: '-': ('word.kwh' | translate) }}
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    </tbody>
                </table>
            </div>

        </ng-container>
    </div>
</app-card>


<!-- General -->
<app-card cardNumber="4" appAdminComponent cardTitle="title.general">
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.project_name' | translate }}</p>
            <p>{{folderProgramResource.data.currentProgram.name}}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.folder_language' | translate }}</p>
            <mat-form-field>
                <mat-select [formControl]="assignedLanguageField">
                    <mat-option *ngFor="let lang of possibleLanguages"
                                [value]="lang.locale">{{ 'word.' + lang.locale | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <app-admin-managers-selection [managersForm]="managersForm"></app-admin-managers-selection>
</app-card>

<!-- Remarks -->
<div id="section5">
    <app-admin-remark-card appAdminComponent cardNumber="5" [folder]="folderProgramResource"></app-admin-remark-card>
</div>

<!--  Analysis -->
<app-card cardNumber="6" appAdminComponent cardTitle="title.analysis">
    <div class="row">
        <div class="col-md-12">
            <mat-form-field>
                <mat-label>{{ 'word.decision' | translate }}</mat-label>
                <textarea matInput
                          [value]="folderProgramResource.data.analysis.decision"
                          cdkTextareaAutosize
                          disabled
                          cdkAutosizeMinRows="5"
                          placeholder="{{ 'placeholder.decision' | translate}}"
                          #analysisDecision
                          maxlength="10000"></textarea>
                <mat-hint align="end">{{ analysisDecision.value.length }} / 65000</mat-hint>
            </mat-form-field>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-12">
            <mat-form-field>
                <mat-label>{{ 'word.comment' | translate }}</mat-label>
                <textarea matInput
                          disabled
                          [value]="folderProgramResource.data.analysis.comment"
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="5"
                          #analysisComment
                          maxlength="65000"></textarea>
                <mat-hint align="end">{{ analysisComment.value.length }} / 65000</mat-hint>
            </mat-form-field>
        </div>
    </div>
</app-card>

<app-todo [folder]="folderProgramResource"></app-todo>

<app-sticky-footer *appIsGranted="'ROLE_STAFF_PKW'">
    <div class="w-50">
        <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="save()"
                [loading]="loading.loading$ | async">
            {{ 'action.save' | translate }} <i class="icon-floppy-disk"></i>
        </button>
    </div>
    <div class="w-50 d-flex justify-content-end">
        <button mat-flat-button color="primary" class="align-self-end" (click)="openFolderSettingsDialog()">
            {{ 'word.folder_settings' | translate }} <i class="icon-filter"></i>
        </button>
    </div>
</app-sticky-footer>
