import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { RegisterRoutingModule } from './register-routing.module';
import { RegisterComponent } from './register/register.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserService } from '../../core/services/user.service';
import { SharedModule } from '../../shared/shared.module';
import { ComponentsModule } from '../../components/components.module';


@NgModule({
    declarations: [
        RegisterComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        RegisterRoutingModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatTabsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatCardModule,
        MatProgressSpinnerModule,
        SharedModule,
        ComponentsModule,
    ],
    providers: [
        UserService
    ],
    bootstrap: [RegisterComponent]
})
export class RegisterModule {
}
