import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { DirtyFormWarnableComponent } from '../../components/form-component/dirty-form.component';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { LoadingService } from '../services/loading.service';

@Injectable({
    providedIn: 'root'
})
export class DirtyFormGuard implements CanDeactivate<DirtyFormWarnableComponent> {
    constructor(
        private dialog: MatDialog,
        private loading: LoadingService
    ) {
    }

    /**
     * This prevents user from leaving a form if the form is dirty
     */
    canDeactivate(component: DirtyFormWarnableComponent): Observable<boolean> | boolean {
        if (component.canLeave && !component.canLeave()) {
            const dialog = this.dialog.open(ConfirmDialogComponent, {data: {
                title: 'dialog.leave_dirty_form.title',
                content: 'dialog.leave_dirty_form.content',
                cancel: 'dialog.leave_dirty_form.cancel',
                confirm: 'dialog.leave_dirty_form.confirm'
            }});
            dialog.afterClosed().subscribe(leaving => {
                if (!leaving) {
                    this.loading.stopLoading();
                }
            });
            return dialog.afterClosed();
        }

        return true;
    }
}

