<div class="row">
    <div class="col-md-12 d-flex align-items-center mb-2">
        <p class="costs-title">{{'fix_costs.' + fixCost.groupName | translate}}</p>
        <span class="hint-help">
              <mat-icon matTooltip="{{'fix_costs.'+fixCost.groupName+'.tooltip' | translate}}"
                        matTooltipPosition="right">help</mat-icon>
        </span>
    </div>
    <div class="col-4">
        <mat-label class="field-title">{{'word.amount' | translate}}</mat-label>
        <p>{{fixCost.cost.units ? fixCost.cost.units : '-'}}</p>
    </div>
    <div class="col-4">
        <mat-label class="field-title">{{'word.unit_price' | translate}}</mat-label>
        <p>{{fixCost.cost.unitPrice ? fixCost.cost.unitPrice + ' ' + ('word.chf' | translate) : '-'}}</p>
    </div>
    <div class="col-4 mat-form-field-appearance-legacy mat-form-field-disabled">
        <mat-label class="field-title">{{'word.total_cost' | translate}}</mat-label>
        <p>{{fixCost.computedValues.totalCost ? fixCost.computedValues.totalCost + ' ' + ('word.chf' | translate) : '-'}}</p>
    </div>
    <div class="col-8">
        <mat-label class="field-title">{{'word.total_requested_contribution' | translate}}</mat-label>
        <p>{{fixCost.cost.requestedContribution ? fixCost.cost.requestedContribution + ' ' + ('word.chf' | translate) : '-'}}</p>
    </div>
    <div class="col-4 mat-form-field-appearance-legacy mat-form-field-disabled">
        <mat-label class="field-title">{{'word.other_contribution' | translate}}</mat-label>
        <p>{{fixCost.computedValues.thirdPartyContribution ? fixCost.computedValues.thirdPartyContribution + ' ' + ('word.chf' | translate) : '-'}}</p>
    </div>
</div>
