import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';

export abstract class BaseDatasource implements DataSource<any> {
    public totalItems = 0;
    public itemsPerPage = 0;

    protected subject = new BehaviorSubject<any[]>([]);
    protected loadingSubject = new BehaviorSubject<boolean>(false);

    // eslint-disable-next-line
    public loading$ = this.loadingSubject.asObservable();

    /* eslint-disable */
    connect(): Observable<any[]> {
        return this.subject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.subject.complete();
        this.loadingSubject.next(false);
    }
    /* eslint-enable */

    /**
     * Replace the loading subject
     */
    public setLoadingSubject(loadingSubject: BehaviorSubject<boolean>): void {
        if (loadingSubject) {
            this.loadingSubject = loadingSubject;
        }
    }

    public getSubject(): BehaviorSubject<any[]> {
        return this.subject;
    }

    public updateItem(newItem: { '@id'?: string }): void {
        if (null === newItem['@id']) {
            throw new Error('Cannot update an item without an id.');
        }

        const updatedItems = this.subject.value.map((item: { '@id': string }) => {
            if (item['@id'] === newItem['@id']) {
                return newItem;
            }

            return item;
        });

        this.subject.next(updatedItems);
    }
}
