import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryCardComponent } from './project/summary-card/summary-card.component';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { NgMathPipesModule } from 'ngx-pipes';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ComponentsModule as SharedComponents } from '../../../components/components.module';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [
        SummaryCardComponent,
    ],
    exports: [
        SummaryCardComponent,
    ],
    imports: [
        CommonModule,
        MatCardModule,
        TranslateModule,
        PipesModule,
        NgMathPipesModule,
        MatFormFieldModule,
        SharedComponents,
        MatIconModule,
        ReactiveFormsModule,
        MatInputModule,
        MatTooltipModule
    ]
})
export class ComponentsModule { }
