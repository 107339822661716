import { Injectable } from '@angular/core';
import { HydraCollection } from '../models/hydra-collection.model';

@Injectable()
export class IriUtil {

    public static extractId(iri: string): string {
        const parts = iri.split('/');

        return parts.pop();
    }

    public static checkIfIri(potentialIri: string): boolean {
        const parts = potentialIri.split('/');
        return parts && parts.length > 1;
    }

    public static extractIdAsNumber(iri: string): number {
        return +this.extractId(iri);
    }

    public static extractIds(hydra: HydraCollection[]): string[] {
        const ret: string[] = [];
        hydra.forEach(item => {
            ret.push(this.extractId(item['@id']));
        });
        return ret;
    }

    public static extractIdsAsNumber(hydra: HydraCollection[]): number[] {
        const ret: number[] = [];
        const str = this.extractIds(hydra);
        str.forEach((value) => {
            ret.push(parseInt(value, 10));
        });
        return ret;
    }

    /**
     * Need same non static method to use as injection (then in templates)
     */
    public extractId(iri: string): string {
        const parts = iri.split('/');

        return parts.pop();
    }
}
