<h2 mat-dialog-title>{{'word.profile' | translate}} - {{auth.getUser().fullName}}</h2>
<mat-dialog-content [formGroup]="form">
    <div class="row">
        <mat-form-field class="col-md-6">
            <mat-label>{{'word.first_name' | translate}}</mat-label>
            <input matInput formControlName="firstname">
        </mat-form-field>
        <mat-form-field class="col-md-6">
            <mat-label>{{'word.last_name' | translate}}</mat-label>
            <input matInput formControlName="lastname">
        </mat-form-field>
        <mat-form-field class="col-md-6">
            <mat-label>{{'word.email' | translate}}</mat-label>
            <input matInput formControlName="email">
        </mat-form-field>
        <mat-form-field class="col-md-6">
            <mat-label>{{'action.change_password' | translate}}</mat-label>
            <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'" autocomplete="new-password">
            <button (click)="this.hidePassword = !this.hidePassword" mat-icon-button matSuffix type="button">
                <mat-icon>
                    {{ this.hidePassword ? 'visibility_off' : 'visibility' }}
                </mat-icon>
            </button>
        </mat-form-field>
    </div>
</mat-dialog-content>
<div class="col-md-12 d-flex justify-content-end mt-2">
    <button mat-flat-button mat-dialog-close class="cancel-button mr-2">{{ 'action.close' | translate }}</button>
    <button mat-flat-button (click)="save()" color="primary" appMatButtonLoading [loading]="loading.loading$ | async">{{ 'action.save' | translate }}</button>
</div>
