import { FolderProjectResource } from '../models/folder-project-resource.model';
import { FolderProgramResource } from '../models/folder-program-resource.model';

export class ResourceUtil {
    public static isProgram(folder: FolderProjectResource|FolderProgramResource): boolean {
        return 'currentProgram' in folder.data;
    }

    public static isProject(folder: FolderProjectResource|FolderProgramResource): boolean {
        return 'currentProject' in folder.data;
    }
}
