import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../../core/services/auth.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../../core/notifications/notification.service';
import { Subscription } from 'rxjs';
import { FormUtil } from '../../../core/utils/form.util';
import { LoadingService } from '../../../core/services/loading.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnDestroy, OnInit {
    public form = this.fb.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required]],
    });
    public hidePassword: boolean;

    private subscriptions: Subscription[] = [];
    private adminRoles = ['ROLE_STAFF_PKW', 'ROLE_CORNELIA', 'ROLE_OFEN'];

    constructor(
        public loading: LoadingService,
        private fb: FormBuilder,
        private authService: AuthService,
        private router: Router,
        private notificationService: NotificationService,
    ) {
        this.hidePassword = true;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public login(): void {
        if (this.form.valid) {
            const loginSubscription = this.authService.login(this.form.value).subscribe(() => {
                this.notificationService.success('toast.welcome_home');

                if (this.authService.getUser().roles.some(r => this.adminRoles.includes(r))) {
                    return void this.router.navigateByUrl('/admin');
                }

                void this.router.navigateByUrl('/dashboard');
            }, () => {
                this.notificationService.error('toast.wrong_username_or_password');
            });

            this.subscriptions.push(loginSubscription);
        } else {
            FormUtil.validateAllFormFields(this.form);
        }
    }

    public register(): void {
        void this.router.navigateByUrl('/register');
    }

    public passwordForgotten(): void {
        void this.router.navigateByUrl('/ask_reset');
    }

    public onEnter(): void {
        this.login();
    }

    ngOnInit(): void {
        this.authService.autoLogin();
        if (!this.authService.getUser()) {
            return;
        }

        if (this.authService.isAdmin()) {
            void this.router.navigateByUrl('/admin');
            return;
        }

        void this.router.navigateByUrl('/dashboard');
    }
}
