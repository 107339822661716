<!-- Milestones -->
<app-folder-detail id="section1" [tenderSession]="folder.tenderSession" [folder]="folder"
                   [showingFolder]="true"></app-folder-detail>

<!-- Project holder -->
<app-card cardNumber="2" cardTitle="word.supporting_organisation">
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.company_name' | translate }}</p>
            <p>{{ folder.supportingOrganization?.companyName | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.legal_form' | translate }}</p>
            <p>{{ folder.supportingOrganization?.legalForm ? ('legal_forms.' + folder.supportingOrganization.legalForm | translate) : '-'}}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.email' | translate }}</p>
            <p>{{ folder.supportingOrganization?.email | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.address' | translate }}</p>
            <p>{{ folder.supportingOrganization?.address | default:'-' }}</p>
        </div>

    </div>
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.city' | translate }}</p>
            <p>{{ folder.supportingOrganization?.city | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.postal_code' | translate }}</p>
            <p>{{ folder.supportingOrganization?.postalCode | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.mobile_phone' | translate }}</p>
            <p>{{ folder.supportingOrganization?.mobilePhone | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.phone' | translate }}</p>
            <p>{{ folder.supportingOrganization?.phone | default:'-' }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.website' | translate }}</p>
            <p>{{ folder.supportingOrganization?.website | default:'-' }}</p>
        </div>
    </div>
</app-card>

<!-- Project holder -->
<app-card cardNumber="3" cardTitle="title.project_holder">
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.company_name' | translate }}</p>
            <p>{{ folder.folderHolder?.companyName | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.last_name' | translate }}</p>
            <p>{{ folder.folderHolder?.lastName | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.first_name' | translate }}</p>
            <p>{{ folder.folderHolder?.firstName | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.function' | translate }}</p>
            <p>{{ folder.folderHolder?.function | default:'-' }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.email' | translate }}</p>
            <p>{{ folder.folderHolder?.email | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.mobile_phone' | translate }}</p>
            <p>{{ folder.folderHolder?.mobilePhone | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.phone' | translate }}</p>
            <p>{{ folder.folderHolder?.phone | default:'-' }}</p>
        </div>
    </div>
</app-card>

<!-- General -->
<app-card cardNumber="4" cardTitle="title.general">
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.project_name' | translate }}</p>
            <p>{{ folder.currentProgram.name | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.acronym' | translate }}</p>
            <p>{{ folder.currentProgram.acronym | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.average_cost_of_electricity' | translate }}</p>
            <p>{{ folder.currentProgram.dataset.cost | default:'-' }} {{ 'word.cts_kwh' | translate }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.geographic_target' | translate }}</p>
            <p>{{folder.currentProgram.geographicTarget ? ('geographic_target.' + folder.currentProgram.geographicTarget | translate) : '-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.sector' | translate }}</p>
            <p>{{ folder.currentProgram.sector ? ('sectors.' + folder.currentProgram.sector| translate) : '-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.start_date' | translate }}</p>
            <p>{{ folder.currentProgram.startDate | date:'dd.MM.yyyy' | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.end_date' | translate }}</p>
            <p>{{ folder.currentProgram.endDate | date:'dd.MM.yyyy' | default:'-' }}</p>
        </div>
    </div>
    <div class="row">
        <div class="mt-2 col-md-12">
            <p class="field-title">{{ 'word.geographic_target_specifications' | translate }}</p>
            <p>{{ folder.currentProgram.geographicTargetSpecifications| default:'-' }}</p>
        </div>
        <div class="mt-2 col-md-12">
            <p class="field-title">{{ 'word.description' | translate }}</p>
            <p class="text-justify" [innerHTML]="folder.currentProgram.description | nl2br | default:'-'"></p>
        </div>
    </div>
</app-card>

<!-- Measures -->
<app-card cardNumber="5" cardTitle="title.measures_evolution">
    <div class="table-scroll general-measure-wrapper">
        <div class="cs-border table-responsive">
            <table class="table col-md-12">
                <thead class="col-md-12">
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let folder of folder.programs">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view" rowspan="2">
                            {{ 'workflow.user.' + folder.markingName | translate }}
                        </td>
                    </ng-container>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th>{{ 'word.contribution_admin_management' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.programs">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.summary.adminManagementContribution | currency:'CHF':'symbol':'0.0-2' }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.cost_admin_management' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.programs">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.summary.adminManagementCost | currency:'CHF':'symbol':'0.0-2' }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.contribution_admin_accompaniment' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.programs">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.summary.adminManagementContribution | currency:'CHF':'symbol':'0.0-2' }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.cost_admin_accompaniment' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.programs">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.summary.adminAccompanimentCost | currency:'CHF':'symbol':'0.0-2' }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.contribution_admin_total' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.programs">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.summary.adminTotalContribution | currency:'CHF':'symbol':'0.0-2' }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.cost_admin_total' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.programs">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.summary.adminTotalCost | currency:'CHF':'symbol':'0.0-2' }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.contribution_measures' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.programs">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.summary.measuresContribution | currency:'CHF':'symbol':'0.0-2' }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.cost_measures' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.programs">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.summary.measuresCost | currency:'CHF':'symbol':'0.0-2' }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.requested_contribution' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.programs">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.requestedContribution | currency:'CHF':'symbol':'0.0-2' }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.total_cost' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.programs">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.summary.totalCost | currency:'CHF':'symbol':'0.0-2' }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.total_saving' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.programs">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.totalSaving | positive | number }} {{ 'word.kwh' | translate }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.cost_utility_ratio' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.programs">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.bill' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.programs">
                        <td class="blank-case" *ngIf="folder['@permissions'].view"></td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProgramResource.reportSteps;">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="" *ngIf="step['@permissions'].view" [class.report-first]="first"
                                [class.report-last]="last">
                                -
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr class="last-row">
                    <th>{{ 'word.balance' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.programs">
                        <td class="blank-case icon show-icon current-step" *ngIf="folder['@permissions'].view"
                            [class.edit-icon]="folder['@permissions'].edit" (click)="showOrEditFolderStep(folder)"></td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="icon show-icon current-step vertical-separator" *ngIf="step['@permissions'].view"
                                [class.edit-icon]="step['@permissions'].edit" [class.report-first]="first"
                                [class.report-last]="last" (click)="showOrEditReport(column, step)">
                                -
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <ng-container *ngFor="let measure of measures; let index = index">
        <div class="table-scroll">
            <div class="table-responsive mt-4">
                <h3 class="mb-1">{{ 'word.measure_n' | translate:{count: index + 1} }}</h3>
                <table class="table w-100">
                    <thead>
                    <tr>
                        <th class="w-25">{{ '' }}</th>
                        <ng-container *ngFor="let folder of folder.programs">
                            <th class="min-w-120" *ngIf="folder['@permissions'].view" rowspan="2">
                                {{ 'workflow.user.' + folder.markingName | translate }}
                            </th>
                        </ng-container>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{{ 'word.technical_orientation' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.programs">
                            <td *ngIf="folder['@permissions'].view"
                                [innerHTML]="folder.measures[index].datasetMeasure.technicalOrientation ? folder.measures[index].datasetMeasure.technicalOrientation.name : '-'"></td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.description' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.programs">
                            <td *ngIf="folder['@permissions'].view"
                                [innerHTML]="folder.measures[index].datasetMeasure.description | nl2br | default:'-'"></td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.duration_of_use' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.programs">
                            <td *ngIf="folder['@permissions'].view">
                                {{ 'word.n_years' | translate:{count: folder.measures[index].datasetMeasure.durationOfUse | default:'-'} }}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.unit' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.programs">
                            <td *ngIf="folder['@permissions'].view">
                                {{ folder.measures[index].datasetMeasure.units }}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.unit_price' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.programs">
                            <td *ngIf="folder['@permissions'].view">
                                {{ folder.measures[index].datasetMeasure.cost | currency:'CHF':'symbol':'0.0-2'}}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.requested_contribution' | translate }}
                            / {{'word.unit' | translate  | lowercase}}</td>
                        <ng-container *ngFor="let folder of folder.programs">
                            <td *ngIf="folder['@permissions'].view">
                                {{ folder.measures[index].datasetMeasure.requestedContribution | currency:'CHF':'symbol':'0.0-2'}}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.existing_annual_consumption' | translate }}
                            / {{'word.unit' | translate  | lowercase}}</td>
                        <ng-container *ngFor="let folder of folder.programs">
                            <td *ngIf="folder['@permissions'].view">
                                {{ folder.measures[index].datasetMeasure.existingAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.future_annual_consumption' | translate }}
                            / {{'word.unit' | translate  | lowercase}}</td>
                        <ng-container *ngFor="let folder of folder.programs">
                            <td *ngIf="folder['@permissions'].view">
                                {{ folder.measures[index].datasetMeasure.futureAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.annual_saving' | translate }} / {{'word.unit' | translate  | lowercase}}</td>
                        <ng-container *ngFor="let folder of folder.programs">
                            <td *ngIf="folder['@permissions'].view">
                                {{ folder.measures[index].datasetMeasure.annualSavings | number | default: '-': ('word.kwh' | translate) }}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.measure_annual_savings' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.programs">
                            <td *ngIf="folder['@permissions'].view">
                                {{ folder.measures[index].datasetMeasure.units * folder.measures[index].datasetMeasure.annualSavings | number | default: '-': ('word.kwh' | translate) }}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.total_cost' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.programs">
                            <td *ngIf="folder['@permissions'].view">
                                {{ folder.measures[index].datasetMeasure.units * folder.measures[index].datasetMeasure.cost | currency:'CHF':'symbol':'0.0-2'}}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.total_contribution' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.programs">
                            <td *ngIf="folder['@permissions'].view">
                                {{ folder.measures[index].datasetMeasure.units * folder.measures[index].datasetMeasure.requestedContribution | currency:'CHF':'symbol':'0.0-2'}}
                            </td>
                        </ng-container>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-container>

</app-card>

<!-- Documents persisted by staff -->
<app-card cardNumber="6" cardTitle="word.official_documents">
    <ng-container *ngIf="folder.mediaObjects.length > 0">
        <mat-list>
            <mat-list-item *ngFor="let mediaObject of folder.mediaObjects; let i = index; last as last">
                <p matLine>{{ mediaObject.originalName }}</p>
                <button mat-icon-button>
                    <mat-icon class="mat-24" (click)="downloadMediaObject(mediaObject)">cloud_download
                    </mat-icon>
                </button>

                <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
            </mat-list-item>
        </mat-list>
    </ng-container>
    <ng-container *ngIf="folder.mediaObjects.length === 0">
        {{ 'message.no_document' | translate }}
    </ng-container>
</app-card>
