<app-card classes="p-5">
    <div class="text-center">
        <h1>{{ 'title.ask_reset' | translate }}</h1>
    </div>
    <form [formGroup]="form" class="w-100">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="standard">
                    <mat-label>{{ 'word.email' | translate }}</mat-label>
                    <input matInput formControlName="email" autocomplete="email" (keyup.enter)="sendResetLink()">

                    <mat-error *ngIf="form.get('email').hasError('email')">
                        {{ 'validator.email.not_valid' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('email').hasError('required')">
                        {{ 'validator.email.not_blank' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
    <mat-card-actions>
        <button appMatButtonLoading [loading]="loading.loading$ | async" mat-flat-button color="accent"
                class="mat-button mr-1" (click)="sendResetLink()">{{ 'action.recover_password' | translate }}</button>
        <button [disabled]="loading.loading$ | async" mat-stroked-button color="default" class="mat-button"
                (click)="login()">{{ 'action.login' | translate }}</button>
    </mat-card-actions>
</app-card>
