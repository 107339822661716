import { Component } from '@angular/core';
import { FolderService } from '../../../../core/services/folder.service';
import { Router } from '@angular/router';
import { ReportService } from '../../../../core/services/report.service';
import { AdminReportDatasource } from '../../../../core/datasources/admin-report.datasource';
import { IriUtil } from '../../../../core/utils/iri.util';
import { LoadingService } from '../../../../core/services/loading.service';

@Component({
    selector: 'app-admin-dashboard-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})
export class ReportsComponent {
    public dataSource: AdminReportDatasource;
    public displayedColumns = ['folderReference', 'name', 'state', 'actions'];

    constructor(
        private folderService: FolderService,
        private reportService: ReportService,
        private router: Router,
        public loading: LoadingService
    ) {
        this.dataSource = new AdminReportDatasource(this.reportService);
        this.dataSource.loadReports(1, 5);
    }

    gotoReports(): void {
        void this.router.navigateByUrl('/admin/reports');
    }

    public showReport(folderIri: string, reportIri: string, lastReportId: number): void {
        void this.router.navigateByUrl('/admin/projects/' + IriUtil.extractId(folderIri) +
            '/reports/' + IriUtil.extractId(reportIri) + '/validation2/' + String(lastReportId));
    }
}
