import { Component } from '@angular/core';
import { PageTitleService } from '../../core/services/page-title.service';
import { TranslateService } from '@ngx-translate/core';
import { I18nService } from '../../core/i18n/i18n.service';
import { Observable } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { currentYear } from 'src/app/shared/currentYear';

@Component({
    selector: 'app-login-layout',
    templateUrl: './login-layout.component.html',
    styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent {
    public currentLocale;
    public languages = I18nService.languages;
    public currentYear = currentYear.year;

    public isLargeAndUp$: Observable<boolean> = this.breakpointObserver
        .observe([Breakpoints.Large, Breakpoints.XLarge])
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    constructor(
        private title: PageTitleService,
        private translator: TranslateService,
        public i18n: I18nService,
        private breakpointObserver: BreakpointObserver,
    ) {
        title.title(translator.instant('title.connexion'));
    }

    public changeLanguage(lang: string): void {
        this.i18n.useLocale(lang);
        this.currentLocale = lang.toUpperCase();
    }
}
