<form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
    <div class="row">
        <div class="col-md-7">
            <!-- Project's holder -->
            <app-card cardNumber="1" cardTitle="word.projects_holder">
                <div class="contact" formGroupName="folderHolder">
                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.company_name' | translate }}"
                                       formControlName="companyName"
                                       #companyName type="text" autocomplete="off"
                                       maxlength="{{ lengthValidators.genString }}">
                                <mat-error
                                        *ngIf="form.controls['folderHolder'].get('companyName').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.first_name' | translate }}"
                                       formControlName="firstName"
                                       #firstName type="text" autocomplete="off"
                                       maxlength="{{ lengthValidators.genString }}">
                                <mat-error *ngIf="form.controls['folderHolder'].get('firstName').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.last_name' | translate }}"
                                       formControlName="lastName" #lastName
                                       type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error *ngIf="form.controls['folderHolder'].get('lastName').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.address' | translate }}" formControlName="address"
                                       type="text"
                                       #address autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error *ngIf="form.controls['folderHolder'].get('address').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.postal_code' | translate }}"
                                       formControlName="postalCode"
                                       #postalCode type="text" autocomplete="off"
                                       maxlength="{{ lengthValidators.postCode }}">
                                <mat-error
                                        *ngIf="form.controls['folderHolder'].get('postalCode').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.postCode} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.city' | translate }}" formControlName="city"
                                       type="text" #city
                                       autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error *ngIf="form.controls['folderHolder'].get('city').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.email' | translate }}" formControlName="email"
                                       #email
                                       type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error *ngIf="form.controls['folderHolder'].get('email').hasError('pattern')">
                                    {{ 'validator.email.not_valid' | translate }}
                                </mat-error>
                                <mat-error *ngIf="form.controls['folderHolder'].get('email').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.phone' | translate }}" formControlName="phone"
                                       #phone
                                       type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error *ngIf="form.controls['folderHolder'].get('phone').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.mobile_phone' | translate }}"
                                       formControlName="mobilePhone"
                                       #mobilePhone type="text" autocomplete="off"
                                       maxlength="{{ lengthValidators.genString }}">
                                <mat-error
                                        *ngIf="form.controls['folderHolder'].get('mobilePhone').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                </div>
            </app-card>

            <!-- Project's beneficiary -->
            <app-card cardNumber="2" cardTitle="title.project_beneficiary">
                <div role="heading" class="header-action">
                    <button mat-button (click)="copyHolderValues()">
                        {{ 'action.copy_holder_values' | translate }}
                        <mat-icon>file_copy</mat-icon>
                    </button>
                </div>
                <div class="beneficiary" formGroupName="folderBeneficiary">
                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.company_name' | translate }}"
                                       formControlName="companyName"
                                       #companyName2 type="text" autocomplete="off"
                                       maxlength="{{ lengthValidators.genString }}">
                                <mat-error
                                        *ngIf="form.controls['folderBeneficiary'].get('companyName').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.first_name' | translate }}"
                                       formControlName="firstName"
                                       #firstName2 type="text" autocomplete="off"
                                       maxlength="{{ lengthValidators.genString }}">
                                <mat-error
                                        *ngIf="form.controls['folderBeneficiary'].get('firstName').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.last_name' | translate }}"
                                       formControlName="lastName" #lastName2
                                       type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error
                                        *ngIf="form.controls['folderBeneficiary'].get('lastName').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.address' | translate }}" formControlName="address"
                                       #address2
                                       type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error
                                        *ngIf="form.controls['folderBeneficiary'].get('address').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.postal_code' | translate }}"
                                       formControlName="postalCode"
                                       #postalCode2 type="text" autocomplete="off"
                                       maxlength="{{ lengthValidators.postCode }}">
                                <mat-error
                                        *ngIf="form.controls['folderBeneficiary'].get('postalCode').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.postCode} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.city' | translate }}" formControlName="city"
                                       #city2 type="text"
                                       autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error *ngIf="form.controls['folderBeneficiary'].get('city').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.email' | translate }}" formControlName="email"
                                       #email2
                                       type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error *ngIf="form.controls['folderBeneficiary'].get('email').hasError('pattern')">
                                    {{ 'validator.email.not_valid' | translate }}
                                </mat-error>
                                <mat-error
                                        *ngIf="form.controls['folderBeneficiary'].get('email').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.phone' | translate }}" formControlName="phone"
                                       #phone2
                                       type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error
                                        *ngIf="form.controls['folderBeneficiary'].get('phone').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.mobile_phone' | translate }}"
                                       formControlName="mobilePhone"
                                       #mobilePhone2 type="text" autocomplete="off"
                                       maxlength="{{ lengthValidators.genString }}">
                                <mat-error
                                        *ngIf="form.controls['folderBeneficiary'].get('mobilePhone').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </app-card>

            <!-- General -->
            <app-card cardNumber="3" cardTitle="title.general">
                <div class="folder" formGroupName="currentProject">
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.project_name' | translate }}"
                                       formControlName="name" #name
                                       type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error *ngIf="form.controls['currentProject'].get('name').hasError('required')">
                                    {{ 'validator.project_name.not_blank' | translate }}
                                </mat-error>
                                <mat-error *ngIf="form.controls['currentProject'].get('name').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                                <mat-error *ngIf="form.controls['currentProject'].get('name').hasError('pattern')">
                                    {{ 'validator.field.special_chars' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.acronym' | translate }}" formControlName="acronym"
                                       type="text"
                                       #acronym autocomplete="off" maxlength="{{ lengthValidators.acronym }}">
                                <mat-hint align="end">{{ acronym.value.length }}
                                    / {{ lengthValidators.acronym }}</mat-hint>
                                <mat-hint>{{ 'hint.max_n_chars' | translate:{countMax: lengthValidators.acronym} }}</mat-hint>
                                <mat-error *ngIf="form.controls['currentProject'].get('acronym').hasError('required')">
                                    {{ 'validator.acronym.not_blank' | translate }}
                                </mat-error>
                                <mat-error *ngIf="form.controls['currentProject'].get('acronym').hasError('maxlength')">
                                    {{ 'validator.acronym.max_length' | translate:{maxLength: form.controls['currentProject'].get('acronym').errors['maxlength'].requiredLength} }}
                                </mat-error>
                                <mat-error *ngIf="form.controls['currentProject'].get('acronym').hasError('pattern')">
                                    {{ 'validator.field.special_chars' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field>
                                <input matInput
                                       [matDatepicker]="startDatePicker"
                                       placeholder="{{ 'word.start_date' | translate }}"
                                       formControlName="startDate"
                                       [min]="startDateMin"
                                       type="text"
                                       autocomplete="off">
                                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #startDatePicker></mat-datepicker>
                                <mat-error
                                        *ngIf="form.controls['currentProject'].get('startDate').hasError('matDatepickerParse')">
                                    {{ 'validator.startDate.not_valid' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field>
                                <input matInput
                                       [matDatepicker]="endDatePicker"
                                       placeholder="{{ 'word.end_date' | translate }}"
                                       formControlName="endDate"
                                       [min]="form.get('currentProject.startDate').value || startDateMin"
                                       type="text"
                                       autocomplete="off">
                                <mat-hint>
                    <span class="hint-help">
                      <mat-icon matTooltip="{{ 'help.end_date' | translate }}">help</mat-icon>
                    </span>
                                </mat-hint>
                                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #endDatePicker></mat-datepicker>
                                <mat-error
                                        *ngIf="form.controls['currentProject'].get('endDate').hasError('matDatepickerParse')">
                                    {{ 'validator.end_date.not_valid' | translate }}
                                </mat-error>
                                <mat-error
                                        *ngIf="form.controls['currentProject'].get('endDate').hasError('matDatepickerMin')">
                                    {{ 'validator.end_date.min_value' | translate:{min: form.controls['currentProject'].get('endDate').getError('matDatepickerMin').min} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="dataset" formGroupName="dataset">
                        <div class="row">
                            <div class="col-md-6 d-flex">
                                <mat-form-field color="primary">
                                    <input matInput
                                           placeholder="{{ 'word.average_cost_of_electricity' | translate }}"
                                           formControlName="cost"
                                           type="number"
                                           autocomplete="off"
                                           min="{{ lengthValidators.minCtsKwhCost }}"
                                           max="{{ lengthValidators.maxCtsKwhCost }}"
                                           (change)="executeCalculation()">
                                    <mat-hint>
                                        {{ 'hint.cts_kwh' | translate:{countMax: lengthValidators.acronym} }}
                                        <span class="hint-help">
                        <mat-icon matTooltip="{{ 'help.cost' | translate }}">help</mat-icon>
                      </span>
                                    </mat-hint>
                                    <span class="mat-input-suffix" matSuffix>{{ 'word.cts_kwh' | translate }}</span>
                                    <mat-error
                                            *ngIf="form.controls['currentProject'].get('dataset').get('cost').hasError('min')">
                                        {{ 'validator.cost_cts_kwh.min' | translate:{min: form.controls['currentProject'].get('dataset').get('cost').errors['min'].min} }}
                                    </mat-error>
                                    <mat-error
                                            *ngIf="form.controls['currentProject'].get('dataset').get('cost').hasError('max')">
                                        {{ 'validator.cost_cts_kwh.min' | translate:{min: form.controls['currentProject'].get('dataset').get('cost').errors['max'].max} }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>{{ 'word.planned_monitoring' | translate }}</mat-label>
                                <textarea matInput
                                          formControlName="plannedMonitoring"
                                          cdkTextareaAutosize
                                          cdkAutosizeMinRows="5"
                                          placeholder="{{ 'placeholder.planned_monitoring' | translate}}"
                                          #plannedMonitoring
                                          maxlength="{{ lengthValidators.longString }}"></textarea>
                                <mat-hint align="end">{{ plannedMonitoring.value.length }}
                                    / {{ lengthValidators.longString }}</mat-hint>
                                <mat-error
                                        *ngIf="form.controls['currentProject'].get('plannedMonitoring').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.longString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>{{ 'word.additionality' | translate }}</mat-label>
                                <textarea matInput
                                          formControlName="additionality"
                                          cdkTextareaAutosize
                                          placeholder="{{ 'placeholder.additionality' | translate}}"
                                          cdkAutosizeMinRows="5"
                                          #additionality
                                          maxlength="{{ lengthValidators.longString }}"></textarea>
                                <mat-hint align="end">{{ additionality.value.length }}
                                    / {{ lengthValidators.longString }}</mat-hint>
                                <mat-error
                                        *ngIf="form.controls['currentProject'].get('additionality').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.longString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>{{ 'word.description' | translate }}</mat-label>
                                <textarea matInput
                                          formControlName="description"
                                          placeholder="{{ 'placeholder.description' | translate}}"
                                          cdkTextareaAutosize
                                          cdkAutosizeMinRows="5"
                                          #description
                                          maxlength="{{ lengthValidators.longString }}"></textarea>
                                <mat-hint align="end">{{ description.value.length }}
                                    / {{ lengthValidators.longString }}</mat-hint>
                                <mat-error
                                        *ngIf="form.controls['currentProject'].get('description').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.longString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </app-card>

            <!-- Measures -->
            <app-card cardNumber="4" cardTitle="title.measures">
                <div class="folder" formGroupName="currentProject">
                    <div class="measures" formArrayName="measures">
                        <div *ngFor="let item of form.get('currentProject').get('measures')['controls']; let i = index;"
                             [formGroupName]="i">
                            <div class="dataset-measure fields-group" formGroupName="datasetMeasure">
                                <div class="header">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <h2>{{ 'word.measure_n' | translate:{count: i + 1} }}</h2>
                                        </div>
                                        <div>
                                            <button class="float-left"
                                                    tabindex="-1"
                                                    mat-icon-button
                                                    color="primary"
                                                    type="button"
                                                    (click)="removeMeasureGroup(i)">
                                                <mat-icon>highlight_off</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="content">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <mat-form-field color="primary">
                                                <mat-select (selectionChange)="executeCalculation()"
                                                            formControlName="technicalOrientation"
                                                            placeholder="{{ 'word.technical_orientation' | translate }}"
                                                >
                                                    <mat-option>
                                                        <ngx-mat-select-search [formControl]="searchTechnicalOri"
                                                                               placeholderLabel="{{ 'word.search' | translate }}"
                                                                               noEntriesFoundLabel="{{'message.no_result'|translate}}">
                                                        </ngx-mat-select-search>
                                                    </mat-option>
                                                    <mat-option
                                                            *ngFor="let technicalOrientation of this.technicalOrientations"
                                                            [value]="technicalOrientation['@id']">
                                                        {{ technicalOrientation.name }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-form-field
                                                    class="mat-form-field-appearance-legacy mat-form-field-disabled">
                                                <input matInput
                                                       placeholder="{{ 'word.duration_of_use' | translate }}"
                                                       formControlName="durationOfUse"
                                                       [readonly]="true">
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.years' | translate }}</span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <mat-form-field>
                                                <input matInput
                                                       placeholder="{{ 'word.measure_cost' | translate }}"
                                                       formControlName="cost"
                                                       type="number"
                                                       autocomplete="off"
                                                       min="{{ lengthValidators.minCost }}"
                                                       (change)="executeCalculation()">
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.chf' | translate }}</span>
                                                <mat-hint>
                                                 <span class="hint-help">
                                                  <mat-icon matTooltip="{{ 'help.measure_measure_cost' | translate }}">help</mat-icon>
                                                </span>
                                                </mat-hint>
                                                <mat-error *ngIf="item.get('datasetMeasure.cost').hasError('required')">
                                                    {{ 'validator.cost.not_blank' | translate }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-form-field>
                                                <input matInput
                                                       placeholder="{{ 'word.annual_saving' | translate }}"
                                                       value="{{ computedResume.measures[i]?.economyAnnual | positive }}"
                                                       disabled="disabled"
                                                       [readonly]="true">
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.kwh' | translate }}</span>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <mat-form-field>
                                                <input matInput
                                                       placeholder="{{ 'word.existing_annual_consumption' | translate }}"
                                                       formControlName="existingAnnualConsumption"
                                                       type="number"
                                                       autocomplete="off"
                                                       min="{{lengthValidators.positiveValue}}"
                                                       (change)="executeCalculation()">
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.kwh' | translate }}</span>
                                                <mat-error
                                                    *ngIf="item.get('datasetMeasure.existingAnnualConsumption').hasError('required')">
                                                    {{ 'validator.existing_annual_consumption.not_blank' | translate }}
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="item.get('datasetMeasure.existingAnnualConsumption').hasError('min')">
                                                    {{ 'validator.existing_annual_consumption.min' | translate:{min: item.get('datasetMeasure.existingAnnualConsumption').errors['min'].min} }}
                                                </mat-error>

                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-form-field>
                                                <input matInput
                                                       placeholder="{{ 'word.total_saving' | translate }}"
                                                       value="{{ computedResume.measures[i]?.economyTotal | positive }}"
                                                       disabled="disabled"
                                                       [readonly]="true">
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.kwh' | translate }}</span>
                                                <mat-hint>
                                                 <span class="hint-help">
                                                  <mat-icon matTooltip="{{ 'help.measure_total_saving' | translate }}">help</mat-icon>
                                                </span>
                                                </mat-hint>
                                            </mat-form-field>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <mat-form-field>
                                                <input matInput
                                                       placeholder="{{ 'word.future_annual_consumption' | translate }}"
                                                       formControlName="futureAnnualConsumption"
                                                       type="number"
                                                       autocomplete="off"
                                                       min="{{lengthValidators.positiveValue}}"
                                                       (change)="executeCalculation()">
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.kwh' | translate }}</span>
                                                <mat-error
                                                    *ngIf="item.get('datasetMeasure.futureAnnualConsumption').hasError('min')">
                                                    {{ 'validator.future_annual_consumption.min' | translate:{min: item.get('datasetMeasure.futureAnnualConsumption').errors['min'].min} }}
                                                </mat-error>
                                                <mat-error
                                                    *ngIf="item.get('datasetMeasure.futureAnnualConsumption').hasError('lessThan')">
                                                    {{ 'validator.future_annual_consumption_must_be_lower_than_existing_annual_consumption' | translate }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-form-field>
                                                <input matInput
                                                       placeholder="{{ 'word.payback_without_contribution' | translate }}"
                                                       value="{{ computedResume.measures[i]?.paybackWithoutContribution | positive }}"
                                                       disabled="disabled"
                                                       [readonly]="true">
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.years' | translate }}</span>
                                                <mat-hint>
                                                 <span class="hint-help">
                                                  <mat-icon
                                                          matTooltip="{{ 'help.measure_payback_without_contribution' | translate }}">help</mat-icon>
                                                </span>
                                                </mat-hint>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <mat-form-field>
                                                <mat-label>{{ 'word.description' | translate }}</mat-label>
                                                <textarea matInput
                                                          #measureDescription
                                                          formControlName="description"
                                                          cdkTextareaAutosize
                                                          cdkAutosizeMinRows="5"
                                                          maxlength="{{ lengthValidators.longString }}"></textarea>
                                                <mat-hint align="end">{{ measureDescription.value.length }}
                                                    / {{ lengthValidators.longString }}</mat-hint>
                                                <mat-error
                                                        *ngIf="item.get('datasetMeasure.description').hasError('maxlength')">
                                                    {{ 'validator.field.too_long' | translate: '{ max: 4000 }' }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button mat-mini-fab type="button" color="primary" class="card-content-action" (click)="addMeasure()">
                    <mat-icon>add</mat-icon>
                </button>
            </app-card>

            <!-- Requested contribution -->
            <app-card cardNumber="5" cardTitle="title.requested_contribution">
                <p class="mat-caption">{{ 'project_2023Big.requested_contribution_caption' | translate }}</p>

                <div class="folder" formGroupName="currentProject">
                    <div class="dataset" formGroupName="dataset">
                        <div class="row">
                            <mat-form-field class="col-md-6">
                                <input matInput
                                       placeholder="{{ 'word.requested_contribution' | translate }}"
                                       formControlName="requestedContribution"
                                       type="number"
                                       autocomplete="off"
                                       (change)="executeCalculation()">
                                <mat-hint>
                                    {{'word.support_rate' | translate}}
                                    : {{ computedResume.subsidyRate | positive | round }} %
                                    <span class="hint-help"><mat-icon
                                            matTooltip="{{ 'help.measure_maximum_support_contribution_big' | translate }}">help</mat-icon></span>
                                </mat-hint>
                                <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>

                                <mat-error
                                        *ngIf="form.controls['currentProject'].get('dataset.requestedContribution').hasError('min')">
                                    {{ 'validator.requested_contribution.min' | translate:{min: form.controls['currentProject'].get('dataset').get('requestedContribution').errors['min'].min} }}
                                </mat-error>
                                <mat-error
                                    *ngIf="form.controls['currentProject'].get('dataset.requestedContribution').hasError('max')">
                                    {{ 'validator.requested_contribution.max' | translate:{max: form.controls['currentProject'].get('dataset').get('requestedContribution').errors['max'].max} }}
                                </mat-error>
                                <mat-error
                                    *ngIf="form.controls['currentProject'].get('dataset.requestedContribution').hasError('lessThan')">
                                    {{ 'validator.requested_contribution.max' | translate:{max: computedResume.maximumContribution | round} }}
                                </mat-error>
                            </mat-form-field>
                            <mat-form-field class="col-md-6">
                                <input matInput
                                       placeholder="{{ 'word.maximum_contribution' | translate }}"
                                       formControlName="maximumContribution"
                                       disabled="disabled"
                                       [readonly]="true">
                                <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
                                <mat-error *ngIf="currentProjectForm.get('dataset.maximumContribution').hasError('min')" class="color-red">
                                    {{ 'validator.requested_contribution.min' | translate:{min: lengthValidators.minRequestedContribution} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </app-card>

            <!-- Documents -->
            <app-card cardNumber="6" cardTitle="title.documents">
                <div class="description mat-caption"
                     [innerHTML]="'project_2023Big.documents_description' | translate"></div>
                <app-media-objects-list [form]="currentProjectForm"
                                        [folderUuid]="folderProject ? folderProject.data.uuid : getUuid()"></app-media-objects-list>
            </app-card>

            <app-requirements-control [form]="form" [numberOfBoxes]="5" computorName="project_2023Big"
                                      [sectionNumber]="7"></app-requirements-control>
        </div>
        <div class="col-md-5">
            <app-sticky-container offsetTop="100">
                <!-- Summary -->
                <app-card cardTitle="title.summary">
                    <div class="dataset" formGroupName="currentProject">
                        <div class="indicators-row d-flex justify-content-between align-items-center">
                            <div class="indicator-box important-indicator d-flex align-items-center">
                                <div class="icon">
                                    <i class="icon-chart"></i>
                                </div>
                                <div class="indicator" matTooltip="{{ 'help.cost_utility_ratio' | translate }}">
                                    <p>{{ 'word.cost_utility_ratio' | translate }}</p>
                                    <p>{{ computedResume.costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}</p>
                                </div>
                            </div>
                            <div class="indicator-box d-flex align-items-center">
                                <div class="icon">
                                    <i class="icon-save-time"></i>
                                </div>
                                <div class="indicator" matTooltip="{{ 'help.measure_total_saving' | translate }}">
                                    <p>{{ 'word.total_saving' | translate }}</p>
                                    <p>{{ computedResume.economyTotal | positive | number }} {{ 'word.kwh' | translate }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="indicators-row d-flex justify-content-between align-items-center">
                            <div class="indicator-box d-flex align-items-center">
                                <div class="icon">
                                    <i class="icon-management"></i>
                                </div>
                                <div class="indicator">
                                    <p>{{ 'word.total_cost' | translate }}</p>
                                    <p>{{ computedResume.totalCost | round | currency:defaultCurrency:'symbol':'1.0'  }}</p>
                                </div>
                            </div>
                            <div class="indicator-box d-flex align-items-center">
                                <div class="icon shorticon">
                                    <i class="icon-loss"></i>
                                </div>
                                <div class="indicator"
                                     matTooltip="{{ 'help.summary_payback_without_contribution' | translate }}">
                                    <p>{{ 'word.payback_without_contribution' | translate }}</p>
                                    <p>{{ 'word.n_years' | translate:{count: computedResume.paybackWithoutContribution | positive} }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="indicators-row d-flex justify-content-between align-items-center">
                            <div class="indicator-box d-flex align-items-center">
                                <div class="icon">
                                    <i class="icon-stats"></i>
                                </div>
                                <div class="indicator"
                                     matTooltip="{{ 'help.summary_maximum_support_contribution_big' | translate }}">
                                    <p>{{ 'word.maximum_contribution' | translate }}</p>
                                    <p>{{ computedResume.maximumContribution | round | currency:defaultCurrency:'symbol':'1.0' }}</p>
                                </div>
                            </div>
                            <div class="indicator-box d-flex align-items-center">
                                <div class="icon shorticon">
                                    <i class="icon-revenue-up"></i>
                                </div>
                                <div class="indicator">
                                    <p>{{ 'word.payback_with_contribution' | translate }}</p>
                                    <p>{{ 'word.n_years' | translate:{count: computedResume.paybackWithContribution | positive} }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="indicators-row d-flex justify-content-between align-items-center">
                            <div class="indicator-box d-flex align-items-center">
                                <div class="icon shorticon">
                                    <i class="icon-revenue"></i>
                                </div>
                                <div class="indicator">
                                    <p>{{ 'word.requested_contribution' | translate }}</p>
                                    <p>{{ computedResume.requestedContribution | round | currency:defaultCurrency:'symbol':'1.0' }}</p>
                                </div>
                            </div>
                            <div class="indicator-box d-flex align-items-center">
                                <div class="icon shorticon">
                                    <i class="icon-power"></i>
                                </div>
                                <div class="indicator">
                                    <p>{{ 'word.percent_saving' | translate }}</p>
                                    <p>{{ computedResume.economyPercent | positive | round:2 }} %</p>
                                </div>
                            </div>
                        </div>

                        <div class="indicators-row d-flex justify-content-between align-items-center">
                            <div class="indicator-box d-flex align-items-center">
                                <div class="icon shorticon">
                                    <i class="icon-revenue"></i>
                                </div>
                                <div class="indicator"
                                     matTooltip="{{ 'help.measure_maximum_support_contribution' | translate }}">
                                    <p>{{ 'word.support_rate' | translate }}</p>
                                    <p>{{ computedResume.subsidyRate | round:2 | positive }} %</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </app-card>

                <!-- Important dates -->
                <!--                <app-card cardTitle="title.important_dates">-->
                <!--                  <app-important-dates-list [tenderSession]="tenderSession"></app-important-dates-list>-->
                <!--                </app-card>-->

                <!-- Infos -->
                <app-card cardTitle="title.infos">
                    <div [innerHTML]="'project_2023Big.infos' | translate"></div>
                </app-card>

            </app-sticky-container>
        </div>
    </div>
</form>

<app-sticky-footer>
    <div class="justify-content-start">
        <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="save()"
                [loading]="loading.loading$ | async">
            {{ 'action.save' | translate }} <i class="icon-floppy-disk"></i>
        </button>

        <ng-container *ngIf="folderProject">
            <button mat-flat-button color="primary" appMatButtonLoading (click)="exportPdf()"
                    [loading]="loading.loading$ | async">
                {{ 'action.pdf' | translate }} <i class="icon-pdf-file"></i>
            </button>

            <button *ngIf="folderProject['@permissions'] && folderProject['@permissions'].delete" mat-flat-button
                    color="primary" class="ml-2" appConfirmDialog (confirmClick)="delete()" appMatButtonLoading
                    [loading]="loading.loading$ | async">
                {{ 'action.delete' | translate }} <i class="icon-delete"></i>
            </button>
        </ng-container>
    </div>
    <div class="justify-content-end">
        <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="check()"
                [loading]="loading.loading$ | async">
            {{ 'action.check' | translate }} <i class="icon-checked"></i>
        </button>

        <button mat-flat-button color="primary" appMatButtonLoading [loading]="loading.loading$ | async"
                [disabled]="!isSubmittable" appConfirmDialog (confirmClick)="submit()"
                [dialogContent]="'dialog.confirm_submit_project.content_save_without_sending_by_post'"
                [dialogConfirm]="'dialog.confirm_submit_project.confirm'"
                [dialogCancel]="'dialog.confirm_submit_project.cancel'">
            {{ 'action.submit' | translate }} <i class="icon-message"></i>
        </button>
    </div>
</app-sticky-footer>
