<div>
    <ng-container *ngIf="authorizationChecker.checkIsGranted('ROLE_STAFF_PKW'); else cornelia">
        <div class="marking-wrapper ml-3 px-3 py-1" (click)="openModal()">
            {{ (showedMarking$ | async) | translate }} <mat-icon class="dd-icon">keyboard_arrow_down</mat-icon>
        </div>
    </ng-container>

    <ng-template #cornelia>
        <div class="marking-wrapper ml-3 px-3 py-1 disabled">
            {{ (showedMarking$ | async) | translate }}
        </div>
    </ng-template>
</div>
