import { Component, Input, OnInit, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ProgramComputedValues } from '../../../../../core/models/computed-values.model';
import { FolderProgramResource } from '../../../../../core/models/folder-program-resource.model';
import { ProgramCalculationResults } from '../../../services/program-calculator.service';
import { ProgramReportResource } from '../../../../../core/models/program-report-resource';

@Component({
    selector: 'app-computor2022-light-program-summary-card',
    templateUrl: './summary-card.component.html',
    styleUrls: ['./summary-card.component.scss']
})
export class ProgramSummaryCardComponent implements OnInit, OnChanges {
    @Input()
    public folder: FolderProgramResource|ProgramReportResource;

    @Input()
    public computedValues: ProgramComputedValues|ProgramCalculationResults;


    constructor(
        private cdr: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        this.ngOnChanges();
        this.cdr.detectChanges();
    }

    ngOnChanges(): void {
        if (!this.folder) {
            return;
        }

        if ('currentReport' in this.folder.data) {
            this.computedValues = this.folder.data.currentReport.computedValues;
            return;
        }
        this.computedValues = this.folder.data.currentProgram.computedValues;
    }
}
