import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { NotificationService } from '../../../../../../core/notifications/notification.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FolderStatusService } from '../../../../../../core/services/folder-status.service';
import { OldCalculatorService } from '../../../../services/old-calculator.service';
import { TranslateService } from '@ngx-translate/core';
import { SwitchUserService } from '../../../../../../core/services/switch-user.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
    COMPUTOR_NAMES, COMPUTOR_TYPES,
    FolderService,
} from '../../../../../../core/services/folder.service';
import {
    AbstractOldProgramReportFormTemplateComponent
} from '../../../../program-report-form.template';
import { ReportService } from '../../../../../../core/services/report.service';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import { IriUtil } from '../../../../../../core/utils/iri.util';
import { MenuService } from '../../../../../../core/services/menu.service';
import { MediaObjectService } from '../../../../../../core/services/media-object.service';
import { ProjectFieldsCheckerService } from '../../../../services/project-fields-checker.service';
import { ReportForm } from '../../../../models/reportForm';
import { FolderProgramResource } from '../../../../../../core/models/folder-program-resource.model';
import { FormUtil } from '../../../../../../core/utils/form.util';
import { ProgramReportService } from '../../../../../../core/services/program-report.service';
import { PageTitleService } from '../../../../../../core/services/page-title.service';
import { switchMap } from 'rxjs/operators';
import { LoadingService } from '../../../../../../core/services/loading.service';
import { TechnicalOrientationService } from '../../../../../../core/services/technical-orientation.service';
import { ProgramReportResource } from '../../../../../../core/models/program-report-resource';

@Component({
    selector: 'app-report-edit',
    templateUrl: './program-report-edit.component.html',
    styleUrls: ['./program-report-edit.component.scss']
})
export class ProgramReportEditComponent extends AbstractOldProgramReportFormTemplateComponent implements OnInit, AfterViewInit, OnDestroy {
    public static computorName = COMPUTOR_NAMES.oldComputor;

    constructor(
        protected notificationService: NotificationService,
        protected technicalOrientationService: TechnicalOrientationService,
        protected fb: FormBuilder,
        protected folderStatusService: FolderStatusService,
        protected projectCalculator: OldCalculatorService,
        protected oldComputorForm: ReportForm,
        protected translateService: TranslateService,
        protected switchUserService: SwitchUserService,
        protected route: ActivatedRoute,
        protected folderService: FolderService,
        protected mediaObjectService: MediaObjectService,
        private reportService: ReportService,
        private reportResourceService: ProgramReportService,
        private breadCrumbService: BreadcrumbService,
        private router: Router,
        private menuService: MenuService,
        private projectFieldsCheckerService: ProjectFieldsCheckerService,
        private title: PageTitleService,
        public loading: LoadingService
    ) {
        super(
            oldComputorForm,
            projectCalculator,
            notificationService,
            switchUserService,
            technicalOrientationService,
            folderStatusService,
            fb,
            route,
            translateService,
            folderService,
            mediaObjectService
        );
        this.folderProgram = this.route.snapshot.data.folderProgramResource as FolderProgramResource ?? null;

        this.computorType = COMPUTOR_TYPES.program;
        this.computorName = COMPUTOR_NAMES.oldComputor;

        this.tenderSession = this.folderProgram.data.tenderSession;
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.initMenu();

        this.folderService.setProgramResource(this.folderProgram);
        this.folderService.addCurrentReport(this.programReportResource.data.currentReport);

        this.initBreadcrumb();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.folderService.clear();
        this.switchUserService.logoutIfSwitched();
    }

    public check(): void {
        if (this.form.valid) {
            const reportIO = { data: this.form.value as { currentReport }} as ProgramReportResource;
            this.reportResourceService.check(reportIO, this.computorType, this.computorName).subscribe(() => {
                this.isSubmittable = true;
                this.projectFieldsCheckerService.displayCheckResult([]);
            }, () => {
                this.isSubmittable = false;
            });
        } else {
            FormUtil.validateAllFormFields(this.form);
        }
    }

    public save(): void {
        if (this.form.valid) {
            const reportIO = { data: this.form.value as { currentReport }} as ProgramReportResource;
            this.reportResourceService.save(reportIO, this.computorType, this.computorName)
                .subscribe(() => {
                    this.notificationService.success('toast.data_successfully_saved');
                });
        } else {
            FormUtil.validateAllFormFields(this.form);
        }
    }

    public submit(): void {
        if (this.form.valid) {
            const reportIO = { data: this.form.value as {currentReport} } as ProgramReportResource;
            this.reportResourceService.save(reportIO, this.computorType, this.computorName, true)
                .pipe(
                    switchMap(() => this.reportService.submitReport(
                        IriUtil.extractId(this.programReportResource['@id'])
                    )),
                )
                .subscribe(() => {
                    this.notificationService.success('toast.data_successfully_saved');

                    // redirect to show current state (draft - can't edit no more)
                    this.redirectToShowReport(IriUtil.extractId(reportIO.data.currentReport['@id']));
                });
        }
    }

    redirectToShowReport(reportId: string): void {
        if (this.switchUserService.logoutIfSwitched()) {
            return;
        }

        void this.router.navigate([
            'sessions',
            IriUtil.extractId(this.tenderSession['@id']),
            'folders',
            IriUtil.extractId(this.folderProgram['@id']),
            'programs',
            this.programReportResource.data.id,
            'states',
            reportId
        ]);
    }

    private initBreadcrumb(): void {
        this.breadCrumbService.reportView(this.folderLink);
    }

    private initMenu(): void {
        const menu = [{link: '/dashboard', label: 'menu.dashboard', icon: 'icon-home', subItems: []}, {
            link: this.folderLink, label: this.programReportResource.data.name, icon: 'icon-clipboard', subItems: [
                { link: null, label: 'title.general', icon: 'icon-1', scrollTo: '#section1'},
                { link: null, label: 'title.documents', icon: 'icon-2', scrollTo: '#section2'},
                { link: null, label: 'title.invoices', icon: 'icon-3', scrollTo: '#section3'},
            ]
        }];
        this.menuService.store(menu);
    }

    public get currentReportForm(): FormGroup {
        return this.form.get('currentReport') as FormGroup;
    }

    get folderLink(): string {
        return '/sessions/' + IriUtil.extractId(this.tenderSession['@id']) + '/programs/' + String(this.folderProgram.data.id) + '/view';
    }

}
