<div class="row">
    <div class="col-md-7">
        <!-- Supporting Organisation -->
        <app-card cardTitle="word.supporting_organisation" cardNumber="1">
            <div class="row">
                <div class="col-md-4">
                    <p class="field-title">{{ 'word.company_name' | translate }}</p>
                    <p>{{ folder.data.supportingOrganization.companyName | default:'-' }}</p>
                </div>
                <div class="col-md-4">
                    <p class="field-title">{{ 'word.legal_form' | translate }}</p>
                    <p *ngIf="folder.data.supportingOrganization.legalForm">{{ 'legal_forms.' + folder.data.supportingOrganization.legalForm | translate }}</p>
                    <p *ngIf="!folder.data.supportingOrganization.legalForm">-</p>
                </div>
                <div class="col-md-4">
                    <p class="field-title">{{ 'word.address' | translate }}</p>
                    <p>{{ folder.data.supportingOrganization.address | default:'-' }}</p>
                </div>
                <div class="col-md-4">
                    <p class="field-title">{{ 'word.postal_code' | translate }}</p>
                    <p>{{ folder.data.supportingOrganization.postalCode | default:'-' }}</p>
                </div>
                <div class="col-md-4">
                    <p class="field-title">{{ 'word.city' | translate }}</p>
                    <p>{{ folder.data.supportingOrganization.city | default:'-' }}</p>
                </div>
                <div class="col-md-4">
                    <p class="field-title">{{ 'word.email' | translate }}</p>
                    <p>{{ folder.data.supportingOrganization.email | default:'-' }}</p>
                </div>
                <div class="col-md-4">
                    <p class="field-title">{{ 'word.mobile_phone' | translate }}</p>
                    <p>{{ folder.data.supportingOrganization.mobilePhone | default:'-' }}</p>
                </div>
                <div class="col-md-4">
                    <p class="field-title">{{ 'word.phone' | translate }}</p>
                    <p>{{ folder.data.supportingOrganization.phone | default:'-' }}</p>
                </div>
                <div class="col-md-4">
                    <p class="field-title">{{ 'word.website' | translate }}</p>
                    <p>{{ folder.data.supportingOrganization.website | default:'-' }}</p>
                </div>
            </div>
        </app-card>

        <!-- Project holder -->
        <div class="cs-margin">
            <app-card cardTitle="title.project_holder" cardNumber="2">
                <div class="row">
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.company_name' | translate }}</p>
                        <p>{{ folder.data.folderHolder.companyName | default:'-' }}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.last_name' | translate }}</p>
                        <p>{{ folder.data.folderHolder.lastName | default:'-' }}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.first_name' | translate }}</p>
                        <p>{{ folder.data.folderHolder.firstName | default:'-' }}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.function' | translate }}</p>
                        <p>{{ folder.data.folderHolder.function | default:'-' }}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.email' | translate }}</p>
                        <p>{{ folder.data.folderHolder.email | default:'-' }}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.phone' | translate }}</p>
                        <p>{{ folder.data.folderHolder.phone | default:'-' }}</p>
                    </div>
                </div>
            </app-card>

            <!-- General -->
            <app-card cardTitle="title.general" cardNumber="3">
                <div class="row">
                    <div class="col-md-6">
                        <p class="field-title">{{ 'word.project_name' | translate }}</p>
                        <p>{{ folder.data.currentProgram.name }}</p>
                    </div>
                    <div class="col-md-6">
                        <p class="field-title">{{ 'word.acronym' | translate }}</p>
                        <p>{{ folder.data.currentProgram.acronym }}</p>
                    </div>
                    <div class="col-md-6">
                        <p class="field-title">{{ 'word.average_cost_of_electricity' | translate }}</p>
                        <!--                        Divide by 100 because we want cents -->
                        <p>{{ folder.data.currentProgram.dataset.cost | default:'-' }} {{ 'word.cts_kwh' | translate }}</p>
                    </div>
                    <div class="col-md-6">
                        <p class="field-title">{{ 'word.requested_contribution' | translate }}</p>
                        <p>{{ folder.data.currentProgram.dataset.requestedContribution | currency | default:'-' }}</p>
                    </div>
                    <div class="col-md-6">
                        <p class="field-title">{{ 'word.start_date' | translate }}</p>
                        <p>{{ folder.data.currentProgram.startDate | date:'dd.MM.yyyy' | default:'-' }}</p>
                    </div>
                    <div class="col-md-6">
                        <p class="field-title">{{ 'word.end_date' | translate }}</p>
                        <p>{{ folder.data.currentProgram.endDate | date:'dd.MM.yyyy' | default:'-' }}</p>
                    </div>
                    <div class="col-md-6">
                        <p class="field-title">{{ 'word.sector' | translate }}</p>
                        <p>{{ 'sectors.' + folder.data.currentProgram.sector | translate }}</p>
                    </div>
                    <div class="col-md-6">
                        <p class="field-title">{{ 'word.geographic_target' | translate }}</p>
                        <p>{{ 'geographic_target.' + folder.data.currentProgram.geographicTarget | translate }}</p>
                    </div>
                    <div class="mt-2 col-md-12">
                        <p class="field-title">{{ 'word.geographic_target_specifications' | translate }}</p>
                        <p class="text-justify"
                           [innerHTML]="folder.data.currentProgram.geographicTargetSpecifications | nl2br | default:'-'"></p>
                    </div>
                    <div class="mt-2 col-md-12">
                        <p class="field-title">{{ 'word.description' | translate }}</p>
                        <p class="text-justify"
                           [innerHTML]="folder.data.currentProgram.description | nl2br | default:'-'"></p>
                    </div>
                </div>
            </app-card>

            <!-- Fix costs -->
            <app-card cardTitle="word.fix_costs" cardNumber="4">
                <h2>{{'word.program_management' | translate}}</h2>
                <ng-container *ngFor="let fixCost of managementFixCosts">
                    <app-program-readonly-fix-costs [fixCost]="fixCost"></app-program-readonly-fix-costs>
                </ng-container>
                <h2>{{'word.program_accompaniment' | translate}}</h2>
                <ng-container *ngFor="let fixCost of accompanimentFixCosts">
                    <app-program-readonly-fix-costs [fixCost]="fixCost"></app-program-readonly-fix-costs>
                </ng-container>
            </app-card>

            <!-- Measures -->
            <app-card cardTitle="title.measures" cardNumber="5">
                <div *ngFor="let measure of measures; let i = index">
                    <div class="dataset-measure fields-group">
                        <div class="header">
                            <h2>{{ 'word.measure_n' | translate:{count: i + 1} }}</h2>
                        </div>
                        <div class="content">
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="field-title">{{ 'word.technical_orientation' | translate }}</p>
                                    <p class="text-justify">
                                    <p>{{ measure.datasetMeasure.technicalOrientation ? measure.datasetMeasure.technicalOrientation.name : '-' }}</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="field-title">{{ 'word.duration_of_use' | translate }}</p>
                                    <p class="text-justify">{{ measure.datasetMeasure.durationOfUse | default:'-' }} {{ 'word.years' | translate }}</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="field-title">{{ 'word.payback_without_contribution' | translate }}</p>
                                    <p class="text-justify">{{ 'word.n_years' | translate:{count: measure.datasetMeasure.computedValues.paybackWithoutContribution | round} | default:'-' }}</p>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="field-title">{{ 'word.unit' | translate }}</p>
                                    <p class="text-justify">{{ measure.datasetMeasure.units | default:'-' }}</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="field-title">{{ 'word.measure_cost' | translate }}
                                        / {{'word.unit' | translate | lowercase}}</p>
                                    <p class="text-justify">{{ measure.datasetMeasure.cost | currency:'CHF':'symbol':'0.0-2' | default:'-' }}</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="field-title">{{ 'word.total_cost' | translate }}</p>
                                    <p class="text-justify">{{ (measure.datasetMeasure.computedValues.totalCost) | currency:'CHF':'symbol':'0.0-2' | default:'-' }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="field-title">{{ 'word.requested_contribution' | translate }}
                                        / {{'word.unit' | translate | lowercase}}</p>
                                    <p class="text-justify">{{ measure.datasetMeasure.requestedContribution | currency:'CHF':'symbol':'0.0-2' | default:'-' }}</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="field-title">{{ 'word.total_requested_contribution' | translate }}</p>
                                    <p class="text-justify">{{ (measure.datasetMeasure.computedValues.prokilowattContribution) | currency:'CHF':'symbol':'0.0-2' | default:'-' }}</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="field-title">{{ 'word.existing_annual_consumption' | translate }}</p>
                                    <p class="text-justify">{{ measure.datasetMeasure.existingAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="field-title">{{ 'word.future_annual_consumption' | translate }}</p>
                                    <p class="text-justify">{{ measure.datasetMeasure.futureAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="field-title">{{ 'word.annual_saving' | translate }}</p>
                                    <p class="text-justify">{{ measure.datasetMeasure.annualSavings | positive | number | default: '-': ('word.kwh' | translate) }}</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="field-title">{{ 'word.measure_annual_savings' | translate }}</p>
                                    <p class="text-justify">{{ measure.datasetMeasure.computedValues.annualSavingPerMeasure | positive | number | default: '-': ('word.kwh' | translate) }}</p>
                                </div>
                                <div class="col-md-12">
                                    <p class="field-title">{{ 'word.description' | translate }}</p>
                                    <p class="text-justify"
                                       [innerHTML]="measure.datasetMeasure.description | nl2br | default:'-'"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </app-card>

            <!--Analysis costs -->
            <app-card cardTitle="word.analysis_costs" cardNumber="6">
                <app-analysis-costs [analysisCosts]="analysisCosts"
                                    [computedValues]="computedValues"></app-analysis-costs>
            </app-card>

            <app-documents-list id="section7" [mediaObjects]="mediaObjects" [sectionNumber]="7"
                                [folderUuid]="folder.data.uuid"></app-documents-list>

        </div>
    </div>
    <div class="col-md-5">
        <app-sticky-container>
            <app-computor2025-program-summary-card [folder]="folder"></app-computor2025-program-summary-card>
        </app-sticky-container>
    </div>
</div>


<app-sticky-footer>
    <div>
        <ng-container *ngIf="folder">
            <button mat-flat-button color="primary" appMatButtonLoading (click)="exportPdf()"
                    [loading]="loading.loading$ | async">
                {{ 'action.pdf' | translate }} <i class="icon-pdf-file"></i>
            </button>
        </ng-container>
    </div>
</app-sticky-footer>
