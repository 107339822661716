import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjectReportResource } from '../../core/models/project-report-resource';
import { ReportService } from '../../core/services/report.service';
import { NotificationService } from '../../core/notifications/notification.service';
import { FormControl } from '@angular/forms';
import { LoadingService } from '../../core/services/loading.service';

@Component({
    selector: 'app-report-settings-dialog',
    templateUrl: './report-settings-dialog.component.html',
    styleUrls: ['./report-settings-dialog.component.scss']
})
export class ReportSettingsDialogComponent implements OnInit {
    isFinalReportCtrl = new FormControl(false);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {report: ProjectReportResource},
        private reportService: ReportService,
        private notification: NotificationService,
        private dialogRef: MatDialogRef<ReportSettingsDialogComponent>,
        public loading: LoadingService
    ) { }

    ngOnInit(): void {
        this.isFinalReportCtrl.setValue(this.data.report.data.isFinal);
    }

    deleteReport(): void {
        const iri = '/reports/' + String(this.data.report.data.id);
        this.reportService.delete(iri).subscribe(() => this.closeAfterDelete(), () => this.onDeleteReportError());
    }

    onDeleteReportError(): void {
        this.notification.error('word.report_contains_invoices');
    }

    save(): void {
        const iri = '/reports/' + String(this.data.report.data.id);
        const isFinal = Boolean(this.isFinalReportCtrl.value);

        this.reportService.toggleFinal(iri, isFinal).subscribe(() => {
            this.notification.success('toast.data_successfully_saved');
            this.dialogRef.close({
                isFinal
            });
        });
    }

    private closeAfterDelete(): void {
        this.dialogRef.close({
            deleted: true
        });
    }
}
