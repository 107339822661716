import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ConfirmDialogDirective } from '../core/directives/confirm-dialog.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { StickyFooterComponent } from './sticky-footer/sticky-footer.component';
import { ScrollTrackerDirective } from '../core/directives/scroll-tracker.directive';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { RouterModule } from '@angular/router';
import { BasicDialogComponent } from './basic-dialog/basic-dialog.component';
import { ErrorsBottomSheetComponent } from './errors-bottom-sheet/errors-bottom-sheet.component';
import { MatListModule } from '@angular/material/list';
import { MatButtonLoadingDirective } from '../core/directives/mat-button-loading.directive';
import { PipesModule } from '../core/pipes/pipes.module';
import { ChangeFolderStateDialogComponent } from './change-folder-state-dialog/change-folder-state-dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { AddRemarkDialogComponent } from './add-remark-dialog/add-remark-dialog.component';
import { AddTodoDialogComponent } from './add-todo-dialog/add-todo-dialog.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SwitchUserComponentComponent } from './switch-user-component/switch-user-component.component';
import { SwitchUserDirective } from '../core/directives/switch-user.directive';
import {
    DownloadAndSendExplanationDialogComponent
} from './download-and-send-explanation-dialog/download-and-send-explanation-dialog.component';
import { AddTenderSessionDialogComponent } from './add-tender-session-dialog/add-tender-session-dialog.component';
import { MatCardModule } from '@angular/material/card';
import { NewFilterDialogComponent } from './new-filter-dialog/new-filter-dialog.component';
import { AdminAddMilestoneDialogComponent } from './admin-add-milestone-dialog/admin-add-milestone-dialog.component';
import { ChangeReportStateDialogComponent } from './change-report-state-dialog/change-report-state-dialog.component';
import { AddInvoiceDialogComponent } from './add-invoice-dialog/add-invoice-dialog.component';
import { EditInvoiceComponent } from './edit-invoice/edit-invoice.component';
import { TableCellHighlighterDirective } from '../core/directives/table-cell-highlighter.directive';
import { FolderSettingsDialogComponent } from './folder-settings-dialog/folder-settings-dialog.component';
import { IsGrantedDirective } from '../core/security/directive/is-granted-directive';
import { UserProfileDialogComponent } from './user-profile-dialog/user-profile-dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ExportSelectionDialogComponent } from './export-selection-dialog/export-selection-dialog.component';
import { ReportSettingsDialogComponent } from './report-settings-dialog/report-settings-dialog.component';
import { IsNotGrantedDirective } from '../core/security/directive/is-not-granted-directive';
import { AdminIndicatorDirective } from '../core/directives/admin-indicator.directive';
import { ImportantDatesTimelineComponent } from './important-dates-timeline/important-dates-timeline.component';
import { ImportantDatesListComponent } from './important-dates-list/important-dates-list.component';

@NgModule({
    imports: [
        CommonModule,
        MatInputModule,
        ReactiveFormsModule,
        FormsModule,
        MatIconModule,
        MatButtonModule,
        TranslateModule,
        MatDialogModule,
        RouterModule,
        MatListModule,
        PipesModule,
        MatRadioModule,
        MatDatepickerModule,
        MatSelectModule,
        NgxMatSelectSearchModule,
        MatCardModule,
        MatCheckboxModule,
    ],
    declarations: [
        BasicDialogComponent,
        ConfirmDialogComponent,
        ConfirmDialogDirective,
        MatButtonLoadingDirective,
        AdminIndicatorDirective,
        StickyFooterComponent,
        ScrollTrackerDirective,
        SwitchUserDirective,
        TableCellHighlighterDirective,
        IsGrantedDirective,
        IsNotGrantedDirective,
        BreadcrumbComponent,
        ErrorsBottomSheetComponent,
        ChangeFolderStateDialogComponent,
        AddRemarkDialogComponent,
        AddTodoDialogComponent,
        DownloadAndSendExplanationDialogComponent,
        SwitchUserComponentComponent,
        DownloadAndSendExplanationDialogComponent,
        AddTenderSessionDialogComponent,
        NewFilterDialogComponent,
        AdminAddMilestoneDialogComponent,
        ChangeReportStateDialogComponent,
        AddInvoiceDialogComponent,
        EditInvoiceComponent,
        FolderSettingsDialogComponent,
        UserProfileDialogComponent,
        ExportSelectionDialogComponent,
        ReportSettingsDialogComponent,
        ImportantDatesTimelineComponent,
        ImportantDatesListComponent,
    ],
    exports: [
        ConfirmDialogDirective,
        TranslateModule,
        ScrollTrackerDirective,
        SwitchUserDirective,
        TableCellHighlighterDirective,
        IsGrantedDirective,
        IsNotGrantedDirective,
        StickyFooterComponent,
        BreadcrumbComponent,
        ErrorsBottomSheetComponent,
        MatButtonLoadingDirective,
        SwitchUserComponentComponent,
        AdminIndicatorDirective,
        ImportantDatesTimelineComponent,
        ImportantDatesListComponent,
    ],
    providers: [
    ],
    entryComponents: []
})
export class SharedModule {
}
