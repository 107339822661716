<app-card cardTitle="word.todo_list">
    <div class="row">
        <div class="col-md-3 d-flex align-items-center">
            <mat-slide-toggle
                    (click)="toggleArchivedTodos()">{{ 'word.show_todos_archives' | translate }}</mat-slide-toggle>
        </div>
        <div class="col-md-9">
            <mat-form-field color="primary">
                <input #input autocomplete="off" class="col-md-12" matInput
                       placeholder="{{ 'word.search' | translate }}" type="search">
            </mat-form-field>
        </div>
    </div>
    <div class="table-wrapper">
        <table (matSortChange)="sortData($event)" [dataSource]="todoDatasource" class="col-md-12" mat-table matSort>
            <ng-container matColumnDef="doneAction">
                <th *matHeaderCellDef mat-header-cell>
                </th>
                <td *matCellDef="let element" mat-cell>
                    <mat-checkbox (click)="archiveTodo(element['@id'])" *ngIf="element.done == null"
                                  class="custom-frame close-todo-btn"
                                  matTooltip="{{ 'action.archive' | translate }}"
                                  matTooltipPosition="left"></mat-checkbox>
                    <mat-checkbox (click)="activateTodo(element['@id'])" *ngIf="element.done != null" checked="checked"
                                  class="custom-frame close-todo-btn"
                                  matTooltip="{{ 'action.undo_archive' | translate }}"
                                  matTooltipPosition="left"></mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="urgentMark">
                <th mat-header-cell *matHeaderCellDef
                    mat-sort-header="todo.isUrgent"> {{ 'word.is_urgent' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="pt-2">
                    <ng-container *ngIf="element.isUrgent">
                        <mat-icon class="urgent">warning</mat-icon>
                    </ng-container>
                </td>
            </ng-container>
            <ng-container matColumnDef="select">
                <th *matHeaderCellDef class="pr-2" mat-header-cell>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                                  [checked]="selection.hasValue() && isAllPageSelected()"
                                  [indeterminate]="selection.hasValue() && !isAllPageSelected()"
                                  class="close-todo-btn"
                    >
                    </mat-checkbox>
                </th>
                <td *matCellDef="let element" class="pr-2" mat-cell>
                    <mat-checkbox (change)="$event ? toggle(element['@id']) : null"
                                  (click)="$event.stopPropagation()"
                                  [checked]="isSelected(element['@id'])"
                                  class="close-todo-btn"
                    >
                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th *matHeaderCellDef class="description" mat-header-cell> {{ 'word.description' | translate }} </th>
                <td *matCellDef="let element" class="description" mat-cell> {{element.description}} </td>
            </ng-container>
            <ng-container matColumnDef="gsManager">
                <th *matHeaderCellDef class="description" mat-header-cell
                    mat-sort-header="todo.gsMonitoringManager">{{ 'persons.monitoring_gs' | translate }}</th>
                <td *matCellDef="let element" class="description"
                    mat-cell>{{element.folder?.gsMonitoringManager?.firstname | default: '-'}} {{element.folder?.gsMonitoringManager?.lastname}}</td>
            </ng-container>
            <ng-container matColumnDef="assinedUser">
                <th *matHeaderCellDef mat-header-cell
                    mat-sort-header="todo.assignedUser">{{ 'word.assigned_user' | translate }}</th>
                <td *matCellDef="let element" mat-cell>
                    <ng-container *ngIf="element.assignedUser; else noUser">
                        {{element.assignedUser.firstname | translate}} {{element.assignedUser.lastname}}
                    </ng-container>
                    <ng-template #noUser>
                        -
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="deadline">
                <th *matHeaderCellDef mat-header-cell
                    mat-sort-header="todo.deadline">{{ 'word.deadline'| translate }}</th>
                <td *matCellDef="let element" [class.deadline_passed]="checkIfPassed(element.deadline)"
                    [class.deadline_today]="checkIfToday(element.deadline)" mat-cell>
                    {{(element.deadline | date) | default: '-'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="done">
                <th *matHeaderCellDef mat-header-cell mat-sort-header="todo.done">{{ 'word.done_at'| translate }}</th>
                <td *matCellDef="let element" mat-cell>
                    {{(element.done | date) | default: '-'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="updatedAt">
                <th *matHeaderCellDef mat-header-cell
                    mat-sort-header="todo.updatedAt">{{ 'word.updated_at'| translate }}</th>
                <td *matCellDef="let element" mat-cell>
                    {{element.updatedAt | date | default: '-'}}
                </td>
            </ng-container>
            <ng-container matColumnDef="folder">
                <th *matHeaderCellDef mat-header-cell
                    mat-sort-header="todo.project"> {{ 'title.project'| translate }} </th>
                <td *matCellDef="let element" mat-cell>
                    <ng-container *ngIf="element.folder; else noProject">
                        <a class="simple-link" (click)="showFolder(element.folder)">{{ element.folder.reference }}</a>
                    </ng-container>
                    <ng-template #noProject>
                        -
                    </ng-template>
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th *matHeaderCellDef class="text-right" mat-header-cell></th>
                <td *matCellDef="let element" class="action-icon-wrp text-right" mat-cell>
                    <button (click)="editTodo(element['@id'])"
                            [matTooltip]="'action.edit' | translate"
                            mat-icon-button>
                        <mat-icon>edit_outline</mat-icon>
                    </button>

                    <button (confirmClick)="removeTodo(element['@id'])"
                            [matTooltip]="'action.delete' | translate"
                            appConfirmDialog
                            mat-icon-button>
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
        </table>
    </div>
    <mat-card-actions>
        <div class="d-flex justify-content-between align-items-center flex-column flex-sm-row">
            <div>
                <button
                        *appIsGranted="'ROLE_STAFF_PKW'"
                        color="accent"
                        mat-flat-button
                        (click)="openExportSelectionDialog()"
                >{{ 'action.export_selection' | translate:{count: selection.selected.length} }}</button>
            </div>
            <div>
                <mat-paginator [length]="todoDatasource.totalItems"
                               [pageSize]="todoDatasource.itemsPerPage"
                               class="col-md-12"
                               hidePageSize
                               showFirstLastButtons>
                </mat-paginator>
            </div>
        </div>

        <button (click)="showNewTodoDialog()" class="card-content-action" mat-mini-fab>
            <mat-icon>add</mat-icon>
        </button>
    </mat-card-actions>
</app-card>
