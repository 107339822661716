import { BrowserModule } from '@angular/platform-browser';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app/app.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoginModule } from './views/login/login.module';
import { DashboardModule } from './views/client/dashboard/dashboard.module';
import { AdminDashboardModule } from './views/admin/dashboard/dashboard.module';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProjectModule } from './views/client/project/project.module';
import { ComputorModule } from './computors/computor.module';
import { RegisterModule } from './views/register/register.module';
import { ResetPasswordModule } from './views/reset-password/reset-password.module';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
    MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    MAT_MOMENT_DATE_FORMATS,
    MomentDateAdapter
} from '@angular/material-moment-adapter';
import { DashboardScrolledLayoutComponent } from './layouts/dashboard-scrolled-layout/dashboard-scrolled-layout.component';
import { UtilsService } from './core/services/utils.service';
import { SessionStateService } from './core/services/session-state.service';
import { IriUtil } from './core/utils/iri.util';
import { TenderTypeService } from './core/services/tender-type.service';
import { TenderModelService } from './core/services/tender-model.service';
import { DataCacheService } from './core/services/data-cache.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { registerLocaleData } from '@angular/common';
import localeFrCh from '../assets/locales/fr-CH';
import localeDeCh from '../assets/locales/de-CH';
import localeItCh from '../assets/locales/it-CH';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { ComponentsModule } from './components/components.module';
import { InvoiceForm } from './core/models/invoiceForm.model';
import { ReportShowComponent } from './views/client/report/report-show/report-show.component';
import { ReportEditComponent } from './views/client/report/report-edit/report-edit.component';
import { FolderProgramService } from './core/services/folder-program.service';
import { FolderProgramResolver } from './core/resolvers/folder-program.resolver';
import { ProgramReportEditComponent } from './views/client/report/program-report-edit/program-report-edit.component';
import {
    ProgramReportShowStepComponent
} from './views/client/report/program-report-show-step/program-report-show-step.component';
registerLocaleData(localeFrCh);
registerLocaleData(localeDeCh);
registerLocaleData(localeItCh);

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        LoginLayoutComponent,
        DashboardLayoutComponent,
        DashboardScrolledLayoutComponent,
        ReportShowComponent,
        ReportEditComponent,
        ProgramReportEditComponent,
        ProgramReportShowStepComponent
    ],
    imports: [
        CoreModule,
        NgxPageScrollCoreModule,
        SharedModule,
        ComponentsModule,
        AppRoutingModule, // this needed to be declared before all other modules
        DashboardModule,
        AdminDashboardModule,
        LoginModule,
        RegisterModule,
        ComputorModule,
        ProjectModule,
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        MatMenuModule,
        MatListModule,
        MatIconModule,
        MatToolbarModule,
        MatSidenavModule,
        MatButtonModule,
        ResetPasswordModule,
        NgxMatSelectSearchModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            },
        }),
        MatProgressBarModule,
    ],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MAT_MOMENT_DATE_FORMATS
        },
        {
            provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
            useValue: {useUtc: true}
        },
        {
            provide: LOCALE_ID,
            useValue: 'fr-CH'
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'CHF'
        },
        DataCacheService,
        IriUtil,
        UtilsService,
        SessionStateService,
        TenderTypeService,
        TenderModelService,
        InvoiceForm,
        FolderProgramService,
        FolderProgramResolver,
    ],
    exports: [
        TranslateModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
