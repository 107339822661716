import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Report } from '../models/report.model';
import { ReportService } from '../services/report.service';

@Injectable()
export class ReportResolver implements Resolve<Report> {
    constructor(
        private reportService: ReportService,
    ) { }
    /* eslint-disable */
    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<Report> {
        return this.reportService.find(route.paramMap.get('reportId'));
    }
    /* eslint-enable */
}
