import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'default' })
export class DefaultPipe implements PipeTransform {
    private static testEmpty(value: number|string|boolean|null): boolean {
        return '' === value || false === value || null === value || typeof value === 'undefined';
    }

    /**
     * Returns value + suffix if value isn't empty
     */
    public transform(value: number|string|null, defaultValue: number|string = '', suffix: string = null): number|string|boolean {
        if (DefaultPipe.testEmpty(value)) {
            return defaultValue;
        }

        if (suffix) {
            return `${value} ${suffix}`;
        }

        return value;
    }
}
