<!-- Milestones -->
<app-admin-folder-milestone id="section1" [tenderSession]="folderProjectResource.data.tenderSession"
                            [folder]="folderProjectResource" [showingFolder]="true"></app-admin-folder-milestone>

<!-- Project holder -->
<app-card cardTitle="title.project_holder" cardNumber="2">
    <div class="contact">
        <form [formGroup]="folderHolderForm" (keydown.enter)="$event.preventDefault()">
            <div class="row">
                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.company_name' | translate }}"
                               formControlName="companyName"
                               #companyName type="text" autocomplete="off"
                               maxlength="{{ lengthValidators.genString }}">
                        <mat-error
                                *ngIf="folderHolderForm.get('companyName').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.last_name' | translate }}"
                               formControlName="lastName" #lastName
                               type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                        <mat-error *ngIf="folderHolderForm.get('lastName').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.first_name' | translate }}"
                               formControlName="firstName"
                               #firstName type="text" autocomplete="off"
                               maxlength="{{ lengthValidators.genString }}">
                        <mat-error *ngIf="folderHolderForm.get('firstName').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.email' | translate }}" formControlName="email"
                               #email
                               type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                        <mat-error *ngIf="folderHolderForm.get('email').hasError('pattern')">
                            {{ 'validator.email.not_valid' | translate }}
                        </mat-error>
                        <mat-error *ngIf="folderHolderForm.get('email').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.address' | translate }}" formControlName="address"
                               type="text"
                               #address autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                        <mat-error *ngIf="folderHolderForm.get('address').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.city' | translate }}" formControlName="city"
                               type="text" #city
                               autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                        <mat-error *ngIf="folderHolderForm.get('city').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.postal_code' | translate }}"
                               formControlName="postalCode"
                               #postalCode type="text" autocomplete="off"
                               maxlength="{{ lengthValidators.postCode }}">
                        <mat-error
                                *ngIf="folderHolderForm.get('postalCode').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.postCode} }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.mobile_phone' | translate }}"
                               formControlName="mobilePhone"
                               #mobilePhone type="text" autocomplete="off"
                               maxlength="{{ lengthValidators.genString }}">
                        <mat-error *ngIf="folderHolderForm.get('mobilePhone').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.phone' | translate }}" formControlName="phone"
                               #phone
                               type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                        <mat-error *ngIf="folderHolderForm.get('phone').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</app-card>

<app-card cardTitle="title.project_beneficiary" cardNumber="3">
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.company_name' | translate }}</p>
            <p>{{ folderProjectResource.data.folderBeneficiary.companyName | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.last_name' | translate }}</p>
            <p>{{ folderProjectResource.data.folderBeneficiary.lastName | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.first_name' | translate }}</p>
            <p>{{ folderProjectResource.data.folderBeneficiary.firstName | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.email' | translate }}</p>
            <p>{{ folderProjectResource.data.folderBeneficiary.email | default:'-' }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.address' | translate }}</p>
            <p>{{ folderProjectResource.data.folderBeneficiary.address | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.city' | translate }}</p>
            <p>{{ folderProjectResource.data.folderBeneficiary.city | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.postal_code' | translate }}</p>
            <p>{{ folderProjectResource.data.folderBeneficiary.postalCode | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.mobile_phone' | translate }}</p>
            <p>{{ folderProjectResource.data.folderBeneficiary.mobilePhone | default:'-' }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.phone' | translate }}</p>
            <p>{{ folderProjectResource.data.folderBeneficiary.phone | default:'-' }}</p>
        </div>
    </div>
</app-card>

<!-- Measures -->
<app-card cardTitle="title.measures_evolution" cardNumber="4">
    <!--  General evolution -->
    <div class="table-scroll general-measure-wrapper">
        <table class="table table-responsive col-md-12" appTableCellHighlighter>
            <thead class="col-md-12">
            <tr>
                <th class="w-25">{{ '' }}</th>
                <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                    <th class="min-w-120" *ngIf="step['@permissions'].view" rowspan="2">
                        {{ 'workflow.' + step.columnName | translate }}
                    </th>
                </ng-container>
                <ng-container
                        *ngFor="let step of folderProjectResource.reportSteps; let first = first; let last = last; let index = index">
                    <th class="min-w-120 report-header report-first report-last" *ngIf="step['@permissions'].view"
                        [attr.colspan]="countColspan(step.steps)">
                        {{ step['@columnName'] | translate }}
                    </th>
                </ng-container>
                <!--              <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                <!--                <td class="min-w-120" *ngIf="step['@permissions'].view" rowspan="2">-->
                <!--                  {{ 'workflow.' + step.columnName | translate }}-->
                <!--                </td>-->
                <!--              </ng-container>-->
            </tr>
            <tr>
                <th class="w-25">{{ '' }}</th>
                <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                    <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                        <th class="min-w-120 report-header no-border-bottom" *ngIf="step['@permissions'].view"
                            [class.report-first]="first" [class.report-last]="last">
                            {{ 'workflow.' + step['@columnName'] | translate }}
                        </th>
                    </ng-container>
                </ng-container>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th>{{ 'word.average_cost_of_electricity' | translate }}</th>
                <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ step.dataset.cost | default:'-' }} {{ 'word.cts_kwh' | translate }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                    <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                        <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                            [class.report-first]="first" [class.report-last]="last">
                            {{ step.dataset.cost | default:'-' }} {{ 'word.cts_kwh' | translate }}
                        </td>
                    </ng-container>
                </ng-container>
                <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                <!--              <td class="min-w-120" *ngIf="step['@permissions'].view">-->
                <!--                {{ step.dataset.cost | default:'-' }} {{ 'word.cts_kwh' | translate }}-->
                <!--              </td>-->
                <!--            </ng-container>-->
            </tr>
            <tr>
                <th>{{ 'word.total_cost' | translate }}</th>
                <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ step.computedValues.totalCost | currency }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                    <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                        <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                            [class.report-first]="first" [class.report-last]="last">
                            {{ step.computedValues.totalCost | currency }}
                        </td>
                    </ng-container>
                </ng-container>
                <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                <!--              <td class="min-w-120" *ngIf="step['@permissions'].view">-->
                <!--                {{ step.computedValues.totalCost | currency }}-->
                <!--              </td>-->
                <!--            </ng-container>-->
            </tr>
            <tr>
                <th>{{ 'word.maximum_contribution' | translate }}</th>
                <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ step.computedValues.maximumContribution | currency }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                    <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                        <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                            [class.report-first]="first" [class.report-last]="last">
                            {{ step.computedValues.maximumContribution | currency }}
                        </td>
                    </ng-container>
                </ng-container>
                <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                <!--              <td class="min-w-120" *ngIf="step['@permissions'].view">-->
                <!--                {{ step.computedValues.maximumContribution | currency }}-->
                <!--              </td>-->
                <!--            </ng-container>-->
            </tr>
            <tr>
                <th>{{ 'word.requested_contribution' | translate }}</th>
                <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ step.dataset.requestedContribution | currency | default:'-' }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                    <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                        <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                            [class.report-first]="first" [class.report-last]="last">
                            {{ step.dataset.requestedContribution | currency | default:'-' }}
                        </td>
                    </ng-container>
                </ng-container>
                <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                <!--              <td class="min-w-120" *ngIf="step['@permissions'].view">-->
                <!--                {{ step.dataset.requestedContribution | currency | default:'-' }}-->
                <!--              </td>-->
                <!--            </ng-container>-->
            </tr>
            <tr>
                <th>{{ 'word.annual_saving' | translate }}</th>
                <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ step.computedValues.annualSaving | positive | number }} {{ 'word.kwh' | translate }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                    <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                        <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                            [class.report-first]="first" [class.report-last]="last">
                            {{ step.computedValues.annualSaving | positive | number }} {{ 'word.kwh' | translate }}
                        </td>
                    </ng-container>
                </ng-container>
                <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                <!--              <td class="min-w-120" *ngIf="step['@permissions'].view">-->
                <!--                {{ step.computedValues.annualSaving | positive | number }} {{ 'word.kwh' | translate }}-->
                <!--              </td>-->
                <!--            </ng-container>-->
            </tr>
            <tr>
                <th>{{ 'word.total_saving' | translate }}</th>
                <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ step.computedValues.totalSaving | positive | number }} {{ 'word.kwh' | translate }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                    <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                        <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                            [class.report-first]="first" [class.report-last]="last">
                            {{ step.computedValues.totalSaving | positive | number }} {{ 'word.kwh' | translate }}
                        </td>
                    </ng-container>
                </ng-container>
                <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                <!--              <td class="min-w-120" *ngIf="step['@permissions'].view">-->
                <!--                {{ step.computedValues.totalSaving | positive | number }} {{ 'word.kwh' | translate }}-->
                <!--              </td>-->
                <!--            </ng-container>-->
            </tr>
            <tr>
                <th>{{ 'word.percent_saving' | translate }}</th>
                <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ step.computedValues.percentSaving | positive | round:2 }} %
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                    <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                        <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                            [class.report-first]="first" [class.report-last]="last">
                            {{ step.computedValues.percentSaving | positive | round:2 }} %
                        </td>
                    </ng-container>
                </ng-container>
                <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                <!--              <td class="min-w-120" *ngIf="step['@permissions'].view">-->
                <!--                {{ step.computedValues.percentSaving | positive | round:2 }} %-->
                <!--              </td>-->
                <!--            </ng-container>-->
            </tr>
            <tr>
                <th>{{ 'word.payback_without_contribution' | translate }}</th>
                <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ 'word.n_years' | translate:{count: step.computedValues.paybackWithoutContribution | positive | round:2} }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                    <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                        <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                            [class.report-first]="first" [class.report-last]="last">
                            {{ 'word.n_years' | translate:{count: step.computedValues.paybackWithoutContribution | positive | round:2} }}
                        </td>
                    </ng-container>
                </ng-container>
                <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                <!--              <td class="min-w-120" *ngIf="step['@permissions'].view">-->
                <!--                {{ 'word.n_years' | translate:{count: step.computedValues.paybackWithoutContribution | positive | round:2 } }}-->
                <!--              </td>-->
                <!--            </ng-container>-->
            </tr>
            <tr>
                <th>{{ 'word.cost_utility_ratio' | translate }}</th>
                <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ step.computedValues.costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                    <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                        <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                            [class.report-first]="first" [class.report-last]="last">
                            {{ step.computedValues.costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}
                        </td>
                    </ng-container>
                </ng-container>
                <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                <!--              <td class="min-w-120" *ngIf="step['@permissions'].view">-->
                <!--                {{ step.computedValues.costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}-->
                <!--              </td>-->
                <!--            </ng-container>-->
            </tr>
            <tr>
                <th>{{ 'word.bill' | translate }}</th>
                <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                    <td class="blank-case" *ngIf="step['@permissions'].view"></td>
                </ng-container>
                <ng-container *ngFor="let column of folderProjectResource.reportSteps;">
                    <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                        <td class="" *ngIf="step['@permissions'].view" [class.report-first]="first"
                            [class.report-last]="last">
                            {{ isLastViewableReport(column, step) ? (step.computedValues.invoices | currency) : '-' }}
                        </td>
                    </ng-container>
                </ng-container>
                <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                <!--              <td class="blank-case" *ngIf="step['@permissions'].view"></td>-->
                <!--            </ng-container>-->
            </tr>
            <tr class="last-row">
                <th>{{ 'word.balance' | translate }}</th>
                <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                    <td class="blank-case icon show-icon current-step" *ngIf="step['@permissions'].view"
                        [class.edit-icon]="step['@permissions'].edit" (click)="showOrEditFolderStep(step)"></td>
                </ng-container>
                <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                    <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                        <td class="icon show-icon current-step vertical-separator" *ngIf="step['@permissions'].view"
                            [class.edit-icon]="step['@permissions'].edit" [class.report-first]="first"
                            [class.report-last]="last" (click)="showOrEditFolderReport(column, step)">
                            {{ isLastViewableReport(column, step) ? (step.computedValues.balance | currency) : '-' }}
                        </td>
                    </ng-container>
                </ng-container>
                <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                <!--              <td class="blank-case icon show-icon current-step" *ngIf="step['@permissions'].view" [class.edit-icon]="step['@permissions'].edit" (click)="showOrEditFolderStep(step)"></td>-->
                <!--            </ng-container>-->
            </tr>
            </tbody>
        </table>
    </div>

    <!--  General evolution -->
    <!--<div class="measure-wrapper general-measure-wrapper">
        <div class="table-responsive">
            <table class="table col-md-12">
                <thead class="col-md-12">
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let folder of folder.folders">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ 'workflow.' + folder.markingName | translate }}
                        </td>
                    </ng-container>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th>{{ 'word.average_cost_of_electricity' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.folders">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.dataset.cost | toCents | currency | default:'-' }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.total_cost' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.folders">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.totalCost | currency }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.maximum_contribution' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.folders">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.maximumContribution | currency }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.requested_contribution' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.folders">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.dataset.requestedContribution | currency | default:'-' }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.annual_saving' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.folders">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.annualSaving | positive | number }} {{ 'word.kwh' | translate }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.total_saving' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.folders">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.totalSaving | positive | number }} {{ 'word.kwh' | translate }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.percent_saving' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.folders">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.percentSaving | positive | round:2 }} %
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.payback_without_contribution' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.folders">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ 'word.n_years' | translate:{count: folder.computedValues.paybackWithoutContribution | positive | round:2 } }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.cost_utility_ratio' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.folders">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.bill' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.folders">
                        <td class="blank-case" *ngIf="folder['@permissions'].view"></td>
                    </ng-container>
                </tr>
                <tr class="last-row">
                    <th>{{ 'word.balance' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.folders">
                        <td class="blank-case icon show-icon current-step" *ngIf="folder['@permissions'].view" [class.edit-icon]="folder['@permissions'].edit" (click)="showOrEditFolderStep(folder)"></td>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>
    </div>-->
    <!--  END General evolution -->
    <!-- Measures -->
    <div>
        <ng-container *ngFor="let measure of measures; let index = index">
            <h3 class="mb-1">{{ 'word.measure_n' | translate:{count: index + 1} }}</h3>
            <div class="table-scroll">
                <table class="table table-responsive w-100" appTableCellHighlighter>
                    <thead>
                    <tr>
                        <th class="w-25">{{ '' }}</th>
                        <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                            <th class="min-w-120" *ngIf="step['@permissions'].view" rowspan="2">
                                {{ 'workflow.' + step.columnName | translate }}
                            </th>
                        </ng-container>
                        <ng-container *ngFor="let step of folderProjectResource.reportSteps">
                            <th class="min-w-120 report-header report-first report-last"
                                *ngIf="step['@permissions'].view" [attr.colspan]="countColspan(step.steps)">
                                {{ step['@columnName'] }}
                            </th>
                        </ng-container>
                        <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                        <!--              <td class="min-w-120" *ngIf="step['@permissions'].view" rowspan="2">-->
                        <!--                {{ 'workflow.' + step.columnName | translate }}-->
                        <!--              </td>-->
                        <!--            </ng-container>-->
                    </tr>

                    <tr>
                        <th class="w-25">{{ '' }}</th>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <th class="min-w-120 report-header no-border-bottom"
                                    *ngIf="step['@permissions'].view" [class.report-first]="first"
                                    [class.report-last]="last">
                                    {{ 'workflow.' + step['@columnName'] | translate }}
                                </th>
                            </ng-container>
                        </ng-container>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>{{ 'word.technical_orientation' | translate }}</th>
                        <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                <ng-container *ngIf="step.measures[index].technicalOrientation">
                                    {{ step.measures[index].technicalOrientation.name | default:'-' }}
                                </ng-container>
                                <ng-container *ngIf="!step.measures[index].technicalOrientation">
                                    -
                                </ng-container>
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    <ng-container *ngIf="step.measures[index].technicalOrientation">
                                        {{ step.measures[index].technicalOrientation.name | default:'-' }}
                                    </ng-container>
                                    <ng-container *ngIf="!step.measures[index].technicalOrientation">
                                        -
                                    </ng-container>
                                </td>
                            </ng-container>
                        </ng-container>
                        <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                        <!--              <td *ngIf="step['@permissions'].view && step.measures[index]">-->
                        <!--                <ng-container *ngIf="step.measures[index].technicalOrientation">-->
                        <!--                  {{ step.measures[index].technicalOrientation.name | default:'-' }}-->
                        <!--                </ng-container>-->
                        <!--                <ng-container *ngIf="!step.measures[index].technicalOrientation">-->
                        <!--                  - -->
                        <!--                </ng-container>-->
                        <!--              </td>-->
                        <!--            </ng-container>-->
                    </tr>
                    <tr>
                        <th>{{ 'word.description' | translate }}</th>
                        <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                            <td *ngIf="step['@permissions'].view && step.measures[index]"
                                [innerHTML]="step.measures[index].description | nl2br | default:'-'">
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps;">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first"
                                    [class.report-last]="last"
                                    [innerHTML]="step.measures[index].description | nl2br | default:'-'"
                                >
                                    {{ step.measures[index].description | nl2br | default:'-' }}
                                </td>
                            </ng-container>
                        </ng-container>
                        <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                        <!--              <td *ngIf="step['@permissions'].view && step.measures[index]"-->
                        <!--                  [innerHTML]="step.measures[index].description | nl2br | default:'-'">-->
                        <!--              </td>-->
                        <!--            </ng-container>-->
                    </tr>
                    <tr>
                        <th>{{ 'word.duration_of_use' | translate }}</th>
                        <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                {{ 'word.n_years' | translate:{count: step.measures[index].durationOfUse | default:'-'} }}
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps;">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    {{ 'word.n_years' | translate:{count: step.measures[index].durationOfUse | default:'-'} }}
                                </td>
                            </ng-container>
                        </ng-container>
                        <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                        <!--              <td *ngIf="step['@permissions'].view && step.measures[index]">-->
                        <!--                {{ 'word.n_years' | translate:{count: step.measures[index].durationOfUse | default:'-'} }}-->
                        <!--              </td>-->
                        <!--            </ng-container>-->
                    </tr>
                    <tr>
                        <th>{{ 'word.measure_cost' | translate }}</th>
                        <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                {{ step.measures[index].cost | currency | default:'-' }}
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps;">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    {{ step.measures[index].cost | currency | default:'-' }}
                                </td>
                            </ng-container>
                        </ng-container>
                        <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                        <!--              <td *ngIf="step['@permissions'].view && step.measures[index]">-->
                        <!--                {{ step.measures[index].cost | currency | default:'-' }}-->
                        <!--              </td>-->
                        <!--            </ng-container>-->
                    </tr>
                    <tr>
                        <th>{{ 'word.existing_annual_consumption' | translate }}</th>
                        <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                {{ step.measures[index].existingAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps;">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    {{ step.measures[index].existingAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                                </td>
                            </ng-container>
                        </ng-container>
                        <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                        <!--              <td *ngIf="step['@permissions'].view && step.measures[index]">-->
                        <!--                {{ step.measures[index].existingAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}-->
                        <!--              </td>-->
                        <!--            </ng-container>-->
                    </tr>
                    <tr>
                        <th>{{ 'word.future_annual_consumption' | translate }}</th>
                        <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                {{ step.measures[index].futureAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    {{ step.measures[index].futureAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                                </td>
                            </ng-container>
                        </ng-container>
                        <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                        <!--              <td *ngIf="step['@permissions'].view && step.measures[index]">-->
                        <!--                {{ step.measures[index].futureAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}-->
                        <!--              </td>-->
                        <!--            </ng-container>-->
                    </tr>
                    <tr>
                        <th>{{ 'word.annual_saving' | translate }}</th>
                        <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                {{ step.measures[index].annualSavings | positive | number | default: '-': ('word.kwh' | translate) }}
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps;">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    {{ step.measures[index].annualSavings | positive | number | default: '-': ('word.kwh' | translate) }}
                                </td>
                            </ng-container>
                        </ng-container>
                        <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                        <!--              <td *ngIf="step['@permissions'].view && step.measures[index]">-->
                        <!--                {{ step.measures[index].annualSavings | positive | number | default: '-': ('word.kwh' | translate) }}-->
                        <!--              </td>-->
                        <!--            </ng-container>-->
                    </tr>
                    <tr>
                        <th>{{ 'word.total_saving' | translate }}</th>
                        <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                {{ step.measures[index].totalSavings | positive | number |  default: '-': ('word.kwh' | translate) }}
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps;">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    {{ step.measures[index].totalSavings | positive | number |  default: '-': ('word.kwh' | translate) }}
                                </td>
                            </ng-container>
                        </ng-container>
                        <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                        <!--              <td *ngIf="step['@permissions'].view && step.measures[index]">-->
                        <!--                {{ step.measures[index].totalSavings | positive | number |  default: '-': ('word.kwh' | translate) }}-->
                        <!--              </td>-->
                        <!--            </ng-container>-->
                    </tr>
                    <tr>
                        <th>{{ 'word.percent_saving' | translate }}</th>
                        <ng-container *ngFor="let step of folderProjectResource.folderSteps.before">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                {{ step.measures[index].percentSavings | positive | number |  default:'-' }} %
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps;">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    {{ step.measures[index].percentSavings | positive | number |  default:'-' }} %
                                </td>
                            </ng-container>
                        </ng-container>
                        <!--            <ng-container *ngFor="let step of folderProjectResource.folderSteps.after">-->
                        <!--              <td *ngIf="step['@permissions'].view && step.measures[index]">-->
                        <!--                {{ step.measures[index].percentSavings | positive | number |  default:'-' }} %-->
                        <!--              </td>-->
                        <!--            </ng-container>-->
                    </tr>
                    </tbody>
                </table>
                <div *ngIf="isNewMeasuresInReportsWarningEnabled" class="d-flex align-items-center mt-4">
                    <mat-icon class="mr-2" color="warn">warning</mat-icon>
                    {{'word.newly_added_measures_in_reports' | translate}}
                </div>
            </div>
        </ng-container>
        <!--<ng-container *ngFor="let measure of measures; let index = index">
            <div class="measure-wrapper table-responsive mt-4">
                <h3 class="mb-1">{{ 'word.measure_n' | translate:{count: index + 1} }}</h3>

                <table class="table w-100">
                    <thead>
                    <tr>
                        <th class="w-25">{{ '' }}</th>
                        <ng-container *ngFor="let folder of folder.folders">
                            <th class="min-w-120" *ngIf="folder['@permissions'].view">
                                {{ 'workflow.' + folder.markingName | translate }}
                            </th>
                        </ng-container>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{{ 'word.technical_orientation' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.folders">
                            <td *ngIf="folder['@permissions'].view && folder.measures[index]">
                                <ng-container *ngIf="folder.measures[index].datasetMeasure.technicalOrientation">
                                    {{ folder.measures[index].datasetMeasure.technicalOrientation ? folder.measures[index].datasetMeasure.technicalOrientation.name : '-' }}
                                </ng-container>

                                <ng-container *ngIf="!folder.measures[index].datasetMeasure.technicalOrientation">
                                    -
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.description' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.folders">
                            <td *ngIf="folder['@permissions'].view && folder.measures[index]">
                                {{ folder.measures[index].datasetMeasure.description | default:'-' }}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.duration_of_use' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.folders">
                            <td *ngIf="folder['@permissions'].view && folder.measures[index]">
                                {{ 'word.n_years' | translate:{count: folder.measures[index].datasetMeasure.durationOfUse | default:'-'} }}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.average_cost_of_electricity' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.folders">
                            <td *ngIf="folder['@permissions'].view && folder.measures[index]">
                                {{ folder.measures[index].datasetMeasure.cost | currency | default:'-' }}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.maximum_contribution' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.folders">
                            <td *ngIf="folder['@permissions'].view && folder.measures[index]">
                                {{ folder.measures[index].datasetMeasure.maximumContribution | currency | default:'-' }}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.existing_annual_consumption' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.folders">
                            <td *ngIf="folder['@permissions'].view && folder.measures[index]">
                                {{ folder.measures[index].datasetMeasure.existingAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.future_annual_consumption' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.folders">
                            <td *ngIf="folder['@permissions'].view && folder.measures[index]">
                                {{ folder.measures[index].datasetMeasure.futureAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.annual_saving' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.folders">
                            <td *ngIf="folder['@permissions'].view && folder.measures[index]">
                                {{ folder.measures[index].datasetMeasure.annualSavings | positive | number | default: '-': ('word.kwh' | translate) }}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.total_saving' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.folders">
                            <td *ngIf="folder['@permissions'].view && folder.measures[index]">
                                {{ folder.measures[index].datasetMeasure.totalSavings | positive | number |  default: '-': ('word.kwh' | translate) }}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.percent_saving' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.folders">
                            <td *ngIf="folder['@permissions'].view && folder.measures[index]">
                                {{ folder.measures[index].datasetMeasure.percentSavings | positive | number |  default:'-' }} %
                            </td>
                        </ng-container>
                    </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>-->
    </div>
</app-card>

<!-- General -->
<app-card cardTitle="title.general" cardNumber="5" appAdminComponent>
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.project_name' | translate }}</p>
            <p>{{folderProjectResource.data.currentProject.name}}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.folder_language' | translate }}</p>
            <mat-form-field>
                <mat-select [formControl]="assignedLanguageField" [disabled]="!this.hasRoleStaffPkw()">
                    <mat-option *ngFor="let lang of possibleLanguages"
                                [value]="lang.locale">{{ 'word.' + lang.locale | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.first_submission_time' | translate }}</p>
            <p>{{ folderProjectResource.data.submissionDate | localizedDate:'medium' | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.qa_submission_time' | translate }}</p>
            <p>{{ folderProjectResource.data.qaSubmissionDate | localizedDate:'medium' | default:'-' }}</p>
        </div>
    </div>
    <app-admin-managers-selection [managersForm]="managersForm"></app-admin-managers-selection>
</app-card>

<!-- Remarks -->
<app-admin-remark-card id="section6" appAdminComponent cardNumber="6"
                       [folder]="folderProjectResource"></app-admin-remark-card>

<!-- Documents -->
<app-card cardNumber="7" cardTitle="title.documents">
    <app-media-objects-list (savedEvent)="save()" [form]="mediaForm"
                            [folderUuid]="folderProjectResource.data.uuid"></app-media-objects-list>
</app-card>

<app-todo [folder]="folderProjectResource" *appIsGranted="'ROLE_STAFF_PKW'"></app-todo>

<app-sticky-footer *appIsGranted="'ROLE_STAFF_PKW'">
    <div class="w-50">
        <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="save()"
                [loading]="loading.loading$ | async">
            {{ 'action.save' | translate }} <i class="icon-floppy-disk"></i>
        </button>
    </div>
    <div class="w-50 d-flex justify-content-end">
        <button mat-flat-button color="primary" class="align-self-end" (click)="openFolderSettingsDialog()">
            {{ 'word.folder_settings' | translate }} <i class="icon-filter"></i>
        </button>
    </div>
</app-sticky-footer>
