import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable()
export class InvoiceForm {
    constructor(
        private fb: FormBuilder
    ) {}

    public getInvoiceForm(): FormGroup {
        return this.fb.group({
            name: [null, [Validators.required]],
            report: [null, [Validators.required]],
            mediaObject: [null, [Validators.required]]
        });
    }
}
