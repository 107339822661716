import { Component, Input } from '@angular/core';
import { TenderSession } from '../../core/models/tender-session.model';
import { COMPUTOR_TYPE } from '../../core/services/folder.service';

@Component({
    selector: 'app-important-dates-timeline',
    templateUrl: './important-dates-timeline.component.html',
    styleUrls: ['./important-dates-timeline.component.scss']
})
export class ImportantDatesTimelineComponent {
    @Input()
    public tenderSession: TenderSession;

    @Input()
    public type: COMPUTOR_TYPE = 'project';
}
