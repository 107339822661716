import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TodoListComponent } from './todo-list/todo-list.component';
import { DashboardLayoutComponent } from '../../../layouts/dashboard-layout/dashboard-layout.component';
import { AuthGuard } from '../../../core/guards/auth.guard';
import { StaffPkwGuard } from '../../../core/guards/staff-pkw.guard';

const routes: Routes = [
    {
        path: '',
        component: DashboardLayoutComponent,
        children: [
            {
                path: '',
                component: TodoListComponent,
                canActivate: [AuthGuard, StaffPkwGuard],
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TodoRoutingModule { }
