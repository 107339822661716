import { FormGroup } from '@angular/forms';

export function ExportSelectionValidator(controlName: string, matchingControlName: string): (formGroup: FormGroup) => void {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (null === control.value) {
            if (control.errors) {
                delete control.errors.notEmpty;
                if (Object.keys(control.errors).length === 0) {
                    control.setErrors(null);
                }
            }

            return;
        }

        if (null === (control.value as {numberOfItems: number}).numberOfItems && null === matchingControl.value) {
            matchingControl.setErrors({ notEmpty: true });

            return;
        }

        // if it's a selection control, value must greater than 0
        if (0 === (control.value as {numberOfItems: number}).numberOfItems) {
            control.setErrors({ selectionNotNull: true });

            return;
        }

        if (control.errors) {
            delete control.errors.selectionNotNull;
            if (Object.keys(control.errors).length === 0) {
                control.setErrors(null);
            }
        }

        if (matchingControl.errors) {
            delete matchingControl.errors.notEmpty;
            if (Object.keys(matchingControl.errors).length === 0) {
                matchingControl.setErrors(null);
            }
        }
    };
}
