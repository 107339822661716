<div class="row">
    <div class="col-md-8">
      <app-dashboard-project></app-dashboard-project>
      <app-dashboard-program></app-dashboard-program>
    </div>
    <div class="col-md-4">
        <app-card cardTitle="title.about">
            <p [innerHTML]="'dashboard.p0' | translate"></p>
        </app-card>
    </div>
</div>

