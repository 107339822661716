import { Component, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { PageTitleService } from '../../../../core/services/page-title.service';
import { TodoService } from '../../../../core/services/todo.service';
import { TodoDatasource } from '../../../../core/datasources/todo.datasource';
import { IriUtil } from '../../../../core/utils/iri.util';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { BehaviorSubject, fromEvent, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, take, takeUntil, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { AddTodoDialogComponent } from '../../../../shared/add-todo-dialog/add-todo-dialog.component';
import { DateService } from '../../../../core/services/date.service';
import { Todo } from '../../../../core/models/todo.model';
import { environment } from '../../../../../environments/environment';
import { FolderProjectResource } from '../../../../core/models/folder-project-resource.model';
import { FolderProgramResource } from '../../../../core/models/folder-program-resource.model';
import { SelectionModel } from '@angular/cdk/collections';
import { AuthService } from '../../../../core/services/auth.service';
import { I18nService } from '../../../../core/i18n/i18n.service';
import { NotificationService } from '../../../../core/notifications/notification.service';
import { ExportSelectionDialogComponent } from '../../../../shared/export-selection-dialog/export-selection-dialog.component';
import { TenderModel } from '../../../../core/models/tender-model.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-todo-list',
    templateUrl: './todo-list.component.html',
    styleUrls: ['./todo-list.component.scss']
})
export class TodoListComponent implements OnDestroy, AfterViewInit {
    @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
    @ViewChild('input') input: ElementRef;
    @ViewChild(MatSort) sort: MatSort;
    todoDatasource: TodoDatasource;
    selection = new SelectionModel<string>(true, []);

    displayedColumns = ['doneAction', 'select', 'folder', 'description', 'gsManager', 'assinedUser', 'deadline', 'done', 'updatedAt', 'urgentMark', 'actions'];

    viewArchivedTODOs = new BehaviorSubject<boolean>(false);
    viewArchivedTODOs$ = this.viewArchivedTODOs.asObservable();

    today = DateService.getToday();

    public destroySubject: Subject<boolean> = new Subject<boolean>();

    constructor(
        private titleService: PageTitleService,
        private todoService: TodoService,
        private authService: AuthService,
        private i18nService: I18nService,
        private notificationService: NotificationService,
        private router: Router,
        public iriUtil: IriUtil,
        protected dialog: MatDialog,
    ) {
        this.titleService.title('menu.todos');
        this.todoDatasource = new TodoDatasource(todoService);
    }

    ngOnDestroy(): void {
        this.destroySubject.next(true);
        this.destroySubject.complete();
    }

    ngAfterViewInit(): void {
        this.subscribeToSearch();
        this.paginator.page.pipe(
            takeUntil(this.destroySubject)
        ).subscribe(() => this.loadTodosPaginated());

        this.viewArchivedTODOs$.pipe(
            takeUntil(this.destroySubject)
        ).subscribe(() => { // subscribe to checkbox click changes
            this.loadTodosPaginated();
        });
    }

    subscribeToSearch(): void {
        fromEvent(this.input.nativeElement, 'keyup').pipe(
            takeUntil(this.destroySubject),
            debounceTime(environment.searchDebounceTime),
            distinctUntilChanged(),
            tap(() => {
                this.paginator.pageIndex = 0;
                this.loadTodosPaginated();
            })
        ).subscribe();
    }

    sortData($event: Sort): void {
        const inputElement = this.input.nativeElement as HTMLInputElement; // search text input
        this.todoDatasource.loadTODOsPaginated(this.viewArchivedTODOs.getValue(),
            this.paginator.pageIndex + 1, this.paginator.pageSize, true, inputElement.value, $event);
    }

    loadTodosPaginated(): void {
        const inputElement = this.input.nativeElement as HTMLInputElement; // search text input
        this.todoDatasource.loadTODOsPaginated(this.viewArchivedTODOs.getValue(),
            this.paginator.pageIndex + 1, this.paginator.pageSize, true, inputElement.value, this.sort);
    }

    toggleArchivedTodos(): void {
        this.viewArchivedTODOs.next(!this.viewArchivedTODOs.getValue()); // change handled by observable
    }

    archiveTodo(todoIri: string): void {
        this.todoService.archiveTodo(todoIri).pipe(
            take(1)
        ).subscribe(() => {
            this.notificationService.success('toast.todo_archived', true).onAction().pipe(
                take(1)
            ).subscribe(() => {
                this.activateTodo(todoIri);
            });

            this.loadTodosPaginated();
        });
    }

    activateTodo(todoIri: string): void {
        this.todoService.activateTodo(todoIri).pipe(
            take(1)
        ).subscribe(() => {
            this.notificationService.success('toast.todo_unarchived', true).onAction().pipe(
                take(1)
            ).subscribe(() => {
                this.archiveTodo(todoIri);
            });

            this.loadTodosPaginated();
        });
    }

    removeTodo(todoIri: string): void {
        this.todoService.deleteTodo(todoIri).subscribe(() => {
            this.loadTodosPaginated();
        });
    }

    showNewTodoDialog(): void {
        const dialog = this.dialog.open(AddTodoDialogComponent, {
            autoFocus: false,
            width: '500px',
            data: {
                title: 'action.add_new_todo'
            }
        });
        dialog.afterClosed().subscribe((data: { hasSaved: boolean }) => {
            if (data && data.hasSaved) {
                this.loadTodosPaginated();
            }
        });
    }

    checkIfPassed(date: string): boolean {
        const dateObj = DateService.clearDateFromHours(new Date(date));
        return dateObj.getTime() < this.today.getTime();
    }

    checkIfToday(date: string): boolean {
        const dateObj = DateService.clearDateFromHours(new Date(date));
        return dateObj.getTime() === this.today.getTime();
    }

    editTodo(todoIri: string, folder: FolderProjectResource | FolderProgramResource = null): void {
        this.todoService.getTodo(todoIri).subscribe((result: Todo) => {
            const dialog = this.dialog.open(AddTodoDialogComponent, {
                data: {
                    todo: result,
                    folder,
                    title: 'action.edit_todo'
                },
                maxWidth: '500px',
            });
            dialog.afterClosed().subscribe(data => {
                if (data) {
                    this.loadTodosPaginated();
                }
            });
        });
    }

    /** Whether the number of selected elements matches the total number of rows. */
    public isAllPageSelected(): boolean {
        const numSelected = this.selection.selected.filter((e: string) => e.startsWith(this.paginator.pageIndex.toString())).length;

        return numSelected === this.elementsPerPage();
    }

    public masterToggle(): void {
        if (this.isAllPageSelected()) {
            this.deselectPageRows();

            return;
        }

        this.selectPageRows();
    }

    public toggle(iri: string): void {
        this.selection.toggle(this.paginator.pageIndex.toString() + '#' + iri);
    }

    public isSelected(iri: string): boolean {
        return this.selection.isSelected(this.paginator.pageIndex.toString() + '#' + iri);
    }

    public exportSelection(format: string, name: string, computor?: string): void {
        const selectAllTodos = [];
        const irisList = this.isAllPageSelected() ? selectAllTodos : this.selection.selected.map((e: string) => e.substring(e.indexOf('#') + 1));

        this.todoService.exportRequest({
            iris: irisList,
            email: this.authService.getUser().userName,
            locale: this.i18nService.getCurrentLocale(),
            exporterName: name,
            exportFormat: format,
            computorName: computor,
        }).pipe(
            take(1)
        ).subscribe(() => {
            this.notificationService.success('toast.export_request_success');
        });
    }

    public openExportSelectionDialog(): void {
        const dialog = this.dialog.open(ExportSelectionDialogComponent, {
            data: {
                selection: this.selection,
                exporters: {
                    // pdf: [],
                    csv: [
                        {
                            label: 'dialog.export_selection.selection_only',
                            type: 'csv',
                            exporter: 'todo_all_data_csv_exporter',
                            numberOfItems: this.selection.selected.length
                        }, {
                            label: 'dialog.export_selection.all_items',
                            type: 'csv',
                            exporter: 'todo_all_data_csv_exporter',
                            numberOfItems: null
                        },
                    ],
                },
            },
            width: '400px',
            autoFocus: false
        });

        dialog.beforeClosed().subscribe((res: { exporter: { type: string; exporter: string }; computor?: string }) => {
            if (res) {
                this.exportSelection(res.exporter.type, res.exporter.exporter, res.computor);
            }
        });
    }

    public showFolder(folder: { '@id': string; tenderModel: TenderModel }): void {
        const type = `${folder.tenderModel.computorType}s`;
        void this.router.navigateByUrl('/admin/' + type + '/' + IriUtil.extractId(folder['@id']));
    }

    private deselectPageRows(): void {
        this.selection.selected.filter(
            (e: string) => e.startsWith(this.paginator.pageIndex.toString())
        ).forEach((e) => this.selection.deselect(e));
    }

    private elementsPerPage(): number {
        return this.paginator.length < this.paginator.pageSize ? this.paginator.length : this.paginator.pageSize;
    }

    private selectPageRows(): void {
        for (let index = 0; index < this.elementsPerPage(); index++) {
            this.selection.select(
                this.paginator.pageIndex.toString() + '#' + (this.todoDatasource.getSubject().getValue()[index] as Todo)['@id']
            );
        }
    }
}
