import { Injectable, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';


@Injectable()
export class DataCacheService implements OnDestroy {

    // cache TTL default 1 hour
    protected ttl: number = (60 * 60);

    private cachedData: any[] = [];
    private deathOfData: number[] = [];

    constructor(
    ) {
        this.ttl = environment.cacheTTL;
    }

    ngOnDestroy(): void {
        this.clearCache();
    }

    public clearCache(): void {
        this.cachedData = [];
        this.deathOfData = [];
    }

    protected getCache(cacheKey: string): any {
        if (this.dataIsFresh(cacheKey)) {
            return this.cachedData[cacheKey] as Record<any, any>;
        }

        return null;
    }

    protected setCache(cacheKey: string, data: Record<any, any>, customTTL = 0): any {
        const now = moment();
        this.deathOfData[cacheKey] = now.unix() + (customTTL > 0 ? customTTL : this.ttl);
        this.cachedData[cacheKey] = data;

        return this.cachedData[cacheKey] as Record<any, any>;
    }

    private dataIsFresh(cacheKey: string): boolean {
        const now = moment();
        if (typeof this.deathOfData[cacheKey] === 'undefined') {
            return false;
        }

        return (this.deathOfData[cacheKey] > now.unix());
    }
}
