<app-card [cardNumber]="cardNumber" cardTitle="title.remarks">
    <ng-container *ngIf="remarkDatasource.totalItems !== 0; else noItem">
        <div class="row">
            <table mat-table [dataSource]="remarkDatasource" class="w-100">
                <ng-container matColumnDef="text">
                    <th mat-header-cell *matHeaderCellDef> {{ 'word.description' | translate }} </th>
                    <td mat-cell *matCellDef="let element" [innerHTML]="element.text | nl2br"></td>
                </ng-container>
                <ng-container matColumnDef="createdAt">
                    <th mat-header-cell *matHeaderCellDef> {{ 'word.date' | translate }} </th>
                    <td mat-cell *matCellDef="let element">{{element.createdAt | date}}</td>
                </ng-container>
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef> {{ 'word.user' | translate }} </th>
                    <td mat-cell *matCellDef="let element">{{element.user?.firstname}} {{element.user?.lastname}}</td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef class="text-right"></th>
                    <td mat-cell *matCellDef="let element" class="text-right">
                        <ng-container *appIsGranted="'ROLE_STAFF_PKW'">
                            <button mat-icon-button
                                    (click)="editRemark(element)"
                                    [matTooltip]="'action.edit' | translate">
                                <mat-icon>edit_outline</mat-icon>
                            </button>
                            <button mat-icon-button
                                    appConfirmDialog
                                    (confirmClick)="removeRemark(element['@id'])"
                                    [matTooltip]="'action.delete' | translate">
                                <mat-icon>delete_outline</mat-icon>
                            </button>
                        </ng-container>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedRemarksColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedRemarksColumns;"></tr>
            </table>
        </div>
    </ng-container>
    <ng-template #noItem>
        <p>{{ 'message.no_remark' | translate }}</p>
    </ng-template>
    <button mat-mini-fab class="card-content-action" *appIsGranted="'ROLE_STAFF_PKW'" (click)="showAddRemark()">
        <mat-icon>add</mat-icon>
    </button>
</app-card>
