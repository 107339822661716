import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-requirements-control',
    templateUrl: './requirements-control.component.html',
    styleUrls: ['./requirements-control.component.scss']
})
export class RequirementsControlComponent {
    @Input()
    form: FormGroup;

    @Input()
    computorName: string;

    @Input()
    sectionNumber: number;

    @Input()
    public numberOfBoxes = 4;

}
