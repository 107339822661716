<app-card cardTitle="title.filters">
    <div class="mt-4">
        <mat-chip-list aria-label="Tags selection">
            <mat-chip class="pointer" *ngFor="let filter of availableFilters" (click)="editFilter(filter)">
                {{ filter.name }}
                <mat-icon matChipRemove appConfirmDialog (confirmClick)="deleteFilter(filter['@id'])">cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
        <div *ngIf="!availableFilters.length">
            {{'word.no_element' | translate}}
        </div>
    </div>
</app-card>
