import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FolderStatusService } from '../../core/services/folder-status.service';
import { FolderService } from '../../core/services/folder.service';
import { FolderProjectResource } from '../../core/models/folder-project-resource.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FolderProgramResource } from '../../core/models/folder-program-resource.model';

@Component({
    selector: 'app-folder-status',
    templateUrl: './folder-status.component.html',
    styleUrls: ['./folder-status.component.scss']
})
export class FolderStatusComponent implements OnInit, OnDestroy {
    @Input()
    public state: string;

    @Input()
    public closingReason = null; // defined if completed marking

    public marking = 'draft';

    public destroySubject: Subject<boolean> = new Subject<boolean>();

    constructor(
        private folderStatusService: FolderStatusService,
        private folderService: FolderService
    ) { }

    ngOnInit(): void {
        if (this.state) {
            this.marking = this.state;
            return;
        }

        this.folderStatusService.folderStatusChanged.pipe(takeUntil(this.destroySubject)).subscribe((marking: string) => {
            this.marking = marking;
        });

        this.folderService.currentProjectOrProgram$.pipe(takeUntil(this.destroySubject)).subscribe(folder => {
            this.setMarking(folder);
        });
    }

    public ngOnDestroy(): void {
        this.destroySubject.next(true);
        this.destroySubject.complete();
    }

    setMarking(folder: FolderProjectResource|FolderProgramResource): void {
        if (!folder) {
            return;
        }

        if (this.folderService.getReportResource()) {
            this.marking = this.folderService.getReportResource().data.marking;
            return;
        }

        this.marking = folder.data.marking;
    }

}
