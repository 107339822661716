<app-card cardTitle="title.unused_tags">
    <div class="mt-4">
        <mat-chip-list aria-label="Tags selection">
            <mat-chip *ngFor="let tag of availableTags">{{ tag.name }}
                <mat-icon matChipRemove appConfirmDialog (confirmClick)="deleteTag(tag['@id'])">cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
        <div *ngIf="!availableTags.length">
            {{'word.no_element' | translate}}
        </div>
    </div>
    <div class="row d-flex align-items-end justify-content-start">
        <mat-form-field class="mt-4 col-md-11">
            <mat-label>{{ 'action.add_new_tag' | translate }}</mat-label>
            <input (keyup.enter)="addTag()" matInput [formControl]="newTagField">
        </mat-form-field>
        <div class="col-md-1 d-flex align-items-end">
            <button mat-mini-fab (click)="addTag()" appMatButtonLoading [loading]="createButtonLoading">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>
</app-card>
