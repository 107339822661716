import { Injectable } from '@angular/core';
import { AccessDecisionManager } from './access-decision-manager';
import { AuthService } from '../services/auth.service';
import { VoterInterface } from './voter-interface';

@Injectable()
export class AuthorizationChecker {
    private accessDecisionManager: AccessDecisionManager;
    private authService: AuthService;

    constructor(accessDecisionManager: AccessDecisionManager, authService: AuthService) {
        this.accessDecisionManager = accessDecisionManager;
        this.authService = authService;
    }

    /**
     * Checks if the attributes are granted against the current user and optionally supplied subject.
     *
     * @return bool
     */
    public checkIsGranted(attributes: string|string[], subject: Record<any, unknown> = null): boolean {
        if (!Array.isArray(attributes)) {
            attributes = [attributes];
        }

        return this.accessDecisionManager.decide(this.authService.getUser(), attributes, subject);
    }

    public denyAccessUnlessGranted(attributes: string|string[], subject: Record<any, unknown> = null): void {
        if (!this.checkIsGranted(attributes, subject)) {
            throw new Error('You are not allowed to access this resource.');
        }
    }

    public addVoter(voter: VoterInterface): void {
        this.accessDecisionManager.addVoter(voter);
    }
}
