import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
    selector: '[appScrollTracker]'
})
export class ScrollTrackerDirective {
    @Output() public scrolled = new EventEmitter<any>();

    @HostListener('scroll', ['$event'])
    public onScroll(event: Event): void {
        const tracker = event.target as Element;
        let endReached = false;
        const limit = tracker.scrollHeight - tracker.clientHeight;

        if (tracker.scrollTop === limit) {
            endReached = true;
        }

        this.scrolled.emit({
            pos: tracker.scrollTop,
            endReached
        });
    }
}
