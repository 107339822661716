import {
    ComponentFactory,
    ComponentFactoryResolver, ComponentRef,
    Directive, HostBinding, HostListener,
    Input,
    OnChanges,
    Renderer2,
    SimpleChanges,
    ViewContainerRef
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { ThemePalette } from '@angular/material/core';
import { LoadingService } from '../services/loading.service';

@Directive({
    selector: '[appMatButtonLoading]'
})
export class MatButtonLoadingDirective implements OnChanges {
    @Input()
    loading = false;

    @Input()
    disabled = false;

    @Input()
    color: ThemePalette;

    // some forms are triggered by <enter> key, so not clicked. Can force style with this.
    @Input()
    forceHasBeenClicked = false;

    // targets the native button disabled attribute (not the MatButton one)
    @HostBinding('disabled')
    private isDisabled: boolean;

    // is it loading because others are loading or because it's the one clicked ?
    // Show spinner only on the one we clicked
    hasBeenClicked = false;

    private readonly spinnerFactory: ComponentFactory<MatProgressSpinner>;
    private spinner: ComponentRef<MatProgressSpinner>;

    constructor(
        private matButton: MatButton,
        private componentFactoryResolver: ComponentFactoryResolver,
        private viewContainerRef: ViewContainerRef,
        private renderer: Renderer2,
        private loadingService: LoadingService
    ) {
        this.spinnerFactory = this.componentFactoryResolver.resolveComponentFactory(MatProgressSpinner);
    }

    @HostListener('click')
    onClick(): void {
        this.hasBeenClicked = true;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.loading) {
            return;
        }

        if (changes.loading.currentValue) {
            this.isDisabled = true;
            if (this.hasBeenClicked || this.forceHasBeenClicked) { // show spinner if it's the one we clicked
                this.createSpinner();
                this.hasBeenClicked = false;
            }
        } else if (!changes.loading.firstChange) {
            this.isDisabled = this.disabled;
            this.destroySpinner();

            this.loadingService.stopLoading();
        }
    }

    private createSpinner(): void {
        (this.matButton._elementRef.nativeElement as HTMLElement).classList.add('mat-loading');
        if (!this.spinner) {
            this.spinner = this.viewContainerRef.createComponent(this.spinnerFactory);
            this.spinner.instance.color = this.color;
            this.spinner.instance.diameter = 20;
            this.spinner.instance.mode = 'indeterminate';
            this.renderer.appendChild(this.matButton._elementRef.nativeElement, this.spinner.instance._elementRef.nativeElement);
        }
    }

    private destroySpinner(): void {
        (this.matButton._elementRef.nativeElement as HTMLElement).classList.remove('mat-loading');
        if (this.spinner) {
            this.spinner.destroy();
            this.spinner = null;
        }
    }
}
