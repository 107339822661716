import { Component, Input } from '@angular/core';
import { AnalysisCost } from '../../../../../core/models/fixCost.model';
import { ProgramComputedValues } from '../../../../../core/models/computed-values.model';
import {ChildProgram} from '../../../../../core/models/child-folder.model';

@Component({
    selector: 'app-analysis-costs',
    templateUrl: './analysis-costs.component.html',
    styleUrls: ['./analysis-costs.component.scss']
})
export class AnalysisCostsComponent {
    @Input()
    public analysisCosts: AnalysisCost;

    @Input()
    public computedValues: ProgramComputedValues;

    @Input()
    showingReport: ChildProgram;
}
