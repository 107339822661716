import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-download-and-send-explanation-dialog',
    templateUrl: './download-and-send-explanation-dialog.component.html',
    styleUrls: ['./download-and-send-explanation-dialog.component.scss']
})
export class DownloadAndSendExplanationDialogComponent {

    @Output()
    public downloadButtonClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
    public downloadLoading = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {title: string; content: string; download: string}
    ) { }

    public download(): void {
        this.downloadLoading = true;
        this.downloadButtonClicked.emit(true);
    }
}
