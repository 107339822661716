import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Sort } from '@angular/material/sort';
import { HttpParams } from '@angular/common/http';
import { IriUtil } from '../utils/iri.util';
import { Remark } from '../models/remark.model';

@Injectable({
    providedIn: 'root'
})
export class RemarkService {

    constructor(
        private api: ApiService,
    ) { }

    createNewRemark(remark: string, folderId: number): Observable<any> {
        const body = {
            folder: '/folders/' + folderId.toString(),
            text: remark
        } as Remark;
        return this.api.post('/remarks', body);
    }

    getRemarkList(folderId: string): Observable<any> {
        const params = {
            'folder.id': folderId,
            pagination: false,
        };
        return this.api.get('/remarks/list', { params });
    }

    getRemarksPaginated(pageNumber = 1,
        itemsPerPage = Number(environment.itemsPerPage),
        search = '',
        sort?: Sort): Observable<any> {
        let params = new HttpParams()
            .set('page', pageNumber.toString())
            .set('itemsPerPage', itemsPerPage.toString())
            .set('q', search)
            ;
        if (sort) {
            params = params.append('order[' + sort.active + ']', sort.direction);
        }
        return this.api.get('/remarks/list', { params });
    }

    deleteRemark(remarkId: string): Observable<any> {
        if (IriUtil.checkIfIri(remarkId)) {
            return this.api.delete(remarkId); // iri passed instead of creating it
        }
        return this.api.delete(`/remarks/${remarkId}`);
    }

    getRemark(remarkIri: string): Observable<any> {
        return this.api.get(remarkIri);
    }

    patchRemark(remark: Remark): Observable<any> {
        return this.api.patch(remark['@id'], remark as Record<any, any>);
    }

    exportRequest(body: Record<any, unknown>): Observable<void> {
        // select all remarks
        if ((body.iris as any[]).length === 0) {
            return this.api.post('/remarks/export', { ...body, iris: null }) as Observable<void>;
        }

        return this.api.post('/remarks/export', body) as Observable<void>;
    }
}
