<app-card cardTitle="title.summary">
    <div class="dataset">
        <div class="indicators-row d-flex justify-content-between align-items-center">
            <div class="indicator-box important-indicator d-flex align-items-center">
                <div class="icon">
                    <i class="icon-chart"></i>
                </div>
                <div class="indicator">
                    <p>{{ 'word.cost_utility_ratio' | translate }}</p>
                    <p>{{ computedValues.costUtilityRatio | positive | round:2 | default:'-' }} {{ 'word.cts_kwh' | translate }}</p>
                </div>
            </div>
            <div class="indicator-box d-flex align-items-center">
                <div class="icon">
                    <i class="icon-save-time"></i>
                </div>
                <div class="indicator">
                    <p>{{ 'word.total_saving' | translate }}</p>
                    <p>{{ computedValues.totalSaving | positive  | number | default: '-': ('word.kwh' | translate) }}</p>
                </div>
            </div>
        </div>

        <div class="indicators-row d-flex justify-content-between align-items-center">
            <div class="indicator-box d-flex align-items-center">
                <div class="icon">
                    <i class="icon-management"></i>
                </div>
                <div class="indicator">
                    <p>{{ 'word.total_cost' | translate }}</p>
                    <p>{{ computedValues.totalCost | round | currency:'CHF':'symbol':'0.0-2' | default:'-'  }}</p>
                </div>
            </div>
            <div class="indicator-box d-flex align-items-center">
                <div class="icon shorticon">
                    <i class="icon-loss"></i>
                </div>
                <div class="indicator">
                    <p>{{ 'word.payback_without_contribution' | translate }}</p>
                    <p>{{ 'word.n_years' | translate:{count: computedValues.paybackWithoutContribution | round | default: '-'} }}</p>
                </div>
            </div>
        </div>

        <div class="indicators-row d-flex justify-content-between align-items-center">
            <div class="indicator-box d-flex align-items-center">
                <div class="icon">
                    <i class="icon-stats"></i>
                </div>
                <div class="indicator">
                    <p>{{ 'word.maximum_contribution' | translate }}</p>
                    <p>{{ computedValues.maximumContribution | round | currency:'CHF':'symbol':'0.0-2' | default:'-'  }}</p>
                </div>
            </div>
            <div class="indicator-box d-flex align-items-center">
                <div class="icon shorticon">
                    <i class="icon-revenue-up"></i>
                </div>
                <div class="indicator">
                    <p>{{ 'word.payback_with_contribution' | translate }}</p>
                    <p>{{ 'word.n_years' | translate:{count: computedValues.paybackWithContribution | round | default: '-'} }}</p>
                </div>
            </div>
        </div>

        <div class="indicators-row d-flex justify-content-between align-items-center">
            <div class="indicator-box d-flex align-items-center">
                <div class="icon shorticon">
                    <i class="icon-revenue"></i>
                </div>
                <div class="indicator">
                    <p>{{ 'word.requested_contribution' | translate }}</p>
                    <p>{{ currentDataset.requestedContribution | round | currency:'CHF':'symbol':'1.0' | default:'-' }}</p>
                </div>
            </div>
            <div class="indicator-box d-flex align-items-center">
                <div class="icon shorticon">
                    <i class="icon-power"></i>
                </div>
                <div class="indicator">
                    <p>{{ 'word.percent_saving' | translate }}</p>
                    <p>{{ computedValues.percentSaving | positive | round:2 }} %</p>
                </div>
            </div>
        </div>

        <div class="indicators-row d-flex justify-content-between align-items-center">
            <div class="indicator-box d-flex align-items-center">
                <div class="icon shorticon">
                    <i class="icon-revenue"></i>
                </div>
                <div class="indicator">
                    <p>{{ 'word.subsidy_rate' | translate }}</p>
                    <p>{{ computedValues.subsidyRate | round:2 | positive }} %</p>
                </div>
            </div>
        </div>
    </div>
</app-card>
