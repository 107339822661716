import { Injectable } from '@angular/core';
import { VoterInterface, VoterInterfaceConstants } from './voter-interface';
import { RoleVoter } from './voter/role.voter';
import { User } from '../models/user.model';

@Injectable()
export class AccessDecisionManager {
    private voters: VoterInterface[] = [];
    private allowIfAllAbstainDecisions = false;

    constructor(
        private roleVoter: RoleVoter,
    ) {
        this.addVoter(roleVoter);
    }

    public addVoter(voter: VoterInterface): void {
        this.voters.push(voter);
    }

    public decide(currentUser: User, attributes: string[], object: Record<any, unknown> = null): boolean {
        return this.decideAffirmative(currentUser, attributes, object);
    }

    /**
     * Grants access if any voter returns an affirmative response.
     *
     * If all voters abstained from voting, the decision will be based on the
     * allowIfAllAbstainDecisions property value (defaults to false).
     */
    private decideAffirmative(currentUser: User, attributes: string[], object: Record<any, unknown> = null): boolean {
        let deny = 0;
        for (const voter of this.voters) {
            const result = voter.vote(currentUser, attributes, object);

            switch (result) {
            case VoterInterfaceConstants.ACCESS_GRANTED:
                return true;

            case VoterInterfaceConstants.ACCESS_DENIED:
                ++deny;

                break;

            default:
                break;
            }
        }

        if (deny > 0) {
            return false;
        }

        return this.allowIfAllAbstainDecisions;
    }

    /**
     * Grants access if only grant (or abstain) votes were received.
     *
     * If all voters abstained from voting, the decision will be based on the
     * allowIfAllAbstainDecisions property value (defaults to false).
     */
    private decideUnanimous(currentUser: User, attributes: string|string[], object: Record<any, unknown> = null): boolean {
        let grant = 0;
        for (const voter of this.voters) {
            for (const attribute of attributes) {
                const result = voter.vote(currentUser, [attribute], object);

                switch (result) {
                case VoterInterfaceConstants.ACCESS_GRANTED:
                    ++grant;
                    break;

                case VoterInterfaceConstants.ACCESS_DENIED:
                    return false;

                default:
                    break;
                }
            }
        }

        // no deny votes
        if (grant > 0) {
            return true;
        }

        return this.allowIfAllAbstainDecisions;
    }
}
