import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FolderProjectResource } from '../../core/models/folder-project-resource.model';
import { UserService } from '../../core/services/user.service';
import { User } from '../../core/models/user.model';
import { environment } from '../../../environments/environment';
import { Paginator } from '../../core/models/paginator.model';
import { FormControl } from '@angular/forms';
import { ArrayUtil } from '../../core/utils/array.util';
import { TenderSession } from '../../core/models/tender-session.model';
import { TenderSessionService } from '../../core/services/tender-session.service';
import { HttpParams } from '@angular/common/http';
import { FolderService } from '../../core/services/folder.service';
import { Router } from '@angular/router';
import { MarkingService } from '../../core/services/marking.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FolderProgramResource } from '../../core/models/folder-program-resource.model';
import { LoadingService } from '../../core/services/loading.service';

@Component({
    selector: 'app-folder-settings-dialog',
    templateUrl: './folder-settings-dialog.component.html',
    styleUrls: ['./folder-settings-dialog.component.scss']
})
export class FolderSettingsDialogComponent implements OnInit, OnDestroy {
    public allAvailableUsers: User[] = [];
    public showedUsers: User[] = [];

    public userCtrl = new FormControl(null);
    public userSearch = new FormControl(null);

    public availableTenderSessions: TenderSession[] = [];
    tenderSessionCtrl = new FormControl(null);

    public destroySubject: Subject<boolean> = new Subject<boolean>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {folder: FolderProjectResource|FolderProgramResource},
        private user: UserService,
        private tenderSession: TenderSessionService,
        private folder: FolderService,
        private router: Router,
        private dialogRef: MatDialogRef<FolderSettingsDialogComponent>,
        public loading: LoadingService
    ) {
        this.getUsers();
        this.getTenderSessions();
        this.subscribeToSearchUser();
        this.disableTenderSessionIfNotEditable();
    }

    ngOnInit(): void {
        this.userCtrl.setValue(this.data.folder.data.owner['@id']);
        this.tenderSessionCtrl.setValue(this.data.folder.data.tenderSession['@id']);
    }

    ngOnDestroy(): void {
        this.destroySubject.next(true);
        this.destroySubject.complete();
    }

    getUsers(): void {
        this.user.getUserListByRole(environment.userRole, 'false').subscribe((result: Paginator) => {
            this.allAvailableUsers = result['hydra:member'] as User[];
            this.showedUsers = this.allAvailableUsers;
        });
    }

    getTenderSessions(): void {
        let params = new HttpParams().set('tenderModel.computorType', this.data.folder.data.tenderModel.computorType);
        params = params.append('tenderModel.computerName', this.data.folder.data.tenderModel.computerName);
        this.tenderSession.get(params).subscribe((result: Paginator) => {
            this.availableTenderSessions = result['hydra:member'] as TenderSession[];
        });
    }

    disableTenderSessionIfNotEditable(): void {
        if (this.data.folder.data.marking !== MarkingService.FOLDER_INITIAL_PLACE) {
            this.tenderSessionCtrl.disable();
        }
    }

    subscribeToSearchUser(): void {
        this.userSearch.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(search => {
            this.showedUsers = this.searchForUsers(this.allAvailableUsers, search);
        });
    }

    searchForUsers(fullUsersArray: User[], value: string): User[] {
        if (!value || value === '') {
            return fullUsersArray;
        }
        return ArrayUtil.filterObjectArray(value, fullUsersArray, ['firstname', 'lastname', 'email']) as User[];
    }

    save(): void {
        const settings = {
            tenderSession: this.tenderSessionCtrl.value as string,
            owner: this.userCtrl.value as string
        };
        this.folder.assignSettings(String(this.data.folder.data.id), settings).subscribe(() => {
            this.dialogRef.close({
                saved: true
            });
        });
    }

    deleteFolder(): void {
        const folderIri = '/folders/' + String(this.data.folder.data.id);
        this.folder.delete(folderIri).subscribe(() => {
            this.dialogRef.close();
            void this.router.navigateByUrl('/admin/projects');
        });
    }

    get currentName(): string {
        if ('currentProject' in this.data.folder.data) {
            return this.data.folder.data.currentProject.name;
        }

        if ('currentProgram' in this.data.folder.data) {
            return this.data.folder.data.currentProgram.name;
        }
    }
}
