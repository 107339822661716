import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FIX_COST_TYPE_ACCOMPANIMENT, FIX_COST_TYPE_MANAGEMENT } from '../../../core/models/fixCost.model';
import { ProgramForm } from './programForm';
import { ProgramCalculationResults } from '../services/program-calculator.service';

@Injectable({
    providedIn: 'root'
})
export class ProgramReportForm {
    public computedResume: ProgramCalculationResults = {
        costUtilityRatio: 0,
        annualSaving: 0,
        maximumContribution: 0,
        measures: [],
        fixCosts: [],
        paybackWithoutContribution: 0,
        paybackWithContribution: 0,
        subsidyRate: 0,
        costCtskwh: 0,
        totalSaving: 0,
        requestedContribution: 0,
        totalCost: 0,
        percentSaving: 0,
        summary: null
    };

    public lengthValidators = this.programForm.lengthValidators;

    public createMeasureGroup = this.programForm.createMeasureGroup;
    public getDatasetFormGroup = this.programForm.getDatasetFormGroup;
    public createFixCostGroup = this.programForm.createFixCostGroup;

    constructor(
        private programForm: ProgramForm,
        private fb: FormBuilder
    ) { }

    public getReportForm(): FormGroup {
        return this.fb.group({
            '@id': null,
            id: null,
            currentProgram: this.fb.group({
                '@id': null,
                dataset: this.getDatasetFormGroup(),
                measures: this.fb.array([
                    this.createMeasureGroup()
                ]),
                fixCosts: this.fb.array([
                    this.createFixCostGroup('general', FIX_COST_TYPE_MANAGEMENT, false),
                    this.createFixCostGroup('folder', FIX_COST_TYPE_MANAGEMENT, false),
                    this.createFixCostGroup('communication', FIX_COST_TYPE_ACCOMPANIMENT, false),
                    this.createFixCostGroup('training', FIX_COST_TYPE_ACCOMPANIMENT, false),
                    this.createFixCostGroup('advices', FIX_COST_TYPE_ACCOMPANIMENT, false),
                    this.createFixCostGroup('tools', FIX_COST_TYPE_ACCOMPANIMENT, false),
                    this.createFixCostGroup('monitoring', FIX_COST_TYPE_ACCOMPANIMENT, false),
                    this.createFixCostGroup('other1', FIX_COST_TYPE_ACCOMPANIMENT, false),
                    this.createFixCostGroup('other2', FIX_COST_TYPE_ACCOMPANIMENT, false)
                ]),
                analysisCosts: this.fb.group({
                    '@id': [null],
                    units: [null],
                    unitPrice: [null],
                    requestedContribution: [null], // per unit
                    totalRequestedContribution: [null],
                    subsidyRate: [null],
                    totalCost: [null]
                })
            }),
            currentReport: this.fb.group({
                '@id': null,
                reportMeasures: this.fb.array([
                    this.createMeasureGroup()
                ]),
                dataset: this.getDatasetFormGroup(),
                mediaObjects: this.fb.array([]),
                fixCosts: this.fb.array([
                    this.createFixCostGroup('general', FIX_COST_TYPE_MANAGEMENT, false),
                    this.createFixCostGroup('folder', FIX_COST_TYPE_MANAGEMENT, false),
                    this.createFixCostGroup('communication', FIX_COST_TYPE_ACCOMPANIMENT, false),
                    this.createFixCostGroup('training', FIX_COST_TYPE_ACCOMPANIMENT, false),
                    this.createFixCostGroup('advices', FIX_COST_TYPE_ACCOMPANIMENT, false),
                    this.createFixCostGroup('tools', FIX_COST_TYPE_ACCOMPANIMENT, false),
                    this.createFixCostGroup('monitoring', FIX_COST_TYPE_ACCOMPANIMENT, false),
                    this.createFixCostGroup('other1', FIX_COST_TYPE_ACCOMPANIMENT, false),
                    this.createFixCostGroup('other2', FIX_COST_TYPE_ACCOMPANIMENT, false)
                ]),
                analysisCosts: this.fb.group({
                    '@id': [null],
                    units: [null],
                    unitPrice: [null],
                    requestedContribution: [null], // per unit
                    totalRequestedContribution: [null],
                    subsidyRate: [null],
                    totalCost: [null],
                    balance: [null]
                })
            }),
            // following properties are used in validation only
            analysisMediaObjects: this.fb.array([]),
            analysis: this.fb.group({
                decision: [null, []],
                comment: [null, []],
            }),
        });
    }
}
