import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizationChecker } from '../authorization-checker';

/**
 * <div *appIsNotGranted="ROLE_COACH()"></div>
 * <div *appIsNotGranted="[ROLE_COACH()]"></div>
 * <div *appIsNotGranted="'edit'; subject mySubject"></div>
 * <div *appIsNotGranted="['edit', 'delete']; subject mySubject"></div>
 */
@Directive({
    selector: '[appIsNotGranted]'
})
export class IsNotGrantedDirective implements OnInit {
    private attributes: any = [];

    private subject: any = null;

    private exceptions: string|string[] = [];

    @Input()
    set appIsNotGranted(attr: string|string[]) {
        this.attributes = attr;
    }

    @Input()
    set appIsNotGrantedSubject(sub: Record<any, unknown>) {
        this.subject = sub;
    }

    @Input()
    set appIsNotGrantedExceptions(sub: string|string[]) {
        this.exceptions = sub;
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authorizationChecker: AuthorizationChecker,
    ) { }

    public ngOnInit(): void {
        if (!this.authorizationChecker.checkIsGranted(this.attributes, this.subject) ||
            this.authorizationChecker.checkIsGranted(this.exceptions, this.subject)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
