import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable()
@Pipe({
    name: 'reference',
    pure: false, // required to update the value when the promise is resolved
})
export class ReferencePipe implements PipeTransform {

    public transform(value: string): string {
        if (typeof value !== 'string') {
            return value;
        }

        return value.split('-').pop();
    }
}
