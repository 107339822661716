import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { ComputorFactory } from '../../../../computors/computor.factory';
import { PageTitleService } from '../../../../core/services/page-title.service';
import { ActivatedRoute } from '@angular/router';
import { TenderSession } from '../../../../core/models/tender-session.model';
import { FolderProjectResource } from '../../../../core/models/folder-project-resource.model';
import { DirtyFormWarnableComponent } from '../../../../components/form-component/dirty-form.component';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectComponent extends DirtyFormWarnableComponent implements AfterViewInit, AfterViewChecked {
    @ViewChild('computorComponentRef', {
        read: ViewContainerRef
    })
    public viewContainerRef: ViewContainerRef;

    public tenderSession: TenderSession;
    public folderProject: FolderProjectResource = null;

    public form: FormGroup; // retrieved via getForm

    constructor(
        private pageTitleService: PageTitleService,
        private cdr: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        @Inject(ComputorFactory) private factory: ComputorFactory,
    ) {
        super();
        pageTitleService.title(''); // needed to hide title and show breadcrumb. => find a better way to do this.
        this.tenderSession = this.activatedRoute.parent.snapshot.data.tenderSession as TenderSession;
        this.folderProject = this.activatedRoute.snapshot.data.folderProject as FolderProjectResource;
    }

    public ngAfterViewInit(): void {
        const computorComponent = this.factory.loadComputorComponent(this.viewContainerRef, this.tenderSession.tenderModel.computerName, 'ProjectComponent');
        computorComponent.setTenderSession(this.tenderSession);
        computorComponent.setFolderProject(this.folderProject);
        this.form = computorComponent.form; // when create new computorComponent -> call the form 'form'.
    }

    public ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }

    canLeave(): boolean {
        return !this.form.dirty;
    }
}
