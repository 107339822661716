import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { FolderProjectResource } from '../models/folder-project-resource.model';
import { map } from 'rxjs/operators';
import { HttpParamUtil } from '../utils/http-param.util';


@Injectable()
export class FolderProjectService {

    constructor(
        private apiService: ApiService
    ) {
    }

    /**
     * Save a folder project-form
     */
    public save(body: Record<any, unknown>, type: string, computor: string, isSubmitting = false): Observable<FolderProjectResource> {
        const params = new HttpParams()
            .set('type', type)
            .set('computor', computor)
            .set('submitting', String(isSubmitting));

        return this.apiService.post('/folder_project_resources', body, {params}) as Observable<FolderProjectResource>;
    }

    /**
     * Save a folder project-form
     */
    public check(body: Record<any, unknown>, type: string, computor: string): Observable<FolderProjectResource> {
        const params = new HttpParams()
            .set('type', type)
            .set('computor', computor)
            .set('checking', String(true));

        return this.apiService.post('/folder_project_resources', body, {params}) as Observable<FolderProjectResource>;
    }

    /**
     * Submit a folder project-form => change his state
     */
    public submit(id: string, body: Record<any, unknown>): Observable<FolderProjectResource> {
        return this.apiService.patch(id + '/submit', body) as Observable<FolderProjectResource>;
    }

    /**
     * Print a folder object resource
     */
    public pdf(objectId: string, marking: string, exporterName: string): Observable<HttpResponse<Blob>> {
        const params = new HttpParams()
            .set('marking', marking)
            .set('exporter_name', exporterName)
      ;

        return this.apiService.get('/folder_project_resources/' + objectId + '/pdf', {
            params,
            observe: 'response',
            responseType: 'blob' as 'json'
        }) as Observable<HttpResponse<Blob>>;
    }

    /* public pdf(folder: number, type: string, computor: string): Observable<HttpResponse<Blob>> {
        const params = new HttpParams()
            .set('type', type)
            .set('computor', computor)
            .set('folder', String(folder));

        return this.apiService.get('/folder_project_resources/pdf', {
            params,
            observe: 'response',
            responseType: 'blob' as 'json'
        }) as Observable<HttpResponse<Blob>>;
    }*/

    /**
     * Find a FolderProjectResource by his id
     */
    public find(id: string, state: string = null,
                reportId: string = null, reportStateId: string = null): Observable<FolderProjectResource> {
        const httpParamUtil = HttpParamUtil.create();

        if (null !== state) {
            httpParamUtil.add('state', state);
        }

        if (null !== reportId) {
            httpParamUtil.add('report', reportId);
        }

        if (null !== reportStateId) {
            httpParamUtil.add('reportState', reportStateId);
        }

        return this.apiService.get('/folder_project_resources/' + id, {
            params: httpParamUtil.getHttpParams()
        }).pipe(
            map((res) => res as FolderProjectResource)
        );
    }

    public delete(iri: string): Observable<any> {
        return this.apiService.delete(iri);
    }
}
