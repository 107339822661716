import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {InvoiceService} from '../../core/services/invoice.service';
import {FormBuilder, Validators} from '@angular/forms';
import {Invoice} from '../../core/models/invoice.model';
import {FormUtil} from '../../core/utils/form.util';
import {LoadingService} from '../../core/services/loading.service';
import {AuthService} from '../../core/services/auth.service';

@Component({
    selector: 'app-edit-invoice',
    templateUrl: './edit-invoice.component.html',
    styleUrls: ['./edit-invoice.component.scss']
})
export class EditInvoiceComponent {
    type;
    public form = this.fb.group({
        '@id': [null],
        name: [null, [Validators.required]],
        paymentDate: [null],
        receiveDate: [null],
        approvalDate: [null],
        ofenApprovalDate: [null],
        amount: [null, [Validators.required]],
        fixAmount: [null, [Validators.required]],
        variableAmount: [null, [Validators.required]]
    });

    constructor(
        public loading: LoadingService,
        public dialogRef: MatDialogRef<EditInvoiceComponent>,
        public invoice: InvoiceService,
        private fb: FormBuilder,
        private authService: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: { invoice: Invoice, type: string }
    ) {
        this.type = data.type
        if (data && data.invoice) {
            this.initForm();
        }
    }

    initForm(): void {

        if (this.authService.isAdmin()) {
            if (this.type === 'program') {
                this.form.removeControl('amount')
            } else if (this.type === 'project') {
                this.form.removeControl('fixAmount')
                this.form.removeControl('variableAmount')
            }
        }
        this.form.patchValue(this.data.invoice);
    }

    save(): void {
        if (this.form.valid) {
            const body = this.form.value as Invoice;
            this.invoice.updateInvoice(body['@id'], body).subscribe((invoice: Invoice) => {
                this.dialogRef.close({invoice});
            });

            return;
        }

        FormUtil.validateAllFormFields(this.form);
    }

    isAdmin(): boolean {
        return this.authService.isAdmin()
    }
}
