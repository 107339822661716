import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FolderDetailComponent } from './folder-detail.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '../../../../core/pipes/pipes.module';
import { ComponentsModule } from '../../../../components/components.module';


@NgModule({
    declarations: [
        FolderDetailComponent
    ],
    exports: [
        FolderDetailComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        TranslateModule,
        MatIconModule,
        PipesModule,
        ComponentsModule
    ]
})
export class FolderDetailModule { }
