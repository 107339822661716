import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
    selector: '[appTableCellHighlighter]'
})
export class TableCellHighlighterDirective implements AfterViewInit {
    constructor(
        private elRef: ElementRef
    ) {
    }

    public ngAfterViewInit(): void {
        const rows: HTMLCollectionOf<HTMLTableRowElement> = (this.elRef.nativeElement as HTMLTableElement).rows;
        for (const row of Array.from(rows)) {
            let cellContent: string = null;

            for (const cell of Array.from(row.cells)) {
                // only compare TD cells
                if ('TH' === cell.nodeName) {
                    continue;
                }

                // set first TD as starting cell content
                if (null === cellContent) {
                    cellContent = cell.innerText;
                }

                // compare each cells to previous content
                if (0 === cell.childElementCount && cellContent !== cell.innerText) {
                    cell.classList.add('cell-highlighted');
                }

                cellContent = cell.innerText;
            }
        }
    }
}
