<h2 mat-dialog-title>{{ 'action.add_milestone' | translate }}</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <input matInput placeholder="{{ 'word.milestone_name' | translate }}" formControlName="name" type="text" autocomplete="off" maxlength="255" />
                    <mat-error *ngIf="form.get('name').hasError('required')">
                        {{ 'validator.milestone_name.not_blank' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>{{ 'word.type' | translate }}</mat-label>
                    <mat-select [formControl]="selectedType">
                        <mat-option [value]="TYPE_MILESTONE">{{ 'word.milestone' | translate }}</mat-option>
                        <mat-option [value]="TYPE_REPORT">{{ 'word.report' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field>
                    <input matInput
                           [matDatepicker]="date"
                           placeholder="{{ 'word.date' | translate }}"
                           formControlName="dueDate"
                           type="text"
                           autocomplete="off">
                    <mat-datepicker-toggle matSuffix [for]="date"></mat-datepicker-toggle>
                    <mat-datepicker #date></mat-datepicker>
                    <mat-error *ngIf="form.get('dueDate').hasError('matDatepickerParse')">
                        {{ 'validator.date.not_valid' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-stroked-button *ngIf="!newItem && 'milestone' === selectedType.value" appConfirmDialog (confirmClick)="delete()"><mat-icon>delete</mat-icon></button>
    <button mat-flat-button mat-dialog-close>{{ 'action.cancel' | translate }}</button>
    <button mat-flat-button color="accent" (click)="save()" appMatButtonLoading [loading]="loading.loading$ | async">{{ 'action.save' | translate }}</button>
</mat-dialog-actions>
