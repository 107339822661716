import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageTitleService } from '../../../../../../core/services/page-title.service';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FolderProgramResource } from '../../../../../../core/models/folder-program-resource.model';
import { COMPUTOR_NAMES, FolderService } from '../../../../../../core/services/folder.service';
import { ProgramFolder } from '../../../../../../core/models/folder.model';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FolderProgramService } from '../../../../../../core/services/folder-program.service';
import { MarkingService } from '../../../../../../core/services/marking.service';
import { I18nService } from '../../../../../../core/i18n/i18n.service';
import { AuthorizationChecker } from '../../../../../../core/security/authorization-checker';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../../../../core/services/user.service';
import { NotificationService } from '../../../../../../core/notifications/notification.service';
import { MediaObject } from '../../../../../../core/models/media-object.model';
import { MatDialog } from '@angular/material/dialog';
import { Step } from '../../../../../../core/models/step.model';
import { Measure } from '../../../../../../core/models/measure.model';
import { LoadingService } from '../../../../../../core/services/loading.service';
import {
    FIX_COST_TYPE_ACCOMPANIMENT,
    FIX_COST_TYPE_MANAGEMENT,
    FixCost
} from '../../../../../../core/models/fixCost.model';
import { ProgramComputedValues } from '../../../../../../core/models/computed-values.model';
import { ModalService } from '../../../../../../core/services/modal.service';
import { ReportColumn } from '../../../../../../core/models/report-column.model';
import { AdminNavUtil } from '../../../../../../core/utils/admin-nav.util';
import { ChildProgram } from 'src/app/core/models/child-folder.model';

export type FixCostCalculations = {
    subsidyRateManagement: number;
    subsidyRateAccompaniment: number;
    totalCost: number;
    subsidyRate: number;
};

@Component({
    selector: 'app-admin-program-show',
    templateUrl: './admin-program-show.component.html',
    styleUrls: ['./admin-program-show.component.scss']
})
export class AdminProgramShowComponent implements OnInit, OnDestroy {
    public static computorName = COMPUTOR_NAMES.computor2023Big;

    public folderProgramResource: FolderProgramResource;
    public folder: ProgramFolder;

    public managersForm = this.fb.group({
        gsAnalysisManager: [null],
        gsMonitoringManager: [null],
        ofenAnalysisManager: [null],
        ofenMonitoringManager: [null]
    });

    public possibleLanguages = I18nService.languages;
    public assignedLanguageField = new FormControl();

    public questionsAnswersDateCtrl = new FormControl(null);
    public postArrivalDateCtrl = new FormControl(null);

    public mediaForm = this.fb.group({
        mediaObjects: this.fb.array([])
    });

    public lengthValidators = {
        genString: 250,
        longString: 4096,
        postCode: 7,
        acronym: 50,
        minCost: 0.05,
        minRequestedContribution: 20000,
        maxRequestedContribution: 2000000,
        positiveValue: 0,
        minCtsKwhCost: 1,
        maxCtsKwhCost: 100
    };

    public folderHolderForm = this.fb.group({
        '@id': [null, []],
        function: [null, Validators.maxLength(this.lengthValidators.genString)],
        companyName: [null, Validators.maxLength(this.lengthValidators.genString)],
        firstName: [null, Validators.maxLength(this.lengthValidators.genString)],
        lastName: [null, Validators.maxLength(this.lengthValidators.genString)],
        email: [null, [
            Validators.maxLength(this.lengthValidators.genString),
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
        ]],
        phone: [null, Validators.maxLength(this.lengthValidators.genString)],
        mobilePhone: [null, Validators.maxLength(this.lengthValidators.genString)],
    });

    public measures: Measure[];

    private destroySubject: Subject<boolean> = new Subject<boolean>();

    constructor(
        private title: PageTitleService,
        private breadcrumbService: BreadcrumbService,
        private route: ActivatedRoute,
        private folderService: FolderService,
        private folderProgramService: FolderProgramService,
        private markingService: MarkingService,
        private authorizationChecker: AuthorizationChecker,
        private fb: FormBuilder,
        private userService: UserService,
        private notificationService: NotificationService,
        private dialog: MatDialog,
        private router: Router,
        public loading: LoadingService,
        private modalService: ModalService,
        private navUtil: AdminNavUtil
    ) {
        this.folderProgramResource = this.route.snapshot.data.folderProgramResource as FolderProgramResource;
        this.folderService.setProgramResource(this.folderProgramResource);

        this.measures = this.folderProgramResource.data.currentProgram.measures;

        this.folderService.onFolderChange.pipe(
            takeUntil(this.destroySubject)
        ).subscribe(() => {
            void this.updateFolderProgram().subscribe();
        });

    }

    ngOnInit(): void {
        this.addMediaObjects();
        this.initBreadcrumb();
        this.updateFolderProjectOnMarkingChange();
        this.setFormFields();
    }

    ngOnDestroy(): void {
        this.folderService.clear();
        this.destroySubject.next(true);
        this.destroySubject.complete();
    }

    public hasRoleStaffPkw(): boolean {
        return this.authorizationChecker.checkIsGranted('ROLE_STAFF_PKW');
    }

    public countColspan(reportSteps: any[]): number {
        return reportSteps.filter((step: Step) => step['@permissions'].view).length;
    }

    public save(): void {
        const folderLanguage = this.assignedLanguageField.value as string;
        const postArrivalDate = this.postArrivalDateCtrl.value as string;
        const questionsAnswersDate = this.questionsAnswersDateCtrl.value as string;

        const mediaObjects: string[] = [];
        const mediaForm = this.mediaForm.get('mediaObjects').value as { '@id': string }[];

        mediaForm.forEach(object => mediaObjects.push(object['@id']));

        this.folderService.patchFolderHolder(this.folderHolderForm.value).subscribe(() => {
        });

        this.folderService.assignGeneralData(
            this.folderProgramResource.data.id,
            {
                folderLanguage,
                mediaObjects,
                postArrivalDate,
                questionsAnswersDate,
                ...this.managersForm.value
            }
        ).subscribe(() => {
            this.folderProgramResource.data.folderLanguage = folderLanguage; // override lang without server result
            this.finalizeSaveData();
        });
    }

    public async openFolderSettingsDialog(): Promise<void> {
        const saved = await this.modalService.openFolderSettingsDialog(this.folderProgramResource);
        if (saved) {
            this.updateFolderProgram();
        }
    }

    public showOrEditFolderStep(step: Step): void {
        if (step.marking === 'validation_2') {
            void this.router.navigate([
                'admin',
                'programs',
                this.folderProgramResource.data.id,
                'validation2',
                step.id,
            ]);

            return;
        }


        if (step['@permissions'].view && !step['@permissions'].edit) {
            void this.router.navigate([
                'admin',
                'programs',
                this.folderProgramResource.data.id,
                'states',
                step.id,
            ]);
        }
    }

    public get fixCostsManagement(): FixCost[] {
        return this.folderProgramResource.data.currentProgram.fixCosts.filter(f => f.type === FIX_COST_TYPE_MANAGEMENT);
    }

    public get fixCostsAccompaniment(): FixCost[] {
        return this.folderProgramResource.data.currentProgram.fixCosts.filter(f => f.type === FIX_COST_TYPE_ACCOMPANIMENT);
    }

    public fixCostByGroupName(program: ChildProgram, groupName: string): number {
        return program.fixCosts.find(f => f.groupName === groupName).cost.requestedContribution;
    }

    public calculateFixCostsValues(stepIdx: number, reportColumn: number = null): FixCostCalculations {
        // eslint-disable-next-line
        let stepComputedValues = this.folderProgramResource.folderSteps.before[stepIdx].computedValues as ProgramComputedValues;

        if (reportColumn) {
            // eslint-disable-next-line
            stepComputedValues = this.folderProgramResource
                .reportSteps[stepIdx].steps[reportColumn].computedValues as ProgramComputedValues;
        }

        const totalContribution = stepComputedValues.summary.totalContribution;
        const subsidyRateManagement = (stepComputedValues.summary.adminManagementCost / totalContribution) * 100;
        const subsidyRateAccompaniment = (stepComputedValues.summary.adminAccompanimentCost / totalContribution) * 100;
        const subsidyRate = (stepComputedValues.summary.adminTotalCost / totalContribution) * 100;

        return {
            totalCost: stepComputedValues.summary.adminManagementCost,
            subsidyRate,
            subsidyRateManagement,
            subsidyRateAccompaniment
        };
    }

    public showOrEditFolderReport(column: ReportColumn, step: Step): void {
        this.navUtil.showOrEditFolderReport(column, step, this.folderProgramResource);
    }

    public isLastViewableReport(column: ReportColumn, reportStep: Step): boolean {
        const viewableSteps = column.steps.filter(step => true === step['@permissions'].view);
        return reportStep.id === [...viewableSteps].pop().id;
    }

    private setFormFields(): void {
        this.assignedLanguageField.setValue(this.folderProgramResource.data.folderLanguage ?? null);
        this.postArrivalDateCtrl.setValue(this.folderProgramResource.data.postArrivalDate ?? null);
        this.questionsAnswersDateCtrl.setValue(this.folderProgramResource.data.questionsAnswersDate ?? null);
        this.managersForm.get('gsAnalysisManager').setValue(this.folderProgramResource.data.gsAnalysisManager?.['@id'] ?? null);
        this.managersForm.get('gsMonitoringManager').setValue(this.folderProgramResource.data.gsMonitoringManager?.['@id'] ?? null);
        this.managersForm.get('ofenAnalysisManager').setValue(this.folderProgramResource.data.ofenAnalysisManager?.['@id'] ?? null);
        this.managersForm.get('ofenMonitoringManager').setValue(this.folderProgramResource.data.ofenMonitoringManager?.['@id'] ?? null);
        this.folderHolderForm.get('companyName').setValue(this.folderProgramResource.data.folderHolder.companyName ?? null);
        this.folderHolderForm.get('firstName').setValue(this.folderProgramResource.data.folderHolder.firstName ?? null);
        this.folderHolderForm.get('lastName').setValue(this.folderProgramResource.data.folderHolder.lastName ?? null);
        this.folderHolderForm.get('email').setValue(this.folderProgramResource.data.folderHolder.email ?? null);
        this.folderHolderForm.get('phone').setValue(this.folderProgramResource.data.folderHolder.phone ?? null);
        this.folderHolderForm.get('mobilePhone').setValue(this.folderProgramResource.data.folderHolder.mobilePhone ?? null);
        this.folderHolderForm.get('function').setValue(this.folderProgramResource.data.folderHolder.function ?? null);
        this.folderHolderForm.get('@id').setValue(this.folderProgramResource.data.folderHolder['@id'] ?? null);
    }

    private finalizeSaveData(): void {
        this.notificationService.success('toast.data_successfully_saved');
    }

    private addMediaObjects(): void {
        this.folderProgramResource.data.mediaObjects.forEach((mediaObject: MediaObject) => {
            this.addMediaObjectGroup(mediaObject);
        });
    }

    private addMediaObjectGroup(media: MediaObject): void {
        const mediaObjects = this.mediaForm.get('mediaObjects') as FormArray;
        mediaObjects.push(this.createMediaObjectGroup(media));
    }

    private createMediaObjectGroup(media: MediaObject): FormGroup {
        return this.fb.group({
            '@id': [media['@id'], []],
            originalName: [media.originalName, []],
        });
    }

    private initBreadcrumb(): void {
        this.breadcrumbService.folderView();
    }

    private updateFolderProjectOnMarkingChange(): void {
        this.markingService.folderMarking$.pipe(
            distinctUntilChanged(),
            takeUntil(this.destroySubject),
        ).subscribe((res) => {
            if (null === res) {
                return;
            }
            void this.updateFolderProgram().subscribe();
        });
    }

    private updateFolderProgram(): Observable<any> {
        const finishedUpdate = new Subject();

        this.folderProgramService
            .find(this.folderProgramResource.data.id.toString())
            .subscribe((folderProgramResource: FolderProgramResource) => {
                this.folderProgramResource = folderProgramResource;
                this.folderService.setProgramResource(folderProgramResource);
                finishedUpdate.next(true);
            });

        return finishedUpdate;
    }

}
