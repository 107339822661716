import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';


@Injectable({
    providedIn: 'root'
})
export class SwitchUserService {
    public logoutRedirect = '';
    public nextTransition = '';

    private switchUserSubject = new BehaviorSubject<string>('');
    public switchUserSubject$ = this.switchUserSubject.asObservable(); // eslint-disable-line

    constructor(
        private router: Router
    ) {
    }

    public logoutIfSwitched(): boolean {
        if ('' !== this.getSwitchedUser()) {
            this.stopImpersonating();
            return true;
        }

        return false;
    }

    public getSwitchedUser(): string {
        return this.switchUserSubject.getValue();
    }

    public startImpersonating(login: string): void {
        this.switchUserSubject.next(login);

        this.logoutRedirect = this.router.url;
    }

    public isImpersonating(): boolean {
        return '' !== this.switchUserSubject.getValue();
    }

    public stopImpersonating(): void {
        this.switchUserSubject.next('');

        if ('' !== this.logoutRedirect) {
            void this.router.navigateByUrl(this.logoutRedirect);
        }
    }
}
