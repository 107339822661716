import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Invoice } from '../models/invoice.model';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Sort } from '@angular/material/sort';
import { Paginator } from '../models/paginator.model';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class InvoiceService {

    constructor(
        private api: ApiService
    ) { }

    public addNewInvoice(data: Invoice): Observable<Invoice> {
        return this.api.post('/invoices', data) as Observable<Invoice>;
    }

    public deleteInvoice(invoiceIri: string): Observable<any> {
        return this.api.delete(invoiceIri);
    }

    public changeInvoiceState(invoiceIri: string, state: string): Observable<any> {
        const body = {
            state
        };

        return this.api.patch(invoiceIri, body);
    }

    public updateInvoice(invoiceIri: string, body: Invoice): Observable<any> {
        return this.api.patch(invoiceIri, body as Record<any, any>);
    }

    public findAllPaginated(pageNumber = 1,
        itemsPerPage = Number(environment.itemsPerPage),
        search = '',
        sort?: Sort): Observable<Paginator> {
        let params = new HttpParams()
            .set('page', pageNumber.toString())
            .set('itemsPerPage', itemsPerPage.toString());

        if ('' !== search) {
            params = params.append('q', search);
        }

        if (sort) {
            params = params.append('order[' + sort.active + ']', sort.direction);
        }

        return this.api.get('/invoices', { params }) as Observable<Paginator>;
    }

    public exportRequest(body: Record<any, unknown>): Observable<void> {

        // select all invoices
        if ((body.iris as any[]).length === 0) {
            return this.api.post('/invoices/export', { ...body, iris: null }) as Observable<void>;
        }

        return this.api.post('/invoices/export', body) as Observable<void>;
    }
}
