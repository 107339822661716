import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { ProjectRoutingModule } from './project-routing.module';
import { ProjectComponent } from './project-form/project.component';
import { ProjectStepComponent } from './project-step/project-step.component';
import { ProjectShowComponent } from './project-show/project-show.component';
import { ProgramComponent } from '../program/program-form/program.component';
import { ProgramShowComponent } from '../program/program-show/program-show.component';
import { ProgramShowStepComponent } from '../program/program-show-step/program-show-step.component';


@NgModule({
    declarations: [
        ProjectComponent,
        ProjectStepComponent,
        ProjectShowComponent,
        ProgramComponent,
        ProgramShowComponent,
        ProgramShowStepComponent
    ],
    imports: [
        FormsModule,
        ProjectRoutingModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatTabsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatCardModule,
    ],
    providers: [
    ],
    bootstrap: [ProjectComponent]
})
export class ProjectModule { }
