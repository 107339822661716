import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { ProjectCalculatorService } from './services/project-calculator.service';
import { NotificationService } from '../../core/notifications/notification.service';
import { SwitchUserService } from '../../core/services/switch-user.service';
import { FolderStatusService } from '../../core/services/folder-status.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TechnicalOrientation } from '../../core/models/technical-orientation.model';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map, take, takeUntil } from 'rxjs/operators';
import { MediaObject } from '../../core/models/media-object.model';
import AbstractComputor from '../abstract-computor';
import { FolderService } from '../../core/services/folder.service';
import { ReportForm } from './models/reportForm';
import { HtmlInputEvent } from '../../core/events/html-input-event';
import { HttpParamUtil } from '../../core/utils/http-param.util';
import { MediaObjectService } from '../../core/services/media-object.service';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { DownloaderHelper } from '../../core/utils/download.util';
import { ProjectReportResource } from '../../core/models/project-report-resource';
import { TechnicalOrientationService } from '../../core/services/technical-orientation.service';

@Component({
    selector: 'app-report-template-form-component',
    template: ''
})
export abstract class Abstract2024BigProjectReportFormTemplateComponent extends AbstractComputor implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('fileInput') public fileInput: ElementRef;

    /**
     * This class contains the base of a project_2024Big form.
     * Extended in Report edit & project-form component.
     */
    public form = this.computor2024Bigform.getReportForm();

    public lengthValidators = this.computor2024Bigform.lengthValidators;

    public computedResume = {...this.computor2024Bigform.computedResume};

    public baseTechnicalOrientations: TechnicalOrientation[] = [];
    public technicalOrientations: TechnicalOrientation[] = [];

    public searchTechnicalOri = new FormControl();

    public isSubmittable = false;

    public defaultCurrency: string;

    public destroySubject: Subject<boolean> = new Subject<boolean>();

    private uploadedFileType = 'folder';

    protected constructor(
        protected computor2024Bigform: ReportForm,
        protected projectCalculator: ProjectCalculatorService,
        protected notificationService: NotificationService,
        protected switchUserService: SwitchUserService,
        protected technicalOrientationService: TechnicalOrientationService,
        protected folderStatusService: FolderStatusService,
        protected fb: FormBuilder,
        protected route: ActivatedRoute,
        protected translateService: TranslateService,
        protected folderService: FolderService,
        protected mediaObjectService: MediaObjectService
    ) {
        super(switchUserService);

        this.defaultCurrency = environment.defaultCurrency;

        route.data.subscribe(data => {
            if (!data) {
                return;
            }

            if (this.route.snapshot.data.folder) {
                this.folderService.setProjectResource(this.route.snapshot.data.folder);
                this.setFolderProject(this.route.snapshot.data.folder);
                this.form.patchValue(this.projectReportResource.data);
            }
        });
    }

    public ngOnInit(): void {
        this.projectCalculator.setFormReference(this.form);

        this.technicalOrientationService.findAllByTenderModel(this.tenderSession.tenderModel).pipe(
            map((result) => result['hydra:member']),
            take(1)
        ).subscribe( (technicalOrientations: TechnicalOrientation[]) => {
            this.baseTechnicalOrientations = technicalOrientations;
            this.technicalOrientations = technicalOrientations;

            this.projectCalculator.setTechnicalOrientationsList(this.technicalOrientations);

            if (this.folderProject) {
                const measuresCount = this.projectReportResource.data.currentReport.reportMeasures.length;

                for (let i = 1; i < measuresCount; i++) {
                    this.addMeasure();
                }

                this.form.patchValue(this.projectReportResource.data);
                this.projectReportResource.data.analysisMediaObjects.forEach((mediaObject: MediaObject) => {
                    this.addAnalysisMediaObjectGroup(mediaObject);
                });

                this.projectReportResource.data.currentReport.mediaObjects?.forEach(mediaObject => {
                    (this.form.get('currentReport.mediaObjects') as FormArray).push(this.createMediaObjectGroup(mediaObject));
                });

                this.ngAfterViewInit();
            }
        });

        this.subscribeToTechnicalOriSearch();
    }

    public ngOnDestroy(): void {
        this.destroySubject.next(true);
        this.destroySubject.complete();
        this.folderService.clear();
    }

    public ngAfterViewInit(): void {
        this.executeCalculation();
    }

    public get currentProjectForm(): FormGroup {
        return this.form.get('currentProject') as FormGroup;
    }

    public executeCalculation(): void {
        this.projectCalculator.calculate().pipe(takeUntil(this.destroySubject)).subscribe(() => {
            this.computedResume = this.projectCalculator.getResults();
        }, () => {
            this.notificationService.warn(this.projectCalculator.getErrorMessage());
        });
    }

    public removeMeasureGroup(i: number): void {
        const measures = this.form.get('currentReport.reportMeasures') as FormArray;

        if (measures.length > 1) {
            measures.removeAt(i);
        } else {
            measures.reset();
        }

        this.executeCalculation();
    }

    public addMeasure(): void {
        const measures = this.form.get('currentReport.reportMeasures') as FormArray;
        measures.push(this.computor2024Bigform.createMeasureGroup());
    }

    public createMediaObjectGroup(media: MediaObject): FormGroup {
        return this.fb.group({
            '@id': [media['@id'], []],
            originalName: [media.originalName, []],
        });
    }
    public addAnalysisMediaObjectGroup(media: MediaObject): void {
        const analysisMediaObjects = this.form.get('analysisMediaObjects') as FormArray;
        analysisMediaObjects.push(this.createAnalysisMediaObjectGroup(media));
    }

    public createAnalysisMediaObjectGroup(media: MediaObject): FormGroup {
        return this.fb.group({
            '@id': [media['@id'], []],
            originalName: [media.originalName, []],
        });
    }

    public addAnalysisMediaObject(): void {
        this.uploadedFileType = 'analysis';

        const nativeElement = this.fileInput.nativeElement as HTMLElement;
        nativeElement.click();
    }

    public onNewFileSelected(event: HtmlInputEvent): void {
        const httpParams = HttpParamUtil.create()
            .add('type', 'computor_2024Big_folder')
            .add('report', String(this.projectReportResource.data.id))
        ;

        // stackoverflow.com/q/25333488/ (convert target.files to Array)
        for (const file of Array.prototype.slice.call(event.target.files)) {
            this.mediaObjectService.upload(file, httpParams.getHttpParams()).subscribe((media: MediaObject) => {
                if ('analysis' === this.uploadedFileType) {
                    this.addAnalysisMediaObjectGroup(media);
                }
            });
        }
    }

    public downloadMediaObject(mediaObject: MediaObject): void {
        const params = new HttpParams()
            .set('report', String(this.projectReportResource.data.id))
        ;

        this.mediaObjectService.download(mediaObject['@id'], params).subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        });
    }

    public deleteAnalysisMediaObject(mediaObject: MediaObject, mediaObjectIndex: number = null): void {
        this.mediaObjectService.delete(mediaObject['@id']).subscribe(() => {
            const analysisMediaObjectsFormArray = this.form.get('analysisMediaObjects') as FormArray;
            analysisMediaObjectsFormArray.removeAt(mediaObjectIndex);
        });
    }

    public get projectReportResource(): ProjectReportResource {
        return this.folderProject.data.projectReportResource;
    }

    private subscribeToTechnicalOriSearch(): void {
        this.searchTechnicalOri.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe((value: string) => {
            if (value === '' || !value) {
                this.technicalOrientations = this.baseTechnicalOrientations;
                return;
            }

            this.technicalOrientations = this.technicalOrientations.filter(obj => {
                const text = obj.slug;
                return text.toLowerCase().indexOf(value.toLowerCase()) > -1;
            });
        });
    }

    abstract save(): void;
}
