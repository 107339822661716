import { Component, OnInit } from '@angular/core';
import { DashboardLayoutComponent } from '../dashboard-layout/dashboard-layout.component';
import { FolderStatusService } from '../../core/services/folder-status.service';
import { currentYear } from 'src/app/shared/currentYear';

@Component({
    selector: 'app-dashboard-scrolled-layout',
    templateUrl: './dashboard-scrolled-layout.component.html',
    styleUrls: ['./dashboard-scrolled-layout.component.scss', './../dashboard-layout/dashboard-layout.component.scss'],
    providers: [FolderStatusService],
})
export class DashboardScrolledLayoutComponent extends DashboardLayoutComponent implements OnInit {
    public currentYear = currentYear.year;

    scrollToElement(scrollTo: string): void {
        const sectionString = scrollTo.split('#')[1];
        const section: Element = document.getElementById(sectionString);
        section.scrollIntoView({
            behavior : 'smooth'
        });
    }

    public ngOnInit(): void {
        super.ngOnInit();
    }
}
