import { Component, Input } from '@angular/core';
import { TenderSession } from '../../core/models/tender-session.model';
import { COMPUTOR_TYPE } from '../../core/services/folder.service';

@Component({
    selector: 'app-important-dates-list',
    templateUrl: './important-dates-list.component.html',
    styleUrls: ['./important-dates-list.component.scss']
})
export class ImportantDatesListComponent {
    @Input()
    public tenderSession: TenderSession;

    get type(): COMPUTOR_TYPE {
        return this.tenderSession.tenderModel.computorType;
    }
}
