import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable()
@Pipe({
    name: 'positive',
    pure: false, // required to update the value when the promise is resolved
})
export class PositivePipe implements PipeTransform {

    public transform(value: number): number {
        if (typeof value !== 'number') {
            return value;
        }

        return Math.abs(value);
    }
}
