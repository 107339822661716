import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ErrorsBottomSheetComponent } from '../../../shared/errors-bottom-sheet/errors-bottom-sheet.component';
import { Violation } from '../../../core/models/violation.model';


@Injectable()
export class ProjectFieldsCheckerService {

    constructor(
        private dialog: MatDialog,
        private translateService: TranslateService,
        private errorsBottomSheet: MatBottomSheet,
    ) {
    }

    public displayCheckResult(violations: Violation[]): void {
        this.errorsBottomSheet.open(ErrorsBottomSheetComponent, {
            data: {
                violations,
            }
        });

        // const msg: string[] = [];
        // const lines = checkDescription.split(/\r?\n/);

        // if (lines.length) {
        // for (let i = 0; i < lines.length; i++) {
        //
        //        const line = lines[i];
        //        const parts = line.split(':');
        //
        //        if (parts[0]) {
        //
        //            // exclude control for this one
        //            if (!['computor2020FolderContacts[1].iban'].includes(parts[0])) {
        //
        //                // special case for measures
        //                if (parts[0].indexOf('measures[') > -1) {
        //
        //                    let index = parts[0].indexOf('measures[');
        //                    const measureIdx = parseInt(parts[0].substr(index + 'measures['.length), 10);
        //
        //                    index = parts[0].indexOf('].datasetMeasure.');
        //                    const txt = parts[0].substr(index + '].datasetMeasure.'.length);
        //                    msg.push('&bull;&nbsp;' + this.translateService.instant('project_2020.fieldChecker.datasetMeasure.'
        //                        + txt, { num: (measureIdx + 1) }));
        //                } else {
        //
        //                    // standard case
        //                    msg.push('&bull;&nbsp;' + this.translateService.instant('project_2020.fieldChecker.' + parts[0]));
        //                }
        //            }
        //        }
        //    }


        /* this.dialog.open(BasicDialogComponent, {
                maxWidth: '500px',
                data: {
                    title: this.translateService.instant('project_2020.fieldChecker.required_fields'),
                    content: msg.join('<br>'),
                    confirm: this.translateService.instant('action.close'),
                }
            });*/
        // }
    }
}
