import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { NotificationService } from '../../../../../../core/notifications/notification.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FolderStatusService } from '../../../../../../core/services/folder-status.service';
import { ProjectCalculatorService } from '../../../../services/project-calculator.service';
import { TranslateService } from '@ngx-translate/core';
import { SwitchUserService } from '../../../../../../core/services/switch-user.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
    COMPUTOR_NAMES, COMPUTOR_TYPES,
    FolderService,
} from '../../../../../../core/services/folder.service';
import { Abstract2022ProjectReportFormTemplateComponent } from '../../../../project-report-form.template';
import { ReportForm } from '../../../../models/reportForm';
import { ProjectReportResource } from '../../../../../../core/models/project-report-resource';
import { ReportService } from '../../../../../../core/services/report.service';
import { ProjectReportService } from '../../../../../../core/services/project-report.service';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import { FolderProjectResource } from '../../../../../../core/models/folder-project-resource.model';
import { IriUtil } from '../../../../../../core/utils/iri.util';
import { MenuService } from '../../../../../../core/services/menu.service';
import { MediaObjectService } from '../../../../../../core/services/media-object.service';
import { ProjectFieldsCheckerService } from '../../../../services/project-fields-checker.service';
import { PageTitleService } from '../../../../../../core/services/page-title.service';
import { HttpResponse } from '@angular/common/http';
import { DownloaderHelper } from '../../../../../../core/utils/download.util';
import { LoadingService } from '../../../../../../core/services/loading.service';
import { TechnicalOrientationService } from '../../../../../../core/services/technical-orientation.service';

@Component({
    selector: 'app-report-edit',
    templateUrl: './report-edit.component.html',
    styleUrls: ['./report-edit.component.scss']
})
export class ReportEditComponent extends Abstract2022ProjectReportFormTemplateComponent implements OnInit, AfterViewInit, OnDestroy {
    public static computorName = COMPUTOR_NAMES.computor2022;

    constructor(
        protected notificationService: NotificationService,
        protected technicalOrientationService: TechnicalOrientationService,
        protected fb: FormBuilder,
        protected folderStatusService: FolderStatusService,
        protected projectCalculator: ProjectCalculatorService,
        protected computor2022form: ReportForm,
        protected translateService: TranslateService,
        protected switchUserService: SwitchUserService,
        protected route: ActivatedRoute,
        protected folderService: FolderService,
        protected mediaObjectService: MediaObjectService,
        private reportService: ReportService,
        private reportResourceService: ProjectReportService,
        private breadCrumbService: BreadcrumbService,
        private router: Router,
        private menuService: MenuService,
        private projectFieldsCheckerService: ProjectFieldsCheckerService,
        private title: PageTitleService,
        private translator: TranslateService,
        public loading: LoadingService
    ) {
        super(
            computor2022form,
            projectCalculator,
            notificationService,
            switchUserService,
            technicalOrientationService,
            folderStatusService,
            fb,
            route,
            translateService,
            folderService,
            mediaObjectService
        );
        this.folderProject = this.route.snapshot.data.folder as FolderProjectResource ?? null;

        this.computorType = COMPUTOR_TYPES.project;
        this.computorName = COMPUTOR_NAMES.computor2022;

        this.tenderSession = this.folderProject.data.tenderSession;
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.initMenu();

        this.folderService.setProjectResource(this.folderProject);
        this.folderService.addCurrentReport(this.projectReportResource.data.currentReport);

        this.initBreadcrumb();
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.switchUserService.logoutIfSwitched();
    }

    public reportNotCompleted(): boolean {
        return 'completed' !== this.projectReportResource.data.marking;
    }

    public check(): void {
        if (this.form.valid) {
            const reportIO = { data: this.form.value as { currentReport }} as ProjectReportResource;
            this.reportResourceService.check(reportIO, this.computorType, this.computorName).subscribe(() => {
                this.isSubmittable = true;
                this.projectFieldsCheckerService.displayCheckResult([]);
            }, () => {
                this.isSubmittable = true;
            });
        }
    }

    public save(): void {
        if (this.form.valid) {
            const reportIO = { data: this.form.value as { currentReport }} as ProjectReportResource;
            this.reportResourceService.save(reportIO, this.computorType, this.computorName).subscribe(() => {
                this.notificationService.success('toast.data_successfully_saved');
            });
        }
    }

    public submit(): void {
        if (this.form.valid) {
            const reportIO = { data: this.form.value as { currentReport }} as ProjectReportResource;
            this.reportResourceService.save(reportIO, this.computorType, this.computorName, true).subscribe(() => {
                this.reportService.submitReport(IriUtil.extractId(this.projectReportResource['@id'])).subscribe(() => {
                    this.notificationService.success('toast.data_successfully_saved');

                    // redirect to show current state (draft - can't edit no more)
                    this.redirectToShowReport(IriUtil.extractId(reportIO.data.currentReport['@id']));
                });
            });
        }
    }

    redirectToShowReport(reportId: string): void {
        if (this.switchUserService.logoutIfSwitched()) {
            return;
        }

        void this.router.navigate([
            'sessions',
            IriUtil.extractId(this.tenderSession['@id']),
            'folders',
            IriUtil.extractId(this.folderProject['@id']),
            'reports',
            this.projectReportResource.data.id,
            'states',
            reportId
        ]);
    }

    public get currentReportForm(): FormGroup {
        return this.form.get('currentReport') as FormGroup;
    }

    public exportPdf(): void {
        this.reportResourceService.pdf(
            IriUtil.extractId(this.projectReportResource['@id']),
            this.projectReportResource.data.currentReport.marking,
            'pdf_project_report_exporter'
        ).subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        }, () => {
            this.notificationService.error('toast.an_error_occurred_while_generating_pdf');
        });
    }

    private initBreadcrumb(): void {
        this.breadCrumbService.reportView(this.folderLink);
    }

    private initMenu(): void {
        const menu = [{link: '/dashboard', label: 'menu.dashboard', icon: 'icon-home', subItems: []}, {
            link: this.folderLink, label: this.folderProject.data.currentProject.name, icon: 'icon-clipboard', subItems: [
                {
                    link: null, label: this.projectReportResource.data.name, icon: 'icon-clipboard', scrollTo: '#section1', subItems: [
                        { link: null, label: 'title.measures', icon: 'icon-1', scrollTo: '#section1'},
                        { link: null, label: 'title.requested_contribution', icon: 'icon-2', scrollTo: '#section2'},
                        { link: null, label: 'title.documents', icon: 'icon-3', scrollTo: '#section3'},
                        { link: null, label: 'title.invoices', icon: 'icon-4', scrollTo: '#section4'},
                    ]
                }
            ],
        }];
        this.menuService.store(menu);
    }

    get folderLink(): string {
        return '/sessions/' + IriUtil.extractId(this.tenderSession['@id']) + '/folders/' + String(this.folderProject.data.id) + '/view';
    }
}
