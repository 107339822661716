import { Injectable } from '@angular/core';
import { ProgramReportResource } from '../models/program-report-resource';
import { ProjectReportResource } from '../models/project-report-resource';
import { FolderProjectResource } from '../models/folder-project-resource.model';
import { FolderProgramResource } from '../models/folder-program-resource.model';
import { ReportSettingsDialogComponent } from '../../shared/report-settings-dialog/report-settings-dialog.component';
import { FolderSettingsDialogComponent } from '../../shared/folder-settings-dialog/folder-settings-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    constructor(
        private dialog: MatDialog,
        private router: Router
    ) {
    }

    openReportSettingsDialog(folderLink: string, reportResource: ProgramReportResource | ProjectReportResource): void {
        this.dialog.open(ReportSettingsDialogComponent, {
            data: {report: reportResource},
            width: '600px',
        }).afterClosed().subscribe((data: { deleted: boolean; isFinal: boolean } | null) => {
            if (!data) {
                return;
            }

            if (data?.deleted) {
                void this.router.navigateByUrl(folderLink);
            }

            reportResource.data.isFinal = data?.isFinal ?? false;
        });
    }

    async openFolderSettingsDialog(folderResource: FolderProjectResource | FolderProgramResource): Promise<boolean> {
        const dialog = this.dialog.open(FolderSettingsDialogComponent, {
            data: {
                folder: folderResource
            },
            width: '600px'
        });

        const data = (await dialog.afterClosed().toPromise()) as { saved: boolean };

        return !!(data && data.saved);
    }

}
