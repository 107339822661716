import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
    name: 'localizedDate',
    pure: false
})
export class LocalizedDatePipe implements PipeTransform {

    constructor(private translateService: TranslateService) {
    }

    transform(value: string, pattern = 'mediumDate'): string {
        const lang = this.translateService.currentLang + '-CH'; // custom add to retrieve locale data (assets/locales)
        const datePipe: DatePipe = new DatePipe(lang);
        return datePipe.transform(value, pattern);
    }

}
