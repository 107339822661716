import * as oldComputor from './oldComputor/index';
import * as computor2020 from './computor2020/index';
import * as computor2022 from './computor2022/index';
import * as computor2022Lights from './computor2022Lights/index';
import * as computor2023 from './computor2023/index';
import * as computor2023Big from './computor2023Big/index';
import * as computor2024 from './computor2024/index';
import * as computor2024Big from './computor2024Big/index';
import * as computor2024Lights from './computor2024Lights/index';
import * as computor2025 from './computor2025/index';

export {
    oldComputor,
    computor2020,
    computor2022,
    computor2022Lights,
    computor2023,
    computor2023Big,
    computor2024,
    computor2024Big,
    computor2024Lights,
    computor2025
};
