import { Component, OnInit, OnDestroy } from '@angular/core';
import { Folder } from '../../../../../../core/models/folder.model';
import { Measure } from '../../../../../../core/models/measure.model';
import { ActivatedRoute } from '@angular/router';
import { FolderProgramResource } from '../../../../../../core/models/folder-program-resource.model';
import { ReportColumn } from '../../../../../../core/models/report-column.model';
import { Step } from '../../../../../../core/models/step.model';
import { UserService } from '../../../../../../core/services/user.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { I18nService } from '../../../../../../core/i18n/i18n.service';
import { COMPUTOR_NAMES, FolderService } from '../../../../../../core/services/folder.service';
import { PageTitleService } from '../../../../../../core/services/page-title.service';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { FolderProgramService } from '../../../../../../core/services/folder-program.service';
import { MarkingService } from '../../../../../../core/services/marking.service';
import { AdminNavUtil } from '../../../../../../core/utils/admin-nav.util';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../../../../../core/notifications/notification.service';
import { LoadingService } from '../../../../../../core/services/loading.service';
import { ModalService } from '../../../../../../core/services/modal.service';

@Component({
    selector: 'app-admin-program-show',
    templateUrl: './admin-program-show.component.html',
    styleUrls: ['./admin-program-show.component.scss']
})
export class AdminProgramShowComponent implements OnInit, OnDestroy {
    public static computorName = COMPUTOR_NAMES.oldComputor;

    managersForm = this.fb.group({
        gsAnalysisManager: [null],
        gsMonitoringManager: [null],
        ofenAnalysisManager: [null],
        ofenMonitoringManager: [null]
    });

    possibleLanguages = I18nService.languages;
    assignedLanguageField = new FormControl();

    public folder: Folder;
    public folderProgramResource: FolderProgramResource;

    public lengthValidators = {
        genString: 250,
        longString: 4096,
        postCode: 7,
        acronym: 50,
        minCost: 0.05,
        minRequestedContribution: 20000,
        maxRequestedContribution: 2000000,
        positiveValue: 0,
        minCtsKwhCost: 1,
        maxCtsKwhCost: 100
    };

    public folderHolderForm = this.fb.group({
        '@id': [null, []],
        function: [null, Validators.maxLength(this.lengthValidators.genString)],
        companyName: [null, Validators.maxLength(this.lengthValidators.genString)],
        firstName: [null, Validators.maxLength(this.lengthValidators.genString)],
        lastName: [null, Validators.maxLength(this.lengthValidators.genString)],
        email: [null, [
            Validators.maxLength(this.lengthValidators.genString),
            Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
        ]],
        phone: [null, Validators.maxLength(this.lengthValidators.genString)],
        mobilePhone: [null, Validators.maxLength(this.lengthValidators.genString)],
    });
    public measures: Measure[];

    private destroySubject: Subject<boolean> = new Subject<boolean>();

    constructor(
        private route: ActivatedRoute,
        private userService: UserService,
        private fb: FormBuilder,
        private folderService: FolderService,
        private title: PageTitleService,
        private breadcrumbService: BreadcrumbService,
        private folderProgramService: FolderProgramService,
        private markingService: MarkingService,
        private navUtil: AdminNavUtil,
        private dialog: MatDialog,
        private notification: NotificationService,
        public loading: LoadingService,
        private modalService: ModalService
    ) {
        this.folderProgramResource = this.route.snapshot.data.folderProgramResource as FolderProgramResource;
        this.folder = this.route.snapshot.data.folder as Folder;

        this.measures = this.folderProgramResource.data.currentProgram.measures;

        this.folderService.setProgramResource(this.folderProgramResource);

        this.folderService.onFolderChange.subscribe(() => this.updateFolderProgram());
        this.updateFolderProgramOnMarkingChange();

        // Load users by roles (used in dropdowns)
        this.setFormFields();
    }

    ngOnInit(): void {
        this.initBreadcrumb();
    }

    ngOnDestroy(): void {
        this.destroySubject.next(true);
        this.destroySubject.unsubscribe();
        this.folderService.clear();
    }

    setFormFields(): void {
        this.assignedLanguageField.setValue(this.folderProgramResource.data.folderLanguage ?? null);
        if (this.folderProgramResource.data.gsAnalysisManager) {
            this.managersForm.get('gsAnalysisManager').setValue(this.folderProgramResource.data.gsAnalysisManager['@id']);
        }
        if (this.folderProgramResource.data.gsMonitoringManager) {
            this.managersForm.get('gsMonitoringManager').setValue(this.folderProgramResource.data.gsMonitoringManager['@id']);
        }
        if (this.folderProgramResource.data.ofenAnalysisManager) {
            this.managersForm.get('ofenAnalysisManager').setValue(this.folderProgramResource.data.ofenAnalysisManager['@id']);
        }
        if (this.folderProgramResource.data.ofenMonitoringManager) {
            this.managersForm.get('ofenMonitoringManager').setValue(this.folderProgramResource.data.ofenMonitoringManager['@id']);
        }

        this.folderHolderForm.get('companyName').setValue(this.folderProgramResource.data.folderHolder.companyName ?? null);
        this.folderHolderForm.get('firstName').setValue(this.folderProgramResource.data.folderHolder.firstName ?? null);
        this.folderHolderForm.get('lastName').setValue(this.folderProgramResource.data.folderHolder.lastName ?? null);
        this.folderHolderForm.get('email').setValue(this.folderProgramResource.data.folderHolder.email ?? null);
        this.folderHolderForm.get('phone').setValue(this.folderProgramResource.data.folderHolder.phone ?? null);
        this.folderHolderForm.get('mobilePhone').setValue(this.folderProgramResource.data.folderHolder.mobilePhone ?? null);
        this.folderHolderForm.get('function').setValue(this.folderProgramResource.data.folderHolder.function ?? null);
        this.folderHolderForm.get('@id').setValue(this.folderProgramResource.data.folderHolder['@id'] ?? null);
    }

    public countColspan(reportSteps: any[]): number {
        return reportSteps.filter((step: Step) => step['@permissions'].view).length;
    }

    public isLastViewableReport(column: ReportColumn, reportStep: Step): boolean {
        const viewableSteps = column.steps.filter(step => true === step['@permissions'].view);
        return reportStep.id === [...viewableSteps].pop().id;
    }

    public showOrEditFolderStep(): void {
        // not implemented for old Programs
    }

    public showOrEditReport(column: ReportColumn, step: Step): void {
        this.navUtil.showOrEditFolderReport(column, step, this.folderProgramResource);
    }

    public async openFolderSettingsDialog(): Promise<void> {
        const saved = await this.modalService.openFolderSettingsDialog(this.folderProgramResource);
        if (saved) {
            this.updateFolderProgram();
        }
    }

    public save(): void {
        const folderLanguage = this.assignedLanguageField.value as string;

        this.folderService.patchFolderHolder(this.folderHolderForm.value).subscribe(() => {
        });

        this.folderService.assignGeneralData(
            this.folderProgramResource.data.id,
            {
                folderLanguage,
                ...this.managersForm.value
            }
        ).subscribe(() => {
            this.folderProgramResource.data.folderLanguage = folderLanguage; // override lang without server result
            this.notification.success('toast.data_successfully_saved');
        });
    }

    private initBreadcrumb(): void {
        this.breadcrumbService.folderView();
    }

    private updateFolderProgram(): void {
        this.folderProgramService
            .find(this.folderProgramResource.data.id.toString())
            .subscribe((folderProjectResource: FolderProgramResource) => {
                this.folderProgramResource = folderProjectResource;
                this.folderService.setProgramResource(folderProjectResource);
            });
    }

    private updateFolderProgramOnMarkingChange(): void {
        this.markingService.folderMarking$.pipe(
            distinctUntilChanged(),
            takeUntil(this.destroySubject),
        ).subscribe((res) => {
            if (null === res) {
                return;
            }
            void this.updateFolderProgram();
        });
    }
}
