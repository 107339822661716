import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { TechnicalOrientationCollection } from '../models/technical-orientation.collection.model';
import { TenderModel } from '../models/tender-model.model';
import { IriUtil } from '../utils/iri.util';


@Injectable({
    providedIn: 'root'
})
export class TechnicalOrientationService {
    constructor(
        private apiService: ApiService
    ) {
    }

    public findAllByTenderModel(tenderModel: TenderModel): Observable<TechnicalOrientationCollection> {
        const params = new HttpParams()
            .set('pagination', 'false')
            .set('tenderModel', IriUtil.extractId(tenderModel['@id']))
            .set('enabled', '1')
        ;

        return this.apiService.get('/technical_orientations', {params}) as Observable<TechnicalOrientationCollection>;
    }
}
