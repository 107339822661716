import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    COMPUTOR_NAMES,
    COMPUTOR_TYPES,
    FolderService,
} from '../../../../../../core/services/folder.service';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import { Abstract2024BigProjectReportFormTemplateComponent } from '../../../../project-report-form.template';
import { NotificationService } from '../../../../../../core/notifications/notification.service';
import { FormBuilder } from '@angular/forms';
import { FolderStatusService } from '../../../../../../core/services/folder-status.service';
import { ProjectCalculatorService } from '../../../../services/project-calculator.service';
import { TranslateService } from '@ngx-translate/core';
import { SwitchUserService } from '../../../../../../core/services/switch-user.service';
import { ReportForm } from '../../../../models/reportForm';
import { Measure } from '../../../../../../core/models/measure.model';
import { ProjectReportResource } from '../../../../../../core/models/project-report-resource';
import { ProjectReportService } from '../../../../../../core/services/project-report.service';
import { MediaObjectService } from '../../../../../../core/services/media-object.service';
import { ChildProject } from '../../../../../../core/models/child-folder.model';
import { Step } from '../../../../../../core/models/step.model';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { PageTitleService } from '../../../../../../core/services/page-title.service';
import { IriUtil } from '../../../../../../core/utils/iri.util';
import { HttpResponse } from '@angular/common/http';
import { DownloaderHelper } from '../../../../../../core/utils/download.util';
import { FormUtil } from '../../../../../../core/utils/form.util';
import { ReportColumn } from '../../../../../../core/models/report-column.model';
import { LoadingService } from '../../../../../../core/services/loading.service';
import { TechnicalOrientationService } from '../../../../../../core/services/technical-orientation.service';
import { ModalService } from '../../../../../../core/services/modal.service';


@Component({
    selector: 'app-admin-report-show',
    templateUrl: './admin-report-validation2.component.html',
    styleUrls: ['./admin-report-validation2.component.scss']
})
export class AdminReportValidation2Component extends Abstract2024BigProjectReportFormTemplateComponent implements OnInit, OnDestroy {
    public static computorName = COMPUTOR_NAMES.computor2024Big;

    public measures: Measure[] = [];

    constructor(
        protected notificationService: NotificationService,
        protected technicalOrientationService: TechnicalOrientationService,
        protected fb: FormBuilder,
        protected folderStatusService: FolderStatusService,
        protected projectCalculator: ProjectCalculatorService,
        protected computor2024Bigform: ReportForm,
        protected translateService: TranslateService,
        protected switchUserService: SwitchUserService,
        protected route: ActivatedRoute,
        protected folderService: FolderService,
        protected breadcrumbService: BreadcrumbService,
        protected mediaObjectService: MediaObjectService,
        private reportResourceService: ProjectReportService,
        private dialog: MatDialog,
        private router: Router,
        private title: PageTitleService,
        private translator: TranslateService,
        public loading: LoadingService,
        private modalService: ModalService
    ) {
        super(
            computor2024Bigform,
            projectCalculator,
            notificationService,
            switchUserService,
            technicalOrientationService,
            folderStatusService,
            fb,
            route,
            translateService,
            folderService,
            mediaObjectService
        );

        this.computorType = COMPUTOR_TYPES.project;
        this.computorName = this.folderProject.data.tenderModel.computerName; // dynamic -> handles old_computor

        route.data.pipe(takeUntil(this.destroySubject)).subscribe(() => this.addValidatedFolderToReportSteps());
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.measures = this.projectReportResource.data.currentReport.reportMeasures;
        this.folderService.setProjectResource(this.folderProject);
        this.folderService.addCurrentReport(this.projectReportResource.data.currentReport);
        this.initBreadcrumb();
    }

    public ngOnDestroy(): void {
        this.folderService.clear();
        super.ngOnDestroy();
    }

    public addValidatedFolderToReportSteps(): void {
        const validatedFolder = this.folderProject.folderSteps.before.find((step: Step) => 'validation_2' === step.marking) as ChildProject;
        if (!validatedFolder) { return; }
        validatedFolder['@columnName'] = 'validated_from_folder';
        this.folderProject.reportSteps = [
            {
                name: (this.folderProject.reportSteps[0] as ReportColumn).name,
                steps: [validatedFolder]
            },
            ...this.folderProject.reportSteps as Step[]
        ];
    }

    public save(): void {
        if (this.form.valid) {
            const reportIO = { data: this.form.value as { currentReport } } as ProjectReportResource;
            this.reportResourceService.save(reportIO, this.computorType, this.computorName).subscribe(() => {
                this.notificationService.success('toast.data_successfully_saved');
            });
        } else {
            FormUtil.validateAllFormFields(this.form);
            this.notificationService.warn('validator.fields.missing');
        }
    }


    public check(): void {
        if (this.form.valid) {
            const reportIO = { data: this.form.value as { currentReport } } as ProjectReportResource;
            // call will throw an error if fails -> caught by interceptor
            this.reportResourceService.check(reportIO, this.computorType, this.computorName).subscribe();
        }
    }

    public isValidation(): boolean {
        return 'validation' === this.projectReportResource.data.currentReport.marking;
    }

    public reportNotCompleted(): boolean {
        return 'completed' !== this.projectReportResource.data.currentReport.marking;
    }

    public canEdit(): boolean {
        return this.projectReportResource['@permissions'].edit;
    }

    public canEditAnalyse(): boolean {
        return (this.projectReportResource['@permissions'].extra as { editAnalyse: boolean }).editAnalyse;
    }

    public openReportSettingsDialog(): void {
        this.modalService.openReportSettingsDialog(this.folderLink, this.projectReportResource);
    }

    public exportPdf(): void {
        this.reportResourceService.pdf(
            IriUtil.extractId(this.projectReportResource['@id']),
            this.projectReportResource.data.currentReport.marking,
            'pdf_project_report_exporter'
        ).subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        }, () => {
            this.notificationService.error('toast.an_error_occurred_while_generating_pdf');
        });
    }

    private initBreadcrumb(): void {
        this.breadcrumbService.reportView(this.folderLink);
    }

    get folderLink(): string {
        return `/admin/${this.folderProject.data.tenderModel.computorType}s/` + this.folderProject.data.id.toString();
    }
}
