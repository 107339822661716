<ng-container *ngFor="let tenderSession of userTenderSessionResources">
    <app-card classes="card-textured mb-2" *ngIf="tenderSession.folders.length > 0 || tenderSession.isCurrent">
        <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center text-ellipsis">
                <div class="p-2 text-nowrap title">{{ (tenderSession.big ? 'title.my_big_projects' : 'title.my_projects') | translate }}</div>
                <div class="p-2">{{ tenderSession.description }}</div>
            </div>
            <div class="p-2">
                <button mat-flat-button color="primary" [disabled]="!tenderSession.isCurrent"
                        (click)="gotoNewProject(tenderSession)">{{ (tenderSession.big ? 'tender_session.submit_new_big_project' : 'tender_session.submit_new_project') | translate }}
                    <i class="icon-plus"></i>
                </button>
            </div>
        </div>
    </app-card>

    <ng-container *ngIf="tenderSession.folders.length > 0">
        <app-folder-detail *ngFor="let folder of tenderSession.folders" [tenderSession]="tenderSession"
                           [folder]="folder"></app-folder-detail>
    </ng-container>

    <ng-container *ngIf="tenderSession.folders.length === 0 && tenderSession.isCurrent">
        <app-card classes="mb-2">
            <mat-card-content>{{ 'message.no_project_for_this_session' | translate }}</mat-card-content>
        </app-card>
    </ng-container>

    <app-card *ngIf="tenderSession.isCurrent && !tenderSession.continuouslySubmittedSession"
              cardTitle="tender_session.important_dates">
        <app-important-dates-timeline [tenderSession]="tenderSession" type="project"></app-important-dates-timeline>
    </app-card>
</ng-container>
