<form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
    <div class="row">
        <div class="col-md-7">
            <!-- Project's holder -->
            <app-card cardNumber="1" cardTitle="word.supporting_organisation">
                <div class="contact" formGroupName="supportingOrganization">
                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.company_name' | translate }}"
                                       formControlName="companyName"
                                       #companyName type="text" autocomplete="off"
                                       maxlength="{{ lengthValidators.genString }}">
                                <mat-error
                                    *ngIf="form.controls['supportingOrganization'].get('companyName').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <mat-label>{{'word.legal_form' | translate}}</mat-label>
                                <mat-select formControlName="legalForm">
                                    <mat-option *ngFor="let legalForm of legalForms"
                                                [value]="legalForm">{{'legal_forms.' + legalForm | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.address' | translate }}" formControlName="address"
                                       type="text"
                                       #address autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error
                                    *ngIf="form.controls['supportingOrganization'].get('address').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.postal_code' | translate }}"
                                       formControlName="postalCode"
                                       #postalCode type="text" autocomplete="off"
                                       maxlength="{{ lengthValidators.postCode }}">
                                <mat-error
                                    *ngIf="form.controls['supportingOrganization'].get('postalCode').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.postCode} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.city' | translate }}" formControlName="city"
                                       type="text" #city
                                       autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error
                                    *ngIf="form.controls['supportingOrganization'].get('city').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.email' | translate }}" formControlName="email"
                                       #email
                                       type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error
                                    *ngIf="form.controls['supportingOrganization'].get('email').hasError('pattern')">
                                    {{ 'validator.email.not_valid' | translate }}
                                </mat-error>
                                <mat-error
                                    *ngIf="form.controls['supportingOrganization'].get('email').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.phone' | translate }}" formControlName="phone"
                                       #phone
                                       type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error
                                    *ngIf="form.controls['supportingOrganization'].get('phone').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.mobile_phone' | translate }}"
                                       formControlName="mobilePhone"
                                       #mobilePhone type="text" autocomplete="off"
                                       maxlength="{{ lengthValidators.genString }}">
                                <mat-error
                                    *ngIf="form.controls['supportingOrganization'].get('mobilePhone').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.website' | translate }}" formControlName="website"
                                       type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error
                                    *ngIf="form.controls['supportingOrganization'].get('website').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </app-card>

            <!-- Project's beneficiary -->
            <app-card cardNumber="2" cardTitle="title.project_holder">
                <div class="beneficiary" formGroupName="folderHolder">
                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.company_name' | translate }}"
                                       formControlName="companyName"
                                       #companyName2 type="text" autocomplete="off"
                                       maxlength="{{ lengthValidators.genString }}">
                                <mat-error
                                    *ngIf="form.controls['folderHolder'].get('companyName').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.first_name' | translate }}"
                                       formControlName="firstName"
                                       #firstName2 type="text" autocomplete="off"
                                       maxlength="{{ lengthValidators.genString }}">
                                <mat-error *ngIf="form.controls['folderHolder'].get('firstName').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.last_name' | translate }}"
                                       formControlName="lastName" #lastName2
                                       type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error *ngIf="form.controls['folderHolder'].get('lastName').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.function' | translate }}"
                                       formControlName="function"
                                       type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error *ngIf="form.controls['folderHolder'].get('function').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.email' | translate }}" formControlName="email"
                                       #email2
                                       type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error *ngIf="form.controls['folderHolder'].get('email').hasError('pattern')">
                                    {{ 'validator.email.not_valid' | translate }}
                                </mat-error>
                                <mat-error *ngIf="form.controls['folderHolder'].get('email').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.phone' | translate }}" formControlName="phone"
                                       #phone2
                                       type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error *ngIf="form.controls['folderHolder'].get('phone').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <mat-form-field class="col-md-4">
                            <input matInput placeholder="{{ 'word.mobile_phone' | translate }}"
                                   formControlName="mobilePhone"
                                   #phone2
                                   type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                            <mat-error *ngIf="form.controls['folderHolder'].get('mobilePhone').hasError('maxlength')">
                                {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </app-card>

            <app-card cardNumber="3" cardTitle="title.general">
                <div formGroupName="currentProgram">
                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.program_name' | translate }}"
                                       formControlName="name" #name
                                       type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                                <mat-error *ngIf="form.controls['currentProgram'].get('name').hasError('required')">
                                    {{ 'validator.project_name.not_blank' | translate }}
                                </mat-error>
                                <mat-error *ngIf="form.controls['currentProgram'].get('name').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                                <mat-error *ngIf="form.controls['currentProgram'].get('name').hasError('pattern')">
                                    {{ 'validator.field.special_chars' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field>
                                <input matInput placeholder="{{ 'word.acronym' | translate }}" formControlName="acronym"
                                       type="text"
                                       #acronym autocomplete="off" maxlength="{{ lengthValidators.acronym }}">
                                <mat-hint align="end">{{ acronym.value.length }}
                                    / {{ lengthValidators.acronym }}</mat-hint>
                                <mat-hint>{{ 'hint.max_n_chars' | translate:{countMax: lengthValidators.acronym} }}</mat-hint>
                                <mat-error *ngIf="form.controls['currentProgram'].get('acronym').hasError('required')">
                                    {{ 'validator.acronym.not_blank' | translate }}
                                </mat-error>
                                <mat-error *ngIf="form.controls['currentProgram'].get('acronym').hasError('maxlength')">
                                    {{ 'validator.acronym.max_length' | translate:{maxLength: form.controls['currentProgram'].get('acronym').errors['maxlength'].requiredLength} }}
                                </mat-error>
                                <mat-error *ngIf="form.controls['currentProgram'].get('acronym').hasError('pattern')">
                                    {{ 'validator.field.special_chars' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field>
                                <input matInput
                                       [matDatepicker]="startDatePicker"
                                       placeholder="{{ 'word.start_date' | translate }}"
                                       formControlName="startDate"
                                       [min]="startDateMin"
                                       [max]="startDateMax"
                                       type="text"
                                       autocomplete="off">
                                <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #startDatePicker></mat-datepicker>
                                <mat-error
                                    *ngIf="form.controls['currentProgram'].get('startDate').hasError('matDatepickerParse')">
                                    {{ 'validator.startDate.not_valid' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field>
                                <input matInput
                                       [matDatepicker]="endDatePicker"
                                       placeholder="{{ 'word.end_date' | translate }}"
                                       formControlName="endDate"
                                       [min]="form.get('currentProgram.startDate').value || startDateMin"
                                       [max]="endDateMax"
                                       type="text"
                                       autocomplete="off">
                                <mat-hint>
                                    <span class="hint-help">
                                      <mat-icon matTooltip="{{ 'help.end_date' | translate }}">help</mat-icon>
                                    </span>
                                </mat-hint>
                                <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                <mat-datepicker #endDatePicker></mat-datepicker>
                                <mat-error
                                    *ngIf="form.controls['currentProgram'].get('endDate').hasError('matDatepickerParse')">
                                    {{ 'validator.end_date.not_valid' | translate }}
                                </mat-error>
                                <mat-error
                                    *ngIf="form.controls['currentProgram'].get('endDate').hasError('matDatepickerMin')">
                                    {{ 'validator.end_date.min_value' | translate:{min: form.controls['currentProgram'].get('endDate').getError('matDatepickerMin').min} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>{{'word.length_in_month' | translate}}</mat-label>
                                <input matInput [disabled]="true" [readonly]="true" formControlName="lengthInMonth"/>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 d-flex" formGroupName="dataset">
                            <mat-form-field color="primary">
                                <mat-label>{{'word.average_cost_of_electricity' | translate}}</mat-label>
                                <mat-select formControlName="cost" (selectionChange)="executeCalculation()">
                                    <mat-option *ngFor="let cost of electricityCosts"
                                                [value]="cost">{{cost}}</mat-option>
                                </mat-select>
                                <span class="mat-input-suffix" matSuffix>{{ 'word.cts_kwh' | translate }}</span>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>{{'word.sector' | translate}}</mat-label>
                                <mat-select formControlName="sector">
                                    <mat-option *ngFor="let sector of sectors"
                                                [value]="sector">{{'sectors.' + sector | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-6">
                            <mat-form-field>
                                <mat-label>{{'word.geographic_target' | translate}}</mat-label>
                                <mat-select formControlName="geographicTarget">
                                    <mat-option *ngFor="let target of geographicTargets"
                                                [value]="target">{{'geographic_target.' + target | translate}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>{{'word.geographic_target_specifications' | translate}}</mat-label>
                                <input matInput formControlName="geographicTargetSpecifications" autocomplete="off"/>
                                <mat-error
                                    *ngIf="form.controls['currentProgram'].get('geographicTargetSpecifications').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <mat-form-field>
                                <mat-label>{{ 'word.description' | translate }}</mat-label>
                                <textarea matInput
                                          formControlName="description"
                                          cdkTextareaAutosize
                                          cdkAutosizeMinRows="5"
                                          #description
                                          maxlength="{{ lengthValidators.longString }}"></textarea>
                                <mat-hint align="end">{{ description.value.length }}
                                    / {{ lengthValidators.longString }}</mat-hint>
                                <mat-error
                                    *ngIf="form.controls['currentProgram'].get('description').hasError('maxlength')">
                                    {{ 'validator.field.too_long' | translate: {max: lengthValidators.longString} }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </app-card>

            <app-card cardNumber="4" cardTitle="word.fix_costs">
                <p class="mat-caption">{{ 'program_2022.fix_costs_p0' | translate }}</p>

                <h2>{{'word.program_management' | translate}}</h2>
                <p class="mat-caption">{{ 'program_2022.fix_costs_p1' | translate }}</p>

                <div class="row" *ngFor="let group of fixCostsManagement; let i = index">
                    <app-program-fix-costs [form]="fixCostsManagement[i]"
                                           (executeCalculationEvent)="executeCalculation()"></app-program-fix-costs>
                </div>
                <h2>{{'word.program_accompaniment' | translate}}</h2>
                <div class="row" *ngFor="let group of fixCostsAccompaniment; let i = index">
                    <app-program-fix-costs [form]="fixCostsAccompaniment[i]"
                                           (executeCalculationEvent)="executeCalculation()"></app-program-fix-costs>
                </div>
            </app-card>

            <app-card cardNumber="5" cardTitle="title.variable_costs_for_end_customers">
                <div class="position-relative">
                    <h2>{{ 'title.support_measures' | translate }}</h2>
                    <p class="mat-caption">{{ 'program_2022.support_measures.p0' | translate }}</p>
                    <div class="folder" formGroupName="currentProgram">
                        <div formArrayName="measures">
                            <div
                                *ngFor="let item of form.get('currentProgram').get('measures')['controls']; let i = index;"
                                [formGroupName]="i">
                                <div class="dataset-measure fields-group" formGroupName="datasetMeasure">
                                    <div class="header">
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <h3>{{ 'word.measure_n' | translate:{count: i + 1} }}</h3>
                                            </div>
                                            <div>
                                                <button class="float-left"
                                                        tabindex="-1"
                                                        mat-icon-button
                                                        color="primary"
                                                        type="button"
                                                        (click)="removeMeasureGroup(i)">
                                                    <mat-icon>highlight_off</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="content">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <mat-form-field>
                                                    <mat-label>{{ 'word.description' | translate }}</mat-label>
                                                    <textarea matInput
                                                              #measureDescription
                                                              formControlName="description"
                                                              cdkTextareaAutosize
                                                              cdkAutosizeMinRows="1"
                                                              maxlength="{{ lengthValidators.genString }}"></textarea>
                                                    <mat-hint align="end">{{ measureDescription.value.length }}
                                                        / {{ lengthValidators.genString }}</mat-hint>
                                                    <mat-error
                                                        *ngIf="item.get('datasetMeasure.description').hasError('maxlength')">
                                                        {{ 'validator.field.too_long' | translate: '{ max: 250 }' }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <mat-form-field color="primary">
                                                    <mat-select (selectionChange)="executeCalculation()"
                                                                formControlName="technicalOrientation"
                                                                placeholder="{{ 'word.technical_orientation' | translate }}"
                                                    >
                                                        <mat-option>
                                                            <ngx-mat-select-search [formControl]="searchTechnicalOri"
                                                                                   placeholderLabel="{{ 'word.search' | translate }}"
                                                                                   noEntriesFoundLabel="{{'message.no_result'|translate}}">
                                                            </ngx-mat-select-search>
                                                        </mat-option>
                                                        <mat-option
                                                            *ngFor="let technicalOrientation of this.technicalOrientations"
                                                            [value]="technicalOrientation['@id']">
                                                            {{ technicalOrientation.name }}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-6">
                                                <mat-form-field
                                                    class="mat-form-field-appearance-legacy mat-form-field-disabled">
                                                    <input matInput
                                                           placeholder="{{ 'word.duration_of_use' | translate }}"
                                                           formControlName="durationOfUse"
                                                           [readonly]="true">
                                                    <span class="mat-input-suffix"
                                                          matSuffix>{{ 'word.years' | translate }}</span>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <mat-form-field>
                                                    <input matInput
                                                           placeholder="{{ 'word.unit_number' | translate }}"
                                                           formControlName="units"
                                                           type="number"
                                                           autocomplete="off"
                                                           min="{{ lengthValidators.minCost }}"
                                                           (change)="executeCalculation()">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-6">
                                                <mat-form-field
                                                    class="mat-form-field-appearance-legacy mat-form-field-disabled">
                                                    <input matInput
                                                           placeholder="{{ 'word.total_cost' | translate }}"
                                                           formControlName="totalCost"
                                                           type="number"
                                                           autocomplete="off"
                                                           (change)="executeCalculation()" [readonly]="true"
                                                           #totalCost
                                                    >
                                                    <span class="mat-input-suffix"
                                                          matSuffix>{{ 'word.chf' | translate }}</span>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <mat-form-field>
                                                    <input matInput
                                                           placeholder="{{ 'word.measure_cost' | translate }} / {{ 'word.unit' | translate | lowercase }}"
                                                           formControlName="cost"
                                                           type="number"
                                                           autocomplete="off"
                                                           min="{{ lengthValidators.minCost }}"
                                                           (change)="executeCalculation()">
                                                    <span class="mat-input-suffix"
                                                          matSuffix>{{ 'word.chf' | translate }}</span>
                                                    <mat-hint>
                                                     <span class="hint-help">
                                                      <mat-icon
                                                          matTooltip="{{ 'help.measure_measure_cost' | translate }}">help</mat-icon>
                                                    </span>
                                                    </mat-hint>
                                                    <mat-error
                                                        *ngIf="item.get('datasetMeasure.cost').hasError('max')">
                                                        {{ 'validator.field.max' | translate:{max: lengthValidators.maxMeasureCost} }}
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="item.get('datasetMeasure.cost').hasError('required')">
                                                        {{ 'validator.cost.not_blank' | translate }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-6">
                                                <mat-form-field>
                                                    <input matInput
                                                           placeholder="{{ 'word.payback_without_contribution' | translate }}"
                                                           value="{{ computedResume.measures[i]?.paybackWithoutContribution | positive }}"
                                                           disabled="disabled"
                                                           [readonly]="true">
                                                    <span class="mat-input-suffix"
                                                          matSuffix>{{ 'word.years' | translate }}</span>
                                                    <mat-hint>
                                                     <span class="hint-help">
                                                      <mat-icon
                                                          matTooltip="{{ 'help.measure_payback_without_contribution' | translate }}">help</mat-icon>
                                                    </span>
                                                    </mat-hint>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <mat-form-field>
                                                    <input matInput
                                                           placeholder="{{ 'word.requested_contribution' | translate }} / {{'word.unit' | translate | lowercase}}"
                                                           formControlName="requestedContribution"
                                                           type="number"
                                                           autocomplete="off"
                                                           (change)="executeCalculation()">
                                                    <span class="mat-input-suffix"
                                                          matSuffix>{{ 'word.chf' | translate }}</span>
                                                    <mat-error
                                                        *ngIf="item.get('datasetMeasure.requestedContribution').hasError('lessThan')">
                                                        {{ 'validator.field.max' | translate:{max: item.get('datasetMeasure.maximumContribution').value} }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-6">
                                                <mat-form-field
                                                    class="mat-form-field-appearance-legacy mat-form-field-disabled">
                                                    <input matInput
                                                           placeholder="{{ 'word.total_requested_contribution' | translate }}"
                                                           formControlName="totalRequestedContribution"
                                                           type="number"
                                                           autocomplete="off"
                                                           (change)="executeCalculation()" [readonly]="true"
                                                           #totalRequestedContribution
                                                    >
                                                    <span class="mat-input-suffix"
                                                          matSuffix>{{ 'word.chf' | translate }}</span>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <mat-form-field>
                                                    <input matInput
                                                           placeholder="{{ 'word.existing_annual_consumption' | translate }}"
                                                           formControlName="existingAnnualConsumption"
                                                           type="number"
                                                           autocomplete="off"
                                                           min="{{lengthValidators.positiveValue}}"
                                                           (change)="executeCalculation()">
                                                    <span class="mat-input-suffix"
                                                          matSuffix>{{ 'word.kwh' | translate }}</span>
                                                    <mat-error
                                                        *ngIf="item.get('datasetMeasure.existingAnnualConsumption').hasError('required')">
                                                        {{ 'validator.existing_annual_consumption.not_blank' | translate }}
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="item.get('datasetMeasure.existingAnnualConsumption').hasError('min')">
                                                        {{ 'validator.existing_annual_consumption.min' | translate:{min: item.get('datasetMeasure.existingAnnualConsumption').errors['min'].min} }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-6">
                                                <mat-form-field>
                                                    <input matInput
                                                           placeholder="{{ 'word.annual_saving' | translate }}"
                                                           value="{{ computedResume.measures[i]?.economyAnnual | positive }}"
                                                           disabled="disabled"
                                                           [readonly]="true">
                                                    <span class="mat-input-suffix"
                                                          matSuffix>{{ 'word.kwh' | translate }}</span>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <mat-form-field>
                                                    <input matInput
                                                           placeholder="{{ 'word.future_annual_consumption' | translate }}"
                                                           formControlName="futureAnnualConsumption"
                                                           type="number"
                                                           autocomplete="off"
                                                           min="{{lengthValidators.positiveValue}}"
                                                           (change)="executeCalculation()">
                                                    <span class="mat-input-suffix"
                                                          matSuffix>{{ 'word.kwh' | translate }}</span>
                                                    <mat-error
                                                        *ngIf="item.get('datasetMeasure.futureAnnualConsumption').hasError('min')">
                                                        {{ 'validator.future_annual_consumption.min' | translate:{min: item.get('datasetMeasure.futureAnnualConsumption').errors['min'].min} }}
                                                    </mat-error>
                                                    <mat-error
                                                        *ngIf="item.get('datasetMeasure.futureAnnualConsumption').hasError('lessThan')">
                                                        {{ 'validator.future_annual_consumption_must_be_lower_than_existing_annual_consumption' | translate }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-md-6">
                                                <mat-form-field
                                                    class="mat-form-field-appearance-legacy mat-form-field-disabled">
                                                    <input matInput
                                                           placeholder="{{ 'word.measure_total_savings' | translate }}"
                                                           value="{{ computedResume.measures[i]?.measureTotalEconomy | positive }}"
                                                           disabled="disabled"
                                                           [readonly]="true">
                                                    <span class="mat-input-suffix"
                                                          matSuffix>{{ 'word.kwh' | translate }}</span>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <!--                              <mat-form-field-->
                                                <!--                                class="mat-form-field-appearance-legacy mat-form-field-disabled">-->
                                                <!--                                <input matInput-->
                                                <!--                                       placeholder="{{ 'word.measure_annual_savings' | translate }}"-->
                                                <!--                                       value="{{ computedResume.measures[i]?.measureAnnualEconomy | positive }}"-->
                                                <!--                                       disabled="disabled"-->
                                                <!--                                       [readonly]="true">-->
                                                <!--                                <span class="mat-input-suffix"-->
                                                <!--                                      matSuffix>{{ 'word.kwh' | translate }}</span>-->
                                                <!--                              </mat-form-field>-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button mat-mini-fab type="button" color="primary" class="card-content-action"
                            (click)="addMeasure()">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>

                <div formGroupName="currentProgram">
                    <h2 class="mt-5">{{ 'title.analysis' | translate }}</h2>
                    <p class="mat-caption">{{ 'program_2022.analysis.p0' | translate }}</p>
                    <div formGroupName="analysisCosts" class="row">
                        <mat-form-field class="col-md-4">
                            <mat-label>{{'word.amount' | translate}}</mat-label>
                            <input matInput formControlName="units" type="number" autocomplete="off"
                                   (change)="executeCalculation()"/>
                        </mat-form-field>
                        <mat-form-field class="col-md-4">
                            <mat-label>{{'word.unit_price' | translate}}</mat-label>
                            <input matInput formControlName="unitPrice" type="number" autocomplete="off"
                                   (change)="executeCalculation()"/>
                            <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
                        </mat-form-field>
                        <mat-form-field class="col-md-4 mat-form-field-appearance-legacy mat-form-field-disabled">
                            <mat-label>{{'word.total_cost' | translate}}</mat-label>
                            <input matInput formControlName="totalCost" type="number" autocomplete="off"
                                   [readonly]="true"/>
                            <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
                        </mat-form-field>
                        <mat-form-field class="col-md-4">
                            <mat-label>{{'word.requested_contribution' | translate}}
                                / {{('word.unit' | translate) | lowercase}}</mat-label>
                            <input matInput formControlName="requestedContribution" type="number" autocomplete="off"
                                   (change)="executeCalculation()"/>
                            <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
                        </mat-form-field>
                        <mat-form-field class="col-md-4 mat-form-field-appearance-legacy mat-form-field-disabled">
                            <mat-label>{{'word.total_requested_contribution' | translate}}</mat-label>
                            <input matInput formControlName="totalRequestedContribution" type="number"
                                   autocomplete="off"
                                   [readonly]="true"/>
                            <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
                            <mat-error
                                *ngIf="currentProgramForm.get('analysisCosts.totalRequestedContribution').hasError('lessThan')">
                                {{ 'validator.requested_contribution.lt_cost' | translate }}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field class="col-md-4 mat-form-field-appearance-legacy mat-form-field-disabled">
                            <mat-label>{{'word.subsidy_rate' | translate}}</mat-label>
                            <input matInput formControlName="subsidyRate" type="number" autocomplete="off"
                                   [readonly]="true"/>
                            <span class="mat-input-suffix" matSuffix>%</span>
                        </mat-form-field>

                        <!--                  <mat-form-field class="col-md-4 mat-form-field-appearance-legacy mat-form-field-disabled">-->
                        <!--                    <mat-label>{{'word.support_rate' | translate}}</mat-label>-->
                        <!--                    <input matInput formControlName="supportRate" type="number" autocomplete="off"-->
                        <!--                           [readonly]="true"/>-->
                        <!--                    <span class="mat-input-suffix" matSuffix>%</span>-->
                        <!--                  </mat-form-field>-->
                    </div>
                </div>
            </app-card>

            <!--            <app-card cardNumber="6" formGroupName="currentProgram" cardTitle="word.analysis_costs">-->
            <!--                <div formGroupName="analysisCosts" class="row">-->
            <!--                    <mat-form-field class="col-md-4">-->
            <!--                        <mat-label>{{'word.amount' | translate}}</mat-label>-->
            <!--                        <input matInput formControlName="units" type="number" autocomplete="off"-->
            <!--                               (change)="executeCalculation()"/>-->
            <!--                    </mat-form-field>-->
            <!--                    <mat-form-field class="col-md-4">-->
            <!--                        <mat-label>{{'word.unit_price' | translate}}</mat-label>-->
            <!--                        <input matInput formControlName="unitPrice" type="number" autocomplete="off"-->
            <!--                               (change)="executeCalculation()"/>-->
            <!--                        <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>-->
            <!--                    </mat-form-field>-->
            <!--                    <mat-form-field class="col-md-4 mat-form-field-appearance-legacy mat-form-field-disabled">-->
            <!--                        <mat-label>{{'word.total_cost' | translate}}</mat-label>-->
            <!--                        <input matInput formControlName="totalCost" type="number" autocomplete="off" [readonly]="true"/>-->
            <!--                        <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>-->
            <!--                    </mat-form-field>-->
            <!--                    <mat-form-field class="col-md-4">-->
            <!--                        <mat-label>{{'word.requested_contribution' | translate}}-->
            <!--                            / {{('word.unit' | translate) | lowercase}}</mat-label>-->
            <!--                        <input matInput formControlName="requestedContribution" type="number" autocomplete="off"-->
            <!--                               (change)="executeCalculation()"/>-->
            <!--                        <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>-->
            <!--                    </mat-form-field>-->
            <!--                    <mat-form-field class="col-md-4 mat-form-field-appearance-legacy mat-form-field-disabled">-->
            <!--                        <mat-label>{{'word.total_requested_contribution' | translate}}</mat-label>-->
            <!--                        <input matInput formControlName="totalRequestedContribution" type="number" autocomplete="off"-->
            <!--                               [readonly]="true"/>-->
            <!--                        <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>-->
            <!--                    </mat-form-field>-->
            <!--                    <mat-form-field class="col-md-4 mat-form-field-appearance-legacy mat-form-field-disabled">-->
            <!--                        <mat-label>{{'word.subsidy_rate' | translate}}</mat-label>-->
            <!--                        <input matInput formControlName="subsidyRate" type="number" autocomplete="off"-->
            <!--                               [readonly]="true"/>-->
            <!--                        <span class="mat-input-suffix" matSuffix>%</span>-->
            <!--                    </mat-form-field>-->
            <!--                </div>-->
            <!--            </app-card>-->

            <!-- Documents -->
            <app-card cardNumber="6" cardTitle="title.documents">
                <div class="description mat-caption"
                     [innerHTML]="'program_2022.documents_description' | translate"></div>
                <app-media-objects-list [form]="currentProgramForm"
                                        [folderUuid]="folderProject ? folderProject.data.uuid : getUuid()"></app-media-objects-list>
            </app-card>

            <app-requirements-control [form]="form" computorName="program_2022"
                                      [sectionNumber]="7" [numberOfBoxes]="1"></app-requirements-control>
        </div>
        <div class="col-md-5">
            <app-sticky-container offsetTop="100">
                <!-- Summary -->
                <app-computor2022-program-summary-card
                    [computedValues]="computedResume"></app-computor2022-program-summary-card>

                <!-- Important dates -->
                <app-card cardTitle="title.important_dates">
                    <app-important-dates-list [tenderSession]="tenderSession"></app-important-dates-list>
                </app-card>

                <!-- Infos -->
                <app-card cardTitle="title.infos">
                    <div [innerHTML]="'program_2022.infos' | translate"></div>
                </app-card>
            </app-sticky-container>
        </div>
    </div>
</form>

<app-sticky-footer>
    <div class="justify-content-start">
        <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="save()"
                [loading]="loading.loading$ | async">
            {{ 'action.save' | translate }} <i class="icon-floppy-disk"></i>
        </button>

        <ng-container *ngIf="folderProgram">
            <button mat-flat-button color="primary" appMatButtonLoading (click)="exportPdf()"
                    [loading]="loading.loading$ | async">
                {{ 'action.pdf' | translate }} <i class="icon-pdf-file"></i>
            </button>
        </ng-container>
    </div>
    <div class="justify-content-end">
        <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="check()"
                [loading]="loading.loading$ | async">
            {{ 'action.check' | translate }} <i class="icon-checked"></i>
        </button>

        <button mat-flat-button color="primary" appMatButtonLoading [loading]="loading.loading$ | async"
                [disabled]="!isSubmittable" appConfirmDialog (confirmClick)="submit()"
                [dialogContent]="'dialog.confirm_submit_program.content'"
                [dialogConfirm]="'dialog.confirm_submit_program.confirm'"
                [dialogCancel]="'dialog.confirm_submit_program.cancel'">
            {{ 'action.submit_program' | translate }} <i class="icon-message"></i>
        </button>
    </div>
</app-sticky-footer>
