import { Injectable } from '@angular/core';


@Injectable()
export class UtilsService {
    public randomString(length: number, buffer = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'): string {
        let str = '';
        for (let i = 0; i < length; i++) {
            str += buffer.charAt(Math.floor(Math.random() * buffer.length));
        }

        return str;
    }

    public findItem(key: string, value: string, items: Record<any, any>[]): any {
        if (!items) {
            return null;
        }

        for (const item of items) {
            if (item[key] === value) {
                return item;
            }
        }

        return null;
    }
}
