import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class TagService {

    constructor(
        private apiService: ApiService
    ) { }

    public assignTagToFolder(folderId: string, tagIri: string): Observable<any> {
        const body = {
            tag: tagIri
        };
        return this.apiService.patch(`/folders/${folderId}/tag`, body);
    }

    public getAllTags(): Observable<any> {
        const params = new HttpParams().set('pagination', 'false');
        return this.apiService.get('/tags', {params});
    }

    public getDeletableTags(): Observable<any> {
        const params = new HttpParams().set('pagination', 'false');
        return this.apiService.get('/tags/deletables', {params});
    }

    public deleteTag($tagIri: string): Observable<any> {
        return this.apiService.delete($tagIri);
    }

    public createNewTag(tag: string): Observable<any> {
        const body = {
            name: tag
        };
        return this.apiService.post('/tags', body);
    }
}
