import { BaseDatasource } from './base.datasource';
import { environment } from '../../../environments/environment';
import { Paginator } from '../models/paginator.model';
import { Sort } from '@angular/material/sort';
import { TodoService } from '../services/todo.service';

export class TodoDatasource extends BaseDatasource {
    constructor(
        private todoService: TodoService
    ) {
        super();
    }

    public loadTODOsPaginated(
        viewArchived = false,
        page = 1,
        itemsPerPage: number = environment.itemsPerPage,
        showAssignedToFolder = false,
        search = '',
        sort?: Sort): void {

        this.todoService.getTODOsPaginated(viewArchived, page, itemsPerPage, showAssignedToFolder, search, sort)
            .subscribe((result: Paginator) => {
                this.totalItems = result['hydra:totalItems'];
                this.itemsPerPage = itemsPerPage;
                this.subject.next(result['hydra:member']);
            });
    }
    public loadFolderTODOsPaginated(
        folderId: string,
        viewArchived: boolean,
        page = 1,
        itemsPerPage: number = environment.itemsPerPage,
        search = '',
        sort?: Sort): void {

        this.todoService.getFolderTODOsPaginated(folderId,
            viewArchived, page, itemsPerPage, search, sort).subscribe((result: Paginator) => {
            this.totalItems = result['hydra:totalItems'];
            this.itemsPerPage = itemsPerPage;
            this.subject.next(result['hydra:member']);
        });
    }
}
