import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { DataCacheService } from './data-cache.service';
import { map } from 'rxjs/operators';


@Injectable()
export class SessionStateService extends DataCacheService {

    private itemsCacheKey = 'all.session.states.cached';

    constructor(
        private apiService: ApiService
    ) {
        super();
    }

    public get(params?: HttpParams): Observable<any> {
        return this.apiService.get('/session_states', params);
    }

    /**
     * Use memory cache to get/store all session
     * states. See environment cacheTTL.
     */
    public getCachedStates(): Observable<any> {

        // loading data cached
        const ret = this.getCache(this.itemsCacheKey) as Record<any, any>;
        if (ret){
            return of(ret);
        }

        // cache is empty, reload all data
        const params = new HttpParams().set('pagination', 'false');
        return this.apiService.get('/session_states', params).pipe(
            map((result: Record<any, any>) => {

                // store data to cache
                this.setCache(this.itemsCacheKey, result);
                return result;
            })
        );
    }
}
