import { NgModule } from '@angular/core';
import { DocumentsListComponent } from './documents-list/documents-list.component';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { FolderStatusComponent } from './folder-status/folder-status.component';
import { MatChipsModule } from '@angular/material/chips';
import { AdminFolderStatusComponent } from './admin-folder-status/admin-folder-status.component';
import { AdminFolderTagsComponent } from './admin-folder-tags/admin-folder-tags.component';
import { CommonModule } from '@angular/common';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MediaObjectsListComponent } from './media-objects-list/media-objects-list.component';
import { AdminFolderMilestoneComponent } from './admin-folder-milestone/admin-folder-milestone.component';
import { PipesModule } from '../core/pipes/pipes.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RemarkCardComponent } from './admin/remark-card/remark-card.component';
import { MatTableModule } from '@angular/material/table';
import { StickyContainerComponent } from './sticky-container/sticky-container.component';
import { InvoicesListComponent } from './invoices-list/invoices-list.component';
import { MatMenuModule } from '@angular/material/menu';
import { TagListComponent } from './tag-list/tag-list.component';
import { FilterListComponent } from './filter-list/filter-list.component';
import { RequirementsControlComponent } from './requirements-control/requirements-control.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CardComponent } from './card/card.component';
import { AdminManagersSelectionComponent } from './admin-managers-selection/admin-managers-selection.component';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';


@NgModule({
    declarations: [
        DocumentsListComponent,
        FolderStatusComponent,
        AdminFolderStatusComponent,
        AdminFolderTagsComponent,
        MediaObjectsListComponent,
        AdminFolderMilestoneComponent,
        RemarkCardComponent,
        StickyContainerComponent,
        InvoicesListComponent,
        TagListComponent,
        FilterListComponent,
        RequirementsControlComponent,
        CardComponent,
        AdminManagersSelectionComponent,
    ],
    imports: [
        SharedModule,
        MatCardModule,
        MatListModule,
        MatIconModule,
        MatButtonModule,
        TranslateModule,
        MatChipsModule,
        CommonModule,
        OverlayModule,
        MatTooltipModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatInputModule,
        PipesModule,
        MatDialogModule,
        MatDatepickerModule,
        MatTableModule,
        MatMenuModule,
        MatCheckboxModule,
        MatSelectModule,
        NgxMatSelectSearchModule
    ],
    providers: [],
    exports: [
        DocumentsListComponent,
        TranslateModule,
        FolderStatusComponent,
        AdminFolderStatusComponent,
        TagListComponent,
        FilterListComponent,
        AdminFolderTagsComponent,
        MediaObjectsListComponent,
        AdminFolderMilestoneComponent,
        RemarkCardComponent,
        StickyContainerComponent,
        InvoicesListComponent,
        RequirementsControlComponent,
        CardComponent,
        AdminManagersSelectionComponent
    ],
    bootstrap: [DocumentsListComponent]
})
export class ComponentsModule { }
