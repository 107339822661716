import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { FilterM } from '../models/filter.model';
import { HttpParams } from '@angular/common/http';
import { COMPUTOR_TYPE } from './folder.service';

@Injectable({
    providedIn: 'root'
})
export class FilterService {
    public selectedFilter: FilterM = null;

    constructor(
        private api: ApiService
    ) { }

    static getHttpParamsFromFilter(filter: FilterM, existingParams?: HttpParams): HttpParams {
        let params = new HttpParams();
        if (existingParams) {
            params = existingParams;
        }
        filter.filterContent.forEach(obj => {
            if (obj.search) { params = params.append('filter[' + obj.field.currentColumn + ']', obj.search); }
        });
        return params;
    }

    getFieldList(type: COMPUTOR_TYPE): Observable<any> {
        const params = new HttpParams().set('type', type);

        return this.api.get('/filter_fields', {params});
    }

    getFilterList(type?: COMPUTOR_TYPE): Observable<any> {
        if (!type) {
            return this.api.get('/filters');
        }

        const params = new HttpParams().set('type', type);

        return this.api.get('/filters', {params});
    }

    storeNewFilter(data: FilterM): Observable<any> {
        return this.api.post('/filters', data);
    }

    getOneFilter(filterIri: string): Observable<any> {
        return this.api.get(filterIri);
    }

    deleteOneFilter(filterIri: string): Observable<any> {
        return this.api.delete(filterIri);
    }

    putOneFilter(data: FilterM): Observable<any> {
        return this.api.put(data['@id'], data as Record<any, any>);
    }

    clearSelectedFilter(): void {
        this.selectedFilter = null;
    }

    deleteOneFilterContent(contentIri: string): Observable<any> {
        return this.api.delete(contentIri);
    }

}
