<h2 mat-dialog-title>{{ 'dialog.export_selection.title' | translate }}</h2>
<mat-dialog-content class="mat-typography">
    <form (keydown.enter)="$event.preventDefault()" [formGroup]="form">
        <div class="row">
            <div class="col-md-12">
                <mat-radio-group
                        (change)="onExporterChange($event)"
                        class="exporter-radio-group"
                        formControlName="exporter"
                >
                    <ng-container *ngFor="let item of exporters | keyvalue">
                        <h3>{{ 'dialog.export_selection.sub_title' | translate:{format: item.key} }}</h3>
                        <mat-radio-button *ngFor="let exporter of item.value" [value]="exporter">
                            {{ exporter.label | translate }}
                        </mat-radio-button>
                    </ng-container>

                    <mat-error *ngIf="form.get('exporter').hasError('selectionNotNull')">
                        {{ 'validator.exporter.exporter.selection_not_null' | translate }}
                    </mat-error>
                </mat-radio-group>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-md-12">
                <p><i [innerHTML]="'dialog.export_selection.session_message' | translate"></i></p>

                <mat-form-field class="">
                    <input matInput
                           type="text"
                           placeholder="{{ 'dialog.export_selection.folder_reference' | translate }}"
                           formControlName="computor"
                           [readonly]="!showComputorControl"
                    >
                    <mat-hint>
                        {{ 'dialog.export_selection.folder_reference_hint' | translate }}
                    </mat-hint>
                    <mat-error *ngIf="form.get('computor').hasError('notEmpty')">
                        {{ 'validator.exporter.computor.not_empty' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-dialog-close mat-flat-button>{{ 'action.cancel' | translate }}</button>
    <button (click)="save()" [disabled]="!form.valid" [loading]="loading.loading$ | async" appMatButtonLoading color="accent"
            mat-flat-button>{{ 'action.export' | translate }}</button>
</mat-dialog-actions>
