import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { RemarkDatasource } from '../../../core/datasources/remark.datasource';
import { RemarkService } from '../../../core/services/remark.service';
import { AddRemarkDialogComponent } from '../../../shared/add-remark-dialog/add-remark-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Remark } from '../../../core/models/remark.model';
import { FolderProjectResource } from '../../../core/models/folder-project-resource.model';
import { FolderProgramResource } from '../../../core/models/folder-program-resource.model';
import { MatTable } from '@angular/material/table';

@Component({
    selector: 'app-admin-remark-card',
    templateUrl: './remark-card.component.html',
    styleUrls: ['./remark-card.component.scss']
})
export class RemarkCardComponent implements OnInit {
    @Input()
    public cardNumber: number = null;

    @Input()
    public folder: FolderProjectResource|FolderProgramResource = null;

    @ViewChild(MatTable) private table: MatTable<any>;

    public remarkDatasource: RemarkDatasource;
    public displayedRemarksColumns = ['text', 'createdAt', 'user', 'actions'];

    private folderId: number;

    constructor(
        private remarkService: RemarkService,
        private dialog: MatDialog
    ) {
    }

    public ngOnInit(): void {
        this.folderId = this.folder.data.id;

        this.remarkDatasource = new RemarkDatasource(this.remarkService);
        this.remarkDatasource.loadRemarksByFolderId(this.folderId);
    }

    public showAddRemark(): void {
        const dialog = this.dialog.open(AddRemarkDialogComponent, {
            autoFocus: false,
            data: {
                folderId: this.folderId
            }
        });
        dialog.afterClosed().subscribe((data: {saved: boolean}) => {
            if (!data && !data.saved) {
                return;
            }

            this.remarkDatasource.loadRemarksByFolderId(this.folderId);
        });
    }

    public removeRemark(id: string): void {
        this.remarkService.deleteRemark(id).subscribe(() => {
            this.remarkDatasource.loadRemarksByFolderId(this.folderId);
        });
    }

    public editRemark(remark: Remark): void {
        const dialog = this.dialog.open(AddRemarkDialogComponent, {
            data: {
                folderId: this.folderId,
                remark
            }
        });
        dialog.afterClosed().subscribe((data: {saved: boolean; remark: Remark}) => {
            if (!data || !data.saved) {
                return;
            }

            this.remarkDatasource.updateItem(data.remark);
            this.table.renderRows();
        });
    }
}
