import { Component, Input, OnInit, OnChanges, ViewChild } from '@angular/core';
import { TenderSession } from '../../core/models/tender-session.model';
import { Router } from '@angular/router';
import { TransitionsService } from '../../core/services/transitions.service';
import { MatDialog } from '@angular/material/dialog';
import { AdminAddMilestoneDialogComponent } from '../../shared/admin-add-milestone-dialog/admin-add-milestone-dialog.component';
import { ReportService } from '../../core/services/report.service';
import { MilestoneService } from '../../core/services/milestone.service';
import { Milestone } from '../../core/models/milestone.model';
import { FolderProjectResource } from '../../core/models/folder-project-resource.model';
import { Step } from '../../core/models/step.model';
import { Report } from '../../core/models/report.model';
import { FolderService } from '../../core/services/folder.service';
import { ReportColumn } from '../../core/models/report-column.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { FolderProgramResource } from '../../core/models/folder-program-resource.model';
import { AuthorizationChecker } from '../../core/security/authorization-checker';
import { AdminNavUtil } from '../../core/utils/admin-nav.util';

@Component({
    selector: 'app-admin-folder-milestone',
    templateUrl: './admin-folder-milestone.component.html',
    styleUrls: ['./admin-folder-milestone.component.scss']
})
export class AdminFolderMilestoneComponent implements OnInit, OnChanges {
    @ViewChild(MatMenuTrigger)
    contextMenu: MatMenuTrigger;

    @Input()
    public tenderSession: TenderSession;

    @Input()
    public folder: FolderProjectResource|FolderProgramResource;

    @Input()
    public showingFolder = false;

    public milestonesAndReports: any[] = [];
    public createdMilestones: Milestone[] = [];
    public clickedReport: Report; // used in contextMenu only
    public clickedMs: Milestone; // used in contextMenu only
    public contextMenuPosition: { x: string; y: string } = {x: '0px', y: '0px'};

    constructor(
        private router: Router,
        public transitionsService: TransitionsService,
        private dialog: MatDialog,
        private reportService: ReportService,
        private milestoneService: MilestoneService,
        private folderService: FolderService,
        private authorizationChecker: AuthorizationChecker,
        private navUtil: AdminNavUtil
    ) { }

    public ngOnInit(): void {
        // first call handled by ngOnChanges
    }

    ngOnChanges(): void {
        this.getMilestones();
    }

    public getMilestones(): void {
        this.milestoneService.getByFolder(this.folder.data.id).subscribe((m: Milestone[]) => {
            this.milestonesAndReports = [...m, ...this.folder.reportSteps as Report[]];
            this.orderByDueDate();
        });
    }

    public showFolder(folder: FolderProjectResource): void {
        this.navUtil.showFolder(folder, this.tenderSession);
    }

    public viewOrEditReport(column: ReportColumn): void {

        console.log('viewOrEditReport')
        console.log(column)
        if ('Milestone' === column['@type']) {
            if (this.authorizationChecker.checkIsGranted('ROLE_STAFF_PKW')) {
                this.milestoneService.get(String(column['@id'])).subscribe(milestone => {
                    this.editMilestone(milestone);
                });
            }

            return;
        }

        const lastViewPermission = column.steps.slice().reverse().find(cStep => cStep['@permissions'].view === true);
        console.log(lastViewPermission)

        this.navUtil.showOrEditFolderReport(column, lastViewPermission, this.folder);
    }

    public viewOrEdit(step: Step): void {

        console.log('viewOrEdit')
        console.log(step)
        this.navUtil.showOrEditFolderStep(step, this.folder);
    }

    public addMilestone(): void {
        const dialog = this.dialog.open(AdminAddMilestoneDialogComponent);
        dialog.afterClosed().subscribe((data: {saved: boolean}) => {
            if (data && data.saved) {
                this.folderService.onFolderChange.emit('changed');
                this.getMilestones();
            }
        });
    }

    public editMilestone(milestone: Milestone = null, report: Report = null): void {
        if (this.clickedReport) {
            report = this.clickedReport;
        }
        if (this.clickedMs) {
            milestone = this.clickedMs;
        }
        const dialog = this.dialog.open(AdminAddMilestoneDialogComponent, {
            data: {
                report,
                milestone
            }
        });
        dialog.afterClosed().subscribe((data: {saved: boolean}) => {
            if (data && data.saved) {
                this.folderService.onFolderChange.emit('changed');
                this.getMilestones();
            }
        });
    }

    onContextMenu(event: MouseEvent, step: Report, ms: Milestone): void {
        event.preventDefault();
        this.contextMenuPosition.x = String(event.clientX) + 'px';
        this.contextMenuPosition.y = String(event.clientY) + 'px';
        this.contextMenu.menu.focusFirstItem('mouse');
        this.contextMenu.openMenu();
        if (step) {
            this.clickedReport = step;
            this.clickedMs = null;
        }
        if (ms) {
            this.clickedReport = null;
            this.clickedMs = ms;
        }
    }

    private orderByDueDate(): void {
        this.milestonesAndReports.sort((a: Milestone|Report, b: Milestone|Report) =>
            Math.abs(new Date(a.dueDate) as any) - Math.abs(new Date(b.dueDate) as any));
    }

    get currentFolderName(): string {
        if ('currentProject' in this.folder.data) {
            return this.folder.data.currentProject.name;
        }

        return this.folder.data.currentProgram.name;
    }

    get currentFolderAcronym(): string {
        if ('currentProject' in this.folder.data) {
            return this.folder.data.currentProject.acronym;
        }

        return this.folder.data.currentProgram.acronym;
    }
}
