import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { UserTenderSessionResource } from '../models/user-tender-session-resource.model';
import { HttpParams } from '@angular/common/http';


@Injectable()
export class UserTenderSessionResourceService {

    constructor(
        private apiService: ApiService
    ) {
    }

    public findAll(type: string = null): Observable<UserTenderSessionResource> {
        const paramsMap = new Map<any, any>();
        if (null !== type) {
            paramsMap.set('type', type);
        }

        let httpParams = new HttpParams();
        paramsMap.forEach((value: any, key: any) => {
            httpParams = httpParams.set(key, value);
        });

        return this.apiService.get('/user_tender_session_resources', {params: httpParams}).pipe(
            map((res) => res as UserTenderSessionResource)
        );
    }
}
