<div class="row">
    <div class="col-md-7">
        <!-- Project holder -->
        <div class="cs-margin">
            <app-card cardNumber="1" cardTitle="title.project_holder">
                <div class="row">
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.company_name' | translate }}</p>
                        <p>{{ folderProjectResource.data.folderHolder.companyName | default:'-' }}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.last_name' | translate }}</p>
                        <p>{{ folderProjectResource.data.folderHolder.lastName | default:'-' }}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.first_name' | translate }}</p>
                        <p>{{ folderProjectResource.data.folderHolder.firstName | default:'-' }}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.email' | translate }}</p>
                        <p>{{ folderProjectResource.data.folderHolder.email | default:'-' }}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.address' | translate }}</p>
                        <p>{{ folderProjectResource.data.folderHolder.address | default:'-' }}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.city' | translate }}</p>
                        <p>{{ folderProjectResource.data.folderHolder.city | default:'-' }}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.postal_code' | translate }}</p>
                        <p>{{ folderProjectResource.data.folderHolder.postalCode | default:'-' }}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.mobile_phone' | translate }}</p>
                        <p>{{ folderProjectResource.data.folderHolder.mobilePhone | default:'-' }}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.phone' | translate }}</p>
                        <p>{{ folderProjectResource.data.folderHolder.phone | default:'-' }}</p>
                    </div>

                </div>
            </app-card>
        </div>

        <!-- Project beneficiary -->
        <app-card cardNumber="2" cardTitle="title.project_beneficiary">
            <div class="row">
                <div class="col-md-4">
                    <p class="field-title">{{ 'word.company_name' | translate }}</p>
                    <p>{{ folderProjectResource.data.folderBeneficiary.companyName | default:'-' }}</p>
                </div>
                <div class="col-md-4">
                    <p class="field-title">{{ 'word.last_name' | translate }}</p>
                    <p>{{ folderProjectResource.data.folderBeneficiary.lastName | default:'-' }}</p>
                </div>
                <div class="col-md-4">
                    <p class="field-title">{{ 'word.first_name' | translate }}</p>
                    <p>{{ folderProjectResource.data.folderBeneficiary.firstName | default:'-' }}</p>
                </div>
                <div class="col-md-4">
                    <p class="field-title">{{ 'word.email' | translate }}</p>
                    <p>{{ folderProjectResource.data.folderBeneficiary.email | default:'-' }}</p>
                </div>
                <div class="col-md-4">
                    <p class="field-title">{{ 'word.address' | translate }}</p>
                    <p>{{ folderProjectResource.data.folderBeneficiary.address | default:'-' }}</p>
                </div>
                <div class="col-md-4">
                    <p class="field-title">{{ 'word.city' | translate }}</p>
                    <p>{{ folderProjectResource.data.folderBeneficiary.city | default:'-' }}</p>
                </div>
                <div class="col-md-4">
                    <p class="field-title">{{ 'word.postal_code' | translate }}</p>
                    <p>{{ folderProjectResource.data.folderBeneficiary.postalCode | default:'-' }}</p>
                </div>
                <div class="col-md-4">
                    <p class="field-title">{{ 'word.mobile_phone' | translate }}</p>
                    <p>{{ folderProjectResource.data.folderBeneficiary.mobilePhone | default:'-' }}</p>
                </div>
                <div class="col-md-4">
                    <p class="field-title">{{ 'word.phone' | translate }}</p>
                    <p>{{ folderProjectResource.data.folderBeneficiary.phone | default:'-' }}</p>
                </div>
            </div>
        </app-card>

        <!-- General -->
        <app-card cardNumber="3" cardTitle="title.general">
            <div class="row">
                <div class="col-md-6">
                    <p class="field-title">{{ 'word.project_name' | translate }}</p>
                    <p>{{ folderProjectResource.data.currentProject.name }}</p>
                </div>
                <div class="col-md-6">
                    <p class="field-title">{{ 'word.acronym' | translate }}</p>
                    <p>{{ folderProjectResource.data.currentProject.acronym }}</p>
                </div>
                <div class="col-md-6">
                    <p class="field-title">{{ 'word.average_cost_of_electricity' | translate }}</p>
                    <!--                        Divide by 100 because we want cents -->
                    <p>{{ folderProjectResource.data.currentProject.dataset.cost | default:'-' }} {{ 'word.cts_kwh' | translate }}</p>
                </div>
                <div class="col-md-6">
                    <p class="field-title">{{ 'word.requested_contribution' | translate }}</p>
                    <p>{{ folderProjectResource.data.currentProject.dataset.requestedContribution | currency | default:'-' }}</p>
                </div>
                <div class="col-md-6">
                    <p class="field-title">{{ 'word.start_date' | translate }}</p>
                    <p>{{ folderProjectResource.data.currentProject.startDate | date:'dd.MM.yyyy' | default:'-' }}</p>
                </div>
                <div class="col-md-6">
                    <p class="field-title">{{ 'word.end_date' | translate }}</p>
                    <p>{{ folderProjectResource.data.currentProject.endDate | date:'dd.MM.yyyy' | default:'-' }}</p>
                </div>
                <div class="col-md-6">
                    <p class="field-title">{{ 'word.planned_monitoring' | translate }}</p>
                    <p [innerHTML]="folderProjectResource.data.currentProject.plannedMonitoring | nl2br | default:'-'"></p>
                </div>
                <div class="col-md-6">
                    <p class="field-title">{{ 'word.additionality' | translate }}</p>
                    <p [innerHTML]="folderProjectResource.data.currentProject.additionality | nl2br | default:'-'"></p>
                </div>
                <div class="mt-2 col-md-12">
                    <p class="field-title">{{ 'word.description' | translate }}</p>
                    <p class="text-justify"
                       [innerHTML]="folderProjectResource.data.currentProject.description | nl2br | default:'-'"></p>
                </div>
            </div>
        </app-card>

        <app-card cardNumber="4" cardTitle="title.measures">
            <div *ngFor="let measure of folderProjectResource.data.currentProject.measures; let i = index">
                <div class="dataset-measure fields-group">
                    <div class="header">
                        <h2>{{ 'word.measure_n' | translate:{count: i + 1} }}</h2>
                    </div>
                    <div class="content">
                        <div class="row">
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.technical_orientation' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.technicalOrientation ? measure.datasetMeasure.technicalOrientation.name : '-' }}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.duration_of_use' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.durationOfUse | default:'-' }} {{ 'word.years' | translate }}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.measure_cost' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.cost | currency:'CHF':'symbol':'0.0-2' | default:'-' }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.maximum_contribution' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.maximumContribution | currency:'CHF':'symbol':'0.0-2' | default:'-' }}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.existing_annual_consumption' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.existingAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.future_annual_consumption' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.futureAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.annual_saving' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.annualSavings | positive | number | default: '-': ('word.kwh' | translate) }}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.total_saving' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.totalSavings | positive | number | default: '-': ('word.kwh' | translate) }}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.payback_without_contribution' | translate }}</p>
                                <p class="text-justify">{{ 'word.n_years' | translate:{count: measure.datasetMeasure.paybackWithoutContribution | round} | default:'-' }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p class="field-title">{{ 'word.description' | translate }}</p>
                                <p class="text-justify"
                                   [innerHTML]="measure.datasetMeasure.description | nl2br | default:'-'"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-card>

        <app-documents-list id="section5" [mediaObjects]="folderProjectResource.data.currentProject.mediaObjects"
                            [sectionNumber]="5" [folderUuid]="folderProjectResource.data.uuid"></app-documents-list>
    </div>
    <div class="col-md-5" #stickyBoxWidth>
        <app-sticky-container>
            <app-computor2023-project-summary-card
                    [folder]="folderProjectResource"></app-computor2023-project-summary-card>
        </app-sticky-container>
    </div>
</div>

<app-todo [folder]="folderProjectResource" *appIsGranted="'ROLE_STAFF_PKW'"></app-todo>

<app-sticky-footer>
    <div>
        <ng-container *ngIf="folderProjectResource">
            <button mat-flat-button color="primary" appMatButtonLoading (click)="exportPdf()"
                    [loading]="loading.loading$ | async">
                {{ 'action.pdf' | translate }} <i class="icon-pdf-file"></i>
            </button>
        </ng-container>
    </div>
</app-sticky-footer>
