import { Component } from '@angular/core';
import { PageTitleService } from '../../../../core/services/page-title.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {
    constructor(
        pageTitleService: PageTitleService,
    ) {
        pageTitleService.title('title.dashboard');
    }
}
