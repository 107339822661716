<app-card classes="card-header py-4 p-2 mb-2">
    <div class="col-md-12 d-flex justify-content-between">
        <i class="header-icon icon-clipboard"></i>
        <button mat-flat-button class="mat-accent" (click)="gotoReports()">
            {{ 'title.every_reports' | translate }}
        </button>
    </div>
    <div class="col-md-12 card-content d-flex justify-content-between mt-4">
        <p class="card-title">{{ 'menu.reports' | translate }}</p>
        <p class="number-badge">{{ dataSource.totalItems }}</p>
    </div>
</app-card>
<app-card classes="card-content overflow-x">
    <div *ngIf="(loading.loading$ | async)" class="w-100 d-flex align-items-center justify-content-center">
        <mat-spinner diameter="20"></mat-spinner>
    </div>
    <table [dataSource]="dataSource" class="col-md-12" mat-table *ngIf="false === (loading.loading$ | async)">
        <ng-container matColumnDef="folderReference">
            <th *matHeaderCellDef mat-header-cell>{{ 'title.project' | translate }}</th>
            <td *matCellDef="let element" mat-cell>{{element.folder.reference}}</td>
        </ng-container>
        <ng-container matColumnDef="name">
            <th *matHeaderCellDef mat-header-cell>{{ 'word.report_name' | translate }}</th>
            <td *matCellDef="let element" mat-cell>{{element.name}}</td>
        </ng-container>
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef> {{ 'word.state' | translate }} </th>
            <td mat-cell *matCellDef="let element">
                <app-folder-status [state]="element.marking"></app-folder-status>
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th *matHeaderCellDef class="text-right" mat-header-cell></th>
            <td *matCellDef="let element" class="text-right" mat-cell>
                <button (click)="showReport(element.folder['@id'], element['@id'], element.lastReportId)"
                        [matTooltip]="'action.show' | translate"
                        mat-icon-button>
                    <i class="action-icon icon-eye"></i>
                </button>
            </td>
        </ng-container>
        <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
        <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
    </table>
</app-card>
