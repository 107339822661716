import { User } from '../models/user.model';

export interface VoterInterface {
    /**
     * Returns the vote for the given parameters.
     *
     * This method must return one of the following constants:
     * ACCESS_GRANTED, ACCESS_DENIED, or ACCESS_ABSTAIN.
     *
     * @param currentUser The authenticated user
     * @param attributes  An array of attributes associated with the method being invoked
     * @param subject     The subject to secure
     *
     * @return int either ACCESS_GRANTED, ACCESS_ABSTAIN, or ACCESS_DENIED
     */
    vote(currentUser: User, attributes: string[], subject: any): number;
}

export abstract class VoterInterfaceConstants {
    public static readonly ACCESS_GRANTED: number = 1;
    public static readonly ACCESS_ABSTAIN: number = 0;
    public static readonly ACCESS_DENIED: number = -1;
}
