import { TenderSession } from '../core/models/tender-session.model';
import { FolderProjectResource } from '../core/models/folder-project-resource.model';
import { EventEmitter } from '@angular/core';
import { DirtyFormWarnableInterface } from '../components/form-component/dirty-form.component';
import { FormGroup } from '@angular/forms';
import { SwitchUserService } from '../core/services/switch-user.service';
import { FolderProgramResource } from '../core/models/folder-program-resource.model';
export abstract class AbstractComputor implements DirtyFormWarnableInterface {
    form: FormGroup;

    public computorName: string;
    public computorType: string;

    public tenderSession: TenderSession;
    public folderProject: FolderProjectResource;
    public folderProgram: FolderProgramResource;

    public isReadyToSubmit = false;
    public isSubmittable = false;
    public formSavedEvent: EventEmitter<string> = new EventEmitter<string>();

    public startDateMin: Date = new Date();
    public startDateMax: Date = new Date();
    public endDateMax: Date = new Date();

    constructor(protected switchUserService: SwitchUserService) {
    }

    public setTenderSession(tenderSession: TenderSession): void  {
        this.tenderSession = tenderSession;
    }

    public setFolderProject(folderProject: FolderProjectResource): void  {
        this.folderProject = folderProject;
    }

    public setFolderProgram(folderProgram: FolderProgramResource): void {
        this.folderProgram = folderProgram;
    }

    public getNextTransition(folder: FolderProjectResource|FolderProgramResource): string {
        return this.switchUserService.nextTransition ?
            this.switchUserService.nextTransition :
            folder.nextTransition
        ;
    }

    protected setMinAndMaxDatesFromDecisionDate(): void {
        if (this.tenderSession.continuouslySubmittedSession) {
            this.startDateMin = new Date(this.tenderSession.openingDate);
            this.startDateMax = new Date(this.tenderSession.submissionEndDate);
            this.endDateMax = new Date(this.tenderSession.submissionEndDate);
        } else {
            this.startDateMin = new Date(this.tenderSession.openingDate);
            this.startDateMax = new Date(this.tenderSession.maxStartDate);
            this.endDateMax = new Date(this.tenderSession.maxEndDate);
        }
    }
}

export default AbstractComputor;
