import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { ComputorFactory } from '../../../../computors/computor.factory';
import { PageTitleService } from '../../../../core/services/page-title.service';
import { ActivatedRoute } from '@angular/router';
import { TenderSession } from '../../../../core/models/tender-session.model';
import { FolderProgramResource } from '../../../../core/models/folder-program-resource.model';

@Component({
    selector: 'app-project',
    templateUrl: './program-report-show-step.component.html',
    styleUrls: ['./program-report-show-step.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgramReportShowStepComponent implements AfterViewInit, AfterViewChecked {
    @ViewChild('computorComponentRef', {
        read: ViewContainerRef
    })
    public viewContainerRef: ViewContainerRef;

    public tenderSession: TenderSession;
    public folderProgram: FolderProgramResource = null;

    constructor(
        private pageTitleService: PageTitleService,
        private cdr: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        @Inject(ComputorFactory) private factory: ComputorFactory,
    ) {
        pageTitleService.title(''); // needed to hide title and show breadcrumb. => find a better way to do this.
        this.tenderSession = this.activatedRoute.parent.snapshot.data.tenderSession as TenderSession;
        this.folderProgram = this.activatedRoute.snapshot.data.folderProgramResource as FolderProgramResource;
    }

    public ngAfterViewInit(): void {
        const component =
            this.factory.loadComputorComponent(this.viewContainerRef, this.tenderSession.tenderModel.computerName, 'ProgramReportShowStepComponent');
        component.setTenderSession(this.tenderSession);
        component.setFolderProgram(this.folderProgram);
    }

    public ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }

}
