import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangeFolderStateDialogComponent } from '../../shared/change-folder-state-dialog/change-folder-state-dialog.component';
import { FolderService } from '../../core/services/folder.service';
import { ChangeReportStateDialogComponent } from '../../shared/change-report-state-dialog/change-report-state-dialog.component';
import { FolderProjectResource } from '../../core/models/folder-project-resource.model';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthorizationChecker } from '../../core/security/authorization-checker';
import { FolderProgramResource } from '../../core/models/folder-program-resource.model';

@Component({
    selector: 'app-admin-folder-status',
    templateUrl: './admin-folder-status.component.html',
    styleUrls: ['./admin-folder-status.component.scss']
})
export class AdminFolderStatusComponent implements OnInit, OnDestroy {
    public showedMarking: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public showedMarking$ = this.showedMarking.asObservable();

    private destroySubject: Subject<boolean> = new Subject<boolean>();

    constructor(
        public folderService: FolderService,
        private dialog: MatDialog,
        public authorizationChecker: AuthorizationChecker
    ) { }

    ngOnInit(): void {
        this.folderService.currentProjectOrProgram$.pipe(
            takeUntil(this.destroySubject)
        ).subscribe((folder) => {
            this.setMarking(folder);
        });
    }

    setMarking(folder: FolderProjectResource|FolderProgramResource): void {
        if (!folder) {
            return;
        }

        if (this.folderService.getReportResource(folder)) {
            this.showedMarking.next('workflow.admin.' + this.folderService.getReportResource(folder).data.marking);
            return;
        }

        this.showedMarking.next('workflow.admin.' + folder.data.marking);
    }

    openModal(): void {
        if (this.folderService.getReportResource()) { // if showing a report
            this.dialog.open(ChangeReportStateDialogComponent, {
                autoFocus: false
            });
        } else {
            this.dialog.open(ChangeFolderStateDialogComponent, {
                autoFocus: false
            });
        }
    }

    public ngOnDestroy(): void {
        this.destroySubject.next(true);
        this.destroySubject.complete();
    }
}
