<form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
    <div class="row">
        <div class="col-md-7">
            <!-- Measures -->
            <app-card cardNumber="1" cardTitle="title.measures">
                <div class="folder" formGroupName="currentReport">
                    <div class="measures" formArrayName="reportMeasures">
                        <div *ngFor="let item of form.get('currentReport').get('reportMeasures')['controls']; let i = index;"
                             [formGroupName]="i">
                            <div class="dataset-measure fields-group" formGroupName="datasetMeasure">
                                <div class="header">
                                    <div class="d-flex justify-content-between">
                                        <div>
                                            <h2>{{ 'word.measure_n' | translate:{count: i + 1} }}</h2>
                                        </div>
                                        <div>
                                            <button class="float-left"
                                                    tabindex="-1"
                                                    mat-icon-button
                                                    color="primary"
                                                    type="button"
                                                    (click)="removeMeasureGroup(i)">
                                                <mat-icon>highlight_off</mat-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="content">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <mat-form-field color="primary">
                                                <mat-select (selectionChange)="executeCalculation()"
                                                            formControlName="technicalOrientation"
                                                            placeholder="{{ 'word.technical_orientation' | translate }}"
                                                >
                                                    <mat-option>
                                                        <ngx-mat-select-search [formControl]="searchTechnicalOri"
                                                                               placeholderLabel="{{ 'word.search' | translate }}"
                                                                               noEntriesFoundLabel="{{'message.no_result'|translate}}">
                                                        </ngx-mat-select-search>
                                                    </mat-option>
                                                    <mat-option
                                                            *ngFor="let technicalOrientation of this.technicalOrientations"
                                                            [value]="technicalOrientation['@id']">
                                                        {{ technicalOrientation ? technicalOrientation.name : '-' }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-form-field
                                                    class="mat-form-field-appearance-legacy mat-form-field-disabled">
                                                <input matInput
                                                       placeholder="{{ 'word.duration_of_use' | translate }}"
                                                       formControlName="durationOfUse"
                                                       [readonly]="true">
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.years' | translate }}</span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <mat-form-field>
                                                <input matInput
                                                       placeholder="{{ 'word.measure_cost' | translate }}"
                                                       formControlName="cost"
                                                       type="number"
                                                       autocomplete="off"
                                                       min="{{ lengthValidators.minCost }}"
                                                       (change)="executeCalculation()">
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.chf' | translate }}</span>
                                                <mat-hint>
                             <span class="hint-help">
                              <mat-icon matTooltip="{{ 'help.measure_measure_cost' | translate }}">help</mat-icon>
                            </span>
                                                </mat-hint>
                                                <mat-error *ngIf="item.get('datasetMeasure.cost').hasError('required')">
                                                    {{ 'validator.cost.not_blank' | translate }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-form-field
                                                    class="mat-form-field-appearance-legacy mat-form-field-disabled">
                                                <input matInput
                                                       placeholder="{{ 'word.maximum_contribution' | translate }}"
                                                       formControlName="maximumContribution"
                                                       type="number"
                                                       autocomplete="off"
                                                       min="{{ lengthValidators.positiveValue }}"
                                                       [readonly]="true"
                                                       (change)="executeCalculation()">
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.chf' | translate }}</span>
                                                <mat-hint>
                             <span class="hint-help">
                              <mat-icon
                                      matTooltip="{{ 'help.measure_maximum_support_contribution' | translate }}">help</mat-icon>
                            </span>
                                                </mat-hint>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <mat-form-field>
                                                <input matInput
                                                       placeholder="{{ 'word.existing_annual_consumption' | translate }}"
                                                       formControlName="existingAnnualConsumption"
                                                       type="number"
                                                       autocomplete="off"
                                                       min="{{lengthValidators.positiveValue}}"
                                                       (change)="executeCalculation()">
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.kwh' | translate }}</span>
                                                <mat-error
                                                        *ngIf="item.get('datasetMeasure.existingAnnualConsumption').hasError('required')">
                                                    {{ 'validator.existing_annual_consumption.not_blank' | translate }}
                                                </mat-error>
                                                <mat-error
                                                        *ngIf="item.get('datasetMeasure.existingAnnualConsumption').hasError('min')">
                                                    {{ 'validator.existing_annual_consumption.min' | translate:{min: item.get('datasetMeasure.existingAnnualConsumption').errors['min'].min} }}
                                                </mat-error>

                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-form-field>
                                                <input matInput
                                                       placeholder="{{ 'word.annual_saving' | translate }}"
                                                       value="{{ computedResume?.measures[i]?.economyAnnual | positive }}"
                                                       disabled="disabled"
                                                       [readonly]="true">
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.kwh' | translate }}</span>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <mat-form-field>
                                                <input matInput
                                                       placeholder="{{ 'word.future_annual_consumption' | translate }}"
                                                       formControlName="futureAnnualConsumption"
                                                       type="number"
                                                       autocomplete="off"
                                                       min="{{lengthValidators.positiveValue}}"
                                                       (change)="executeCalculation()">
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.kwh' | translate }}</span>
                                                <mat-error
                                                        *ngIf="item.get('datasetMeasure.futureAnnualConsumption').hasError('min')">
                                                    {{ 'validator.future_annual_consumption.min' | translate:{min: item.get('datasetMeasure.futureAnnualConsumption').errors['min'].min} }}
                                                </mat-error>
                                                <mat-error
                                                        *ngIf="item.get('datasetMeasure.futureAnnualConsumption').hasError('lessThan')">
                                                    {{ 'validator.future_annual_consumption_must_be_lower_than_existing_annual_consumption' | translate }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6">
                                            <mat-form-field>
                                                <input matInput
                                                       placeholder="{{ 'word.total_saving' | translate }}"
                                                       value="{{ computedResume?.measures[i]?.economyTotal | positive }}"
                                                       disabled="disabled"
                                                       [readonly]="true">
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.kwh' | translate }}</span>
                                                <mat-hint>
                             <span class="hint-help">
                              <mat-icon matTooltip="{{ 'help.measure_total_saving' | translate }}">help</mat-icon>
                            </span>
                                                </mat-hint>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-md-6 offset-md-6">
                                            <mat-form-field>
                                                <input matInput
                                                       placeholder="{{ 'word.payback_without_contribution' | translate }}"
                                                       value="{{ computedResume?.measures[i]?.paybackWithoutContribution | positive }}"
                                                       disabled="disabled"
                                                       [readonly]="true">
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.years' | translate }}</span>
                                                <mat-hint>
                             <span class="hint-help">
                              <mat-icon
                                      matTooltip="{{ 'help.measure_payback_without_contribution' | translate }}">help</mat-icon>
                            </span>
                                                </mat-hint>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-12">
                                            <mat-form-field>
                                                <mat-label>{{ 'word.description' | translate }}</mat-label>
                                                <textarea matInput
                                                          #measureDescription
                                                          formControlName="description"
                                                          cdkTextareaAutosize
                                                          cdkAutosizeMinRows="5"
                                                          maxlength="{{ lengthValidators.longString }}"></textarea>
                                                <mat-hint
                                                        align="start">{{'word.report_measure_description' | translate}}</mat-hint>
                                                <mat-hint align="end">{{ measureDescription.value.length }}
                                                    / {{ lengthValidators.longString }}</mat-hint>
                                                <mat-error
                                                        *ngIf="item.get('datasetMeasure.description').hasError('maxlength')">
                                                    {{ 'validator.field.too_long' | translate: '{ max: 4000 }' }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button mat-mini-fab type="button" color="primary" class="card-content-action" (click)="addMeasure()">
                    <mat-icon>add</mat-icon>
                </button>
            </app-card>
            <!-- Requested contribution -->
            <app-card cardNumber="2" cardTitle="title.requested_contribution">
                <div class="folder" formGroupName="currentReport">
                    <div class="dataset" formGroupName="dataset">
                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field class="col-md-6">
                                    <input matInput
                                           placeholder="{{ 'word.requested_contribution' | translate }}"
                                           formControlName="requestedContribution"
                                           type="number"
                                           autocomplete="off"
                                           (change)="executeCalculation()">
                                    <mat-hint>
                                        {{'word.subsidy_rate' | translate}}
                                        : {{ computedResume?.subsidyRate | positive | round }} %
                                        <span class="hint-help">
                        <mat-icon
                                matTooltip="{{ 'help.measure_maximum_support_contribution' | translate }}">help</mat-icon>
                      </span>
                                    </mat-hint>
                                    <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>

                                    <mat-error
                                            *ngIf="form.controls['currentProject'].get('dataset').get('requestedContribution').hasError('min')">
                                        {{ 'validator.requested_contribution.min' | translate:{min: form.controls['currentProject'].get('dataset').get('requestedContribution').errors['min'].min} }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="col-md-6">
                                    <input matInput
                                           placeholder="{{ 'word.maximum_contribution' | translate }}"
                                           value="{{ computedResume?.maximumContribution | round }}"
                                           disabled="disabled"
                                           [readonly]="true">
                                    <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
                                    <mat-hint>
                                            <span class="hint-help">
                                                <mat-icon
                                                        matTooltip="{{ 'help.report_maximum_support_contribution' | translate }}">help</mat-icon>
                                            </span>
                                    </mat-hint>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </app-card>
        </div>
        <div class="col-md-5">
            <app-sticky-container offsetTop="100">
                <!-- Summary -->
                <app-card cardTitle="title.summary">
                    <div class="dataset" formGroupName="currentProject">
                        <div class="indicators-row d-flex justify-content-between align-items-center">
                            <div class="indicator-box important-indicator d-flex align-items-center">
                                <div class="icon">
                                    <i class="icon-chart"></i>
                                </div>
                                <div class="indicator" matTooltip="{{ 'help.cost_utility_ratio' | translate }}">
                                    <p>{{ 'word.cost_utility_ratio' | translate }}</p>
                                    <p>{{ computedResume?.costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}</p>
                                </div>
                            </div>
                            <div class="indicator-box d-flex align-items-center">
                                <div class="icon">
                                    <i class="icon-save-time"></i>
                                </div>
                                <div class="indicator" matTooltip="{{ 'help.measure_total_saving' | translate }}">
                                    <p>{{ 'word.total_saving' | translate }}</p>
                                    <p>{{ computedResume?.economyTotal | positive | number }} {{ 'word.kwh' | translate }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="indicators-row d-flex justify-content-between align-items-center">
                            <div class="indicator-box d-flex align-items-center">
                                <div class="icon">
                                    <i class="icon-management"></i>
                                </div>
                                <div class="indicator">
                                    <p>{{ 'word.total_cost' | translate }}</p>
                                    <p>{{ computedResume?.totalCost | round | currency:defaultCurrency:'symbol':'1.0'  }}</p>
                                </div>
                            </div>
                            <div class="indicator-box d-flex align-items-center">
                                <div class="icon shorticon">
                                    <i class="icon-loss"></i>
                                </div>
                                <div class="indicator"
                                     matTooltip="{{ 'help.summary_payback_without_contribution' | translate }}">
                                    <p>{{ 'word.payback_without_contribution' | translate }}</p>
                                    <p>{{ 'word.n_years' | translate:{count: computedResume?.paybackWithoutContribution | positive} }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="indicators-row d-flex justify-content-between align-items-center">
                            <div class="indicator-box d-flex align-items-center">
                                <div class="icon">
                                    <i class="icon-stats"></i>
                                </div>
                                <div class="indicator"
                                     matTooltip="{{ 'help.report_maximum_support_contribution' | translate }}">
                                    <p>{{ 'word.maximum_contribution' | translate }}</p>
                                    <p>{{ computedResume?.maximumContribution | round | currency:defaultCurrency:'symbol':'1.0' }}</p>
                                </div>
                            </div>
                            <div class="indicator-box d-flex align-items-center">
                                <div class="icon shorticon">
                                    <i class="icon-revenue-up"></i>
                                </div>
                                <div class="indicator">
                                    <p>{{ 'word.payback_with_contribution' | translate }}</p>
                                    <p>{{ 'word.n_years' | translate:{count: computedResume?.paybackWithContribution | positive} }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="indicators-row d-flex justify-content-between align-items-center">
                            <div class="indicator-box d-flex align-items-center">
                                <div class="icon shorticon">
                                    <i class="icon-revenue"></i>
                                </div>
                                <div class="indicator">
                                    <p>{{ 'word.requested_contribution' | translate }}</p>
                                    <p>{{ computedResume?.requestedContribution | round | currency:defaultCurrency:'symbol':'1.0' }}</p>
                                </div>
                            </div>
                            <div class="indicator-box d-flex align-items-center">
                                <div class="icon shorticon">
                                    <i class="icon-power"></i>
                                </div>
                                <div class="indicator">
                                    <p>{{ 'word.percent_saving' | translate }}</p>
                                    <p>{{ computedResume?.economyPercent | positive | round:2 }} %</p>
                                </div>
                            </div>
                        </div>

                        <div class="indicators-row d-flex justify-content-between align-items-center">
                            <div class="indicator-box d-flex align-items-center">
                                <div class="icon shorticon">
                                    <i class="icon-revenue"></i>
                                </div>
                                <div class="indicator"
                                     matTooltip="{{ 'help.measure_maximum_support_contribution' | translate }}">
                                    <p>{{ 'word.subsidy_rate' | translate }}</p>
                                    <p>{{ computedResume?.subsidyRate | round:2 | positive }} %</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </app-card>
            </app-sticky-container>
        </div>
        <div class="col-md-7" id="section3">
            <app-card cardTitle="title.documents">
                <app-media-objects-list [form]="currentReportForm"
                                        [reportIri]="projectReportResource.data.currentReport.report['@id']"></app-media-objects-list>
            </app-card>
        </div>
        <div class="col-md-7">
            <app-invoices-list [sectionNumber]="4" [report]="projectReportResource"
                               [hideControls]="!reportNotCompleted"></app-invoices-list>
        </div>
    </div>
</form>

<app-sticky-footer>
    <div class="justify-content-start">
        <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="save()"
                [loading]="loading.loading$ | async">
            {{ 'action.save' | translate }} <i class="icon-floppy-disk"></i>
        </button>
        <button mat-flat-button color="primary" appMatButtonLoading (click)="exportPdf()"
                [loading]="loading.loading$ | async">
            {{ 'action.pdf' | translate }} <i class="icon-pdf-file"></i>
        </button>
    </div>
    <div class="justify-content-end">
        <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="check()"
                [loading]="loading.loading$ | async">
            {{ 'action.check' | translate }} <i class="icon-checked"></i>
        </button>

        <button mat-flat-button color="primary" [disabled]="!isSubmittable" appMatButtonLoading
                [loading]="loading.loading$ | async" appConfirmDialog (confirmClick)="submit()"
                [dialogContent]="'dialog.confirm_submit_report.content'"
                [dialogConfirm]="'dialog.confirm_submit_project.confirm'"
                [dialogCancel]="'dialog.confirm_submit_project.cancel'">
            {{ 'action.submit_report' | translate }} <i class="icon-message"></i>
        </button>
    </div>
</app-sticky-footer>
