<!-- Milestones -->
<app-folder-detail id="section1" [tenderSession]="folder.tenderSession" [folder]="folder"
                   [showingFolder]="true"></app-folder-detail>

<!-- Project holder -->
<app-card cardNumber="2" cardTitle="title.project_holder">
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.company_name' | translate }}</p>
            <p>{{ folder.folderHolder.companyName | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.last_name' | translate }}</p>
            <p>{{ folder.folderHolder.lastName | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.first_name' | translate }}</p>
            <p>{{ folder.folderHolder.firstName | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.email' | translate }}</p>
            <p>{{ folder.folderHolder.email | default:'-' }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.address' | translate }}</p>
            <p>{{ folder.folderHolder.address | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.city' | translate }}</p>
            <p>{{ folder.folderHolder.city | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.postal_code' | translate }}</p>
            <p>{{ folder.folderHolder.postalCode | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.mobile_phone' | translate }}</p>
            <p>{{ folder.folderHolder.mobilePhone | default:'-' }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.phone' | translate }}</p>
            <p>{{ folder.folderHolder.phone | default:'-' }}</p>
        </div>
    </div>
</app-card>

<app-card cardNumber="3" cardTitle="title.project_beneficiary">
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.company_name' | translate }}</p>
            <p>{{ folder.folderBeneficiary.companyName | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.last_name' | translate }}</p>
            <p>{{ folder.folderBeneficiary.lastName | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.first_name' | translate }}</p>
            <p>{{ folder.folderBeneficiary.firstName | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.email' | translate }}</p>
            <p>{{ folder.folderBeneficiary.email | default:'-' }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.address' | translate }}</p>
            <p>{{ folder.folderBeneficiary.address | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.city' | translate }}</p>
            <p>{{ folder.folderBeneficiary.city | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.postal_code' | translate }}</p>
            <p>{{ folder.folderBeneficiary.postalCode | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.mobile_phone' | translate }}</p>
            <p>{{ folder.folderBeneficiary.mobilePhone | default:'-' }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.phone' | translate }}</p>
            <p>{{ folder.folderBeneficiary.phone | default:'-' }}</p>
        </div>
    </div>
</app-card>

<!-- General -->
<app-card cardNumber="4" cardTitle="title.general">
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.project_name' | translate }}</p>
            <p>{{ folder.projects[0].name | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.acronym' | translate }}</p>
            <p>{{ folder.projects[0].acronym | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.average_cost_of_electricity' | translate }}</p>
            <p>{{ folder.projects[0].dataset.cost | default:'-' }} {{ 'word.cts_kwh' | translate }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.requested_contribution' | translate }}</p>
            <p>{{ folder.projects[0].dataset.requestedContribution | currency | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.start_date' | translate }}</p>
            <p>{{ folder.projects[0].startDate | date:'dd.MM.yyyy' | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.end_date' | translate }}</p>
            <p>{{ folder.projects[0].endDate | date:'dd.MM.yyyy' | default:'-' }}</p>
        </div>
    </div>
    <div class="row">
        <div class="mt-2 col-md-6">
            <p class="field-title">{{ 'word.planned_monitoring' | translate }}</p>
            <p class="text-justify" [innerHTML]="folder.projects[0].plannedMonitoring | nl2br | default:'-'"></p>
        </div>
        <div class="mt-2 col-md-6">
            <p class="field-title">{{ 'word.additionality' | translate }}</p>
            <p class="text-justify" [innerHTML]="folder.projects[0].additionality | nl2br | default:'-'"></p>
        </div>
    </div>
    <div class="row">
        <div class="mt-2 col-md-12">
            <p class="field-title">{{ 'word.description' | translate }}</p>
            <p class="text-justify" [innerHTML]="folder.projects[0].description | nl2br | default:'-'"></p>
        </div>
    </div>
</app-card>

<!-- Measures -->
<app-card cardNumber="5" cardTitle="title.measures_evolution">
    <!--  General evolution -->
    <div class="table-scroll general-measure-wrapper">
        <div class="cs-border table-responsive">
            <table class="table col-md-12">
                <thead class="col-md-12">
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let folder of folder.projects">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view" rowspan="2">
                            {{ 'workflow.user.' + folder.markingName | translate }}
                        </td>
                    </ng-container>
                    <ng-container
                            *ngFor="let step of folderProjectResource.reportSteps; let first = first; let last = last; let index = index">
                        <td class="min-w-120 report-header report-first report-last" *ngIf="step['@permissions'].view"
                            [attr.colspan]="countColspan(step.steps)">
                            {{ step['@columnName'] | translate }}
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 report-header" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ 'workflow.' + step['@columnName'] | translate }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th>{{ 'word.cost_utility_ratio' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.projects">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ step.computedValues.costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.average_cost_of_electricity' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.projects">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.dataset.cost | default:'-' }} {{ 'word.cts_kwh' | translate }}
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ step.dataset.cost | default:'-' }} {{ 'word.cts_kwh' | translate }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.total_cost' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.projects">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.totalCost | currency:'CHF':'symbol':'0.0-2' }}
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ step.computedValues.totalCost | currency }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.maximum_contribution' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.projects">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.maximumContribution | currency:'CHF':'symbol':'0.0-2' }}
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ step.computedValues.maximumContribution | currency }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.requested_contribution' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.projects">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.dataset.requestedContribution | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ step.dataset.requestedContribution | currency | default:'-' }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.annual_saving' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.projects">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.annualSaving | positive | number }} {{ 'word.kwh' | translate }}
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ step.computedValues.annualSaving | positive | number }} {{ 'word.kwh' | translate }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.total_saving' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.projects">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.totalSaving | positive | number }} {{ 'word.kwh' | translate }}
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ step.computedValues.totalSaving | positive | number }} {{ 'word.kwh' | translate }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.payback_without_contribution' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.projects">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ 'word.n_years' | translate:{count: folder.computedValues.paybackWithoutContribution | positive | round:2} }}
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ 'word.n_years' | translate:{count: step.computedValues.paybackWithoutContribution | positive | round:2} }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.payback_with_contribution' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.projects">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ 'word.n_years' | translate:{count: folder.computedValues.paybackWithContribution | positive | round:2} }}
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ 'word.n_years' | translate:{count: step.computedValues.paybackWithContribution | positive | round:2} }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.percent_saving' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.projects">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.percentSaving | positive | round:2 }} %
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ 'word.n_years' | translate:{count: step.computedValues.percentSaving | positive | round:2} }}
                                %
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.subsidy_rate' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.projects">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            {{ folder.computedValues.subsidyRate | positive | round:2 }} %
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="min-w-120 vertical-separator" *ngIf="step['@permissions'].view"
                                [class.report-first]="first" [class.report-last]="last">
                                {{ 'word.n_years' | translate:{count: step.computedValues.subsidyRate | positive | round:2} }}
                                %
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.bill' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.projects">
                        <td class="blank-case" *ngIf="folder['@permissions'].view"></td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProjectResource.reportSteps;">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="" *ngIf="step['@permissions'].view" [class.report-first]="first"
                                [class.report-last]="last">
                                -
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr class="last-row">
                    <th>{{ 'word.balance' | translate }}</th>
                    <ng-container *ngFor="let folder of folder.projects">
                        <td class="blank-case icon show-icon current-step" *ngIf="folder['@permissions'].view"
                            [class.edit-icon]="folder['@permissions'].edit" (click)="showOrEditFolderStep(folder)"></td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td class="icon show-icon current-step vertical-separator" *ngIf="step['@permissions'].view"
                                [class.edit-icon]="step['@permissions'].edit" [class.report-first]="first"
                                [class.report-last]="last" (click)="showOrEditReport(column, step)">
                                -
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
    <!--  END General evolution -->
    <!-- Measures -->
    <div>
        <ng-container *ngFor="let measure of measures; let index = index">
            <div class="table-scroll table-responsive mt-4">
                <h3 class="mb-1">{{ 'word.measure_n' | translate:{count: index + 1} }}</h3>

                <table class="table w-100">
                    <thead>
                    <tr>
                        <th class="w-25">{{ '' }}</th>
                        <ng-container *ngFor="let folder of folder.projects">
                            <th class="min-w-120" *ngIf="folder['@permissions'].view" rowspan="2">
                                {{ 'workflow.user.' + folder.markingName | translate }}
                            </th>
                        </ng-container>
                        <ng-container *ngFor="let step of folderProjectResource.reportSteps">
                            <td class="min-w-120 report-header report-first report-last"
                                *ngIf="step['@permissions'].view" [attr.colspan]="countColspan(step.steps)">
                                {{ step['@columnName'] }}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <th class="w-25">{{ '' }}</th>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td class="min-w-120" *ngIf="step['@permissions'].view" [class.report-first]="first"
                                    [class.report-last]="last">
                                    {{ 'workflow.' + step['@columnName'] | translate }}
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{{ 'word.technical_orientation' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.projects">
                            <td *ngIf="folder['@permissions'].view"
                                [innerHTML]="folder.measures[index].datasetMeasure.technicalOrientation ? folder.measures[index].datasetMeasure.technicalOrientation.name : '-'"></td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    <ng-container *ngIf="step.measures[index].technicalOrientation">
                                        {{ step.measures[index].technicalOrientation.name | default:'-' }}
                                    </ng-container>
                                    <ng-container *ngIf="!step.measures[index].technicalOrientation">
                                        -
                                    </ng-container>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.description' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.projects">
                            <td *ngIf="folder['@permissions'].view"
                                [innerHTML]="folder.measures[index].datasetMeasure.description | nl2br | default:'-'"></td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps;">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first"
                                    [class.report-last]="last"
                                    [innerHTML]="step.measures[index].description | nl2br | default:'-'"
                                >
                                    {{ step.measures[index].description | nl2br | default:'-' }}
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.duration_of_use' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.projects">
                            <td *ngIf="folder['@permissions'].view">
                                {{ 'word.n_years' | translate:{count: folder.measures[index].datasetMeasure.durationOfUse | default:'-'} }}
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps;">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    {{ 'word.n_years' | translate:{count: step.measures[index].durationOfUse | default:'-'} }}
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.measure_cost' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.projects">
                            <td *ngIf="folder['@permissions'].view">
                                {{ folder.measures[index].datasetMeasure.cost | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps;">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    {{ step.measures[index].cost | currency | default:'-' }}
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.maximum_contribution' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.projects">
                            <td *ngIf="folder['@permissions'].view">
                                {{ folder.measures[index].datasetMeasure.maximumContribution | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps;">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    {{ step.measures[index].maximumContribution | currency | default:'-' }}
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.existing_annual_consumption' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.projects">
                            <td *ngIf="folder['@permissions'].view">
                                {{ folder.measures[index].datasetMeasure.existingAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps;">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    {{ step.measures[index].existingAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.future_annual_consumption' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.projects">
                            <td *ngIf="folder['@permissions'].view">
                                {{ folder.measures[index].datasetMeasure.futureAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    {{ step.measures[index].futureAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.annual_saving' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.projects">
                            <td *ngIf="folder['@permissions'].view">
                                {{ folder.measures[index].datasetMeasure.annualSavings | positive | number | default: '-': ('word.kwh' | translate) }}
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps;">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    {{ step.measures[index].annualSavings | positive | number | default: '-': ('word.kwh' | translate) }}
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.total_saving' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.projects">
                            <td *ngIf="folder['@permissions'].view">
                                {{ folder.measures[index].datasetMeasure.totalSavings | positive | number |  default: '-': ('word.kwh' | translate) }}
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps;">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    {{ step.measures[index].totalSavings | positive | number |  default: '-': ('word.kwh' | translate) }}
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <td>{{ 'word.percent_saving' | translate }}</td>
                        <ng-container *ngFor="let folder of folder.projects">
                            <td *ngIf="folder['@permissions'].view">
                                {{ folder.measures[index].datasetMeasure.percentSavings | positive | number |  default:'-' }}
                                %
                            </td>
                        </ng-container>
                        <ng-container *ngFor="let column of folderProjectResource.reportSteps;">
                            <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                <td *ngIf="step['@permissions'].view && step.measures[index]"
                                    [class.report-first]="first" [class.report-last]="last">
                                    {{ step.measures[index].percentSavings | positive | number |  default:'-' }} %
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </div>
</app-card>

<!-- Documents persisted by staff -->
<app-card cardNumber="6" cardTitle="word.official_documents">
    <ng-container *ngIf="folder.mediaObjects.length > 0">
        <mat-list>
            <mat-list-item *ngFor="let mediaObject of folder.mediaObjects; let i = index; last as last">
                <p matLine>{{ mediaObject.originalName }}</p>
                <button mat-icon-button>
                    <mat-icon class="mat-24" (click)="downloadMediaObject(mediaObject)">cloud_download
                    </mat-icon>
                </button>

                <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
            </mat-list-item>
        </mat-list>
    </ng-container>
    <ng-container *ngIf="folder.mediaObjects.length === 0">
        {{ 'message.no_document' | translate }}
    </ng-container>
</app-card>
