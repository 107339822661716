import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TodoService } from '../../core/services/todo.service';
import { FormUtil } from '../../core/utils/form.util';
import { Person } from '../../core/models/person.model';
import { UserService } from '../../core/services/user.service';
import { environment } from '../../../environments/environment';
import { Paginator } from '../../core/models/paginator.model';
import { Todo } from '../../core/models/todo.model';
import { ArrayUtil } from '../../core/utils/array.util';
import { FolderProjectResource } from '../../core/models/folder-project-resource.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FolderProgramResource } from '../../core/models/folder-program-resource.model';
import { LoadingService } from '../../core/services/loading.service';

@Component({
    selector: 'app-add-todo-dialog',
    templateUrl: './add-todo-dialog.component.html',
    styleUrls: ['./add-todo-dialog.component.scss']
})
export class AddTodoDialogComponent implements OnInit, OnDestroy {
    public searchUser: FormControl = new FormControl();

    // If dialog called from folder view
    public folder: FolderProjectResource|FolderProgramResource;


    public startDateMin: Date = new Date(); // minimum deadline date is today

    public form = this.fb.group({
        '@id': [null],
        folder: [null],
        description: [null, [Validators.required]],
        deadline: [null],
        assignedUser: [null],
        isUrgent: [null],
        destinedToOfen: [null],
        destinedToOwner: [null]
    });

    public FULL_USERS: Person[] = []; // Full list of users fetched from API
    public SHOWED_USERS: Person[] = []; // List of showed users (updated via search)

    public persistedTodo: Todo = null;

    public destroySubject: Subject<boolean> = new Subject<boolean>();

    constructor(
        public loading: LoadingService,
        public dialogRef: MatDialogRef<AddTodoDialogComponent>,
        private fb: FormBuilder,
        private todoService: TodoService,
        private userService: UserService,
        @Inject(MAT_DIALOG_DATA) public data: {folder: FolderProjectResource|FolderProgramResource; todo: Todo; title: string}
    ) {
        this.getUsersFromRole(environment.staffPkwRole);
        this.getUsersFromRole(environment.ofenAnalysisRole);
        this.getUsersFromRole(environment.corneliaRole);
    }

    ngOnInit(): void {
        if (this.data && this.data.todo) { // edit
            this.persistedTodo = this.data.todo;
            this.form.patchValue(this.persistedTodo);
            this.form.get('folder').setValue(this.persistedTodo.folder ? this.persistedTodo.folder['@id'] : null);
            this.form.get('assignedUser').setValue(this.persistedTodo.assignedUser && this.persistedTodo.assignedUser.role.name !== 'ROLE_GENERIC'
                ? this.persistedTodo.assignedUser['@id'] : null);  // set only if real user
        }
        if (this.data && this.data.folder) {
            this.folder = this.data.folder;
            this.form.get('folder').setValue('/folders/' + String(this.data.folder.data.id));
        }
        this.searchUser.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(value => {
            this.searchUsers(value);
        });
        this.form.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(() => {
            this.checkIfAssignedUser();
            this.checkIfShouldDisableCheckboxes();
        });
        this.checkIfAssignedUser();
        this.checkIfShouldDisableCheckboxes();
    }

    ngOnDestroy(): void {
        this.destroySubject.next(true);
        this.destroySubject.complete();
    }

    checkIfShouldDisableCheckboxes(): void {
        const todo = this.form.getRawValue() as Todo;
        let shouldDisableUserSelection = false;
        let ownerEnabled = false;
        let ofenEnabled = false;

        if (true === todo.destinedToOwner) {
            shouldDisableUserSelection = true;
            ownerEnabled = true;
        }

        if (true === todo.destinedToOfen) {
            shouldDisableUserSelection = true;
            ofenEnabled = true;
        }

        if (shouldDisableUserSelection) {
            this.form.get('assignedUser').disable({emitEvent: false});
        } else {
            this.form.get('assignedUser').enable({emitEvent: false});
        }

        if (ownerEnabled) {
            this.form.get('destinedToOfen').disable({emitEvent: false});
        } else {
            this.form.get('destinedToOfen').enable({emitEvent: false});
        }

        if (ofenEnabled) {
            this.form.get('destinedToOwner').disable({emitEvent: false});
        } else {
            this.form.get('destinedToOwner').enable({emitEvent: false});
        }
    }

    checkIfAssignedUser(): void {
        if (null !== this.form.get('assignedUser').value) {
            this.form.get('destinedToOwner').disable({emitEvent: false});
            this.form.get('destinedToOfen').disable({emitEvent: false});
        } else {
            this.form.get('destinedToOwner').enable({emitEvent: false});
            this.form.get('destinedToOfen').enable({emitEvent: false});
        }
    }

    /**
     * Get the user list for given role
     * Override ADMIN_USERS with ...ADMIN_USERS and the ...users
     * This will keep currently in array users and add the new ones
     * By default, set SHOWED_USERS to ADMIN_USERS (before any search)
     * @param role wanted role
     */
    getUsersFromRole(role: string): void {
        this.userService.getUserListByRole(role).subscribe((result: Paginator) => {
            const users = result['hydra:member'] as Person[];
            this.FULL_USERS = [...this.FULL_USERS, ...users];
            this.SHOWED_USERS = this.FULL_USERS;
        });
    }

    /**
     * Function that filters the ADMIN_USERS[] with the input el value
     * Store the result into SHOWED_USERS[]
     */
    searchUsers(search: string): void {
        if (search === '') {
            this.SHOWED_USERS = this.FULL_USERS;
            return;
        }
        this.SHOWED_USERS = ArrayUtil.filterObjectArray(search, this.FULL_USERS, ['firstname', 'lastname']) as Person[];
    }

    /**
     * On 'save' button click, check if form is valid
     * if so -> start loading, pass data to the todoService.createTodo
     * close modal when done
     */
    addTodo(): void {
        if (this.form.valid) {
            if (null !== this.persistedTodo) {
                this.edit();
                return;
            }
            const todo = this.form.getRawValue() as Todo;
            this.todoService.createTodo(todo).subscribe(() => {
                this.dialogRef.close({
                    hasSaved: true
                });
            });
        } else {
            FormUtil.validateAllFormFields(this.form);
        }
    }

    edit(): void {
        const todo = this.form.getRawValue() as Todo;
        this.todoService.patchTodo(todo).subscribe(() => {
            this.dialogRef.close({
                hasSaved: true
            });
        });
    }

    get currentFolderName(): string {
        if ('currentProject' in this.folder.data) {
            return this.folder.data.currentProject.name;
        }

        return this.folder.data.currentProgram.name;
    }
}
