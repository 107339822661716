import { Injectable, EventEmitter } from '@angular/core';
import { BehaviorSubject, merge, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { Folder } from '../models/folder.model';
import { FolderProjectResource } from '../models/folder-project-resource.model';
import { environment } from '../../../environments/environment';
import { Paginator } from '../models/paginator.model';
import { HttpParams } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { FilterM } from '../models/filter.model';
import { FilterService } from './filter.service';
import { ChildReport, ProgramChildReport } from '../models/child-report.model';
import { FolderProgramResource } from '../models/folder-program-resource.model';
import { ResourceUtil } from '../utils/resourceUtil';
import { ProgramReportResource } from '../models/program-report-resource';
import { ProjectReportResource } from '../models/project-report-resource';
import { ChildProject, ChildProgram } from '../models/child-folder.model';

export type COMPUTOR_NAME = 'computor_2020' | 'computor_2022' | 'old_computor' | 'computor_2022Lights' | 'computor_2023' | 'computor_2023Big' | 'computor_2024' | 'computor_2024Big' | 'computor_2024Lights';

export const COMPUTOR_NAMES = {
    oldComputor: 'old_computor' as COMPUTOR_NAME,
    computor2020: 'computor_2020' as COMPUTOR_NAME,
    computor2022: 'computor_2022' as COMPUTOR_NAME,
    computor2022Lights: 'computor_2022Lights' as COMPUTOR_NAME,
    computor2023: 'computor_2023' as COMPUTOR_NAME,
    computor2023Big: 'computor_2023Big' as COMPUTOR_NAME,
    computor2024: 'computor_2024' as COMPUTOR_NAME,
    computor2024Big: 'computor_2024Big' as COMPUTOR_NAME,
    computor2024Lights: 'computor_2024Lights' as COMPUTOR_NAME,
    computor2025: 'computor_2025' as COMPUTOR_NAME,

};

export type COMPUTOR_TYPE = 'project' | 'program';

export const COMPUTOR_TYPES = {
    project: 'project' as COMPUTOR_TYPE,
    program: 'program' as COMPUTOR_TYPE
};

@Injectable({
    providedIn: 'root'
})
export class FolderService {
    projectResource = new BehaviorSubject<FolderProjectResource>(null);
    programResource = new BehaviorSubject<FolderProgramResource>(null);

    currentProject$ = this.projectResource.asObservable();
    currentProgram$ = this.programResource.asObservable();

    currentProjectOrProgram$ = merge(this.currentProject$, this.currentProgram$);

    onFolderChange = new EventEmitter();

    constructor(
        private apiService: ApiService,
    ) {
    }

    /**
     * Find a Folder by his id
     */
    public find(id: string): Observable<Folder> {
        return this.apiService.get('/folders/' + id).pipe(
            map((res) => res as Folder)
        );
    }

    /**
     * Submit a folder project-form => change his state
     */
    public transition(id: string, transition: string, body: Record<any, unknown>): Observable<Folder> {
        return this.apiService.patch('/folders/' + id + '/' + transition, body) as Observable<Folder>;
    }

    /**
     * Submit a folder project-form => change his state
     */
    public transitionAdmin(id: string, transition: string, body: Record<any, unknown>): Observable<FolderProjectResource> {
        return this.apiService.patch('/folders/' + id + '/admin/' + transition, body) as Observable<FolderProjectResource>;
    }

    public delete(iri: string): Observable<any> {
        return this.apiService.delete(iri);
    }

    public findAllPaginated(pageNumber = 1,
        itemsPerPage = Number(environment.itemsPerPage),
        search = '',
        sort?: Sort,
        filter?: FilterM,
        computorType = COMPUTOR_TYPES.project): Observable<Paginator> {
        let params = new HttpParams()
            .set('page', pageNumber.toString())
            .set('itemsPerPage', itemsPerPage.toString());

        if ('' !== search) {
            params = params.append('q', search);
        }

        if (sort) {
            params = params.append('order[' + sort.active + ']', sort.direction);
        }

        if (filter) {
            params = FilterService.getHttpParamsFromFilter(filter, params);
        }

        params = params.append('tenderModel.computorType', computorType);

        return this.apiService.get('/folders', { params }) as Observable<Paginator>;
    }

    assignGeneralData(
        folderId: number,
        data: {
            gsAnalysisManager?: string;
            gsMonitoringManager?: string;
            ofenAnalysisManager?: string;
            ofenMonitoringManager?: string;
            postArrivalDate?: string;
            questionsAnswersDate?: string;
            folderLanguage?: string;
            mediaObjects?: string[];
        }
    ): Observable<any> {
        return this.apiService.patch(`/folders/${folderId}/general_data`, data);
    }

    patchFolderHolder(
        data: {
            '@id'?: string;
            address?: string;
            postalCode?: string;
            city?: string;
            companyName?: string;
            firstName?: string;
            lastName?: string;
            email?: string;
            phone?: string;
            mobilePhone?: string;
        }

    ): Observable<any> {
        return this.apiService.patch(data['@id'], data);
    }

    assignContact(
        contactId: string,
        data: {
            companyName?: string;
        }
    ): Observable<any> {
        return this.apiService.patch(contactId, data);
    }

    /* Resource methods */
    assignSettings(folderId: string, settings: { tenderSession: string; owner: string }): Observable<any> {
        return this.apiService.patch(`/folders/${folderId}/settings`, settings);
    }

    setProjectResource(folder: FolderProjectResource): void {
        this.projectResource.next(folder);
    }

    setProgramResource(folder: FolderProgramResource): void {
        this.programResource.next(folder);
    }

    getProjectResource(): FolderProjectResource {
        return this.projectResource.getValue();
    }

    getProgramResource(): FolderProgramResource {
        return this.programResource.getValue();
    }

    getCurrentResource(): FolderProjectResource | FolderProgramResource {
        return this.projectResource.getValue() ? this.projectResource.getValue() : this.programResource.getValue();
    }

    getCurrentProjectOrProgram(r: FolderProjectResource | FolderProgramResource = null): ChildProject | ChildProgram {
        const currentResource = r ?? this.getCurrentResource();

        if (ResourceUtil.isProject(currentResource)) {
            return (currentResource as FolderProjectResource).data.currentProject;
        }

        if (ResourceUtil.isProgram(currentResource)) {
            return (currentResource as FolderProgramResource).data.currentProgram;
        }
    }

    getReportResource(r: FolderProjectResource | FolderProgramResource = null): ProgramReportResource | ProjectReportResource | null {
        const currentResource = r ?? this.getCurrentResource();

        if (null === currentResource) {
            return null;
        }

        if (ResourceUtil.isProject(currentResource)) {
            return (currentResource as FolderProjectResource).data.projectReportResource;
        }

        if (ResourceUtil.isProgram(currentResource)) {
            return (currentResource as FolderProgramResource).data.programReportResource;
        }
    }

    setCurrentProjectOrProgram(folder: FolderProjectResource | FolderProgramResource): void {
        if (ResourceUtil.isProject(this.getCurrentResource())) {
            this.setProjectResource(folder as FolderProjectResource);

            return;
        }

        this.setProgramResource(folder as FolderProgramResource);
    }

    clear(): void {
        this.projectResource.next(null);
        this.programResource.next(null);
    }

    addCurrentReport(currentReport: ChildReport | ProgramChildReport): void {
        const currentResource = this.getCurrentResource();

        if (ResourceUtil.isProgram(currentResource)) {
            (currentResource as FolderProgramResource).data.programReportResource.data.currentReport = currentReport as ProgramChildReport;
        }

        if (ResourceUtil.isProject(currentResource)) {
            (currentResource as FolderProjectResource).data.projectReportResource.data.currentReport = currentReport as ChildReport;
        }

        this.setCurrentProjectOrProgram(currentResource);
    }

    getCurrentComputorType(): string {
        return this.getCurrentResource().data.tenderModel.computorType;
    }


    /**
     * Send an export request of the given folders iri.
     */
    projectExportRequest(body: Record<any, unknown>): Observable<void> {
        // select all projects
        if ((body.iris as any[]).length === 0) {
            return this.apiService.post('/folders/export', { ...body, iris: null }) as Observable<void>;
        }

        return this.apiService.post('/folders/export', body) as Observable<void>;
    }

    programExportRequest(body: Record<any, unknown>): Observable<void> {
        // select all programs
        if ((body.iris as any[]).length === 0) {
            return this.apiService.post('/programs/export', { ...body, iris: null }) as Observable<void>;
        }

        return this.apiService.post('/programs/export', body) as Observable<void>;
    }
}
