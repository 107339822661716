<div class="container-fluid h-100">
    <div class="h-100 d-flex flex-column">
        <div class="row flex-grow-1">
            <div class="d-flex h-100 login-layout-background justify-content-between flex-column pt-5 position-relative" [class.col-md-7]="isLargeAndUp$ | async">
                <a routerLink="/login">
                    <img src="assets/images/logo.svg" alt="ProKilowatt" style="max-width: 150px">
                </a>
                <div *ngIf="false === (isLargeAndUp$ | async)" class="mt-4 d-flex flex-column align-items-center">
                    <p class="headline mb-5">{{'login_layout.headline' | translate}}</p>
                    <ng-container *ngTemplateOutlet="route"></ng-container>
                </div>
                <div class="pl-md-5" style="z-index: 1">
                    <p class="headline" *ngIf="isLargeAndUp$ | async">{{'login_layout.headline' | translate}}</p>
                    <div class="d-flex icons-wrp mt-md-5">
                        <div>
                            <div class="icon">
                                <i class="icon-save-time"></i>
                            </div>
                            <h4>{{'login_layout.economy' | translate}}</h4>
                        </div>
                        <div class="ml-3 ml-md-5">
                            <div class="icon">
                                <i class="icon-chart"></i>
                            </div>
                            <h4>{{'login_layout.demand' | translate}}</h4>
                        </div>
                        <div class="ml-3 ml-md-5">
                            <div class="icon">
                                <i class="icon-revenue"></i>
                            </div>
                            <h4>{{'login_layout.subsidies' | translate}}</h4>
                        </div>
                    </div>
                </div>
                <footer class="align-self-start mat-small text-dark mb-5" style="z-index: 1">{{ currentYear }} Powered by <a href="http://tokiwi.ch" class="text-dark">Tokiwi</a></footer>
                <img src="assets/images/waves-svg.png" class="waves">
            </div>
            <main class="col-md-5 login-background position-relative pt-5 d-flex" *ngIf="isLargeAndUp$ | async">
                <button mat-button class="d-inline lang-chooser user-menu text-white" [matMenuTriggerFor]="language_menu">
                    {{ (i18n.currentLangObs | async) | uppercase }}
                    <mat-icon>expand_more</mat-icon>
                </button>
                <div class="custom-position">
                    <ng-container *ngTemplateOutlet="route"></ng-container>
                </div>
            </main>
        </div>
    </div>
</div>


<ng-template #route>
    <router-outlet></router-outlet>
</ng-template>

<mat-menu #language_menu="matMenu">
    <button *ngFor="let language of languages; last as last"  mat-menu-item (click)="changeLanguage(language.locale)">
        <span>{{ 'word.' + language.locale | translate }}</span>
        <mat-divider [inset]="false" *ngIf="!last"></mat-divider>
    </button>
</mat-menu>
