import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AskResetComponent } from './ask-reset/ask-reset.component';
import { LoginLayoutComponent } from '../../layouts/login-layout/login-layout.component';

const routes: Routes = [{
    path: '',
    component: LoginLayoutComponent,
    children: [
        {
            path: 'ask_reset',
            component: AskResetComponent,
            pathMatch: 'full'
        },
        {
            path: 'reset_password/:uuid',
            component: ResetPasswordComponent,
            pathMatch: 'full'
        }
    /**
     * YM : je pense qu'il faut blinder l'inscription à la plate-forme
     * avec une validation de l'inscription. Pour ce faire, il faudrait :
     * - ajouter un champ "validate_at" dans l'entité User
     * - faire en sorte lors de l'authentification que le user doit avoir
     *   une date dans "validate_at"
     * - lors de l'inscription, envoyer un email contenant une url avec
     *   ./register/validate/<token>
     * - le token peut être provisoirement stoké dans "reset_password_token"
     *   (double usage du champ)
     * - lorsque le compte est validé via l'ouverture du lien, on supprime
     *   la valeur de "reset_password_token", on enregistre la date "validate_at",
     *   on authentifie le user et on le redirige vers le dashboard
     */
    // {
    //   path: 'register/validate/:validateToken',
    //   component: RegisterValidationComponent,
    //   pathMatch: 'full'
    //   resolve: {
    //      registredAppUser: ValidateRegistrationTokenResolver
    //    }
    // }
    ]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ResetPasswordRoutingModule {
}
