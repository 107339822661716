<div [formGroup]="form">
    <!-- Summary -->
    <app-card cardNumber="1" cardTitle="title.summary">
        <div class="table-scroll">
            <table class="table table-responsive w-100" appTableCellHighlighter>
                <thead>
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <th class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ 'workflow.' + step.columnName | translate }}
                        </th>
                    </ng-container>
                </tr>
                </thead>
                <tbody>
                <ng-container formGroupName="currentProject">
                    <ng-container formGroupName="dataset">
                        <tr>
                            <th>{{ 'word.average_cost_of_electricity' | translate }}</th>
                            <ng-container *ngFor="let step of folderSteps">
                                <td class="min-w-120" *ngIf="step['@permissions'].view">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ step.dataset.cost | default:'-' }} {{ 'word.cts_kwh' | translate }}
                                    </ng-container>

                                    <ng-container *ngIf="step['@permissions'].edit">
                                        <mat-form-field>
                                            <input matInput
                                                   formControlName="cost"
                                                   type="number"
                                                   autocomplete="off"
                                                   (change)="executeCalculation()">
                                            <span class="mat-input-suffix"
                                                  matSuffix>{{ 'word.cents' | translate }}</span>
                                        </mat-form-field>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.total_cost' | translate }}</th>
                            <ng-container *ngFor="let step of folderSteps">
                                <td class="min-w-120" *ngIf="step['@permissions'].view">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ step.computedValues.totalCost | currency }}
                                    </ng-container>
                                    <ng-container *ngIf="step['@permissions'].edit">
                                        {{ computedResume.totalCost | currency }}
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.maximum_contribution' | translate }}</th>
                            <ng-container *ngFor="let step of folderSteps">
                                <td class="min-w-120" *ngIf="step['@permissions'].view">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ step.computedValues.maximumContribution | currency }}
                                    </ng-container>
                                    <ng-container *ngIf="step['@permissions'].edit">
                                        {{ computedResume.maximumContribution | currency }}
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.requested_contribution' | translate }}</th>
                            <ng-container *ngFor="let step of folderSteps">
                                <td class="min-w-120" *ngIf="step['@permissions'].view"
                                    [class.input]="step['@permissions'].edit">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ step.dataset.requestedContribution | currency | default:'-' }}
                                    </ng-container>

                                    <ng-container *ngIf="step['@permissions'].edit">
                                        <mat-form-field>
                                            <input matInput
                                                   formControlName="requestedContribution"
                                                   type="number"
                                                   autocomplete="off"
                                                   (change)="executeCalculation()">
                                            <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>

                                            <mat-error
                                                    *ngIf="form.controls['currentProject'].get('dataset').get('requestedContribution').hasError('min')">
                                                {{ 'validator.requested_contribution.min' | translate:{min: form.controls['currentProject'].get('dataset').get('requestedContribution').errors['min'].min} }}
                                            </mat-error>
                                        </mat-form-field>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.annual_saving' | translate }}</th>
                            <ng-container *ngFor="let step of folderSteps">
                                <td class="min-w-120" *ngIf="step['@permissions'].view">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ step.computedValues.annualSaving | positive | number }} {{ 'word.kwh' | translate }}
                                    </ng-container>
                                    <ng-container *ngIf="step['@permissions'].edit">
                                        {{ computedResume.economyAnnual | positive | number }} {{ 'word.kwh' | translate }}
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.payback_without_contribution' | translate }}</th>
                            <ng-container *ngFor="let step of folderSteps">
                                <td class="min-w-120" *ngIf="step['@permissions'].view">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ 'word.n_years' | translate:{count: step.computedValues.paybackWithoutContribution | positive | round:2} }}
                                    </ng-container>
                                    <ng-container *ngIf="step['@permissions'].edit">
                                        {{ 'word.n_years' | translate:{count: computedResume.paybackWithoutContribution | positive | round:2} }}
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.cost_utility_ratio' | translate }}</th>
                            <ng-container *ngFor="let step of folderSteps">
                                <td class="min-w-120" *ngIf="step['@permissions'].view">
                                    <ng-container *ngIf="step['@permissions'].edit">
                                        {{computedResume.costUtilityRatio | positive | round:2}}
                                    </ng-container>
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ step.computedValues.costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                    </ng-container>
                </ng-container>
                </tbody>
            </table>
        </div>
    </app-card>

    <!-- Project holder -->
    <app-card cardNumber="2" cardTitle="title.project_holder">
        <div class="table-scroll">
            <table class="table table-responsive col-md-12" formGroupName="folderHolder" appTableCellHighlighter>
                <thead class="col-md-12">
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let step of folderProject.holderSteps">
                        <th class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ 'workflow.' + step['@columnName'] | translate }}
                        </th>
                    </ng-container>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th>{{ 'word.company_name' | translate }}</th>
                    <ng-container *ngFor="let step of folderProject.holderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.companyName | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="companyName"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['folderHolder'].get('companyName').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.first_name' | translate }}</th>
                    <ng-container *ngFor="let step of folderProject.holderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.firstName | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="firstName"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['folderHolder'].get('firstName').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.last_name' | translate }}</th>
                    <ng-container *ngFor="let step of folderProject.holderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.lastName | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="lastName"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['folderHolder'].get('lastName').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.address' | translate }}</th>
                    <ng-container *ngFor="let step of folderProject.holderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.address | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="address"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['folderHolder'].get('address').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.postal_code' | translate }}</th>
                    <ng-container *ngFor="let step of folderProject.holderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.postalCode | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="postalCode"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.postCode }}">
                                    <mat-error
                                            *ngIf="form.controls['folderHolder'].get('postalCode').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.postCode} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.city' | translate }}</th>
                    <ng-container *ngFor="let step of folderProject.holderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.city | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="city"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error *ngIf="form.controls['folderHolder'].get('city').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.email' | translate }}</th>
                    <ng-container *ngFor="let step of folderProject.holderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.email | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="email"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error *ngIf="form.controls['folderHolder'].get('email').hasError('pattern')">
                                        {{ 'validator.email.not_valid' | translate }}
                                    </mat-error>
                                    <mat-error *ngIf="form.controls['folderHolder'].get('email').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.phone' | translate }}</th>
                    <ng-container *ngFor="let step of folderProject.holderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.phone | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="phone"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error *ngIf="form.controls['folderHolder'].get('phone').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.mobile_phone' | translate }}</th>
                    <ng-container *ngFor="let step of folderProject.holderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.mobilePhone | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="mobilePhone"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['folderHolder'].get('mobilePhone').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>
    </app-card>

    <!-- Project beneficiary -->
    <app-card cardNumber="3" cardTitle="title.project_beneficiary">
        <div class="table-scroll">
            <table class="table table-responsive col-md-12" formGroupName="folderBeneficiary" appTableCellHighlighter>
                <thead class="col-md-12">
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let step of folderProject.beneficiarySteps">
                        <th class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ 'workflow.' + step['@columnName'] | translate }}
                        </th>
                    </ng-container>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th>{{ 'word.company_name' | translate }}</th>
                    <ng-container *ngFor="let step of folderProject.beneficiarySteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.companyName | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="companyName"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['folderBeneficiary'].get('companyName').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.first_name' | translate }}</th>
                    <ng-container *ngFor="let step of folderProject.beneficiarySteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.firstName | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="firstName"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['folderBeneficiary'].get('firstName').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.last_name' | translate }}</th>
                    <ng-container *ngFor="let step of folderProject.beneficiarySteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.lastName | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="lastName"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['folderBeneficiary'].get('lastName').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.address' | translate }}</th>
                    <ng-container *ngFor="let step of folderProject.beneficiarySteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.address | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="address"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['folderBeneficiary'].get('address').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.postal_code' | translate }}</th>
                    <ng-container *ngFor="let step of folderProject.beneficiarySteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.postalCode | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="postalCode"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.postCode }}">
                                    <mat-error
                                            *ngIf="form.controls['folderBeneficiary'].get('postalCode').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.postCode} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.city' | translate }}</th>
                    <ng-container *ngFor="let step of folderProject.beneficiarySteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.city | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="city"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['folderBeneficiary'].get('city').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.email' | translate }}</th>
                    <ng-container *ngFor="let step of folderProject.beneficiarySteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.email | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="email"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['folderBeneficiary'].get('email').hasError('pattern')">
                                        {{ 'validator.email.not_valid' | translate }}
                                    </mat-error>
                                    <mat-error
                                            *ngIf="form.controls['folderBeneficiary'].get('email').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.phone' | translate }}</th>
                    <ng-container *ngFor="let step of folderProject.beneficiarySteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.phone | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="phone"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['folderBeneficiary'].get('phone').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.mobile_phone' | translate }}</th>
                    <ng-container *ngFor="let step of folderProject.beneficiarySteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.mobilePhone | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="mobilePhone"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['folderBeneficiary'].get('mobilePhone').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>
    </app-card>

    <!-- General -->
    <app-card cardNumber="4" cardTitle="title.general">
        <div class="table-scroll">
            <table class="table table-responsive w-100" appTableCellHighlighter>
                <thead>
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <th class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ 'workflow.' + step.columnName | translate }}
                        </th>
                    </ng-container>
                </tr>
                </thead>
                <tbody>
                <tr formGroupName="currentProject">
                    <th>{{ 'word.name' | translate }}</th>
                    <ng-container *ngFor="let folder of folderSteps">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view"
                            [class.input]="folder['@permissions'].edit">
                            <ng-container *ngIf="!folder['@permissions'].edit">
                                {{ folder.name }}
                            </ng-container>

                            <ng-container *ngIf="folder['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="name"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error *ngIf="form.controls['currentProject'].get('name').hasError('required')">
                                        {{ 'validator.project_name.not_blank' | translate }}
                                    </mat-error>
                                    <mat-error
                                            *ngIf="form.controls['currentProject'].get('name').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr formGroupName="currentProject">
                    <th>{{ 'word.start_date' | translate }}</th>
                    <ng-container *ngFor="let folder of folderSteps">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view"
                            [class.input]="folder['@permissions'].edit">
                            <ng-container *ngIf="!folder['@permissions'].edit">
                                {{ folder.startDate | localizedDate }}
                            </ng-container>

                            <ng-container *ngIf="folder['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           [matDatepicker]="startDatePicker"
                                           formControlName="startDate"
                                           [min]="startDateMin"
                                           type="text"
                                           autocomplete="off">
                                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #startDatePicker></mat-datepicker>
                                    <mat-error
                                            *ngIf="form.controls['currentProject'].get('startDate').hasError('matDatepickerParse')">
                                        {{ 'validator.startDate.not_valid' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr formGroupName="currentProject">
                    <th>{{ 'word.end_date' | translate }}</th>
                    <ng-container *ngFor="let folder of folderSteps">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view"
                            [class.input]="folder['@permissions'].edit">
                            <ng-container *ngIf="!folder['@permissions'].edit">
                                {{ folder.endDate | localizedDate }}
                            </ng-container>

                            <ng-container *ngIf="folder['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           [matDatepicker]="endDatePicker"
                                           formControlName="endDate"
                                           [min]="form.get('currentProject.startDate').value || startDateMin"
                                           type="text"
                                           autocomplete="off">
                                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #endDatePicker></mat-datepicker>
                                    <mat-error
                                            *ngIf="form.controls['currentProject'].get('endDate').hasError('matDatepickerParse')">
                                        {{ 'validator.end_date.not_valid' | translate }}
                                    </mat-error>
                                    <mat-error
                                            *ngIf="form.controls['currentProject'].get('endDate').hasError('matDatepickerMin')">
                                        {{ 'validator.end_date.min_value' | translate:{min: form.controls['currentProject'].get('endDate').getError('matDatepickerMin').min} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr formGroupName="currentProject">
                    <th>{{ 'word.acronym' | translate }}</th>
                    <ng-container *ngFor="let folder of folderSteps">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            <ng-container *ngIf="!folder['@permissions'].edit">
                                {{ folder.acronym }}
                            </ng-container>

                            <ng-container *ngIf="folder['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="acronym"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.acronym }}">
                                    <mat-error
                                            *ngIf="form.controls['currentProject'].get('acronym').hasError('required')">
                                        {{ 'validator.project_name.not_blank' | translate }}
                                    </mat-error>
                                    <mat-error
                                            *ngIf="form.controls['currentProject'].get('acronym').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.acronym} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.reference' | translate }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ step.reference }}
                        </td>
                    </ng-container>
                </tr>
                <tr formGroupName="currentProject">
                    <th>{{ 'word.description' | translate }}</th>
                    <ng-container *ngFor="let subFolder of folderSteps">
                        <td class="min-w-120" *ngIf="subFolder['@permissions'].view"
                            [class.input]="subFolder['@permissions'].edit">
                            <ng-container *ngIf="!subFolder['@permissions'].edit">
                                {{ subFolder.description }}
                            </ng-container>

                            <ng-container *ngIf="subFolder['@permissions'].edit">
                                <mat-form-field>
                                        <textarea matInput
                                                  formControlName="description"
                                                  cdkTextareaAutosize
                                                  cdkAutosizeMinRows="5"
                                                  maxlength="{{ lengthValidators.longString }}"></textarea>
                                    <mat-error
                                            *ngIf="form.controls['currentProject'].get('description').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.longString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>
    </app-card>

    <!-- Measures -->
    <app-card cardNumber="5" cardTitle="title.measures_evolution">
        <!-- Measures -->
        <div formGroupName="currentProject">
            <ng-container *ngFor="let measure of measures; let index = index">
                <div class="table-scroll mt-4" *ngIf="currentProjectForm.get('measures')['controls'][index]">
                    <h3 class="mb-1">{{ 'word.measure_n' | translate:{count: index + 1} }}</h3>

                    <table class="table table-responsive w-100" appTableCellHighlighter>
                        <thead>
                        <tr>
                            <th class="w-25">{{ '' }}</th>
                            <ng-container *ngFor="let step of folderSteps">
                                <th class="min-w-120" *ngIf="step['@permissions'].view">
                                    {{ 'workflow.' + step.columnName | translate }}
                                </th>
                            </ng-container>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th>{{ 'word.technical_orientation' | translate }}</th>
                            <ng-container *ngFor="let step of folderSteps">
                                <td *ngIf="step['@permissions'].view && step.measures[index] && step.measures[index].technicalOrientation">
                                    {{ step.measures[index].technicalOrientation.name | default:'-' }}
                                </td>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.duration_of_use' | translate }}</th>
                            <ng-container *ngFor="let step of folderSteps">
                                <td *ngIf="step['@permissions'].view && step.measures[index]">
                                    {{ 'word.n_years' | translate:{count: step.measures[index].durationOfUse | default:'-'} }}
                                </td>
                            </ng-container>
                        </tr>
                        <tr formArrayName="measures">
                            <th>{{ 'word.measure_cost' | translate }}</th>
                            <ng-container *ngFor="let step of folderSteps">
                                <td *ngIf="step['@permissions'].view">
                                    <ng-container *ngIf="!step['@permissions'].edit && step.measures[index]">
                                        {{ step.measures[index].cost | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                                    </ng-container>

                                    <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                        <div class="dataset-measure" formGroupName="datasetMeasure">
                                            <mat-form-field>
                                                <input matInput
                                                       formControlName="cost"
                                                       type="number"
                                                       autocomplete="off"
                                                       min="{{ lengthValidators.minCost }}"
                                                       (change)="executeCalculation()">
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.chf' | translate }}</span>
                                                <mat-error
                                                        *ngIf="form.get('currentProject.measures')['controls'][index].get('datasetMeasure.cost').hasError('required')">
                                                    {{ 'validator.cost.not_blank' | translate }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.maximum_contribution' | translate }}</th>
                            <ng-container *ngFor="let step of folderSteps">
                                <td *ngIf="step['@permissions'].view && step.measures[index] && computedResume.measures[index]">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ step.measures[index].maximumContribution | currency | default:'-' }}
                                    </ng-container>
                                    <ng-container *ngIf="step['@permissions'].edit">
                                        {{ computedResume.measures[index].maximumContribution | currency | default:'-' }}
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                        <tr formArrayName="measures">
                            <th>{{ 'word.existing_annual_consumption' | translate }}</th>
                            <ng-container *ngFor="let step of folderSteps">
                                <td *ngIf="step['@permissions'].view && step.measures[index]">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ step.measures[index].existingAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                                    </ng-container>

                                    <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                        <div class="dataset-measure fields-group" formGroupName="datasetMeasure">
                                            <mat-form-field>
                                                <input matInput
                                                       formControlName="existingAnnualConsumption"
                                                       type="number"
                                                       autocomplete="off"
                                                       min="{{lengthValidators.positiveValue}}"
                                                       (change)="executeCalculation()">
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.kwh' | translate }}</span>

                                                <mat-error
                                                        *ngIf="form.get('currentProject.measures')['controls'][index].get('datasetMeasure.existingAnnualConsumption').hasError('required')">
                                                    {{ 'validator.existing_annual_consumption.not_blank' | translate }}
                                                </mat-error>
                                                <mat-error
                                                        *ngIf="form.get('currentProject.measures')['controls'][index].get('datasetMeasure.existingAnnualConsumption').hasError('min')">
                                                    {{ 'validator.existing_annual_consumption.min' | translate:{min: form.get('currentProject.measures')['controls'][index].get('datasetMeasure.existingAnnualConsumption').errors['min'].min} }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                        <tr formArrayName="measures">
                            <th>{{ 'word.future_annual_consumption' | translate }}</th>
                            <ng-container *ngFor="let step of folderSteps">
                                <td *ngIf="step['@permissions'].view && step.measures[index]">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ step.measures[index].futureAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                                    </ng-container>

                                    <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                        <div class="dataset-measure fields-group" formGroupName="datasetMeasure">
                                            <mat-form-field>
                                                <input matInput
                                                       formControlName="futureAnnualConsumption"
                                                       type="number"
                                                       autocomplete="off"
                                                       min="{{lengthValidators.positiveValue}}"
                                                       (change)="executeCalculation()">
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.kwh' | translate }}</span>
                                                <mat-error
                                                        *ngIf="form.get('currentProject.measures')['controls'][index].get('datasetMeasure.futureAnnualConsumption').hasError('min')">
                                                    {{ 'validator.future_annual_consumption.min' | translate:{min: form.get('currentProject.measures')['controls'][index].get('datasetMeasure.futureAnnualConsumption').errors['min'].min} }}
                                                </mat-error>
                                                <mat-error
                                                        *ngIf="form.get('currentProject.measures')['controls'][index].get('datasetMeasure.futureAnnualConsumption').hasError('lessThan')">
                                                    {{ 'validator.future_annual_consumption_must_be_lower_than_existing_annual_consumption' | translate }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.annual_saving' | translate }}</th>
                            <ng-container *ngFor="let step of folderSteps">
                                <td *ngIf="step['@permissions'].view && step.measures[index] && computedResume.measures[index]">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ step.measures[index].annualSavings | positive | number |  default: '-': ('word.kwh' | translate) }}
                                    </ng-container>
                                    <ng-container *ngIf="step['@permissions'].edit">
                                        {{ computedResume.measures[index].economyAnnual | positive | number |  default: '-': ('word.kwh' | translate) }}
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.total_saving' | translate }}</th>
                            <ng-container *ngFor="let step of folderSteps">
                                <td *ngIf="step['@permissions'].view && step.measures[index] && computedResume.measures[index]">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ step.measures[index].totalSavings | positive | number |  default: '-': ('word.kwh' | translate) }}
                                    </ng-container>
                                    <ng-container *ngIf="step['@permissions'].edit">
                                        {{ computedResume.measures[index].economyTotal | positive | number |  default: '-': ('word.kwh' | translate) }}
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.percent_saving' | translate }}</th>
                            <ng-container *ngFor="let step of folderSteps">
                                <td *ngIf="step['@permissions'].view && step.measures[index] && computedResume.measures[index]">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ step.measures[index].percentSavings | positive | number |  default:'-' }} %
                                    </ng-container>
                                    <ng-container *ngIf="step['@permissions'].edit">
                                        {{ computedResume.measures[index].economyPercent | positive | number |  default:'-' }}
                                        %
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                        <tr formArrayName="measures">
                            <th>{{ 'word.description' | translate }}</th>
                            <ng-container *ngFor="let step of folderSteps">
                                <td *ngIf="step['@permissions'].view && step.measures[index]">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ step.measures[index].description | default:'-' }}
                                    </ng-container>

                                    <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                        <div class="dataset-measure fields-group" formGroupName="datasetMeasure">
                                            <mat-form-field>
                                                    <textarea matInput
                                                              formControlName="description"
                                                              cdkTextareaAutosize
                                                              cdkAutosizeMinRows="5"
                                                              maxlength="{{ lengthValidators.longString }}"></textarea>
                                                <mat-error
                                                        *ngIf="form.get('currentProject.measures')['controls'][index].get('datasetMeasure.description').hasError('maxlength')">
                                                    {{ 'validator.field.too_long' | translate: '{ max: 4000 }' }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </div>
    </app-card>

    <app-card cardNumber="6" cardTitle="title.documents">
        <table class="table w-100">
            <thead>
            <tr>
                <th class="w-25">{{ '' }}</th>
                <ng-container *ngFor="let step of folderSteps; let childFolderIndex = index;">
                    <th class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ 'workflow.' + step.columnName | translate }}

                        <div class="add-media-object" *ngIf="step['@permissions'].edit">
                            <button mat-mini-fab color="accent" (click)="addMediaObject(childFolderIndex)">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </th>
                </ng-container>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td class="table-inactive-cell"></td>
                <ng-container *ngFor="let step of folderSteps; let childFolderIndex = index;">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        <mat-list dense role="list">
                            <mat-list-item role="listitem"
                                           *ngFor="let mediaObject of step.mediaObjects; let mediaObjectIndex = index">
                                <span class="mat-small" mat-line>{{ mediaObject.originalName }}</span>
                                <button mat-icon-button *appIsGranted="'ROLE_STAFF_PKW'" appConfirmDialog
                                        (confirmClick)="deleteMediaObject(mediaObject, childFolderIndex, mediaObjectIndex)">
                                    <mat-icon class="mat-16">delete_outline</mat-icon>
                                </button>
                                <button mat-icon-button (click)="downloadMediaObject(mediaObject)">
                                    <mat-icon class="mat-16">get_app</mat-icon>
                                </button>
                            </mat-list-item>
                        </mat-list>
                    </td>
                </ng-container>
            </tr>
            </tbody>
        </table>
    </app-card>

    <app-card cardNumber="7" appAdminComponent cardTitle="title.analysis">
        <div class="row">
            <div class="col-md-8" formGroupName="analysis">
                <mat-form-field>
                    <mat-label>{{ 'word.decision' | translate }}</mat-label>
                    <textarea matInput
                              formControlName="decision"
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="5"
                              #analysisDecision
                              [readonly]="!canEditAnalyse() || !isValidation2()"
                              maxlength="10000"></textarea>
                    <mat-hint align="end">{{ analysisDecision.value.length }} / 65000</mat-hint>
                    <mat-error *ngIf="form.get('analysis.decision').hasError('maxlength')">
                        {{ 'validator.field.too_long' | translate: {max: 65000} }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <table class="table w-100">
                    <thead>
                    <tr>
                        <th class="min-w-120">
                            {{ 'word.documents' | translate }}

                            <div class="add-media-object" *ngIf="canEdit()">
                                <button mat-mini-fab color="accent" (click)="addAnalysisMediaObject()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <mat-list dense role="list">
                                <mat-list-item role="listitem"
                                               *ngFor="let mediaObject of form.get('analysisMediaObjects').value; let i = index; last as last">
                                    <span class="mat-small" mat-line>{{ mediaObject.originalName }}</span>
                                    <button mat-icon-button appConfirmDialog *appIsGranted="'ROLE_STAFF_PKW'"
                                            (confirmClick)="deleteAnalysisMediaObject(mediaObject, i)">
                                        <mat-icon class="mat-16">delete_outline</mat-icon>
                                    </button>
                                    <button mat-icon-button (click)="downloadMediaObject(mediaObject)">
                                        <mat-icon class="mat-16">get_app</mat-icon>
                                    </button>
                                </mat-list-item>
                            </mat-list>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" formGroupName="analysis">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>{{ 'word.comment' | translate }}</mat-label>
                    <textarea matInput
                              formControlName="comment"
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="5"
                              #analysisComment
                              [readonly]="!canEditAnalyse()  || !isValidation2()"
                              maxlength="65000"></textarea>
                    <mat-hint align="end">{{ analysisComment.value.length }} / 65000</mat-hint>
                    <mat-error *ngIf="form.get('analysis.comment').hasError('maxlength')">
                        {{ 'validator.field.too_long' | translate: {max: 65000} }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </app-card>

    <!-- Remarks -->
    <app-admin-remark-card appAdminComponent cardNumber="8" [folder]="folderProject"></app-admin-remark-card>

    <!-- Todos -->
    <app-todo [bottom]="isValidation2() ? '85px' : '25px'" [folder]="folderProject"
              *appIsGranted="'ROLE_STAFF_PKW'"></app-todo>

    <app-sticky-footer *ngIf="isValidation2()">
        <div>
            <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="save()"
                    [loading]="loading.loading$ | async">
                {{ 'action.save' | translate }} <i class="icon-floppy-disk"></i>
            </button>
        </div>
        <div class="justify-content-end">
            <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="check()"
                    [loading]="loading.loading$ | async">
                {{ 'action.check' | translate }} <i class="icon-checked"></i>
            </button>
        </div>
    </app-sticky-footer>
</div>

<input #fileInput
       type="file"
       class="file-input hidden"
       multiple="multiple"
       (change)="onNewFileSelected($event)"/>
