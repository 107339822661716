<div *ngIf="form" [formGroup]="form">

    <div formGroupName="cost">
        <div class="col-2 d-inline-block">
            <div class="d-flex align-items-center">
                <p class="costs-title d-flex">{{'fix_costs.' + groupName | translate}}
                <span class="hint-help">
                      <mat-icon matTooltip="{{'fix_costs.'+groupName+'.tooltip' | translate}}" matTooltipPosition="right">help</mat-icon>
                </span>
                </p>
            </div>
        </div>
        <mat-form-field class="col-2">
<!--            <mat-label>{{'word.amount' | translate}}</mat-label>-->
            <input matInput formControlName="units" type="number" autocomplete="off" (change)="executeCalculation()"/>
        </mat-form-field>
        <mat-form-field class="col-2">
<!--            <mat-label>{{'word.unit_price' | translate}}</mat-label>-->
            <input matInput formControlName="unitPrice" type="number" autocomplete="off"
                   (change)="executeCalculation()"/>
            <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
        </mat-form-field>
        <mat-form-field class="col-2 mat-form-field-appearance-legacy mat-form-field-disabled">
<!--            <mat-label>{{'word.total_cost' | translate}}</mat-label>-->
            <input matInput formControlName="totalCost" type="number" autocomplete="off" [readonly]="true"/>
            <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
        </mat-form-field>

        <mat-form-field class="col-2 mat-form-field-appearance-legacy mat-form-field-disabled">
<!--            <mat-label>{{'word.own_and_third_party_contributions' | translate}}</mat-label>-->
            <input matInput formControlName="otherContribution" type="number" autocomplete="off" [readonly]="true"/>
            <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
        </mat-form-field>
        <mat-form-field class="col-2">
<!--            <mat-label>{{'word.total_requested_contribution' | translate}}</mat-label>-->
            <input matInput formControlName="requestedContribution" type="number" autocomplete="off"
                   (change)="executeCalculation()"/>
            <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
            <mat-error *ngIf="form.get('cost.requestedContribution').hasError('lessThan')">
                {{'validator.requested_contribution.lt_cost' | translate}}
            </mat-error>
        </mat-form-field>
    </div>
</div>
