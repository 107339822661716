import { Component, OnInit } from '@angular/core';
import { UserService } from '../../core/services/user.service';
import { AuthService } from '../../core/services/auth.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FormUtil } from '../../core/utils/form.util';
import { User } from '../../core/models/user.model';
import { NotificationService } from '../../core/notifications/notification.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoadingService } from '../../core/services/loading.service';

@Component({
    selector: 'app-user-profile-dialog',
    templateUrl: './user-profile-dialog.component.html',
    styleUrls: ['./user-profile-dialog.component.scss']
})
export class UserProfileDialogComponent implements OnInit {
    form = this.fb.group({
        firstname: [null, [Validators.required]],
        lastname: [null, [Validators.required]],
        email: [null, [Validators.required, Validators.email]],
        password: [null]
    });

    hidePassword = true;

    constructor(
        public auth: AuthService,
        private fb: FormBuilder,
        private user: UserService,
        private notification: NotificationService,
        private dialogRef: MatDialogRef<UserProfileDialogComponent>,
        private router: Router,
        public loading: LoadingService
    ) { }

    ngOnInit(): void {
        this.form.patchValue({
            firstname: this.auth.getUser().firstname,
            lastname: this.auth.getUser().lastname,
            email: this.auth.getUser().userName
        });
    }

    save(): void {
        if (this.form.valid) {
            const hasChangedEmail = this.form.get('email').dirty;
            const user = this.form.value as User;
            const userIri = '/users/' + String(this.auth.getUser().id);

            if (null === user.password) {
                delete user.password;
            }

            this.user.update(userIri, user).subscribe((newUser) => {
                this.auth.updateUserInfos(newUser);
                if (hasChangedEmail) {
                    this.auth.logout();
                    void this.router.navigateByUrl('/');
                    this.notification.success('word.log_back_in_with_new_address');
                } else {
                    this.notification.success('toast.data_successfully_saved');
                }

                this.dialogRef.close();
            });
        } else {
            FormUtil.validateAllFormFields(this.form);
        }
    }
}
