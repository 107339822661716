import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormUtil } from '../../core/utils/form.util';
import { FilterField, FilterM } from '../../core/models/filter.model';
import { NotificationService } from '../../core/notifications/notification.service';
import { MarkingEnum } from '../../core/enum/markin.enum';
import { FilterService } from '../../core/services/filter.service';
import { Paginator } from '../../core/models/paginator.model';
import { COMPUTOR_TYPE } from '../../core/services/folder.service';

@Component({
    selector: 'app-new-filter-dialog',
    templateUrl: './new-filter-dialog.component.html',
    styleUrls: ['./new-filter-dialog.component.scss']
})
export class NewFilterDialogComponent implements OnInit {
    form = this.fb.group({
        '@id': null,
        name: [null, [Validators.required]],
        filterContent: this.fb.array([]), // here we define filters,
        type: [null, [Validators.required]]
    });

    availableFields: FilterField[] = [];

    availableMarkings = MarkingEnum.availableMarkings;

    removeBtnLoadingIdx = -1;

    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<NewFilterDialogComponent>,
        private notification: NotificationService,
        @Inject(MAT_DIALOG_DATA) private data: {filter?: FilterM; type: COMPUTOR_TYPE},
        private filterService: FilterService
    ) {
    }

    fillFormForEdit(): void {
        if (!this.data?.filter) {
            return;
        }

        this.form.patchValue(this.data.filter);
        this.data.filter.filterContent.forEach(content => {
            this.content.push(this.fb.group({
                '@id': content['@id'],
                field: [content.field['@id'], [Validators.required]],
                search: [content.search]
            }));
        });
    }

    ngOnInit(): void {
        if (!this.data?.filter?.filterContent.length) {
            this.addControl();
        }

        this.data.type = this.data.type ? this.data.type : this.data.filter.type;

        this.filterService.getFieldList(this.data.type).subscribe((result: Paginator) => { // retrieve current columns
            this.availableFields = result['hydra:member'] as FilterField[];
            this.fillFormForEdit();
        });

        this.form.get('type').setValue(this.data.type);
    }

    get content(): FormArray {
        return this.form.get('filterContent') as FormArray;
    }

    addControl(): void {
        this.content.push(this.fb.group({
            field: [null, [Validators.required]],
            search: [null]
        }));
    }

    save(): void {
        const newFilter = this.form.value as FilterM;
        if (this.form.valid && this.content.length > 0 && this.checkFilterHasValues(newFilter)) {
            this.dialogRef.close({
                filter: newFilter
            });

            return;
        }

        if (this.content.length === 0) {
            this.notification.error('message.add_some_filters');
        }

        if (!this.checkFilterHasValues(newFilter)) {
            this.notification.error('message.one_filter_has_no_value');
        }

        FormUtil.validateAllFormFields(this.form);
    }

    /* Method that checks if content has at least a direction or a search value */
    checkFilterHasValues(filter: FilterM): boolean {
        let hasValues = true;
        filter.filterContent.forEach(content => {
            if (!content.search) { // no search and no direction
                hasValues = false;
            }
        });

        return hasValues;
    }

    isFieldMarking(ctrlIdx: number): boolean {
        const fieldIri = this.content.at(ctrlIdx).get('field').value as string;
        if (fieldIri) {
            return this.getFieldColumn(fieldIri) === 'project.marking' ||
                this.getFieldColumn(fieldIri) === 'program.marking';
        }

        return false;
    }

    getFieldColumn(fieldIri: string): string { // retrieve database column name
        return this.availableFields.find(i => i['@id'] === fieldIri).currentColumn;
    }

    removeRow(idx: number): void {
        const field = this.content.at(idx).get('@id');
        if (field && field.value) {
            const iri = field.value as string;
            this.removeBtnLoadingIdx = idx;

            this.filterService.deleteOneFilterContent(iri).subscribe(() => {
                this.content.removeAt(idx);
                this.removeBtnLoadingIdx = -1;

                if (!this.content.length) {
                    this.addControl();
                }
            });

            return;
        }

        this.content.removeAt(idx);

        if (!this.content.length) {
            this.addControl();
        }
    }

    isButtonLoading(btnIdx: number): boolean {
        return this.removeBtnLoadingIdx === btnIdx;
    }
}
