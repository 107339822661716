<div [class.next-important-date]="tenderSession['nextDate'] === tenderSession['openingDate']"
     class="date-row d-flex align-items-center">
  <div>{{ tenderSession['openingDate'] | localizedDate }}</div>
  <!--<div><span class="material-icons">lens</span></div>-->
  <div>{{ 'tender_session.' + type + '.opening_date' | translate }}</div>
</div>
<div [class.next-important-date]="tenderSession['nextDate'] === tenderSession['submissionEndDate']"
     class="date-row d-flex align-items-center">
  <div>{{ tenderSession['submissionEndDate'] | localizedDate }}</div>
  <!--<div><span class="material-icons">lens</span></div>-->
  <div>{{ 'tender_session.' + type + '.submission_end_date' | translate }}</div>
</div>

<div *ngIf="tenderSession['questionRoundStartDate']" [class.next-important-date]="tenderSession['nextDate'] === tenderSession['questionRoundStartDate']"
     class="date-row d-flex align-items-center">
  <div>{{ tenderSession['questionRoundStartDate'] | localizedDate }}</div>
  <!--<div><span class="material-icons">lens</span></div>-->
  <div>{{ 'tender_session.' + type + '.question_round_start_date' | translate }}</div>
</div>
<div *ngIf="tenderSession['questionRoundEndDate']" [class.next-important-date]="tenderSession['nextDate'] === tenderSession['questionRoundEndDate']"
     class="date-row d-flex align-items-center">
  <div>{{ tenderSession['questionRoundEndDate'] | localizedDate }}</div>
  <!--<div><span class="material-icons">lens</span></div>-->
  <div>{{ 'tender_session.' + type + '.question_round_end_date' | translate }}</div>
</div>
<div *ngIf="tenderSession['decisionDate']" [class.next-important-date]="tenderSession['nextDate'] === tenderSession['decisionDate']"
     class="date-row d-flex align-items-center">
  <div>{{ tenderSession['decisionDate'] | localizedDate }}</div>
  <!--<div><span class="material-icons">lens</span></div>-->
  <div>{{ 'tender_session.' + type + '.decision_date' | translate }}</div>
</div>
<div *ngIf="tenderSession['maxStartDate']" [class.next-important-date]="tenderSession['nextDate'] === tenderSession['maxStartDate']"
     class="date-row d-flex align-items-center">
  <div>{{ tenderSession['maxStartDate'] | localizedDate }}</div>
  <!--<div><span class="material-icons">lens</span></div>-->
  <div>{{ 'tender_session.' + type + '.max_start_date' | translate }}</div>
</div>
<div *ngIf="tenderSession['maxEndDate']" [class.next-important-date]="tenderSession['nextDate'] === tenderSession['maxEndDate']"
     class="date-row d-flex align-items-center">
  <div>{{ tenderSession['maxEndDate'] | localizedDate }}</div>
  <!--<div><span class="material-icons">lens</span></div>-->
  <div>{{ 'tender_session.' + type + '.max_end_date' | translate }}</div>
</div>
