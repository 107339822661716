import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { LessThanValidator } from '../../../core/validators/less-than.validator';
import { ProgramCalculationResults } from '../services/program-calculator.service';
import { FIX_COST_TYPE_MANAGEMENT, FIX_COST_TYPE_ACCOMPANIMENT } from '../../../core/models/fixCost.model';
import { ProjectForm } from './projectForm';

@Injectable()
export class ProgramForm {
    public static LEGAL_FORMS = ['sa', 'sarl', 'individual', 'snc', 'other'];
    public static GEOGRAPHIC_TARGETS = ['national', 'regional', 'cantonal', 'local'];
    public static SECTORS = [
        'industrial',
        'artisanal',
        'commercial',
        'services',
        'agricultural',
        'households'
    ];

    public static POSSIBLE_ELECTRICITY_COSTS = [15, 20];

    public lengthValidators = {
        genString: 250,
        longString : 4096,
        postCode : 7,
        acronym : 50,
        minCost : 0.05,
        minRequestedContribution : 20000,
        maxRequestedContribution: 2000000,
        positiveValue : 0,
        maxCtsKwhCost : 100,
        maxMeasureCost: 300000
    };

    public computedResume: ProgramCalculationResults =  {
        costUtilityRatio: 0,
        annualSaving: 0,
        maximumContribution: 0,
        measures: [],
        fixCosts: [],
        paybackWithoutContribution: 0,
        paybackWithContribution: 0,
        subsidyRate: 0,
        costCtskwh: 0,
        totalSaving: 0,
        requestedContribution: 0,
        totalCost: 0,
        percentSaving: 0,
        summary: null
    };

    constructor(
        private fb: FormBuilder,
    ) {}

    public getProgramForm(): FormGroup {
        return this.fb.group({
            id: [null],
            uuid: [uuidv4()], // eslint-disable-line
            currentState: [null, []],
            tenderSession: [null, [Validators.required]],
            tenderModel: [null, [Validators.required]],
            mediaObjects: this.fb.array([]),
            gsAnalysisManager: [null, []],
            gsMonitoringManager: [null, []],
            ofenAnalysisManager: [null, []],
            ofenMonitoringManager: [null, []],
            owner: [null, []],
            supportingOrganization: this.fb.group({
                '@id': [null, []],
                address: [null, [Validators.maxLength(this.lengthValidators.genString)]],
                postalCode: [null, [Validators.maxLength(this.lengthValidators.postCode)]],
                city: [null, [Validators.maxLength(this.lengthValidators.genString)]],
                companyName: [null, Validators.maxLength(this.lengthValidators.genString)],
                legalForm: [null],
                email: [null, [
                    Validators.maxLength(this.lengthValidators.genString),
                    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
                ]],
                phone: [null, Validators.maxLength(this.lengthValidators.genString)],
                mobilePhone: [null, Validators.maxLength(this.lengthValidators.genString)],
                website: [null, [Validators.maxLength(this.lengthValidators.genString)]]
            }),
            folderHolder: this.fb.group({
                '@id': [null, []],
                function: [null, Validators.maxLength(this.lengthValidators.genString)],
                companyName: [null, Validators.maxLength(this.lengthValidators.genString)],
                firstName: [null, Validators.maxLength(this.lengthValidators.genString)],
                lastName: [null, Validators.maxLength(this.lengthValidators.genString)],
                email: [null, [
                    Validators.maxLength(this.lengthValidators.genString),
                    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
                ]],
                phone: [null, Validators.maxLength(this.lengthValidators.genString)],
                mobilePhone: [null, Validators.maxLength(this.lengthValidators.genString)],
            }),
            currentProgram: this.fb.group({
                '@id': [null, []],
                name: ['', [Validators.required, Validators.maxLength(this.lengthValidators.genString),
                    Validators.pattern(ProjectForm.noSpecialCharsRegex)]],
                acronym: ['', [Validators.required, Validators.maxLength(this.lengthValidators.acronym),
                    Validators.pattern(ProjectForm.noSpecialCharsRegex)]],
                startDate: [null],
                endDate: [null],
                sector: [null],
                lengthInMonth: [null], // not saved
                geographicTarget: [null],
                geographicTargetSpecifications: [null, Validators.maxLength(this.lengthValidators.genString)],
                description: [null, Validators.maxLength(this.lengthValidators.longString)],
                plannedMonitoring: [null, Validators.maxLength(this.lengthValidators.longString)],
                additionality: [null, Validators.maxLength(this.lengthValidators.longString)],
                markingName: [null],
                mediaObjects: this.fb.array([]),
                state: [null],
                dataset: this.getDatasetFormGroup(),
                measures: this.fb.array([
                    this.createMeasureGroup()
                ]),
                fixCosts: this.fb.array([
                    this.createFixCostGroup('general', FIX_COST_TYPE_MANAGEMENT),
                    this.createFixCostGroup('folder', FIX_COST_TYPE_MANAGEMENT),
                    this.createFixCostGroup('communication', FIX_COST_TYPE_ACCOMPANIMENT),
                    this.createFixCostGroup('training', FIX_COST_TYPE_ACCOMPANIMENT),
                    this.createFixCostGroup('advices', FIX_COST_TYPE_ACCOMPANIMENT),
                    this.createFixCostGroup('tools', FIX_COST_TYPE_ACCOMPANIMENT),
                    this.createFixCostGroup('monitoring', FIX_COST_TYPE_ACCOMPANIMENT),
                    this.createFixCostGroup('other1', FIX_COST_TYPE_ACCOMPANIMENT),
                    this.createFixCostGroup('other2', FIX_COST_TYPE_ACCOMPANIMENT)
                ]),
                analysisCosts: this.fb.group({
                    '@id': [null],
                    units: [null],
                    unitPrice: [null],
                    requestedContribution: [null], // per unit
                    totalRequestedContribution: [null],
                    subsidyRate: [null],
                    totalCost: [null]
                }, {
                    validators: [
                        LessThanValidator('totalRequestedContribution', 'totalCost', true)
                    ]
                })
            }),
            requirementsControl: this.fb.group({
                requirement1: [false, []],
            }),
            // following properties are used in validation2
            analysisMediaObjects: this.fb.array([]),
            analysis: this.fb.group({
                decision: [null, []],
                comment: [null, []],
            }),
        });
    }

    public createMeasureGroup(): FormGroup {
        return this.fb.group({
            '@id': [null, []],
            folder: [null, []],
            datasetMeasure: this.fb.group({
                '@id': [null, []],
                units: [null],
                cost: [null, [Validators.min(this.lengthValidators.positiveValue), Validators.max(this.lengthValidators.maxMeasureCost)]],
                totalCost: [null],
                existingAnnualConsumption: [null, [Validators.min(this.lengthValidators.positiveValue)]],
                futureAnnualConsumption: [null, [Validators.min(this.lengthValidators.positiveValue)]],
                description: [null, [Validators.maxLength(this.lengthValidators.genString)]],
                durationOfUse: [null, [Validators.min(this.lengthValidators.positiveValue), Validators.pattern('[0-9]+')]],
                requestedContribution: [null], // by unit
                totalRequestedContribution: [null], // units * requestedContribution
                maximumContribution: [null, [Validators.min(this.lengthValidators.positiveValue)]],
                technicalOrientation: [null, []],
                percentSavings: [null, []],
                totalSavings: [null],
                measureAnnualSavings: [null],
                measureTotalSavings: [null],
                supportRate: [null],
                engagedRequestedContribution: [null],
                realisedRequestedContribution: [null],
                engagedTotalEconomy: [null],
                realisedTotalEconomy: [null],
                balanceTotalEconomy: [null] // calculated
            }, {
                validators: [
                    LessThanValidator('futureAnnualConsumption', 'existingAnnualConsumption'),
                    LessThanValidator('requestedContribution', 'maximumContribution', true)
                ]
            })
        });
    }

    public getDatasetFormGroup(): FormGroup {
        return this.fb.group({
            '@id': [null, []],
            subsidies: null,
            cost: [null,
                [Validators.min(this.lengthValidators.positiveValue), Validators.max(this.lengthValidators.maxCtsKwhCost)]],
            requestedContribution: [null],
        });
    }

    public createFixCostGroup(groupName: string = null, type: string = null, withValidators = true): FormGroup {
        return this.fb.group({
            '@id': [null],
            groupName: [groupName, [Validators.required]],
            type: [type],
            cost: this.fb.group({
                units: [null],
                unitPrice: [null],
                requestedContribution: [null], // total pkw contribution
                otherContribution: [null],
                totalCost: [null],
                balance: [null] // used in program report only
            }, {
                validators: withValidators ? [
                    LessThanValidator('requestedContribution', 'totalCost', true),
                ] : []
            })
        });
    }
}
