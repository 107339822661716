import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Report } from '../models/report.model';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Sort } from '@angular/material/sort';
import { Paginator } from '../models/paginator.model';
import { IriUtil } from '../utils/iri.util';

@Injectable({
    providedIn: 'root'
})
export class ReportService {

    constructor(
        private api: ApiService
    ) { }

    public createNewReport(report: Report, type: string, computor: string): Observable<any> {
        if (report['@id']) {
            return this.patch(report);
        }

        const params = new HttpParams()
            .set('type', type)
            .set('computor', computor);

        return this.api.post('/reports', report, { params });
    }

    public submitReport(reportId: string): Observable<any> {
        return this.api.patch(`/reports/${reportId}/validate`);
    }

    /**
     * Submit a folder project-form => change his state
     */
    public transition(id: string, transition: string, body: Record<any, unknown>): Observable<Report> {
        return this.api.patch('/reports/' + id + '/' + transition, body) as Observable<Report>;
    }

    public find(id: string): Observable<Report> {
        return this.api.get('/reports/' + id).pipe(
            map((res) => res as Report)
        );
    }

    public patch(report: Report): Observable<any> {
        return this.api.patch(report['@id'], report as Record<any, any>);
    }

    public findAllPaginated(pageNumber = 1,
        itemsPerPage = Number(environment.itemsPerPage),
        search = '',
        sort?: Sort): Observable<Paginator> {
        let params = new HttpParams()
            .set('page', pageNumber.toString())
            .set('itemsPerPage', itemsPerPage.toString());

        if ('' !== search) {
            params = params.append('q', search);
        }

        if (sort) {
            params = params.append('order[' + sort.active + ']', sort.direction);
        }

        return this.api.get('/reports/list', { params }) as Observable<Paginator>;
    }

    exportRequest(body: Record<any, unknown>): Observable<void> {
        // select all reports
        if ((body.iris as any[]).length === 0) {
            return this.api.post('/reports/export', { ...body, iris: null }) as Observable<void>;
        }

        return this.api.post('/reports/export', body) as Observable<void>;
    }

    delete(reportIri: string): Observable<any> {
        return this.api.delete(reportIri);
    }

    toggleFinal(reportIri: string, isFinal: boolean): Observable<any> {
        const id = IriUtil.extractId(reportIri);
        return this.api.patch(`/reports/${id}/toggle_final?isFinal=${String(isFinal)}`, {});
    }
}
