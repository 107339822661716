import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationService } from '../../../core/notifications/notification.service';
import { UserService } from '../../../core/services/user.service';
import { Subscription } from 'rxjs';
import { FormUtil } from '../../../core/utils/form.util';
import { LoadingService } from '../../../core/services/loading.service';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnDestroy {
    public form = this.fb.group({
        firstName: [null, [Validators.required, Validators.maxLength(200)]],
        lastName: [null, [Validators.required, Validators.maxLength(200)]],
        email: [null, [Validators.required, Validators.email, Validators.maxLength(200)]],
        password: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(200)]],
    });
    public hidePassword: boolean;
    private subscriptions: Subscription[] = [];

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private notificationService: NotificationService,
        private userService: UserService,
        public loading: LoadingService
    ) {
        this.hidePassword = true;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    public login(): void {
        void this.router.navigateByUrl('/login');
    }

    public register(): void {
        if (this.form.valid) {
            this.subscriptions.push(
                this.userService.register(this.form.value).subscribe(() => {
                    this.notificationService.success('toast.user_account_created');
                    void this.router.navigateByUrl('/login');
                })
            );
        } else {
            FormUtil.validateAllFormFields(this.form);
        }
    }
}
