import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UserService } from '../../../core/services/user.service';
import { Router } from '@angular/router';
import { NotificationService } from '../../../core/notifications/notification.service';
import { LoadingService } from '../../../core/services/loading.service';
import { catchError, finalize } from 'rxjs/operators';

@Component({
    selector: 'app-ask-reset-password',
    templateUrl: './ask-reset.component.html',
    styleUrls: ['./ask-reset.component.scss']
})

export class AskResetComponent implements OnDestroy {
    public form = this.fb.group({
        email: ['', [Validators.required, Validators.email]]
    });
    public hidePassword: boolean;
    private subscriptions: Subscription[] = [];

    constructor(
        public loading: LoadingService,
        private userService: UserService,
        private fb: FormBuilder,
        private router: Router,
        private notificationService: NotificationService
    ) { }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    sendResetLink(): void {
        if (!this.form.get('email').errors){
            this.subscriptions.push(
                this.userService.askForResetLink(this.form.get('email').value)
                    .pipe(
                        catchError(err => {
                            this.onSuccess();
                            throw err;
                        }),
                        finalize(() => this.onSuccess())
                    )
                    .subscribe()
            );
        }
    }

    public login(): void {
        void this.router.navigateByUrl('/login');
    }

    private onSuccess(): void {
        void this.router.navigateByUrl('/');
        this.notificationService.success('toast.password_reset_request_success');
    }
}
