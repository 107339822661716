import { Component, OnInit, OnDestroy } from '@angular/core';
import { I18nService } from '../core/i18n/i18n.service';
import { AuthService } from '../core/services/auth.service';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy{
    routerSubscription: Subscription;

    constructor(
        private i18nService: I18nService,
        private authService: AuthService,
        private router: Router,
    ) {
        this.i18nService.init();

        // try to authenticate user from local storage
        this.authService.autoLogin();
    }

    ngOnInit(): void {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                const classes = document.getElementsByClassName('mat-toolbar mat-primary');
                if (classes.length > 0) {
                    classes[0].scrollIntoView();
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
    }


}
