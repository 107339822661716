import { Injectable } from '@angular/core';
import { ProjectCalculationResults } from '../services/project-calculator.service';
import { ProjectForm } from './projectForm';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class ReportForm {
    public computedResume: ProjectCalculationResults =  {
        costUtilityRatio: 0,
        economyAnnual: 0,
        economyPercent: 0,
        economyTotal: 0,
        maximumContribution: 0,
        measures: [],
        paybackWithoutContribution: 0,
        paybackWithContribution: 150,
        requestedContribution: 0,
        subsidyRate: 0,
        totalCost: 0,
        costCtskwh: 0
    };

    public lengthValidators = this.folderForm.lengthValidators;

    public createMeasureGroup = this.folderForm.createMeasureGroup;
    public getDatasetFormGroup = this.folderForm.getDatasetFormGroup;

    constructor(
        private folderForm: ProjectForm,
        private fb: FormBuilder
    ) { }

    public getReportForm(): FormGroup {
        return this.fb.group({
            '@id': null,
            id: null,
            currentProject: this.fb.group({
                '@id': null,
                measures: this.fb.array([
                    this.createMeasureGroup()
                ]),
                dataset: this.getDatasetFormGroup()
            }),
            currentReport: this.fb.group({
                '@id': null,
                reportMeasures: this.fb.array([
                    this.createMeasureGroup()
                ]),
                dataset: this.getDatasetFormGroup(),
                mediaObjects: this.fb.array([])
            }),
            // following properties are used in validation only
            analysisMediaObjects: this.fb.array([]),
            analysis: this.fb.group({
                decision: [null, []],
                comment: [null, []],
            }),
        });
    }
}
