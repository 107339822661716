import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { ExportSelectionValidator } from '../../core/validators/export-selection.validator';
import { FormUtil } from '../../core/utils/form.util';
import { LoadingService } from '../../core/services/loading.service';

@Component({
    selector: 'app-export-selection-dialog',
    templateUrl: './export-selection-dialog.component.html',
    styleUrls: ['./export-selection-dialog.component.scss']
})
export class ExportSelectionDialogComponent {
    public showComputorControl = false;
    public numberOfSelectedItems = 0;

    public form = this.fb.group({
        exporter: [null, [Validators.required]],
        computor: [null],
    }, {
        validators: [
            ExportSelectionValidator('exporter', 'computor'),
        ]
    });

    public exporters: {
        pdf: {label: string; type: string; exporter: string; marking?: string; numberOfItems?: number}[];
        csv: {label: string; type: string; exporter: string; marking?: string; numberOfItems?: number}[];
    };

    constructor(
        public loading: LoadingService,
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<ExportSelectionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {
            selection: SelectionModel<string>;
            exporters: {
                pdf: {label: string; type: string; exporter: string; marking?: string; numberOfItems?: number}[];
                csv: {label: string; type: string; exporter: string; marking?: string; numberOfItems?: number}[]; };
        }
    ) {
        this.numberOfSelectedItems = this.data.selection.selected.length;
        this.exporters = this.data.exporters;
    }

    public save(): void {
        if (this.form.valid) {
            this.dialogRef.close(this.form.value);
        } else {
            FormUtil.validateAllFormFields(this.form);
        }
    }

    public onExporterChange($event: MatRadioChange): void {
        if (($event.value as {numberOfItems: number}).numberOfItems !== null) {
            this.form.get('computor').setValue(null);
        }

        this.showComputorControl = ($event.value as {numberOfItems: number}).numberOfItems === null;
    }
}
