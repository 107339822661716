import { Component, Input } from '@angular/core';
import { FixCost } from '../../../../../core/models/fixCost.model';

@Component({
    selector: 'app-program-readonly-fix-costs',
    templateUrl: './program-readonly-fix-costs.component.html',
    styleUrls: ['./program-readonly-fix-costs.component.scss']
})
export class ProgramReadonlyFixCostsComponent {
    @Input()
    fixCost: FixCost;
}
