import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Milestone } from '../models/milestone.model';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { HydraCollection } from '../models/hydra-collection.model';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MilestoneService {

    constructor(
        private api: ApiService
    ) { }

    create(ms: Milestone): Observable<any> {
        if (ms['@id']) {
            return this.patch(ms);
        }

        return this.api.post('/milestones', ms);
    }

    get(msIri: string): Observable<Milestone> {
        return this.api.get(msIri) as Observable<Milestone>;
    }

    getByFolder(folderId: number): Observable<Milestone[]> {
        const params = new HttpParams()
            .set('folder.id', folderId.toString())
            .set('pagination', 'false')
        ;

        return this.api.get('/milestones', { params }).pipe(
            map((res: HydraCollection) => res['hydra:member'] as Milestone[])
        );
    }

    patch(ms: Milestone): Observable<any> {
        return this.api.patch(ms['@id'], ms as Record<any, any>);
    }

    delete(milestoneIri: string): Observable<any> {
        return this.api.delete(milestoneIri);
    }
}
