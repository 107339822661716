import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { FolderProjectResource } from '../models/folder-project-resource.model';
import { FolderProjectService } from '../services/folder-project.service';

@Injectable()
export class FolderProjectResolver implements Resolve<FolderProjectResource> {
    constructor(
        private folderProjectService: FolderProjectService,
    ) { }
    public resolve(
        route: ActivatedRouteSnapshot
    ): Observable<FolderProjectResource> {
        return this.folderProjectService.find(
            route.paramMap.get('folderId'),
            route.paramMap.get('stateId'),
            route.paramMap.get('reportId'),
            route.paramMap.get('reportStateId')
        );
    }
}
