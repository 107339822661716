<mat-card [id]="cardNumber ? 'section'+cardNumber : ''" [class]="classes">
    <ng-content select="mat-card-header"></ng-content>

    <mat-card-header *ngIf="cardNumber || cardTitle">
        <div mat-card-avatar class="rounded-number" *ngIf="cardNumber">{{ cardNumber }}</div>
        <mat-card-title *ngIf="cardTitle">{{ cardTitle | translate }}</mat-card-title>
        <ng-content select="[role=heading]"></ng-content>
    </mat-card-header>

    <mat-card-content>
        <ng-content></ng-content>
    </mat-card-content>

    <ng-content select="mat-card-actions"></ng-content>
</mat-card>

