<app-card classes="p-5">
    <div class="text-center">
        <h1>{{'title.login' | translate}}</h1>
    </div>
    <form [formGroup]="form" class="w-100">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="standard">
                    <mat-label>{{ 'word.email' | translate }}</mat-label>
                    <input matInput formControlName="email" autocomplete="email" (keyup.enter)="onEnter()">

                    <mat-error *ngIf="form.get('email').hasError('email')">
                        {{ 'validator.email.not_valid' | translate }}
                    </mat-error>
                    <mat-error *ngIf="form.get('email').hasError('required')">
                        {{ 'validator.email.not_blank' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="standard">
                    <mat-label>{{ 'word.password' | translate }}</mat-label>
                    <input matInput [type]="this.hidePassword ? 'password' : 'text'" formControlName="password"
                           (keyup.enter)="onEnter()" autocomplete="current-password">
                    <button (click)="this.hidePassword = !this.hidePassword" mat-icon-button matSuffix type="button">
                        <mat-icon>
                            {{ this.hidePassword ? 'visibility_off' : 'visibility' }}
                        </mat-icon>
                    </button>
                    <mat-error *ngIf="form.get('password').hasError('required')">
                        {{ 'validator.password.not_blank' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
    <mat-card-actions>
        <button appMatButtonLoading [forceHasBeenClicked]="true" [loading]="loading.loading$ | async" mat-flat-button
                color="accent" class="mat-button mr-1" (click)="login()">{{ 'action.login' | translate }}</button>
        <button mat-stroked-button color="default" class="mat-button"
                (click)="register()">{{ 'action.register' | translate }}</button>
        <button mat-stroked-button color="default" class="mat-button"
                (click)="passwordForgotten()">{{ 'action.recover_password' | translate }}</button>
    </mat-card-actions>
</app-card>
