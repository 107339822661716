import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TenderModelService } from '../../core/services/tender-model.service';
import { HttpParams } from '@angular/common/http';
import { Paginator } from '../../core/models/paginator.model';
import { TenderModel } from '../../core/models/tender-model.model';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { TenderSessionService } from '../../core/services/tender-session.service';
import { TenderSession } from '../../core/models/tender-session.model';
import { FormUtil } from '../../core/utils/form.util';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from '../../core/services/loading.service';
import { COMPUTOR_TYPE } from '../../core/services/folder.service';

@Component({
    selector: 'app-add-tender-session-dialog',
    templateUrl: './add-tender-session-dialog.component.html',
    styleUrls: ['./add-tender-session-dialog.component.scss']
})
export class AddTenderSessionDialogComponent implements OnInit, OnDestroy {
    // Actually acts as an edit form too.
    fullTenderModels: TenderModel[] = [];
    availableTenderModels: TenderModel[] = [];
    tenderModelSelection = new FormControl();
    tenderModelSearch = new FormControl();

    public destroySubject: Subject<boolean> = new Subject<boolean>();

    form = this.fb.group({
        sessionState: '/session_states/1', // TODO: validate if by default it's waiting or something else
        name: [null, [Validators.required]],
        description: [null, [Validators.maxLength(255)]],
        tenderModel: [null, [Validators.required]],
        openingDate: [null, [Validators.required]],
        submissionEndDate: [null, [Validators.required]],
        questionRoundStartDate: [null, []],
        questionRoundEndDate: [null, []],
        decisionDate: [null, []],
        maxStartDate: [null, []],
        maxEndDate: [null, []]
    });

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: {tenderSessionType?: COMPUTOR_TYPE; tenderSession?: TenderSession},
        private tenderModelService: TenderModelService,
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<AddTenderSessionDialogComponent>,
        private tenderSessionService: TenderSessionService,
        public loading: LoadingService
    ) { }

    ngOnInit(): void {
        this.subscribeToSearch();
        const params = new HttpParams().set('computorType', this.data.tenderSessionType);
        this.tenderModelService.get(params).subscribe((result: Paginator) => {
            this.fullTenderModels = result['hydra:member'] as TenderModel[];
            this.availableTenderModels = this.fullTenderModels;
        });
        if (this.data.tenderSession) {
            this.form.patchValue(this.data.tenderSession);
            this.form.patchValue({tenderModel: this.data.tenderSession.tenderModel['@id']});
        }
    }

    ngOnDestroy(): void {
        this.destroySubject.next(true);
        this.destroySubject.complete();
    }

    subscribeToSearch(): void {
        this.tenderModelSearch.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(value => {
            this.searchForModel(value);
        });
    }

    searchForModel(value: string): void {
        this.availableTenderModels = this.fullTenderModels.filter(obj => obj.name.toLowerCase().indexOf(value.toLowerCase()) > -1);
    }

    addTenderSession(): void {
        if (!this.form.valid) {
            FormUtil.validateAllFormFields(this.form);
            return;
        }
        if (this.data.tenderSession) {
            this.tenderSessionService.update(this.data.tenderSession['@id'], this.form.value).subscribe(() => {
                this.dialogRef.close({
                    save: true
                });
            });
        } else {
            this.tenderSessionService.create(this.form.value).subscribe(() => {
                this.dialogRef.close({
                    save: true
                });
            });
        }

    }
}
