import { Component, Input, OnInit } from '@angular/core';
import { Folder } from '../../../../core/models/folder.model';
import { TenderSession } from '../../../../core/models/tender-session.model';
import { Router } from '@angular/router';
import { IriUtil } from '../../../../core/utils/iri.util';
import { TransitionsService } from '../../../../core/services/transitions.service';
import { TimelineItem } from '../../../../core/models/timeline-item.model';
import { ChildProject } from '../../../../core/models/child-folder.model';

@Component({
    selector: 'app-folder-detail',
    templateUrl: './folder-detail.component.html',
    styleUrls: ['./folder-detail.component.scss']
})
export class FolderDetailComponent implements OnInit {

    @Input()
    public tenderSession: TenderSession;

    @Input()
    public folder: Folder = null;

    @Input()
    public showingFolder = false;

    public currentChild: ChildProject;

    public isProgram = false;

    constructor(
        private router: Router,
        public transitionsService: TransitionsService,
    ) { }

    ngOnInit(): void {
        this.currentChild = this.folder.currentProject ? this.folder.currentProject : this.folder.currentProgram;
        this.isProgram = !('currentProject' in this.folder);
    }

    showFolder(folder: Folder): void {
        void this.router.navigate([
            'sessions',
            IriUtil.extractId(this.tenderSession['@id']),
            this.isProgram ? 'programs' : 'folders',
            IriUtil.extractId(folder['@id']),
            'view'
        ]);
    }

    public viewOrEdit(item: TimelineItem): Promise<boolean> {
        if ('milestone' === item.type.toLowerCase()) {
            return;
        }

        if ('report' === item.type.toLowerCase()) {
            if (item['@permissions'].edit) {
                return this.router.navigate([
                    'sessions',
                    IriUtil.extractId(this.tenderSession['@id']),
                    this.isProgram ? 'programs' : 'folders',
                    IriUtil.extractId(this.folder['@id']),
                    'reports',
                    item.parentObjectId,
                    'edit'
                ]);
            }

            if (item['@permissions'].view) {
                return this.router.navigate([
                    'sessions',
                    IriUtil.extractId(this.tenderSession['@id']),
                    this.isProgram ? 'programs' : 'folders',
                    IriUtil.extractId(this.folder['@id']),
                    'reports',
                    item.parentObjectId,
                    'states',
                    item.objectId
                ]);
            }
        }

        if (item['@permissions'].edit) {
            return this.router.navigate([
                'sessions',
                IriUtil.extractId(this.tenderSession['@id']),
                this.isProgram ? 'programs' : 'projects',
                IriUtil.extractId(this.folder['@id']),
            ]);
        }

        if (!item['@permissions'].edit) {
            return this.router.navigate([
                'sessions',
                IriUtil.extractId(this.tenderSession['@id']),
                this.isProgram ? 'programs' : 'folders',
                IriUtil.extractId(this.folder['@id']),
                'states',
                item.objectId,
            ]);
        }
    }
}
