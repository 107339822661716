import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable()
@Pipe({
    name: 'snakeCase',
    pure: false, // required to update the value when the promise is resolved
})
export class SnakeCasePipe implements PipeTransform {
    public transform(value: string): string {
        return value.replace(/\W+/g, ' ')
            .split(/ |\B(?=[A-Z])/)
            .map(word => word.toLowerCase())
            .join('_');
    }
}
