<button mat-fab class="todo-list-btn"
        [style]="'bottom:' + bottom"
        (click)="openShowTodoList = !openShowTodoList" #trigger="cdkOverlayOrigin" cdkOverlayOrigin>
  <i class="icon icon-checklist"></i>
</button>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayHasBackdrop]="true"
  (backdropClick)="openShowTodoList = false"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="openShowTodoList">
  <app-todo-small-list [folder]="folder"></app-todo-small-list>
</ng-template>
