import { VoterInterface, VoterInterfaceConstants } from '../voter-interface';
import { Injectable } from '@angular/core';
import { User } from '../../models/user.model';

@Injectable()
export class RoleVoter implements VoterInterface {
    public vote(currentUser: User, attributes: string[]): number {
        // abstain vote by default in case none of the attributes are supported
        let vote = VoterInterfaceConstants.ACCESS_ABSTAIN;

        if (!currentUser.roles) {
            return vote;
        }

        const roles = currentUser.roles;

        for (const attribute of attributes) {
            // as soon as at least one attribute is supported, default is to deny access
            vote = VoterInterfaceConstants.ACCESS_DENIED;

            // grant user if matches at least one current user role
            for (const roleId of roles) {
                if (attribute === roleId) {
                    return VoterInterfaceConstants.ACCESS_GRANTED;
                }
            }
        }

        return vote;
    }
}
