import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { Todo } from '../models/todo.model';
import { environment } from '../../../environments/environment';
import { Sort } from '@angular/material/sort';
import { HttpParams } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class TodoService {

    constructor(
        private api: ApiService
    ) { }

    getTODOsPaginated(viewArchived = false,
        pageNumber = 1,
        itemsPerPage = Number(environment.itemsPerPage),
        showAssignedToFolder = false,
        search = '',
        sort?: Sort): Observable<any> {
        let params = new HttpParams()
            .set('done[null]', String(!viewArchived))
            .set('page', pageNumber.toString())
            .set('itemsPerPage', itemsPerPage.toString())
            .set('q', search)
            ;
        if (sort) {
            params = params.append('order[' + sort.active + ']', sort.direction);
        }
        if (!showAssignedToFolder) { // if set -> will not show all todos but the one who has or not a folder
            params = params.append('exists[folder]', String(showAssignedToFolder));
        }
        return this.api.get('/todo/list', { params });
    }

    getFolderTODOsPaginated(folderId: string,
        viewArchived = false,
        pageNumber = 1,
        itemsPerPage = Number(environment.itemsPerPage),
        search = '',
        sort?: Sort): Observable<any> {
        let params = new HttpParams()
            .set('done[null]', String(!viewArchived))
            .set('page', pageNumber.toString())
            .set('itemsPerPage', itemsPerPage.toString())
            .set('q', search)
            .set('folder.id', folderId)
            ;
        if (sort) {
            params = params.append('order[' + sort.active + ']', sort.direction);
        }
        return this.api.get('/todo/list', { params });
    }

    getUserTODOs(userId: string): Observable<any> {
        const params = new HttpParams().set('assignedUser.id', userId);
        return this.api.get('/todo/list', { params });
    }

    createTodo(todo: Todo): Observable<any> {
        return this.api.post('/todo', todo);
    }

    archiveTodo(todoIri: string): Observable<any> {
        const body = {
            done: (new Date()).toUTCString()
        };
        return this.api.patch(todoIri, body);
    }

    /**
     * If the _todo is archived (done) -> set it to not done
     */
    activateTodo(todoIri: string): Observable<any> {
        const body = {
            done: null
        };
        return this.api.patch(todoIri, body);
    }

    deleteTodo(todoIri: string): Observable<any> {
        return this.api.delete(todoIri);
    }

    getTodo(todoIri: string): Observable<any> {
        return this.api.get(todoIri);
    }

    patchTodo(todo: Todo): Observable<any> {
        return this.api.patch(todo['@id'], todo as Record<any, any>);
    }

    exportRequest(body: Record<any, unknown>): Observable<void> {
        // select all todos
        if ((body.iris as any[]).length === 0) {
            return this.api.post('/todos/export', { ...body, iris: null }) as Observable<void>;
        }

        return this.api.post('/todos/export', body) as Observable<void>;
    }
}
