<div class="row">
    <ng-container *ngIf="!showingReport">
        <div class="col-md-4">
            <mat-label class="field-title">{{'word.amount' | translate}}</mat-label>
            <p>{{ analysisCosts.units }}</p>
        </div>
        <div class="col-md-4">
            <mat-label class="field-title">{{'word.unit_price' | translate}}</mat-label>
            <p>{{ analysisCosts.unitPrice | number }} {{ 'word.chf' | translate }}</p>
        </div>
        <div class="col-md-4">
            <mat-label class="field-title">{{'word.total_cost' | translate}}</mat-label>
            <p>{{ computedValues.analysisCosts.totalCost | number }} {{ 'word.chf' | translate }}</p>
        </div>
        <div class="col-md-4">
            <mat-label class="field-title">{{'word.requested_contribution' | translate}}
                / {{('word.unit' | translate) | lowercase}}</mat-label>
            <p>{{ analysisCosts.requestedContribution | number }}  {{ 'word.chf' | translate }}</p>
        </div>
        <div class="col-md-4">
            <mat-label class="field-title">{{'word.total_requested_contribution' | translate}}</mat-label>
            <p>{{ computedValues.analysisCosts.prokilowattTotalContribution | number }} {{ 'word.chf' | translate }}</p>
        </div>
        <div class="col-md-4">
            <mat-label class="field-title">{{'word.subsidy_rate' | translate}}</mat-label>
            <p>{{ computedValues.analysisCosts.subsidyRate | round:(2) }} %</p>
        </div>
    </ng-container>
    <ng-container *ngIf="showingReport">

        <div class="col-md-6">
            <mat-label class="field-title">{{'word.realised_requested_contribution' | translate}}</mat-label>
            <p>{{ analysisCosts.requestedContribution * analysisCosts.units | number }}  {{ 'word.chf' | translate }}</p>
        </div>

        <div class="col-md-6">
            <mat-label class="field-title">{{'word.balance' | translate}}</mat-label>
            <p>{{ showingReport.computedValues.analysisCosts.prokilowattTotalContribution - (analysisCosts.requestedContribution * analysisCosts.units) | number }}  {{ 'word.chf' | translate }}</p>
        </div>




<!--        <div class="col-md-6">-->
<!--            <mat-label class="field-title">{{'word.balance' | translate}}</mat-label>-->

<!--          -->
<!--            -->
<!--            <p>{{ computedValues.analysisCosts.totalCost | number }} {{ 'word.chf' | translate }}</p>-->
<!--        </div>-->
<!--        <div class="col-md-4">-->
<!--            <mat-label class="field-title">{{'word.subsidy_rate' | translate}}</mat-label>-->
<!--            <p>{{ computedValues.analysisCosts.subsidyRate | round:(2) }} %</p>-->
<!--        </div>-->
    </ng-container>
</div>
