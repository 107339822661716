import { Component, Input } from '@angular/core';
import { FolderProjectResource } from '../../../../core/models/folder-project-resource.model';
import { FolderProgramResource } from '../../../../core/models/folder-program-resource.model';

@Component({
    selector: 'app-todo',
    templateUrl: './todo.component.html',
    styleUrls: ['./todo.component.scss']
})
export class TodoComponent {
    @Input()
    folder: FolderProjectResource|FolderProgramResource;

    @Input()
    bottom = '85px';

    public openShowTodoList = false;
}
