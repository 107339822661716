import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { UtilsService } from '../../../core/services/utils.service';
import { TechnicalOrientation } from '../../../core/models/technical-orientation.model';
import { min } from 'rxjs/operators';
import { FolderService } from '../../../core/services/folder.service';
import { FormUtil } from '../../../core/utils/form.util';


@Injectable()
export class ProjectCalculatorService {

    readonly maximumContribution = 2000000;
    readonly maximumContributionFactor = 0.3;
    readonly economyTotalFactor = 0.75;

    private formReference: FormGroup;
    private calculatorResults: ProjectCalculationResults = null;
    private errorMessage: string = null;
    private technicalOrientations: any[];

    constructor(
        private utilsService: UtilsService,
        private folderService: FolderService
    ){
    }

    public setFormReference(ref: FormGroup): FormGroup {
        this.formReference = ref;
        return this.formReference;
    }

    public setTechnicalOrientationsList(technicalOrientations: TechnicalOrientation[]): void {
        this.technicalOrientations = technicalOrientations;
    }

    public getErrorMessage(): string|null {
        return this.errorMessage;
    }

    public getResults(): ProjectCalculationResults|null {
        return this.calculatorResults;
    }

    get formType(): 'report'|'folder' {
        if (this.formReference.contains('currentReport')) {
            return 'report';
        }

        return 'folder';
    }

    public calculate(): Observable<boolean> {
        if (this.formReference) {

            // load ProjectCalculationResults with form reference values
            this.loadFormReferenceValues();

            // calculate values
            this.compute();

            this.errorMessage = null;
            return of(true);
        } else {
            this.errorMessage = null;
            return of(false);
        }
    }

    private loadFormReferenceValues(): void {
        this.calculatorResults = {
            costUtilityRatio: 0,
            economyAnnual: 0,
            economyPercent: 0,
            economyTotal: 0,
            maximumContribution: 0,
            measures: [],
            paybackWithoutContribution: 0,
            paybackWithContribution: 0,
            requestedContribution: this.currentProject.get('dataset.requestedContribution').value as number ?? 0,
            subsidyRate: 0,
            totalCost: 0,
            costCtskwh: this.currentProject.get('dataset.cost').value as number ?? 0
        };

        const measures = this.getMeasures().controls;
        measures.forEach((measure) => {
            // when we load page -> technicalOri is object, when we change it, it becomes an iri
            const technicalOri = measure.get('datasetMeasure.technicalOrientation').value as TechnicalOrientation;
            const technicalOriIri = technicalOri ? ('string' === typeof technicalOri ? technicalOri : technicalOri['@id']) : null;

            const techOrient = this.utilsService.findItem('@id', technicalOriIri, this.technicalOrientations) as Record<any, number>;

            let techOrientComputerValue = 0;
            if (techOrient) {
                techOrientComputerValue = techOrient.computerValue;
                /**
                 * Special case with durationOfUse :
                 * The field "durationOfUse" depends of a value of the technical orientation.
                 * We must set the value of "durationOfUse" of the form.
                 */
                measure.get('datasetMeasure.technicalOrientation').setValue(technicalOriIri);
                measure.get('datasetMeasure.durationOfUse').setValue(techOrientComputerValue);
            }

            const pcm: ProjectCalculationMeasure = {
                technicalOrientation: measure.get('datasetMeasure.technicalOrientation').value as number ?? null,
                durationOfUse: techOrientComputerValue,
                cost: parseFloat(measure.get('datasetMeasure.cost').value) ?? null,
                maximumContribution: null,
                existingAnnualConsumption: measure.get('datasetMeasure.existingAnnualConsumption').value as number ?? null,
                futureAnnualConsumption: measure.get('datasetMeasure.futureAnnualConsumption').value as number ?? null,
                economyAnnual: null,
                economyTotal: null,
                economyPercent: null,
                paybackWithoutContribution: null
            };

            this.calculatorResults.measures.push(pcm);
        });
    }

    private get currentProject(): FormGroup {
        if (this.formType === 'report') {
            return this.formReference.get('currentReport') as FormGroup;
        }

        return this.formReference.get('currentProject') as FormGroup;
    }

    private getMeasures(): FormArray {
        if (this.formType === 'report') {
            return this.currentProject.get('reportMeasures') as FormArray;
        }

        return this.currentProject.get('measures') as FormArray;
    }

    private compute(): void {
        let sumCost = 0;
        let sumMaximumContribution = 0;
        let sumEconomyAnnual = 0;
        let sumEconomyTotal = 0;
        let sumExistingAnnualConsumption = 0;

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < this.calculatorResults.measures.length; i++) {
            const measure = this.calculatorResults.measures[i];

            // maximum contribution
            measure.maximumContribution = measure.cost ?
                measure.cost * this.maximumContributionFactor :
                null
            ;

            const measures = this.getMeasures().controls;
            measures[i].get('datasetMeasure.maximumContribution').setValue(+measure.maximumContribution?.toFixed(2));

            measure.economyAnnual = measure.existingAnnualConsumption ?
                measure.futureAnnualConsumption - measure.existingAnnualConsumption :
                null
            ;

            measure.economyTotal = measure.economyAnnual ?
                measure.economyAnnual * measure.durationOfUse * this.economyTotalFactor :
                null
            ;

            measure.economyPercent = measure.existingAnnualConsumption ?
                (measure.economyAnnual / measure.existingAnnualConsumption) * 100 :
                0
            ;
            measure.paybackWithoutContribution = (measure.economyAnnual && this.calculatorResults.costCtskwh) ?
                measure.cost / ((measure.economyAnnual * this.calculatorResults.costCtskwh) / 100) :
                null
            ;

            measure.paybackWithoutContribution = measure.paybackWithoutContribution !== null ?
                Number(measure.paybackWithoutContribution.toFixed(2)) :
                null
            ;

            console.log("measureCost", measure.cost);

            sumCost += measure.cost || 0.0;
            sumMaximumContribution += measure.maximumContribution || 0.0;
            sumEconomyAnnual += measure.economyAnnual || 0.0;
            sumEconomyTotal += measure.economyTotal || 0.0;
            sumExistingAnnualConsumption += measure.existingAnnualConsumption || 0.0;
        }

        this.calculatorResults.totalCost = isNaN(sumCost) ? 0 : sumCost;
        this.calculatorResults.maximumContribution = isNaN(sumMaximumContribution) ?
            null : (sumMaximumContribution > this.maximumContribution ? this.maximumContribution : sumMaximumContribution);
        this.currentProject.get('dataset.maximumContribution')
            .setValue(this.calculatorResults.maximumContribution ? +this.calculatorResults.maximumContribution.toFixed(0) : null);

        this.calculatorResults.economyAnnual = sumEconomyAnnual;
        this.calculatorResults.economyTotal = sumEconomyTotal;
        this.calculatorResults.economyPercent = sumExistingAnnualConsumption ?
            (this.calculatorResults.economyAnnual / sumExistingAnnualConsumption) * 100 :
            0
        ;
        this.calculatorResults.paybackWithoutContribution = (this.calculatorResults.economyAnnual && this.calculatorResults.costCtskwh) ?
            this.calculatorResults.totalCost / ((this.calculatorResults.economyAnnual * this.calculatorResults.costCtskwh) / 100) :
            0
        ;
        this.calculatorResults.paybackWithContribution =
            (this.calculatorResults.costCtskwh && this.calculatorResults.requestedContribution) ?
                (this.calculatorResults.totalCost - this.calculatorResults.requestedContribution)
                / ((sumEconomyAnnual * this.calculatorResults.costCtskwh) / 100) :
                0
        ;
        this.calculatorResults.subsidyRate = this.calculatorResults.totalCost ?
            (this.calculatorResults.requestedContribution / this.calculatorResults.totalCost) * 100 :
            0
        ;
        this.calculatorResults.costUtilityRatio = this.calculatorResults.economyTotal ?
            this.calculatorResults.requestedContribution / this.calculatorResults.economyTotal * 100 :
            0
        ;
        if (this.calculatorResults.paybackWithoutContribution) {
            this.calculatorResults.paybackWithoutContribution = Number(this.calculatorResults.paybackWithoutContribution.toFixed(2));
        }

        if (this.calculatorResults.paybackWithContribution) {
            this.calculatorResults.paybackWithContribution = Number(this.calculatorResults.paybackWithContribution.toFixed(2));
        }

        // custom report max contrib calculation
        if (this.formType === 'report') { // set max contribution for report
            if (Math.abs(this.calculatorResults.paybackWithoutContribution) < 4) {
                this.calculatorResults.maximumContribution = 0;
            } else {
                const validatedFolder = this.folderService.getProjectResource().data.projects
                    .find(folder => 'validation_2' === folder.marking);

                if (!validatedFolder) {
                    return;
                }

                const investments = this.calculatorResults.totalCost / validatedFolder.computedValues.totalCost;
                const savings = Math.abs(this.calculatorResults.economyTotal) / validatedFolder.computedValues.totalSaving;
                of(1, investments, savings).pipe(min()).subscribe(res => {
                    this.calculatorResults.maximumContribution = Math.abs(res * validatedFolder.dataset.requestedContribution);
                });
            }
        }

        FormUtil.validateAllFormFields(this.currentProject);
    }
}

export interface ProjectCalculationResults {
    measures: ProjectCalculationMeasure[];
    totalCost: number;                            // calculated
    maximumContribution: number;                  // calculated
    requestedContribution: number;
    economyAnnual: number;                        // calculated
    economyTotal: number;                         // calculated
    economyPercent: number;                       // calculated
    paybackWithoutContribution: number;           // calculated
    paybackWithContribution: number;              // calculated
    subsidyRate: number;                          // calculated
    costUtilityRatio: number;                     // calculated
    costCtskwh: number;
}

export interface ProjectCalculationMeasure {
    technicalOrientation: number;
    durationOfUse: number;                        // preselected into technical orientation list
    cost: number;
    maximumContribution: number;                  // calculated
    existingAnnualConsumption: number;
    futureAnnualConsumption: number;
    economyAnnual: number;                        // calculated
    economyTotal: number;                         // calculated
    economyPercent: number;                       // calculated
    paybackWithoutContribution: number;            // calculated
}
