import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SummaryCardComponent } from './project/summary-card/summary-card.component';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { NgMathPipesModule } from 'ngx-pipes';
import { ProgramSummaryCardComponent } from './program/summary-card/summary-card.component';
import { AnalysisCostsComponent } from './program/analysis-costs/analysis-costs.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ComponentsModule as SharedComponents } from '../../../components/components.module';
import { ProgramFixCostsComponent } from './program/program-fix-costs/program-fix-costs.component';
import { ProgramReadonlyFixCostsComponent } from './program/program-readonly-fix-costs/program-readonly-fix-costs.component';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
    ReportReadonlyFixCostsComponent
} from './report/report-readonly-fix-costs/report-readonly-fix-costs.component';

@NgModule({
    declarations: [
        SummaryCardComponent,
        ProgramSummaryCardComponent,
        AnalysisCostsComponent,
        ProgramFixCostsComponent,
        ProgramReadonlyFixCostsComponent,
        ReportReadonlyFixCostsComponent
    ],
    exports: [
        SummaryCardComponent,
        ProgramSummaryCardComponent,
        AnalysisCostsComponent,
        ProgramFixCostsComponent,
        ProgramReadonlyFixCostsComponent,
        ReportReadonlyFixCostsComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        TranslateModule,
        PipesModule,
        NgMathPipesModule,
        MatFormFieldModule,
        SharedComponents,
        MatIconModule,
        ReactiveFormsModule,
        MatInputModule,
        MatTooltipModule
    ]
})
export class ComponentsModule { }
