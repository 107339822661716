import { NgModule } from '@angular/core';
import { LoginRoutingModule } from './login-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { LoginComponent } from './login/login.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from '../../shared/shared.module';
import { ComponentsModule } from '../../components/components.module';


@NgModule({
    declarations: [
        LoginComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        LoginRoutingModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatTabsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatCardModule,
        MatProgressSpinnerModule,
        SharedModule,
        ComponentsModule,
    ],
    providers: [
    ],
    bootstrap: [LoginComponent]
})
export class LoginModule { }
