import { BaseDatasource } from './base.datasource';
import { environment } from '../../../environments/environment';
import { Paginator } from '../models/paginator.model';
import { COMPUTOR_TYPES, FolderService } from '../services/folder.service';
import { Sort } from '@angular/material/sort';
import { FilterM } from '../models/filter.model';

export class AdminProgramDatasource extends BaseDatasource {
    constructor(
        private folderService: FolderService,
    ) {
        super();
    }

    public loadPrograms(page = 1, itemsPerPage: number = environment.itemsPerPage, search = '', sort?: Sort, filter?: FilterM): void {
        this.folderService.findAllPaginated(page, itemsPerPage, search, sort, filter, COMPUTOR_TYPES.program)
            .subscribe((paginator: Paginator) => {
                this.totalItems = paginator['hydra:totalItems'];
                this.itemsPerPage = itemsPerPage;
                this.subject.next(paginator['hydra:member']);
            });
    }

}
