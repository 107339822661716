import { Component, OnInit } from '@angular/core';
import { FolderProgramResource } from '../../../../../../core/models/folder-program-resource.model';
import AbstractComputor from '../../../../../abstract-computor';
import { SwitchUserService } from '../../../../../../core/services/switch-user.service';
import { ActivatedRoute } from '@angular/router';
import {
    AnalysisCost,
    FIX_COST_TYPE_ACCOMPANIMENT,
    FIX_COST_TYPE_MANAGEMENT,
    FixCost
} from '../../../../../../core/models/fixCost.model';
import { MediaObject } from '../../../../../../core/models/media-object.model';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import { MenuService } from '../../../../../../core/services/menu.service';
import { ProgramMeasure } from '../../../../../../core/models/measure.model';
import { COMPUTOR_NAMES, FolderService } from '../../../../../../core/services/folder.service';
import { HttpResponse } from '@angular/common/http';
import { DownloaderHelper } from '../../../../../../core/utils/download.util';
import { Observable } from 'rxjs';
import { NotificationService } from '../../../../../../core/notifications/notification.service';
import { FolderProgramService } from '../../../../../../core/services/folder-program.service';
import { LoadingService } from '../../../../../../core/services/loading.service';
import { ProgramComputedValues } from '../../../../../../core/models/computed-values.model';

@Component({
    selector: 'app-program-show-step',
    templateUrl: './program-show-step.component.html',
    styleUrls: ['./program-show-step.component.scss']
})
export class ProgramShowStepComponent extends AbstractComputor implements OnInit {
    public static computorName = COMPUTOR_NAMES.computor2024Big;

    public folder: FolderProgramResource;

    constructor(
        public switchUserService: SwitchUserService,
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private menuService: MenuService,
        private folderService: FolderService,
        private folderProgramService: FolderProgramService,
        private notificationService: NotificationService,
        public loading: LoadingService
    ) {
        super(switchUserService);

        this.folder = this.route.snapshot.data.folderProgramResource as FolderProgramResource;

        this.folderService.setProgramResource(this.folder);
    }

    ngOnInit(): void {
        this.initBreadcrumb();
    }

    public get managementFixCosts(): FixCost[] {
        return this.folder.data.currentProgram.fixCosts.filter(fixCost => FIX_COST_TYPE_MANAGEMENT === fixCost.type);
    }

    public get accompanimentFixCosts(): FixCost[] {
        return this.folder.data.currentProgram.fixCosts.filter(fixCost => FIX_COST_TYPE_ACCOMPANIMENT === fixCost.type);
    }

    public get analysisCosts(): AnalysisCost {
        return this.folder.data.currentProgram.analysisCosts;
    }

    public get computedValues(): ProgramComputedValues {
        return this.folderProgram.data.currentProgram.computedValues;
    }

    public get measures(): ProgramMeasure[] {
        return this.folder.data.currentProgram.measures;
    }

    public get mediaObjects(): MediaObject[]|null {
        return this.folder.data.currentProgram.mediaObjects ?? null;
    }

    public exportPdf(): void {
        this.exportPdfCall().subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        }, () => {
            this.notificationService.error('toast.an_error_occurred_while_generating_pdf');
        });
    }

    private initBreadcrumb(): void {
        const folderName = this.folder.data.currentProgram.acronym;
        this.breadcrumbService.clientFolderStep(folderName);

        const menu = [{link: '/dashboard', label: 'menu.dashboard', icon: 'icon-home', subItems: []}, {
            link: null, label: folderName, icon: 'icon-clipboard', subItems: [
                { link: null, label: 'word.supporting_organisation', icon: 'icon-1', scrollTo: '#section1'},
                { link: null, label: 'title.project_holder', icon: 'icon-2', scrollTo: '#section2'},
                { link: null, label: 'title.general', icon: 'icon-3', scrollTo: '#section3'},
                { link: null, label: 'word.fix_costs', icon: 'icon-4', scrollTo: '#section4'},
                { link: null, label: 'title.measures', icon: 'icon-5', scrollTo: '#section5'},
                { link: null, label: 'word.analysis_costs', icon: 'icon-6', scrollTo: '#section6'},
                { link: null, label: 'title.documents', icon: 'icon-7', scrollTo: '#section7'},
            ]
        }];

        this.menuService.store(menu);
    }

    private exportPdfCall(): Observable<any> {
        return this.folderProgramService.pdf(this.folderProgram.data.id.toString(), this.folderProgram.data.currentProgram.marking, 'pdf_program_exporter');
    }
}
