import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { ProjectReportResource } from '../models/project-report-resource';


@Injectable({
    providedIn: 'root'
})
export class ProjectReportService {

    constructor(
        private api: ApiService
    ) { }

    public save(body: ProjectReportResource, type: string, computor: string, isSubmitting = false): Observable<any> {
        const params = new HttpParams()
            .set('type', type)
            .set('computor', computor)
            .set('submitting', String(isSubmitting));

        return this.api.post('/project_report_resource', body, {params});
    }

    /**
     * Check a report resource
     */
    public check(body: ProjectReportResource, type: string, computor: string): Observable<ProjectReportResource> {
        const params = new HttpParams()
            .set('type', type)
            .set('computor', computor)
            .set('checking', String(true));

        return this.api.post('/project_report_resource', body, {params}) as Observable<ProjectReportResource>;
    }

    public pdf(objectId: string, marking: string, exporterName: string): Observable<HttpResponse<Blob>> {
        const params = new HttpParams()
            .set('marking', marking)
            .set('exporter_name', exporterName)
        ;

        return this.api.get('/project_report_resource/' + objectId + '/pdf', {
            params,
            observe: 'response',
            responseType: 'blob' as 'json'
        }) as Observable<HttpResponse<Blob>>;
    }
}
