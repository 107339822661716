import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Breadcrumb } from '../models/breadcrumb.model';
import { PageTitleService } from './page-title.service';
import { TranslateService } from '@ngx-translate/core';
import { FolderService } from './folder.service';
import { StringUtil } from '../utils/string.util';
import { ResourceUtil } from '../utils/resourceUtil';
import { IriUtil } from '../utils/iri.util';


@Injectable()
export class BreadcrumbService {
    public breadcrumbsSource: Subject<Breadcrumb[]>;
    public breadcrumbsChanged$: Observable<Breadcrumb[]>;

    constructor(
        private title: PageTitleService,
        private translator: TranslateService,
        private folder: FolderService
    ) {
        this.breadcrumbsSource = new Subject<Breadcrumb[]>();
        this.breadcrumbsChanged$ = this.breadcrumbsSource.asObservable();
    }

    public store(breadcrumbs: Breadcrumb[]): void {
        this.breadcrumbsSource.next(breadcrumbs);
    }

    // Return the breadcrumbs
    public get(): Observable<Breadcrumb[]> {
        return this.breadcrumbsChanged$;
    }

    public reportView(folderLink: string): void {
        const folder = this.folder.getCurrentProjectOrProgram();
        const report = this.folder.getReportResource();

        this.title.title(String(this.translator.instant('word.report')) + ' ' + report.data.name + ' - '
            + String(this.translator.instant('workflow.' + report.data.currentReport.marking)));

        this.store([
            {
                label: folder.acronym,
                url: folderLink
            }, {
                label: 'menu.reports',
            }, {
                label: report.data.name,
            }
        ]);
    }

    public folderValidation2(folderLink: string): void {
        const folder = this.folder.getCurrentProjectOrProgram();

        this.title.title(folder.name + ' - ' + String(this.translator.instant('workflow.validation')));

        let firstStep = {
            label: 'menu.projects',
            url: '/admin/projects'
        };

        if (ResourceUtil.isProgram(this.folder.getCurrentResource())) {
            firstStep = {
                label: 'menu.programs',
                url: '/admin/programs'
            };
        }

        this.store([
            firstStep,
            {
                label: StringUtil.sprintf('%s %s',
                    folder.acronym,
                    this.folder.getCurrentResource().data.reference ? '(' + this.folder.getCurrentResource().data.reference + ')' : ''
                ),
                url: folderLink
            },
            {
                label: 'workflow.validation',
            }
        ]);
    }

    public folderView(): void {
        const folder = this.folder.getCurrentProjectOrProgram();

        this.title.title(folder.name);

        let firstStep = {
            label: 'menu.projects',
            url: '/admin/projects'
        };

        if (ResourceUtil.isProgram(this.folder.getCurrentResource())) {
            firstStep = {
                label: 'menu.programs',
                url: '/admin/programs'
            };
        }

        this.store([
            firstStep,
            {
                label: StringUtil.sprintf('%s %s',
                    folder.acronym,
                    this.folder.getCurrentResource().data.reference ? '(' + this.folder.getCurrentResource().data.reference + ')' : ''
                )
            }
        ]);
    }

    folderStep(folderLink: string): void {
        const folder = this.folder.getCurrentProjectOrProgram();

        let firstStep = {
            label: 'menu.projects',
            url: '/admin/projects'
        };

        if (ResourceUtil.isProgram(this.folder.getCurrentResource())) {
            firstStep = {
                label: 'menu.programs',
                url: '/admin/programs'
            };
        }

        this.title.title(folder.name + ' - ' + String(this.translator.instant('workflow.' + folder.markingName)));

        this.store([
            firstStep,
            {
                label: StringUtil.sprintf('%s %s',
                    folder.acronym,
                    this.folder.getCurrentResource().data.reference ? '(' + this.folder.getCurrentResource().data.reference + ')' : ''
                ),
                url: folderLink
            }, {
                label: 'workflow.' + folder.markingName,
            }
        ]);
    }

    public clientFolderView(folderName: string): void {
        this.title.title(folderName);

        this.store([
            {
                label: 'title.dashboard',
                url: '/dashboard'
            }, {
                label: folderName,
            }
        ]);
    }

    public clientFolderStep(folderName: string): void {
        const folder = this.folder.getCurrentProjectOrProgram();
        this.title.title(folderName + ' - ' + String(this.translator.instant('workflow.' + folder.markingName)));

        let view = 'folders';

        if (ResourceUtil.isProgram(this.folder.getCurrentResource())) {
            view = 'programs';
        }

        this.store([{
            label: 'title.dashboard',
            url: '/dashboard'
        }, {
            label: folderName,
            url: '/sessions/' + IriUtil.extractId(this.folder.getCurrentResource().data.tenderSession['@id']) +
                `/${view}/` + String(this.folder.getCurrentResource().data.id) + '/view'
        }, {
            label: 'workflow.user.' + folder.markingName,
        }]);
    }
}
