<ol class="mat-elevation-z1" *ngIf="showBreadcrumb">
  <li *ngFor="let breadcrumb of breadcrumbs; let last = last" [ngClass]="{ 'active': last }">
    <a *ngIf="!last && breadcrumb.url" [routerLink]="breadcrumb.url">
      {{ breadcrumb.label | translate }}
    </a>

    <span *ngIf="!last && !breadcrumb.url">{{ breadcrumb.label | translate }}</span>

    <span *ngIf="last" class="last">{{ breadcrumb.label | translate }}</span>
  </li>
</ol>
