<ng-container [formGroup]="form">
    <!-- Summary -->
    <app-card cardNumber="1" formGroupName="currentReport" cardTitle="title.summary">
        <div class="table-scroll" formGroupName="dataset">
            <table class="table table-responsive w-100" appTableCellHighlighter>
                <thead>
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let column of folderProject.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <th class="min-w-120" *ngIf="step['@permissions'].view">
                                {{ 'workflow.' + step['@columnName'] | translate }}
                            </th>
                        </ng-container>
                    </ng-container>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th>{{ 'word.total_cost' | translate }}</th>
                    <ng-container *ngFor="let column of folderProject.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step['computedValues'].totalCost | currency | default:'-' }}
                                </ng-container>
                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{ computedResume.totalCost | currency | default:'-' }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.maximum_contribution' | translate }}</th>
                    <ng-container *ngFor="let column of folderProject.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step['computedValues'].maximumContribution | currency | default:'-' }}
                                </ng-container>
                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{ computedResume.maximumContribution | currency | default:'-' }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.requested_contribution' | translate }}</th>
                    <ng-container *ngFor="let column of folderProject.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.dataset.requestedContribution | currency | default:'-' }}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    <mat-form-field>
                                        <input matInput
                                               formControlName="requestedContribution"
                                               type="number"
                                               autocomplete="off"
                                               (change)="executeCalculation()">
                                        <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>

                                        <mat-error
                                                *ngIf="form.controls['currentReport'].get('dataset').get('requestedContribution').hasError('min')">
                                            {{ 'validator.requested_contribution.min' | translate:{min: form.controls['currentReport'].get('dataset').get('requestedContribution').errors['min'].min} }}
                                        </mat-error>
                                    </mat-form-field>
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.annual_saving' | translate }}</th>
                    <ng-container *ngFor="let column of folderProject.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step['computedValues'].annualSaving | positive | number }} {{ 'word.kwh' | translate }}
                                </ng-container>
                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{ computedResume.economyAnnual | positive | number }} {{ 'word.kwh' | translate }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.payback_without_contribution' | translate }}</th>
                    <ng-container *ngFor="let column of folderProject.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ 'word.n_years' | translate:{count: step['computedValues'].paybackWithoutContribution} | default:'-' }}
                                </ng-container>
                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{ 'word.n_years' | translate:{count: (computedResume.paybackWithoutContribution | positive | round)} | default:'-' }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.cost_utility_ratio' | translate }}</th>
                    <ng-container *ngFor="let column of folderProject.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step['computedValues'].costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}
                                </ng-container>
                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{ computedResume.costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>
    </app-card>

    <!-- Measures -->
    <app-card cardNumber="2" cardTitle="title.measures_evolution">
        <!-- Measures -->
        <div formGroupName="currentReport">
            <ng-container *ngFor="let measure of measures; let i = index;">
                <div class="table-scroll mt-4" formGroupName="reportMeasures"
                     *ngIf="form.get('currentReport.reportMeasures')['controls'][i]">
                    <h3 class="mb-1">{{ 'word.measure_n' | translate:{count: i + 1} }}</h3>
                    <table class="table table-responsive w-100" appTableCellHighlighter>
                        <thead>
                        <tr>
                            <th class="w-25">{{ '' }}</th>
                            <ng-container *ngFor="let column of folderProject.reportSteps">
                                <ng-container *ngFor="let step of column.steps">
                                    <th class="min-w-120" *ngIf="step['@permissions'].view && step.measures[i]">
                                        {{ 'workflow.' + step['@columnName'] | translate }}
                                    </th>
                                </ng-container>
                            </ng-container>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th>{{ 'word.technical_orientation' | translate }}</th>
                            <ng-container *ngFor="let column of folderProject.reportSteps">
                                <ng-container *ngFor="let step of column.steps">
                                    <td *ngIf="step['@permissions'].view && step.measures[i] && step.measures[i].technicalOrientation">
                                        {{ step.measures[i].technicalOrientation.name | default:'-' }}
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>

                        <tr>
                            <th>{{ 'word.duration_of_use' | translate }}</th>
                            <ng-container *ngFor="let column of folderProject.reportSteps">
                                <ng-container *ngFor="let step of column.steps">
                                    <td *ngIf="step['@permissions'].view && step.measures[i]">
                                        {{ 'word.n_years' | translate:{count: step.measures[i].durationOfUse | default:'-'} }}
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>

                        <tr>
                            <th>{{ 'word.measure_cost' | translate }}</th>
                            <ng-container *ngFor="let column of folderProject.reportSteps">
                                <ng-container *ngFor="let step of column.steps">
                                    <td *ngIf="step['@permissions'].view && step.measures[i]">
                                        <ng-container *ngIf="!step['@permissions'].edit">
                                            {{ step.measures[i].cost | number | default:'-' }} {{ 'word.chf' | translate }}
                                        </ng-container>

                                        <ng-container [formGroupName]="i" *ngIf="step['@permissions'].edit">
                                            <div class="dataset-measure fields-group"
                                                 formGroupName="datasetMeasure">
                                                <mat-form-field>
                                                    <input matInput
                                                           formControlName="cost"
                                                           type="number"
                                                           autocomplete="off"
                                                           min="{{lengthValidators.positiveValue}}"
                                                           (change)="executeCalculation()">
                                                    <span class="mat-input-suffix"
                                                          matSuffix>{{ 'word.chf' | translate }}</span>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>

                        <tr>
                            <th>{{ 'word.existing_annual_consumption' | translate }}</th>
                            <ng-container *ngFor="let column of folderProject.reportSteps">
                                <ng-container *ngFor="let step of column.steps">
                                    <td *ngIf="step['@permissions'].view && step.measures[i]">
                                        <ng-container *ngIf="!step['@permissions'].edit">
                                            {{ step.measures[i].existingAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                                        </ng-container>

                                        <ng-container [formGroupName]="i" *ngIf="step['@permissions'].edit">
                                            <div class="dataset-measure fields-group"
                                                 formGroupName="datasetMeasure">
                                                <mat-form-field>
                                                    <input matInput
                                                           formControlName="existingAnnualConsumption"
                                                           type="number"
                                                           autocomplete="off"
                                                           min="{{lengthValidators.positiveValue}}"
                                                           (change)="executeCalculation()">
                                                    <span class="mat-input-suffix"
                                                          matSuffix>{{ 'word.kwh' | translate }}</span>

                                                    <mat-error
                                                            *ngIf="form.get('currentReport.reportMeasures')['controls'][i].get('datasetMeasure.existingAnnualConsumption').hasError('required')">
                                                        {{ 'validator.existing_annual_consumption.not_blank' | translate }}
                                                    </mat-error>
                                                    <mat-error
                                                            *ngIf="form.get('currentReport.reportMeasures')['controls'][i].get('datasetMeasure.existingAnnualConsumption').hasError('min')">
                                                        {{ 'validator.existing_annual_consumption.min' | translate:{min: form.get('currentReport.reportMeasures')['controls'][i].get('datasetMeasure.existingAnnualConsumption').errors['min'].min} }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>

                        <tr>
                            <th>{{ 'word.future_annual_consumption' | translate }}</th>
                            <ng-container *ngFor="let column of folderProject.reportSteps">
                                <ng-container *ngFor="let step of column.steps">
                                    <td *ngIf="step['@permissions'].view && step.measures[i]">
                                        <ng-container *ngIf="!step['@permissions'].edit">
                                            {{ step.measures[i].futureAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                                        </ng-container>

                                        <ng-container [formGroupName]="i" *ngIf="step['@permissions'].edit">
                                            <div class="dataset-measure fields-group"
                                                 formGroupName="datasetMeasure">
                                                <mat-form-field>
                                                    <input matInput
                                                           formControlName="futureAnnualConsumption"
                                                           type="number"
                                                           autocomplete="off"
                                                           min="{{lengthValidators.positiveValue}}"
                                                           (change)="executeCalculation()">
                                                    <span class="mat-input-suffix"
                                                          matSuffix>{{ 'word.kwh' | translate }}</span>
                                                    <mat-error
                                                            *ngIf="form.get('currentReport.reportMeasures')['controls'][i].get('datasetMeasure.futureAnnualConsumption').hasError('min')">
                                                        {{ 'validator.future_annual_consumption.min' | translate:{min: form.get('currentReport.reportMeasures')['controls'][i].get('datasetMeasure.futureAnnualConsumption').errors['min'].min} }}
                                                    </mat-error>
                                                    <mat-error
                                                            *ngIf="form.get('currentReport.reportMeasures')['controls'][i].get('datasetMeasure.futureAnnualConsumption').hasError('lessThan')">
                                                        {{ 'validator.future_annual_consumption_must_be_lower_than_existing_annual_consumption' | translate }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>

                        <tr>
                            <th>{{ 'word.annual_saving' | translate }}</th>
                            <ng-container *ngFor="let column of folderProject.reportSteps">
                                <ng-container *ngFor="let step of column.steps">
                                    <td *ngIf="step['@permissions'].view && step.measures[i] && computedResume.measures[i]">
                                        <ng-container *ngIf="!step['@permissions'].edit">
                                            {{ step.measures[i].annualSavings | positive | number | default: '-': ('word.kwh' | translate) }}
                                        </ng-container>
                                        <ng-container *ngIf="step['@permissions'].edit">
                                            {{ computedResume.measures[i].economyAnnual | positive | number | default: '-': ('word.kwh' | translate) }}
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>

                        <tr>
                            <th>{{ 'word.total_saving' | translate }}</th>
                            <ng-container *ngFor="let column of folderProject.reportSteps">
                                <ng-container *ngFor="let step of column.steps">
                                    <td *ngIf="step['@permissions'].view && step.measures[i] && computedResume.measures[i]">
                                        <ng-container *ngIf="!step['@permissions'].edit">
                                            {{ step.measures[i].totalSavings | positive | number |  default: '-': ('word.kwh' | translate) }}
                                        </ng-container>
                                        <ng-container *ngIf="step['@permissions'].edit">
                                            {{ computedResume.measures[i].economyTotal | positive | number |  default: '-': ('word.kwh' | translate) }}
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>

                        <tr>
                            <th>{{ 'word.percent_saving' | translate }}</th>
                            <ng-container *ngFor="let column of folderProject.reportSteps">
                                <ng-container *ngFor="let step of column.steps">
                                    <td *ngIf="step['@permissions'].view && step.measures[i] && computedResume.measures[i]">
                                        <ng-container *ngIf="!step['@permissions'].edit">
                                            {{ step.measures[i].percentSavings | positive | number |  default:'-' }}
                                            %
                                        </ng-container>
                                        <ng-container *ngIf="step['@permissions'].edit">
                                            {{ computedResume.measures[i].economyPercent | positive | number |  default:'-' }}
                                            %
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>

                        <tr>
                            <th>{{ 'word.description' | translate }}</th>
                            <ng-container *ngFor="let column of folderProject.reportSteps">
                                <ng-container *ngFor="let step of column.steps">
                                    <td *ngIf="step['@permissions'].view && step.measures[i]">
                                        <ng-container *ngIf="!step['@permissions'].edit">
                                            <div [innerHTML]="step.measures[i].description | default:'-'"></div>
                                        </ng-container>

                                        <ng-container [formGroupName]="i" *ngIf="step['@permissions'].edit">
                                            <div class="dataset-measure fields-group"
                                                 formGroupName="datasetMeasure">
                                                <mat-form-field>
                              <textarea matInput
                                        formControlName="description"
                                        cdkTextareaAutosize
                                        cdkAutosizeMinRows="5"
                                        maxlength="{{ lengthValidators.longString }}"></textarea>
                                                    <mat-error
                                                            *ngIf="form.get('currentReport.reportMeasures')['controls'][i].get('datasetMeasure.description').hasError('maxlength')">
                                                        {{ 'validator.field.too_long' | translate: '{ max: 4000 }' }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </div>
    </app-card>
    <!--  Analysis -->
    <app-card cardNumber="3" appAdminComponent cardTitle="title.analysis">
        <div class="row">
            <div class="col-md-8" formGroupName="analysis">
                <mat-form-field>
                    <mat-label>{{ 'word.decision' | translate }}</mat-label>
                    <textarea matInput
                              formControlName="decision"
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="5"
                              #analysisDecision
                              [readonly]="!canEditAnalyse()"
                              maxlength="10000"></textarea>
                    <mat-hint align="end">{{ analysisDecision.value.length }} / 65000</mat-hint>
                    <mat-error *ngIf="form.get('analysis.decision').hasError('maxlength')">
                        {{ 'validator.field.too_long' | translate: {max: 65000} }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <table class="table w-100">
                    <thead>
                    <tr>
                        <th class="min-w-120">
                            {{ 'word.documents' | translate }}

                            <ng-container>
                                <div class="add-media-object" *ngIf="canEdit()">
                                    <button mat-mini-fab color="accent" (click)="addAnalysisMediaObject()">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </ng-container>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <mat-list dense role="list">
                                <mat-list-item role="listitem"
                                               *ngFor="let mediaObject of form.get('analysisMediaObjects').value; let i = index; last as last">
                                    <span class="mat-small" mat-line>{{ mediaObject.originalName }}</span>
                                    <ng-container *appIsGranted="'ROLE_STAFF_PKW'">
                                        <button
                                                mat-icon-button
                                                appConfirmDialog
                                                (confirmClick)="deleteAnalysisMediaObject(mediaObject, i)"
                                                *ngIf="reportNotCompleted()"
                                        >
                                            <mat-icon class="mat-16">delete_outline</mat-icon>
                                        </button>
                                    </ng-container>

                                    <button mat-icon-button (click)="downloadMediaObject(mediaObject)">
                                        <mat-icon class="mat-16">get_app</mat-icon>
                                    </button>
                                </mat-list-item>
                            </mat-list>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" formGroupName="analysis">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>{{ 'word.comment' | translate }}</mat-label>
                    <textarea matInput
                              formControlName="comment"
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="5"
                              #analysisComment
                              [readonly]="!canEditAnalyse()"
                              maxlength="65000"></textarea>
                    <mat-hint align="end">{{ analysisComment.value.length }} / 65000</mat-hint>
                    <mat-error *ngIf="form.get('analysis.comment').hasError('maxlength')">
                        {{ 'validator.field.too_long' | translate: {max: 65000} }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </app-card>
</ng-container>

<app-admin-remark-card appAdminComponent [cardNumber]="4" [folder]="folderProject"></app-admin-remark-card>

<app-documents-list sectionNumber="5" [reportIri]="projectReportResource.data.currentReport.report['@id']"
                    [mediaObjects]="projectReportResource.data.currentReport.mediaObjects"></app-documents-list>

<app-invoices-list [sectionNumber]="6" [hideControls]="!reportNotCompleted()" [report]="projectReportResource"
                   [showStateControls]="reportNotCompleted()"></app-invoices-list>

<!-- Todos -->
<app-todo [folder]="folderProject" *appIsGranted="'ROLE_STAFF_PKW'"></app-todo>

<app-sticky-footer *appIsGranted="'ROLE_CORNELIA'">
    <div class="w-50">
        <ng-container *ngIf="isValidation()">
            <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="save()"
                    [loading]="loading.loading$ | async">
                {{ 'action.save' | translate }} <i class="icon-floppy-disk"></i>
            </button>
            <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="check()"
                    [loading]="loading.loading$ | async">
                {{ 'action.check' | translate }} <i class="icon-checked"></i>
            </button>
        </ng-container>
        <button mat-flat-button color="primary" appMatButtonLoading (click)="exportPdf()"
                [loading]="loading.loading$ | async">
            {{ 'action.pdf' | translate }} <i class="icon-pdf-file"></i>
        </button>
    </div>
    <div class="w-50 d-flex justify-content-end" *appIsGranted="'ROLE_STAFF_PKW'">
        <button mat-flat-button color="primary" class="align-self-end" (click)="openReportSettingsDialog()">
            {{ 'word.report_settings' | translate }} <i class="icon-filter"></i>
        </button>
    </div>
</app-sticky-footer>

<input #fileInput
       type="file"
       class="file-input hidden"
       multiple="multiple"
       (change)="onNewFileSelected($event)"/>
