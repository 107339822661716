import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { ResetPasswordRoutingModule } from './reset-password-routing.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserService } from '../../core/services/user.service';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AskResetComponent } from './ask-reset/ask-reset.component';
import { SharedModule } from '../../shared/shared.module';
import { ComponentsModule } from '../../components/components.module';


@NgModule({
    declarations: [
        ResetPasswordComponent,
        AskResetComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ResetPasswordRoutingModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatTabsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatCardModule,
        MatProgressSpinnerModule,
        SharedModule,
        ComponentsModule,
    ],
    providers: [
        UserService
    ],
    bootstrap: [ResetPasswordComponent]
})
export class ResetPasswordModule {
}
