import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Injectable()
@Pipe({
    name: 'toCents',
    pure: false, // required to update the value when the promise is resolved
})
export class ToCentsPipe implements PipeTransform {

    public transform(value: number|string): number {
        return +value / 100;
    }
}
