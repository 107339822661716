import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { TenderSession } from '../models/tender-session.model';
import { HttpParams } from '@angular/common/http';


@Injectable()
export class TenderSessionService {

    constructor(
        private apiService: ApiService
    ) {
    }

    /**
     * Find a TenderSession by his id
     */
    public findAll(): Observable<any> {
        return this.apiService.get('/tender_sessions/');
    }

    /**
     * Find a TenderSession by his id
     */
    public find(id: string): Observable<TenderSession> {
        return this.apiService.get('/tender_sessions/' + id).pipe(
            map((res) => res as TenderSession)
        );
    }

    public get(params?: HttpParams): Observable<any> {
        return this.apiService.get('/tender_sessions', { params });
    }

    public create(form: TenderSession): Observable<any> {
        return this.apiService.post('/tender_sessions', form);
    }

    public delete(TSiri: string): Observable<any> {
        return this.apiService.delete(TSiri);
    }

    public update(TSiri: string, form: TenderSession): Observable<any> {
        return this.apiService.patch(TSiri, form as Record<any, any>);
    }
}
