import { NgModule } from '@angular/core';
import { PositivePipe } from './positive.pipe';
import { ToCentsPipe } from './to-cents.pipe';
import { DefaultPipe } from './default.pipe';
import { SnakeCasePipe } from './snake-case.pipe';
import { ReferencePipe } from './reference.pipe';
import { Nl2BrPipe } from './nl-2-br.pipe';
import { LocalizedDatePipe } from './localized-date.pipe';
import { Iri2IdPipe } from './iri-2-id.pipe';

@NgModule({
    imports: [],
    declarations: [
        PositivePipe,
        ToCentsPipe,
        DefaultPipe,
        SnakeCasePipe,
        ReferencePipe,
        Nl2BrPipe,
        LocalizedDatePipe,
        Iri2IdPipe
    ],
    exports: [
        PositivePipe,
        ToCentsPipe,
        DefaultPipe,
        SnakeCasePipe,
        ReferencePipe,
        Nl2BrPipe,
        LocalizedDatePipe,
        Iri2IdPipe
    ]
})
export class PipesModule { }
