<div class="row" [formGroup]="managersForm">
    <div class="col-md-3">
        <p class="field-title">{{ 'persons.analysis_gs' | translate }}</p>
        <mat-form-field>
            <mat-select formControlName="gsAnalysisManager">
                <mat-option>
                    <ngx-mat-select-search [formControl]="GS_AN_SEARCH"
                                           placeholderLabel="{{'word.search' | translate}}"></ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="null">{{'word.none' | translate}}</mat-option>
                <mat-option *ngFor="let user of F_GS_ANALYSIS_USERS"
                            value="{{ user['@id'] }}">{{ user.lastname }} {{ user.firstname }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-3">
        <p class="field-title">{{ 'persons.monitoring_gs' | translate }}</p>
        <mat-form-field>
            <mat-select formControlName="gsMonitoringManager">
                <mat-option>
                    <ngx-mat-select-search [formControl]="GS_MO_SEARCH"
                                           placeholderLabel="{{'word.search' | translate}}"></ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="null">{{'word.none' | translate}}</mat-option>
                <mat-option *ngFor="let user of F_GS_MONITORING_USERS"
                            value="{{ user['@id'] }}">{{ user.lastname }} {{ user.firstname }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-3">
        <p class="field-title">{{ 'persons.analysis_ofen' | translate }}</p>
        <mat-form-field>
            <mat-select formControlName="ofenAnalysisManager">
                <mat-option>
                    <ngx-mat-select-search [formControl]="OF_AN_SEARCH"
                                           placeholderLabel="{{'word.search' | translate}}"></ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="null">{{'word.none' | translate}}</mat-option>
                <mat-option *ngFor="let user of F_OFEN_ANALYSIS_USERS"
                            value="{{ user['@id'] }}">{{ user.lastname }} {{ user.firstname }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div class="col-md-3">
        <p class="field-title">{{ 'persons.monitoring_ofen' | translate }}</p>
        <mat-form-field>
            <mat-select formControlName="ofenMonitoringManager">
                <mat-option>
                    <ngx-mat-select-search [formControl]="OF_MO_SEARCH"
                                           placeholderLabel="{{'word.search' | translate}}"></ngx-mat-select-search>
                </mat-option>
                <mat-option [value]="null">{{'word.none' | translate}}</mat-option>
                <mat-option *ngFor="let user of F_OFEN_MONITORING_USER"
                            value="{{ user['@id'] }}">{{ user.lastname }} {{ user.firstname }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
