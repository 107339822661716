import { Component, Inject, OnInit } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { Violation } from '../../core/models/violation.model';
import { FolderService } from '../../core/services/folder.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-errors-bottom-sheet',
    templateUrl: './errors-bottom-sheet.component.html',
    styleUrls: ['./errors-bottom-sheet.component.scss']
})
export class ErrorsBottomSheetComponent implements OnInit {

    public violations: Violation[];

    public violationsCount = 0;

    public isReport = false;

    // for translated property path
    private contactIdx = null;

    constructor(
        private bottomSheetRef: MatBottomSheetRef<ErrorsBottomSheetComponent>,
        private folderService: FolderService,
        private translateService: TranslateService,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: {violations: Violation[]}
    ) {
        this.violations = this.assignPropertyFromPropertyPath(data.violations);
        this.violations = this.buildViolationPath(this.violations);
        this.violationsCount = this.violations.length;
    }

    ngOnInit(): void {
        if (this.folderService.getReportResource()) {
            this.isReport = true;
        }
    }

    public hasViolations(): boolean {
        return this.violationsCount > 0;
    }

    private assignPropertyFromPropertyPath(violations: Violation[]): Violation[] {
        return violations.map((violation) => {
            const index = violation.propertyPath.lastIndexOf('.') + 1;
            violation.property = index > 0 ? violation.propertyPath.substring(index) : '';
            return violation;
        });
    }

    /*
     * Adds translated path to variables
     */
    private buildViolationPath(violations: Violation[]): Violation[] {
        return violations.map(v => {
            const parts = v.propertyPath.split('.');

            const translatedStrings = parts?.map((w, idx) => this.returnTranslatedPart(w, idx, parts));
            const spans = translatedStrings.map(p => this.getViolationAsDivs(p));
            v.translatedParts = Array.prototype.concat.apply([], spans) as string[]; // flattens array of arrays

            return v;
        });
    }

    /*
     * returns translated part, with key if array is passed
     */
    private returnTranslatedPart(word: string, idx: number, parts: string[]): string {
        let translatable = word.split('[')[0];

        const indexRegex = new RegExp('[[0-9]*\]'); // get [x] from array[x]
        const onlyNumberRegex = new RegExp(/\[([^\][]*)]/g); // get x from [x]

        let key = indexRegex.exec(word)?.[0] ?? null;

        // custom for contacts / beneficiaries / organizations (as they are arrays)
        if (word.indexOf('Contacts') > -1) {
            translatable = 'contact';
            const cIdx = onlyNumberRegex.exec(key)?.[1]; // check current array idx
            if (!this.contactIdx) {
                this.contactIdx = cIdx;
            }

            if (this.contactIdx < cIdx) {
                translatable = 'beneficiary';
            }

            key = null;
        }

        if (word.indexOf('Organizations') > -1) { // todo use regex in all indexOf
            key = null;
            translatable = 'organization';
        }

        if (word.indexOf('Programs') > -1) {
            translatable = 'program';
        }

        if (word.indexOf('Projects') > -1) {
            translatable = 'project';
        }

        if (word.indexOf('Reports') > -1 || word.endsWith('reports')) {
            translatable = 'report';
        }

        translatable = `violations.${translatable}`; // adds base key for i18n

        if (0 === idx) { // let the first as it is so it will be a parent
            return this.translateService.instant(translatable) as string;
        }

        if (!key) { // others are managed in i18n files
            const i = idx + 1;
            // check if next property path can be added too (if not an array)
            while (parts[i] && null === indexRegex.exec(parts[i])) {
                translatable = `${translatable}.${parts[i]}`;
                parts.splice(i - 1, 1); // remove added part
            }

            return this.translateService.instant(translatable) as string;
        }

        const index = Number(onlyNumberRegex.exec(key)?.[1]) + 1;

        return `${this.translateService.instant(translatable) as string} ${index}`;
    }

    /*
    Already translated word as input
     */
    private getViolationAsDivs(word: string): string[] {
        const parts = word.split('>'); // used in translations

        if (parts.length < 2) { // will always be 1 (base word)
            return [`<div>${word}</div>`];
        }

        return parts.map(p => `<div>${p}</div>`);
    }
}
