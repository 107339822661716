import { Component, OnDestroy, OnInit } from '@angular/core';
import { COMPUTOR_TYPES, FolderService } from '../../core/services/folder.service';
import { FormBuilder, Validators } from '@angular/forms';
import { EnabledTransitionService } from '../../core/services/enabled-transition.service';
import { EnabledTransitionResource } from '../../core/models/enabled-transition-resource/enable-transition-resource.model';
import { IriUtil } from '../../core/utils/iri.util';
import { MatRadioChange } from '@angular/material/radio';
import { ReportService } from '../../core/services/report.service';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthorizationChecker } from '../../core/security/authorization-checker';
import { ChildReport } from '../../core/models/child-report.model';
import { ResourceUtil } from '../../core/utils/resourceUtil';
import { LoadingService } from '../../core/services/loading.service';

@Component({
    selector: 'app-change-report-state-dialog',
    templateUrl: './change-report-state-dialog.component.html',
    styleUrls: ['./change-report-state-dialog.component.scss']
})
export class ChangeReportStateDialogComponent implements OnInit, OnDestroy {
    public currentTransition: string = null;

    public form = this.fb.group({
        id: [null],
        transition: [null, Validators.required],
    });

    public enabledTransitions: EnabledTransitionResource[] = null;

    public impersonateRedirectUrl = '';

    private destroySubject: Subject<boolean> = new Subject<boolean>();

    private tenderSession: string; // TenderSession IRI
    private report: string; // Parent IRI
    private folder: string; // Parent IRI

    constructor(
        public folderService: FolderService,
        private fb: FormBuilder,
        private transitionsService: EnabledTransitionService,
        private reportService: ReportService,
        private router: Router,
        private dialogRef: MatDialogRef<ChangeReportStateDialogComponent>,
        private authorizationChecker: AuthorizationChecker,
        public loading: LoadingService
    ) {
        this.authorizationChecker.denyAccessUnlessGranted('ROLE_STAFF_PKW');
    }

    ngOnInit(): void {
        this.report = this.currentReport.report['@id'];
        this.folder = this.folderService.getCurrentResource()['@id'];
        this.tenderSession = IriUtil.extractId(this.folderService.getCurrentResource().data.tenderSession['@id']);

        this.impersonateRedirectUrl = '/sessions/' + this.tenderSession + '/folders/'
            + IriUtil.extractId(this.folder) + '/reports/' + IriUtil.extractId(this.report) + '/edit';

        if (ResourceUtil.isProgram(this.folderService.getCurrentResource())) {
            this.impersonateRedirectUrl = '/sessions/' + this.tenderSession + '/programs/'
                + IriUtil.extractId(this.folder) + '/reports/' + IriUtil.extractId(this.report) + '/edit';
        }

        this.transitionsService.findAllForReport(IriUtil.extractId(this.report)).pipe(
            takeUntil(this.destroySubject)
        ).subscribe(res => {
            this.enabledTransitions = res;
        });
    }

    public isTransitionEnabled(transition: string): boolean {
        const hasTransition = this.enabledTransitions.find((enabledTransition) => enabledTransition.name === transition);

        return !!hasTransition;
    }

    public changeState(): void {
        let type = 'projects';
        if (COMPUTOR_TYPES.program === this.folderService.getCurrentComputorType()) {
            type = 'programs';
        }

        if (this.currentTransition) {
            this.reportService.transition(IriUtil.extractId(this.report), this.currentTransition, {}).pipe(
                takeUntil(this.destroySubject)
            ).subscribe((report) => {
                this.closeModal();
                void this.router.navigate(['admin', type, IriUtil.extractId(this.folder),
                    'reports', IriUtil.extractId(this.report), 'validation2', report.currentReportId]);
            });
        }
    }

    onChange($event: MatRadioChange): void {
        this.currentTransition = $event.value as string;
    }

    canSwitchUser(): boolean {
        return 'opened' === this.currentReport.marking;
    }

    public ngOnDestroy(): void {
        this.destroySubject.next(true);
        this.destroySubject.complete();
    }

    public closeModal(): void {
        this.dialogRef.close();
    }

    public get currentMarking(): string {
        return this.folderService.getReportResource().data.marking;
    }

    public get currentReport(): ChildReport {
        return this.folderService.getReportResource().data.currentReport;
    }
}
