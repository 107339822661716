import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { NotificationService } from '../../core/notifications/notification.service';
import { SwitchUserService } from '../../core/services/switch-user.service';
import { FolderStatusService } from '../../core/services/folder-status.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TechnicalOrientation } from '../../core/models/technical-orientation.model';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map, take, takeUntil } from 'rxjs/operators';
import { MediaObject } from '../../core/models/media-object.model';
import AbstractComputor from '../abstract-computor';
import { FolderService } from '../../core/services/folder.service';
import { HtmlInputEvent } from '../../core/events/html-input-event';
import { HttpParamUtil } from '../../core/utils/http-param.util';
import { MediaObjectService } from '../../core/services/media-object.service';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { DownloaderHelper } from '../../core/utils/download.util';
import { TechnicalOrientationService } from '../../core/services/technical-orientation.service';
import { ProgramReportForm } from './models/programReportForm';
import { ProgramCalculationFixCost, ProgramCalculatorService } from './services/program-calculator.service';
import { IriUtil } from '../../core/utils/iri.util';
import { ProgramReportService } from '../../core/services/program-report.service';
import { ProjectFieldsCheckerService } from './services/project-fields-checker.service';
import { ReportService } from '../../core/services/report.service';
import { FIX_COST_TYPE_ACCOMPANIMENT, FIX_COST_TYPE_MANAGEMENT } from '../../core/models/fixCost.model';
import { ProgramReportResource } from '../../core/models/program-report-resource';

@Component({
    selector: 'app-report-template-form-component',
    template: ''
})
export abstract class Abstract2022ProgramReportFormTemplateComponent extends AbstractComputor implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('fileInput') public fileInput: ElementRef;

    /**
     * This class contains the base of a project_2022 report form.
     * Extended in Report edit & project-form component.
     */
    public form = this.computor2022form.getReportForm();

    public lengthValidators = this.computor2022form.lengthValidators;

    public computedResume = {...this.computor2022form.computedResume};

    public baseTechnicalOrientations: TechnicalOrientation[] = [];
    public technicalOrientations: TechnicalOrientation[] = [];

    public searchTechnicalOri = new FormControl();

    public isSubmittable = false;

    public defaultCurrency: string;

    public destroySubject: Subject<boolean> = new Subject<boolean>();

    private uploadedFileType = 'folder';

    protected constructor(
        protected computor2022form: ProgramReportForm,
        protected programCalculatorService: ProgramCalculatorService,
        protected notificationService: NotificationService,
        protected switchUserService: SwitchUserService,
        protected technicalOrientationService: TechnicalOrientationService,
        protected folderStatusService: FolderStatusService,
        protected fb: FormBuilder,
        protected route: ActivatedRoute,
        protected translateService: TranslateService,
        protected folderService: FolderService,
        protected mediaObjectService: MediaObjectService,
        protected reportResourceService: ProgramReportService,
        protected projectFieldsCheckerService: ProjectFieldsCheckerService,
        protected reportService: ReportService,
        protected router: Router
    ) {
        super(switchUserService);

        this.defaultCurrency = environment.defaultCurrency;

        route.data.subscribe(data => {
            if (!data) {
                return;
            }

            if (this.route.snapshot.data.folderProgramResource) {
                this.folderService.setProgramResource(this.route.snapshot.data.folderProgramResource);
                this.setFolderProgram(this.route.snapshot.data.folderProgramResource);
                this.form.patchValue(this.programReportResource.data);
                this.form.patchValue({currentProgram: this.folderProgram.data.currentProgram});
            }
        });
    }

    public ngOnInit(): void {
        this.programCalculatorService.setFormReference(this.form);

        this.technicalOrientationService.findAllByTenderModel(this.tenderSession.tenderModel).pipe(
            map((result) => result['hydra:member']),
            take(1)
        ).subscribe((technicalOrientations: TechnicalOrientation[]) => {
            this.baseTechnicalOrientations = technicalOrientations;
            this.technicalOrientations = technicalOrientations;

            this.programCalculatorService.setTechnicalOrientationsList(this.technicalOrientations);

            if (this.folderProgram) {
                const measuresCount = this.programReportResource.data.currentReport.reportMeasures.length;

                for (let i = 1; i < measuresCount; i++) {
                    this.addMeasure();
                    this.addProgramMeasure();
                }

                this.programReportResource.data.analysisMediaObjects.forEach((mediaObject: MediaObject) => {
                    this.addAnalysisMediaObjectGroup(mediaObject);
                });

                this.programReportResource.data.currentReport.mediaObjects?.forEach(mediaObject => {
                    (this.form.get('currentReport.mediaObjects') as FormArray).push(this.createMediaObjectGroup(mediaObject));
                });

                this.ngAfterViewInit();
            }
            this.form.patchValue({currentReport: this.programReportResource.data.currentReport});
            this.executeCalculation();
        });

        this.subscribeToTechnicalOriSearch();
    }

    public ngOnDestroy(): void {
        this.destroySubject.next(true);
        this.destroySubject.complete();
        this.folderService.clear();
    }

    public ngAfterViewInit(): void {
        this.executeCalculation();
    }

    public check(): void {
        if (this.form.valid) {
            const reportIO = {data: this.form.value as { currentReport }} as ProgramReportResource;
            this.reportResourceService.check(reportIO, this.computorType, this.computorName).subscribe(() => {
                this.isSubmittable = true;
                this.projectFieldsCheckerService.displayCheckResult([]);
            }, () => {
                this.isSubmittable = true;
            });
        }
    }

    public save(): void {
        if (this.form.valid) {
            const reportIO = {data: this.form.value as { currentReport }} as ProgramReportResource;
            this.reportResourceService.save(reportIO, this.computorType, this.computorName).subscribe(() => {
                this.notificationService.success('toast.data_successfully_saved');
            });
        }
    }

    public submit(): void {
        if (this.form.valid) {
            const reportIO = {data: this.form.value as { currentReport }} as ProgramReportResource;
            this.reportResourceService.save(reportIO, this.computorType, this.computorName, true).subscribe(() => {
                this.reportService.submitReport(IriUtil.extractId(this.programReportResource['@id'])).subscribe(() => {
                    this.notificationService.success('toast.data_successfully_saved');

                    // redirect to show current state (draft - can't edit no more)
                    this.redirectToShowReport(IriUtil.extractId(reportIO.data.currentReport['@id']));
                });
            });
        }
    }

    public get currentReportForm(): FormGroup {
        return this.form.get('currentReport') as FormGroup;
    }

    public get fixCostsForm(): FormArray {
        return this.currentReportForm.get('fixCosts') as FormArray;
    }

    public get fixCostsManagement(): FormGroup[] {
        const fixCosts = this.fixCostsForm.controls;
        return fixCosts.filter((f, idx) => this.fixCostsForm.at(idx).get('type').value === FIX_COST_TYPE_MANAGEMENT) as FormGroup[];
    }

    public get fixCostsAccompaniment(): FormGroup[] {
        const fixCosts = this.fixCostsForm.controls;
        return fixCosts.filter((f, idx) => this.fixCostsForm.at(idx).get('type').value === FIX_COST_TYPE_ACCOMPANIMENT) as FormGroup[];
    }

    public executeCalculation(): void {

        if (this.currentReportForm.get('analysisCosts.totalRequestedContribution').value) {
            this.currentReportForm.get('analysisCosts.requestedContribution').setValue(
                // tslint:disable-next-line:max-line-length
                this.currentReportForm.get('analysisCosts.totalRequestedContribution').value / this.currentReportForm.get('analysisCosts.units').value
            );
        }


        this.programCalculatorService.calculate().pipe(takeUntil(this.destroySubject)).subscribe(() => {
            this.computedResume = this.programCalculatorService.getResults();
        }, () => {
            this.notificationService.warn(this.programCalculatorService.getErrorMessage());
        });
    }

    public removeMeasureGroup(i: number): void {
        const measures = this.form.get('currentReport.reportMeasures') as FormArray;

        if (measures.length > 1) {
            measures.removeAt(i);
        } else {
            measures.reset();
        }

        this.executeCalculation();
    }

    public addMeasure(): void {
        const measures = this.form.get('currentReport.reportMeasures') as FormArray;
        measures.push(this.computor2022form.createMeasureGroup());
    }

    public addProgramMeasure(): void {
        const measures = this.form.get('currentProgram.measures') as FormArray;
        measures.push(this.computor2022form.createMeasureGroup());
    }

    public addMediaObjectGroup(media: MediaObject): void {
        const mediaObjects = this.form.get('currentProject.mediaObjects') as FormArray;
        mediaObjects.push(this.createMediaObjectGroup(media));
    }

    public createMediaObjectGroup(media: MediaObject): FormGroup {
        return this.fb.group({
            '@id': [media['@id'], []],
            originalName: [media.originalName, []],
        });
    }

    public addAnalysisMediaObjectGroup(media: MediaObject, newMediaObject = false): void {
        const analysisMediaObjects = this.form.get('analysisMediaObjects') as FormArray;
        analysisMediaObjects.push(this.createAnalysisMediaObjectGroup(media));
        if (newMediaObject) {
            this.save();
        }
    }

    public createAnalysisMediaObjectGroup(media: MediaObject): FormGroup {
        return this.fb.group({
            '@id': [media['@id'], []],
            originalName: [media.originalName, []],
        });
    }

    public addAnalysisMediaObject(): void {
        this.uploadedFileType = 'analysis';

        const nativeElement = this.fileInput.nativeElement as HTMLElement;
        nativeElement.click();
    }

    public onNewFileSelected(event: HtmlInputEvent): void {
        const httpParams = HttpParamUtil.create()
            .add('type', 'computor_2020_folder')
            .add('report', String(this.programReportResource.data.id))
        ;

        // stackoverflow.com/q/25333488/ (convert target.files to Array)
        for (const file of Array.prototype.slice.call(event.target.files)) {
            this.mediaObjectService.upload(file, httpParams.getHttpParams()).subscribe((media: MediaObject) => {
                if ('analysis' === this.uploadedFileType) {
                    this.addAnalysisMediaObjectGroup(media, true);
                }
            });
        }
    }

    public downloadMediaObject(mediaObject: MediaObject): void {
        const params = new HttpParams()
            .set('report', String(this.programReportResource.data.id))
        ;

        this.mediaObjectService.download(mediaObject['@id'], params).subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        });
    }

    public deleteAnalysisMediaObject(mediaObject: MediaObject, mediaObjectIndex: number = null): void {
        this.mediaObjectService.delete(mediaObject['@id']).subscribe(() => {
            const analysisMediaObjectsFormArray = this.form.get('analysisMediaObjects') as FormArray;
            analysisMediaObjectsFormArray.removeAt(mediaObjectIndex);
        });
    }

    public get programReportResource(): ProgramReportResource {
        return this.folderProgram.data.programReportResource;
    }

    private subscribeToTechnicalOriSearch(): void {
        this.searchTechnicalOri.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe((value: string) => {
            if (value === '' || !value) {
                this.technicalOrientations = this.baseTechnicalOrientations;
                return;
            }

            this.technicalOrientations = this.technicalOrientations.filter(obj => {
                const text = obj.slug;
                return text.toLowerCase().indexOf(value.toLowerCase()) > -1;
            });
        });
    }

    private redirectToShowReport(reportId: string): void {
        if (this.switchUserService.logoutIfSwitched()) {
            return;
        }

        void this.router.navigate([
            'sessions',
            IriUtil.extractId(this.tenderSession['@id']),
            'programs',
            IriUtil.extractId(this.folderProgram['@id']),
            'reports',
            this.programReportResource.data.id,
            'states',
            reportId
        ]);
    }
}
