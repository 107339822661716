import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../../core/guards/auth.guard';
import { ProjectComponent } from './project-form/project.component';
import {
    DashboardScrolledLayoutComponent
} from '../../../layouts/dashboard-scrolled-layout/dashboard-scrolled-layout.component';
import { FolderProjectResolver } from '../../../core/resolvers/folder-project.resolver';
import { FolderResolver } from '../../../core/resolvers/folder.resolver';
import { FolderStateResolver } from '../../../core/resolvers/folder-state.resolver';
import { DirtyFormGuard } from '../../../core/guards/dirty-form-guard.service';
import { ProjectStepComponent } from './project-step/project-step.component';
import { ProjectShowComponent } from './project-show/project-show.component';
import { ReportEditComponent } from '../report/report-edit/report-edit.component';
import { ReportShowComponent } from '../report/report-show/report-show.component';
import { ProgramReportEditComponent } from '../report/program-report-edit/program-report-edit.component';
import { FolderProgramResolver } from '../../../core/resolvers/folder-program.resolver';
import { ProgramComponent } from '../program/program-form/program.component';
import { ProgramShowComponent } from '../program/program-show/program-show.component';
import { ProgramShowStepComponent } from '../program/program-show-step/program-show-step.component';
import { ProgramReportShowStepComponent } from '../report/program-report-show-step/program-report-show-step.component';

const routes: Routes = [{
    path: '',
    component: DashboardScrolledLayoutComponent,
    children: [
        {
            path: 'projects/new',
            component: ProjectComponent,
            canActivate: [AuthGuard],
            canDeactivate: [DirtyFormGuard]
        }, {
            path: 'projects/:folderId',
            component: ProjectComponent,
            canActivate: [AuthGuard],
            canDeactivate: [DirtyFormGuard],
            resolve: {
                folderProject: FolderProjectResolver,
            }
        }, {
            path: 'folders/:folderId/reports/:reportId/states/:reportStateId',
            component: ReportShowComponent,
            canActivate: [AuthGuard],
            resolve: {
                folder: FolderProjectResolver,
            }
        }, {
            path: 'folders/:folderId/reports/:reportId/edit',
            component: ReportEditComponent,
            canActivate: [AuthGuard],
            resolve: {
                folder: FolderProjectResolver,
            }
        }, {
            path: 'folders/:folderId/states/:stateId',
            component: ProjectStepComponent,
            resolve: {
                folder: FolderStateResolver
            }
        }, {
            path: 'folders/:folderId/view',
            component: ProjectShowComponent,
            resolve: {
                folder: FolderResolver,
                folderProjectResource: FolderProjectResolver,
            }
        },
        // programs routes
        {
            path: 'programs/new',
            component: ProgramComponent,
            canActivate: [AuthGuard],
            canDeactivate: [DirtyFormGuard]
        },
        {
            path: 'programs/:folderId',
            component: ProgramComponent,
            canActivate: [AuthGuard],
            canDeactivate: [DirtyFormGuard],
            resolve: {
                folderProgramResource: FolderProgramResolver,
            }
        },
        {
            path: 'programs/:folderId/view',
            component: ProgramShowComponent,
            canActivate: [AuthGuard],
            canDeactivate: [DirtyFormGuard],
            resolve: {
                folder: FolderResolver,
                folderProgramResource: FolderProgramResolver,
            }
        },
        {
            path: 'programs/:folderId/states/:stateId',
            component: ProgramShowStepComponent,
            canActivate: [AuthGuard],
            canDeactivate: [DirtyFormGuard],
            resolve: {
                folderProgramResource: FolderProgramResolver,
            }
        },
        {
            path: 'programs/:folderId/reports/:reportId/edit',
            component: ProgramReportEditComponent,
            canActivate: [AuthGuard],
            resolve: {
                folderProgramResource: FolderProgramResolver,
            }
        },
        {
            path: 'programs/:folderId/reports/:reportId/states/:reportStateId',
            component: ProgramReportShowStepComponent,
            canActivate: [AuthGuard],
            resolve: {
                folderProgramResource: FolderProgramResolver,
            }
        }
    ]
}];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ProjectRoutingModule {
}
