import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root'
})

export class StaffPkwGuard implements CanActivate {
    constructor(
        private authService: AuthService
    ){}

    canActivate(): boolean {
        return this.authService.getUser().isStaffPkw ?? false;
    }

}
