<div class="tags-wrapper ml-3 px-3 py-1"
     cdkOverlayOrigin
     (click)="isOpen = this.openOverlay()"
     #trigger="cdkOverlayOrigin"
     #originRef
     [class.disabled]="!this.openOverlay()"
     appAdminComponent
     xAxisPosition="right"
     forceStyle="top: 4px"
    >
    <mat-icon class="pin-icon material-icons-outlined">push_pin</mat-icon>
    {{ currentTag | translate}}
    <mat-icon class="dd-icon" *appIsGranted="'ROLE_STAFF_PKW'">keyboard_arrow_down</mat-icon>
</div>

<ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayHasBackdrop]="true"
        (backdropClick)="isOpen = false"
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="isOpen"
    >
    <mat-card class="overlay-wrp mt-2" #containerRef *appIsGranted="'ROLE_STAFF_PKW'">
        <mat-action-list role="list" class="pt-0">
            <button mat-button (click)="setTagToEmpty()" *ngIf="folderService.getCurrentResource().data.tag">{{'word.no_tag' | translate}}</button>
            <button mat-button
                    *ngFor="let tag of availableTags"
                    (click)="selectTag(tag)">{{ tag.name }}</button>
        </mat-action-list>
        <div [formGroup]="form" class="row">
            <mat-form-field class="col-md-12">
                <input matInput formControlName="name" placeholder="{{ 'action.add_new_tag' | translate }}" #newTagInput>
            </mat-form-field>
            <button mat-flat-button appMatButtonLoading [loading]="addTagLoading" class="col-md-12" (click)="addTag()">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </mat-card>
</ng-template>

