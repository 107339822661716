import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Injectable()
export class PageTitleService {
    private pageTitleSubject = new ReplaySubject<string>(1);
    public pageTitle = this.pageTitleSubject.asObservable(); // eslint-disable-line

    constructor(
        private translate: TranslateService,
        private titleService: Title
    ) {
    }

    public title(title = ''): void {
        if ('' !== title) {
            this.translate.get(title).subscribe((translatedTitle) => this.titleService.setTitle(translatedTitle));
        } else {
            this.titleService.setTitle('');
        }
    }

    public getTitle(): string {
        return this.titleService.getTitle();
    }
}
