<app-card classes="p-5">
    <div class="text-center">
        <h1>{{ 'title.ask_reset' | translate }}</h1>
    </div>
    <form [formGroup]="form" class="w-100">
        <div class="row">
            <div class="col-md-12">
                <mat-form-field appearance="standard">
                    <mat-label>{{ 'word.password' | translate }}</mat-label>
                    <input matInput formControlName="password" autocomplete="false" [attr.type]="showPassword ? 'text' : 'password'"
                           (keyup.enter)="resetPassword()">
                    <button (click)="this.showPassword = !this.showPassword" mat-icon-button matSuffix type="button">
                        <mat-icon>
                            {{ this.showPassword ? 'visibility_off' : 'visibility' }}
                        </mat-icon>
                    </button>
                    <mat-error>
                        {{ 'validator.password.not_valid' | translate: {min: this.requiredLength.password} }}
                    </mat-error>
                    <mat-error *ngIf="form.get('password').hasError('required')">
                        {{ 'validator.password.not_blank' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
    <mat-card-actions>
        <button appMatButtonLoading [loading]="loading.loading$ | async" mat-flat-button color="accent"
                class="mat-button mr-1" (click)="resetPassword()">{{ 'action.reset_password' | translate }}</button>
    </mat-card-actions>
</app-card>
