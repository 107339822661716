import {Component, Input, Output, EventEmitter, HostBinding} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-program-fix-costs',
    templateUrl: './program-fix-costs.component.html',
    styleUrls: ['./program-fix-costs.component.scss']
})
export class ProgramFixCostsComponent {
    @HostBinding('class') class = 'w-full';

    @Input()
    form: FormGroup;

    @Output()
    executeCalculationEvent = new EventEmitter();

    executeCalculation(): void {
        this.executeCalculationEvent.emit(true);
    }

    get groupName(): string {
        return this.form.get('groupName').value as string;
    }
}
