import { BaseDatasource } from './base.datasource';
import { environment } from '../../../environments/environment';
import { Paginator } from '../models/paginator.model';
import { Sort } from '@angular/material/sort';
import { ReportService } from '../services/report.service';

export class AdminReportDatasource extends BaseDatasource {
    constructor(
        private reportService: ReportService,
    ) {
        super();
    }

    public loadReports(page = 1, itemsPerPage: number = environment.itemsPerPage, search = '', sort?: Sort): void {
        this.reportService.findAllPaginated(page, itemsPerPage, search, sort).subscribe((paginator: Paginator) => {
            this.totalItems = paginator['hydra:totalItems'];
            this.itemsPerPage = itemsPerPage;
            this.subject.next(paginator['hydra:member']);
        });
    }

}
