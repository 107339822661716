<div [formGroup]="form">
    <!-- Requirements control -->
    <app-card [cardNumber]="sectionNumber" cardTitle="title.requirements_control">
        <div formGroupName="requirementsControl">
            <div class="req-box" *ngFor="let item of [].constructor(numberOfBoxes); let i = index">
                <div class="req-title">
                    <mat-checkbox formControlName="requirement{{ (i + 1) }}">
                        {{ computorName + '.requirements.box' + (i + 1) + '.title' | translate }}
                    </mat-checkbox>
                </div>
                <div class="req-description">
                    {{ computorName + '.requirements.box' + (i + 1) + '.description' | translate }}
                </div>
            </div>
        </div>
    </app-card>
</div>
