import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';


@Injectable()
export class MediaObjectService {
    constructor(
        private apiService: ApiService
    ) {
    }

    /**
     * Upload a media object
     */
    public upload(file: File, params: HttpParams = null): Observable<any> {
        const formData = new FormData();
        formData.append('file', file, file.name);

        return this.apiService.post('/media_objects', formData, {params});
    }

    /**
     * Delete a media object
     */
    public delete(id: string): Observable<any> {
        return this.apiService.delete(id);
    }

    public download(id: string, params?: HttpParams): Observable<any> {
        return this.apiService.get(id + '/download', {
            observe: 'response',
            responseType: 'blob' as 'json',
            params
        });
    }
}
