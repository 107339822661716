import { Component, OnInit } from '@angular/core';
import { COMPUTOR_NAMES, COMPUTOR_TYPES, FolderService } from '../../../../../../core/services/folder.service';
import { NotificationService } from '../../../../../../core/notifications/notification.service';
import { TechnicalOrientationService } from '../../../../../../core/services/technical-orientation.service';
import { FormBuilder } from '@angular/forms';
import { FolderStatusService } from '../../../../../../core/services/folder-status.service';
import { TranslateService } from '@ngx-translate/core';
import { SwitchUserService } from '../../../../../../core/services/switch-user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MediaObjectService } from '../../../../../../core/services/media-object.service';
import { ProgramReportForm } from '../../../../models/programReportForm';
import { ProgramCalculatorService } from '../../../../services/program-calculator.service';
import { ProgramReportService } from '../../../../../../core/services/program-report.service';
import { ProjectFieldsCheckerService } from '../../../../services/project-fields-checker.service';
import { IriUtil } from '../../../../../../core/utils/iri.util';
import { ReportService } from '../../../../../../core/services/report.service';
import { LoadingService } from '../../../../../../core/services/loading.service';
import { PageTitleService } from '../../../../../../core/services/page-title.service';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import { MenuService } from '../../../../../../core/services/menu.service';
import { Abstract2023BigProgramReportFormTemplateComponent } from '../../../../program-report-form.template';

@Component({
    selector: 'app-program-report-edit',
    templateUrl: './program-report-edit.component.html',
    styleUrls: ['./program-report-edit.component.scss']
})
export class ProgramReportEditComponent extends Abstract2023BigProgramReportFormTemplateComponent implements OnInit {
    public static computorName = COMPUTOR_NAMES.computor2023Big;

    constructor(
        protected notificationService: NotificationService,
        protected technicalOrientationService: TechnicalOrientationService,
        protected fb: FormBuilder,
        protected folderStatusService: FolderStatusService,
        protected programCalculator: ProgramCalculatorService,
        protected computorForm: ProgramReportForm,
        protected translateService: TranslateService,
        protected switchUserService: SwitchUserService,
        protected route: ActivatedRoute,
        protected folderService: FolderService,
        protected mediaObjectService: MediaObjectService,
        protected reportResourceService: ProgramReportService,
        protected projectFieldsCheckerService: ProjectFieldsCheckerService,
        protected reportService: ReportService,
        protected router: Router,
        public loading: LoadingService,
        private title: PageTitleService,
        private breadCrumbService: BreadcrumbService,
        private translator: TranslateService,
        private menuService: MenuService
    ) {
        super(
            computorForm,
            programCalculator,
            notificationService,
            switchUserService,
            technicalOrientationService,
            folderStatusService,
            fb,
            route,
            translateService,
            folderService,
            mediaObjectService,
            reportResourceService,
            projectFieldsCheckerService,
            reportService,
            router
        );

        this.computorType = COMPUTOR_TYPES.program;
        this.computorName = COMPUTOR_NAMES.computor2023Big;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.initBreadcrumb();
        this.initMenu();
    }

    public reportNotCompleted(): boolean {
        return 'completed' !== this.programReportResource.data.marking;
    }

    setAnalysisRequestedContributionFromTotal(): void {
        const units = Number(this.currentReportForm.get('analysisCosts').get('units').value) ?? 0;
        const totalContribution = Number(this.currentReportForm.get('analysisCosts').get('totalRequestedContribution').value) ?? 0;
        this.currentReportForm.get('analysisCosts.requestedContribution').setValue(totalContribution / units);
        this.executeCalculation();
    }

    private initBreadcrumb(): void {
        this.breadCrumbService.reportView(this.folderLink);
    }

    private initMenu(): void {
        const menu = [{link: '/dashboard', label: 'menu.dashboard', icon: 'icon-home', subItems: []}, {
            link: this.folderLink, label: this.folderProgram.data.currentProgram.name, icon: 'icon-clipboard', subItems: [
                {
                    link: null, label: this.programReportResource.data.name, icon: 'icon-clipboard', scrollTo: '#section1', subItems: [
                        { link: null, label: 'word.fix_costs', icon: 'icon-1', scrollTo: '#section1'},
                        { link: null, label: 'title.measures', icon: 'icon-2', scrollTo: '#section2'},
                        { link: null, label: 'word.analysis_costs', icon: 'icon-3', scrollTo: '#section3'},
                    ]
                }
            ],
        }];
        this.menuService.store(menu);
    }

    get folderLink(): string {
        return '/sessions/' + IriUtil.extractId(this.tenderSession['@id']) + '/programs/' + String(this.folderProgram.data.id) + '/view';
    }

}
