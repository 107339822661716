import { Component, Input } from '@angular/core';
import { MediaObject } from '../../core/models/media-object.model';
import { DownloaderHelper } from '../../core/utils/download.util';
import { MediaObjectService } from '../../core/services/media-object.service';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { IriUtil } from '../../core/utils/iri.util';

@Component({
    selector: 'app-documents-list',
    templateUrl: './documents-list.component.html',
    styleUrls: ['./documents-list.component.scss']
})
export class DocumentsListComponent {
    // Actually a list of media objects but simplified (not all actions)

    @Input() folderUuid: string;

    @Input() reportIri: string;

    @Input()
    public sectionNumber: number;

    @Input()
    public mediaObjects: MediaObject[];

    constructor(
        private mediaObjectService: MediaObjectService
    ) {
    }

    public downloadMediaObject(mediaObject: MediaObject): void {
        let params = new HttpParams();
        if (this.folderUuid) {
            params = params.set('folder', this.folderUuid);
        }
        if (this.reportIri) {
            params = params.set('report', IriUtil.extractId(this.reportIri));
        }
        this.mediaObjectService.download(mediaObject['@id'], params).subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        });
    }
}
