import { Component, OnInit } from '@angular/core';
import AbstractComputor from '../../../../../abstract-computor';
import { SwitchUserService } from '../../../../../../core/services/switch-user.service';
import { ProjectReportResource } from '../../../../../../core/models/project-report-resource';
import { ActivatedRoute } from '@angular/router';
import { FolderProjectResource } from '../../../../../../core/models/folder-project-resource.model';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import { IriUtil } from '../../../../../../core/utils/iri.util';
import {
    COMPUTOR_NAMES, COMPUTOR_TYPES,
    FolderService,
} from '../../../../../../core/services/folder.service';
import { MenuService } from '../../../../../../core/services/menu.service';
import { PageTitleService } from '../../../../../../core/services/page-title.service';
import { TranslateService } from '@ngx-translate/core';
import { HttpResponse } from '@angular/common/http';
import { DownloaderHelper } from '../../../../../../core/utils/download.util';
import { ProjectReportService } from '../../../../../../core/services/project-report.service';
import { NotificationService } from '../../../../../../core/notifications/notification.service';
import { LoadingService } from '../../../../../../core/services/loading.service';
import { AuthService } from '../../../../../../core/services/auth.service';

@Component({
    selector: 'app-report-show',
    templateUrl: './report-show.component.html',
    styleUrls: ['./report-show.component.scss']
})
export class ReportShowComponent extends AbstractComputor implements OnInit {
    public static computorName = COMPUTOR_NAMES.computor2024Big;

    constructor(
        protected switchUserService: SwitchUserService,
        private route: ActivatedRoute,
        private breadCrumbService: BreadcrumbService,
        private folderService: FolderService,
        private menuService: MenuService,
        private title: PageTitleService,
        private translator: TranslateService,
        private projectReportService: ProjectReportService,
        private notificationService: NotificationService,
        public loading: LoadingService,
        private auth: AuthService
    ) {
        super(switchUserService);

        this.computorType = COMPUTOR_TYPES.project;
        this.computorName = COMPUTOR_NAMES.computor2024Big;

        this.folderProject = this.route.snapshot.data.folder as FolderProjectResource ?? null;
        this.tenderSession = this.folderProject.data.tenderSession;

        this.folderService.setProjectResource(this.folderProject);
        this.folderService.addCurrentReport(this.projectReportResource.data.currentReport);
    }

    ngOnInit(): void {
        this.initMenu();
        this.initBreadcrumb();
    }

    public reportNotCompleted(): boolean {
        return 'completed' !== this.projectReportResource.data.marking;
    }

    public exportPdf(): void {
        this.projectReportService.pdf(
            IriUtil.extractId(this.projectReportResource['@id']),
            this.projectReportResource.data.currentReport.marking,
            'pdf_project_report_exporter'
        ).subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        }, () => {
            this.notificationService.error('toast.an_error_occurred_while_generating_pdf');
        });
    }

    public get projectReportResource(): ProjectReportResource {
        return this.folderProject.data.projectReportResource;
    }

    private initBreadcrumb(): void {
        this.breadCrumbService.reportView(this.folderLink);
    }

    private initMenu(): void {
        if (this.auth.isAdmin()) {
            return;
        }

        const menu = [{link: '/dashboard', label: 'menu.dashboard', icon: 'icon-home', subItems: []}, {
            link: this.folderLink, label: this.folderProject.data.currentProject.name, icon: 'icon-clipboard', subItems: [
                {
                    link: null, label: this.projectReportResource.data.name, icon: 'icon-clipboard', scrollTo: '#section1', subItems: [
                        { link: null, label: 'title.measures', icon: 'icon-1', scrollTo: '#section1'},
                        { link: null, label: 'title.requested_contribution', icon: 'icon-2', scrollTo: '#section2'},
                        { link: null, label: 'title.documents', icon: 'icon-3', scrollTo: '#section3'},
                        { link: null, label: 'title.invoices', icon: 'icon-4', scrollTo: '#section4'},
                    ]
                }
            ],
        }];
        this.menuService.store(menu);
    }

    get folderLink(): string {
        if (!this.auth.isAdmin()) {
            return '/sessions/' + IriUtil.extractId(this.folderProject.data.tenderSession['@id'])
                + '/folders/' + String(this.folderProject.data.id) + '/view';
        }

        return '/admin/projects/' + this.folderProject.data.id.toString();
    }
}
