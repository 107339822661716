import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Folder } from '../models/folder.model';
import { FolderService } from '../services/folder.service';

@Injectable()
export class FolderResolver implements Resolve<Folder> {
    constructor(
        private folderService: FolderService,
    ) { }
    /* eslint-disable */
    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<Folder> {
        return this.folderService.find(route.paramMap.get('folderId'));
    }
    /* eslint-enable */
}
