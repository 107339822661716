import { Injectable } from '@angular/core';
import { ChildProject } from '../models/child-folder.model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MarkingService {
    public static FOLDER_INITIAL_PLACE = 'draft';

    public folderMarkingSubject = new BehaviorSubject(null);
    public folderMarking$ = this.folderMarkingSubject.asObservable();

    public reportMarkingSubject = new BehaviorSubject(null);
    public reportMarking$ = this.reportMarkingSubject.asObservable(); // TODO use this in change-report-state-dialog

    private folderMarkings = [
        'completed',
        'in_progress',
        'validation_2',
        'questions',
        'validation_1',
        'draft'
    ];

    public getMissingMarking(currentMarkings: ChildProject[]): string[] {
        currentMarkings.forEach((currentMarking) => {
            const index = this.folderMarkings.indexOf(currentMarking.marking);
            if (-1 !== index) {
                this.folderMarkings.splice(index);
            }
        });

        return this.folderMarkings.reverse();
    }

    public folderMarkingChanged(marking: string): void {
        this.folderMarkingSubject.next(marking);
    }

    public reportMarkingChanged(marking: string): void {
        this.reportMarkingSubject.next(marking);
    }

    public clear(): void {
        this.folderMarkingSubject.next(null);
        this.reportMarkingSubject.next(null);
    }
}
