<app-card cardTitle="title.summary">
    <div class="dataset">
        <div class="indicators-row d-flex justify-content-between align-items-center">
            <div class="indicator-box important-indicator d-flex align-items-center">
                <div class="icon">
                    <i class="icon-chart"></i>
                </div>
                <div class="indicator">
                    <p>{{ 'word.cost_utility_ratio' | translate }}</p>
                    <p>{{ computedValues.costUtilityRatio | positive | round:2 | default:'-' }} {{ 'word.cts_kwh' | translate }}</p>
                </div>
            </div>
            <div class="indicator-box d-flex align-items-center">
                <div class="icon">
                    <i class="icon-save-time"></i>
                </div>
                <div class="indicator">
                    <p>{{ 'word.total_saving' | translate }}</p>
                    <p>{{ computedValues.totalSaving | positive | number | default: '-': ('word.kwh' | translate) }}</p>
                </div>
            </div>
        </div>

        <div class="indicators-row d-flex justify-content-between align-items-center">
            <div class="indicator-box d-flex align-items-center" [matTooltip]="'program_2023Big.summary.tooltip.requested_contribution' | translate">
                <div class="icon shorticon">
                    <i class="icon-loss"></i>
                </div>
                <div class="indicator">
                    <p>{{ 'word.requested_contribution' | translate }}</p>
                    <p>{{ computedValues.requestedContribution | round | currency:'CHF':'symbol':'0.0-2' | default:'-' }}</p>
                </div>
            </div>
            <div class="indicator-box d-flex align-items-center">
              <div class="icon shorticon">
                <i class="icon-revenue"></i>
              </div>
              <div class="indicator">
                <p>{{ 'word.contribution_admin_total' | translate }}</p>
                <p>{{ computedValues.summary?.adminTotalContribution  | round | currency:'CHF':'symbol':'0.0-2' | default:'-' }}</p>
              </div>
            </div>
        </div>

        <div class="indicators-row d-flex justify-content-between align-items-center">

            <div class="indicator-box d-flex align-items-center">
              <div class="icon shorticon">
                <i class="icon-revenue"></i>
              </div>
              <div class="indicator">
                <p>{{ 'word.contribution_measures' | translate }}</p>
                <p>{{ computedValues.summary?.measuresContribution  | round | currency:'CHF':'symbol':'0.0-2' | default:'-' }}</p>
              </div>
            </div>
        </div>
    </div>
</app-card>
