import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { JwtService } from './jwt.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';
import { User } from '../models/user.model';
import { Router } from '@angular/router';


@Injectable()
export class AuthService {
    public authEvent = new EventEmitter<void>();
    public purgeAuthEvent = new EventEmitter<void>();

    private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
    public isAuthenticated = this.isAuthenticatedSubject.asObservable(); // eslint-disable-line

    private userSubject = new BehaviorSubject<User>
    ({
        id: -1,
        firstname: '',
        lastname: '',
        fullName: '',
        roles: [],
        lang: '',
        userName: '',
        isStaffPkw: false,
        isCornelia: false,
        isOfen: false
    }); // init first blank user

    public user = this.userSubject.asObservable(); // eslint-disable-line

    constructor(
        private apiService: ApiService,
        private route: Router
    ) {
    }

    /**
     * Login the user based on token stored in local storage
     */
    public autoLogin(): void {
        // If JWT detected, attempt to get & store user's info
        const token = JwtService.getToken();

        if (token) {
            this.setAuth({token});
        } else {
            // Remove any potential remnants of previous auth states
            this.purgeAuth();
        }
    }

    public login(credentials: {password: string; email: string}): Observable<any> {
        return this.apiService.post('/authenticate', credentials).pipe(map(
            data => {
                this.setAuth(data);

                return data; // eslint-disable-line
            }
        ));
    }

    /**
     * Logout the user by removing local storage token and setting
     * authenticatedSubject to false.
     */
    public logout(): void {
        this.purgeAuth();
    }

    public getUser(): User {
        return this.userSubject.getValue();
    }

    public isAdmin(): boolean {
        if (!this.getUser()) {
            return false;
        }

        return this.getUser().isStaffPkw || this.getUser().isOfen || this.getUser().isCornelia;
    }

    public updateUserInfos(user: User): void {
        const currentUser = this.getUser();
        currentUser.userName = user.email;
        currentUser.firstname = user.firstname;
        currentUser.lastname = user.lastname;
    }

    private purgeAuth(): void {
        // Remove JWT from localstorage
        JwtService.destroyToken();

        // Set auth status to false
        this.isAuthenticatedSubject.next(false);
        this.purgeAuthEvent.emit();
    }

    private setAuth(data: {token: string}): void {
        const helper = new JwtHelperService();
        const isExpired = helper.isTokenExpired(data.token);

        if (!isExpired) {
            let setIsStaffPkw = false;
            let setIsCornelia = false;
            let setIsOfen = false;
            JwtService.saveToken(data.token);
            this.isAuthenticatedSubject.next(true);

            const decodedToken: TokenData = helper.decodeToken(data.token);
            decodedToken.roles.forEach(role => {
                if (role === environment.staffPkwRole) {
                    setIsStaffPkw = true;
                }

                if (role === environment.corneliaRole) {
                    setIsCornelia = true;
                }

                if (role === environment.ofenRole) {
                    setIsOfen = true;
                }
            });
            this.userSubject.next({
                id: decodedToken.user.id,
                firstname: decodedToken.user.firstName,
                lastname: decodedToken.user.lastName,
                fullName: decodedToken.user.fullName,
                userName: decodedToken.user.userName,
                lang: decodedToken.lang,
                roles: decodedToken.roles,
                isStaffPkw: setIsStaffPkw,
                isCornelia: setIsCornelia,
                isOfen: setIsOfen,
            });
        } else {
            this.purgeAuth();
        }
    }
}

export interface TokenData {
    user: {
        id: number;
        firstName: string;
        lastName: string;
        fullName: string;
        userName: string;
    };
    lang: string;
    roles: string[];
}
