import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FolderProjectResource } from '../../../../../../core/models/folder-project-resource.model';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import { MenuService } from '../../../../../../core/services/menu.service';
import { FolderStatusService } from '../../../../../../core/services/folder-status.service';
import { IriUtil } from '../../../../../../core/utils/iri.util';
import { ProjectCalculatorService } from '../../../../services/project-calculator.service';
import AbstractComputor from '../../../../../abstract-computor';
import { MediaObject } from '../../../../../../core/models/media-object.model';
import { environment } from '../../../../../../../environments/environment';
import { FolderProjectService } from '../../../../../../core/services/folder-project.service';
import { NotificationService } from '../../../../../../core/notifications/notification.service';
import { DownloaderHelper } from '../../../../../../core/utils/download.util';
import { TenderSession } from '../../../../../../core/models/tender-session.model';
import { SwitchUserService } from '../../../../../../core/services/switch-user.service';
import { ProjectReportResource } from '../../../../../../core/models/project-report-resource';
import { HttpResponse } from '@angular/common/http';
import { LoadingService } from '../../../../../../core/services/loading.service';
import {
    COMPUTOR_NAMES,
    COMPUTOR_TYPES, FolderService,
} from '../../../../../../core/services/folder.service';

@Component({
    selector: 'app-folder-show-step',
    templateUrl: './project-show-step.component.html',
    styleUrls: ['./project-show-step.component.scss', '../project-form/project.component.scss']
})
export class ProjectShowStepComponent extends AbstractComputor implements OnInit {
    public static computorName = COMPUTOR_NAMES.computor2024Lights;

    public folder: FolderProjectResource & ProjectReportResource;
    public tenderSession: TenderSession;
    public mediaObjects: MediaObject[];

    public defaultCurrency: string;

    constructor(
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private menuService: MenuService,
        private folderStatusService: FolderStatusService,
        public projectCalculator: ProjectCalculatorService,
        private notificationService: NotificationService,
        private folderProjectService: FolderProjectService,
        protected switchUserService: SwitchUserService,
        private folderService: FolderService,
        public loading: LoadingService
    ) {
        super(switchUserService);

        this.computorType = COMPUTOR_TYPES.project;
        this.computorName = COMPUTOR_NAMES.computor2024Lights;

        this.defaultCurrency = environment.defaultCurrency;
    }

    ngOnInit(): void {
        this.tenderSession = this.route.parent.snapshot.data.tenderSession as TenderSession;
        this.folder = this.route.snapshot.data.folder as FolderProjectResource & ProjectReportResource;
        this.folderService.setProjectResource(this.folder);

        // update header status
        this.folderStatusService.folderStatusChanged.emit(this.folder.data.marking);

        const folderName = this.folder.data.reference + ' : ' +  this.folder.data.currentProject.acronym;
        this.breadcrumbService.clientFolderStep(folderName);

        const menu = [{link: '/dashboard', label: 'menu.dashboard', icon: 'icon-home', subItems: []}, {
            link: '/sessions/' + IriUtil.extractId(this.tenderSession['@id']) + '/folders/' + IriUtil.extractId(this.folder['@id']) + '/view',
            label: folderName,
            icon: 'icon-clipboard',
            subItems: [
                { link: null, label: 'title.project_holder', icon: 'icon-1', scrollTo: '#section1'},
                { link: null, label: 'title.project_beneficiary', icon: 'icon-2', scrollTo: '#section2'},
                { link: null, label: 'title.general', icon: 'icon-3', scrollTo: '#section3'},
                { link: null, label: 'title.measures', icon: 'icon-4', scrollTo: '#section4'},
                { link: null, label: 'title.documents', icon: 'icon-5', scrollTo: '#section5'},
            ]
        }];

        this.menuService.store(menu);
        this.mediaObjects = this.folder.data.currentProject.mediaObjects.length > 0 ? this.folder.data.currentProject.mediaObjects : null;
    }

    public exportPdf(): void {
        this.folderProjectService.pdf(
            IriUtil.extractId(this.folder['@id']),
            this.folder.data.currentProject.marking,
            'pdf_project_exporter'
        ).subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        }, () => {
            this.notificationService.error('toast.an_error_occurred_while_generating_pdf');
        });
    }
}
