import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TenderSessionService } from '../services/tender-session.service';
import { TenderSession } from '../models/tender-session.model';

@Injectable()
export class TenderSessionResolver implements Resolve<TenderSession> {
    constructor(
        private tenderSessionService: TenderSessionService,
    ) { }
    /* eslint-disable */
    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<TenderSession> {
        return this.tenderSessionService.find(route.paramMap.get('sessionId'));
    }
    /* eslint-enable */
}
