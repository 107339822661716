<div class="row" [formGroup]="form" style="min-width: 40vw;">
    <div class="col-md-12">
        <h1 class="mat-dialog-title">{{ 'action.edit_invoice' | translate }}</h1>
    </div>
    <div class="col-md-12">
        <mat-form-field>
            <mat-label>{{ 'word.invoice_name' | translate }}</mat-label>
            <input matInput formControlName="name" required>
        </mat-form-field>
    </div>


    <div class="col-md-12" *ngIf="type === 'project' || isAdmin()">
        <mat-form-field>
            <mat-label>{{ 'word.invoice_amount' | translate }}</mat-label>
            <input matInput formControlName="amount" type="number">
        </mat-form-field>
    </div>


    <div class="col-md-6" *ngIf="type === 'program' || isAdmin()">
        <mat-form-field>
            <mat-label>{{ 'word.invoice_fix_amount' | translate }}</mat-label>
            <input matInput formControlName="fixAmount" type="number">
        </mat-form-field>
    </div>
    <div class="col-md-6" *ngIf="type === 'program' || isAdmin()">
        <mat-form-field>
            <mat-label>{{ 'word.invoice_variable_amount' | translate }}</mat-label>
            <input matInput formControlName="variableAmount" type="number">
        </mat-form-field>
    </div>


    <div class="col-md-3">
        <mat-form-field>
            <mat-label>{{ 'word.receive_date' | translate }}</mat-label>
            <input matInput [matDatepicker]="picker2" formControlName="receiveDate"/>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-md-3">
        <mat-form-field>
            <mat-label>{{ 'word.approval_date' | translate }}</mat-label>
            <input matInput [matDatepicker]="picker1" formControlName="approvalDate"/>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-md-3">
        <mat-form-field>
            <mat-label>{{ 'word.ofen_approval_date' | translate }}</mat-label>
            <input matInput [matDatepicker]="picker3" formControlName="ofenApprovalDate"/>
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-md-3">
        <mat-form-field>
            <mat-label>{{ 'word.payment_date' | translate }}</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="paymentDate"/>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-md-12 d-flex justify-content-end mt-2">
        <button mat-flat-button (click)="dialogRef.close(null)"
                class="cancel-button mr-2">{{ 'action.close' | translate }}
        </button>
        <button mat-flat-button (click)="save()" color="primary" appMatButtonLoading
                [loading]="loading.loading$ | async">{{ 'action.save' | translate }}
        </button>
    </div>
</div>

