import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { LessThanValidator } from '../../../core/validators/less-than.validator';
import { ProjectCalculationResults } from '../services/project-calculator.service';

@Injectable()
export class ProjectForm {
    public static noSpecialCharsRegex = '^[^~`!@#$%^&*()+={}\\[\\]|\\\\:;"\'<>,.\\/?]+$';
    public computedResume: ProjectCalculationResults =  {
        costUtilityRatio: 0,
        economyAnnual: 0,
        economyPercent: 0,
        economyTotal: 0,
        maximumContribution: 0,
        measures: [],
        paybackWithoutContribution: 0,
        paybackWithContribution: 150,
        requestedContribution: 0,
        subsidyRate: 0,
        totalCost: 0,
        costCtskwh: 0
    };

    public lengthValidators = {
        genString: 250,
        longString : 4096,
        postCode : 7,
        acronym : 50,
        minCost : 0.05,
        minRequestedContribution : 2000000,
        maxRequestedContribution: 6000000,
        positiveValue : 0,
        minCtsKwhCost : 1,
        maxCtsKwhCost : 100
    };

    constructor(
        private fb: FormBuilder
    ) {}

    public getProjectForm(): FormGroup {
        return this.fb.group({
            id: [null],
            uuid: [uuidv4()], // eslint-disable-line
            currentState: [null, []],
            tenderSession: [null, [Validators.required]],
            tenderModel: [null, [Validators.required]],
            mediaObjects: this.fb.array([]),
            gsAnalysisManager: [null, []],
            gsMonitoringManager: [null, []],
            ofenAnalysisManager: [null, []],
            ofenMonitoringManager: [null, []],
            owner: [null, []],
            folderHolder: this.fb.group({
                '@id': [null, []],
                address: [null, [Validators.maxLength(this.lengthValidators.genString)]],
                postalCode: [null, [Validators.maxLength(this.lengthValidators.postCode)]],
                city: [null, [Validators.maxLength(this.lengthValidators.genString)]],
                companyName: [null, Validators.maxLength(this.lengthValidators.genString)],
                firstName: [null, Validators.maxLength(this.lengthValidators.genString)],
                lastName: [null, Validators.maxLength(this.lengthValidators.genString)],
                email: [null, [
                    Validators.maxLength(this.lengthValidators.genString),
                    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
                ]],
                phone: [null, Validators.maxLength(this.lengthValidators.genString)],
                mobilePhone: [null, Validators.maxLength(this.lengthValidators.genString)],
            }),
            folderBeneficiary: this.fb.group({
                '@id': [null, []],
                address: [null, [Validators.maxLength(this.lengthValidators.genString)]],
                postalCode: [null, [Validators.maxLength(this.lengthValidators.postCode)]],
                city: [null, [Validators.maxLength(this.lengthValidators.genString)]],
                companyName: [null, Validators.maxLength(this.lengthValidators.genString)],
                firstName: [null, Validators.maxLength(this.lengthValidators.genString)],
                lastName: [null, Validators.maxLength(this.lengthValidators.genString)],
                email: [null, [
                    Validators.maxLength(this.lengthValidators.genString),
                    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')
                ]],
                phone: [null, Validators.maxLength(this.lengthValidators.genString)],
                mobilePhone: [null, Validators.maxLength(this.lengthValidators.genString)],
            }),
            currentProject: this.fb.group({
                '@id': [null, []],
                name: ['', [Validators.required, Validators.maxLength(this.lengthValidators.genString),
                    Validators.pattern(ProjectForm.noSpecialCharsRegex)]],
                acronym: ['', [Validators.required, Validators.maxLength(this.lengthValidators.acronym),
                    Validators.pattern(ProjectForm.noSpecialCharsRegex)]],
                startDate: [null],
                endDate: [null],
                description: [null, Validators.maxLength(this.lengthValidators.longString)],
                plannedMonitoring: [null, Validators.maxLength(this.lengthValidators.longString)],
                additionality: [null, Validators.maxLength(this.lengthValidators.longString)],
                markingName: [null],
                mediaObjects: this.fb.array([]),
                state: [null],
                dataset: this.getDatasetFormGroup(),
                measures: this.fb.array([
                    this.createMeasureGroup()
                ]),
            }),
            requirementsControl: this.fb.group({
                requirement1: [false, []],
                requirement2: [false, []],
                requirement3: [false, []],
                requirement4: [false, []],
                requirement5: [false, []],
            }),
            // following properties are used in validation2
            analysisMediaObjects: this.fb.array([]),
            analysis: this.fb.group({
                decision: [null, []],
                comment: [null, []],
            }),
        });
    }

    public createMeasureGroup(): FormGroup {
        return this.fb.group({
            '@id': [null, []],
            folder: [null, []],
            datasetMeasure: this.fb.group({
                '@id': [null, []],
                cost: [null, [Validators.min(0)]],
                existingAnnualConsumption: [null, [Validators.min(this.lengthValidators.positiveValue)]],
                futureAnnualConsumption: [null, [Validators.min(this.lengthValidators.positiveValue)]],
                description: [null, [Validators.maxLength(this.lengthValidators.longString)]],
                durationOfUse: [null, [Validators.min(this.lengthValidators.positiveValue), Validators.pattern('[0-9]+')]],
                maximumContribution: [null],
                technicalOrientation: [null, []],
                percentSavings: [null, []],
                totalSavings: [null]
            }, {
                validators: [
                    LessThanValidator('futureAnnualConsumption', 'existingAnnualConsumption'),
                    // GreaterThanValidator('futureAnnualConsumption', 'existingAnnualConsumption')
                ]
            })
        });
    }

    public getDatasetFormGroup(): FormGroup {
        return this.fb.group({
            '@id': [null, []],
            subsidies: null,
            cost: [null,
                [Validators.min(this.lengthValidators.minCtsKwhCost), Validators.max(this.lengthValidators.maxCtsKwhCost)]],
            requestedContribution: [null,
                [
                    Validators.min(this.lengthValidators.minRequestedContribution),
                    Validators.max(this.lengthValidators.maxRequestedContribution)
                ]
            ],
            maximumContribution: [null]
        }, {
            validators: [
                LessThanValidator('requestedContribution', 'maximumContribution', true),
            ]
        });
    }
}
