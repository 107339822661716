import { Component, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { RemarkService } from '../../core/services/remark.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { FormUtil } from '../../core/utils/form.util';
import { Remark } from '../../core/models/remark.model';
import { LoadingService } from '../../core/services/loading.service';

@Component({
    selector: 'app-add-remark-dialog',
    templateUrl: './add-remark-dialog.component.html',
    styleUrls: ['./add-remark-dialog.component.scss']
})
export class AddRemarkDialogComponent {
    @ViewChild('input') public input: ViewContainerRef;

    public form = this.fb.group({
        '@id': null,
        text: [null, [Validators.required]]
    });

    private folderId: number = null;

    constructor(
        public dialogRef: MatDialogRef<AddRemarkDialogComponent>,
        public loading: LoadingService,
        private remarkService: RemarkService,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: { folderId: number; remark?: Remark }
    ) {
        this.folderId = this.data.folderId;

        if (data.remark) {
            this.form.patchValue(data.remark);
        }
    }

    addRemark(): void {
        if (this.form.valid) {
            if (this.data.remark) {
                this.edit();
                return;
            }
            this.remarkService.createNewRemark(this.form.get('text').value, this.folderId).subscribe((remark: Remark) => {
                this.dialogRef.close({
                    saved: true,
                    remark
                });
            });
        } else {
            FormUtil.validateAllFormFields(this.form);
        }
    }

    edit(): void {
        const remark = this.form.value as Remark;
        this.remarkService.patchRemark(remark).subscribe((updatedRemark: Remark) => {
            this.dialogRef.close({
                saved: true,
                remark: updatedRemark
            });
        });
    }
}
