import { Component } from '@angular/core';
import { SwitchUserService } from '../../core/services/switch-user.service';

@Component({
    selector: 'app-switch-user-component',
    templateUrl: './switch-user-component.component.html',
    styleUrls: ['./switch-user-component.component.scss']
})
export class SwitchUserComponentComponent {
    public user = '';
    public isImpersonating = false;

    constructor(
        private switchUserService: SwitchUserService
    ) {
        this.user = this.switchUserService.getSwitchedUser();
        this.isImpersonating = this.switchUserService.isImpersonating();
    }
}
