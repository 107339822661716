import { Component, OnInit } from '@angular/core';
import { FilterM } from '../../core/models/filter.model';
import { Paginator } from '../../core/models/paginator.model';
import { NewFilterDialogComponent } from '../../shared/new-filter-dialog/new-filter-dialog.component';
import { FilterService } from '../../core/services/filter.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-filter-list',
    templateUrl: './filter-list.component.html',
    styleUrls: ['./filter-list.component.scss']
})
export class FilterListComponent implements OnInit {

    availableFilters: FilterM[] = [];

    constructor(
        private filterService: FilterService,
        private dialog: MatDialog
    ) { }

    ngOnInit(): void {
        this.getFilters();
    }

    getFilters(): void {
        this.filterService.getFilterList().subscribe((result: Paginator) => {
            this.availableFilters = result['hydra:member'] as FilterM[];
        });
    }

    deleteFilter(filterIri: string): void {
        this.filterService.deleteOneFilter(filterIri).subscribe(() => {
            this.getFilters();
        });
    }

    editFilter(filter: FilterM): void {
        const dialog = this.dialog.open(NewFilterDialogComponent, {
            data: {
                filter
            }
        });
        dialog.afterClosed().subscribe((data: {filter: FilterM}) => {
            if (data && data.filter) {
                this.filterService.putOneFilter(data.filter).subscribe(() => this.getFilters());
            } else {
                this.getFilters();
            }
        });
    }
}
