import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import {
    AdminProgramReportValidation2Component,
    ProgramReportShowStepComponent,
    ProjectComponent
} from './../computor2025';
import { ComputorFactory } from '../computor.factory';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SharedModule } from '../../shared/shared.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ProjectCalculatorService } from './services/project-calculator.service';
import { ProjectFieldsCheckerService } from './services/project-fields-checker.service';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { PipesModule } from '../../core/pipes/pipes.module';
import { ProjectShowComponent } from './../computor2025';
import { DashboardModule } from '../../views/client/dashboard/dashboard.module';
import { MatTableModule } from '@angular/material/table';
import { NgMathPipesModule } from 'ngx-pipes';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProjectShowStepComponent } from './../computor2025';
import { ComponentsModule } from '../../components/components.module';
import { FolderDetailModule } from '../../views/client/dashboard/folder-detail/folder-detail.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AdminProjectValidation2Component } from './../computor2025';
import { OverlayModule } from '@angular/cdk/overlay';
import { AdminProjectShowStepComponent } from './../computor2025';
import { AdminProjectShowComponent } from './../computor2025';
import { TodoModule } from '../../views/admin/todo/todo.module';
import { ReportShowComponent } from './../computor2025';
import { ReportEditComponent } from './../computor2025';
import { AdminReportValidation2Component } from './../computor2025';
import { ComponentsModule as ComputorComponentsModule } from './../computor2025/components/components.module';
import { ProgramComponent } from './../computor2025';
import { ProgramShowComponent } from './../computor2025';
import { ProgramShowStepComponent } from './../computor2025';
import { AdminProgramShowComponent } from './../computor2025';
import { AdminProgramShowStepComponent } from './../computor2025';
import { AdminProgramValidation2Component } from './../computor2025';
import { MatExpansionModule } from '@angular/material/expansion';
import { TechnicalOrientationService } from '../../core/services/technical-orientation.service';
import { ProjectForm } from './models/projectForm';
import { ProgramForm } from './models/programForm';
import { ProgramReportEditComponent } from './../computor2025';
import { ProgramReportForm } from './models/programReportForm';
import { ReportForm } from './models/reportForm';
import {
    ProgramReportFixCostsComponent
} from './components/report/program-report-fix-costs/program-report-fix-costs.component';
import {MatGridListModule} from "@angular/material/grid-list";

@NgModule({
    declarations: [
        ProjectComponent,
        ProjectShowComponent,
        ProjectShowStepComponent,
        AdminProjectValidation2Component,
        AdminProjectShowComponent,
        AdminProjectShowStepComponent,
        AdminReportValidation2Component,
        ReportShowComponent,
        ReportEditComponent,
        AdminProgramShowComponent,
        ProgramComponent,
        ProgramShowComponent,
        ProgramShowStepComponent,
        AdminProgramShowStepComponent,
        AdminProgramValidation2Component,
        ProgramReportEditComponent,
        ProgramReportFixCostsComponent,
        ProgramReportShowStepComponent,
        AdminProgramReportValidation2Component
    ],
    imports: [
        BrowserModule,
        FormsModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatTabsModule,
        MatBottomSheetModule,
        ReactiveFormsModule,
        TranslateModule,
        MatCardModule,
        MatDatepickerModule,
        SharedModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatListModule,
        MatCheckboxModule,
        PipesModule,
        DashboardModule,
        MatTableModule,
        NgMathPipesModule,
        MatTooltipModule,
        ComponentsModule,
        FolderDetailModule,
        NgxMatSelectSearchModule,
        OverlayModule,
        TodoModule,
        ComputorComponentsModule,
        MatExpansionModule,
        MatGridListModule,
    ],
    providers: [
        ComputorFactory,
        TechnicalOrientationService,
        ProjectCalculatorService,
        ProjectFieldsCheckerService,
        ProjectForm,
        ProgramForm,
        ProgramReportForm,
        ReportForm
    ],
    bootstrap: [ProjectComponent]
})
export class Computor2025Module {
}
