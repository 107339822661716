import { MenuItem } from '../models/menu-item.model';

export class AdminMenuService {
    static getAdminMenu(roles: string[]): MenuItem[] {
        const menus = [
            {label: 'menu.dashboard', link: '/admin/dashboard', icon: 'icon-home', subItems: [], roles: [
                'ROLE_STAFF_PKW',
                'ROLE_CORNELIA',
                'ROLE_OFEN',
            ]},
            {label: 'menu.projects', link: '/admin/projects', icon: 'icon-clipboard', subItems: [], roles: ['ROLE_STAFF_PKW', 'ROLE_CORNELIA', 'ROLE_OFEN']},
            {label: 'menu.programs', link: '/admin/programs', icon: 'icon-clipboard', subItems: [], roles: ['ROLE_STAFF_PKW', 'ROLE_CORNELIA']},
            {label: 'menu.reports', link: '/admin/reports', icon: 'icon-clipboard', subItems: [], roles: [
                'ROLE_STAFF_PKW',
                'ROLE_CORNELIA',
                'ROLE_OFEN'
            ]},
            {label: 'word.remarks', link: '/admin/remarks', icon: 'icon-alert', subItems: [], roles: [
                'ROLE_STAFF_PKW',
                'ROLE_CORNELIA',
                'ROLE_OFEN'
            ]},
            {label: 'menu.todos', link: '/admin/todo', icon: 'icon-checklist', subItems: [], roles: ['ROLE_STAFF_PKW']},
            {label: 'menu.invoices', link: '/admin/invoices', icon: 'icon-cash', subItems: [], roles: ['ROLE_STAFF_PKW', 'ROLE_CORNELIA', 'ROLE_OFEN']},
            {label: 'menu.tender_session', link: '/admin/tenderSessions', icon: 'icon-calendar-check', subItems: [], roles: [
                'ROLE_STAFF_PKW',
                'ROLE_CORNELIA',
                'ROLE_OFEN'
            ]},
            // ONLY ONE BUTTON POSSIBLE (absolute position)
            {label: 'menu.settings', link: '/admin/settings', icon: 'icon-filter', subItems: [], isButton: true, roles: [
                'ROLE_STAFF_PKW',
                'ROLE_CORNELIA',
                'ROLE_OFEN'
            ]}
        ];

        // return only menus where user has role
        return menus.filter((menu: MenuItem) => menu.roles.some(role => roles.includes(role)));
    }
}
