// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    defaultLang: 'fr',
    apiUrl: 'https://api.webtool.prokw.ch',
    // apiUrl: 'http://localhost',

    localeStorageKey: 'pkw-locale',
    itemsPerPage: 50,
    jwtTokenKey: 'pkw',
    debugMode: true,
    cacheTTL: (60 * 60 * 10), // cache TTL 10 hours
    defaultCurrency: 'CHF',
    searchDebounceTime: 500,
    staffPkwRole: 'ROLE_STAFF_PKW',
    corneliaRole: 'ROLE_CORNELIA',
    ofenRole: 'ROLE_OFEN',
    ofenAnalysisRole: 'ROLE_OFEN',
    ofenMonitoringRole: 'ROLE_OFEN',
    gsAnalysisRole: 'ROLE_STAFF_PKW',
    gsMonitoringRole: 'ROLE_STAFF_PKW',
    userRole: 'ROLE_USER'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
