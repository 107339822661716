<div class="row">
    <div class="col-md-6">
        <app-admin-dashboard-projects></app-admin-dashboard-projects>
    </div>
    <div class="col-md-6">
        <app-admin-dashboard-reports></app-admin-dashboard-reports>
    </div>
    <div class="col-md-6">
        <app-admin-dashboard-programs></app-admin-dashboard-programs>
    </div>
</div>


<app-todo bottom="25px" *appIsGranted="'ROLE_STAFF_PKW'"></app-todo>
