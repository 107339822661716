import { Component } from '@angular/core';
import { AdminProgramDatasource } from '../../../../core/datasources/admin-program.datasource';
import { FolderService } from '../../../../core/services/folder.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../../../core/services/loading.service';

@Component({
    selector: 'app-admin-dashboard-programs',
    templateUrl: './programs.component.html',
    styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent {
    datasource: AdminProgramDatasource;
    public displayedColumns = ['no', 'title', 'state', 'actions'];

    constructor(
        private folderService: FolderService,
        private router: Router,
        public loading: LoadingService
    ) {
        this.datasource = new AdminProgramDatasource(this.folderService);
        this.datasource.loadPrograms(1, 5);
    }

    gotoPrograms(): void {
        void this.router.navigateByUrl('/admin/programs');
    }

}
