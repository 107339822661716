import { NgModule } from '@angular/core';
import { ProgramComponent } from './../computor2022Lights';
import { ReactiveFormsModule } from '@angular/forms';
import { ComponentsModule } from '../../components/components.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ComponentsModule as PrgComponents } from './components/components.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../../shared/shared.module';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../../core/pipes/pipes.module';
import { NgMathPipesModule } from 'ngx-pipes';
import { ComputorFactory } from '../computor.factory';
import { ProgramForm } from './models/programForm';
import { ProjectFieldsCheckerService } from './services/project-fields-checker.service';
import {
    ProgramReportSummaryCardComponent
} from './components/program/report-summary-card/report-summary-card.component';
import {ProgramShowStepComponent} from './program/client/program/program-show-step/program-show-step.component';
import {
    ProgramReportShowStepComponent
} from './program/client/report/program-report-show-step/program-report-show-step.component';
import { ProgramReportEditComponent } from './../computor2022Lights';
import {Computor2024Module} from '../computor2024/computor2024.module';
import {
    AdminProgramValidation2Component
} from './program/admin/program/admin-program-validation2/admin-program-validation2.component';
import {MatListModule} from '@angular/material/list';
import {AdminProgramReportValidation2Component} from './index';
@NgModule({
    declarations: [
        ProgramComponent,
        ProgramReportEditComponent,
        ProgramReportSummaryCardComponent,
        ProgramShowStepComponent,
        ProgramReportShowStepComponent,
        AdminProgramValidation2Component,
        AdminProgramReportValidation2Component
    ],
    imports: [
        ReactiveFormsModule,
        ComponentsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatDatepickerModule,
        MatButtonModule,
        MatIconModule,
        NgxMatSelectSearchModule,
        MatTooltipModule,
        SharedModule,
        CommonModule,
        PipesModule,
        NgMathPipesModule,
        PrgComponents,
        Computor2024Module,
        MatListModule,
    ],
    providers: [
        ComputorFactory,
        ProgramForm,
        ProjectFieldsCheckerService
    ],
    bootstrap: [ProgramComponent]
})
export class Computor2022LightsModule {
}
