import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizationChecker } from '../authorization-checker';

/**
 * <div *appIsGranted="ROLE_COACH()"></div>
 * <div *appIsGranted="[ROLE_COACH()]"></div>
 * <div *appIsGranted="'edit'; subject mySubject"></div>
 * <div *appIsGranted="['edit', 'delete']; subject mySubject"></div>
 */
@Directive({
    selector: '[appIsGranted]'
})
export class IsGrantedDirective implements OnInit {
    private attributes: any = [];

    private subject: any = null;

    @Input()
    set appIsGranted(attr: string|string[]) {
        this.attributes = attr;
    }

    @Input()
    set appIsGrantedSubject(sub: Record<any, unknown>) {
        this.subject = sub;
    }

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private authorizationChecker: AuthorizationChecker,
    ) { }

    public ngOnInit(): void {
        if (this.authorizationChecker.checkIsGranted(this.attributes, this.subject)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
