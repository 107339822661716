import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SwitchUserService } from '../../../../../../core/services/switch-user.service';
import { FolderProgramService } from '../../../../../../core/services/folder-program.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FolderStatusService } from '../../../../../../core/services/folder-status.service';
import { FormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../../../../core/notifications/notification.service';
import { ProjectFieldsCheckerService } from '../../../../services/project-fields-checker.service';
import { ProgramCalculatorService } from '../../../../services/program-calculator.service';
import { COMPUTOR_NAMES, COMPUTOR_TYPES, FolderService } from '../../../../../../core/services/folder.service';
import { LoadingService } from '../../../../../../core/services/loading.service';
import { FolderProgramResource } from '../../../../../../core/models/folder-program-resource.model';
import { PageTitleService } from '../../../../../../core/services/page-title.service';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import { takeUntil } from 'rxjs/operators';
import { Measure } from '../../../../../../core/models/measure.model';
import { TechnicalOrientationService } from '../../../../../../core/services/technical-orientation.service';
import { MatDialog } from '@angular/material/dialog';
import { MediaObjectService } from '../../../../../../core/services/media-object.service';
import { AuthorizationChecker } from '../../../../../../core/security/authorization-checker';
import { Abstract2024BigProgramReportFormTemplateComponent } from '../../../../program-report-form.template';
import { ProgramReportForm } from '../../../../models/programReportForm';
import { ProgramReportService } from '../../../../../../core/services/program-report.service';
import { ReportService } from '../../../../../../core/services/report.service';
import { Step } from '../../../../../../core/models/step.model';
import { ChildProject } from '../../../../../../core/models/child-folder.model';
import { ReportColumn } from '../../../../../../core/models/report-column.model';
import { IriUtil } from '../../../../../../core/utils/iri.util';
import { HttpResponse } from '@angular/common/http';
import { DownloaderHelper } from '../../../../../../core/utils/download.util';
import { ModalService } from '../../../../../../core/services/modal.service';

@Component({
    selector: 'app-admin-program-validation2',
    templateUrl: './admin-program-report-validation2.component.html',
    styleUrls: ['./admin-program-report-validation2.component.scss']
})
export class AdminProgramReportValidation2Component extends Abstract2024BigProgramReportFormTemplateComponent implements OnInit {
    public static computorName = COMPUTOR_NAMES.computor2024Big;
    @ViewChild('fileInput') public fileInput: ElementRef;
    public measures: Measure[];
    public reportSteps: Step[];

    constructor(
        protected programForm: ProgramReportForm,
        protected programCalculatorService: ProgramCalculatorService,
        protected notificationService: NotificationService,
        protected switchUserService: SwitchUserService,
        protected technicalOrientationService: TechnicalOrientationService,
        protected folderStatusService: FolderStatusService,
        protected fb: FormBuilder,
        protected route: ActivatedRoute,
        protected translateService: TranslateService,
        protected folderService: FolderService,
        protected mediaObjectService: MediaObjectService,
        protected reportResourceService: ProgramReportService,
        protected projectFieldsCheckerService: ProjectFieldsCheckerService,
        protected reportService: ReportService,
        protected router: Router,
        private folderProgramService: FolderProgramService,
        public loading: LoadingService,
        private title: PageTitleService,
        private translator: TranslateService,
        private breadcrumbService: BreadcrumbService,
        protected dialog: MatDialog,
        private authorizationChecker: AuthorizationChecker,
        private programReportService: ProgramReportService,
        private modalService: ModalService
    ) {
        super(
            programForm,
            programCalculatorService,
            notificationService,
            switchUserService,
            technicalOrientationService,
            folderStatusService,
            fb,
            route,
            translateService,
            folderService,
            mediaObjectService,
            reportResourceService,
            projectFieldsCheckerService,
            reportService,
            router,
        );

        this.folderProgram = this.route.snapshot.data.folderProgramResource as FolderProgramResource;
        this.tenderSession = this.folderProgram.data.tenderSession;

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        this.reportSteps = [...this.folderProgram.reportSteps];

        this.measures = this.programReportResource.data.currentReport.reportMeasures;

        folderService.setProgramResource(this.folderProgram);

        this.computorType = COMPUTOR_TYPES.program;
        this.computorName = COMPUTOR_NAMES.computor2024Big;

        route.data.pipe(takeUntil(this.destroySubject)).subscribe(() => this.addValidatedFolderToReportSteps());
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.initBreadcrumb();
        this.setMinAndMaxDatesFromDecisionDate();
    }

    public addValidatedFolderToReportSteps(): void {
        const validatedFolder = this.folderProgram.folderSteps.before.find((step: Step) => 'validation_2' === step.marking) as ChildProject;
        if (!validatedFolder) { return; }
        validatedFolder['@columnName'] = 'validated_from_folder';
        this.folderProgram.reportSteps = [
            {
                '@permissions': {view: true},
                '@type': 'Folder',
                name: (this.folderProgram.reportSteps[0] as ReportColumn).name,
                steps: [validatedFolder]
            },
            ...this.folderProgram.reportSteps as Step[]
        ];
    }

    public isValidation2(): boolean {
        return this.programReportResource.data.marking === 'validation';
    }

    get folderLink(): string {
        return '/admin/programs/' + this.folderProgram.data.id.toString();
    }

    public isRoleCorneliaOrAbove(): boolean {
        return this.authorizationChecker.checkIsGranted('ROLE_CORNELIA');
    }

    public canEdit(): boolean {
        return this.programReportResource['@permissions'].edit;
    }

    public canEditAnalyse(): boolean {
        return (this.programReportResource['@permissions'].extra as {editAnalyse: boolean}).editAnalyse;
    }

    public reportNotCompleted(): boolean {
        return 'completed' !== this.programReportResource.data.currentReport.marking;
    }

    public exportPdf(): void {
        this.programReportService.pdf(
            IriUtil.extractId(this.programReportResource['@id']),
            this.programReportResource.data.currentReport.marking,
            'pdf_program_report_exporter'
        ).subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        }, () => {
            this.notificationService.error('toast.an_error_occurred_while_generating_pdf');
        });
    }

    public openReportSettingsDialog(): void {
        this.modalService.openReportSettingsDialog(this.folderLink, this.programReportResource);
    }

    private initBreadcrumb(): void {
        this.breadcrumbService.reportView(this.folderLink);
    }
}
