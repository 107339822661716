import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProjectsComponent } from './projects/projects.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';
import { ProgramsComponent } from './programs/programs.component';
import { MatTableModule } from '@angular/material/table';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { ComponentsModule } from '../../../components/components.module';
import { TodoModule } from '../todo/todo.module';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from '../../../shared/shared.module';
import { ReportsComponent } from './reports/reports.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [
        DashboardComponent,
        ProjectsComponent,
        ProgramsComponent,
        ReportsComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatButtonModule,
        TranslateModule,
        MatTableModule,
        PipesModule,
        ComponentsModule,
        TodoModule,
        RouterModule,
        MatTooltipModule,
        SharedModule,
        MatProgressSpinnerModule
    ]
})
export class AdminDashboardModule { }
