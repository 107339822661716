<h2 mat-dialog-title>{{'menu.settings' | translate}}</h2>
<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-md-12">
            <mat-checkbox [formControl]="isFinalReportCtrl" class="my-3" [value]="true">{{'word.set_as_final_report' | translate}}</mat-checkbox>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="Box color-border-danger" *appIsGranted="'ROLE_STAFF_PKW'">
                <ul>
                    <li>
                        <div class="d-flex justify-content-between">
                            <div>
                                <strong>{{ 'dialog.report_settings.delete_this_report' | translate }}</strong>
                                <p class="mb-0">
                                    {{ 'dialog.report_settings.delete_this_report_message' | translate }}
                                </p>
                            </div>
                            <div class="pl-4 align-self-center">
                                <button mat-stroked-button color="warn" appConfirmDialog (confirmClick)="deleteReport()">
                                    {{ 'dialog.report_settings.delete_this_report' | translate }}
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close class="cancel-button mr-2">{{ 'action.close' | translate }}</button>
    <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="save()" [loading]="loading.loading$ | async">
        {{ 'action.save' | translate }} <i class="icon-floppy-disk"></i>
    </button>
</mat-dialog-actions>
