import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { map } from 'rxjs/operators';
import { EnabledTransitionResource } from '../models/enabled-transition-resource/enable-transition-resource.model';
import { HttpParams } from '@angular/common/http';
import { Paginator } from '../models/paginator.model';


@Injectable()
export class EnabledTransitionService {
    constructor(
        private apiService: ApiService
    ) {
    }

    /**
     * Find enabled transitions for a folder
     */
    public findAllByFolderId(id: string): Observable<EnabledTransitionResource[]> {
        const params = new HttpParams().set('folder.id', id);

        return this.apiService.get('/enabled_transitions', {params}).pipe(
            map((result: Paginator) => result['hydra:member'] as EnabledTransitionResource[])
        );
    }

    public findAllForReport(reportId: string): Observable<EnabledTransitionResource[]> {
        const params = new HttpParams().set('report.id', reportId);

        return this.apiService.get('/enabled_report_transitions', {params}).pipe(
            map((result: Paginator) => result['hydra:member'] as EnabledTransitionResource[])
        );
    }
}
