export class ArrayUtil {
    /**
     * Method to automatically search & filter an array of objects
     * Use as : filterArray('myName', this.USERS, ['firstname', 'lastname'])
     * Value will be converted to text -> if search for true -> search for 'true'
     *
     * @param needle string that you are searching for
     * @param array array that contains objects that contain the values you are searching for
     * @param keys object keys that we want to search in
     */
    public static filterObjectArray(needle: string, array: any[], keys: any[]): any[] {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return array.filter((value: any[]) => {
            let found = false;
            keys.forEach((key: string) => {
                if (String(value[key]).toLowerCase().indexOf(needle.toLowerCase()) > -1) {
                    found = true;
                }
            });
            return found;
        });
    }
}
