<app-card>
    <mat-card-header *ngIf="!showingFolder">
        <mat-card-title>
            <a (click)="showFolder(folder)">{{ currentChild.name }}</a>
        </mat-card-title>
        <div class="header-action">
            <button mat-button (click)="showFolder(folder)">
                {{ 'action.project_follow_up' | translate }}
                <mat-icon>chevron_right</mat-icon>
            </button>
        </div>
        <mat-card-subtitle>{{ folder.reference ? folder.reference + ' / ' : '' }}{{ currentChild.acronym }}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-header *ngIf="showingFolder">
        <div mat-card-avatar class="rounded-number">1</div>
        <mat-card-title>{{ 'title.milestones' | translate }}</mat-card-title>
    </mat-card-header>

    <div class="folder-detail-wrapper scrollX">
        <div class="d-flex justify-content-between align-items-start">
            <ng-container *ngFor="let item of folder.timelineItems">
                <div class="step p-2 type-{{ item.type }}" *ngIf="item['@permissions'].view">
                    <div class="icon passed" (click)="viewOrEdit(item)" [class.icon-link]="item.type !== 'milestone'">
                        <i *ngIf="item.type !== 'milestone'"
                           class="{{ item['@permissions'].edit ? 'icon-edit' : 'icon-eye' }}"></i>
                        <i *ngIf="item.type === 'milestone'" class="icon-push-pin"></i>
                    </div>
                    <p class="description mb-1"
                       *ngIf="item.type !== 'report' && item.type !== 'milestone'">{{ 'workflow.' + item.name | translate }}</p>
                    <p class="description mb-1"
                       *ngIf="item.type === 'report' || item.type === 'milestone'">{{ item.name }}</p>
                    <p class="date" *ngIf="item.date">{{ item.date | localizedDate }}</p>
                </div>
            </ng-container>

            <div class="step p-2" *ngIf="folder.nextMarking">
                <div class="icon view"></div>
                <p class="description mb-1">{{ 'workflow.' + folder.nextMarking | translate }}</p>
            </div>

            <div class="bar"></div>
        </div>
    </div>
</app-card>
