<!-- Milestones -->
<app-admin-folder-milestone id="section1" [tenderSession]="folderProgramResource.data.tenderSession"
                            [folder]="folderProgramResource" [showingFolder]="true"></app-admin-folder-milestone>

<!-- Project holder -->

<app-card cardNumber="2" cardTitle="word.supporting_organisation">
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.company_name' | translate }}</p>
            <p>{{ folderProgramResource.data.supportingOrganization.companyName | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.legal_form' | translate }}</p>
            <p>{{ folderProgramResource.data.supportingOrganization.legalForm ? ('legal_forms.' + folderProgramResource.data.supportingOrganization.legalForm | translate) : '-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.email' | translate }}</p>
            <p>{{ folderProgramResource.data.supportingOrganization.email | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.address' | translate }}</p>
            <p>{{ folderProgramResource.data.supportingOrganization.address | default:'-' }}</p>
        </div>

    </div>
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.city' | translate }}</p>
            <p>{{ folderProgramResource.data.supportingOrganization.city | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.postal_code' | translate }}</p>
            <p>{{ folderProgramResource.data.supportingOrganization.postalCode | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.mobile_phone' | translate }}</p>
            <p>{{ folderProgramResource.data.supportingOrganization.mobilePhone | default:'-' }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.phone' | translate }}</p>
            <p>{{ folderProgramResource.data.supportingOrganization.phone | default:'-' }}</p>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.website' | translate }}</p>
            <p>{{ folderProgramResource.data.supportingOrganization.website | default:'-' }}</p>
        </div>
    </div>
</app-card>

<!-- Project holder -->
<app-card cardNumber="3" cardTitle="title.project_holder">
    <div class="contact">
        <form [formGroup]="folderHolderForm" (keydown.enter)="$event.preventDefault()">
            <div class="row">
                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.company_name' | translate }}"
                               formControlName="companyName"
                               type="text" autocomplete="off"
                               maxlength="{{ lengthValidators.genString }}">
                        <mat-error
                            *ngIf="folderHolderForm.get('companyName').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.last_name' | translate }}"
                               formControlName="lastName"
                               type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                        <mat-error *ngIf="folderHolderForm.get('lastName').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.first_name' | translate }}"
                               formControlName="firstName"
                               type="text" autocomplete="off"
                               maxlength="{{ lengthValidators.genString }}">
                        <mat-error *ngIf="folderHolderForm.get('firstName').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.function' | translate }}"
                               formControlName="function"
                               type="text" autocomplete="off"
                               maxlength="{{ lengthValidators.genString }}">
                        <mat-error *ngIf="folderHolderForm.get('function').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.email' | translate }}" formControlName="email"
                               type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                        <mat-error *ngIf="folderHolderForm.get('email').hasError('pattern')">
                            {{ 'validator.email.not_valid' | translate }}
                        </mat-error>
                        <mat-error *ngIf="folderHolderForm.get('email').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.mobile_phone' | translate }}"
                               formControlName="mobilePhone" type="text" autocomplete="off"
                               maxlength="{{ lengthValidators.genString }}">
                        <mat-error *ngIf="folderHolderForm.get('mobilePhone').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3">
                    <mat-form-field>
                        <input matInput placeholder="{{ 'word.phone' | translate }}" formControlName="phone"
                               type="text" autocomplete="off" maxlength="{{ lengthValidators.genString }}">
                        <mat-error *ngIf="folderHolderForm.get('phone').hasError('maxlength')">
                            {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
</app-card>

<!-- Measure evolution -->
<app-card cardNumber="4" cardTitle="title.measures_evolution">
    <!-- Measures -->
    <mat-accordion class="my-3 d-block">
        <ng-container *ngFor="let measure of measures; let index = index">
            <mat-expansion-panel togglePosition="after">
                <mat-expansion-panel-header>
                    <h3 class="m-0">{{ 'word.measure_n' | translate:{count: index + 1} }}</h3>
                </mat-expansion-panel-header>
                <div class="table-scroll">
                    <table class="table table-responsive col-md-12" appTableCellHighlighter>
                        <thead>
                        <tr>
                            <th class="w-25">{{ '' }}</th>
                            <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                                <th class="min-w-120" *ngIf="step['@permissions'].view" rowspan="2">
                                    {{ 'workflow.' + step.columnName | translate }}
                                </th>
                            </ng-container>
                            <ng-container
                                *ngFor="let step of folderProgramResource.reportSteps; let first = first; let last = last; let index = index">
                                <th class="min-w-120 report-header report-first report-last"
                                    *ngIf="step['@permissions'].view"
                                    [attr.colspan]="countColspan(step.steps)">
                                    {{ step['@columnName'] | translate }}
                                </th>
                            </ng-container>
                        </tr>
                        <tr>
                            <th class="w-25">{{ '' }}</th>
                            <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                                <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                    <th class="min-w-120 report-header no-border-bottom"
                                        *ngIf="step['@permissions'].view"
                                        [class.report-first]="first" [class.report-last]="last">
                                        {{ 'workflow.' + step['@columnName'] | translate }}
                                    </th>
                                </ng-container>
                            </ng-container>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <th>{{ 'word.technical_orientation' | translate }}</th>
                            <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                                <td *ngIf="step['@permissions'].view"
                                    [innerHTML]="step.measures[index].technicalOrientation ? step.measures[index].technicalOrientation.name : '-'"></td>
                            </ng-container>
                            <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                                <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                    <td *ngIf="step['@permissions'].view && step.measures[index]"
                                        [class.report-first]="first" [class.report-last]="last">
                                        {{ step.measures[index].technicalOrientation?.name | default:'-' }}
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.description' | translate }}</th>
                            <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                                <td *ngIf="step['@permissions'].view"
                                    [innerHTML]="step.measures[index].description | nl2br | default:'-'"></td>
                            </ng-container>
                            <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                                <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                    <td *ngIf="step['@permissions'].view"
                                        [innerHTML]="step.measures[index].description | nl2br | default:'-'"></td>
                                </ng-container>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.duration_of_use' | translate }}</th>
                            <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                                <td *ngIf="step['@permissions'].view">
                                    {{ 'word.n_years' | translate:{count: step.measures[index].durationOfUse | default:'-'} }}
                                </td>
                            </ng-container>
                            <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                                <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                    <td *ngIf="step['@permissions'].view">
                                        {{ 'word.n_years' | translate:{count: step.measures[index].durationOfUse | default:'-'} }}
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.unit' | translate }}</th>
                            <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                                <td *ngIf="step['@permissions'].view">
                                    {{ step.measures[index].units }}
                                </td>
                            </ng-container>
                            <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                                <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                    <!--                                    <td *ngIf="step['@permissions'].view">-->
                                    <!--                                        {{ step.measures[index].units }}-->
                                    <!--                                    </td>-->
                                </ng-container>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.unit_price' | translate }}</th>
                            <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                                <td *ngIf="step['@permissions'].view">
                                    {{ step.measures[index].cost | currency:'CHF':'symbol':'0.0-2' }}
                                </td>
                            </ng-container>
                            <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                                <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                    <!--                                    <td *ngIf="step['@permissions'].view">-->
                                    <!--                                        {{ step.measures[index].cost | currency:'CHF':'symbol':'0.0-2'}}-->
                                    <!--                                    </td>-->
                                </ng-container>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.requested_contribution' | translate }}
                                / {{ 'word.unit' | translate  | lowercase }}
                            </th>
                            <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                                <td *ngIf="step['@permissions'].view">
                                    {{ step.measures[index].requestedContribution | currency:'CHF':'symbol':'0.0-2' }}
                                </td>
                            </ng-container>
                            <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                                <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                    <!--                                    <td *ngIf="step['@permissions'].view">-->
                                    <!--                                        {{ step.measures[index].requestedContribution | currency:'CHF':'symbol':'0.0-2'}}-->
                                    <!--                                    </td>-->
                                </ng-container>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.existing_annual_consumption' | translate }}
                                / {{ 'word.unit' | translate  | lowercase }}
                            </th>
                            <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                                <td *ngIf="step['@permissions'].view">
                                    {{ step.measures[index].existingAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                                </td>
                            </ng-container>
                            <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                                <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                    <!--                                    <td *ngIf="step['@permissions'].view">-->
                                    <!--                                        {{ step.measures[index].existingAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}-->
                                    <!--                                    </td>-->
                                </ng-container>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.future_annual_consumption' | translate }}
                                / {{ 'word.unit' | translate  | lowercase }}
                            </th>
                            <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                                <td *ngIf="step['@permissions'].view">
                                    {{ step.measures[index].futureAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                                </td>
                            </ng-container>
                            <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                                <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                    <!--                                    <td *ngIf="step['@permissions'].view">-->
                                    <!--                                        {{ step.measures[index].futureAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}-->
                                    <!--                                    </td>-->
                                </ng-container>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.annual_saving' | translate }}
                                / {{ 'word.unit' | translate  | lowercase }}
                            </th>
                            <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                                <td *ngIf="step['@permissions'].view">
                                    {{ step.measures[index].annualSavings | number | default: '-': ('word.kwh' | translate) }}
                                </td>
                            </ng-container>
                            <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                                <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                    <!--                                    <td *ngIf="step['@permissions'].view">-->
                                    <!--                                        {{ step.measures[index].annualSavings | number | default: '-': ('word.kwh' | translate) }}-->
                                    <!--                                    </td>-->
                                </ng-container>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.total_saving' | translate }}
                                / {{ 'word.unit' | translate  | lowercase }}
                            </th>
                            <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                                <td *ngIf="step['@permissions'].view">
                                    {{ step.measures[index].totalSavings | number | default: '-': ('word.kwh' | translate) }}
                                </td>
                            </ng-container>
                            <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                                <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                    <!--                                    <td *ngIf="step['@permissions'].view">-->
                                    <!--                                        {{ step.measures[index].totalSavings | number | default: '-': ('word.kwh' | translate) }}-->
                                    <!--                                    </td>-->
                                </ng-container>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.measure_annual_savings' | translate }}</th>
                            <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                                <td *ngIf="step['@permissions'].view">
                                    {{ step.measures[index].units * step.measures[index].annualSavings | number | default: '-': ('word.kwh' | translate) }}
                                </td>
                            </ng-container>
                            <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                                <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                    <!--                                    <td *ngIf="step['@permissions'].view">-->
                                    <!--                                        {{ step.measures[index].units * step.measures[index].annualSavings | number | default: '-': ('word.kwh' | translate) }}-->
                                    <!--                                    </td>-->
                                </ng-container>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.measure_total_savings' | translate }}</th>
                            <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                                <td *ngIf="step['@permissions'].view">
                                    {{ step.measures[index].units * step.measures[index].totalSavings | number | default: '-': ('word.kwh' | translate) }}
                                </td>
                            </ng-container>
                            <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                                <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                    <td *ngIf="step['@permissions'].view">

                                        {{ step.measures[index].realisedTotalEconomy | number | default: '-': ('word.kwh' | translate) }}
                                        ({{ (step.measures[index].realisedTotalEconomy / (getFolderStep().measures[index].units * getFolderStep().measures[index].totalSavings)) | percent }}
                                        )

                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>
                        <tr *ngIf="folderProgramResource.reportSteps.length">
                            <th>{{ 'word.engaged_total_economy' | translate }}</th>
                            <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                                <td *ngIf="step['@permissions'].view">
                                </td>
                            </ng-container>
                            <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                                <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                    <td *ngIf="step['@permissions'].view">
                                        {{ step.measures[index].engagedTotalEconomy | number | default: '-': ('word.kwh' | translate) }}
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.total_contribution' | translate }}</th>
                            <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                                <td *ngIf="step['@permissions'].view">
                                    {{ step.measures[index].units * step.measures[index].requestedContribution | currency:'CHF':'symbol':'0.0-2' }}
                                </td>
                            </ng-container>
                            <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                                <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                    <td *ngIf="step['@permissions'].view">
                                        {{ step.measures[index].realisedRequestedContribution | currency:'CHF':'symbol':'0.0-2' }}

                                        ({{ step.measures[index].realisedRequestedContribution / (getFolderStep().measures[index].requestedContribution * getFolderStep().measures[index].units) | percent }}
                                        )

                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>
                        <tr *ngIf="folderProgramResource.reportSteps.length">
                            <th>{{ 'word.engaged_requested_contribution' | translate }}</th>
                            <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                                <td *ngIf="step['@permissions'].view">
                                </td>
                            </ng-container>
                            <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                                <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                    <td *ngIf="step['@permissions'].view">
                                        {{ step.measures[index].engagedRequestedContribution | currency:'CHF':'symbol':'0.0-2' }}
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>
                        <tr>
                            <th>{{ 'word.total_cost' | translate }}</th>
                            <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                                <td *ngIf="step['@permissions'].view">
                                    {{ step.measures[index].units * step.measures[index].cost | currency:'CHF':'symbol':'0.0-2' }}
                                </td>
                            </ng-container>
                            <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                                <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                                    <!--                                    <td *ngIf="step['@permissions'].view">-->
                                    <!--                                        {{ step.measures[index].units * step.measures[index].cost | currency:'CHF':'symbol':'0.0-2'}}-->
                                    <!--                                    </td>-->
                                </ng-container>
                            </ng-container>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </mat-expansion-panel>
        </ng-container>
    </mat-accordion>

    <!-- Fix Costs -->
    <div class="table-scroll general-measure-wrapper">
        <h3 class="mb-1">{{ 'word.fix_costs' | translate }}</h3>

        <table class="table table-responsive col-md-12" appTableCellHighlighter>
            <thead class="col-md-12">
            <tr>
                <th class="w-25">{{ '' }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <th class="min-w-120" *ngIf="step['@permissions'].view" rowspan="2">
                        {{ 'workflow.' + step.columnName | translate }}
                    </th>
                </ng-container>
                <ng-container
                    *ngFor="let step of folderProgramResource.reportSteps; let first = first; let last = last; let index = index">
                    <th class="min-w-120 report-header report-first report-last" *ngIf="step['@permissions'].view"
                        [attr.colspan]="countColspan(step.steps)">
                        {{ step['@columnName'] | translate }}
                    </th>
                </ng-container>
            </tr>
            <tr>
                <th class="w-25">{{ '' }}</th>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                    <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                        <th class="min-w-120 report-header no-border-bottom" *ngIf="step['@permissions'].view"
                            [class.report-first]="first" [class.report-last]="last">
                            {{ 'workflow.' + step['@columnName'] | translate }}
                        </th>
                    </ng-container>
                </ng-container>
            </tr>
            </thead>
            <tbody>
            <ng-container *ngFor="let fixCost of fixCostsManagement; let i = index">
                <tr>
                    <th>{{ 'fix_costs.' + fixCost.groupName | translate }}</th>
                    <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                        <td *ngIf="step['@permissions'].view">
                            {{ step.fixCosts[i].cost.requestedContribution | currency:'CHF':'symbol':'0.0-2' }}
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                        <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                            <td *ngIf="step['@permissions'].view">
                                {{ step.fixCosts[i].cost.requestedContribution | currency:'CHF':'symbol':'0.0-2' }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
            </ng-container>
            <tr>
                <th>{{ 'word.subsidy_rate' | translate }} {{ 'word.management' | translate | lowercase }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before; let i = index">
                    <td *ngIf="step['@permissions'].view">
                        {{ calculateFixCostsValues(i).subsidyRateManagement | round:2 }}%
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">
                        <td *ngIf="step['@permissions'].view">
                            <!--                                                    {{calculateFixCostsValues(i, j).subsidyRateManagement | round:2 }}%-->
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
            <ng-container *ngFor="let fixCost of fixCostsAccompaniment; let i = index">
                <tr>
                    <th>{{ 'fix_costs.' + fixCost.groupName | translate }}</th>
                    <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                        <td *ngIf="step['@permissions'].view">
                            {{ fixCostByGroupName(step, fixCost.groupName) | currency:'CHF':'symbol':'0.0-2' }}
                        </td>
                    </ng-container>
                    <ng-container *ngFor="let column of folderProgramResource.reportSteps;">
                        <ng-container *ngFor="let step of column.steps; let j = index;">
                            <td *ngIf="step['@permissions'].view">
                                {{ fixCostByGroupName(step, fixCost.groupName)| currency:'CHF':'symbol':'0.0-2' }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
            </ng-container>
            <tr>
                <th>{{ 'word.subsidy_rate' | translate }} {{ 'word.accompaniment' | translate | lowercase }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before; let i = index">
                    <td *ngIf="step['@permissions'].view">
                        {{ calculateFixCostsValues(i).subsidyRateAccompaniment | round:2 }}%
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">
                        <td *ngIf="step['@permissions'].view">
                            <!--                            {{calculateFixCostsValues(i, j).subsidyRateAccompaniment | round:2 }}%-->
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <th>{{ 'word.total_cost' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before; let i = index">
                    <td *ngIf="step['@permissions'].view">
                        {{ step.computedValues.summary.adminTotalContribution | currency:'CHF':'symbol':'0.0-2' }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">
                        <td *ngIf="step['@permissions'].view">
                            {{ (step.computedValues.summary.adminTotalContribution) | currency:'CHF':'symbol':'0.0-2' }}
                            ({{ ((step.computedValues.summary.adminTotalContribution) / getFolderStep().computedValues.summary.adminTotalContribution)|percent }}
                            )
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <th>{{ 'word.subsidy_rate' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before; let i = index">
                    <td *ngIf="step['@permissions'].view">
                        {{ calculateFixCostsValues(i).subsidyRate | round:2 }}%
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">
                        <td *ngIf="step['@permissions'].view">
                            <!--                            {{calculateFixCostsValues(i, j).subsidyRate | round:2 }}%-->
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
            </tbody>
        </table>
    </div>

    <!-- Analysis costs -->
    <div class="table-scroll">
        <h3 class="mb-1">{{ 'word.analysis_costs' | translate }}</h3>
        <table class="table table-responsive col-md-12" appTableCellHighlighter>
            <thead>
            <tr>
                <th class="w-25">{{ '' }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <th class="min-w-120" *ngIf="step['@permissions'].view" rowspan="2">
                        {{ 'workflow.' + step.columnName | translate }}
                    </th>
                </ng-container>
                <ng-container
                    *ngFor="let step of folderProgramResource.reportSteps; let first = first; let last = last; let index = index">
                    <th class="min-w-120 report-header report-first report-last" *ngIf="step['@permissions'].view"
                        [attr.colspan]="countColspan(step.steps)">
                        {{ step['@columnName'] | translate }}
                    </th>
                </ng-container>
            </tr>
            <tr>
                <th class="w-25">{{ '' }}</th>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                    <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                        <th class="min-w-120 report-header no-border-bottom" *ngIf="step['@permissions'].view"
                            [class.report-first]="first" [class.report-last]="last">
                            {{ 'workflow.' + step['@columnName'] | translate }}
                        </th>
                    </ng-container>
                </ng-container>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th>{{ 'word.amount' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td *ngIf="step['@permissions'].view">
                        {{ step.analysisCosts.units }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">
                        <td *ngIf="step['@permissions'].view">
                            <!--                            {{step.analysisCosts.units}}-->
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <th>{{ 'word.unit_price' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td *ngIf="step['@permissions'].view">
                        {{ step.analysisCosts.unitPrice | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">
                        <td *ngIf="step['@permissions'].view">
                            <!--                            {{step.analysisCosts.unitPrice | currency:'CHF':'symbol':'0.0-2' | default:'-' }}-->
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <th>{{ 'word.total_cost' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td *ngIf="step['@permissions'].view">
                        {{ (step.analysisCosts.units * step.analysisCosts.unitPrice) | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">
                        <td *ngIf="step['@permissions'].view">
                            <!--                            {{(step.analysisCosts.units * step.analysisCosts.unitPrice) | currency:'CHF':'symbol':'0.0-2' | default:'-' }}-->
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
<!--            <tr>-->
<!--                <th>{{ 'word.requested_contribution' | translate }} / {{ 'word.unit' | translate | lowercase }}</th>-->
<!--                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">-->
<!--                    <td *ngIf="step['@permissions'].view">-->
<!--                        {{ step.analysisCosts.requestedContribution | currency:'CHF':'symbol':'0.0-2' | default:'-' }}-->
<!--                    </td>-->
<!--                </ng-container>-->
<!--                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">-->
<!--                    <ng-container *ngFor="let step of column.steps; let j = index;">-->
<!--                        <td *ngIf="step['@permissions'].view">-->
<!--                            &lt;!&ndash;                            {{step.analysisCosts.requestedContribution | currency:'CHF':'symbol':'0.0-2' | default:'-' }}&ndash;&gt;-->
<!--                        </td>-->
<!--                    </ng-container>-->
<!--                </ng-container>-->
<!--            </tr>-->
            <tr>
                <th>{{ 'word.total_requested_contribution' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td *ngIf="step['@permissions'].view">
                        {{ (step.analysisCosts.units * step.analysisCosts.requestedContribution) | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">
                        <td *ngIf="step['@permissions'].view">
                            {{ (step.analysisCosts.units * step.analysisCosts.requestedContribution) | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <th>{{ 'word.subsidy_rate' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td *ngIf="step['@permissions'].view">
                        {{
                            ((step.analysisCosts.unitPrice > 0) ?
                                    (step.analysisCosts.units * step.analysisCosts.requestedContribution) / (step.analysisCosts.units * step.analysisCosts.unitPrice) * 100 :
                                    0
                            ) | round:2 | default:'-'
                        }}
                        %
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">
                        <td *ngIf="step['@permissions'].view">
                            <!--                            {{((step.analysisCosts.unitPrice > 0) ?-->
                            <!--                                (step.analysisCosts.units * step.analysisCosts.requestedContribution) / (step.analysisCosts.units * step.analysisCosts.unitPrice) * 100 :-->
                            <!--                                0-->
                            <!--                        ) | round:2 | default:'-'}}-->
                            <!--                            %-->
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
            </tbody>
        </table>
    </div>

    <!-- Summary -->
    <div class="table-scroll general-measure-wrapper">
        <h3 class="mb-1">{{ 'word.summary' | translate }}</h3>
        <table class="table table-responsive col-md-12" appTableCellHighlighter>
            <thead class="col-md-12">
            <tr>
                <th class="w-25">{{ '' }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <th class="min-w-120" *ngIf="step['@permissions'].view" rowspan="2">
                        {{ 'workflow.' + step.columnName | translate }}
                    </th>
                </ng-container>
                <ng-container
                    *ngFor="let step of folderProgramResource.reportSteps; let first = first; let last = last; let index = index">
                    <th class="min-w-120 report-header report-first report-last" *ngIf="step['@permissions'].view"
                        [attr.colspan]="countColspan(step.steps)">
                        {{ step['@columnName'] | translate }}
                    </th>
                </ng-container>
            </tr>
            <tr>
                <th class="w-25">{{ '' }}</th>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                    <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                        <th class="min-w-120 report-header no-border-bottom" *ngIf="step['@permissions'].view"
                            [class.report-first]="first" [class.report-last]="last">
                            {{ 'workflow.' + step['@columnName'] | translate }}
                        </th>
                    </ng-container>
                </ng-container>
            </tr>
            </thead>
            <tbody>
            <tr>
                <th>{{ 'word.contribution_admin_management' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ step.computedValues.summary.adminManagementContribution | currency:'CHF':'symbol':'0.0-2' }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">

                            {{ step.computedValues.summary.adminManagementContribution | currency:'CHF':'symbol':'0.0-2' }}
                            ({{ (step.computedValues.summary.adminManagementContribution / getFolderStep().computedValues.summary.adminManagementContribution)|percent }}
                            )
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <th>{{ 'word.cost_admin_management' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ step.computedValues.summary.adminManagementCost | currency:'CHF':'symbol':'0.0-2' }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">
                        <!--                        <td class="min-w-120" *ngIf="step['@permissions'].view">-->
                        <!--                            {{ step.computedValues.summary.adminManagementCost | currency:'CHF':'symbol':'0.0-2' }}-->
                        <!--                        </td>-->
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <th>{{ 'word.contribution_admin_accompaniment' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ step.computedValues.summary.adminAccompanimentContribution | currency:'CHF':'symbol':'0.0-2' }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ step.computedValues.summary.adminAccompanimentContribution | currency:'CHF':'symbol':'0.0-2' }}
                            ({{ (step.computedValues.summary.adminAccompanimentContribution / getFolderStep().computedValues.summary.adminAccompanimentContribution)|percent }}
                            )

                        </td>
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <th>{{ 'word.cost_admin_accompaniment' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ step.computedValues.summary.adminAccompanimentCost | currency:'CHF':'symbol':'0.0-2' }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">
                        <!--                        <td class="min-w-120" *ngIf="step['@permissions'].view">-->
                        <!--                            {{ step.computedValues.summary.adminAccompanimentCost | currency:'CHF':'symbol':'0.0-2' }}-->
                        <!--                        </td>-->
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <th>{{ 'word.contribution_admin_total' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ step.computedValues.summary.adminTotalContribution | currency:'CHF':'symbol':'0.0-2' }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ step.computedValues.summary.adminTotalContribution | currency:'CHF':'symbol':'0.0-2' }}
                            ({{ (step.computedValues.summary.adminTotalContribution / getFolderStep().computedValues.summary.adminTotalContribution)|percent }}
                            )

                        </td>
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <th>{{ 'word.cost_admin_total' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ step.computedValues.summary.adminTotalCost | currency:'CHF':'symbol':'0.0-2' }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">
                        <!--                        <td class="min-w-120" *ngIf="step['@permissions'].view">-->
                        <!--                            {{ step.computedValues.summary.adminTotalCost | currency:'CHF':'symbol':'0.0-2' }}-->
                        <!--                        </td>-->
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <th>{{ 'word.contribution_measures' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">

<!--                        <pre>-->
<!--                            {{step.computedValues|json}}-->
<!--                        </pre>-->
                        {{ step.computedValues.summary.totalVariableContribution | currency:'CHF':'symbol':'0.0-2' }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">




                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ step.computedValues.summary.measuresRealisedRequestedContribution + step.computedValues.analysisCosts.prokilowattTotalContribution | positive | round:2 | number }} {{ 'word.chf' | translate }}
                            ({{ ( (step.computedValues.summary.measuresRealisedRequestedContribution + step.computedValues.analysisCosts.prokilowattTotalContribution) / getFolderStep().computedValues.summary.totalVariableContribution) | percent }})
                        </td>


                        <!--                        <td class="min-w-120" *ngIf="step['@permissions'].view">-->
                        <!--                            {{ step.computedValues.summary.measuresContribution | currency:'CHF':'symbol':'0.0-2' }}-->
                        <!--                        </td>-->


                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <th>{{ 'word.cost_measures' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ step.computedValues.summary.measuresCost | currency:'CHF':'symbol':'0.0-2' }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">
                        <!--                        <td class="min-w-120" *ngIf="step['@permissions'].view">-->
                        <!--                            {{ step.computedValues.summary.measuresCost | currency:'CHF':'symbol':'0.0-2' }}-->
                        <!--                        </td>-->
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <th>{{ 'word.requested_contribution' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ step.computedValues.requestedContribution | currency:'CHF':'symbol':'0.0-2' }}
                    </td>
                </ng-container>

                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">

                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ getReportStepValues(step).computedValues.summary.adminTotalContribution + getReportStepValues(step).computedValues.summary.measuresRealisedRequestedContribution + getReportStepValues(step).computedValues.analysisCosts.prokilowattTotalContribution | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                        </td>
                    </ng-container>
                </ng-container>

<!--                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">-->
<!--                    <ng-container *ngFor="let step of column.steps; let j = index;">-->
<!--                        <td class="min-w-120" *ngIf="step['@permissions'].view">-->
<!--                            {{ step.computedValues.requestedContribution | currency:'CHF':'symbol':'0.0-2' }}-->
<!--                        </td>-->
<!--                    </ng-container>-->
<!--                </ng-container>-->
            </tr>
<!--            <tr>-->
<!--                <th>{{ 'word.total_measures_realised_costs' | translate }}</th>-->
<!--                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">-->
<!--                    <td class="min-w-120" *ngIf="step['@permissions'].view">-->
<!--                        {{ step.computedValues.summary.measuresRealisedRequestedContribution + step.computedValues.analysisCosts.prokilowattTotalContribution | currency:'CHF':'symbol':'0.0-2' }}-->
<!--                    </td>-->
<!--                </ng-container>-->
<!--                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">-->
<!--                    <ng-container *ngFor="let step of column.steps; let j = index;">-->
<!--                        <td class="min-w-120" *ngIf="step['@permissions'].view">-->
<!--                            {{ step.computedValues.summary.measuresRealisedRequestedContribution + step.computedValues.analysisCosts.prokilowattTotalContribution | currency:'CHF':'symbol':'0.0-2' }}-->
<!--                        </td>-->
<!--                    </ng-container>-->
<!--                </ng-container>-->
<!--            </tr>-->




<!--            METTREICI -->
<!--            <tr>-->
<!--                <th>{{ 'word.total_realised_costs' | translate }}</th>-->
<!--                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">-->
<!--                    <td class="min-w-120" *ngIf="step['@permissions'].view">-->
<!--                    </td>-->
<!--                </ng-container>-->
<!--                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">-->
<!--                    <ng-container *ngFor="let step of column.steps; let j = index;">-->

<!--                        <td class="min-w-120" *ngIf="step['@permissions'].view">-->
<!--                            {{ getReportStepValues(step).computedValues.summary.adminTotalContribution + getReportStepValues(step).computedValues.summary.measuresRealisedRequestedContribution + getReportStepValues(step).computedValues.analysisCosts.prokilowattTotalContribution | currency:'CHF':'symbol':'0.0-2' | default:'-' }}-->
<!--                        </td>-->
<!--                    </ng-container>-->
<!--                </ng-container>-->
<!--            </tr>-->


            <tr>
                <th>{{ 'word.total_cost' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ step.computedValues.summary.totalCost | currency:'CHF':'symbol':'0.0-2' }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">
                        <!--                        <td class="min-w-120" *ngIf="step['@permissions'].view">-->
                        <!--                            {{ step.computedValues.summary.totalCost | currency:'CHF':'symbol':'0.0-2' }}-->
                        <!--                        </td>-->
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <th>{{ 'word.total_saving' | translate }} </th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ step.computedValues.totalSaving | positive | number }} {{ 'word.kwh' | translate }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ getReportStepValues(step).computedValues.summary.realisedTotalEconomy | positive | round:2 | number }} {{ 'word.kwh' | translate }}
                            ({{ (getReportStepValues(step).computedValues.summary.realisedTotalEconomy / getFolderStep().computedValues.totalSaving) | percent }})
                            <!--                        </td>-->
                            <!--                            {{ step.computedValues.totalSaving | positive | number }} {{ 'word.kwh' | translate }}-->
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
            <tr>
                <th>{{ 'word.cost_utility_ratio' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ step.computedValues.costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}
                    </td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">
                    <ng-container *ngFor="let step of column.steps; let j = index;">
                    </ng-container>
                </ng-container>
            </tr>


<!--            <tr>-->
<!--                <th>{{ 'title.realised_economy' | translate }}</th>-->
<!--                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">-->
<!--                    <td class="min-w-120" *ngIf="step['@permissions'].view">-->
<!--                    </td>-->
<!--                </ng-container>-->
<!--                <ng-container *ngFor="let column of folderProgramResource.reportSteps; let i = index">-->
<!--                    <ng-container *ngFor="let step of column.steps; let j = index;">-->

<!--                        <td class="min-w-120" *ngIf="step['@permissions'].view">-->
<!--                            {{ getReportStepValues(step).computedValues.summary.realisedTotalEconomy | positive | round:2 | number }} {{ 'word.kwh' | translate }}-->
<!--                            ({{ (getReportStepValues(step).computedValues.summary.realisedTotalEconomy / getFolderStep().computedValues.totalSaving) | percent }}-->
<!--                            )-->

<!--                        </td>-->
<!--                    </ng-container>-->
<!--                </ng-container>-->
<!--            </tr>-->


            <tr>
                <th>{{ 'word.bill_fix' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td class="blank-case" *ngIf="step['@permissions'].view"></td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps;">
                    <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                        <td class="" *ngIf="step['@permissions'].view" [class.report-first]="first"
                            [class.report-last]="last">
                            {{ isLastViewableReport(column, step) ? (step.computedValues.invoicesFix | currency) : '-' }}
                            {{ isLastViewableReport(column, step) ? '(' + (step.computedValues.invoicesFix / (step.computedValues.summary.totalContribution - step.computedValues.summary.totalVariableContribution) | percent) + ')' : '' }}

                        </td>
                    </ng-container>
                </ng-container>
            </tr>

            <tr>
                <th>{{ 'word.bill_variable' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td class="blank-case" *ngIf="step['@permissions'].view"></td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps;">
                    <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                        <td class="" *ngIf="step['@permissions'].view" [class.report-first]="first"
                            [class.report-last]="last">


                            {{ isLastViewableReport(column, step) ? (step.computedValues.invoicesVariable| currency) : '-' }}
                            {{ isLastViewableReport(column, step) ? '(' + (step.computedValues.invoicesVariable / step.computedValues.summary.totalVariableContribution | percent) + ')' : '' }}

                        </td>
                    </ng-container>
                </ng-container>
            </tr>

            <tr>
                <th>{{ 'word.balance_variable' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td class="blank-case icon show-icon current-step" *ngIf="step['@permissions'].view"
                        [class.edit-icon]="step['@permissions'].edit"
                        (click)="showOrEditFolderStep(step)"></td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                    <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                        <td class="icon show-icon current-step vertical-separator" *ngIf="step['@permissions'].view"
                            [class.edit-icon]="step['@permissions'].edit" [class.report-first]="first"
                            [class.report-last]="last" (click)="showOrEditFolderReport(column, step)">
                            {{ isLastViewableReport(column, step) ? (step.computedValues.summary.measuresContribution - step.computedValues.invoicesVariable | currency) : '-' }}
                        </td>
                    </ng-container>
                </ng-container>
            </tr>


            <tr class="last-row">
                <th>{{ 'word.balance_fix' | translate }}</th>
                <ng-container *ngFor="let step of folderProgramResource.folderSteps.before">
                    <td class="blank-case icon show-icon current-step" *ngIf="step['@permissions'].view"
                        [class.edit-icon]="step['@permissions'].edit"
                        (click)="showOrEditFolderStep(step)"></td>
                </ng-container>
                <ng-container *ngFor="let column of folderProgramResource.reportSteps">
                    <ng-container *ngFor="let step of column.steps; let first = first; let last = last;">
                        <td class="icon show-icon current-step vertical-separator" *ngIf="step['@permissions'].view"
                            [class.edit-icon]="step['@permissions'].edit" [class.report-first]="first"
                            [class.report-last]="last" (click)="showOrEditFolderReport(column, step)">

                            {{ isLastViewableReport(column, step) ? ((getFolderStep().computedValues.summary.adminTotalContribution) - step.computedValues.invoicesFix | currency) : '-' }}
                        </td>
                    </ng-container>
                </ng-container>
            </tr>

            </tbody>
        </table>
    </div>
</app-card>

<!-- General -->
<app-card cardNumber="5" appAdminComponent cardTitle="title.general">
    <div class="row">
        <div class="col-md-3">
            <p class="field-title">{{ 'word.project_name' | translate }}</p>
            <p>{{ folderProgramResource.data.currentProgram.name }}</p>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.folder_language' | translate }}</p>
            <mat-form-field>
                <mat-select [formControl]="assignedLanguageField" [disabled]="!this.hasRoleStaffPkw()">
                    <mat-option *ngFor="let lang of possibleLanguages"
                                [value]="lang.locale">{{ 'word.' + lang.locale | translate }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.post_arrival_date' | translate }}</p>
            <mat-form-field>
                <input matInput
                       [matDatepicker]="postArrivalDate"
                       [formControl]="postArrivalDateCtrl"
                       [disabled]="!this.hasRoleStaffPkw()"
                       type="text"
                       autocomplete="off">
                <mat-datepicker-toggle matSuffix [for]="postArrivalDate"></mat-datepicker-toggle>
                <mat-datepicker #postArrivalDate></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <p class="field-title">{{ 'word.questions_answers_date' | translate }}</p>
            <mat-form-field>
                <input matInput
                       [matDatepicker]="questionsAnswersDate"
                       [formControl]="questionsAnswersDateCtrl"
                       [disabled]="!this.hasRoleStaffPkw()"
                       type="text"
                       autocomplete="off">
                <mat-datepicker-toggle matSuffix [for]="questionsAnswersDate"></mat-datepicker-toggle>
                <mat-datepicker #questionsAnswersDate></mat-datepicker>
            </mat-form-field>
        </div>
    </div>
    <app-admin-managers-selection [managersForm]="managersForm"></app-admin-managers-selection>
</app-card>

<!-- Remarks -->
<app-admin-remark-card appAdminComponent cardNumber="6" [folder]="folderProgramResource"></app-admin-remark-card>

<!-- Documents -->
<app-card cardNumber="7" cardTitle="title.documents">
    <app-media-objects-list (savedEvent)="save()" [form]="mediaForm"
                            [folderUuid]="folderProgramResource.data.uuid"></app-media-objects-list>
</app-card>

<app-todo [folder]="folderProgramResource" *appIsGranted="'ROLE_STAFF_PKW'"></app-todo>

<app-sticky-footer *appIsGranted="'ROLE_STAFF_PKW'">
    <div class="w-50">
        <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="save()"
                [loading]="loading.loading$ | async">
            {{ 'action.save' | translate }} <i class="icon-floppy-disk"></i>
        </button>
    </div>

    <div class="w-50 d-flex justify-content-end">
        <button mat-flat-button color="primary" class="align-self-end" (click)="openFolderSettingsDialog()">
            {{ 'word.folder_settings' | translate }} <i class="icon-filter"></i>
        </button>
    </div>
</app-sticky-footer>
