import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    COMPUTOR_NAMES,
    COMPUTOR_TYPES,
    FolderService,
} from '../../../../../../core/services/folder.service';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import {
    AbstractOldProgramReportFormTemplateComponent
} from '../../../../program-report-form.template';
import { NotificationService } from '../../../../../../core/notifications/notification.service';
import { FormBuilder } from '@angular/forms';
import { FolderStatusService } from '../../../../../../core/services/folder-status.service';
import { TranslateService } from '@ngx-translate/core';
import { SwitchUserService } from '../../../../../../core/services/switch-user.service';
import { Measure } from '../../../../../../core/models/measure.model';
import { MediaObjectService } from '../../../../../../core/services/media-object.service';
import { ReportForm } from '../../../../models/reportForm';
import { OldCalculatorService } from '../../../../services/old-calculator.service';
import { ProgramReportService } from '../../../../../../core/services/program-report.service';
import { PageTitleService } from '../../../../../../core/services/page-title.service';
import { ReportSettingsDialogComponent } from '../../../../../../shared/report-settings-dialog/report-settings-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { LoadingService } from '../../../../../../core/services/loading.service';
import { TechnicalOrientationService } from '../../../../../../core/services/technical-orientation.service';
import { ProgramReportResource } from '../../../../../../core/models/program-report-resource';

@Component({
    selector: 'app-admin-report-show',
    templateUrl: './admin-program-report-validation2.component.html',
    styleUrls: ['./admin-program-report-validation2.component.scss']
})
export class AdminProgramReportValidation2Component extends AbstractOldProgramReportFormTemplateComponent implements OnInit, OnDestroy {
    public static computorName = COMPUTOR_NAMES.oldComputor;

    public measures: Measure[] = [];

    constructor(
        protected notificationService: NotificationService,
        protected technicalOrientationService: TechnicalOrientationService,
        protected fb: FormBuilder,
        protected folderStatusService: FolderStatusService,
        protected projectCalculator: OldCalculatorService,
        protected oldComputorForm: ReportForm,
        protected translateService: TranslateService,
        protected switchUserService: SwitchUserService,
        protected route: ActivatedRoute,
        protected folderService: FolderService,
        protected breadcrumbService: BreadcrumbService,
        protected mediaObjectService: MediaObjectService,
        private reportResourceService: ProgramReportService,
        private title: PageTitleService,
        private dialog: MatDialog,
        private router: Router,
        public loading: LoadingService
    ) {
        super(
            oldComputorForm,
            projectCalculator,
            notificationService,
            switchUserService,
            technicalOrientationService,
            folderStatusService,
            fb,
            route,
            translateService,
            folderService,
            mediaObjectService
        );

        this.computorType = COMPUTOR_TYPES.program;
        this.computorName = COMPUTOR_NAMES.oldComputor;
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.measures = this.programReportResource.data.currentReport.reportMeasures;
        this.folderService.addCurrentReport(this.programReportResource.data.currentReport);
        this.initBreadcrumb();
    }

    ngOnDestroy(): void {
        this.folderService.clear();
    }

    public save(): void {
        if (this.form.valid) {
            const reportIO = { data: this.form.value as {currentReport} } as ProgramReportResource;
            this.reportResourceService.save(reportIO, this.computorType, this.computorName).subscribe(() => {
                this.notificationService.success('toast.data_successfully_saved');
            });
        }
    }

    public reportNotCompleted(): boolean {
        return 'completed' !== this.programReportResource.data.marking;
    }

    public openReportSettingsDialog(): void {
        const dialog = this.dialog.open(ReportSettingsDialogComponent, {
            data: {report: this.programReportResource},
            width: '600px',
        });
        dialog.afterClosed().subscribe((data: {deleted: boolean; isFinal: boolean}) => {
            if (!data) {
                return;
            }

            if (data.deleted) {
                void this.router.navigateByUrl(this.folderLink);
            }

            this.programReportResource.data.isFinal = data.isFinal ?? false;
        });
    }

    private initBreadcrumb(): void {
        this.breadcrumbService.reportView(this.folderLink);
    }

    get folderLink(): string {
        return '/admin/programs/' + this.folderProgram.data.id.toString();
    }
}
