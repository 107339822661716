import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TodoRoutingModule } from './todo-routing.module';
import { TodoListComponent } from './todo-list/todo-list.component';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { SharedModule } from '../../../shared/shared.module';
import { MatSortModule } from '@angular/material/sort';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TodoSmallListComponent } from './todo-small-list/todo-small-list.component';
import { TodoComponent } from './todo/todo.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatMenuModule } from '@angular/material/menu';
import { ComponentsModule } from '../../../components/components.module';


@NgModule({
    declarations: [TodoListComponent, TodoSmallListComponent, TodoComponent],
    exports: [
        TodoListComponent,
        TodoSmallListComponent,
        TodoComponent
    ],
    imports: [
        CommonModule,
        TodoRoutingModule,
        MatCardModule,
        TranslateModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        MatPaginatorModule,
        MatTableModule,
        SharedModule,
        MatSortModule,
        PipesModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        MatTooltipModule,
        OverlayModule,
        MatMenuModule,
        ComponentsModule
    ]
})
export class TodoModule { }
