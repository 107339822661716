import {
    Directive,
    EventEmitter, HostListener,
    Input,
    Output,
} from '@angular/core';
import { SwitchUserService } from '../services/switch-user.service';
import { Router } from '@angular/router';

@Directive({
    selector: '[appSwitchUser]'
})
export class SwitchUserDirective {
    @Input()
    startImpersonate = null;

    @Input()
    stopImpersonate = false;

    @Input()
    redirectUrl = null;

    @Input()
    canSwitchUser = false;

    @Input()
    nextTransition: string = null;

    @Output()
    userSwitched = new EventEmitter();

    constructor(
        private switchUserService: SwitchUserService,
        private router: Router
    ) {
    }

    @HostListener('click', ['$event'])
    public clickEvent(): void {
        if (!this.canSwitchUser) {
            return;
        }

        if (this.startImpersonate) {
            this.switchUserService.startImpersonating(this.startImpersonate);
            this.switchUserService.nextTransition = this.nextTransition;

            this.userSwitched.emit();

            void this.router.navigateByUrl(this.redirectUrl);
        }

        if (this.stopImpersonate) {
            this.switchUserService.stopImpersonating();
            this.clearNextTransition();

            void this.router.navigateByUrl(this.switchUserService.logoutRedirect);
        }
    }

    private clearNextTransition(): void {
        this.switchUserService.nextTransition = null;
    }
}
