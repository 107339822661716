<h2 mat-dialog-title>{{ 'action.add_filter' | translate }}</h2>
<mat-dialog-content class="mat-typography" [formGroup]="form">
    <mat-form-field>
        <mat-label>{{ 'word.filter_name' | translate }}</mat-label>
        <input matInput formControlName="name">
    </mat-form-field>
    <div formArrayName="filterContent" class="mb-2">
        <div class="field-row-wrapper">
            <ng-container *ngFor="let filter of content.controls; let i = index">
                <div [formGroupName]="i" class="row field-row">
                    <mat-form-field class="col-md-4">
                        <mat-label>{{ 'word.filter_field' | translate }}</mat-label>
                        <mat-select formControlName="field">
                            <mat-option *ngFor="let field of availableFields; let i = index" [value]="field['@id']">
                                {{ field.slug | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="col-md-6" *ngIf="!isFieldMarking(i)">
                        <mat-label>{{ 'word.filter_search' | translate }}</mat-label>
                        <input matInput formControlName="search">
                    </mat-form-field>
                    <!--                    If marking field is selected -> dropdown so right value is set for marking -->
                    <mat-form-field class="col-md-6" *ngIf="isFieldMarking(i)">
                        <mat-label>{{ 'word.filter_search' | translate }}</mat-label>
                        <mat-select formControlName="search">
                            <mat-option *ngFor="let marking of availableMarkings" [value]="marking">{{'workflow.'+marking | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="col-md-2 d-flex justify-content-end align-items-center">
                        <button mat-button appMatButtonLoading [loading]="isButtonLoading(i)" (click)="removeRow(i)"><mat-icon>remove_circle_outline</mat-icon></button>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-between">
    <div>
        <button mat-button (click)="addControl()"><mat-icon>add_circle_outline</mat-icon></button>
    </div>
    <div>
        <button mat-flat-button (click)="dialogRef.close(null)" class="cancel-button mr-2">{{ 'action.close' | translate }}</button>
        <button mat-flat-button (click)="save()" color="primary">{{ 'action.save' | translate }}</button>
    </div>
</mat-dialog-actions>
