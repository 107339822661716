<form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
    <div class="row">
        <div class="col-md-12">
            <!-- Requested contribution -->
            <app-card cardNumber="1" cardTitle="title.general">
                <div class="folder" formGroupName="currentReport">
                    <div class="dataset" formGroupName="dataset">
                        <div class="row">
                            <div class="col-md-12">
                                <mat-form-field class="col-md-6">
                                    <input matInput
                                           placeholder="{{ 'word.requested_contribution' | translate }}"
                                           formControlName="requestedContribution"
                                           type="number"
                                           autocomplete="off">
                                    <mat-hint>
                                        {{'word.subsidy_rate' | translate}}
                                        : {{ computedResume?.subsidyRate | positive | round }} %
                                        <span class="hint-help"><mat-icon
                                                matTooltip="{{ 'help.measure_maximum_support_contribution' | translate }}">help</mat-icon></span>
                                    </mat-hint>
                                    <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>

                                    <mat-error
                                            *ngIf="form.controls['currentProject'].get('dataset').get('requestedContribution').hasError('min')">
                                        {{ 'validator.requested_contribution.min' | translate:{min: form.controls['currentProject'].get('dataset').get('requestedContribution').errors['min'].min} }}
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="col-md-6">
                                    <span class="mat-input-suffix"
                                          matSuffix>{{ 'word.cost_cts_kwh' | translate }}</span>
                                    <input matInput
                                           type="number"
                                           placeholder="{{ 'word.cost_utility_ratio' | translate }}"
                                           formControlName="costUtilityRatio">
                                </mat-form-field>
                            </div>
                            <div class="col-md-12">
                                <mat-form-field class="col-md-6">
                                    <span class="mat-input-suffix" matSuffix>{{ 'word.kwh' | translate }}</span>
                                    <input matInput
                                           placeholder="{{ 'word.initiated_savings' | translate }}"
                                           formControlName="initiatedSavings"
                                           type="number"
                                           autocomplete="off">
                                </mat-form-field>
                                <mat-form-field class="col-md-6">
                                    <span class="mat-input-suffix" matSuffix>{{ 'word.kwh' | translate }}</span>
                                    <input matInput
                                           placeholder="{{ 'word.performed_savings' | translate }}"
                                           formControlName="performedSavings"
                                           type="number"
                                           autocomplete="off">
                                </mat-form-field>
                            </div>
                            <div class="col-md-12">
                                <mat-form-field class="col-md-4">
                                    <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
                                    <input matInput
                                           placeholder="{{ 'word.third_party_subsidies' | translate }}"
                                           formControlName="thirdPartySubsidies"
                                           type="number"
                                           autocomplete="off">
                                </mat-form-field>
                                <mat-form-field class="col-md-4">
                                    <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
                                    <input matInput
                                           placeholder="{{ 'word.fixed_subsidies' | translate }}"
                                           formControlName="fixedSubsidies"
                                           type="number"
                                           autocomplete="off">
                                </mat-form-field>
                                <mat-form-field class="col-md-4">
                                    <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
                                    <input matInput
                                           placeholder="{{ 'word.variable_subsidies' | translate }}"
                                           formControlName="variableSubsidies"
                                           type="number"
                                           autocomplete="off">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
            </app-card>
        </div>

        <app-card class="col-md-12" cardNumber="2" cardTitle="title.documents">
            <app-media-objects-list [form]="currentReportForm"
                                    [reportIri]="programReportResource.data.currentReport.report['@id']"></app-media-objects-list>
        </app-card>

        <app-invoices-list class="col-md-12" [sectionNumber]="3" [report]="programReportResource"
                           [hideControls]="false"></app-invoices-list>
    </div>
</form>

<app-sticky-footer>
    <div class="justify-content-start">
        <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="save()"
                [loading]="loading.loading$ | async">
            {{ 'action.save' | translate }} <i class="icon-floppy-disk"></i>
        </button>
    </div>
    <div class="justify-content-end">
        <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="check()"
                [loading]="loading.loading$ | async">
            {{ 'action.check' | translate }} <i class="icon-checked"></i>
        </button>

        <button mat-flat-button color="primary" [disabled]="!isSubmittable" appMatButtonLoading
                [loading]="loading.loading$ | async" appConfirmDialog (confirmClick)="submit()"
                [dialogContent]="'dialog.confirm_submit_report.content'"
                [dialogConfirm]="'dialog.confirm_submit_project.confirm'"
                [dialogCancel]="'dialog.confirm_submit_project.cancel'">
            {{ 'action.submit_report' | translate }} <i class="icon-message"></i>
        </button>
    </div>
</app-sticky-footer>
