import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Breadcrumb } from '../../core/models/breadcrumb.model';
import { BreadcrumbService } from '../../core/services/breadcrumb.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, AfterContentChecked, OnDestroy {
    @Input()
    public showBreadcrumb = true;

    public breadcrumbs: Breadcrumb[];

    public destroySubject: Subject<boolean> = new Subject<boolean>();

    constructor(
        private breadcrumbService: BreadcrumbService,
        private cdr: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        this.breadcrumbService.breadcrumbsChanged$.pipe(takeUntil(this.destroySubject)).subscribe((res) => {
            this.breadcrumbs = res;
        });
    }

    ngOnDestroy(): void {
        this.destroySubject.next(true);
        this.destroySubject.complete();
    }

    ngAfterContentChecked(): void {
        this.cdr.detectChanges();
    }
}
