import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Measure } from '../../../../../../core/models/measure.model';
import { PageTitleService } from '../../../../../../core/services/page-title.service';
import { FolderStatusService } from '../../../../../../core/services/folder-status.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
    COMPUTOR_NAMES, COMPUTOR_TYPES,
    FolderService,
} from '../../../../../../core/services/folder.service';
import { MarkingService } from '../../../../../../core/services/marking.service';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../../../../../core/notifications/notification.service';
import { environment } from '../../../../../../../environments/environment';
import { MediaObject } from '../../../../../../core/models/media-object.model';
import { FolderProjectResource } from '../../../../../../core/models/folder-project-resource.model';
import { FormUtil } from '../../../../../../core/utils/form.util';
import { JsonLdError } from '../../../../../../core/models/json-ld.error.model';
import { FolderProjectService } from '../../../../../../core/services/folder-project.service';
import { ProjectFieldsCheckerService } from '../../../../services/project-fields-checker.service';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { DownloaderHelper } from '../../../../../../core/utils/download.util';
import { MediaObjectService } from '../../../../../../core/services/media-object.service';
import { HtmlInputEvent } from '../../../../../../core/events/html-input-event';
import { HttpParamUtil } from '../../../../../../core/utils/http-param.util';
import { DirtyFormWarnableInterface } from '../../../../../../components/form-component/dirty-form.component';
import { ProjectForm } from '../../../../models/projectForm';
import { Abstract2024BigProjectFormTemplateComponent } from '../../../../project-form.template';
import { ProjectCalculatorService } from '../../../../services/project-calculator.service';
import { TranslateService } from '@ngx-translate/core';
import { SwitchUserService } from '../../../../../../core/services/switch-user.service';
import { MenuService } from '../../../../../../core/services/menu.service';
import { PageScrollService } from 'ngx-page-scroll-core';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { IriUtil } from '../../../../../../core/utils/iri.util';
import { AuthorizationChecker } from '../../../../../../core/security/authorization-checker';
import { ChildProject } from '../../../../../../core/models/child-folder.model';
import { LoadingService } from '../../../../../../core/services/loading.service';
import { TechnicalOrientationService } from '../../../../../../core/services/technical-orientation.service';

@Component({
    selector: 'app-admin-project-validation2',
    templateUrl: './admin-project-validation2.component.html',
    styleUrls: ['./admin-project-validation2.component.scss']
})
export class AdminProjectValidation2Component
    extends Abstract2024BigProjectFormTemplateComponent implements OnInit, OnDestroy, DirtyFormWarnableInterface {
    public static computorName = COMPUTOR_NAMES.computor2024Big;

    @ViewChild('fileInput') public fileInput: ElementRef;
    @ViewChild('analysisFileInput') public analysisFileInput: ElementRef;

    public folderProject: FolderProjectResource;
    public measures: Measure[];
    public missingMarking: string[];
    public folderSteps: any[];
    private childFolderIndex: number = null;
    private uploadedFileType = 'folder';

    constructor(
        protected notificationService: NotificationService,
        protected technicalOrientationService: TechnicalOrientationService,
        protected fb: FormBuilder,
        protected folderStatusService: FolderStatusService,
        protected projectCalculator: ProjectCalculatorService,
        protected computor2024Bigform: ProjectForm,
        protected translateService: TranslateService,
        protected switchUserService: SwitchUserService,
        protected route: ActivatedRoute,
        protected folderService: FolderService,
        private breadcrumbService: BreadcrumbService,
        private folderProjectService: FolderProjectService,
        private formUtils: FormUtil,
        private router: Router,
        private projectFieldsCheckerService: ProjectFieldsCheckerService,
        private mediaObjectService: MediaObjectService,
        private titleService: PageTitleService,
        private menuService: MenuService,
        private pageScrollService: PageScrollService,
        private dialog: MatDialog,
        private markingService: MarkingService,
        private authorizationChecker: AuthorizationChecker,
        private title: PageTitleService,
        private translator: TranslateService,
        public loading: LoadingService
    ) {
        super(
            computor2024Bigform,
            projectCalculator,
            notificationService,
            switchUserService,
            technicalOrientationService,
            folderStatusService,
            fb,
            route,
            translateService,
            folderService
        );

        this.defaultCurrency = environment.defaultCurrency;
        this.folderProject = this.route.snapshot.data.folderProjectResource as FolderProjectResource;

        // eslint-disable-next-line
        this.folderSteps = [...this.folderProject.folderSteps.before];

        this.measures = this.folderProject.data.currentProject.measures;

        this.missingMarking = this.markingService.getMissingMarking(this.folderProject.data.projects);
        folderService.setProjectResource(this.folderProject);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.initBreadcrumb();
        this.setMinAndMaxDatesFromDecisionDate();
        this.updateFolderProjectOnMarkingChange();

        if (this.folderProject) {
            // clear values before adding them again as they are patched above
            const mediaObjects = this.form.get('analysisMediaObjects') as FormArray;
            mediaObjects.clear();

            this.folderProject.data.analysisMediaObjects.forEach((mediaObject: MediaObject) => {
                this.addAnalysisMediaObjectGroup(mediaObject);
            });
        }
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.markingService.clear();
        this.folderService.clear();
    }

    save(): void {
        this.form.patchValue({ tenderSession: this.folderProject.data.tenderSession['@id'] });
        // this.form.patchValue({tenderModel: this.folder.tenderSession.tenderModel});

        if (this.form.valid) {
            // eslint-disable-next-line
            this.folderProjectService.save({ data: this.form.value }, COMPUTOR_TYPES.project, COMPUTOR_NAMES.computor2024Big, false)
                .subscribe(() => {
                    this.form.markAsPristine();

                    this.notificationService.success('toast.data_successfully_saved');
                    this.updateFolder();
                }, (error: JsonLdError) => {
                    if (error['@type'] === 'ConstraintViolationList') {
                        this.projectFieldsCheckerService.displayCheckResult(error.violations);
                    }
                });
        } else {
            FormUtil.validateAllFormFields(this.form);
            this.notificationService.warn('validator.fields.missing');
        }
    }

    public check(): void {
        this.form.patchValue({
            requirementsControl: {
                requirement1: true, requirement2: true, requirement3: true,
                requirement4: true, requirement5: true
            }
        }); // bypass requirements check

        this.folderProjectService.check({ data: this.form.value }, COMPUTOR_TYPES.project, COMPUTOR_NAMES.computor2024Big)
            .subscribe(() => {
                this.isSubmittable = true;
                this.projectFieldsCheckerService.displayCheckResult([]);
            });
    }

    public downloadMediaObject(mediaObject: MediaObject): void {
        const params = new HttpParams()
            .set('folder', this.folderProject.data.uuid)
            ;

        this.mediaObjectService.download(mediaObject['@id'], params).subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        });
    }

    public deleteMediaObject(mediaObject: MediaObject, childFolderIndex: number = null, mediaObjectIndex: number = null): void {
        this.childFolderIndex = childFolderIndex;

        this.mediaObjectService.delete(mediaObject['@id']).subscribe(() => {
            if (null !== this.childFolderIndex) {
                (this.folderSteps[this.childFolderIndex] as ChildProject).mediaObjects.splice(mediaObjectIndex, 1);

                const mediaObjectsFormArray = this.form.get('currentProject.mediaObjects') as FormArray;
                mediaObjectsFormArray.removeAt(mediaObjectIndex);
                this.save();
            }
        });
    }

    public deleteAnalysisMediaObject(mediaObject: MediaObject, mediaObjectIndex: number = null): void {
        this.mediaObjectService.delete(mediaObject['@id']).subscribe(() => {
            const analysisMediaObjectsFormArray = this.form.get('analysisMediaObjects') as FormArray;
            analysisMediaObjectsFormArray.removeAt(mediaObjectIndex);
            this.save();
        });
    }

    public addMediaObject(childFolderIndex: number = null): void {
        this.uploadedFileType = 'folder';
        this.childFolderIndex = childFolderIndex;

        const nativeElement = this.fileInput.nativeElement as HTMLElement;
        nativeElement.click();
    }

    public addAnalysisMediaObject(): void {
        this.uploadedFileType = 'analysis';

        const nativeElement = this.fileInput.nativeElement as HTMLElement;
        nativeElement.click();
    }

    public onNewFileSelected(event: HtmlInputEvent): void {
        const httpParams = HttpParamUtil.create()
            .add('type', 'computor_2024Big_folder')
            .add('folder', this.folderProject.data.uuid)
            ;

        // stackoverflow.com/q/25333488/ (convert target.files to Array)
        for (const file of Array.prototype.slice.call(event.target.files)) {
            this.mediaObjectService.upload(file, httpParams.getHttpParams()).subscribe((media: MediaObject) => {
                if ('folder' === this.uploadedFileType) {
                    this.addMediaObjectGroup(media, true);
                }

                if ('analysis' === this.uploadedFileType) {
                    this.addAnalysisMediaObjectGroup(media, true);
                }
            });
        }
    }

    addMediaObjectGroup(media: MediaObject, newMediaObject = false): void {
        super.addMediaObjectGroup(media);
        if (newMediaObject) {
            const currentProjectStep = this.folderSteps[this.folderSteps.length - 1] as ChildProject;
            currentProjectStep.mediaObjects.push(media);
            this.save();
        }
    }

    public isRoleCorneliaOrAbove(): boolean {
        return this.authorizationChecker.checkIsGranted('ROLE_CORNELIA');
    }

    public canEdit(): boolean {
        return this.folderProject['@permissions'].edit;
    }

    public canEditAnalyse(): boolean {
        return (this.folderProject['@permissions'].extra as { editAnalyse: boolean }).editAnalyse;
    }

    public isValidation2(): boolean {
        return this.folderProject.currentMarking === 'validation_2';
    }

    private addAnalysisMediaObjectGroup(media: MediaObject, newMediaObject = false): void {
        const analysisMediaObjects = this.form.get('analysisMediaObjects') as FormArray;
        analysisMediaObjects.push(this.createAnalysisMediaObjectGroup(media));
        if (newMediaObject) {
            this.save();
        }
    }

    private createAnalysisMediaObjectGroup(media: MediaObject): FormGroup {
        return this.fb.group({
            '@id': [media['@id'], []],
            originalName: [media.originalName, []],
        });
    }

    private initBreadcrumb(): void {
        this.breadcrumbService.folderValidation2(this.folderLink);
    }

    private canLeave(): boolean { // used by guard
        return !this.form.dirty;
    }

    private updateFolderProjectOnMarkingChange(): void {
        this.markingService.folderMarking$.pipe(
            distinctUntilChanged(),
            takeUntil(this.destroySubject)
        ).subscribe((res) => {
            if (null === res) {
                return;
            }
            this.updateFolder();
        });
    }

    private updateFolder(): void {
        this.folderProjectService.find(String(this.folderProject.data.id),
            IriUtil.extractId(this.folderProject.data.currentProject['@id'])).subscribe((folder: FolderProjectResource) => {
                this.folderService.setProjectResource(folder);
                this.folderProject = folder;
                this.folderSteps = [...this.folderProject.folderSteps.before]; // eslint-disable-line
            });
    }

    get folderLink(): string {
        return '/admin/projects/' + this.folderProject.data.id.toString();
    }

}
