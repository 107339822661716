import { Component, OnInit } from '@angular/core';
import { Tag } from '../../core/models/tag.model';
import { TagService } from '../../core/services/tag.service';
import { Paginator } from '../../core/models/paginator.model';
import { NotificationService } from '../../core/notifications/notification.service';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-tag-list',
    templateUrl: './tag-list.component.html',
    styleUrls: ['./tag-list.component.scss']
})
export class TagListComponent implements OnInit {
    availableTags: Tag[] = [];
    newTagField = new FormControl();
    createButtonLoading = false;

    constructor(
        private tagService: TagService,
        private notificationService: NotificationService,
    ) { }

    ngOnInit(): void {
        this.getDeletableTags();
    }

    getDeletableTags(): void {
        this.tagService.getDeletableTags().subscribe((result: Paginator) => {
            this.availableTags = result['hydra:member'] as Tag[];
        });
    }

    deleteTag(tag: string): void {
        this.tagService.deleteTag(tag).subscribe(() => {
            this.getDeletableTags();
            this.notificationService.success('toast.data_successfully_saved');
        });
    }

    addTag(): void {
        if (this.newTagField.value && String(this.newTagField.value).trim().length > 0) {
            this.createButtonLoading = true;
            this.tagService.createNewTag(this.newTagField.value).subscribe(() => {
                this.createButtonLoading = false;
                this.newTagField.setValue(null);
                this.notificationService.success('toast.data_successfully_saved');
                this.getDeletableTags();
            }, () => { // tag already exists
                this.createButtonLoading = false;
            });
        }
    }
}
