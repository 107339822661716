import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FolderService } from '../../core/services/folder.service';
import { MatRadioChange, MatRadioGroup } from '@angular/material/radio';
import { MatDialogRef } from '@angular/material/dialog';
import { EnabledTransitionService } from '../../core/services/enabled-transition.service';
import { EnabledTransitionResource } from '../../core/models/enabled-transition-resource/enable-transition-resource.model';
import { IriUtil } from '../../core/utils/iri.util';
import { FormBuilder, Validators } from '@angular/forms';
import { FormUtil } from '../../core/utils/form.util';
import { delay, finalize, map, takeUntil } from 'rxjs/operators';
import { FolderProjectResource } from '../../core/models/folder-project-resource.model';
import { MarkingService } from '../../core/services/marking.service';
import { Subject } from 'rxjs';
import { FolderProgramResource } from '../../core/models/folder-program-resource.model';
import { ResourceUtil } from '../../core/utils/resourceUtil';
import { LoadingService } from '../../core/services/loading.service';

@Component({
    selector: 'app-change-folder-state-dialog',
    templateUrl: './change-folder-state-dialog.component.html',
    styleUrls: ['./change-folder-state-dialog.component.scss']
})
export class ChangeFolderStateDialogComponent implements OnInit, OnDestroy {

    @ViewChild(MatRadioGroup, {static: false}) private radioGroup: MatRadioGroup;

    public currentMarkingRadioBtnIdx: number;

    public showBackwardStateWarning = false;

    public currentResource: FolderProjectResource|FolderProgramResource;
    public currentTransition: string = null;

    public tenderSessionId: string;
    public folderId: string;
    public enabledTransitions: EnabledTransitionResource[] = null;

    public destroySubject: Subject<boolean> = new Subject<boolean>();

    public form = this.fb.group({
        id: [null],
        transition: [null, Validators.required],
    });

    constructor(
        public folderService: FolderService,
        private fb: FormBuilder,
        private enabledTransitionService: EnabledTransitionService,
        private dialog: MatDialogRef<ChangeFolderStateDialogComponent>,
        private markingService: MarkingService,
        public loading: LoadingService
    ) {
    }

    ngOnInit(): void {
        this.currentResource = this.folderService.getCurrentResource();

        this.tenderSessionId = IriUtil.extractId(this.currentResource.data.tenderSession['@id']);
        this.folderId = this.currentResource.data.id.toString();

        this.enabledTransitionService.findAllByFolderId(this.folderId).pipe(
            map((transitions: EnabledTransitionResource[]) => {
                this.enabledTransitions = transitions;
            }),
            delay(0),
            finalize(() => {
                // now this.radioGroup is filled
                this.getCurrentMarkingRadioButtonIdx();
            })
        ).subscribe();

        this.addOrRemoveExplanationControl();
    }

    ngOnDestroy(): void {
        this.destroySubject.next(true);
        this.destroySubject.complete();
    }

    public checkIfBackwardChange(selectedRadioID: string): void {
        let i = 0;
        this.radioGroup._radios.toArray().forEach(radio => {
            if (radio.id === selectedRadioID) {
                this.showBackwardStateWarning = i < this.currentMarkingRadioBtnIdx;
                return;
            }
            i++;
        });
    }

    getCurrentMarkingRadioButtonIdx(): void {
        let i = 0;
        this.radioGroup._radios.toArray().forEach(radio => {
            if (radio.checked) {
                this.currentMarkingRadioBtnIdx = i;
                return;
            }
            i++;
        });
    }

    onChange($event: MatRadioChange): void {
        this.checkIfBackwardChange($event.source.id);
        this.currentTransition = $event.value as string;
    }

    changeState(): void {
        if (this.form.valid) {

            const body: {closingReason?: string} = {};
            if (this.form.contains('explanation')) {
                body.closingReason = this.form.get('explanation').value as string;
            }

            const transition = this.form.get('transition').value as string;

            this.folderService.transitionAdmin(this.folderId, transition, body)
                .subscribe(() => {
                    this.markingService.folderMarkingChanged(transition);
                    this.dialog.close();
                });
        } else {
            FormUtil.validateAllFormFields(this.form);
        }
    }

    public isTransitionEnabled(transition: string): boolean {
        const hasTransition = this.enabledTransitions.find((enabledTransition) => enabledTransition.name === transition);

        return !!hasTransition;
    }

    public closeDialog(): void {
        this.dialog.close();
    }

    /**
     * Determinate if switch user must be used for transition "validate".
     * Switch user is only possible if current marking is "draft" transition.
     */
    public validateSwitchUser(): boolean {
        // tslint:disable-next-line:max-line-length
        return -1 !== ['validation_1', 'questions_answers', 'validation_2', 'in_progress', 'completed'].indexOf(this.currentResource.data.marking);
    }

    /**
     * Determinate if switch user must be used for transition "validate_answers".
     * Switch user is only possible if current marking is "questions_answers".
     */
    public validateAnswersSwitchUser(): boolean {
        return -1 !== ['draft', 'validation_1', 'validation_2', 'in_progress', 'completed'].indexOf(this.currentResource.data.marking);
    }

    /**
     * Returns URL of project / program user form
     */
    public getImpersonateUrl(): string {
        if (ResourceUtil.isProgram(this.currentResource)) {
            return '/sessions/' + this.tenderSessionId + '/programs/' + this.folderId;
        }

        return '/sessions/' + this.tenderSessionId + '/projects/' + this.folderId;
    }

    private addOrRemoveExplanationControl(): void {
        this.form.get('transition').valueChanges.pipe(takeUntil(this.destroySubject)).subscribe((transition: string) => {
            if (transition === 'complete') {
                this.form.addControl('explanation', this.fb.control(null, [Validators.required]));
            } else if (this.form.contains('explanation')) {
                this.form.removeControl('explanation');
            }
        });
    }
}
