<div [formGroup]="form">

    <!-- General -->
    <app-card cardNumber="1" cardTitle="title.general">
        <div class="table-scroll">
            <table class="table table-responsive w-100" appTableCellHighlighter>
                <thead>
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <th class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ 'workflow.' + step.columnName | translate }}
                        </th>
                    </ng-container>
                </tr>
                </thead>
                <tbody>
                <tr formGroupName="currentProgram">
                    <th>{{ 'word.name' | translate }}</th>
                    <ng-container *ngFor="let folder of folderSteps">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view"
                            [class.input]="folder['@permissions'].edit">
                            <ng-container *ngIf="!folder['@permissions'].edit">
                                {{ folder.name }}
                            </ng-container>

                            <ng-container *ngIf="folder['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="name"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error *ngIf="form.controls['currentProgram'].get('name').hasError('required')">
                                        {{ 'validator.project_name.not_blank' | translate }}
                                    </mat-error>
                                    <mat-error
                                            *ngIf="form.controls['currentProgram'].get('name').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr formGroupName="currentProgram">
                    <th>{{ 'word.acronym' | translate }}</th>
                    <ng-container *ngFor="let folder of folderSteps">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view">
                            <ng-container *ngIf="!folder['@permissions'].edit">
                                {{ folder.acronym }}
                            </ng-container>

                            <ng-container *ngIf="folder['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="acronym"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.acronym }}">
                                    <mat-error
                                            *ngIf="form.controls['currentProgram'].get('acronym').hasError('required')">
                                        {{ 'validator.project_name.not_blank' | translate }}
                                    </mat-error>
                                    <mat-error
                                            *ngIf="form.controls['currentProgram'].get('acronym').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.acronym} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.reference' | translate }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ step.reference }}
                        </td>
                    </ng-container>
                </tr>
                <tr formGroupName="currentProgram">
                    <th>{{ 'word.start_date' | translate }}</th>
                    <ng-container *ngFor="let folder of folderSteps">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view"
                            [class.input]="folder['@permissions'].edit">
                            <ng-container *ngIf="!folder['@permissions'].edit">
                                {{ folder.startDate | localizedDate }}
                            </ng-container>

                            <ng-container *ngIf="folder['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           [matDatepicker]="startDatePicker"
                                           formControlName="startDate"
                                           [min]="startDateMin"
                                           type="text"
                                           autocomplete="off">
                                    <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #startDatePicker></mat-datepicker>
                                    <mat-error
                                            *ngIf="form.controls['currentProgram'].get('startDate').hasError('matDatepickerParse')">
                                        {{ 'validator.startDate.not_valid' | translate }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr formGroupName="currentProgram">
                    <th>{{ 'word.end_date' | translate }}</th>
                    <ng-container *ngFor="let folder of folderSteps">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view"
                            [class.input]="folder['@permissions'].edit">
                            <ng-container *ngIf="!folder['@permissions'].edit">
                                {{ folder.endDate | localizedDate }}
                            </ng-container>

                            <ng-container *ngIf="folder['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           [matDatepicker]="endDatePicker"
                                           formControlName="endDate"
                                           [min]="form.get('currentProgram.startDate').value || startDateMin"
                                           type="text"
                                           autocomplete="off">
                                    <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
                                    <mat-datepicker #endDatePicker></mat-datepicker>
                                    <mat-error
                                            *ngIf="form.controls['currentProgram'].get('endDate').hasError('matDatepickerParse')">
                                        {{ 'validator.end_date.not_valid' | translate }}
                                    </mat-error>
                                    <mat-error
                                            *ngIf="form.controls['currentProgram'].get('endDate').hasError('matDatepickerMin')">
                                        {{ 'validator.end_date.min_value' | translate:{min: form.controls['currentProgram'].get('endDate').getError('matDatepickerMin').min} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr formGroupName="currentProgram">
                    <th>{{'word.length_in_month' | translate}}</th>
                    <ng-container *ngFor="let folder of folderSteps">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view"
                            [class.input]="folder['@permissions'].edit">
                            <ng-container *ngIf="!folder['@permissions'].edit">
                                {{ countInMonths(folder.startDate, folder.endDate) }}
                            </ng-container>
                            <ng-container *ngIf="folder['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="lengthInMonth"
                                           [disabled]="true" [readonly]="true"/>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr formGroupName="currentProgram">
                    <th>{{'word.sector' | translate}}</th>
                    <ng-container *ngFor="let folder of folderSteps">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view"
                            [class.input]="folder['@permissions'].edit">
                            <ng-container *ngIf="!folder['@permissions'].edit">
                                {{ 'sectors.' + folder.sector | translate }}
                            </ng-container>
                            <ng-container *ngIf="folder['@permissions'].edit">
                                <mat-select formControlName="sector">
                                    <mat-option *ngFor="let sector of sectors"
                                                [value]="sector">{{'sectors.' + sector | translate}}</mat-option>
                                </mat-select>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr formGroupName="currentProgram">
                    <th>{{'word.geographic_target' | translate}}</th>
                    <ng-container *ngFor="let folder of folderSteps">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view"
                            [class.input]="folder['@permissions'].edit">
                            <ng-container *ngIf="!folder['@permissions'].edit">
                                {{ 'geographic_target.' + folder.geographicTarget | translate }}
                            </ng-container>
                            <ng-container *ngIf="folder['@permissions'].edit">
                                <mat-select formControlName="geographicTarget">
                                    <mat-option *ngFor="let target of geographicTargets"
                                                [value]="target">{{'geographic_target.' + target | translate}}</mat-option>
                                </mat-select>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr formGroupName="currentProgram">
                    <th>{{'word.geographic_target_specifications' | translate}}</th>
                    <ng-container *ngFor="let folder of folderSteps">
                        <td class="min-w-120" *ngIf="folder['@permissions'].view"
                            [class.input]="folder['@permissions'].edit">
                            <ng-container *ngIf="!folder['@permissions'].edit">
                                {{ folder.geographicTargetSpecifications }}
                            </ng-container>
                            <ng-container *ngIf="folder['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="geographicTargetSpecifications"/>
                                    <mat-error
                                            *ngIf="form.controls['currentProgram'].get('geographicTargetSpecifications').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.longString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr formGroupName="currentProgram">
                    <th>{{ 'word.description' | translate }}</th>
                    <ng-container *ngFor="let subFolder of folderSteps">
                        <td class="min-w-120" *ngIf="subFolder['@permissions'].view"
                            [class.input]="subFolder['@permissions'].edit">
                            <ng-container *ngIf="!subFolder['@permissions'].edit">
                                {{ subFolder.description }}
                            </ng-container>

                            <ng-container *ngIf="subFolder['@permissions'].edit">
                                <mat-form-field>
                                        <textarea matInput
                                                  formControlName="description"
                                                  cdkTextareaAutosize
                                                  cdkAutosizeMinRows="5"
                                                  maxlength="{{ lengthValidators.longString }}"></textarea>
                                    <mat-error
                                            *ngIf="form.controls['currentProgram'].get('description').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.longString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>
    </app-card>


    <!-- Supporting Organization -->
    <app-card cardNumber="2" cardTitle="word.supporting_organisation">
        <div class="table-scroll">
            <table class="table table-responsive col-md-12" formGroupName="supportingOrganization"
                   appTableCellHighlighter>
                <thead class="col-md-12">
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let step of folderProgram.organizationSteps">
                        <th class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ 'workflow.' + step['@columnName'] | translate }}
                        </th>
                    </ng-container>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th>{{ 'word.company_name' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgram.organizationSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.companyName | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="companyName"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['supportingOrganization'].get('companyName').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{'word.legal_form' | translate}}</th>
                    <ng-container *ngFor="let step of folderProgram.organizationSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ 'legal_forms.' + step.legalForm | translate }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <mat-select formControlName="legalForm">
                                        <mat-option *ngFor="let legalForm of legalForms"
                                                    [value]="legalForm">{{'legal_forms.' + legalForm | translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.address' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgram.organizationSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.address | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="address"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['supportingOrganization'].get('address').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.postal_code' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgram.organizationSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.postalCode | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="postalCode"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.postCode }}">
                                    <mat-error
                                            *ngIf="form.controls['supportingOrganization'].get('postalCode').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.postCode} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.city' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgram.organizationSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.city | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="city"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['supportingOrganization'].get('city').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.email' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgram.organizationSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.email | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="email"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['supportingOrganization'].get('email').hasError('pattern')">
                                        {{ 'validator.email.not_valid' | translate }}
                                    </mat-error>
                                    <mat-error
                                            *ngIf="form.controls['supportingOrganization'].get('email').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.phone' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgram.organizationSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.phone | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="phone"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['supportingOrganization'].get('phone').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.mobile_phone' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgram.organizationSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.mobilePhone | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="mobilePhone"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['supportingOrganization'].get('mobilePhone').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{'word.website' | translate}}</th>
                    <ng-container *ngFor="let step of folderProgram.organizationSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.website | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="website"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>
    </app-card>

    <!-- Project holder -->
    <app-card cardNumber="3" cardTitle="title.project_holder">
        <div class="table-scroll">
            <table class="table table-responsive col-md-12" formGroupName="folderHolder" appTableCellHighlighter>
                <thead class="col-md-12">
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let step of folderProgram.holderSteps">
                        <th class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ 'workflow.' + step['@columnName'] | translate }}
                        </th>
                    </ng-container>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th>{{ 'word.company_name' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgram.holderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.companyName | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="companyName"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['folderHolder'].get('companyName').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.first_name' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgram.holderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.firstName | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="firstName"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['folderHolder'].get('firstName').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.last_name' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgram.holderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.lastName | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="lastName"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['folderHolder'].get('lastName').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.email' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgram.holderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.email | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="email"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error *ngIf="form.controls['folderHolder'].get('email').hasError('pattern')">
                                        {{ 'validator.email.not_valid' | translate }}
                                    </mat-error>
                                    <mat-error *ngIf="form.controls['folderHolder'].get('email').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.phone' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgram.holderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.phone | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="phone"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error *ngIf="form.controls['folderHolder'].get('phone').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.mobile_phone' | translate }}</th>
                    <ng-container *ngFor="let step of folderProgram.holderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view"
                            [class.input]="step['@permissions'].edit">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.mobilePhone | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           formControlName="mobilePhone"
                                           type="text"
                                           autocomplete="off"
                                           maxlength="{{ lengthValidators.genString }}">
                                    <mat-error
                                            *ngIf="form.controls['folderHolder'].get('mobilePhone').hasError('maxlength')">
                                        {{ 'validator.field.too_long' | translate: {max: lengthValidators.genString} }}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>
    </app-card>

    <app-card cardNumber="4" cardTitle="word.fix_costs">
        <div class="table-scroll">
            <table class="table table-responsive col-md-12" formGroupName="currentProgram" appTableCellHighlighter>
                <thead class="col-md-12">
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <th class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ 'workflow.' + step['@columnName'] | translate }}
                        </th>
                    </ng-container>
                </tr>
                </thead>
                <tbody formArrayName="fixCosts" *ngIf="computedResume.fixCosts.length">
                <!-- Wait for fixCosts to be calculated before we show them -->
                <ng-container *ngFor="let fixCost of folderProgram.data.currentProgram.fixCosts; let i = index"
                              [formGroupName]="i">
                    <tr> <!-- Fix Cost Title -->
                        <ng-container *ngFor="let step of folderSteps; let first = first">
                            <th *ngIf="step['@permissions'].view"><span *ngIf="first"
                                                                        class="font-weight-bold">{{'fix_costs.' + fixCost.groupName | translate}}</span>
                            </th>
                        </ng-container>
                    </tr>
                    <tr formGroupName="cost">
                        <th>{{ 'word.unit_number' | translate }}</th>
                        <ng-container *ngFor="let step of folderSteps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view"
                                [class.input]="step['@permissions'].edit">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{step.fixCosts[i].cost.units | default:'-'}}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    <mat-form-field>
                                        <input matInput
                                               formControlName="units"
                                               type="number"
                                               autocomplete="off"
                                               (change)="executeCalculation()"/>
                                    </mat-form-field>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr formGroupName="cost">
                        <th>{{ 'word.unit_price' | translate }}</th>
                        <ng-container *ngFor="let step of folderSteps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view"
                                [class.input]="step['@permissions'].edit">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{step.fixCosts[i].cost.unitPrice | currency:'CHF':'symbol':'0.0-2' | default:'-'}}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    <mat-form-field>
                                        <input matInput
                                               formControlName="unitPrice"
                                               type="number"
                                               autocomplete="off"
                                               (change)="executeCalculation()"/>
                                    </mat-form-field>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{ 'word.total_cost' | translate }}</th>
                        <ng-container *ngFor="let step of folderSteps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view"
                                [class.input]="step['@permissions'].edit">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{step.fixCosts[i].computedValues.totalCost | currency:'CHF':'symbol':'0.0-2' | default:'-'}}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{computedResume?.fixCosts[i]?.totalCost | currency:'CHF':'symbol':'0.0-2' | default:'-'}}
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr formGroupName="cost">
                        <th>{{ 'word.total_requested_contribution' | translate }}</th>
                        <ng-container *ngFor="let step of folderSteps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view"
                                [class.input]="step['@permissions'].edit">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{step.fixCosts[i].cost.requestedContribution | currency:'CHF':'symbol':'0.0-2' | default:'-'}}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    <mat-form-field>
                                        <input matInput
                                               formControlName="requestedContribution"
                                               type="number"
                                               autocomplete="off"
                                               (change)="executeCalculation()"/>
                                    </mat-form-field>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{ 'word.other_contribution' | translate }}</th>
                        <ng-container *ngFor="let step of folderSteps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view"
                                [class.input]="step['@permissions'].edit">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{step.fixCosts[i].computedValues.thirdPartyContribution | currency:'CHF':'symbol':'0.0-2' | default:'-'}}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{computedResume?.fixCosts[i]?.otherContribution | currency:'CHF':'symbol':'0.0-2' | default:'-'}}
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </div>
    </app-card>

    <!-- Measures -->
    <app-card cardNumber="5" cardTitle="title.measures">
        <ng-container *ngFor="let measure of measures; let index = index" formGroupName="currentProgram">
            <div class="table-scroll mt-4">
                <h3 class="mb-1">{{ 'word.measure_n' | translate:{count: index + 1} }}</h3>

                <!-- ngIf used to wait for form creation -->
                <table class="table table-responsive w-100" appTableCellHighlighter *ngIf="form.get('currentProgram.measures')['controls'][index]">
                    <thead>
                    <tr>
                        <th class="w-25">{{ '' }}</th>
                        <ng-container *ngFor="let step of folderSteps">
                            <th class="min-w-120" *ngIf="step['@permissions'].view">
                                {{ 'workflow.' + step.columnName | translate }}
                            </th>
                        </ng-container>
                    </tr>
                    </thead>
                    <tbody formArrayName="measures" *ngIf="computedResume.measures.length">
                    <tr>
                        <th>{{ 'word.technical_orientation' | translate }}</th>
                        <ng-container *ngFor="let step of folderSteps">
                            <td *ngIf="step['@permissions'].view">
                                {{ step.measures[index].technicalOrientation.name | default:'-' }}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{ 'word.description' | translate }}</th>
                        <ng-container *ngFor="let step of folderSteps">
                            <td *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.measures[index].description | default:'-' }}
                                </ng-container>

                                <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                    <div class="dataset-measure fields-group" formGroupName="datasetMeasure">
                                        <mat-form-field>
                                            <input matInput
                                                   formControlName="description"
                                                   type="text"
                                                   autocomplete="off"/>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{ 'word.duration_of_use' | translate }}</th>
                        <ng-container *ngFor="let step of folderSteps">
                            <td *ngIf="step['@permissions'].view">
                                {{ 'word.n_years' | translate:{count: step.measures[index].durationOfUse | default:'-'} }}
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{ 'word.unit_number' | translate }}</th>
                        <ng-container *ngFor="let step of folderSteps">
                            <td *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.measures[index].units | default:'-' }}
                                </ng-container>

                                <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                    <div class="dataset-measure fields-group" formGroupName="datasetMeasure">
                                        <mat-form-field>
                                            <input matInput
                                                   formControlName="units"
                                                   type="number"
                                                   autocomplete="off"
                                                   min="{{ lengthValidators.positiveValue }}"
                                                   (change)="executeCalculation()"/>
                                        </mat-form-field>
                                        <mat-error
                                                *ngIf="form.get('currentProgram.measures')['controls'][index].get('datasetMeasure.units').hasError('required')">
                                            {{ 'validator.field.not_blank' | translate }}
                                        </mat-error>
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{ 'word.unit_price' | translate }}</th>
                        <ng-container *ngFor="let step of folderSteps">
                            <td *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit && step.measures[index]">
                                    {{ step.measures[index].cost | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                                </ng-container>

                                <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                    <div class="dataset-measure" formGroupName="datasetMeasure">
                                        <mat-form-field>
                                            <input matInput
                                                   formControlName="cost"
                                                   type="number"
                                                   autocomplete="off"
                                                   min="{{ lengthValidators.minCost }}"
                                                   (change)="executeCalculation()">
                                            <span class="mat-input-suffix"
                                                  matSuffix>{{ 'word.chf' | translate }}</span>
                                            <mat-error
                                                    *ngIf="form.get('currentProgram.measures')['controls'][index].get('datasetMeasure.cost').hasError('required')">
                                                {{ 'validator.cost.not_blank' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{'word.requested_contribution' | translate}} / {{'word.unit' | translate}}</th>
                        <ng-container *ngFor="let step of folderSteps">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.measures[index].requestedContribution | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                                </ng-container>

                                <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                    <div class="dataset-measure fields-group" formGroupName="datasetMeasure">
                                        <mat-form-field>
                                            <input matInput
                                                   formControlName="requestedContribution"
                                                   type="number"
                                                   autocomplete="off"
                                                   min="{{ lengthValidators.positiveValue }}"
                                                   (change)="executeCalculation()"/>
                                            <span class="mat-input-suffix"
                                                  matSuffix>{{ 'word.chf' | translate }}</span>
                                            <mat-error
                                                    *ngIf="form.get('currentProgram.measures')['controls'][index].get('datasetMeasure.requestedContribution').hasError('required')">
                                                {{ 'validator.field.not_blank' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{ 'word.existing_annual_consumption' | translate }}
                            / {{'word.unit' | translate  | lowercase}}</th>

                        <ng-container *ngFor="let step of folderSteps">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.measures[index].existingAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                                </ng-container>

                                <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                    <div class="dataset-measure fields-group" formGroupName="datasetMeasure">
                                        <mat-form-field>
                                            <input matInput
                                                   formControlName="existingAnnualConsumption"
                                                   type="number"
                                                   autocomplete="off"
                                                   min="{{ lengthValidators.positiveValue }}"
                                                   (change)="executeCalculation()"/>
                                            <span class="mat-input-suffix"
                                                  matSuffix>{{ 'word.kwh' | translate }}</span>
                                            <mat-error
                                                    *ngIf="form.get('currentProgram.measures')['controls'][index].get('datasetMeasure.existingAnnualConsumption').hasError('required')">
                                                {{ 'validator.field.not_blank' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{ 'word.future_annual_consumption' | translate }}
                            / {{'word.unit' | translate  | lowercase}}</th>

                        <ng-container *ngFor="let step of folderSteps">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.measures[index].futureAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}
                                </ng-container>

                                <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                    <div class="dataset-measure fields-group" formGroupName="datasetMeasure">
                                        <mat-form-field>
                                            <input matInput
                                                   formControlName="futureAnnualConsumption"
                                                   type="number"
                                                   autocomplete="off"
                                                   min="{{ lengthValidators.positiveValue }}"
                                                   (change)="executeCalculation()"/>
                                            <span class="mat-input-suffix"
                                                  matSuffix>{{ 'word.kwh' | translate }}</span>
                                            <mat-error
                                                    *ngIf="form.get('currentProgram.measures')['controls'][index].get('datasetMeasure.futureAnnualConsumption').hasError('required')">
                                                {{ 'validator.field.not_blank' | translate }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{ 'word.annual_saving' | translate }} / {{'word.unit' | translate  | lowercase}}</th>

                        <ng-container *ngFor="let step of folderSteps">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.measures[index].annualSavings | number | default: '-': ('word.kwh' | translate) }}
                                </ng-container>

                                <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                    {{ computedResume.measures[index].economyAnnual | positive | number | default: '-': ('word.kwh' | translate) }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{ 'word.total_saving' | translate }} / {{'word.unit' | translate  | lowercase}}</th>

                        <ng-container *ngFor="let step of folderSteps">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.measures[index].totalSavings | number | default: '-': ('word.kwh' | translate) }}
                                </ng-container>

                                <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                    {{ computedResume.measures[index].economyTotal | positive | number | default: '-': ('word.kwh' | translate) }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{ 'word.measure_annual_savings' | translate }}</th>

                        <ng-container *ngFor="let step of folderSteps">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.measures[index].units * step.measures[index].annualSavings | number | default: '-': ('word.kwh' | translate) }}
                                </ng-container>

                                <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                    {{ computedResume.measures[index].measureAnnualEconomy | positive | number | default: '-': ('word.kwh' | translate) }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{ 'word.measure_total_savings' | translate }}</th>

                        <ng-container *ngFor="let step of folderSteps">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.measures[index].units * step.measures[index].totalSavings | number | default: '-': ('word.kwh' | translate) }}
                                </ng-container>

                                <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                    {{ computedResume.measures[index].measureTotalEconomy | positive | number | default: '-': ('word.kwh' | translate) }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{'word.total_cost' | translate}}</th>

                        <ng-container *ngFor="let step of folderSteps">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.measures[index].units * step.measures[index].cost | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                                </ng-container>

                                <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                    {{ computedResume.measures[index].totalCost | currency:'CHF':'symbol':'0.0-2' | default:'-'}}
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{'word.total_contribution' | translate}}</th>

                        <ng-container *ngFor="let step of folderSteps">
                            <td *ngIf="step['@permissions'].view && step.measures[index]">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.measures[index].units * step.measures[index].requestedContribution | currency:'CHF':'symbol':'0.0-2' | default:'-'}}
                                </ng-container>

                                <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                    {{ computedResume.measures[index].totalRequestedContribution | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </app-card>


    <!-- Analysis costs -->
    <app-card cardNumber="6" cardTitle="word.analysis_costs">
        <div class="table-scroll">
            <table class="table table-responsive col-md-12" formGroupName="currentProgram" appTableCellHighlighter>
                <thead class="col-md-12">
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <th class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ 'workflow.' + step['@columnName'] | translate }}
                        </th>
                    </ng-container>
                </tr>
                </thead>
                <tbody formGroupName="analysisCosts" *ngIf="computedResume.analysisCosts">
                <tr>
                    <th>{{'word.amount' | translate}}</th>

                    <ng-container *ngFor="let step of folderSteps">
                        <td *ngIf="step['@permissions'].view">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.analysisCosts.units }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           type="number"
                                           formControlName="units"
                                           (change)="executeCalculation()"/>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{'word.unit_price' | translate}}</th>

                    <ng-container *ngFor="let step of folderSteps">
                        <td *ngIf="step['@permissions'].view">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.analysisCosts.unitPrice | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           type="number"
                                           formControlName="unitPrice"
                                           (change)="executeCalculation()"/>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{'word.total_cost' | translate}}</th>

                    <ng-container *ngFor="let step of folderSteps">
                        <td *ngIf="step['@permissions'].view">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.analysisCosts.units * step.analysisCosts.unitPrice | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                {{computedResume.analysisCosts.totalCost | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{'word.requested_contribution' | translate}}</th>

                    <ng-container *ngFor="let step of folderSteps">
                        <td *ngIf="step['@permissions'].view">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.analysisCosts.requestedContribution | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                <mat-form-field>
                                    <input matInput
                                           type="number"
                                           formControlName="requestedContribution"
                                           (change)="executeCalculation()"/>
                                </mat-form-field>
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{'word.total_requested_contribution' | translate}} / {{'word.unit' | translate}}</th>

                    <ng-container *ngFor="let step of folderSteps">
                        <td *ngIf="step['@permissions'].view">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.analysisCosts.units * step.analysisCosts.requestedContribution | currency:'CHF':'symbol':'0.0-2' | default:'-'  }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                {{ computedResume.analysisCosts.prokilowattTotalContribution | currency:'CHF':'symbol':'0.0-2' | default:'-'}}
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{'word.subsidy_rate' | translate}}</th>

                    <ng-container *ngFor="let step of folderSteps">
                        <td *ngIf="step['@permissions'].view">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ (step.analysisCosts.units * step.analysisCosts.requestedContribution) / (step.analysisCosts.units * step.analysisCosts.unitPrice) * 100 | round:2 }}
                                %
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                {{ computedResume.analysisCosts.subsidyRate | round:2 }} %
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>
    </app-card>

    <app-card cardNumber="7" cardTitle="title.summary">
        <div class="table-scroll">
            <table class="table table-responsive col-md-12" formGroupName="currentProgram" appTableCellHighlighter>
                <thead class="col-md-12">
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <th class="min-w-120" *ngIf="step['@permissions'].view">
                            {{ 'workflow.' + step['@columnName'] | translate }}
                        </th>
                    </ng-container>
                </tr>
                </thead>
                <tbody *ngIf="computedResume.summary">
                <tr>
                    <th>{{ 'word.contribution_admin_management' | translate }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.computedValues.summary.adminManagementContribution | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                {{ computedResume.summary.adminManagementContribution | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.cost_admin_management' | translate }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.computedValues.summary.adminManagementCost | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                {{ computedResume.summary.adminManagementCost | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.contribution_admin_accompaniment' | translate }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.computedValues.summary.adminAccompanimentContribution | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                {{ computedResume.summary.adminAccompanimentContribution | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.cost_admin_accompaniment' | translate }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.computedValues.summary.adminAccompanimentCost | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                {{ computedResume.summary.adminAccompanimentCost | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.contribution_admin_total' | translate }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.computedValues.summary.adminTotalContribution | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                {{ computedResume.summary.adminTotalContribution | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.cost_admin_total' | translate }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.computedValues.summary.adminTotalCost | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                {{ computedResume.summary.adminTotalCost | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.contribution_measures' | translate }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.computedValues.summary.measuresContribution | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                {{ computedResume.summary.measuresContribution | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.cost_measures' | translate }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.computedValues.summary.measuresCost | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                {{ computedResume.summary.measuresCost | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.requested_contribution' | translate }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.computedValues.requestedContribution | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                {{ computedResume.requestedContribution | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.total_cost' | translate }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.computedValues.summary.totalCost | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                {{ computedResume.summary.totalCost | currency:'CHF':'symbol':'0.0-2' }}
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.total_saving' | translate }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.computedValues.totalSaving | positive | number }} {{ 'word.kwh' | translate }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                {{ computedResume.totalSaving | positive | number }} {{ 'word.kwh' | translate }}
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.cost_utility_ratio' | translate }}</th>
                    <ng-container *ngFor="let step of folderSteps">
                        <td class="min-w-120" *ngIf="step['@permissions'].view">
                            <ng-container *ngIf="!step['@permissions'].edit">
                                {{ step.computedValues.costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}
                            </ng-container>

                            <ng-container *ngIf="step['@permissions'].edit">
                                {{ computedResume.costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}
                            </ng-container>
                        </td>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>
    </app-card>

    <app-card cardNumber="8" cardTitle="title.documents">
        <table class="table w-100">
            <thead>
            <tr>
                <th class="w-25">{{ '' }}</th>
                <ng-container *ngFor="let step of folderSteps; let childFolderIndex = index;">
                    <th class="min-w-120" *ngIf="step['@permissions'].view">
                        {{ 'workflow.' + step.columnName | translate }}

                        <div class="add-media-object" *ngIf="step['@permissions'].edit">
                            <button mat-mini-fab color="accent" (click)="addMediaObject(childFolderIndex)">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </th>
                </ng-container>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td class="table-inactive-cell"></td>
                <ng-container *ngFor="let step of folderSteps; let childFolderIndex = index;">
                    <td class="min-w-120" *ngIf="step['@permissions'].view">
                        <mat-list dense role="list">
                            <mat-list-item role="listitem"
                                           *ngFor="let mediaObject of step.mediaObjects; let mediaObjectIndex = index">
                                <span class="mat-small" mat-line>{{ mediaObject.originalName }}</span>
                                <button mat-icon-button *appIsGranted="'ROLE_STAFF_PKW'" appConfirmDialog
                                        (confirmClick)="deleteMediaObject(mediaObject, childFolderIndex, mediaObjectIndex)">
                                    <mat-icon class="mat-16">delete_outline</mat-icon>
                                </button>
                                <button mat-icon-button (click)="downloadMediaObject(mediaObject)">
                                    <mat-icon class="mat-16">get_app</mat-icon>
                                </button>
                            </mat-list-item>
                        </mat-list>
                    </td>
                </ng-container>
            </tr>
            </tbody>
        </table>
    </app-card>

    <!-- Analysis -->
    <app-card cardNumber="9" appAdminComponent cardTitle="title.analysis">
        <div class="row">
            <div class="col-md-8" formGroupName="analysis">
                <mat-form-field>
                    <mat-label>{{ 'word.decision' | translate }}</mat-label>
                    <textarea matInput
                              formControlName="decision"
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="5"
                              #analysisDecision
                              [readonly]="!canEditAnalyse() || !isValidation2()"
                              maxlength="10000"></textarea>
                    <mat-hint align="end">{{ analysisDecision.value.length }} / 65000</mat-hint>
                    <mat-error *ngIf="form.get('analysis.decision').hasError('maxlength')">
                        {{ 'validator.field.too_long' | translate: {max: 65000} }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <table class="table w-100">
                    <thead>
                    <tr>
                        <th class="min-w-120">
                            {{ 'word.documents' | translate }}

                            <div class="add-media-object" *ngIf="canEdit()">
                                <button mat-mini-fab color="accent" (click)="addAnalysisMediaObject()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <mat-list dense role="list">
                                <mat-list-item role="listitem"
                                               *ngFor="let mediaObject of form.get('analysisMediaObjects').value; let i = index; last as last">
                                    <span class="mat-small" mat-line>{{ mediaObject.originalName }}</span>
                                    <button mat-icon-button appConfirmDialog *appIsGranted="'ROLE_STAFF_PKW'"
                                            (confirmClick)="deleteAnalysisMediaObject(mediaObject, i)">
                                        <mat-icon class="mat-16">delete_outline</mat-icon>
                                    </button>
                                    <button mat-icon-button (click)="downloadMediaObject(mediaObject)">
                                        <mat-icon class="mat-16">get_app</mat-icon>
                                    </button>
                                </mat-list-item>
                            </mat-list>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" formGroupName="analysis">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>{{ 'word.comment' | translate }}</mat-label>
                    <textarea matInput
                              formControlName="comment"
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="5"
                              #analysisComment
                              [readonly]="!canEditAnalyse()  || !isValidation2()"
                              maxlength="65000"></textarea>
                    <mat-hint align="end">{{ analysisComment.value.length }} / 65000</mat-hint>
                    <mat-error *ngIf="form.get('analysis.comment').hasError('maxlength')">
                        {{ 'validator.field.too_long' | translate: {max: 65000} }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </app-card>

    <!-- Remarks -->
    <app-admin-remark-card appAdminComponent cardNumber="10" [folder]="folderProgram"></app-admin-remark-card>

    <!-- Todos -->
    <app-todo [bottom]="isValidation2() ? '85px' : '25px'" [folder]="folderProgram"
              *appIsGranted="'ROLE_STAFF_PKW'"></app-todo>

    <app-sticky-footer *ngIf="isValidation2()">
        <div>
            <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="save()"
                    [loading]="loading.loading$ | async">
                {{ 'action.save' | translate }} <i class="icon-floppy-disk"></i>
            </button>
        </div>
        <div class="justify-content-end">
            <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="check()"
                    [loading]="loading.loading$ | async">
                {{ 'action.check' | translate }} <i class="icon-checked"></i>
            </button>
        </div>
    </app-sticky-footer>
</div>

<input #fileInput
       type="file"
       class="file-input hidden"
       multiple="multiple"
       (change)="onNewFileSelected($event)"/>
