import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { HtmlInputEvent } from '../../core/events/html-input-event';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { MediaObject } from '../../core/models/media-object.model';
import { DownloaderHelper } from '../../core/utils/download.util';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MediaObjectService } from '../../core/services/media-object.service';
import { IriUtil } from '../../core/utils/iri.util';

@Component({
    selector: 'app-media-objects-list',
    templateUrl: './media-objects-list.component.html',
    styleUrls: ['./media-objects-list.component.scss']
})
export class MediaObjectsListComponent {
    @ViewChild('fileInput') public fileInput: ElementRef;

    @Input()
    form: FormGroup;

    @Input() folderUuid: string;

    @Input() reportIri: string;

    @Output() savedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private mediaObjectService: MediaObjectService,
        private fb: FormBuilder
    ) { }

    getParamsWithFolderId(params: HttpParams): HttpParams {
        if (this.folderUuid) {
            params = params.set('folder', this.folderUuid);
        }
        if (this.reportIri) {
            params = params.set('report', IriUtil.extractId(this.reportIri));
        }
        return params;
    }

    onNewFileSelected(event: HtmlInputEvent): void {
        let params = new HttpParams().set('type', 'computor_2020_folder');
        params = this.getParamsWithFolderId(params);

        // stackoverflow.com/q/25333488/ (convert target.files to Array)
        for (const file of Array.prototype.slice.call(event.target.files)) {
            this.mediaObjectService.upload(file, params).subscribe((media: MediaObject) => {
                this.addMediaObjectGroup(media);
                this.savedEvent.emit(true);
            });
        }
    }

    addMediaObject(): void {
        const nativeElement = this.fileInput.nativeElement as HTMLElement;
        nativeElement.click();
    }

    downloadMediaObject(mediaObject: MediaObject): void {
        let params = new HttpParams();
        params = this.getParamsWithFolderId(params);

        this.mediaObjectService.download(mediaObject['@id'], params).subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        });
    }

    deleteMediaObject(mediaObject: MediaObject, mediaObjectIndex: number): void {
        this.mediaObjectService.delete(mediaObject['@id']).subscribe(() => {
            this.mediaObjects.removeAt(mediaObjectIndex);
            if (this.mediaObjects.controls.length === 0) {
                this.mediaObjects.markAsPristine();
            }
        });
    }

    addMediaObjectGroup(media: MediaObject): void {
        this.mediaObjects.push(this.createMediaObjectGroup(media));
        this.mediaObjects.markAsDirty();
    }

    get mediaObjects(): FormArray {
        return this.form.get('mediaObjects') as FormArray;
    }

    private createMediaObjectGroup(media: MediaObject): FormGroup {
        return this.fb.group({
            '@id': [media['@id'], []],
            originalName: [media.originalName, []],
        });
    }

}
