import { Component, ElementRef, Inject, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InvoiceForm } from '../../core/models/invoiceForm.model';
import { FormUtil } from '../../core/utils/form.util';
import { ProjectReportResource } from '../../core/models/project-report-resource';
import { MediaObjectService } from '../../core/services/media-object.service';
import { HtmlInputEvent } from '../../core/events/html-input-event';
import { HttpParams } from '@angular/common/http';
import { MediaObject } from '../../core/models/media-object.model';
import { InvoiceService } from '../../core/services/invoice.service';
import { Invoice } from '../../core/models/invoice.model';
import { LoadingService } from '../../core/services/loading.service';

@Component({
    selector: 'app-add-invoice-dialog',
    templateUrl: './add-invoice-dialog.component.html',
    styleUrls: ['./add-invoice-dialog.component.scss']
})
export class AddInvoiceDialogComponent implements OnInit, AfterViewInit {
    @ViewChild('fileInput') public fileInput: ElementRef;

    public hasSelectedFile = false;

    public form = this.invoiceForm.getInvoiceForm();

    constructor(
        public loading: LoadingService,
        public dialogRef: MatDialogRef<AddInvoiceDialogComponent>,
        private invoiceForm: InvoiceForm,
        @Inject(MAT_DIALOG_DATA) private data: {report: ProjectReportResource},
        private mediaObjectService: MediaObjectService,
        private invoiceService: InvoiceService
    ) { }

    ngOnInit(): void {
        if (!this.data.report) {
            // error
        } else {
            this.form.patchValue({report: `/reports/${this.data.report.data.id}`});
        }
    }

    ngAfterViewInit(): void {
        this.addMediaObject();
    }

    public addMediaObject(): void {
        const el = this.fileInput.nativeElement as HTMLInputElement;
        el.click();
    }

    public onFileSelected(event: HtmlInputEvent): void {
        let params = new HttpParams().set('type', 'computor_2020_folder');
        params = params.set('report', String(this.data.report.data.id));

        // stackoverflow.com/q/25333488/ (convert target.files to Array)
        for (const file of Array.prototype.slice.call(event.target.files)) {
            this.mediaObjectService.upload(file, params).subscribe((media: MediaObject) => {
                this.form.patchValue({name: media.originalName});
                this.form.patchValue({mediaObject: media['@id']});
                this.hasSelectedFile = true;
            });
        }
    }

    public save(): void {
        if (this.form.valid) {
            const data = this.form.value as Invoice;
            this.invoiceService.addNewInvoice(data).subscribe((invoice) => {
                this.dialogRef.close({
                    invoice
                });
            });
        } else {
            FormUtil.validateAllFormFields(this.form);
        }
    }
}
