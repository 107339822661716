<app-card classes="card-header py-4 p-2 mb-2">
    <div class="col-md-12 d-flex justify-content-between">
        <i class="header-icon icon-clipboard"></i>
        <button mat-flat-button class="mat-accent" (click)="gotoProjects()">
            {{ 'title.every_projects' | translate }}
        </button>
    </div>
    <div class="col-md-12 card-content d-flex justify-content-between mt-4">
        <p class="card-title">{{ 'menu.projects' | translate }}</p>
        <p class="number-badge">{{ dataSource.totalItems }}</p>
    </div>
</app-card>
<app-card classes="card-content overflow-x">
    <div *ngIf="(loading.loading$ | async)" class="w-100 d-flex align-items-center justify-content-center">
       <mat-spinner diameter="20"></mat-spinner>
    </div>
    <table mat-table [dataSource]="dataSource" id="table-projects" class="w-100 mat-elevation-z0" *ngIf="false === (loading.loading$ | async)">
        <ng-container matColumnDef="no">
            <th mat-header-cell *matHeaderCellDef>{{ 'word.reference' | translate }}</th>
            <td mat-cell *matCellDef="let element" [routerLink]="'/admin/projects/' + (element['@id'] | iri2id)">
                {{element.reference}}
            </td>
        </ng-container>
        <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef>{{ 'word.name' | translate }}</th>
            <td mat-cell *matCellDef="let element" class="truncate-cell" [routerLink]="'/admin/projects/' + (element['@id'] | iri2id)">
                {{element.currentProject?.name}}
            </td>
        </ng-container>
        <ng-container matColumnDef="state">
            <th mat-header-cell *matHeaderCellDef> {{ 'word.state' | translate }} </th>
            <td mat-cell *matCellDef="let element">
                <app-folder-status [state]="element.marking"></app-folder-status>
            </td>
        </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="text-right"></th>
        <td mat-cell *matCellDef="let element" class="text-right">
          <button mat-icon-button
                  [routerLink]="'/admin/projects/' + (element['@id'] | iri2id)"
                  [matTooltip]="'action.show' | translate">
            <i class="action-icon icon-eye"></i>
          </button>
        </td>
      </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</app-card>
