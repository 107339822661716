import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { COMPUTOR_NAMES, FolderService } from '../../../../../../core/services/folder.service';
import { Folder } from '../../../../../../core/models/folder.model';
import { IriUtil } from '../../../../../../core/utils/iri.util';
import { Measure } from '../../../../../../core/models/measure.model';
import { MarkingService } from '../../../../../../core/services/marking.service';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import { PageTitleService } from '../../../../../../core/services/page-title.service';
import { FolderStatusService } from '../../../../../../core/services/folder-status.service';
import { MenuService } from '../../../../../../core/services/menu.service';
import { ChildProject } from '../../../../../../core/models/child-folder.model';
import { MediaObject } from '../../../../../../core/models/media-object.model';
import { environment } from '../../../../../../../environments/environment';
import { DownloaderHelper } from '../../../../../../core/utils/download.util';
import { MediaObjectService } from '../../../../../../core/services/media-object.service';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { FolderProjectResource } from '../../../../../../core/models/folder-project-resource.model';
import { Step } from '../../../../../../core/models/step.model';
import { ReportColumn } from '../../../../../../core/models/report-column.model';

@Component({
    selector: 'app-folder-show',
    templateUrl: './project-show.component.html',
    styleUrls: ['./project-show.component.scss'],
})
export class ProjectShowComponent implements OnInit {
    public static computorName = COMPUTOR_NAMES.computor2023Big;

    public folderId;
    public folder: Folder;
    public folderProjectResource: FolderProjectResource;
    public measures: Measure[];
    public missingMarking: string[];

    public defaultCurrency: string;

    public mediaObjects$: MediaObject[];

    constructor(
        private menuService: MenuService,
        private title: PageTitleService,
        private folderStatusService: FolderStatusService,
        private route: ActivatedRoute,
        private folderService: FolderService,
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        private markingService: MarkingService,
        private mediaObjectService: MediaObjectService
    ) {
        this.defaultCurrency = environment.defaultCurrency;
    }

    ngOnInit(): void {
        this.folder = this.route.snapshot.data.folder as Folder;
        this.folder.projects = this.folder.projects.filter(p => p.marking !== 'completed'); // we do not wanna see completed column

        this.folderProjectResource = this.route.snapshot.data.folderProjectResource as FolderProjectResource;
        this.measures = this.folderProjectResource.data.projects[this.folderProjectResource.data.projects.length - 1].measures;
        this.missingMarking = this.markingService.getMissingMarking(this.folderProjectResource.data.projects);

        this.folderService.setProjectResource(this.folderProjectResource);
        this.fetchMediaObjects();

        this.folderStatusService.folderStatusChanged.emit(this.folderProjectResource.data.marking);

        const folderName = this.folder ?
            (this.folderProjectResource.data.reference ?
                this.folderProjectResource.data.reference + ' : ' : ''
            ) + this.folderProjectResource.data.currentProject.acronym :
            'word.new_project'
        ;
        this.breadcrumbService.clientFolderView(folderName);

        const menu = [{link: '/dashboard', label: 'menu.dashboard', icon: 'icon-home', subItems: []}, {
            link: null, label: folderName, icon: 'icon-clipboard', scrollTo: '#top', subItems: [
                { link: null, label: 'title.milestones', icon: 'icon-1', scrollTo: '#section1'},
                { link: null, label: 'title.project_holder', icon: 'icon-2', scrollTo: '#section2'},
                { link: null, label: 'title.project_beneficiary', icon: 'icon-3', scrollTo: '#section3'},
                { link: null, label: 'title.general', icon: 'icon-4', scrollTo: '#section4'},
                { link: null, label: 'title.measures', icon: 'icon-5', scrollTo: '#section5'},
                { link: null, label: 'word.official_documents', icon: 'icon-6', scrollTo: '#section6'},
            ]
        }];

        this.menuService.store(menu);
    }

    public showOrEditFolderStep(folder: ChildProject): void {
        if (folder.isCurrent && folder['@permissions'].edit) {
            void this.router.navigate([
                'sessions',
                IriUtil.extractId(this.folderProjectResource.data.tenderSession['@id']),
                'projects',
                IriUtil.extractId(this.folder['@id']),
            ]);
        }

        if (!folder['@permissions'].edit) {
            void this.router.navigate([
                'sessions',
                IriUtil.extractId(this.folderProjectResource.data.tenderSession['@id']),
                'folders',
                IriUtil.extractId(this.folder['@id']),
                'states',
                IriUtil.extractId(folder['@id']),
            ]);
        }
    }

    downloadMediaObject(mediaObject: MediaObject): void {
        const params = new HttpParams().set('folder', IriUtil.extractId(this.folder.uuid));
        this.mediaObjectService.download(mediaObject['@id'], params).subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        });
    }

    public countColspan(reportSteps: any[]): number {
        return reportSteps.filter((step: Step) => step['@permissions'].view).length;
    }

    public showOrEditReport(column: ReportColumn, item: Step): Promise<any> {
        if (item['@permissions'].edit) {
            return this.router.navigate([
                'sessions',
                IriUtil.extractId(this.folderProjectResource.data.tenderSession['@id']),
                'folders',
                IriUtil.extractId(this.folder['@id']),
                'reports',
                column['@id'],
                'edit'
            ]);
        }

        if (item['@permissions'].view) {
            return this.router.navigate([
                'sessions',
                IriUtil.extractId(this.folderProjectResource.data.tenderSession['@id']),
                'folders',
                IriUtil.extractId(this.folder['@id']),
                'reports',
                column['@id'],
                'states',
                item.id
            ]);
        }
    }

    private fetchMediaObjects(): void {
        if (this.folderProjectResource.data.mediaObjects.length > 0) {
            this.mediaObjects$ = this.folderProjectResource.data.mediaObjects;
        }
    }
}
