import { Component, Input, OnInit, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ComputedValues } from '../../../../../core/models/computed-values.model';
import { FolderProjectResource } from '../../../../../core/models/folder-project-resource.model';
import { Dataset } from '../../../../../core/models/dataset.model';
import { FolderService } from '../../../../../core/services/folder.service';

@Component({
    selector: 'app-computor2024Lights-project-summary-card',
    templateUrl: './summary-card.component.html',
    styleUrls: ['./summary-card.component.scss']
})
export class SummaryCardComponent implements OnInit, OnChanges {
    @Input()
    public folder: FolderProjectResource;

    @Input()
    public type: 'folder'|'report' = 'folder';

    public computedValues: ComputedValues;

    constructor(
        private cdr: ChangeDetectorRef,
        private folderService: FolderService
    ) { }

    ngOnInit(): void {
        this.ngOnChanges();
        this.cdr.detectChanges();
    }

    ngOnChanges(): void {
        if (!this.folder) {
            return;
        }

        if (this.type === 'report') {
            if (this.folderService.getReportResource(this.folder)) {
                this.computedValues = this.folderService.getReportResource(this.folder).data.currentReport.computedValues;
            }

            return;
        }

        this.computedValues = this.folder.data.currentProject.computedValues;
    }

    get currentDataset(): Dataset {
        if (this.type === 'report') {

            return this.folderService.getReportResource(this.folder).data.currentReport.dataset;
        }

        return this.folderService.getCurrentProjectOrProgram(this.folder).dataset;
    }
}
