import { Component, Input, OnInit, OnChanges, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { ProgramComputedValues } from '../../../../../core/models/computed-values.model';
import { FolderProgramResource } from '../../../../../core/models/folder-program-resource.model';
import { ProgramCalculationResults } from '../../../services/program-calculator.service';
import { ProgramReportResource } from '../../../../../core/models/program-report-resource';

@Component({
    selector: 'app-computor2022-program-report-summary-card',
    templateUrl: './report-summary-card.component.html',
    styleUrls: ['./report-summary-card.component.scss']
})
export class ProgramReportSummaryCardComponent implements OnInit, OnChanges {
    @Input()
    public reportMeasures: any;

    @Input()
    public computedValues: any;

    @Input()
    public folder: any;

    @Input()
    public validatedComputedValues: any|null;

    ngOnInit(): void {
        console.log(this.computedValues);
        console.log(this.validatedComputedValues);

    }

    ngOnChanges() {

        this.computedValues.summary.totalEngagedMeasures = 0;
        this.computedValues.summary.totalRealisedMeasures = 0;
        this.computedValues.summary.measureTotalSavings = 0;
        this.computedValues.summary.engagedTotalEconomy = 0;
        this.computedValues.summary.realisedTotalEconomy = 0;
        this.computedValues.summary.measuresCost = 0;

        if(this.validatedComputedValues)
        {
            this.computedValues.summary.adminAccompanimentBalance = this.validatedComputedValues.adminAccompanimentBalance;
            this.computedValues.summary.adminManagementBalance = this.validatedComputedValues.adminManagementBalance;
            this.computedValues.analysisCosts.validatedProkilowattTotalContribution = this.validatedComputedValues.analysisCosts.prokilowattTotalContribution;
        }else{
            this.computedValues.summary.measuresContribution = this.computedValues.summary.measuresContribution - this.computedValues.analysisCosts.prokilowattTotalContribution
            this.computedValues.analysisCosts.validatedProkilowattTotalContribution = this.computedValues.analysisCosts.balance + this.computedValues.analysisCosts.prokilowattTotalContribution;
        }

        if(this.reportMeasures.controls)
        {
            this.reportMeasures.controls.forEach(c => {
                let controls = c.controls.datasetMeasure.controls;
                this.computedValues.summary.totalEngagedMeasures += controls.engagedRequestedContribution.value;
                this.computedValues.summary.measuresCost += controls.totalCost.value;
                this.computedValues.summary.totalRealisedMeasures += controls.realisedRequestedContribution.value;
                this.computedValues.summary.measureTotalSavings += controls.measureTotalSavings.value;
                this.computedValues.summary.engagedTotalEconomy += controls.engagedTotalEconomy.value;
                this.computedValues.summary.realisedTotalEconomy += controls.realisedTotalEconomy.value;
            });
        }else{

            this.reportMeasures.forEach(c => {
                let controls = c.datasetMeasure;
                this.computedValues.summary.totalEngagedMeasures += controls.engagedRequestedContribution;
                this.computedValues.summary.totalRealisedMeasures += controls.realisedRequestedContribution;
                this.computedValues.summary.measureTotalSavings += controls.computedValues.totalSavingPerMeasure;
                this.computedValues.summary.engagedTotalEconomy += controls.engagedTotalEconomy;
                this.computedValues.summary.realisedTotalEconomy += controls.realisedTotalEconomy;
                this.computedValues.summary.measuresCost += controls.computedValues.totalCost;

            });
        }

    }


}
