import { Injectable } from '@angular/core';
import { ProjectCalculationResults } from '../../computor2020/services/project-calculator.service';
import { FolderForm } from './folderForm';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class ReportForm {
    public computedResume: ProjectCalculationResults =  {
        costUtilityRatio: 0,
        economyAnnual: 0,
        economyPercent: 0,
        economyTotal: 0,
        maximumContribution: 0,
        measures: [],
        paybackWithoutContribution: 0,
        paybackWithContribution: 150,
        requestedContribution: 0,
        subsidyRate: 0,
        totalCost: 0,
        costCtskwh: 0
    };

    public lengthValidators = this.folderForm.lengthValidators;

    public createMeasureGroup = this.folderForm.createMeasureGroup;

    constructor(
        private folderForm: FolderForm,
        private fb: FormBuilder
    ) { }

    public getDatasetFormGroup(): FormGroup {
        return this.fb.group({
            '@id': [null, []],
            subsidies: null,
            cost: [null,
                [Validators.min(this.lengthValidators.positiveValue), Validators.max(this.lengthValidators.maxCtsKwhCost)]],
            requestedContribution: [null, [Validators.min(this.lengthValidators.minRequestedContribution)]],
            maximumContribution: [null],
            thirdPartySubsidies: [null],
            initiatedSavings: [null],
            performedSavings: [null],
            costUtilityRatio: [null],
            fixedSubsidies: [null],
            variableSubsidies: [null],
            totalSavings: [null],
            annualSavings: [null],
            percentSavings: [null],
            totalCost: [null],
            paybackWithoutContribution: [null]
        });
    }

    public getReportForm(): FormGroup {
        return this.fb.group({
            '@id': null,
            id: null,
            currentProject: this.fb.group({
                '@id': null,
                measures: this.fb.array([
                    this.createMeasureGroup()
                ]),
                dataset: this.getDatasetFormGroup()
            }),
            currentReport: this.fb.group({
                '@id': null,
                reportMeasures: this.fb.array([
                    this.createMeasureGroup()
                ]),
                dataset: this.getDatasetFormGroup(),
                mediaObjects: this.fb.array([])
            }),
            // following properties are used in validation only
            analysisMediaObjects: this.fb.array([]),
            analysis: this.fb.group({
                decision: [null, []],
                comment: [null, []],
            }),
        });
    }
}
