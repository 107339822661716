import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

export const NOTIFICATION_SUCCESS = 'success';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    constructor(
        private readonly snackBar: MatSnackBar,
        private readonly zone: NgZone,
        private translateService: TranslateService
    ) {}

    default(message: string, action = null): MatSnackBarRef<any> {
        return this.show(message, action, {
            duration: 2000,
            panelClass: 'default-notification-overlay'
        });
    }

    info(message: string, action = null): MatSnackBarRef<any> {
        return this.show(message, action, {
            duration: 2000,
            panelClass: 'info-notification-overlay'
        });
    }
    success(message: string, action = null): MatSnackBarRef<any> {
        return this.show(message, action, {
            duration: 3000,
            panelClass: 'success-notification-overlay'
        });
    }

    warn(message: string, action = null): MatSnackBarRef<any> {
        return this.show(message, action, {
            duration: 3500,
            panelClass: 'warning-notification-overlay'
        });
    }

    error(message: string, action = null): MatSnackBarRef<any> {
        return this.show(message, action, {
            duration: 4000,
            panelClass: 'error-notification-overlay'
        });
    }

    private show(message: string, action = null, configuration: MatSnackBarConfig): MatSnackBarRef<any> {
        // Need to open snackBar from Angular zone to prevent issues with its position per
        // https://stackoverflow.com/questions/50101912/snackbar-position-wrong-when-use-errorhandler-in-angular-5-and-material
        return this.zone.run(() => this.snackBar.open(
            this.translateService.instant(message), null !== action ? this.translateService.instant('action.cancel') : action, configuration
        ));
    }
}
