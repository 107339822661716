import { FormGroup } from '@angular/forms';

export class TimeUtil {
    public static setMonthsFromCurrentProgram(form: FormGroup): void {
        const lengthControl = form.get('lengthInMonth');
        const startDate = new Date(form.get('startDate').value);
        const endDate = new Date(form.get('endDate').value);

        if (1970 === endDate.getFullYear()) { // no value
            lengthControl.setValue(0);
            return;
        }

        lengthControl.setValue(TimeUtil.countMonths(startDate, endDate));
    }

    public static countMonths(startDate: Date, endDate: Date): number {
        const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
        return Math.round(timeDiff / (2e3 * 3600 * 365.25));
    }
}
