import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ApiService } from './api.service';
import { Person } from '../models/person.model';
import { map, tap } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { User } from '../models/user.model';
import { HydraCollection } from '../models/hydra-collection.model';


@Injectable()
export class UserService {
    private cache: Map<string, HydraCollection> = new Map<string, HydraCollection>();

    constructor(
        private apiService: ApiService,
    ) {
    }

    /**
     * Update an existing Person
     *
     * @param iri    organization id url
     * @param body  User fields to update
     */
    public update(iri: string, body: User | Person): Observable<User> {
        return this.apiService.patch(iri, body).pipe(
            map((res) => res as User)
        );
    }

    /**
     * Register new user
     *
     * @param payload   Array of the user
     */
    public register(payload: Record<string, any>): Observable<any> {
        return this.apiService.post('/user_registration', payload);
    }



    /**
     *  (1) User input to send e-mail
     *
     * @param email form input
     */
    public askForResetLink(email: string): Observable<any> {
        const body = {
            email
        };
        return this.apiService.post('/ask_reset', body);
    }

    /**
     *  (2) Change user password via e-mail
     *
     * @param newPassword   form input
     * @param uuid          Hash stored in DB (sent by mail)
     */
    public setNewPassword(newPassword: string, uuid: string): Observable<any> {
        const body = {
            uuid,
            newPassword
        };
        return this.apiService.post('/reset_password', body);
    }

    /**
     * Return a user list that has the given role
     * @param role Search filter
     * @param enablePagination pagination is enabled ?
     */
    public getUserListByRole(role: string, enablePagination = 'true'): Observable<HydraCollection> {
        if (this.cache.has(role)) {
            return of(this.cache.get(role));
        }

        const params = new HttpParams()
            .set('pagination', enablePagination)
            .set('role.name', role);

        return this.apiService.get('/users', {params})
            .pipe(
                tap((res: HydraCollection) => this.cache.set(role, res))
            );
    }

    public getUser(userIri: string): Observable<Person> {
        return this.apiService.get(userIri) as Observable<Person>;
    }
}
