<form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
    <div class="row" formGroupName="currentReport">
        <div class="col-md-7">
            <app-card cardNumber="1" cardTitle="word.fix_costs" formArrayName="fixCosts">
                <h2>{{'word.program_management' | translate}}</h2>
                <div class="row" *ngFor="let group of fixCostsManagement; let i = index" [formGroupName]="i">
                    <app-program-report-fix-costs [form]="fixCostsManagement[i]" (executeCalculationEvent)="executeCalculation()"></app-program-report-fix-costs>
                </div>
                <h2>{{'word.program_accompaniment' | translate}}</h2>
                <div class="row" *ngFor="let group of fixCostsAccompaniment; let i = index">
                    <app-program-report-fix-costs [form]="fixCostsAccompaniment[i]" (executeCalculationEvent)="executeCalculation()"></app-program-report-fix-costs>
                </div>
            </app-card>

            <app-card cardNumber="2" cardTitle="title.measures" formArrayName="reportMeasures">
                <div *ngFor="let item of form.get('currentReport').get('reportMeasures')['controls']; let i = index;"
                     [formGroupName]="i">
                    <div class="dataset-measure fields-group" formGroupName="datasetMeasure">
                        <div class="header">
                            <div class="d-flex justify-content-between">
                                <div>
                                    <h2>{{ 'word.measure_n' | translate:{count: i + 1} }}</h2>
                                </div>
                                <div>
                                    <button class="float-left"
                                            tabindex="-1"
                                            mat-icon-button
                                            color="primary"
                                            type="button"
                                            (click)="removeMeasureGroup(i)">
                                        <mat-icon>highlight_off</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="content">
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field color="primary">
                                        <mat-select (selectionChange)="executeCalculation()"
                                                    formControlName="technicalOrientation"
                                                    placeholder="{{ 'word.technical_orientation' | translate }}"
                                        >
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="searchTechnicalOri"
                                                                       placeholderLabel="{{ 'word.search' | translate }}"
                                                                       noEntriesFoundLabel="{{'message.no_result'|translate}}">
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option
                                                *ngFor="let technicalOrientation of this.technicalOrientations"
                                                [value]="technicalOrientation['@id']">
                                                {{ technicalOrientation ? technicalOrientation.name : '-' }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field
                                        class="mat-form-field-appearance-legacy mat-form-field-disabled">
                                        <input matInput
                                               placeholder="{{ 'word.duration_of_use' | translate }}"
                                               formControlName="durationOfUse"
                                               [readonly]="true">
                                        <span class="mat-input-suffix"
                                              matSuffix>{{ 'word.years' | translate }}</span>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field>
                                        <input matInput type="number"
                                               placeholder="{{ 'word.engaged_requested_contribution' | translate }}"
                                               formControlName="engagedRequestedContribution" (change)="executeCalculation()">
                                        <span class="mat-input-suffix"
                                              matSuffix>{{ 'word.chf' | translate }}</span>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field>
                                        <input matInput type="number"
                                               placeholder="{{ 'word.realised_requested_contribution' | translate }}"
                                               formControlName="realisedRequestedContribution" (change)="executeCalculation()">
                                        <span class="mat-input-suffix"
                                              matSuffix>{{ 'word.chf' | translate }}</span>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field>
                                        <input matInput type="number"
                                               placeholder="{{ 'word.engaged_total_economy' | translate }}"
                                               formControlName="engagedTotalEconomy" (change)="executeCalculation()">
                                        <span class="mat-input-suffix"
                                              matSuffix>{{ 'word.kwh' | translate }}</span>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field>
                                        <input matInput type="number"
                                               placeholder="{{ 'word.realised_total_economy' | translate }}"
                                               formControlName="realisedTotalEconomy">
                                        <span class="mat-input-suffix"
                                              matSuffix>{{ 'word.kwh' | translate }}</span>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                    <mat-form-field class="mat-form-field-appearance-legacy mat-form-field-disabled">
                                        <input matInput type="number"
                                               placeholder="{{ 'word.total_economy_balance' | translate }}"
                                               formControlName="balanceTotalEconomy" [readonly]="true">
                                        <span class="mat-input-suffix"
                                              matSuffix>{{ 'word.kwh' | translate }}</span>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </app-card>

            <app-card cardNumber="3" cardTitle="word.analysis_costs" formGroupName="analysisCosts">
                <div class="row">
                    <mat-form-field class="col-md-6">
                        <mat-label>{{'word.realised_requested_contribution' | translate}}</mat-label>
                        <input matInput formControlName="totalRequestedContribution" type="number" autocomplete="off"
                               (change)="setAnalysisRequestedContributionFromTotal()"/>
                        <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
                    </mat-form-field>
                    <mat-form-field class="col-md-6 mat-form-field-appearance-legacy mat-form-field-disabled">
                        <mat-label>{{'word.balance' | translate}}</mat-label>
                        <input matInput formControlName="balance" type="number" autocomplete="off" [readonly]="true"/>
                        <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
                    </mat-form-field>
                </div>
            </app-card>
            <app-card cardTitle="title.documents" cardNumber="4">
                <app-media-objects-list [form]="currentReportForm"
                                        [reportIri]="programReportResource.data.currentReport.report['@id']"></app-media-objects-list>
            </app-card>
            <app-invoices-list [sectionNumber]="5" [report]="programReportResource"
                               [hideControls]="!reportNotCompleted"></app-invoices-list>
        </div>
        <div class="col-md-5">
            <app-sticky-container offsetTop="100">
                <!-- Summary -->
                <app-computor2025-program-summary-card
                    [computedValues]="computedResume"></app-computor2025-program-summary-card>
            </app-sticky-container>
        </div>
    </div>
</form>

<app-sticky-footer>
    <div class="justify-content-start">
        <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="save()"
                [loading]="loading.loading$ | async">
            {{ 'action.save' | translate }} <i class="icon-floppy-disk"></i>
        </button>

    </div>
    <div class="justify-content-end">
        <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="check()"
                [loading]="loading.loading$ | async">
            {{ 'action.check' | translate }} <i class="icon-checked"></i>
        </button>

        <button mat-flat-button color="primary" [disabled]="!isSubmittable" appMatButtonLoading
                [loading]="loading.loading$ | async" appConfirmDialog (confirmClick)="submit()"
                [dialogContent]="'dialog.confirm_submit_report.content'"
                [dialogConfirm]="'dialog.confirm_submit_project.confirm'"
                [dialogCancel]="'dialog.confirm_submit_project.cancel'">
            {{ 'action.submit_report' | translate }} <i class="icon-message"></i>
        </button>
    </div>
</app-sticky-footer>
