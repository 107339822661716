<ng-container [formGroup]="form">
    <!-- Summary -->
    <app-card cardNumber="1" formGroupName="currentReport" cardTitle="title.summary">
        <div formGroupName="dataset" class="table-scroll">
            <table class="table table-responsive w-100">
                <thead>
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                {{ 'workflow.' + step['@columnName'] | translate }}
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th>{{ 'word.requested_contribution' | translate }}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.dataset.requestedContribution | currency | default:'-' }}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    <mat-form-field>
                                        <input matInput
                                               formControlName="requestedContribution"
                                               type="number"
                                               autocomplete="off">
                                        <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>

                                        <mat-error
                                                *ngIf="form.controls['currentReport'].get('dataset').get('requestedContribution').hasError('min')">
                                            {{ 'validator.requested_contribution.min' | translate:{min: form.controls['currentReport'].get('dataset').get('requestedContribution').errors['min'].min} }}
                                        </mat-error>
                                    </mat-form-field>
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.cost_utility_ratio' | translate }}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step['computedValues'].costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}
                                </ng-container>
                                <ng-container *ngIf="step['@permissions'].edit">
                                    <mat-form-field>
                                        <input matInput
                                               formControlName="costUtilityRatio"
                                               type="number"
                                               autocomplete="off">
                                        <span class="mat-input-suffix"
                                              matSuffix>{{ 'word.cost_cts_kwh' | translate }}</span>

                                        <mat-error
                                                *ngIf="form.controls['currentReport'].get('dataset').get('requestedContribution').hasError('min')">
                                            {{ 'validator.requested_contribution.min' | translate:{min: form.controls['currentReport'].get('dataset').get('requestedContribution').errors['min'].min} }}
                                        </mat-error>
                                    </mat-form-field>
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{'word.third_party_subsidies' | translate}}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.dataset.thirdPartySubsidies | currency | default:'-' }}
                                </ng-container>
                                <ng-container *ngIf="step['@permissions'].edit">
                                    <mat-form-field>
                                        <input matInput
                                               formControlName="thirdPartySubsidies"
                                               type="number"
                                               autocomplete="off">
                                        <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
                                    </mat-form-field>
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{'word.fixed_subsidies' | translate}}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.dataset.fixedSubsidies | currency | default:'-' }}
                                </ng-container>
                                <ng-container *ngIf="step['@permissions'].edit">
                                    <mat-form-field>
                                        <input matInput
                                               formControlName="fixedSubsidies"
                                               type="number"
                                               autocomplete="off">
                                        <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
                                    </mat-form-field>
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{'word.variable_subsidies' | translate}}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.dataset.variableSubsidies | currency | default:'-' }}
                                </ng-container>
                                <ng-container *ngIf="step['@permissions'].edit">
                                    <mat-form-field>
                                        <input matInput
                                               formControlName="variableSubsidies"
                                               type="number"
                                               autocomplete="off">
                                        <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
                                    </mat-form-field>
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{'word.annual_saving' | translate}}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.computedValues.annualSaving | positive | number | default:0 }} {{ 'word.kwh' | translate }}
                                </ng-container>
                                <ng-container *ngIf="step['@permissions'].edit">
                                    <mat-form-field>
                                        <input matInput
                                               formControlName="annualSavings"
                                               type="number"
                                               autocomplete="off">
                                        <span class="mat-input-suffix" matSuffix>{{ 'word.kwh' | translate }}</span>
                                    </mat-form-field>
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{'word.total_saving' | translate}}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.computedValues.totalSaving | positive | number | default:0 }} {{ 'word.kwh' | translate }}
                                </ng-container>
                                <ng-container *ngIf="step['@permissions'].edit">
                                    <mat-form-field>
                                        <input matInput
                                               formControlName="totalSavings"
                                               type="number"
                                               autocomplete="off">
                                        <span class="mat-input-suffix" matSuffix>{{ 'word.kwh' | translate }}</span>
                                    </mat-form-field>
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{'word.initiated_savings' | translate}}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.dataset.initiatedSavings | positive | number | default:0 }} {{ 'word.kwh' | translate }}
                                </ng-container>
                                <ng-container *ngIf="step['@permissions'].edit">
                                    <mat-form-field>
                                        <input matInput
                                               formControlName="initiatedSavings"
                                               type="number"
                                               autocomplete="off">
                                        <span class="mat-input-suffix" matSuffix>{{ 'word.kwh' | translate }}</span>
                                    </mat-form-field>
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{'word.performed_savings' | translate}}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.dataset.performedSavings | positive | number | default:0 }} {{ 'word.kwh' | translate }}
                                </ng-container>
                                <ng-container *ngIf="step['@permissions'].edit">
                                    <mat-form-field>
                                        <input matInput
                                               formControlName="performedSavings"
                                               type="number"
                                               autocomplete="off">
                                        <span class="mat-input-suffix" matSuffix>{{ 'word.kwh' | translate }}</span>
                                    </mat-form-field>
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>
    </app-card>

    <!-- Measures -->
    <app-card cardNumber="2" cardTitle="title.measures_evolution">
        <!-- Measures -->
        <div formGroupName="currentReport">
            <ng-container *ngFor="let measure of measures; let i = index;">
                <div class="table-scroll mt-4" formGroupName="reportMeasures"
                     *ngIf="form.get('currentReport.reportMeasures')['controls'][i]">
                    <h3 class="mb-1">{{ 'word.measure_n' | translate:{count: i + 1} }}</h3>
                    <table class="table table-responsive w-100">
                        <thead>
                        <tr>
                            <th class="w-25">{{ '' }}</th>
                            <ng-container *ngFor="let column of folderProgram.reportSteps">
                                <ng-container *ngFor="let step of column.steps">
                                    <th class="min-w-120" *ngIf="step['@permissions'].view">
                                        {{ 'workflow.' + step['@columnName'] | translate }}
                                    </th>
                                </ng-container>
                            </ng-container>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{{ 'word.technical_orientation' | translate }}</td>
                            <ng-container *ngFor="let column of folderProgram.reportSteps">
                                <ng-container *ngFor="let step of column.steps">
                                    <td *ngIf="step['@permissions'].view && step.measures[i] && step.measures[i].technicalOrientation">
                                        {{ step.measures[i].technicalOrientation.name | default:'-' }}
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>

                        <tr>
                            <td>{{ 'word.duration_of_use' | translate }}</td>
                            <ng-container *ngFor="let column of folderProgram.reportSteps">
                                <ng-container *ngFor="let step of column.steps">
                                    <td *ngIf="step['@permissions'].view && step.measures[i]">
                                        <ng-container *ngIf="!step['@permissions'].edit">
                                            {{ 'word.n_years' | translate:{count: step.measures[i].durationOfUse | default:'-'} }}
                                        </ng-container>
                                        <ng-container [formGroupName]="i" *ngIf="step['@permissions'].edit">
                                            <div class="dataset-measure fields-group"
                                                 formGroupName="datasetMeasure">
                                                <ng-container *ngIf="step['@permissions'].edit">
                                                    <mat-form-field>
                                                        <input matInput
                                                               formControlName="durationOfUse"
                                                               type="number"
                                                               autocomplete="off">
                                                        <span class="mat-input-suffix"
                                                              matSuffix>{{ 'word.years' | translate }}</span>
                                                    </mat-form-field>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>

                        <tr>
                            <td>{{ 'word.description' | translate }}</td>
                            <ng-container *ngFor="let column of folderProgram.reportSteps">
                                <ng-container *ngFor="let step of column.steps">
                                    <td *ngIf="step['@permissions'].view && step.measures[i]">
                                        <ng-container *ngIf="!step['@permissions'].edit">
                                            <div [innerHTML]="step.measures[i].description | default:'-'"></div>
                                        </ng-container>

                                        <ng-container [formGroupName]="i" *ngIf="step['@permissions'].edit">
                                            <div class="dataset-measure fields-group"
                                                 formGroupName="datasetMeasure">
                                                <mat-form-field>
                                  <textarea matInput
                                            formControlName="description"
                                            cdkTextareaAutosize
                                            cdkAutosizeMinRows="5"
                                            maxlength="{{ lengthValidators.longString }}"></textarea>
                                                    <mat-error
                                                            *ngIf="form.get('currentReport.reportMeasures')['controls'][i].get('datasetMeasure.description').hasError('maxlength')">
                                                        {{ 'validator.field.too_long' | translate: '{ max: 4000 }' }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </td>
                                </ng-container>
                            </ng-container>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
        </div>
    </app-card>
    <!--  Analysis -->
    <app-card cardNumber="3" appAdminComponent cardTitle="title.analysis">
        <div class="row">
            <div class="col-md-8" formGroupName="analysis">
                <mat-form-field>
                    <mat-label>{{ 'word.decision' | translate }}</mat-label>
                    <textarea matInput
                              formControlName="decision"
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="5"
                              #analysisDecision
                              [readonly]="!reportNotCompleted()"
                              maxlength="10000"></textarea>
                    <mat-hint align="end">{{ analysisDecision.value.length }} / 65000</mat-hint>
                    <mat-error *ngIf="form.get('analysis.decision').hasError('maxlength')">
                        {{ 'validator.field.too_long' | translate: {max: 65000} }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <table class="table w-100">
                    <thead>
                    <tr>
                        <th class="min-w-120">
                            {{ 'word.documents' | translate }}

                            <div class="add-media-object" *ngIf="reportNotCompleted()">
                                <button mat-mini-fab color="accent" (click)="addAnalysisMediaObject()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <mat-list dense role="list">
                                <mat-list-item role="listitem"
                                               *ngFor="let mediaObject of form.get('analysisMediaObjects').value; let i = index; last as last">
                                    <span class="mat-small" mat-line>{{ mediaObject.originalName }}</span>
                                    <button mat-icon-button appConfirmDialog
                                            (confirmClick)="deleteAnalysisMediaObject(mediaObject, i)"
                                            *ngIf="reportNotCompleted()">
                                        <mat-icon class="mat-16">delete_outline</mat-icon>
                                    </button>
                                    <button mat-icon-button (click)="downloadMediaObject(mediaObject)">
                                        <mat-icon class="mat-16">get_app</mat-icon>
                                    </button>
                                </mat-list-item>
                            </mat-list>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" formGroupName="analysis">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>{{ 'word.comment' | translate }}</mat-label>
                    <textarea matInput
                              formControlName="comment"
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="5"
                              #analysisComment
                              [readonly]="!reportNotCompleted()"
                              maxlength="65000"></textarea>
                    <mat-hint align="end">{{ analysisComment.value.length }} / 65000</mat-hint>
                    <mat-error *ngIf="form.get('analysis.comment').hasError('maxlength')">
                        {{ 'validator.field.too_long' | translate: {max: 65000} }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </app-card>
</ng-container>

<app-admin-remark-card appAdminComponent [cardNumber]="4" [folder]="folderProgram"></app-admin-remark-card>

<app-documents-list sectionNumber="5" [reportIri]="programReportResource.data.currentReport.report['@id']"
                    [mediaObjects]="programReportResource.data.currentReport.mediaObjects"></app-documents-list>

<app-invoices-list [sectionNumber]="6" [hideControls]="!reportNotCompleted()" [report]="programReportResource"
                   [showStateControls]="reportNotCompleted()"></app-invoices-list>

<!-- Todos -->
<app-todo [folder]="folderProgram"></app-todo>

<app-sticky-footer>
    <div class="w-50">
        <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="save()"
                [loading]="loading.loading$ | async">
            {{ 'action.save' | translate }} <i class="icon-floppy-disk"></i>
        </button>
    </div>
    <div class="w-50 d-flex justify-content-end" *appIsGranted="'ROLE_STAFF_PKW'">
        <button mat-flat-button color="primary" class="align-self-end" (click)="openReportSettingsDialog()">
            {{ 'word.report_settings' | translate }} <i class="icon-filter"></i>
        </button>
    </div>
</app-sticky-footer>

<input #fileInput
       type="file"
       class="file-input hidden"
       multiple="multiple"
       (change)="onNewFileSelected($event)"/>
