import { Component } from '@angular/core';
import { COMPUTOR_NAMES, COMPUTOR_TYPES, FolderService } from '../../../../../../core/services/folder.service';
import AbstractComputor from '../../../../../abstract-computor';
import { SwitchUserService } from '../../../../../../core/services/switch-user.service';
import { FolderProgramResource } from '../../../../../../core/models/folder-program-resource.model';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgramReportResource } from '../../../../../../core/models/program-report-resource';
import {
    AnalysisCost,
    FIX_COST_TYPE_ACCOMPANIMENT,
    FIX_COST_TYPE_MANAGEMENT,
    FixCost
} from '../../../../../../core/models/fixCost.model';
import { ProgramMeasure } from '../../../../../../core/models/measure.model';
import { MediaObject } from '../../../../../../core/models/media-object.model';
import { ProgramComputedValues } from '../../../../../../core/models/computed-values.model';
import { Step } from '../../../../../../core/models/step.model';
import { ChildProgram } from '../../../../../../core/models/child-folder.model';
import { AuthService } from '../../../../../../core/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { IriUtil } from '../../../../../../core/utils/iri.util';
import { TranslateService } from '@ngx-translate/core';
import { PageTitleService } from '../../../../../../core/services/page-title.service';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import { LoadingService } from '../../../../../../core/services/loading.service';
import { HttpResponse } from '@angular/common/http';
import { DownloaderHelper } from '../../../../../../core/utils/download.util';
import { NotificationService } from '../../../../../../core/notifications/notification.service';
import { ProgramReportService } from '../../../../../../core/services/program-report.service';
import { ProgramDatasetMeasure } from '../../../../../../core/models/dataset-measure.model';
import { ModalService } from '../../../../../../core/services/modal.service';

@Component({
    selector: 'app-program-report-show',
    templateUrl: './program-report-show-step.component.html',
    styleUrls: ['./program-report-show-step.component.scss']
})
export class ProgramReportShowStepComponent extends AbstractComputor {
    public static computorName = COMPUTOR_NAMES.computor2024Lights;
    public validatedFolder: ChildProgram = null;

    constructor(
        protected switchUserService: SwitchUserService,
        private route: ActivatedRoute,
        private folderService: FolderService,
        public auth: AuthService,
        private dialog: MatDialog,
        private router: Router,
        private translator: TranslateService,
        private title: PageTitleService,
        private breadCrumbService: BreadcrumbService,
        public loading: LoadingService,
        private notification: NotificationService,
        private programReportService: ProgramReportService,
        private modalService: ModalService
    ) {
        super(switchUserService);

        this.folderProgram = this.route.snapshot.data.folderProgramResource as FolderProgramResource ?? null;

        this.computorType = COMPUTOR_TYPES.program;
        this.computorName = COMPUTOR_NAMES.computor2024Lights;

        this.folderService.setProgramResource(this.folderProgram);
        this.folderService.addCurrentReport(this.programReportResource.data.currentReport);
        this.validatedFolder = this.folderProgram.folderSteps.before.find((step: Step) => 'validation_2' === step.marking) as ChildProgram;

        this.initBreadcrumb();
    }

    public get managementFixCosts(): FixCost[] {
        return this.programReportResource.data.currentReport.fixCosts.filter(fixCost => FIX_COST_TYPE_MANAGEMENT === fixCost.type);
    }

    public get accompanimentFixCosts(): FixCost[] {
        return this.programReportResource.data.currentReport.fixCosts.filter(fixCost => FIX_COST_TYPE_ACCOMPANIMENT === fixCost.type);
    }

    public get measures(): ProgramMeasure[] {
        return this.programReportResource.data.currentReport.reportMeasures;
    }

    public get mediaObjects(): MediaObject[]|null {
        return this.programReportResource.data.currentReport.mediaObjects ?? null;
    }

    public get programReportResource(): ProgramReportResource {
        return this.folderProgram.data.programReportResource;
    }

    public get analysisCosts(): AnalysisCost {
        return this.programReportResource.data.currentReport.analysisCosts;
    }

    public get computedValues(): ProgramComputedValues {
        return this.programReportResource.data.currentReport.computedValues;
    }

    public exportPdf(): void {
        this.programReportService.pdf(
            IriUtil.extractId(this.programReportResource['@id']),
            this.programReportResource.data.currentReport.marking,
            'pdf_program_report_exporter'
        ).subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        }, () => {
            this.notification.error('toast.an_error_occurred_while_generating_pdf');
        });
    }

    public openReportSettingsDialog(): void {
        this.modalService.openReportSettingsDialog(this.folderLink, this.programReportResource);
    }

    get folderLink(): string {
        if (!this.auth.isAdmin()) {
            return '/sessions/' + IriUtil.extractId(this.folderProgram.data.tenderSession['@id'])
                + '/programs/' + String(this.folderProgram.data.id) + '/view';
        }

        return '/admin/programs/' + this.folderProgram.data.id.toString();
    }

    private initBreadcrumb(): void {
        this.breadCrumbService.reportView(this.folderLink);
    }
}
