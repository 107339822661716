<h2 mat-dialog-title>{{'menu.settings' | translate}} - {{ currentName }}</h2>
<mat-dialog-content class="mat-typography">
    <div class="row">
        <div class="col-md-6">
            <mat-form-field>
                <mat-label>{{'word.user' | translate}}</mat-label>
                <mat-select [formControl]="userCtrl">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="userSearch" placeholderLabel="{{'word.search' | translate}}"></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let user of showedUsers" [value]="user['@id']">{{user.firstname}} {{user.lastname}} - {{user.email}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field>
                <mat-label>{{'menu.tender_session' | translate}}</mat-label>
                <mat-select [formControl]="tenderSessionCtrl">
                    <mat-option *ngFor="let session of availableTenderSessions" [value]="session['@id']">{{session.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="Box color-border-danger" *appIsGranted="'ROLE_STAFF_PKW'">
                <ul>
                    <li>
                        <div class="d-flex justify-content-between">
                            <div>
                                <strong>{{ 'dialog.folder_settings.delete_this_folder' | translate }}</strong>
                                <p class="mb-0">
                                    {{ 'dialog.folder_settings.delete_this_folder_message' | translate }}
                                </p>
                            </div>
                            <div class="pl-4 align-self-center">
                                <button mat-stroked-button color="warn" appConfirmDialog (confirmClick)="deleteFolder()">
                                    {{ 'dialog.folder_settings.delete_this_folder' | translate}}
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close class="cancel-button mr-2">{{ 'action.close' | translate }}</button>
    <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="save()" [loading]="loading.loading$ | async">
        {{ 'action.save' | translate }} <i class="icon-floppy-disk"></i>
    </button>
</mat-dialog-actions>
