import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SwitchUserService } from '../services/switch-user.service';

@Injectable()
export class HttpSwitchUserInterceptor implements HttpInterceptor {
    constructor(
        private switchUserService: SwitchUserService
    ) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let headersConfig = {};

        const user = this.switchUserService.getSwitchedUser();

        if (user) {
            headersConfig = {
                'x-switch-user': user
            };
        }


        const request = req.clone({ setHeaders: headersConfig });

        return next.handle(request);
    }
}
