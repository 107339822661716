import { Component, OnInit } from '@angular/core';
import { SwitchUserService } from '../../../../../../core/services/switch-user.service';
import { ProgramForm } from '../../../../models/programForm';
import { takeUntil } from 'rxjs/operators';
import { Abstract2024ProgramFormTemplateComponent } from '../../../../program-form.template';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FolderStatusService } from '../../../../../../core/services/folder-status.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '../../../../../../core/notifications/notification.service';
import { FolderProgramService } from '../../../../../../core/services/folder-program.service';
import { ProjectFieldsCheckerService } from '../../../../services/project-fields-checker.service';
import { AuthService } from '../../../../../../core/services/auth.service';
import { ProgramCalculatorService } from '../../../../services/program-calculator.service';
import { COMPUTOR_NAMES, FolderService } from '../../../../../../core/services/folder.service';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import { MenuService } from '../../../../../../core/services/menu.service';
import { LoadingService } from '../../../../../../core/services/loading.service';
import { HttpResponse } from '@angular/common/http';
import { DownloaderHelper } from '../../../../../../core/utils/download.util';
import { TimeUtil } from '../../../../../../core/utils/time.util';
import { TechnicalOrientationService } from '../../../../../../core/services/technical-orientation.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-program-form',
    templateUrl: './program.component.html',
    styleUrls: ['./program.component.scss']
})
export class ProgramComponent extends Abstract2024ProgramFormTemplateComponent implements OnInit {
    public static computorName = COMPUTOR_NAMES.computor2024;

    constructor(
        protected switchUserService: SwitchUserService,
        protected technicalOrientationService: TechnicalOrientationService,
        protected programForm: ProgramForm,
        protected folderProgramService: FolderProgramService,
        protected route: ActivatedRoute,
        protected folderStatusService: FolderStatusService,
        protected fb: FormBuilder,
        protected translateService: TranslateService,
        protected router: Router,
        protected notificationService: NotificationService,
        protected projectFieldsCheckerService: ProjectFieldsCheckerService,
        protected authService: AuthService,
        protected programCalculator: ProgramCalculatorService,
        protected folderService: FolderService,
        private breadcrumbService: BreadcrumbService,
        private menuService: MenuService,
        public loading: LoadingService,
        protected dialog: MatDialog,
    ) {
        super(
            switchUserService,
            technicalOrientationService,
            programForm,
            folderProgramService,
            route,
            folderStatusService,
            fb,
            translateService,
            router,
            notificationService,
            projectFieldsCheckerService,
            authService,
            programCalculator,
            folderService,
            dialog
        );
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.setMinAndMaxDatesFromDecisionDate();
        this.subscribeToDateChanges();
        this.calculateLengthInMonth();
        this.initBreadcrumbAndMenu();
    }

    public exportPdf(): void {
        this.exportPdfCall().subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        }, () => {
            this.notificationService.error('toast.an_error_occurred_while_generating_pdf');
        });
    }

    private subscribeToDateChanges(): void {
        this.form.get('currentProgram.endDate').valueChanges.pipe(takeUntil(this.destroySubject)).subscribe(() => {
            this.calculateLengthInMonth();
        });
    }

    private calculateLengthInMonth(): void {
        TimeUtil.setMonthsFromCurrentProgram(this.form.get('currentProgram') as FormGroup);
    }

    private initBreadcrumbAndMenu(): void {
        const folderName = this.folderProgram?.data.currentProgram.acronym ?? 'word.new_program';
        this.breadcrumbService.clientFolderView(folderName);

        const menu = [{link: '/dashboard', label: 'menu.dashboard', icon: 'icon-home', subItems: []}, {
            link: null, label: folderName, icon: 'icon-clipboard', subItems: [
                { link: null, label: 'word.supporting_organisation', icon: 'icon-1', scrollTo: '#section1'},
                { link: null, label: 'title.project_holder', icon: 'icon-2', scrollTo: '#section2'},
                { link: null, label: 'title.general', icon: 'icon-3', scrollTo: '#section3'},
                { link: null, label: 'word.fix_costs', icon: 'icon-4', scrollTo: '#section4'},
                { link: null, label: 'title.variable_costs_for_end_customers', icon: 'icon-5', scrollTo: '#section5'},
                { link: null, label: 'title.documents', icon: 'icon-6', scrollTo: '#section6'},
                { link: null, label: 'title.requirements_control', icon: 'icon-7', scrollTo: '#section7'},
            ]
        }];

        this.menuService.store(menu);
    }
}
