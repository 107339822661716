<div class="row">
    <div class="col-md-7">
        <!-- General -->
        <app-card cardNumber="1" cardTitle="title.general">
            <div class="row">
                <div class="col-md-6">
                    <p class="field-title">{{ 'word.average_cost_of_electricity' | translate }}</p>
                    <!--                        Divide by 100 because we want cents -->
                    <p>{{ projectReportResource.data.currentReport.dataset.cost | default:'-' }} {{ 'word.cts_kwh' | translate }}</p>
                </div>
                <div class="col-md-6">
                    <p class="field-title">{{ 'word.requested_contribution' | translate }}</p>
                    <p>{{ projectReportResource.data.currentReport.dataset.requestedContribution | currency | default:'-' }}</p>
                </div>
            </div>
        </app-card>
        <!-- Measures -->
        <app-card cardNumber="2" cardTitle="title.measures">
            <div *ngFor="let measure of projectReportResource.data.currentReport.reportMeasures; let i = index">
                <div class="dataset-measure fields-group">
                    <div class="header">
                        <h2>{{ 'word.measure_n' | translate:{count: i + 1} }}</h2>
                    </div>
                    <div class="content">
                        <div class="row">
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.technical_orientation' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.technicalOrientation ? measure.datasetMeasure.technicalOrientation.name : '-' }}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.duration_of_use' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.durationOfUse | default:'-' }} {{ 'word.years' | translate }}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.measure_cost' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.cost | currency:'CHF':'symbol':'0.0-2' | default:'-' }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.maximum_contribution' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.maximumContribution | currency:'CHF':'symbol':'0.0-2' | default:'-' }}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.existing_annual_consumption' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.existingAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.future_annual_consumption' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.futureAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.annual_saving' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.annualSavings | positive | number | default: '-': ('word.kwh' | translate) }}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.total_saving' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.totalSavings | positive | number | default: '-': ('word.kwh' | translate) }}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.payback_without_contribution' | translate }}</p>
                                <p class="text-justify">{{ 'word.n_years' | translate:{count: measure.datasetMeasure.paybackWithoutContribution | round} | default:'-' }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p class="field-title">{{ 'word.report_measure_description' | translate }}</p>
                                <p class="text-justify"
                                   [innerHTML]="measure.datasetMeasure.description | nl2br | default:'-'"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-card>
        <ng-container *ngIf="projectReportResource">
            <app-documents-list sectionNumber="3" [reportIri]="projectReportResource.data.currentReport.report['@id']"
                                [mediaObjects]="projectReportResource.data.currentReport.mediaObjects"></app-documents-list>
        </ng-container>
        <ng-container *ngIf="projectReportResource">
            <app-invoices-list [sectionNumber]="4" [report]="projectReportResource"
                               [hideControls]="!reportNotCompleted()"></app-invoices-list>
        </ng-container>
    </div>
    <div class="col-md-5">
        <ng-container *ngIf="projectReportResource">
            <app-sticky-container>
                <app-computor2020-project-summary-card [folder]="folderProject"
                                                       type="report"></app-computor2020-project-summary-card>
            </app-sticky-container>
        </ng-container>
    </div>
</div>

<app-sticky-footer>
    <div>
        <ng-container *ngIf="folderProject">
            <button mat-flat-button color="primary" appMatButtonLoading (click)="exportPdf()"
                    [loading]="loading.loading$ | async">
                {{ 'action.pdf' | translate }} <i class="icon-pdf-file"></i>
            </button>
        </ng-container>
    </div>
</app-sticky-footer>
