import { Component, Input } from '@angular/core';
import { FixCost } from '../../../../../core/models/fixCost.model';
import { ChildProgram } from '../../../../../core/models/child-folder.model';

@Component({
    selector: 'app-report-readonly-fix-costs',
    templateUrl: './report-readonly-fix-costs.component.html',
    styleUrls: ['./report-readonly-fix-costs.component.scss']
})
export class ReportReadonlyFixCostsComponent {
    @Input()
    fixCost: FixCost;

    @Input()
    validatedFolder: ChildProgram = null;

    getBalance(): number {
        const validatedCost = this.validatedFolder.fixCosts.find(f => f.groupName === this.fixCost.groupName);
        return validatedCost.cost.requestedContribution - this.fixCost.cost.requestedContribution;
    }
}
