import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Tag } from '../../core/models/tag.model';
import { TagService } from '../../core/services/tag.service';
import { Paginator } from '../../core/models/paginator.model';
import { FormBuilder, Validators } from '@angular/forms';
import { FolderService } from '../../core/services/folder.service';
import { FormUtil } from '../../core/utils/form.util';
import { IriUtil } from '../../core/utils/iri.util';
import { take } from 'rxjs/operators';
import { AuthorizationChecker } from '../../core/security/authorization-checker';

@Component({
    selector: 'app-admin-folder-tags',
    templateUrl: './admin-folder-tags.component.html',
    styleUrls: ['./admin-folder-tags.component.scss']
})
export class AdminFolderTagsComponent implements OnInit {
    @ViewChild('originRef', {read: ViewContainerRef})
    public originRef: ViewContainerRef;
    @ViewChild('containerRef', {read: ViewContainerRef})
    public containerRef: ViewContainerRef;
    @ViewChild('newTagInput', {read: ViewContainerRef})
    public newTagInput: ViewContainerRef;
    isOpen = false;
    availableTags: Tag[];
    elementWidth: number;
    addTagLoading = false;

    form = this.fb.group({
        name: [null, [Validators.required]]
    });

    constructor(
        private tagService: TagService,
        private fb: FormBuilder,
        private authorizationChecker: AuthorizationChecker,
        public folderService: FolderService,
    ) { }

    ngOnInit(): void {
        this.tagService.getAllTags().pipe(
            take(1)
        ).subscribe((result: Paginator) => {
            this.availableTags = result['hydra:member'] as Tag[];
        });
    }

    /**
     * Assign the selected tag to current folder
     * @param tag Selected tag
     */
    selectTag(tag: Tag): void {
        this.isOpen = false;
        const currentResource = this.folderService.getCurrentResource();
        this.tagService.assignTagToFolder(IriUtil.extractId(currentResource['@id']), tag['@id']).subscribe((folder: { tag: Tag }) => {
            currentResource.data.tag = folder.tag;
            this.folderService.setCurrentProjectOrProgram(currentResource);
        });
    }

    /**
     * Adds a new tag then assign it to the current folder
     * Then fetch all tags to show newly created one
     */
    addTag(): void {
        if (!this.form.valid) {
            FormUtil.validateAllFormFields(this.form);
            return;
        }
        const inputElement = this.newTagInput.element.nativeElement as HTMLInputElement;
        this.addTagLoading = true;
        this.tagService.createNewTag(inputElement.value).subscribe((newTag: Tag) => {
            this.clearTagName();
            this.addTagLoading = false;
            this.tagService.getAllTags().pipe(
                take(1)
            ).subscribe((allTags: Paginator) => {
                this.selectTag(newTag);
                this.availableTags = allTags['hydra:member'] as Tag[];
            });
        }, (() => { // error handling
            this.addTagLoading = false;
        }));
    }

    setTagToEmpty(): void {
        this.isOpen = false;
        const currentChild = this.folderService.getCurrentResource();
        this.tagService.assignTagToFolder(IriUtil.extractId(currentChild['@id']), null).subscribe((folder: { tag: Tag }) => {
            currentChild.data.tag = folder.tag;
            this.folderService.setCurrentProjectOrProgram(currentChild);
        });
    }

    clearTagName(): void {
        this.form.get('name').setValue(null);
    }

    openOverlay(): boolean {
        if (!this.authorizationChecker.checkIsGranted('ROLE_STAFF_PKW')) {
            return false;
        }

        return !this.isOpen;
    }

    get currentTag(): string {
        if (null === this.folderService.getCurrentResource()) {
            return 'word.no_tag';
        }

        return this.folderService.getCurrentResource().data.tag ?
            this.folderService.getCurrentResource().data.tag.name : 'word.no_tag';
    }
}
