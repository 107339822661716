<app-card [cardNumber]="sectionNumber" cardTitle="title.invoices">
    <div class="media-groups scrollX">
        <ng-container *ngIf="report.data.invoices && report.data.invoices.length > 0">
            <table mat-table [dataSource]="report.data.invoices" class="w-100">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>{{ 'word.invoice_name' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.name}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="amount">
                    <th mat-header-cell *matHeaderCellDef>{{ 'word.invoice_amount' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.amount | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="amountFix">
                    <th mat-header-cell *matHeaderCellDef>{{ 'word.invoice_amount_fix' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.fixAmount | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="amountVariable">
                    <th mat-header-cell *matHeaderCellDef>{{ 'word.invoice_amount_variable' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.variableAmount | currency}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="receiveDate">
                    <th mat-header-cell *matHeaderCellDef>{{ 'word.receive_date' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.receiveDate | localizedDate | default:''}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="approvalDate">
                    <th mat-header-cell *matHeaderCellDef>{{ 'word.approval_date' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.approvalDate | localizedDate | default:''}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="ofenApprovalDate">
                    <th mat-header-cell *matHeaderCellDef>{{ 'word.ofen_approval_date' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.ofenApprovalDate | localizedDate | default:''}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="paymentDate">
                    <th mat-header-cell *matHeaderCellDef>{{ 'word.payment_date' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.paymentDate | localizedDate | default:''}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef>{{ 'word.state' | translate }}</th>
                    <td mat-cell *matCellDef="let element">
                        {{'invoice.state.' + element.state | translate}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element" class="text-right">
                        <ng-container *appIsGranted="'ROLE_STAFF_PKW'">
                            <ng-container *ngIf="showStateControls">
                                <button mat-icon-button appConfirmDialog
                                        (confirmClick)="passToState(element, 'valid')"
                                        *ngIf="element.state !== 'valid'">
                                    <mat-icon class="mat-24" color="accent"
                                              matTooltip="{{'invoice.state.valid' | translate}}">check
                                    </mat-icon>
                                </button>
                                <button mat-icon-button appConfirmDialog
                                        (confirmClick)="passToState(element, 'payed')"
                                        *ngIf="element.state === 'valid'">
                                    <mat-icon class="mat-24" color="accent"
                                              matTooltip="{{'invoice.state.payed' | translate}}">check
                                    </mat-icon>
                                </button>
                                <button mat-icon-button appConfirmDialog
                                        (confirmClick)="passToState(element, 'refused')">
                                    <mat-icon class="mat-24" color="warn"
                                              matTooltip="{{'invoice.state.refused' | translate}}">highlight_off
                                    </mat-icon>
                                </button>
                            </ng-container>
                        </ng-container>

                        <button mat-icon-button class="ml-4" [matTooltip]="'action.download' | translate">
                            <mat-icon class="mat-24" (click)="downloadMediaObject(element.mediaObject)">
                                cloud_download
                            </mat-icon>
                        </button>

                        <ng-container *appIsGranted="'ROLE_STAFF_PKW'">
                            <button mat-icon-button *ngIf="!hideControls" [matTooltip]="'action.delete' | translate"
                                    appConfirmDialog (confirmClick)="deleteInvoice(element)">
                                <mat-icon>delete_outline</mat-icon>
                            </button>
                            <button *ngIf="showStateControls" mat-icon-button (click)="showEditInvoice(element)"
                                    [matTooltip]="'action.edit' | translate">
                                <mat-icon>edit_outline</mat-icon>
                            </button>
                        </ng-container>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </ng-container>
        <ng-container *ngIf="!report.data.invoices || report.data.invoices.length === 0">
            <p>{{ 'message.no_invoice' | translate }}</p>
        </ng-container>
    </div>
    <button mat-mini-fab color="primary" class="card-content-action" (click)="addInvoice()" *ngIf="!hideControls">
        <mat-icon>add</mat-icon>
    </button>
</app-card>
