import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[appAdminComponent]'
})
export class AdminIndicatorDirective implements OnInit {
    @Input() yAxisPosition: 'top'|'bottom' = 'top';

    @Input() xAxisPosition: 'left'|'right' = 'left';

    @Input() forceStyle: string; // edge cases

    private element: ElementRef;

    constructor(
        el: ElementRef
    ) {
        this.element = el;
    }

    ngOnInit(): void {
        this.buildAdminIcon();
    }

    private buildAdminIcon(): void {
        this.htmlElement.style.position = 'relative';
        const additionalClasses = 'admin-icon-wrapper-' + this.xAxisPosition + ' admin-icon-wrapper-' + this.yAxisPosition;
        this.htmlElement.insertAdjacentHTML('beforeend', '<div style="' + this.forceStyle + '"' +
            ' class="admin-icon-wrapper mat-elevation-z3 ' + additionalClasses + '"><i class="icon-search-shield"></i></div>');
    }

    private get htmlElement(): HTMLElement {
        return this.element.nativeElement as HTMLElement;
    }
}
