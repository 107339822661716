<app-card classes="projectList-wrapper col-md-12 py-4 mat-elevation-z5" style="min-width: 60vw;" cardTitle="word.todo_list">
    <table mat-table [dataSource]="todoDatasource" class="col-md-12">
        <ng-container matColumnDef="doneAction">
            <th mat-header-cell *matHeaderCellDef>
            </th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox *ngIf="element.done == null" matTooltip="{{ 'action.archive' | translate }}"
                              matTooltipPosition="left"
                              class="custom-frame close-todo-btn" (click)="archiveTodo(element['@id'])"></mat-checkbox>
                <mat-checkbox *ngIf="element.done != null" matTooltip="{{ 'action.undo_archive' | translate }}"
                              matTooltipPosition="left"
                              class="custom-frame close-todo-btn" checked="checked"
                              (click)="activateTodo(element['@id'])"></mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="urgentMark">
            <th mat-header-cell *matHeaderCellDef> {{ 'word.is_urgent' | translate }} </th>
            <td mat-cell *matCellDef="let element" class="pt-2">
                <ng-container *ngIf="element.isUrgent">
                    <mat-icon class="urgent">warning</mat-icon>
                </ng-container>
            </td>
        </ng-container>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> {{ 'word.description' | translate }} </th>
            <td mat-cell *matCellDef="let element"><p class="description"> {{element.description}} </p></td>
        </ng-container>
        <ng-container matColumnDef="assinedUser">
            <th mat-header-cell *matHeaderCellDef> {{ 'word.assigned_user' | translate }} </th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.assignedUser; else noUser">
                    {{element.assignedUser.firstname | translate}} {{element.assignedUser.lastname}}
                </ng-container>
                <ng-template #noUser>
                    -
                </ng-template>
            </td>
        </ng-container>
        <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef> {{ 'word.created_at'| translate }} </th>
            <td mat-cell *matCellDef="let element">
                {{(element.createdAt | date) | default: '-'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="deadline">
            <th mat-header-cell *matHeaderCellDef> {{ 'word.deadline'| translate }} </th>
            <td mat-cell *matCellDef="let element" [class.deadline_passed]="checkIfPassed(element.deadline)"
                [class.deadline_today]="checkIfToday(element.deadline)">
                {{(element.deadline | date) | default: '-'}}
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="text-right"></th>
            <td mat-cell *matCellDef="let element" class="action-icon-wrp text-right">
                <button mat-icon-button
                        (click)="editTodo(element['@id'])"
                        [matTooltip]="'action.edit' | translate">
                    <i class="action-icon icon-edit"></i>
                </button>

                <button mat-icon-button
                        (confirmClick)="removeTodo(element['@id'])"
                        appConfirmDialog
                        [matTooltip]="'action.delete' | translate">
                    <i class="action-icon icon-delete"></i>
                </button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="col">
        <div class="row">
            <div class="col-md-6 d-flex align-items-center">
                <mat-slide-toggle
                        (click)="toggleArchivedTodos()">{{ 'word.show_todos_archives' | translate }}</mat-slide-toggle>
            </div>
            <mat-paginator class="col-md-6"
                           [length]="todoDatasource.totalItems"
                           [pageSize]="todoDatasource.itemsPerPage"
                           [pageSizeOptions]="[10]">
            </mat-paginator>
        </div>
    </div>
    <button mat-mini-fab class="card-content-action" (click)="showNewTodoDialog()">
        <mat-icon>add</mat-icon>
    </button>
</app-card>
