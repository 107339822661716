import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { IriUtil } from '../utils/iri.util';

@Injectable()
@Pipe({
    name: 'iri2id',
    pure: false, // required to update the value when the promise is resolved
})
export class Iri2IdPipe implements PipeTransform {
    public transform(iri: string): string {
        return IriUtil.extractId(iri);
    }
}
