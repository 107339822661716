<div class="row">
    <div class="col-md-12 d-flex align-items-center mb-2">
        <p class="costs-title">{{'fix_costs.' + fixCost.groupName | translate}}</p>
        <span class="hint-help">
              <mat-icon matTooltip="{{'fix_costs.'+fixCost.groupName+'.tooltip' | translate}}"
                        matTooltipPosition="right">help</mat-icon>
        </span>
    </div>
    <div class="col-8">
        <mat-label class="field-title">{{'word.realised_requested_contribution' | translate}}</mat-label>
        <p>{{fixCost.cost.requestedContribution ? fixCost.cost.requestedContribution + ' ' + ('word.chf' | translate) : '-'}}</p>
    </div>
    <div class="col-4">
        <mat-label class="field-title">{{'word.balance' | translate}}</mat-label>
        <p>{{getBalance()}} {{'word.chf' | translate}}</p>
    </div>
</div>
