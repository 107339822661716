import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams } from '@angular/common/http';
import { TenderModel } from '../models/tender-model.model';
import { HydraCollection } from '../models/hydra-collection.model';


@Injectable()
export class TenderModelService {

    constructor(
        private apiService: ApiService
    ) {
    }

    public findAll(): Observable<HydraCollection> {
        return this.apiService.get('/tender_models/') as Observable<HydraCollection>;
    }

    public get(params?: HttpParams): Observable<HydraCollection> {
        return this.apiService.get('/tender_models', { params }) as Observable<HydraCollection>;
    }

    public getOne(tenderModelIri: string): Observable<TenderModel> {
        return this.apiService.get(tenderModelIri) as Observable<TenderModel>;
    }
}
