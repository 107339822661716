import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { HttpParamUtil } from '../utils/http-param.util';
import { FolderProgramResource } from '../models/folder-program-resource.model';


@Injectable()
export class FolderProgramService {

    constructor(
        private apiService: ApiService
    ) {
    }

    /**
     * Save a folder program-form
     */
    public save(body: Record<any, unknown>, type: string, computor: string, submitting = false): Observable<FolderProgramResource> {
        const params = new HttpParams()
            .set('type', type)
            .set('computor', computor)
            .set('submitting', String(submitting));

        return this.apiService.post('/folder_program_resources', body, {params}) as Observable<FolderProgramResource>;
    }

    /**
     * Check a program-form
     */
    public check(body: Record<any, unknown>, type: string, computor: string): Observable<FolderProgramResource> {
        const params = new HttpParams()
            .set('type', type)
            .set('computor', computor)
            .set('checking', String(true));

        return this.apiService.post('/folder_program_resources', body, {params}) as Observable<FolderProgramResource>;
    }

    /**
     * Submit a folder project-form => change his state
     */
    public submit(id: string, body: Record<any, unknown>): Observable<FolderProgramResource> {
        return this.apiService.patch(id + '/submit', body) as Observable<FolderProgramResource>;
    }

    /**
     * Print a folder object resource
     */
    public pdf(objectId: string, marking: string, exporterName: string): Observable<HttpResponse<Blob>> {
        const params = new HttpParams()
            .set('marking', marking)
            .set('exporter_name', exporterName)
        ;

        return this.apiService.get('/folder_program_resources/' + objectId + '/pdf', {
            params,
            observe: 'response',
            responseType: 'blob' as 'json'
        }) as Observable<HttpResponse<Blob>>;
    }

    /**
     * Find a FolderProgramResource by his id
     */
    public find(id: string, state: string = null, reportId: string = null): Observable<FolderProgramResource> {
        const httpParamUtil = HttpParamUtil.create();

        if (null !== state) {
            httpParamUtil.add('state', state);
        }

        if (null !== reportId) {
            httpParamUtil.add('report', reportId);
        }

        return this.apiService.get('/folder_program_resources/' + id, {
            params: httpParamUtil.getHttpParams()
        }).pipe(
            map((res) => res as FolderProgramResource)
        );
    }

    public delete(iri: string): Observable<any> {
        return this.apiService.delete(iri);
    }
}
