import { ErrorHandler, NgModule } from '@angular/core';
import { I18nService } from './i18n/i18n.service';
import { ConfirmDialogService } from './services/confirm-dialog.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpAuthorizationInterceptor } from './interceptors/http.authorization.interceptor';
import { ApiService } from './services/api.service';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { HttpLanguageInterceptor } from './interceptors/http.language.interceptor';
import { HttpContentTypeInterceptor } from './interceptors/http.content-type.interceptor';
import { AppErrorHandler } from './handlers/app-error.handler';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { NotificationService } from './notifications/notification.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { PageTitleService } from './services/page-title.service';
import { LoadingService } from './services/loading.service';
import { BreadcrumbService } from './services/breadcrumb.service';
import { FolderProjectService } from './services/folder-project.service';
import { FormUtil } from './utils/form.util';
import { TenderSessionService } from './services/tender-session.service';
import { TenderSessionResolver } from './resolvers/tender-session.resolver';
import { FolderResolver } from './resolvers/folder.resolver';
import { FolderService } from './services/folder.service';
import { FolderProjectResolver } from './resolvers/folder-project.resolver';
import { MediaObjectService } from './services/media-object.service';
import { MenuService } from './services/menu.service';
import { UserTenderSessionResourceService } from './services/user-tender-session-resource.service';
import { FolderStateResolver } from './resolvers/folder-state.resolver';
import { TransitionsService } from './services/transitions.service';
import { MarkingService } from './services/marking.service';
import { FolderStatusService } from './services/folder-status.service';
import { EnabledTransitionService } from './services/enabled-transition.service';
import { HttpSwitchUserInterceptor } from './interceptors/http.switch-user.interceptor';
import { HttpRequestInterceptor } from './interceptors/http.request.interceptor';
import { ReportResolver } from './resolvers/report.resolver';
import { AuthorizationChecker } from './security/authorization-checker';
import { AccessDecisionManager } from './security/access-decision-manager';
import { RoleVoter } from './security/voter/role.voter';

@NgModule({
    imports: [
        MatSnackBarModule
    ],
    declarations: [
    ],
    exports: [
    ],
    providers: [
        TenderSessionResolver,
        AuthorizationChecker,
        AccessDecisionManager,
        RoleVoter,
        FolderResolver,
        FolderProjectResolver,
        ApiService,
        I18nService,
        ConfirmDialogService,
        AuthService,
        AuthGuard,
        PageTitleService,
        LoadingService,
        BreadcrumbService,
        MenuService,
        AppErrorHandler,
        FolderProjectService,
        TenderSessionService,
        FolderService,
        TransitionsService,
        MarkingService,
        MediaObjectService,
        EnabledTransitionService,
        FolderStatusService,
        UserTenderSessionResourceService,
        FormUtil,
        NotificationService,
        FolderStateResolver,
        ReportResolver,
        {provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: HttpAuthorizationInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: HttpLanguageInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: HttpContentTypeInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: HttpSwitchUserInterceptor, multi: true},
        {provide: ErrorHandler, useClass: AppErrorHandler},
    ],
    entryComponents: []
})
export class CoreModule {
}
