<h1 mat-dialog-title>{{ 'word.change_report_state' | translate }}</h1>
<div mat-dialog-content>
    <form [formGroup]="form">
        <mat-radio-group formControlName="transition" *ngIf="enabledTransitions" (change)="onChange($event)">
            <div class="row status-wrapper">
                <div class="col-md-12">
                    <mat-radio-button value="open" [checked]="currentMarking == 'opened'" [disabled]="!isTransitionEnabled('open')">
                        <span class="status-color opened"></span> <span class="state-title">{{ 'workflow.opened' | translate }}</span>
                    </mat-radio-button>
                </div>
                <div class="col-md-12">
                    <p class="state-text">{{ 'dialog.change_report_state.draft.p0' | translate }}</p>
                </div>
            </div>
            <div class="row status-wrapper">
                <div class="col-md-12">
                    <ng-container *ngIf="canSwitchUser(); else noSwitchUser">
                        <mat-radio-button appSwitchUser [nextTransition]="'validate'" [canSwitchUser]="isTransitionEnabled('validate')" (userSwitched)="closeModal()" [startImpersonate]="folderService.getCurrentResource().data.owner.email" [redirectUrl]="impersonateRedirectUrl"
                                          value="validate" [checked]="currentMarking == 'validation'" [disabled]="!isTransitionEnabled('validate')">
                            <span class="status-color validation_1"></span> <span class="state-title">{{ 'workflow.validation' | translate }}</span> <i class="icon icon-search-shield"></i>
                        </mat-radio-button>
                    </ng-container>
                    <ng-template #noSwitchUser>
                        <mat-radio-button value="validate" [checked]="currentMarking == 'validation'" [disabled]="!isTransitionEnabled('validate')">
                            <span class="status-color validation_1"></span> <span class="state-title">{{ 'workflow.validation' | translate }}</span>
                        </mat-radio-button>
                    </ng-template>
                </div>
                <div class="col-md-12">
                    <p class="state-text">{{ 'dialog.change_report_state.validation.p0' | translate }}</p>
                </div>
            </div>
            <div class="row status-wrapper">
                <div class="col-md-12">
                    <mat-radio-button value="confirm" [checked]="currentMarking == 'validated'" [disabled]="!isTransitionEnabled('confirm')">
                        <span class="status-color validation_2"></span> <span class="state-title">{{ 'workflow.validated' | translate }}</span>
                    </mat-radio-button>
                </div>
                <div class="col-md-12">
                    <p class="state-text">{{ 'dialog.change_report_state.validated.p0' | translate }}</p>
                </div>
            </div>
            <div class="row status-wrapper">
                <div class="col-md-12">
                    <mat-radio-button value="complete" [checked]="currentMarking == 'completed'" [disabled]="!isTransitionEnabled('complete')">
                        <span class="status-color completed"></span> <span class="state-title">{{ 'workflow.completed' | translate }}</span>
                    </mat-radio-button>
                </div>
                <div class="col-md-12">
                    <p class="state-text">{{ 'dialog.change_report_state.completed.p0' | translate }}</p>
                </div>
            </div>
        </mat-radio-group>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close class="cancel-button mr-2">{{ 'action.close' | translate }}</button>
    <button mat-flat-button appMatButtonLoading color="accent" class="btn-white" (click)="changeState()" [loading]="loading.loading$ | async" [disabled]="null === currentTransition">
        {{ 'action.save' | translate }}
    </button>
</div>
