import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FolderProgramService } from '../services/folder-program.service';
import { FolderProgramResource } from '../models/folder-program-resource.model';

@Injectable()
export class FolderProgramResolver implements Resolve<FolderProgramResource> {
    constructor(
        private folderProgramService: FolderProgramService,
    ) { }
    /* eslint-disable */
    public resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<FolderProgramResource> {
        return this.folderProgramService.find(
            route.paramMap.get('folderId'), route.paramMap.get('stateId'), route.paramMap.get('reportId'));
    }
    /* eslint-enable */
}
