import { OnInit, AfterViewInit, OnDestroy, Component } from '@angular/core';
import { ProjectForm } from './models/projectForm';
import { ProjectCalculatorService } from './services/project-calculator.service';
import { NotificationService } from '../../core/notifications/notification.service';
import { SwitchUserService } from '../../core/services/switch-user.service';
import { FolderStatusService } from '../../core/services/folder-status.service';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TechnicalOrientation } from '../../core/models/technical-orientation.model';
import { Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map, take, takeUntil } from 'rxjs/operators';
import { MediaObject } from '../../core/models/media-object.model';
import AbstractComputor from '../abstract-computor';
import { FolderService } from '../../core/services/folder.service';
import { DirtyFormWarnableInterface } from '../../components/form-component/dirty-form.component';
import { TechnicalOrientationService } from '../../core/services/technical-orientation.service';

@Component({
    template: ''
})
export abstract class Abstract2024BigProjectFormTemplateComponent
    extends AbstractComputor implements OnInit, AfterViewInit, OnDestroy, DirtyFormWarnableInterface {
    /**
     * This class contains the base of a project_2024Big form.
     * Extended in Report edit & project-form component.
     */
    public form = this.computor2024Bigform.getProjectForm();

    public lengthValidators = this.computor2024Bigform.lengthValidators;

    public computedResume = {...this.computor2024Bigform.computedResume};

    public baseTechnicalOrientations: TechnicalOrientation[] = [];
    public technicalOrientations: TechnicalOrientation[] = [];

    public searchTechnicalOri = new FormControl();

    public isSubmittable = false;

    public defaultCurrency: string;

    public destroySubject: Subject<boolean> = new Subject<boolean>();

    protected constructor(
        protected computor2024Bigform: ProjectForm,
        protected projectCalculator: ProjectCalculatorService,
        protected notificationService: NotificationService,
        protected switchUserService: SwitchUserService,
        protected technicalOrientationService: TechnicalOrientationService,
        protected folderStatusService: FolderStatusService,
        protected fb: FormBuilder,
        protected route: ActivatedRoute,
        protected translateService: TranslateService,
        protected folderService: FolderService
    ) {
        super(switchUserService);

        this.defaultCurrency = environment.defaultCurrency;
    }

    public ngOnInit(): void {
        if (this.route.snapshot.data.folder) {
            this.setFolderProject(this.route.snapshot.data.folder);
        }

        this.projectCalculator.setFormReference(this.form);

        this.technicalOrientationService.findAllByTenderModel(this.tenderSession.tenderModel).pipe(
            map((result) => result['hydra:member']),
            take(1)
        ).subscribe( (technicalOrientations: TechnicalOrientation[]) => {
            this.baseTechnicalOrientations = technicalOrientations;
            this.technicalOrientations = technicalOrientations;
            this.projectCalculator.setTechnicalOrientationsList(technicalOrientations);

            if (this.folderProject) {
                const measuresCount = this.folderProject.data.currentProject.measures.length;
                for (let i = 1; i < measuresCount; i++) {
                    this.addMeasure();
                }

                this.form.patchValue(this.folderProject.data);

                this.folderProject.data.currentProject.mediaObjects.forEach((mediaObject: MediaObject) => {
                    this.addMediaObjectGroup(mediaObject);
                });

                // update header status
                this.folderStatusService.folderStatusChanged.emit(this.folderProject.data.marking);

                this.ngAfterViewInit();
            }
        });

        this.subscribeToTechnicalOriSearch();
    }

    public ngOnDestroy(): void {
        this.destroySubject.next(true);
        this.destroySubject.complete();
        this.folderService.clear();
    }

    public ngAfterViewInit(): void {
        this.executeCalculation();
    }

    public addMediaObjectGroup(media: MediaObject): void {
        const mediaObjects = this.form.get('currentProject.mediaObjects') as FormArray;
        mediaObjects.push(this.createMediaObjectGroup(media));
    }

    public get currentProjectForm(): FormGroup {
        return this.form.get('currentProject') as FormGroup;
    }

    public executeCalculation(): void {
        this.projectCalculator.calculate().pipe(takeUntil(this.destroySubject)).subscribe(() => {
            this.computedResume = this.projectCalculator.getResults();
        }, () => {
            this.notificationService.warn(this.projectCalculator.getErrorMessage());
        });
    }

    public removeMeasureGroup(i: number): void {
        const measures = this.form.get('currentProject.measures') as FormArray;

        if (measures.length > 1) {
            measures.removeAt(i);
        } else {
            measures.reset();
        }

        this.executeCalculation();
    }

    public addMeasure(): void {
        const measures = this.form.get('currentProject.measures') as FormArray;
        measures.push(this.computor2024Bigform.createMeasureGroup());
    }

    public getUuid(): string {
        return this.form.get('uuid').value as string;
    }

    private createMediaObjectGroup(media: MediaObject): FormGroup {
        return this.fb.group({
            '@id': [media['@id'], []],
            originalName: [media.originalName, []],
        });
    }

    private subscribeToTechnicalOriSearch(): void {
        this.searchTechnicalOri.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe((value: string) => {
            if (value === '' || !value) {
                this.technicalOrientations = this.baseTechnicalOrientations;
                return;
            }

            this.technicalOrientations = this.technicalOrientations.filter(obj => {
                const text = obj.slug;
                return text.toLowerCase().indexOf(value.toLowerCase()) > -1;
            });
        });
    }

    abstract save(): void;
}
