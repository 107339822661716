import { FormGroup } from '@angular/forms';

export function LessThanValidator(controlName: string, matchingControlName: string, orEqual = false): (formGroup: FormGroup) => void {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (null === control.value || null === matchingControl.value) {
            if (control.errors) {
                delete control.errors.lessThan;
                if (Object.keys(control.errors).length === 0) {
                    control.setErrors(null);
                }
            }

            return;
        }

        // less than or equal
        if (orEqual && Number(control.value) > Number(matchingControl.value)) {
            control.setErrors({ lessThan: true });

            return;
        }

        // less than
        if (!orEqual && Number(control.value) >= Number(matchingControl.value)) {
            control.setErrors({ lessThan: true });

            return;
        }

        if (control.errors) {
            delete control.errors.lessThan;
            if (Object.keys(control.errors).length === 0) {
                control.setErrors(null);
            }
        }
    };
}
