import * as FS from 'file-saver';
import { HttpResponse } from '@angular/common/http';

export class DownloaderHelper {
    public static forceDownload(response: HttpResponse<Blob>): void {
        (FS as FileSaver).saveAs(response.body, DownloaderHelper.fileName(response));
    }

    public static fileName(response: HttpResponse<Blob>): string {
        const contentDisposition = response.headers.get('content-disposition');

        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
                return matches[1].replace(/['"]/g, '');
            }
        }

        return 'file';
    }
}

interface FileSaver {
    // Unused interface -> declares the saveAs function from FileSaver library
    saveAs(blob, name?, opts?, popup?): void;
}
