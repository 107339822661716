import { NgModule } from '@angular/core';
import { ComputorFactory } from './computor.factory';
import { TechnicalOrientationService } from '../core/services/technical-orientation.service';
import { Computor2020Module } from './computor2020/computor2020.module';
import { OldComputorModule } from './oldComputor/oldComputor.module';
import { Computor2022Module } from './computor2022/computor2022.module';
import { Computor2022LightsModule } from './computor2022Lights/computor2022Lights.module';
import { Computor2023Module } from './computor2023/computor2023.module';
import { Computor2023BigModule } from './computor2023Big/computor2023Big.module';
import { Computor2024Module } from './computor2024/computor2024.module';
import { Computor2025Module } from './computor2025/computor2025.module';
import { Computor2024BigModule } from './computor2024Big/computor2024Big.module';
import { Computor2024LightsModule } from './computor2024Lights/computor2024Lights.module';


@NgModule({
    declarations: [
    ],
    imports: [
        OldComputorModule,
        Computor2020Module,
        Computor2022Module,
        Computor2022LightsModule,
        Computor2023Module,
        Computor2023BigModule,
        Computor2024Module,
        Computor2024BigModule,
        Computor2024LightsModule,
        Computor2025Module
    ],
    providers: [
        ComputorFactory,
        TechnicalOrientationService,
    ],
    bootstrap: []
})
export class ComputorModule {
}
