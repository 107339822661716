<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content [innerHTML]="data.content | translate"></div>
<div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="false">{{ data.cancel | translate }}</button>
    <button mat-flat-button color="warn" class="btn-white" [mat-dialog-close]="true">
        {{ data.confirm | translate }}
    </button>
</div>


