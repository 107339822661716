import { AfterViewChecked, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { ScrollTrackerDirective } from '../../core/directives/scroll-tracker.directive';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
    selector: 'app-sticky-container',
    templateUrl: './sticky-container.component.html',
    styleUrls: ['./sticky-container.component.scss']
})
export class StickyContainerComponent implements OnInit, AfterViewChecked, OnDestroy {
    @ViewChild('stickyBoxRef', {read: ViewContainerRef})
    public scrollContainerRef: ViewContainerRef;

    @ViewChild('stickyBoxWidth', {read: ViewContainerRef})
    public scrollContainerWidth: ViewContainerRef;

    public sticky = false;
    public elementPosition: number;
    public elementWidth = '0';

    public isLargeAndUpSubject = new BehaviorSubject<boolean>(true);
    public isLargeAndUp$: Observable<boolean> = this.breakpointObserver
        .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    private destroySubject: Subject<boolean> = new Subject<boolean>();
    private resizeObservable$: Observable<Event>;
    private resizeSubscription$: Subscription;

    constructor(
        private scrollTrackerDirective: ScrollTrackerDirective,
        private breakpointObserver: BreakpointObserver,
    ) { }

    public ngOnInit(): void {
        this.checkIfStickyOnScroll();

        this.isLargeAndUp$.pipe(
            takeUntil(this.destroySubject)
        ).subscribe((result) => {
            this.isLargeAndUpSubject.next(result);
        });

        this.resizeObservable$ = fromEvent(window, 'resize');
        this.resizeSubscription$ = this.resizeObservable$.pipe(
            takeUntil(this.destroySubject)
        ).subscribe(() => {
            if (this.isLargeAndUpSubject.getValue() === false) {
                (this.scrollContainerRef.element.nativeElement as HTMLElement).style.width = this.elementWidth + 'px';
            }
        });
    }

    public ngAfterViewChecked(): void {
      this.elementWidth = (this.scrollContainerWidth.element.nativeElement.clientWidth).toString(); // eslint-disable-line
      this.elementPosition = +this.scrollContainerRef.element.nativeElement.offsetTop + 100; // eslint-disable-line
    }

    public ngOnDestroy(): void {
        this.destroySubject.next(true);
        this.destroySubject.complete();
    }

    private checkIfStickyOnScroll(): void {
        this.scrollTrackerDirective.scrolled.pipe(
            takeUntil(this.destroySubject)
        ).subscribe((event: { pos: number; endReached: boolean }) => {
            // eslint-disable-next-line
            this.scrollContainerRef.element.nativeElement.style.width = this.elementWidth + 'px';

            // sticky position available for large screens only
            if (this.isLargeAndUpSubject.getValue() === true) {
                this.sticky = event.pos >= this.elementPosition;
            } else {
                this.sticky = false;
            }
        });
    }
}
