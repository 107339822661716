import { Component, OnInit } from '@angular/core';
import AbstractComputor from '../../../../../abstract-computor';
import { SwitchUserService } from '../../../../../../core/services/switch-user.service';
import { ProjectReportResource } from '../../../../../../core/models/project-report-resource';
import { ActivatedRoute } from '@angular/router';
import { FolderProjectResource } from '../../../../../../core/models/folder-project-resource.model';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import { IriUtil } from '../../../../../../core/utils/iri.util';
import {
    COMPUTOR_NAMES, COMPUTOR_TYPES,
    FolderService,
} from '../../../../../../core/services/folder.service';
import { LoadingService } from '../../../../../../core/services/loading.service';
import { FolderProgramResource } from '../../../../../../core/models/folder-program-resource.model';
import { ProgramReportResource } from '../../../../../../core/models/program-report-resource';
import { AuthService } from '../../../../../../core/services/auth.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { DownloaderHelper } from 'src/app/core/utils/download.util';
import { HttpResponse } from '@angular/common/http';
import { NotificationService } from 'src/app/core/notifications/notification.service';
import { ProgramReportService } from 'src/app/core/services/program-report.service';

@Component({
    selector: 'app-report-show',
    templateUrl: './program-report-show-step.component.html',
    styleUrls: ['./program-report-show-step.component.scss']
})
export class ProgramReportShowStepComponent extends AbstractComputor implements OnInit {
    public static computorName = COMPUTOR_NAMES.oldComputor;

    constructor(
        protected switchUserService: SwitchUserService,
        private route: ActivatedRoute,
        private breadCrumbService: BreadcrumbService,
        private folderService: FolderService,
        public loading: LoadingService,
        public auth: AuthService,
        private programReportService: ProgramReportService,
        private notification: NotificationService,
        private modalService: ModalService
    ) {
        super(switchUserService);

        this.computorType = COMPUTOR_TYPES.program;
        this.computorName = COMPUTOR_NAMES.oldComputor;

        this.folderProgram = this.route.snapshot.data.folderProgramResource as FolderProgramResource ?? null;
        this.tenderSession = this.folderProgram.data.tenderSession;

        this.folderService.setProgramResource(this.folderProgram);
        this.folderService.addCurrentReport(this.programReportResource.data.currentReport);
    }

    ngOnInit(): void {
        this.initBreadcrumb();
    }

    public reportNotCompleted(): boolean {
        return 'completed' !== this.programReportResource.data.marking;
    }


    public get programReportResource(): ProgramReportResource {
        return this.folderProgram.data.programReportResource;
    }

    public exportPdf(): void {
        this.programReportService.pdf(
            IriUtil.extractId(this.programReportResource['@id']),
            this.programReportResource.data.currentReport.marking,
            'pdf_program_report_exporter'
        ).subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        }, () => {
            this.notification.error('toast.an_error_occurred_while_generating_pdf');
        });
    }

    public openReportSettingsDialog(): void {
        this.modalService.openReportSettingsDialog(this.folderLink, this.programReportResource);
    }

    private initBreadcrumb(): void {
        this.breadCrumbService.reportView(this.folderLink);
    }

    get folderLink(): string {
        if (!this.auth.isAdmin()) {
            return '/sessions/' + IriUtil.extractId(this.folderProgram.data.tenderSession['@id'])
                + '/programs/' + String(this.folderProgram.data.id) + '/view';
        }

        return '/admin/programs/' + this.folderProgram.data.id.toString();
    }

}
