import { Component } from '@angular/core';
import { PageTitleService } from '../../../../core/services/page-title.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

    constructor(
        private pageTitleService: PageTitleService
    ) {
        this.pageTitleService.title('Dashboard');
    }

}
