<app-card cardTitle="title.documents" [cardNumber]="sectionNumber">
    <div class="media-groups">
        <ng-container *ngIf="mediaObjects && mediaObjects.length > 0">
            <mat-list>
                <mat-list-item *ngFor="let mediaObject of mediaObjects; let j = index; last as last">
                    <p matLine>{{ mediaObject.originalName }}</p>
                    <button mat-icon-button>
                        <mat-icon class="mat-24" (click)="downloadMediaObject(mediaObject)">cloud_download</mat-icon>
                    </button>

                    <mat-divider [inset]="true" *ngIf="!last"></mat-divider>
                </mat-list-item>
            </mat-list>
        </ng-container>
        <ng-container *ngIf="!mediaObjects || mediaObjects.length === 0">
            <p>{{ 'message.no_document' | translate }}</p>
        </ng-container>
    </div>
</app-card>
