import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { MenuItem } from '../models/menu-item.model';
import { Router } from '@angular/router';
import { AdminMenuService } from './admin-menu.service';
import { AuthService } from './auth.service';


@Injectable()
export class MenuService {
    private menuItemsSubject = new ReplaySubject<MenuItem[]>(1);
    public menuItems$ = this.menuItemsSubject.asObservable(); // eslint-disable-line

    constructor(
        private router: Router,
        private authService: AuthService,
    ) {
    }

    public store(items: MenuItem[]): void {
        this.menuItemsSubject.next(items);
    }

    public get(): Observable<MenuItem[]> {
        return this.menuItems$;
    }

    /**
     * Method that check if the current router URL is an admin URL
     * stores the menu in function of that condition
     */
    public createMenuFromUrl(): void {
        const userRoles = this.authService.getUser().roles;

        if (this.router.url.indexOf('admin') > -1) {
            this.store(AdminMenuService.getAdminMenu(userRoles));
        } else {
            this.store([
                { link: '/dashboard', label: 'menu.dashboard', icon: 'icon-home', subItems: []},
            ]);
        }
    }
}
