<div *ngIf="form" [formGroup]="form">
    <div class="col-md-12 d-flex align-items-center">
        <p class="costs-title">{{'fix_costs.'+groupName | translate}}</p>
        <span class="hint-help">
          <mat-icon matTooltip="{{'fix_costs.'+groupName+'.tooltip' | translate}}" matTooltipPosition="right">help</mat-icon>
        </span>
    </div>
    <div formGroupName="cost">
        <mat-form-field class="col-8">
            <mat-label>{{'word.realised_requested_contribution' | translate}}</mat-label>
            <input matInput formControlName="requestedContribution" type="number" autocomplete="off" (change)="executeCalculation()"/>
            <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
            <mat-error *ngIf="form.get('cost.requestedContribution').hasError('lessThan')">
                {{'validator.fix_cost_requested_contribution' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="col-4 mat-form-field-appearance-legacy mat-form-field-disabled">
            <mat-label>{{'word.balance' | translate}}</mat-label>
            <input matInput formControlName="balance" type="number" autocomplete="off" [readonly]="true"/>
            <span class="mat-input-suffix" matSuffix>{{ 'word.chf' | translate }}</span>
        </mat-form-field>
    </div>
</div>
