import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { ComputorFactory } from '../../../../computors/computor.factory';
import { PageTitleService } from '../../../../core/services/page-title.service';
import { ActivatedRoute } from '@angular/router';
import { TenderSession } from '../../../../core/models/tender-session.model';

@Component({
    selector: 'app-project',
    templateUrl: './project-show.component.html',
    styleUrls: ['./project-show.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectShowComponent implements AfterViewInit, AfterViewChecked {
    @ViewChild('computorComponentRef', {
        read: ViewContainerRef
    })
    public viewContainerRef: ViewContainerRef;

    public tenderSession: TenderSession;

    constructor(
        private pageTitleService: PageTitleService,
        private cdr: ChangeDetectorRef,
        private activatedRoute: ActivatedRoute,
        @Inject(ComputorFactory) private factory: ComputorFactory,
    ) {
        pageTitleService.title(''); // needed to hide title and show breadcrumb. => find a better way to do this.
        this.tenderSession = this.activatedRoute.parent.snapshot.data.tenderSession as TenderSession;
    }

    public ngAfterViewInit(): void {
        this.factory.loadComputorComponent(this.viewContainerRef, this.tenderSession.tenderModel.computerName, 'ProjectShowComponent');
    }

    public ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }

}
