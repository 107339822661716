<app-card>
    <mat-card-header *ngIf="!showingFolder">
        <mat-card-title>
            <a (click)="showFolder(folder)">{{ currentFolderName }}</a>
        </mat-card-title>
        <div class="header-action">
            <button mat-button (click)="showFolder(folder)">
                {{ 'action.project_follow_up' | translate }}
                <mat-icon>chevron_right</mat-icon>
            </button>
        </div>
        <mat-card-subtitle>{{ folder.data.reference ? folder.data.reference + ' / ' : '' }}{{ currentFolderAcronym }}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-header *ngIf="showingFolder">
        <div mat-card-avatar class="rounded-number">1</div>
        <mat-card-title>{{ 'title.milestones' | translate }}</mat-card-title>
    </mat-card-header>

    <div class="folder-detail-wrapper scrollX">
        <div class="d-flex justify-content-between align-items-start">
            <div class="flex-fill child-folders">
                <div class="d-flex justify-content-between">
                    <!--
                        Passed folders, without completed
                    -->
                    <ng-container *ngFor="let step of folder.folderSteps.before">
                        <div class="step p-2" *ngIf="step['@permissions'].view">
                            <!--<div class="icon passed icon-link" (click)="viewOrEdit(childFolder)">-->
                            <div class="icon passed icon-link" (click)="viewOrEdit(step)">
                                <i class="{{ step['@permissions'].edit ? 'icon-edit' : 'icon-eye' }}"></i>
                            </div>
                            <p class="description mb-1">{{ 'workflow.' + step.columnName | translate }}</p>
                            <p class="date">{{ step.updatedAt | localizedDate }}</p>
                        </div>
                    </ng-container>

                    <ng-container *ngFor="let step of milestonesAndReports">
                        <div class="complete-folder">
                            <div class="d-flex justify-content-end">
                                <div class="step step-report p-2" *ngIf="step['@permissions'].view">
                                    <div class="icon passed" (click)="viewOrEditReport(step)"
                                         [class.icon-link]="step['@type'] === 'Report'">
                                        <i *ngIf="step['@type'] === 'Report'"
                                           class="{{ step['@permissions'].edit ? 'icon-edit' : 'icon-eye' }}"
                                           (contextmenu)="onContextMenu($event, step, null)"></i>
                                        <i *ngIf="step['@type'] === 'Milestone'" class="cursor-pointer icon-push-pin"
                                           (contextmenu)="onContextMenu($event, null, step)"></i>
                                    </div>
                                    <p class="description mb-1">{{ step.name }}</p>
                                    <p class="date">{{ step.dueDate | localizedDate }}</p>
                                </div>
                            </div>
                        </div>
                    </ng-container>


                    <ng-container *appIsGranted="'ROLE_STAFF_PKW'">
                        <div class="reports" *ngIf="folder.currentMarking === 'in_progress'">
                            <div class="step step-report align-items-center">
                                <span class="icon material-icons cs-pointer"
                                      matTooltip="{{ 'action.add_milestone' | translate }}"
                                      (click)="addMilestone()">add</span>
                            </div>
                        </div>
                    </ng-container>

                    <!--
                        Passed folders, only completed
                    -->
                    <ng-container *ngFor="let step of folder.folderSteps.after">
                        <div class="complete-folder">
                            <div class="d-flex justify-content-end">
                                <div class="step p-2" *ngIf="step['@permissions'].view">
                                    <div class="icon passed icon-link" (click)="viewOrEdit(step)">
                                        <i class="{{ step.isCurrent && step['@permissions'].edit ? 'icon-edit' : 'icon-eye' }}"></i>
                                    </div>
                                    <p class="description mb-1">{{ 'workflow.' + step.columnName | translate }}</p>
                                    <p class="date">{{ step.createdAt | localizedDate }}
                                        ({{ 'word.' + step.closingReason.reason | translate }})</p>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!--
                        Next marking
                    -->
                    <div class="next-folder" *ngIf="folder.nextMarking">
                        <div class="step p-2">
                            <div class="icon view"></div>
                            <p class="description mb-1">{{ 'workflow.' + folder.nextMarking | translate }}</p>
                        </div>
                    </div>
                </div>

                <div class="bar"></div>
            </div>
        </div>
    </div>
</app-card>
<div style="position: fixed; visibility: hidden;"
     [style.left]="contextMenuPosition.x"
     [style.top]="contextMenuPosition.y"
     [matMenuTriggerFor]="contextMenu">
</div>
<mat-menu #contextMenu="matMenu">
    <ng-template matMenuContent>
        <button mat-menu-item (click)="editMilestone()">
            <mat-icon>edit_outline</mat-icon>
            {{'action.edit' | translate}}</button>
    </ng-template>
</mat-menu>
