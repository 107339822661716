<div class="dashboard-layout">
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport="true"
                 [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
                 [mode]="(isHandset$ | async) ? 'over' : 'side'"
                 [opened]="(isLargeAndUp$ | async) === true">

      <div class="d-flex flex-column h-100">
        <mat-toolbar class="logo my-4" [routerLink]="(isAdminRoute ? '/admin' : '') + '/dashboard'">
          <img src="assets/images/logo.svg" alt="ProKilowatt">
        </mat-toolbar>

        <mat-action-list class="sidenav-main-menu h-100">
          <ng-container *ngFor="let item of this.menuService.menuItems$ | async">
            <mat-list-item class="d-flex justify-content-center" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" [routerLink]="item.link" *ngIf="!item.isButton">
              <i class="{{ item.icon }} mr-3"></i>
              <button>
                {{ item.label | translate }}
              </button>
            </mat-list-item>
            <mat-list class="sidenav-main-menu-sub-items" *ngIf="item.subItems.length != 0">
              <div *ngFor="let subItem of item.subItems">
                <mat-list-item>
                  <i class="{{ subItem.icon }} mr-3"></i>
                  {{ subItem.label }}
                </mat-list-item>
              </div>
            </mat-list>
            <ng-container *ngIf="item.isButton">
              <div class="sidenav-main-menu-buttons-wrp">
                <button mat-flat-button color="accent" [routerLink]="item.link">
                  {{ item.label | translate }}
                  <i class="{{item.icon}} ml-3"></i>
                </button>
              </div>
            </ng-container>
          </ng-container>
        </mat-action-list>

        <div class="footer px-2 mb-4 text-center">
            © {{ currentYear }} powered by <a href="https://tokiwi.ch" target="_blank">Tokiwi</a>
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content mat-drawer-content mat-sidenav-content">
      <app-switch-user-component></app-switch-user-component>

      <div class="progress-bar-wrapper">
        <mat-progress-bar mode="indeterminate" *ngIf="loadingService.loading$ | async"></mat-progress-bar>
      </div>

      <mat-toolbar color="primary" class="md-tall">
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="(isLargeAndUp$ | async) === false">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <span *ngIf="(isLargeAndUp$ | async) === false">PKW</span>

        <ng-container *ngIf="(isLargeAndUp$ | async) && '' !== (pageTitle.getTitle())">
          <h1 class="title">{{ pageTitle.getTitle() }}</h1>
        </ng-container>

        <ng-container *ngIf="'' === (pageTitle.getTitle())">
          <app-breadcrumb [showBreadcrumb]="(isLargeAndUp$ | async)"></app-breadcrumb>
        </ng-container>

        <span class="spacer"></span>

        <ng-container *ngIf="true === (authService.isAuthenticated | async)">
          <button mat-button class="d-inline dashboard-avatar user-menu" [matMenuTriggerFor]="desktop_menu">
            {{ 'word.my_account' | translate }}

            <i class="icon-person ml-2"></i>
          </button>

          <mat-menu #desktop_menu="matMenu">
            <button mat-menu-item (click)="editProfile()">
              {{'word.profile' | translate}}
            </button>
            <button mat-menu-item (click)="logout()">
              <span>{{ 'action.logout' | translate }}</span>
            </button>
          </mat-menu>

          <button mat-button class="d-inline dashboard-avatar user-menu" [matMenuTriggerFor]="language_menu">
            {{ (i18n.currentLangObs | async) | uppercase }}
            <mat-icon>expand_more</mat-icon>
          </button>
          <mat-menu #language_menu="matMenu">
            <button *ngFor="let language of languages; last as last"  mat-menu-item (click)="changeLanguage(language.locale)">
              <span>{{ 'word.' + language.locale | translate }}</span>
              <mat-divider [inset]="false" *ngIf="!last"></mat-divider>
            </button>
          </mat-menu>
        </ng-container>
      </mat-toolbar>

      <div class="sidenav-content-inner">
        <!-- Module content would be loaded here -->
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
