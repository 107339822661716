import { Component, OnInit } from '@angular/core';
import { PageTitleService } from '../../../../../../core/services/page-title.service';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import { TranslateService } from '@ngx-translate/core';
import { FolderProgramResource } from '../../../../../../core/models/folder-program-resource.model';
import { ActivatedRoute } from '@angular/router';
import {
    AnalysisCost,
    FIX_COST_TYPE_ACCOMPANIMENT,
    FIX_COST_TYPE_MANAGEMENT,
    FixCost
} from '../../../../../../core/models/fixCost.model';
import { MediaObject } from '../../../../../../core/models/media-object.model';
import { ProgramMeasure } from '../../../../../../core/models/measure.model';
import { ProgramComputedValues } from '../../../../../../core/models/computed-values.model';
import { LoadingService } from '../../../../../../core/services/loading.service';
import { IriUtil } from '../../../../../../core/utils/iri.util';
import { HttpResponse } from '@angular/common/http';
import { DownloaderHelper } from '../../../../../../core/utils/download.util';
import { FolderProgramService } from '../../../../../../core/services/folder-program.service';
import { COMPUTOR_NAMES, FolderService } from '../../../../../../core/services/folder.service';
import { NotificationService } from '../../../../../../core/notifications/notification.service';
import AbstractComputor from '../../../../../abstract-computor';
import { SwitchUserService } from '../../../../../../core/services/switch-user.service';

@Component({
    selector: 'app-admin-program-show-step',
    templateUrl: './admin-program-show-step.component.html',
    styleUrls: ['./admin-program-show-step.component.scss']
})
export class AdminProgramShowStepComponent extends AbstractComputor implements OnInit {
    public static computorName = COMPUTOR_NAMES.computor2024Big;

    public folder: FolderProgramResource;
    public folderProgramResource: FolderProgramResource;

    constructor(
        private folderService: FolderService,
        private title: PageTitleService,
        private breadcrumbService: BreadcrumbService,
        private translator: TranslateService,
        private route: ActivatedRoute,
        private folderProgramService: FolderProgramService,
        private notificationService: NotificationService,
        public loading: LoadingService,
        public switchUserService: SwitchUserService
    ) {
        super(switchUserService);

        this.folder = this.route.snapshot.data.folderProgramResource as FolderProgramResource;
    }

    ngOnInit(): void {
        this.folderProgramResource = this.route.snapshot.data.folderProgramResource as FolderProgramResource;

        this.folderService.setProgramResource(this.folderProgramResource);

        this.initBreadcrumb();
    }

    public get managementFixCosts(): FixCost[] {
        return this.folder.data.currentProgram.fixCosts.filter(fixCost => FIX_COST_TYPE_MANAGEMENT === fixCost.type);
    }

    public get accompanimentFixCosts(): FixCost[] {
        return this.folder.data.currentProgram.fixCosts.filter(fixCost => FIX_COST_TYPE_ACCOMPANIMENT === fixCost.type);
    }

    public get analysisCosts(): AnalysisCost {
        return this.folder.data.currentProgram.analysisCosts;
    }

    public get computedValues(): ProgramComputedValues {
        return this.folder.data.currentProgram.computedValues;
    }

    public get measures(): ProgramMeasure[] {
        return this.folder.data.currentProgram.measures;
    }

    public get mediaObjects(): MediaObject[]|null {
        return this.folder.data.currentProgram.mediaObjects ?? null;
    }

    public exportPdf(): void {
        this.folderProgramService.pdf(
            IriUtil.extractId(this.folderProgramResource['@id']),
            this.folderProgramResource.data.currentProgram.marking,
            'pdf_program_exporter'
        ).subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        }, () => {
            this.notificationService.error('toast.an_error_occurred_while_generating_pdf');
        });
    }

    private initBreadcrumb(): void {
        this.breadcrumbService.folderStep(this.folderLink);
    }

    get folderLink(): string {
        return '/admin/programs/' + this.folder.data.id.toString();
    }
}
