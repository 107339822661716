import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { FolderProjectService } from '../services/folder-project.service';

@Injectable()
export class FolderStateResolver {
    constructor(
        private folderProjectService: FolderProjectService
    ) { }
    public resolve(
        route: ActivatedRouteSnapshot
    ): Observable<any> {
        return this.folderProjectService.find(route.paramMap.get('folderId'), route.paramMap.get('stateId'));
    }
}
