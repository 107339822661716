import { BaseDatasource } from './base.datasource';
import { environment } from '../../../environments/environment';
import { Paginator } from '../models/paginator.model';
import { Sort } from '@angular/material/sort';
import { RemarkService } from '../services/remark.service';

export class RemarkDatasource extends BaseDatasource {
    constructor(
        private remarkService: RemarkService
    ) {
        super();
    }

    public loadRemarksByFolderId(folderId: number): void {
        this.loadingSubject.next(true);

        this.remarkService.getRemarkList(folderId.toString()).subscribe((paginator: Paginator) => {
            this.totalItems = paginator['hydra:totalItems'];
            this.itemsPerPage = this.totalItems;
            this.subject.next(paginator['hydra:member']);
            this.loadingSubject.next(false);
        });
    }

    public loadRemarksPaginated(page = 1, itemsPerPage: number = environment.itemsPerPage, search = '', sort?: Sort): void {
        this.loadingSubject.next(true);

        this.remarkService.getRemarksPaginated(page, itemsPerPage, search, sort).subscribe((result: Paginator) => {
            this.totalItems = result['hydra:totalItems'];
            this.itemsPerPage = itemsPerPage;
            this.subject.next(result['hydra:member']);
            this.loadingSubject.next(false);
        });
    }
}
