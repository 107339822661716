import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../../core/services/user.service';
import { NotificationService } from '../../../core/notifications/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../../../core/services/loading.service';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
})

export class ResetPasswordComponent implements OnInit, OnDestroy {
    public showPassword = false;

    public requiredLength = {
        password: 6
    };

    public form = this.fb.group({
        password: [null, [Validators.required, Validators.minLength(this.requiredLength.password)]]
    });

    public hidePassword: boolean;
    private subscriptions: Subscription[] = [];
    private uuid: string;

    constructor(
        public loading: LoadingService,
        private userService: UserService,
        private fb: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private notificationService: NotificationService,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.uuid = String(this.activatedRoute.snapshot.params.uuid);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => {
            subscription.unsubscribe();
        });
    }

    resetPassword(): void {
        const errors = this.form.get('password').errors;
        const password = String(this.form.get('password').value);
        if ( null === errors ) {
            this.subscriptions.push(
                this.userService.setNewPassword(password, this.uuid).subscribe(() => {
                    this.notificationService.success(this.translate.instant('toast.password_success'));
                    void this.router.navigateByUrl('/');
                }, () => {
                    this.notificationService.error(this.translate.instant('toast.password_error'));
                    void this.router.navigateByUrl('/');
                })
            );
        } else {
            this.form.get('password').markAsTouched();
        }
    }

}
