<div class="row" [formGroup]="form" style="min-width: 40vw;">
    <div class="col-md-12">
        <h1 class="mat-dialog-title">{{'action.add_new_invoice' | translate}}</h1>
    </div>
    <div class="col-md-12" *ngIf="hasSelectedFile">
        <mat-form-field>
            <mat-label>{{ 'word.invoice_name' | translate }}</mat-label>
            <input matInput formControlName="name" required>
        </mat-form-field>
    </div>
    <button mat-raised-button *ngIf="!hasSelectedFile" class="col-md-6 mx-auto my-3" (click)="addMediaObject()">
        {{ 'word.select_invoice' | translate }}
        <mat-icon class="ml-2">cloud_upload</mat-icon>
    </button>
    <div class="col-md-12 d-flex justify-content-end mt-2">
        <button mat-flat-button (click)="dialogRef.close(null)" class="cancel-button mr-2">{{ 'action.close' | translate }}</button>
        <button mat-flat-button (click)="save()" color="primary" [disabled]="!hasSelectedFile" appMatButtonLoading [loading]="loading.loading$ | async">{{ 'action.save' | translate }}</button>
    </div>
</div>

<input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file">
