import { NgModule } from '@angular/core';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MatChipsModule } from '@angular/material/chips';
import { MatCardModule } from '@angular/material/card';
import { SharedModule } from '../../../shared/shared.module';
import { MatTableModule } from '@angular/material/table';
import { ProjectComponent } from './project/project.component';
import { ProgramComponent } from './program/program.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { FolderDetailModule } from './folder-detail/folder-detail.module';
import { PipesModule } from '../../../core/pipes/pipes.module';
import { ComponentsModule } from '../../../components/components.module';


@NgModule({
    declarations: [
        DashboardComponent,
        ProjectComponent,
        ProgramComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        FolderDetailModule,
        DashboardRoutingModule,
        MatButtonModule,
        MatIconModule,
        MatInputModule,
        MatTabsModule,
        MatTableModule,
        MatChipsModule,
        ReactiveFormsModule,
        TranslateModule,
        MatCardModule,
        SharedModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatMenuModule,
        PipesModule,
        ComponentsModule,
    ],
    providers: [],
    exports: [],
    bootstrap: [DashboardComponent]
})
export class DashboardModule {
}
