<div class="title">
  <mat-icon [class.error]="hasViolations() && !isReport" [class.report-error]="hasViolations() && isReport">{{ hasViolations() ? 'error_outline' : 'check_circle' }}</mat-icon>
  <div class="count" *ngIf="hasViolations()">{{ 'word.n_errors'|translate:{count: violationsCount} }}</div>
  <div *ngIf="!hasViolations()">{{ 'field_checker.ready_to_next_step' | translate }}</div>
</div>

<mat-list dense *ngIf="hasViolations()" class="errors">
  <mat-list-item *ngFor="let violation of violations" class="error" [class.report-error]="isReport">
    <h3 matLine>{{ violation.message }}</h3>
    <p matLine *ngIf="!violation.translatedParts.length && violation.property !== ''">
      <span class="mat-small">{{ 'word.' + ((violation.payload ? violation.payload.property : violation.property) | snakeCase) | translate }}</span>
    </p>
    <div matLine class="translated-path-wrp" *ngIf="violation.translatedParts.length" [innerHTML]="violation.translatedParts.join('')">
    </div>
  </mat-list-item>
</mat-list>
