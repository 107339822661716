<h1 mat-dialog-title>{{ 'word.change_state' | translate }}</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-radio-group aria-label="Select a state" (change)="onChange($event)" *ngIf="enabledTransitions" formControlName="transition">
          <div class="row status-wrapper">
              <div class="col-md-12">
                  <mat-radio-button value="to_draft" [disabled]="!isTransitionEnabled('to_draft')" [checked]="currentResource.data.marking == 'draft'" >
                      <span class="status-color draft"></span> <span class="state-title">{{ 'workflow.admin.draft' | translate }}</span>
                  </mat-radio-button>
              </div>
              <div class="col-md-12">
                  <p class="state-text">{{ 'dialog.change_folder_state.draft.p0' | translate }}</p>
              </div>
          </div>

          <div class="row status-wrapper">
              <div class="col-md-12">
                  <ng-container *ngIf="validateSwitchUser(); else validate_switch_user">
                      <mat-radio-button value="validate" [disabled]="!isTransitionEnabled('validate')" [checked]="currentResource.data.marking == 'validation_1'">
                          <span class="status-color validation_1"></span> <span class="state-title">{{ 'workflow.admin.validation_1' | translate }}</span>
                      </mat-radio-button>
                  </ng-container>
                  <ng-template #validate_switch_user>
                      <mat-radio-button appSwitchUser [nextTransition]="'validate'" [canSwitchUser]="isTransitionEnabled('validate')" [startImpersonate]="currentResource.data.owner.email" [redirectUrl]="getImpersonateUrl()" (userSwitched)="closeDialog()" value="validate" [disabled]="!isTransitionEnabled('validate')" [checked]="currentResource.data.marking == 'validation_1'">
                          <span class="status-color validation_1"></span> <span class="state-title">{{ 'workflow.admin.validation_1' | translate }}</span> <i class="icon icon-search-shield"></i>
                      </mat-radio-button>
                  </ng-template>
              </div>
              <div class="col-md-12">
                  <p class="state-text">{{ 'dialog.change_folder_state.validation_1.p0' | translate }}</p>
              </div>
          </div>
          <div class="row status-wrapper">
              <div class="col-md-12">
                  <mat-radio-button value="ask_for_questions_answers" [disabled]="!isTransitionEnabled('ask_for_questions_answers')" [checked]="currentResource.data.marking == 'questions_answers'">
                      <span class="status-color questions"></span> <span class="state-title">{{ 'workflow.admin.questions' | translate }}</span>
                  </mat-radio-button>
              </div>
              <div class="col-md-12">
                  <p class="state-text">{{ 'dialog.change_folder_state.questions_answers.p0' | translate }}</p>
              </div>
          </div>
          <div class="row status-wrapper">
              <div class="col-md-12">
                <ng-container *ngIf="validateAnswersSwitchUser(); else validate_answers_switch_user">
                  <mat-radio-button value="validate_answers" [disabled]="!isTransitionEnabled('validate_answers')" [checked]="currentResource.data.marking == 'validation_2'">
                    <span class="status-color validation_2"></span> <span class="state-title">{{ 'workflow.admin.validation_2' | translate }}</span>
                  </mat-radio-button>
                </ng-container>
                <ng-template #validate_answers_switch_user>
                  <mat-radio-button appSwitchUser [nextTransition]="'validate_answers'" [canSwitchUser]="isTransitionEnabled('validate_answers')" [startImpersonate]="currentResource.data.owner.email" [redirectUrl]="getImpersonateUrl()" (userSwitched)="closeDialog()" value="validate_answers" [disabled]="!isTransitionEnabled('validate_answers')" [checked]="currentResource.data.marking == 'validation_2'">
                    <span class="status-color validation_2"></span> <span class="state-title">{{ 'workflow.admin.validation_2' | translate }}</span> <i class="icon icon-search-shield"></i>
                  </mat-radio-button>
                </ng-template>
              </div>
              <div class="col-md-12">
                  <p class="state-text">{{ 'dialog.change_folder_state.validation_2.p0' | translate }}</p>
              </div>
          </div>
          <div class="row status-wrapper">
              <div class="col-md-12">
                  <mat-radio-button value="progress" [disabled]="!isTransitionEnabled('progress')" [checked]="currentResource.data.marking == 'in_progress'">
                      <span class="status-color in_progress"></span> <span class="state-title">{{ 'workflow.admin.in_progress' | translate }}</span>
                  </mat-radio-button>
              </div>
              <div class="col-md-12">
                  <p class="state-text">{{ 'dialog.change_folder_state.in_progress.p0' | translate }}</p>
              </div>
          </div>
          <div class="row status-wrapper">
              <div class="col-md-12">
                  <mat-radio-button value="complete" [disabled]="!isTransitionEnabled('complete')" [checked]="currentResource.data.marking == 'completed'">
                      <span class="status-color completed"></span> <span class="state-title">{{ 'workflow.admin.completed' | translate }}</span>
                  </mat-radio-button>
              </div>
              <div class="col-md-12">
                  <p class="state-text">{{ 'dialog.change_folder_state.completed.p0' | translate }}</p>
              </div>

            <ng-container *ngIf="form.contains('explanation')">
              <div class="col-md-12">
                  <mat-form-field color="primary">
                    <mat-select
                                formControlName="explanation"
                                placeholder="{{ 'word.complete_state_explanation' | translate }}"
                    >
                      <mat-option value="not_deposited">{{ 'word.not_deposited' | translate }}</mat-option>
                      <mat-option value="took_of">{{ 'word.took_of' | translate }}</mat-option>
                      <mat-option value="declined">{{ 'word.declined' | translate }}</mat-option>
                      <mat-option value="out_of_budget">{{ 'word.out_of_budget' | translate }}</mat-option>
                      <mat-option value="canceled">{{ 'word.canceled' | translate }}</mat-option>
                      <mat-option value="executed">{{ 'word.executed' | translate }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.get('explanation').hasError('required')">
                      {{ 'validator.explanation.not_blank' | translate }}
                    </mat-error>
                  </mat-form-field>
              </div>
            </ng-container>
          </div>
          <div class="row status-wrapper">
              <ng-container *ngIf="showBackwardStateWarning">
                  <div class="col-md-12">
                      <p class="warning">
                          {{ 'message.back_to_previous_step' | translate }}
                      </p>
                  </div>
              </ng-container>
          </div>
      </mat-radio-group>
  </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close class="cancel-button mr-2">{{ 'action.close' | translate }}</button>
    <button mat-flat-button appMatButtonLoading color="accent" class="btn-white" [disabled]="currentTransition === null" (click)="changeState()" [loading]="loading.loading$ | async">
        {{ 'action.save' | translate }}
    </button>
</div>


