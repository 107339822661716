import { Component, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { IriUtil } from '../../../../core/utils/iri.util';
import { AddTodoDialogComponent } from '../../../../shared/add-todo-dialog/add-todo-dialog.component';
import { TodoListComponent } from '../todo-list/todo-list.component';
import { FolderProjectResource } from '../../../../core/models/folder-project-resource.model';
import { FolderProgramResource } from '../../../../core/models/folder-program-resource.model';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-todo-small-list',
    templateUrl: './todo-small-list.component.html',
    styleUrls: ['./todo-small-list.component.scss']
})
export class TodoSmallListComponent extends TodoListComponent implements OnDestroy, AfterViewInit {
    @Input()
    folder: FolderProjectResource|FolderProgramResource;

    displayedColumns = ['doneAction', 'description', 'assinedUser', 'createdAt', 'deadline', 'urgentMark', 'actions'];

    ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    showNewTodoDialog(): void {
        const dialog = this.dialog.open(AddTodoDialogComponent, {autoFocus: false, data: {folder: this.folder}});
        dialog.afterClosed().subscribe((data: {hasSaved: boolean}) => {
            if (data && data.hasSaved) {
                this.loadTodosPaginated();
            }
        });
    }

    editTodo(todoIri: string): void {
        super.editTodo(todoIri, this.folder);
    }

    ngAfterViewInit(): void {
        this.paginator.page.pipe(takeUntil(this.destroySubject)).subscribe(() => this.loadTodosPaginated());
        this.viewArchivedTODOs$.pipe(takeUntil(this.destroySubject)).subscribe(() => { // subscribe to checkbox click changes
            this.loadTodosPaginated();
        });
    }

    loadTodosPaginated(): void {
        if (!this.folder) {
            this.todoDatasource.loadTODOsPaginated(this.viewArchivedTODOs.getValue(),
                this.paginator.pageIndex + 1, this.paginator.pageSize);
            return;
        }

        this.todoDatasource.loadFolderTODOsPaginated(IriUtil.extractId(String(this.folder.data.id)), this.viewArchivedTODOs.getValue(),
            this.paginator.pageIndex + 1, this.paginator.pageSize);
    }
}
