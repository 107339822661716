<div class="row" [formGroup]="form">
    <div class="col-md-12">
        <h1 class="mat-dialog-title" *ngIf="!data.tenderSession">{{'title.new_tender_session' | translate}}</h1>
        <h1 class="mat-dialog-title" *ngIf="data.tenderSession">{{'title.edit_tender_session' | translate:{name: data.tenderSession.name} }}</h1>
    </div>
    <div class="col-md-6">
        <mat-form-field>
            <mat-label>{{ 'word.type' | translate }}</mat-label>
            <mat-select formControlName="tenderModel">
                <mat-option>
                    <ngx-mat-select-search [formControl]="tenderModelSearch"
                                           placeholderLabel="{{ 'word.search' | translate }}"
                                           noEntriesFoundLabel="{{'message.no_result'|translate}}">
                    </ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="let model of availableTenderModels" [value]="model['@id']">{{ model.name }}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'word.tender_session_name' | translate }}</mat-label>
            <input matInput formControlName="name">
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{ 'word.description' | translate }}</mat-label>
            <input matInput formControlName="description" maxLength="255" #description>
            <mat-hint align="end">{{ description.value.length }} / 255</mat-hint>
        </mat-form-field>
    </div>
    <div class="col-md-6">
        <mat-form-field class="col-md-12">
            <mat-label>{{ 'tender_session.' + data.tenderSessionType + '.opening_date' | translate }}</mat-label>
            <input matInput
                   [matDatepicker]="openingDate"
                   formControlName="openingDate"
                   type="text"
                   autocomplete="off">
            <mat-datepicker-toggle matSuffix [for]="openingDate"></mat-datepicker-toggle>
            <mat-datepicker #openingDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="col-md-12">
            <mat-label>{{ 'tender_session.' + data.tenderSessionType + '.submission_end_date' | translate }}</mat-label>
            <input matInput
                   [matDatepicker]="submissionEndDate"
                   formControlName="submissionEndDate"
                   type="text"
                   autocomplete="off">
            <mat-datepicker-toggle matSuffix [for]="submissionEndDate"></mat-datepicker-toggle>
            <mat-datepicker #submissionEndDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="col-md-6">
            <mat-label>{{ 'tender_session.' + data.tenderSessionType + '.question_round_start_date' | translate }}</mat-label>
            <input matInput
                   [matDatepicker]="questionRoundStartDate"
                   formControlName="questionRoundStartDate"
                   type="text"
                   autocomplete="off">
            <mat-datepicker-toggle matSuffix [for]="questionRoundStartDate"></mat-datepicker-toggle>
            <mat-datepicker #questionRoundStartDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="col-md-6">
            <mat-label>{{ 'tender_session.' + data.tenderSessionType + '.question_round_end_date' | translate }}</mat-label>
            <input matInput
                   [matDatepicker]="questionRoundEndDate"
                   formControlName="questionRoundEndDate"
                   type="text"
                   autocomplete="off">
            <mat-datepicker-toggle matSuffix [for]="questionRoundEndDate"></mat-datepicker-toggle>
            <mat-datepicker #questionRoundEndDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="col-md-12">
            <mat-label>{{ 'tender_session.' + data.tenderSessionType + '.decision_date' | translate }}</mat-label>
            <input matInput
                   [matDatepicker]="decisionDate"
                   formControlName="decisionDate"
                   type="text"
                   autocomplete="off">
            <mat-datepicker-toggle matSuffix [for]="decisionDate"></mat-datepicker-toggle>
            <mat-datepicker #decisionDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="col-md-6">
            <mat-label>{{ 'tender_session.' + data.tenderSessionType + '.max_start_date' | translate }}</mat-label>
            <input matInput
                   [matDatepicker]="maxStartDate"
                   formControlName="maxStartDate"
                   type="text"
                   autocomplete="off">
            <mat-datepicker-toggle matSuffix [for]="maxStartDate"></mat-datepicker-toggle>
            <mat-datepicker #maxStartDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="col-md-6">
            <mat-label>{{ 'tender_session.' + data.tenderSessionType + '.max_end_date' | translate }}</mat-label>
            <input matInput
                   [matDatepicker]="maxEndDate"
                   formControlName="maxEndDate"
                   type="text"
                   autocomplete="off">
            <mat-datepicker-toggle matSuffix [for]="maxEndDate"></mat-datepicker-toggle>
            <mat-datepicker #maxEndDate></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="col-md-12 d-flex justify-content-end">
        <button mat-flat-button (click)="dialogRef.close(null)" class="cancel-button mr-2">{{ 'action.close' | translate }}</button>
        <button mat-flat-button (click)="addTenderSession()" color="primary" appMatButtonLoading [loading]="loading.loading$ | async">{{ 'action.save' | translate }}</button>
    </div>
</div>

