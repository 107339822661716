<div class="row">
    <div class="col-md-7">
        <!-- Fix costs -->
        <app-card cardTitle="word.fix_costs" cardNumber="1">
            <h2>{{'word.program_management' | translate}}</h2>
            <ng-container *ngFor="let fixCost of managementFixCosts">
                <app-report-readonly-fix-costs [fixCost]="fixCost" [validatedFolder]="validatedFolder"></app-report-readonly-fix-costs>
            </ng-container>
            <h2>{{'word.program_accompaniment' | translate}}</h2>
            <ng-container *ngFor="let fixCost of accompanimentFixCosts">
                <app-report-readonly-fix-costs [fixCost]="fixCost" [validatedFolder]="validatedFolder"></app-report-readonly-fix-costs>
            </ng-container>
        </app-card>


        <!-- Measures -->
        <app-card cardTitle="title.measures" cardNumber="2">
            <div *ngFor="let measure of measures; let i = index">
                <div class="dataset-measure fields-group">
                    <div class="header">
                        <h2>{{ 'word.measure_n' | translate:{count: i + 1} }}</h2>
                    </div>
                    <div class="content">
                        <div class="row">
                            <div class="col-md-6">
                                <p class="field-title">{{ 'word.technical_orientation' | translate }}</p>
                                <p class="text-justify">
                                <p>{{ measure.datasetMeasure.technicalOrientation ? measure.datasetMeasure.technicalOrientation.name : '-' }}</p>
                            </div>
                            <div class="col-md-6">
                                <p class="field-title">{{ 'word.duration_of_use' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.durationOfUse | default:'-' }} {{ 'word.years' | translate }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <p class="field-title">{{ 'word.engaged_requested_contribution' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.engagedRequestedContribution | currency:'CHF':'symbol':'0.0-2' | default:'-' }}</p>
                            </div>
                            <div class="col-md-6">
                                <p class="field-title">{{ 'word.realised_requested_contribution' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.realisedRequestedContribution | currency:'CHF':'symbol':'0.0-2' | default:'-' }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <p class="field-title">{{ 'word.engaged_total_economy' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.engagedTotalEconomy | positive | number | default: '-': ('word.kwh' | translate) }}</p>
                            </div>
                            <div class="col-md-6">
                                <p class="field-title">{{ 'word.realised_total_economy' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.realisedTotalEconomy | positive | number | default: '-': ('word.kwh' | translate) }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <p class="field-title">{{ 'word.total_economy_balance' | translate }}</p>
                                <p class="text-justify">{{measure.datasetMeasure.engagedTotalEconomy - measure.datasetMeasure.realisedTotalEconomy | number | default: '-': ('word.kwh' | translate) }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-card>

        <!--Analysis costs -->
        <app-card cardTitle="word.analysis_costs" cardNumber="3">
            <app-analysis-costs [analysisCosts]="analysisCosts" [showingReport]="validatedFolder"
                                [computedValues]="computedValues"></app-analysis-costs>
        </app-card>

        <app-documents-list id="section4" [mediaObjects]="mediaObjects" [sectionNumber]="4"
                            [reportIri]="programReportResource.data.currentReport.report['@id']"></app-documents-list>
    </div>
    <div class="col-md-5">
        <app-sticky-container>
            <app-computor2024Big-program-summary-card
                [folder]="programReportResource"></app-computor2024Big-program-summary-card>
        </app-sticky-container>
    </div>
</div>

<app-sticky-footer *ngIf="auth.getUser().isStaffPkw">
    <div class="w-50">
        <ng-container *ngIf="folderProgram">
            <button mat-flat-button color="primary" appMatButtonLoading (click)="exportPdf()"
                    [loading]="loading.loading$ | async">
                {{ 'action.pdf' | translate }} <i class="icon-pdf-file"></i>
            </button>
        </ng-container>
    </div>
    <div class="w-50 d-flex justify-content-end" *appIsGranted="'ROLE_STAFF_PKW'">
        <button mat-flat-button color="primary" class="align-self-end" (click)="openReportSettingsDialog()">
            {{ 'word.report_settings' | translate }} <i class="icon-filter"></i>
        </button>
    </div>
</app-sticky-footer>
