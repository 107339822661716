<div [formGroup]="form">
    <app-card cardNumber="4" cardTitle="word.fix_costs">
        <div class="table-scroll">
            <table class="table table-responsive col-md-12" formGroupName="currentReport" appTableCellHighlighter>
                <thead class="col-md-12">
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <th class="min-w-120" *ngIf="step['@permissions'].view">
                                {{ 'workflow.' + step['@columnName'] | translate }}
                            </th>
                        </ng-container>
                    </ng-container>
                </tr>
                </thead>
                <tbody formArrayName="fixCosts" *ngIf="computedResume.fixCosts.length">
                <!-- Wait for fixCosts to be calculated before we show them -->
                <ng-container *ngFor="let fixCost of programReportResource.data.currentReport.fixCosts; let i = index"
                              [formGroupName]="i">
                    <tr> <!-- Fix Cost Title -->
                        <ng-container *ngFor="let column of folderProgram.reportSteps; let first = first">
                            <!-- First column is validated folder -->
                            <th *ngIf="column['@permissions'].view">
                                <span *ngIf="first"
                                      class="font-weight-bold">{{'fix_costs.' + fixCost.groupName | translate}}</span>
                            </th>
                            <ng-container *ngFor="let step of column.steps">
                                <!-- Blank case for reports -->
                                <th *ngIf="!first && step['@permissions'].view"></th>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr formGroupName="cost">
                        <th>{{ 'word.total_cost' | translate }}</th>
                        <ng-container *ngFor="let column of folderProgram.reportSteps">
                            <ng-container *ngFor="let step of column.steps">
                                <td class="min-w-120" *ngIf="step['@permissions'].view"
                                    [class.input]="step['@permissions'].edit">
                                    {{step.fixCosts[i].computedValues.totalCost | currency:'CHF':'symbol':'0.0-2' | default:'-'}}
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr formGroupName="cost">
                        <th>{{ 'word.total_requested_contribution' | translate }}</th>
                        <ng-container *ngFor="let column of folderProgram.reportSteps">
                            <ng-container *ngFor="let step of column.steps">
                                <td class="min-w-120" *ngIf="step['@permissions'].view"
                                    [class.input]="step['@permissions'].edit">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{step.fixCosts[i].cost.requestedContribution | currency:'CHF':'symbol':'0.0-2' | default:'-'}}
                                    </ng-container>

                                    <ng-container *ngIf="step['@permissions'].edit">
                                        <mat-form-field>
                                            <input matInput
                                                   formControlName="requestedContribution"
                                                   type="number"
                                                   autocomplete="off"
                                                   (change)="executeCalculation()"/>
                                        </mat-form-field>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </div>
    </app-card>

    <!-- Measures -->
    <app-card cardNumber="5" cardTitle="title.measures">
        <ng-container *ngFor="let measure of measures; let index = index" formGroupName="currentReport">
            <div class="table-scroll mt-4">
                <h3 class="mb-1">{{ 'word.measure_n' | translate:{count: index + 1} }}</h3>

                <!-- ngIf used to wait for form creation -->
                <table class="table table-responsive w-100" appTableCellHighlighter
                       *ngIf="form.get('currentReport.reportMeasures')['controls'][index]">
                    <thead>
                    <tr>
                        <th class="w-25">{{ '' }}</th>
                        <ng-container *ngFor="let column of folderProgram.reportSteps">
                            <ng-container *ngFor="let step of column.steps">
                                <th class="min-w-120" *ngIf="step['@permissions'].view">
                                    {{ 'workflow.' + step['@columnName'] | translate }}
                                </th>
                            </ng-container>
                        </ng-container>
                    </tr>
                    </thead>
                    <tbody formArrayName="reportMeasures" *ngIf="computedResume.measures.length">
                    <tr>
                        <th>{{ 'word.technical_orientation' | translate }}</th>
                        <ng-container *ngFor="let column of folderProgram.reportSteps">
                            <ng-container *ngFor="let step of column.steps">
                                <td *ngIf="step['@permissions'].view">
                                    {{ step.measures[index].technicalOrientation.name | default:'-' }}
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{ 'word.description' | translate }}</th>
                        <ng-container *ngFor="let column of folderProgram.reportSteps">
                            <ng-container *ngFor="let step of column.steps">
                                <td *ngIf="step['@permissions'].view">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ step.measures[index].description | default:'-' }}
                                    </ng-container>

                                    <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                        <div class="dataset-measure fields-group" formGroupName="datasetMeasure">
                                            <mat-form-field>
                                                <input matInput
                                                       formControlName="description"
                                                       type="text"
                                                       autocomplete="off"/>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{ 'word.duration_of_use' | translate }}</th>
                        <ng-container *ngFor="let column of folderProgram.reportSteps">
                            <ng-container *ngFor="let step of column.steps">
                                <td *ngIf="step['@permissions'].view">
                                    {{ 'word.n_years' | translate:{count: step.measures[index].durationOfUse | default:'-'} }}
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{'word.realised_requested_contribution' | translate}}</th>
                        <ng-container *ngFor="let column of folderProgram.reportSteps">
                            <ng-container *ngFor="let step of column.steps">
                                <td *ngIf="step['@permissions'].view && step.measures[index]">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ (step.measures[index].realisedRequestedContribution || step.measures[index].requestedContribution) | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                                    </ng-container>

                                    <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                        <div class="dataset-measure fields-group" formGroupName="datasetMeasure">
                                            <mat-form-field>
                                                <mat-label>{{'word.realised_requested_contribution' | translate}}</mat-label>
                                                <input matInput
                                                       formControlName="realisedRequestedContribution"
                                                       type="number"
                                                       autocomplete="off"
                                                       min="{{ lengthValidators.positiveValue }}"
                                                       (change)="executeCalculation()"/>
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.chf' | translate }}</span>
                                                <mat-error
                                                    *ngIf="form.get('currentReport.reportMeasures')['controls'][index].get('datasetMeasure.realisedRequestedContribution').hasError('required')">
                                                    {{ 'validator.field.not_blank' | translate }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{'word.engaged_requested_contribution' | translate}}</th>
                        <ng-container *ngFor="let column of folderProgram.reportSteps">
                            <ng-container *ngFor="let step of column.steps">
                                <td *ngIf="step['@permissions'].view && step.measures[index]">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ (step.measures[index].engagedRequestedContribution || '') | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                                    </ng-container>

                                    <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                        <div class="dataset-measure fields-group" formGroupName="datasetMeasure">
                                            <mat-form-field>
                                                <mat-label>{{'word.engaged_requested_contribution' | translate}}</mat-label>
                                                <input matInput
                                                       formControlName="engagedRequestedContribution"
                                                       type="number"
                                                       autocomplete="off"
                                                       min="{{ lengthValidators.positiveValue }}"
                                                       (change)="executeCalculation()"/>
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.chf' | translate }}</span>
                                                <mat-error
                                                    *ngIf="form.get('currentReport.reportMeasures')['controls'][index].get('datasetMeasure.engagedRequestedContribution').hasError('required')">
                                                    {{ 'validator.field.not_blank' | translate }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{'word.realised_total_economy' | translate}}</th>
                        <ng-container *ngFor="let column of folderProgram.reportSteps">
                            <ng-container *ngFor="let step of column.steps">
                                <td *ngIf="step['@permissions'].view && step.measures[index]">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ (step.measures[index].realisedTotalEconomy || (step.measures[index].units * step.measures[index].totalSavings)) | number | default: '-': ('word.kwh' | translate) }}
                                    </ng-container>

                                    <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                        <div class="dataset-measure fields-group" formGroupName="datasetMeasure">
                                            <mat-form-field>
                                                <mat-label>{{'word.realised_total_economy' | translate}}</mat-label>
                                                <input matInput
                                                       formControlName="realisedTotalEconomy"
                                                       type="number"
                                                       autocomplete="off"
                                                       min="{{ lengthValidators.positiveValue }}"
                                                       (change)="executeCalculation()"/>
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.kwh' | translate }}</span>
                                                <mat-error
                                                    *ngIf="form.get('currentReport.reportMeasures')['controls'][index].get('datasetMeasure.realisedTotalEconomy').hasError('required')">
                                                    {{ 'validator.field.not_blank' | translate }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{'word.engaged_total_economy' | translate}}</th>
                        <ng-container *ngFor="let column of folderProgram.reportSteps">
                            <ng-container *ngFor="let step of column.steps">
                                <td *ngIf="step['@permissions'].view && step.measures[index]">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ (step.measures[index].engagedTotalEconomy || '') | number | default: '-': ('word.kwh' | translate) }}
                                    </ng-container>

                                    <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                        <div class="dataset-measure fields-group" formGroupName="datasetMeasure">
                                            <mat-form-field>
                                                <mat-label>{{'word.engaged_total_economy' | translate}}</mat-label>
                                                <input matInput
                                                       formControlName="engagedTotalEconomy"
                                                       type="number"
                                                       autocomplete="off"
                                                       min="{{ lengthValidators.positiveValue }}"
                                                       (change)="executeCalculation()"/>
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.kwh' | translate }}</span>
                                                <mat-error
                                                    *ngIf="form.get('currentReport.reportMeasures')['controls'][index].get('datasetMeasure.engagedTotalEconomy').hasError('required')">
                                                    {{ 'validator.field.not_blank' | translate }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    <tr>
                        <th>{{'word.total_economy_balance' | translate}}</th>
                        <ng-container *ngFor="let column of folderProgram.reportSteps">
                            <ng-container *ngFor="let step of column.steps">
                                <td *ngIf="step['@permissions'].view && step.measures[index]">
                                    <ng-container *ngIf="!step['@permissions'].edit">
                                        {{ (step.measures[index].engagedTotalEconomy - step.measures[index].realisedTotalEconomy || '') | number | default: '-': ('word.kwh' | translate) }}
                                    </ng-container>

                                    <ng-container [formGroupName]="index" *ngIf="step['@permissions'].edit">
                                        <div class="dataset-measure fields-group" formGroupName="datasetMeasure">
                                            <mat-form-field class="mat-form-field-appearance-legacy mat-form-field-disabled">
                                                <mat-label>{{'word.engaged_total_economy' | translate}}</mat-label>
                                                <input matInput [readonly]="true"
                                                       formControlName="balanceTotalEconomy"
                                                       type="number"
                                                       autocomplete="off"
                                                       min="{{ lengthValidators.positiveValue }}"
                                                       (change)="executeCalculation()"/>
                                                <span class="mat-input-suffix"
                                                      matSuffix>{{ 'word.kwh' | translate }}</span>
                                                <mat-error
                                                    *ngIf="form.get('currentReport.reportMeasures')['controls'][index].get('datasetMeasure.engagedTotalEconomy').hasError('required')">
                                                    {{ 'validator.field.not_blank' | translate }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </ng-container>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                    </tbody>
                </table>
            </div>
        </ng-container>
    </app-card>


    <!-- Analysis costs -->
    <app-card cardNumber="6" cardTitle="word.analysis_costs">
        <div class="table-scroll">
            <table class="table table-responsive col-md-12" formGroupName="currentReport" appTableCellHighlighter>
                <thead class="col-md-12">
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <th class="min-w-120" *ngIf="step['@permissions'].view">
                                {{ 'workflow.' + step['@columnName'] | translate }}
                            </th>
                        </ng-container>
                    </ng-container>
                </tr>
                </thead>
                <tbody formGroupName="analysisCosts" *ngIf="computedResume.analysisCosts">
                <tr>
                    <th>{{'word.total_cost' | translate}}</th>

                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.analysisCosts.units * step.analysisCosts.unitPrice | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{computedResume.analysisCosts.totalCost | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{'word.requested_contribution' | translate}} / {{'word.unit' | translate}}</th>

                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.analysisCosts.requestedContribution | currency:'CHF':'symbol':'0.0-2' | default:'-' }}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    <mat-form-field>
                                        <mat-label>{{'word.realised_requested_contribution' | translate}}</mat-label>
                                        <input matInput
                                               type="number"
                                               formControlName="requestedContribution"
                                               (change)="executeCalculation()"/>
                                    </mat-form-field>
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{'word.total_requested_contribution' | translate}}</th>

                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.analysisCosts.units * step.analysisCosts.requestedContribution | currency:'CHF':'symbol':'0.0-2' | default:'-'  }}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{ computedResume.analysisCosts.prokilowattTotalContribution | currency:'CHF':'symbol':'0.0-2' | default:'-'}}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{'word.subsidy_rate' | translate}}</th>

                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ (step.analysisCosts.units * step.analysisCosts.requestedContribution) / (step.analysisCosts.units * step.analysisCosts.unitPrice) * 100 | round:2 }}
                                    %
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{ computedResume.analysisCosts.subsidyRate | round:2 }} %
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>
    </app-card>

    <app-card cardNumber="7" cardTitle="title.summary">
        <div class="table-scroll">
            <table class="table table-responsive col-md-12" formGroupName="currentReport" appTableCellHighlighter>
                <thead class="col-md-12">
                <tr>
                    <th class="w-25">{{ '' }}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <th class="min-w-120" *ngIf="step['@permissions'].view">
                                {{ 'workflow.' + step['@columnName'] | translate }}
                            </th>
                        </ng-container>
                    </ng-container>
                </tr>
                </thead>
                <tbody *ngIf="computedResume.summary">
                <tr>
                    <th>{{ 'word.contribution_admin_management' | translate }}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.computedValues.summary.adminManagementContribution | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{ computedResume.summary.adminManagementContribution | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.cost_admin_management' | translate }}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.computedValues.summary.adminManagementCost | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{ computedResume.summary.adminManagementCost | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.contribution_admin_accompaniment' | translate }}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.computedValues.summary.adminAccompanimentContribution | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{ computedResume.summary.adminAccompanimentContribution | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.cost_admin_accompaniment' | translate }}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.computedValues.summary.adminAccompanimentCost | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{ computedResume.summary.adminAccompanimentCost | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.contribution_admin_total' | translate }}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.computedValues.summary.adminTotalContribution | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{ computedResume.summary.adminTotalContribution | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.cost_admin_total' | translate }}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.computedValues.summary.adminTotalCost | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{ computedResume.summary.adminTotalCost | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.contribution_measures' | translate }}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.computedValues.summary.measuresContribution | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{ computedResume.summary.measuresContribution | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.cost_measures' | translate }}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.computedValues.summary.measuresCost | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{ computedResume.summary.measuresCost | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.requested_contribution' | translate }}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.computedValues.requestedContribution | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{ computedResume.requestedContribution | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.total_cost' | translate }}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.computedValues.summary.totalCost | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{ computedResume.summary.totalCost | currency:'CHF':'symbol':'0.0-2' }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.total_saving' | translate }}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.computedValues.totalSaving | positive | number }} {{ 'word.kwh' | translate }}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{ computedResume.totalSaving | positive | number }} {{ 'word.kwh' | translate }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                <tr>
                    <th>{{ 'word.cost_utility_ratio' | translate }}</th>
                    <ng-container *ngFor="let column of folderProgram.reportSteps">
                        <ng-container *ngFor="let step of column.steps">
                            <td class="min-w-120" *ngIf="step['@permissions'].view">
                                <ng-container *ngIf="!step['@permissions'].edit">
                                    {{ step.computedValues.costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}
                                </ng-container>

                                <ng-container *ngIf="step['@permissions'].edit">
                                    {{ computedResume.costUtilityRatio | positive | round:2 }} {{ 'word.cts_kwh' | translate }}
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </tr>
                </tbody>
            </table>
        </div>
    </app-card>

    <!-- Analysis -->
    <app-card cardNumber="8" appAdminComponent cardTitle="title.analysis">
        <div class="row">
            <div class="col-md-8" formGroupName="analysis">
                <mat-form-field>
                    <mat-label>{{ 'word.decision' | translate }}</mat-label>
                    <textarea matInput
                              formControlName="decision"
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="5"
                              #analysisDecision
                              [readonly]="!canEditAnalyse() || !isValidation2()"
                              maxlength="10000"></textarea>
                    <mat-hint align="end">{{ analysisDecision.value.length }} / 65000</mat-hint>
                    <mat-error *ngIf="form.get('analysis.decision').hasError('maxlength')">
                        {{ 'validator.field.too_long' | translate: {max: 65000} }}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-md-4">
                <table class="table w-100">
                    <thead>
                    <tr>
                        <th class="min-w-120">
                            {{ 'word.documents' | translate }}

                            <div class="add-media-object" *ngIf="canEdit()">
                                <button mat-mini-fab color="accent" (click)="addAnalysisMediaObject()">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <mat-list dense role="list">
                                <mat-list-item role="listitem"
                                               *ngFor="let mediaObject of form.get('analysisMediaObjects').value; let i = index; last as last">
                                    <span class="mat-small" mat-line>{{ mediaObject.originalName }}</span>
                                    <button mat-icon-button appConfirmDialog *appIsGranted="'ROLE_STAFF_PKW'"
                                            (confirmClick)="deleteAnalysisMediaObject(mediaObject, i)">
                                        <mat-icon class="mat-16">delete_outline</mat-icon>
                                    </button>
                                    <button mat-icon-button (click)="downloadMediaObject(mediaObject)">
                                        <mat-icon class="mat-16">get_app</mat-icon>
                                    </button>
                                </mat-list-item>
                            </mat-list>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row" formGroupName="analysis">
            <div class="col-md-12">
                <mat-form-field>
                    <mat-label>{{ 'word.comment' | translate }}</mat-label>
                    <textarea matInput
                              formControlName="comment"
                              cdkTextareaAutosize
                              cdkAutosizeMinRows="5"
                              #analysisComment
                              [readonly]="!isValidation2()"
                              maxlength="65000"></textarea>
                    <mat-hint align="end">{{ analysisComment.value.length }} / 65000</mat-hint>
                    <mat-error *ngIf="form.get('analysis.comment').hasError('maxlength')">
                        {{ 'validator.field.too_long' | translate: {max: 65000} }}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </app-card>

    <app-documents-list sectionNumber="9" [reportIri]="programReportResource.data.currentReport.report['@id']"
                        [mediaObjects]="programReportResource.data.currentReport.mediaObjects"></app-documents-list>

    <app-invoices-list [sectionNumber]="10" [hideControls]="!reportNotCompleted()" [report]="programReportResource"
                       [showStateControls]="reportNotCompleted()"></app-invoices-list>

    <!-- Remarks -->
    <app-admin-remark-card appAdminComponent cardNumber="11" [folder]="folderProgram"></app-admin-remark-card>

    <!-- Todos -->
    <app-todo [folder]="folderProgram"
              *appIsGranted="'ROLE_STAFF_PKW'"></app-todo>

    <app-sticky-footer>
        <div>
            <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="save()"
                    [loading]="loading.loading$ | async" *ngIf="isValidation2()">
                {{ 'action.save' | translate }} <i class="icon-floppy-disk"></i>
            </button>
            <button mat-flat-button color="primary" appMatButtonLoading (click)="exportPdf()"
                    [loading]="loading.loading$ | async">
                {{ 'action.pdf' | translate }} <i class="icon-pdf-file"></i>
            </button>
        </div>
        <div class="justify-content-end">
            <button mat-flat-button color="primary" class="mr-2" appMatButtonLoading (click)="check()"
                    [loading]="loading.loading$ | async" *ngIf="isValidation2()">
                {{ 'action.check' | translate }} <i class="icon-checked"></i>
            </button>
            <button mat-flat-button color="primary" class="align-self-end" (click)="openReportSettingsDialog()">
                {{ 'word.report_settings' | translate }} <i class="icon-filter"></i>
            </button>
        </div>
    </app-sticky-footer>
</div>
<input #fileInput
       type="file"
       class="file-input hidden"
       multiple="multiple"
       (change)="onNewFileSelected($event)"/>
