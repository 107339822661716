import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FolderProgramResource } from '../../../../../../core/models/folder-program-resource.model';
import { ProgramFolder } from '../../../../../../core/models/folder.model';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import { MenuService } from '../../../../../../core/services/menu.service';
import { MediaObject } from '../../../../../../core/models/media-object.model';
import { HttpParams, HttpResponse } from '@angular/common/http';
import { IriUtil } from '../../../../../../core/utils/iri.util';
import { DownloaderHelper } from '../../../../../../core/utils/download.util';
import { MediaObjectService } from '../../../../../../core/services/media-object.service';
import { ProgramMeasure } from '../../../../../../core/models/measure.model';
import { ChildProgram } from '../../../../../../core/models/child-folder.model';
import { ReportColumn } from '../../../../../../core/models/report-column.model';
import { Step } from '../../../../../../core/models/step.model';
import { COMPUTOR_NAMES } from '../../../../../../core/services/folder.service';

@Component({
    selector: 'app-program-show',
    templateUrl: './program-show.component.html',
    styleUrls: ['./program-show.component.scss']
})
export class ProgramShowComponent implements OnInit {
    public static computorName = COMPUTOR_NAMES.computor2023Big;

    public folder: ProgramFolder;
    public folderProgramResource: FolderProgramResource;

    constructor(
        private route: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private menuService: MenuService,
        private mediaObjectService: MediaObjectService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.folder = this.route.snapshot.data.folder as ProgramFolder;
        this.folderProgramResource = this.route.snapshot.data.folderProgramResource as FolderProgramResource;

        this.initBreadcrumbAndMenu();
    }

    get measures(): ProgramMeasure[] {
        return this.folder.currentProgram.measures;
    }

    downloadMediaObject(mediaObject: MediaObject): void {
        const params = new HttpParams().set('folder', this.folder.uuid);
        this.mediaObjectService.download(mediaObject['@id'], params).subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        });
    }

    public showOrEditFolderStep(folder: ChildProgram): void { // TODO: refactor and use an UTIL
        if (folder.isCurrent && folder['@permissions'].edit) {
            void this.router.navigate([
                'sessions',
                IriUtil.extractId(this.folderProgramResource.data.tenderSession['@id']),
                'programs',
                IriUtil.extractId(this.folder['@id']),
            ]);
        }

        if (!folder['@permissions'].edit) {
            void this.router.navigate([
                'sessions',
                IriUtil.extractId(this.folderProgramResource.data.tenderSession['@id']),
                'programs',
                IriUtil.extractId(this.folder['@id']),
                'states',
                IriUtil.extractId(folder['@id']),
            ]);
        }
    }

    showOrEditReport(column: ReportColumn, item: Step): void {
        console.log(column, item);
    }

    private initBreadcrumbAndMenu(): void {
        const folderName = this.folder.currentProgram.acronym;
        this.breadcrumbService.clientFolderView(folderName);

        const menu = [{link: '/dashboard', label: 'menu.dashboard', icon: 'icon-home', subItems: []}, {
            link: null, label: folderName, icon: 'icon-clipboard', scrollTo: '#top', subItems: [
                { link: null, label: 'title.milestones', icon: 'icon-1', scrollTo: '#section1'},
                { link: null, label: 'word.supporting_organisation', icon: 'icon-2', scrollTo: '#section2'},
                { link: null, label: 'title.project_holder', icon: 'icon-3', scrollTo: '#section3'},
                { link: null, label: 'title.general', icon: 'icon-4', scrollTo: '#section4'},
                { link: null, label: 'title.measures_evolution', icon: 'icon-5', scrollTo: '#section5'},
                { link: null, label: 'word.official_documents', icon: 'icon-6', scrollTo: '#section6'},
            ]
        }];

        this.menuService.store(menu);
    }

}
