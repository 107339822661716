import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { UtilsService } from '../../../core/services/utils.service';
import { TechnicalOrientation } from '../../../core/models/technical-orientation.model';
import { min } from 'rxjs/operators';
import { FolderService } from '../../../core/services/folder.service';
import { FixCost } from '../../../core/models/fixCost.model';
import { Cost } from '../../../core/models/cost.model';
import { FormUtil } from '../../../core/utils/form.util';
import { ProgramMeasure } from '../../../core/models/measure.model';


@Injectable({
    providedIn: 'root'
})
export class ProgramCalculatorService {

    readonly maximumContributionFactor = 0.3;
    readonly economyTotalFactor = 0.75;

    private formReference: FormGroup;
    private calculatorResults: ProgramCalculationResults = null;
    private errorMessage: string = null;
    private technicalOrientations: any[];

    constructor(
        private utilsService: UtilsService,
        private folderService: FolderService
    ) {
    }

    public setFormReference(ref: FormGroup): FormGroup {
        this.formReference = ref;
        return this.formReference;
    }

    public setTechnicalOrientationsList(technicalOrientations: TechnicalOrientation[]): void {
        this.technicalOrientations = technicalOrientations;
    }

    public getErrorMessage(): string | null {
        return this.errorMessage;
    }

    public getResults(): ProgramCalculationResults | null {
        return this.calculatorResults;
    }

    get formType(): 'report' | 'folder' {
        if (this.formReference.contains('currentReport')) {
            return 'report';
        }

        return 'folder';
    }

    public calculate(): Observable<boolean> {
        if (this.formReference) {

            // load ProjectCalculationResults with form reference values
            this.loadFormReferenceValues();

            // calculate values
            this.compute();

            this.errorMessage = null;
            return of(true);
        } else {
            this.errorMessage = null;
            return of(false);
        }
    }

    private loadFormReferenceValues(): void {

        this.calculatorResults = {
            costUtilityRatio: 0,
            annualSaving: 0,
            maximumContribution: 0,
            measures: [],
            fixCosts: [],
            paybackWithoutContribution: 0,
            paybackWithContribution: 0,
            subsidyRate: 0,
            costCtskwh: this.currentProgram.get('dataset.cost').value as number ?? 0,
            analysisCosts: null,
            totalSaving: 0,
            percentSaving: 0,
            totalCost: 0,
            requestedContribution: 0,
            summary: {
                totalCost: 0,
                totalContribution: 0,
                adminManagementContribution: 0,
                adminManagementCost: 0,
                adminAccompanimentCost: 0,
                adminAccompanimentContribution: 0,
                adminTotalContribution: 0,
                adminTotalCost: 0,
                measuresCost: 0,
                measuresContribution: 0,
            }
        };

        const measures = this.getMeasures().controls;

        measures.forEach((measure) => {



            // when we load page -> technicalOri is object, when we change it, it becomes an iri
            const technicalOri = measure.get('datasetMeasure.technicalOrientation').value as TechnicalOrientation;
            const technicalOriIri = technicalOri ? ('string' === typeof technicalOri ? technicalOri : technicalOri['@id']) : null;

            const techOrient = this.utilsService.findItem('@id', technicalOriIri, this.technicalOrientations) as Record<any, number>;

            let techOrientComputerValue = 0;
            if (techOrient) {
                techOrientComputerValue = techOrient.computerValue;
                /**
                 * Special case with durationOfUse :
                 * The field "durationOfUse" depends of a value of the technical orientation.
                 * We must set the value of "durationOfUse" of the form.
                 */
                measure.get('datasetMeasure.technicalOrientation').setValue(technicalOriIri);
                measure.get('datasetMeasure.durationOfUse').setValue(techOrientComputerValue);
            }

            const pcm: ProgramCalculationMeasure = {
                units: measure.get('datasetMeasure.units').value as number ?? null,
                technicalOrientation: measure.get('datasetMeasure.technicalOrientation').value as number ?? null,
                durationOfUse: techOrientComputerValue,
                cost: parseFloat(measure.get('datasetMeasure.cost').value) ?? null,
                totalCost: null,
                // realisedContribution: measure.get('datasetMeasure.realisedContributions').value as number ?? null,
                requestedContribution: measure.get('datasetMeasure.requestedContribution').value as number ?? null,
                totalRequestedContribution: null,
                existingAnnualConsumption: measure.get('datasetMeasure.existingAnnualConsumption').value as number ?? null,
                futureAnnualConsumption: measure.get('datasetMeasure.futureAnnualConsumption').value as number ?? null,
                economyAnnual: null,
                economyTotal: null,
                economyPercent: null,
                paybackWithoutContribution: null,
                measureAnnualEconomy: null,
                measureTotalEconomy: null,
                maximumContribution: null,
                supportRate: null
            };

            this.calculatorResults.measures.push(pcm);
        });

        const fixCosts = this.getFixCosts().controls;
        fixCosts.forEach(fixCost => {
            this.calculatorResults.fixCosts.push({...(fixCost.value as FixCost).cost, groupName: (fixCost.value as FixCost).groupName});
        });

        this.calculatorResults.analysisCosts = this.currentProgram.get('analysisCosts').value as Cost;
    }

    private compute(): void {
        let sumEconomyAnnual = 0;

        console.log(this.calculatorResults.measures)

        for (let i = 0; i < this.calculatorResults.measures.length; i++) {

            console.log(this.calculatorResults.measures[i])


            const measure = this.calculatorResults.measures[i];
            // maximum contribution
            measure.maximumContribution = measure.cost ?
                measure.cost * this.maximumContributionFactor :
                null
            ;

            measure.totalCost = measure.units > 0 ? measure.units * measure.cost : 0;


            measure.totalRequestedContribution = measure.units * measure.requestedContribution;


            measure.economyAnnual = measure.existingAnnualConsumption ?
                measure.futureAnnualConsumption - measure.existingAnnualConsumption :
                null
            ;

            measure.economyTotal = measure.economyAnnual ?
                measure.economyAnnual * measure.durationOfUse * this.economyTotalFactor :
                null
            ;

            measure.economyPercent = measure.existingAnnualConsumption ?
                (measure.economyAnnual / measure.existingAnnualConsumption) * 100 :
                0
            ;

            measure.paybackWithoutContribution = (measure.economyAnnual && this.calculatorResults.costCtskwh) ?
                measure.cost / ((measure.economyAnnual * this.calculatorResults.costCtskwh) / 100) :
                null
            ;

            measure.paybackWithoutContribution = measure.paybackWithoutContribution !== null ?
                Number(measure.paybackWithoutContribution.toFixed(2)) :
                null
            ;

            measure.measureAnnualEconomy = Math.abs(measure.units * measure.economyAnnual);

            measure.measureTotalEconomy = Math.abs(measure.units * measure.economyTotal);


            const measures = this.getMeasures().controls;

            measures[i].get('datasetMeasure.measureAnnualSavings').setValue(measure.measureAnnualEconomy);
            measures[i].get('datasetMeasure.totalCost').setValue(measure.totalCost);
            measures[i].get('datasetMeasure.totalRequestedContribution').setValue(measure.totalRequestedContribution);
            measures[i].get('datasetMeasure.measureTotalSavings').setValue(measure.measureTotalEconomy);
            measures[i].get('datasetMeasure.maximumContribution')
                .setValue(measure.maximumContribution ? +measure.maximumContribution.toFixed(2) : null);

            if ('report' === this.formType) {
                console.log(measures[i]);
                const realised = Number(measures[i].get('datasetMeasure.realisedTotalEconomy').value);
                const engaged = Number(measures[i].get('datasetMeasure.measureTotalSavings').value);
                measures[i].get('datasetMeasure.balanceTotalEconomy').setValue(engaged - realised);
            }

            sumEconomyAnnual += measure.measureAnnualEconomy;
        }

        for (let i = 0; i < this.calculatorResults.fixCosts.length; ++i) {
            const fixCost = this.calculatorResults.fixCosts[i];
            fixCost.totalCost = fixCost.units * fixCost.unitPrice;
            fixCost.otherContribution = fixCost.totalCost - fixCost.requestedContribution;
            fixCost.prokilowattTotalContribution = fixCost.requestedContribution;

            const fixCosts = this.getFixCosts().controls;
            fixCosts[i].get('cost.totalCost').setValue(fixCost.totalCost);
            fixCosts[i].get('cost.otherContribution').setValue(fixCost.otherContribution);
        }

        this.calculateReportFixCostBalance();

        // analysis costs
        const analysisCosts = this.calculatorResults.analysisCosts;

        analysisCosts.totalCost = analysisCosts.units ? analysisCosts.units * analysisCosts.unitPrice : null;
        analysisCosts.prokilowattTotalContribution = analysisCosts.units ? analysisCosts.units * analysisCosts.requestedContribution : null;
        analysisCosts.subsidyRate = analysisCosts.totalCost > 0 ?
            (analysisCosts.prokilowattTotalContribution / analysisCosts.totalCost) * 100 : null
        ;

        this.calculateAnalysisCostsBalance();

        this.currentProgram.get('analysisCosts.totalCost').setValue(+analysisCosts.totalCost?.toFixed(2));
        this.currentProgram.get('analysisCosts.totalRequestedContribution')
            .setValue(+analysisCosts.prokilowattTotalContribution?.toFixed(2));
        this.currentProgram.get('analysisCosts.subsidyRate').setValue(+analysisCosts.subsidyRate?.toFixed(2));

        this.calculateResume();

        this.calculatorResults.maximumContribution = 3000000;

        this.calculatorResults.annualSaving = sumEconomyAnnual;

        this.calculatorResults.paybackWithoutContribution = (this.calculatorResults.annualSaving && this.calculatorResults.costCtskwh) ?
            this.calculatorResults.summary.totalCost / ((sumEconomyAnnual * this.calculatorResults.costCtskwh) / 100) :
            null
        ;
        this.calculatorResults.paybackWithContribution =
            (this.calculatorResults.costCtskwh && this.calculatorResults.summary.totalContribution) ?
                (this.calculatorResults.summary.totalCost - this.calculatorResults.summary.totalContribution)
                / ((sumEconomyAnnual * this.calculatorResults.costCtskwh) / 100) :
                null
        ;
        if (this.calculatorResults.paybackWithoutContribution) {
            this.calculatorResults.paybackWithoutContribution = Number(this.calculatorResults.paybackWithoutContribution.toFixed(2));
        }

        if (this.calculatorResults.paybackWithContribution) {
            this.calculatorResults.paybackWithContribution = Number(this.calculatorResults.paybackWithContribution.toFixed(2));
        }

        // custom report max contrib calculation
        if (this.formType === 'report') { // set max contribution for report
            if (Math.abs(this.calculatorResults.paybackWithoutContribution) < 4) {
                this.calculatorResults.maximumContribution = 0;
            } else {
                const validatedProgram = this.folderService.getProgramResource().data.programs
                    .find(program => 'validation_2' === program.marking);
                const investments = this.calculatorResults.summary.totalCost / validatedProgram.computedValues.summary.totalCost;
                const savings = Math.abs(this.calculatorResults.totalSaving) / validatedProgram.computedValues.totalSaving;
                of(1, investments, savings).pipe(min()).subscribe(res => {
                    this.calculatorResults.maximumContribution = Math.abs(res * validatedProgram.dataset.requestedContribution);
                });
            }
        }

        FormUtil.validateAllFormFields(this.currentProgram);
    }

    private calculateResume(): void {

        const resume = this.calculatorResults.summary;
        const generalFixCost = this.calculatorResults.fixCosts.find(fixCost => 'general' === fixCost.groupName);
        const folderFixCost = this.calculatorResults.fixCosts.find(fixCost => 'folder' === fixCost.groupName);



        resume.adminManagementContribution = generalFixCost.prokilowattTotalContribution + folderFixCost.prokilowattTotalContribution;
        resume.adminManagementCost = generalFixCost.totalCost + folderFixCost.totalCost;

        const otherFixCosts =
            this.calculatorResults.fixCosts.filter(fixCost => 'general' !== fixCost.groupName && 'folder' !== fixCost.groupName);

        otherFixCosts.forEach(fixCost => resume.adminAccompanimentContribution += fixCost.prokilowattTotalContribution);
        otherFixCosts.forEach(fixCost => resume.adminAccompanimentCost += fixCost.totalCost);

        if (this.formType === 'report') {
            const validationStep = this.folderService.getProgramResource().folderSteps.before.find((s: any) => s['@columnName'] === 'validated');
            if (validationStep) {
                const validatedGeneralFixCost = validationStep.fixCosts.find(fixCost => 'general' === fixCost.groupName).cost;
                const validatedFolderFixCost = validationStep.fixCosts.find(fixCost => 'folder' === fixCost.groupName).cost;
                const validatedOtherFixCosts = validationStep.fixCosts.filter(fixCost => 'folder' !== fixCost.groupName && 'general' !== fixCost.groupName);
                resume.adminAccompanimentBalance = 0;
                validatedOtherFixCosts.forEach(fixCost => resume.adminAccompanimentBalance += fixCost.cost.requestedContribution);
                resume.adminAccompanimentBalance -= resume.adminAccompanimentContribution;
                resume.adminManagementBalance = validatedGeneralFixCost.requestedContribution - generalFixCost.requestedContribution + validatedFolderFixCost.requestedContribution - folderFixCost.requestedContribution;
            }
        }

        resume.adminTotalContribution = resume.adminManagementContribution + resume.adminAccompanimentContribution;
        resume.adminTotalCost = resume.adminManagementCost + resume.adminAccompanimentCost;
        resume.totalRealisedSavings = 0;

        this.calculatorResults.measures.forEach(measure => {
            resume.measuresContribution += measure.totalRequestedContribution;
            resume.measuresCost += measure.totalCost ?? 0;
            console.log(measure)

            resume.totalRealisedSavings += 1
            this.calculatorResults.totalSaving += measure.measureTotalEconomy ?? 0;
        });

        resume.measuresContribution += this.calculatorResults.analysisCosts.prokilowattTotalContribution;
        resume.measuresCost += this.calculatorResults.analysisCosts.totalCost;

        resume.totalContribution = resume.adminTotalContribution + resume.measuresContribution;
        resume.totalCost = resume.adminTotalCost + resume.measuresCost;

        this.calculatorResults.costUtilityRatio = this.calculatorResults.totalSaving ?
            (resume.totalContribution / this.calculatorResults.totalSaving) * 100 : 0
        ;

        this.calculatorResults.subsidyRate = resume.totalCost ?
            (resume.totalContribution / resume.totalCost) * 100 : 0
        ;

        this.calculatorResults.requestedContribution = resume.totalContribution;

        // set to null so validator is not triggered by default
        this.currentProgram.get('dataset.requestedContribution').setValue(0 === resume.totalContribution ? null : resume.totalContribution);
    }

    private calculateReportFixCostBalance(): void {
        if ('report' !== this.formType) {
            return;
        }

        const validationStep = this.folderService.getProgramResource().folderSteps.before.find((s: any) => s['@columnName'] === 'validated')

        if (validationStep) {
            const currentProgramFixCosts = this.folderService.getProgramResource().data.currentProgram.fixCosts;
            for (let i = 0; i < this.calculatorResults.fixCosts.length; ++i) {
                const oldContrib = validationStep.fixCosts.find((c, idx) => i === idx).cost.requestedContribution;
                const currentCost = this.getFixCosts().controls[i];
                currentCost.get('cost.balance').setValue(oldContrib - Number(currentCost.get('cost.requestedContribution').value));
            }
        }

    }

    private calculateAnalysisCostsBalance(): void {
        if ('report' !== this.formType) {
            return;
        }

        const currentProgramAnalysisCost = this.folderService.getProgramResource().data.currentProgram.analysisCosts;
        const oldContribution = currentProgramAnalysisCost.requestedContribution * currentProgramAnalysisCost.units;
        const newContribution = Number(this.currentProgram.get('analysisCosts.totalRequestedContribution').value);
        this.currentProgram.get('analysisCosts.balance').setValue(oldContribution - newContribution);
    }

    private get currentProgram(): FormGroup {
        if (this.formType === 'report') {
            return this.formReference.get('currentReport') as FormGroup;
        }

        return this.formReference.get('currentProgram') as FormGroup;
    }

    private getMeasures(): FormArray {
        if (this.formType === 'report') {
            return this.currentProgram.get('reportMeasures') as FormArray;
        }

        return this.currentProgram.get('measures') as FormArray;
    }

    private getFixCosts(): FormArray {
        return this.currentProgram.get('fixCosts') as FormArray;
    }

}

export interface ProgramCalculationResults {
    measures: ProgramCalculationMeasure[];      // calculated
    fixCosts: ProgramCalculationFixCost[];      // calculated
    analysisCosts?: ProgramCalculationFixCost;      // calculated
    maximumContribution: number;                  // calculated
    percentSaving: number;                      // not used
    totalCost: number;                          // not used (in summary)
    annualSaving: number;                        // calculated
    totalSaving: number;                         // calculated
    paybackWithoutContribution: number;           // calculated
    paybackWithContribution: number;              // calculated
    subsidyRate: number;                          // calculated
    costUtilityRatio: number;                     // calculated
    requestedContribution: number;
    costCtskwh: number;
    summary: ProgramCalculationResume;
}

export interface ProgramCalculationResume {
    totalCost: number;
    totalContribution: number;
    adminManagementContribution: number;
    adminManagementBalance?: number;
    adminManagementCost: number;
    adminAccompanimentCost: number;
    adminAccompanimentContribution: number;
    adminAccompanimentBalance?: number;
    adminTotalContribution: number;
    adminTotalCost: number;
    measuresContribution: number;
    measuresCost: number;
    totalRealisedSavings?:number;
}

export interface ProgramCalculationMeasure {
    units: number;
    technicalOrientation: number;
    durationOfUse: number;                        // preselected into technical orientation list
    cost: number;
    totalCost: number;                          // calculated
    requestedContribution: number;
    realisedContribution?: number;
    totalRequestedContribution: number;               // calculated
    existingAnnualConsumption: number;
    futureAnnualConsumption: number;
    economyAnnual: number;                        // calculated
    economyTotal: number;                         // calculated
    economyPercent: number;                       // calculated
    paybackWithoutContribution: number;            // calculated
    measureAnnualEconomy: number; // units * economyAnnual
    measureTotalEconomy: number; // units * economyTotal
    maximumContribution: number; // calculated
    supportRate: number; // calculated
}

export interface ProgramCalculationFixCost {
    groupName?: string;
    units: number;
    unitPrice: number;
    requestedContribution: number;
    totalCost?: number; // calculated
    prokilowattTotalContribution?: number;
    otherContribution?: number; // calculated
    subsidyRate?: number; // calculated
}
