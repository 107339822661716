<div class="row">
    <div class="col-md-12">
        <!-- General -->
        <app-card cardNumber="1" cardTitle="title.general">
            <div class="row">
                <div class="col-md-6">
                    <p class="field-title">{{ 'word.average_cost_of_electricity' | translate }}</p>
                    <!--                        Divide by 100 because we want cents -->
                    <p>{{ programReportResource.data.currentReport.dataset.cost | default:'-' }} {{ 'word.cts_kwh' | translate }}</p>
                </div>
                <div class="col-md-6">
                    <p class="field-title">{{ 'word.requested_contribution' | translate }}</p>
                    <p>{{ programReportResource.data.currentReport.dataset.requestedContribution | currency | default:'-' }}</p>
                </div>
            </div>
        </app-card>
        <!-- Measures -->
        <app-card cardNumber="2" cardTitle="title.measures">
            <div *ngFor="let measure of programReportResource.data.currentReport.reportMeasures; let i = index">
                <div class="dataset-measure fields-group">
                    <div class="header">
                        <h2>{{ 'word.measure_n' | translate:{count: i + 1} }}</h2>
                    </div>
                    <div class="content">
                        <div class="row">
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.technical_orientation' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.technicalOrientation ? measure.datasetMeasure.technicalOrientation.name : '-' }}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.duration_of_use' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.durationOfUse | default:'-' }} {{ 'word.years' | translate }}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.measure_cost' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.cost | currency:'CHF':'symbol':'0.0-2' | default:'-' }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.maximum_contribution' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.maximumContribution | currency:'CHF':'symbol':'0.0-2' | default:'-' }}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.existing_annual_consumption' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.existingAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.future_annual_consumption' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.futureAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.annual_saving' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.annualSavings | positive | number | default: '-': ('word.kwh' | translate) }}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.total_saving' | translate }}</p>
                                <p class="text-justify">{{ measure.datasetMeasure.totalSavings | positive | number | default: '-': ('word.kwh' | translate) }}</p>
                            </div>
                            <div class="col-md-4">
                                <p class="field-title">{{ 'word.payback_without_contribution' | translate }}</p>
                                <p class="text-justify">{{ 'word.n_years' | translate:{count: measure.datasetMeasure.paybackWithoutContribution | round} | default:'-' }}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p class="field-title">{{ 'word.report_measure_description' | translate }}</p>
                                <p class="text-justify"
                                   [innerHTML]="measure.datasetMeasure.description | nl2br | default:'-'"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </app-card>
        <ng-container *ngIf="programReportResource">
            <app-documents-list sectionNumber="3" [reportIri]="programReportResource.data.currentReport.report['@id']"
                                [mediaObjects]="programReportResource.data.currentReport.mediaObjects"></app-documents-list>
        </ng-container>
        <ng-container *ngIf="programReportResource">
            <app-invoices-list [sectionNumber]="4" [report]="programReportResource"
                               [hideControls]="!reportNotCompleted()"></app-invoices-list>
        </ng-container>
    </div>
</div>

<app-sticky-footer *ngIf="auth.getUser().isStaffPkw">
    <div class="w-50">
        <ng-container *ngIf="folderProgram">
            <button mat-flat-button color="primary" appMatButtonLoading (click)="exportPdf()"
                    [loading]="loading.loading$ | async">
                {{ 'action.pdf' | translate }} <i class="icon-pdf-file"></i>
            </button>
        </ng-container>
    </div>
    <div class="w-50 d-flex justify-content-end" *appIsGranted="'ROLE_STAFF_PKW'">
        <button mat-flat-button color="primary" class="align-self-end" (click)="openReportSettingsDialog()">
            {{ 'word.report_settings' | translate }} <i class="icon-filter"></i>
        </button>
    </div>
</app-sticky-footer>