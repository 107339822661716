import { Component, OnDestroy, OnInit } from '@angular/core';
import { TenderSession } from '../../../../../../core/models/tender-session.model';
import { ActivatedRoute } from '@angular/router';
import { COMPUTOR_NAMES, FolderService } from '../../../../../../core/services/folder.service';
import { Folder } from '../../../../../../core/models/folder.model';
import { Subject } from 'rxjs';
import { FolderProjectResource } from '../../../../../../core/models/folder-project-resource.model';
import { PageTitleService } from '../../../../../../core/services/page-title.service';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import { ProjectReportResource } from '../../../../../../core/models/project-report-resource';
import { DownloaderHelper } from '../../../../../../core/utils/download.util';
import { FolderProjectService } from '../../../../../../core/services/folder-project.service';
import { NotificationService } from '../../../../../../core/notifications/notification.service';
import { IriUtil } from '../../../../../../core/utils/iri.util';
import { HttpResponse } from '@angular/common/http';
import { LoadingService } from '../../../../../../core/services/loading.service';

@Component({
    selector: 'app-admin-project-show-step',
    templateUrl: './admin-project-show-step.component.html',
    styleUrls: ['./admin-project-show-step.component.scss']
})
export class AdminProjectShowStepComponent implements OnInit, OnDestroy {
    public static computorName = COMPUTOR_NAMES.computor2020;

    public folder: Folder;
    public folderProjectResource: FolderProjectResource & ProjectReportResource;
    public tenderSession: TenderSession;

    private destroySubject: Subject<boolean> = new Subject<boolean>();

    constructor(
        private folderService: FolderService,
        private route: ActivatedRoute,
        private title: PageTitleService,
        private breadcrumbService: BreadcrumbService,
        private folderProjectService: FolderProjectService,
        private notificationService: NotificationService,
        public loading: LoadingService
    ) { }

    ngOnInit(): void {
        // this.folder = this.route.snapshot.data.folder as Folder;
        this.folderProjectResource = this.route.snapshot.data.folderProjectResource as FolderProjectResource & ProjectReportResource;

        this.folderService.setProjectResource(this.folderProjectResource);

        this.initBreadcrumb();
    }

    public ngOnDestroy(): void {
        this.folderService.clear();
        this.destroySubject.next(true);
        this.destroySubject.complete();
    }

    public exportPdf(): void {
        this.folderProjectService.pdf(
            IriUtil.extractId(this.folderProjectResource['@id']),
            this.folderProjectResource.data.currentProject.marking,
            'pdf_project_exporter'
        ).subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        }, () => {
            this.notificationService.error('toast.an_error_occurred_while_generating_pdf');
        });
    }

    private initBreadcrumb(): void {
        this.breadcrumbService.folderStep(this.folderLink);
    }

    get folderLink(): string {
        return '/admin/projects/' + this.folderProjectResource.data.id.toString();
    }
}
