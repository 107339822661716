import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { LoginComponent } from './views/login/login/login.component';
import { TenderSessionResolver } from './core/resolvers/tender-session.resolver';

const routes: Routes = [
    {
        path: '',
        component: LoginLayoutComponent,
        children: [
            {
                path: '',
                component: LoginComponent,
                pathMatch: 'full'
            },
            {
                path: 'login',
                component: LoginComponent,
                pathMatch: 'full'
            }
        ]
    },
    {
        path: 'sessions/:sessionId',
        resolve: {
            tenderSession: TenderSessionResolver,
        },
        loadChildren: () => import('./views/client/project/project.module').then((m) => m.ProjectModule)
    },
    {
        path: 'admin',
        loadChildren: () => import('./views/admin/admin.module').then((m) => m.AdminModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,  {
        onSameUrlNavigation: 'reload',
        relativeLinkResolution: 'legacy'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
