<h1 mat-dialog-title>{{ data.title | translate }}</h1>
<div mat-dialog-content [formGroup]="form">
    <div class="row">
        <div class="col-md-12 todo-create-header">
            <p *ngIf="!folder">{{ 'message.no_assigned_todo_creation' | translate }}</p>
            <p *ngIf="folder">{{ 'message.assigned_todo_creation' | translate:{folder: currentFolderName, reference: folder.data.reference} }}</p>
        </div>
    </div>

    <div class="d-flex flex-column justify-content-sm-between">
        <div [formGroup]="form">
            <mat-checkbox formControlName="isUrgent">{{'word.is_urgent' | translate}}</mat-checkbox>
        </div>
        <div [formGroup]="form">
            <mat-checkbox (change)="checkIfShouldDisableCheckboxes()" formControlName="destinedToOfen">{{'word.for_ofen' | translate}}</mat-checkbox>
        </div>
        <div [formGroup]="form">
            <mat-checkbox (change)="checkIfShouldDisableCheckboxes()" formControlName="destinedToOwner">{{'word.for_owner' | translate}}</mat-checkbox>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <mat-form-field>
                <mat-label>{{ 'word.description' | translate }}</mat-label>
                <textarea matInput formControlName="description" required mat-autosize></textarea>
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <mat-form-field>
                <input matInput
                       [matDatepicker]="deadlineDatePicker"
                       placeholder="{{ 'word.deadline' | translate }}"
                       formControlName="deadline"
                       [min]="startDateMin"
                       type="text"
                       autocomplete="off">
                <mat-datepicker-toggle matSuffix [for]="deadlineDatePicker"></mat-datepicker-toggle>
                <mat-datepicker #deadlineDatePicker></mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col-md-6">
            <mat-form-field>
                <mat-select placeholder="{{'word.assigned_user' | translate}}" formControlName="assignedUser">
                    <mat-option>
                        <ngx-mat-select-search [formControl]="searchUser"
                                               placeholderLabel="{{ 'word.search' | translate }}"
                                               noEntriesFoundLabel="{{'message.no_result'|translate}}">
                        </ngx-mat-select-search>
                    </mat-option>
                    <mat-option [value]="null">{{'word.none' | translate}}</mat-option>
                    <mat-option *ngFor="let user of SHOWED_USERS" value="{{ user['@id'] }}">{{ user.lastname }} {{ user.firstname }}</mat-option>
                    <ng-container *ngIf="SHOWED_USERS && SHOWED_USERS.length === 0">
                        <p class="px-3 pt-2">{{ 'message.no_result' | translate }}</p>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button (click)="dialogRef.close(null)" class="cancel-button mr-2">{{ 'action.close' | translate }}</button>
    <button mat-flat-button (click)="addTodo()" color="primary" appMatButtonLoading [loading]="loading.loading$ | async">{{ 'action.save' | translate }}</button>
</div>
