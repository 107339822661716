import {
    AfterViewInit,
    Component,
    ElementRef,
    Inject,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { BreadcrumbService } from '../../../../../../core/services/breadcrumb.service';
import { FolderProjectService } from '../../../../../../core/services/folder-project.service';
import { FormUtil } from '../../../../../../core/utils/form.util';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from '../../../../../../core/notifications/notification.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
    ProjectCalculatorService
} from '../../../../services/project-calculator.service';
import { ProjectFieldsCheckerService } from '../../../../services/project-fields-checker.service';
import { MediaObjectService } from '../../../../../../core/services/media-object.service';
import { DownloaderHelper } from '../../../../../../core/utils/download.util';
import { IriUtil } from '../../../../../../core/utils/iri.util';
import { MenuService } from '../../../../../../core/services/menu.service';
import { PageScrollService } from 'ngx-page-scroll-core';
import { DOCUMENT } from '@angular/common';
import { JsonLdError } from '../../../../../../core/models/json-ld.error.model';
import { RoundPipe } from 'ngx-pipes';
import {
    COMPUTOR_NAMES, COMPUTOR_TYPES,
    FolderService,
} from '../../../../../../core/services/folder.service';
import { FolderStatusService } from '../../../../../../core/services/folder-status.service';
import { PageTitleService } from '../../../../../../core/services/page-title.service';
import { FolderProjectResource } from '../../../../../../core/models/folder-project-resource.model';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { SwitchUserService } from '../../../../../../core/services/switch-user.service';
import { ProjectForm } from '../../../../models/projectForm';
import { Abstract2023ProjectFormTemplateComponent } from '../../../../project-form.template';
import { HttpResponse } from '@angular/common/http';
import { AuthService } from '../../../../../../core/services/auth.service';
import { LoadingService } from '../../../../../../core/services/loading.service';
import { TechnicalOrientationService } from '../../../../../../core/services/technical-orientation.service';

@Component({
    selector: 'app-project',
    templateUrl: './project.component.html',
    styleUrls: ['./project.component.scss'],
    providers: [RoundPipe]
})
export class ProjectComponent extends Abstract2023ProjectFormTemplateComponent implements OnInit, AfterViewInit, OnDestroy {
    public static computorName = COMPUTOR_NAMES.computor2023;

    @ViewChild('fileInput') public fileInput: ElementRef;

    @ViewChild('stickyBoxRef', {read: ViewContainerRef})
    public scrollContainerRef: ViewContainerRef;

    public decisionDate = null;
    public elementWidth?: string = null;

    constructor(
        protected notificationService: NotificationService,
        protected technicalOrientationService: TechnicalOrientationService,
        protected fb: FormBuilder,
        protected folderStatusService: FolderStatusService,
        protected projectCalculator: ProjectCalculatorService,
        protected computor2023form: ProjectForm,
        protected translateService: TranslateService,
        protected switchUserService: SwitchUserService,
        protected route: ActivatedRoute,
        protected folderService: FolderService,
        private breadcrumbService: BreadcrumbService,
        private folderProjectService: FolderProjectService,
        private formUtils: FormUtil,
        private router: Router,
        private projectFieldsCheckerService: ProjectFieldsCheckerService,
        private mediaObjectService: MediaObjectService,
        private titleService: PageTitleService,
        private menuService: MenuService,
        private pageScrollService: PageScrollService,
        private dialog: MatDialog,
        private auth: AuthService,
        public loading: LoadingService,
        @Inject(DOCUMENT) private document: Document,
    ) {
        super(
            computor2023form,
            projectCalculator,
            notificationService,
            switchUserService,
            technicalOrientationService,
            folderStatusService,
            fb,
            route,
            translateService,
            folderService
        );
    }

    public ngOnInit(): void {
        super.ngOnInit();

        const folderName = this.folderProject ? this.folderProject.data.currentProject.acronym : 'word.new_project';
        this.breadcrumbService.clientFolderView(folderName);

        const menu = [{link: '/dashboard', label: 'menu.dashboard', icon: 'icon-home', subItems: []}, {
            link: null, label: folderName, icon: 'icon-clipboard', subItems: [
                {link: null, label: 'title.project_holder', icon: 'icon-1', scrollTo: '#section1'},
                {link: null, label: 'title.project_beneficiary', icon: 'icon-2', scrollTo: '#section2'},
                {link: null, label: 'title.general', icon: 'icon-3', scrollTo: '#section3'},
                {link: null, label: 'title.measures', icon: 'icon-4', scrollTo: '#section4'},
                {link: null, label: 'title.requested_contribution', icon: 'icon-5', scrollTo: '#section5'},
                {link: null, label: 'title.documents', icon: 'icon-6', scrollTo: '#section6'},
                {link: null, label: 'title.requirements_control', icon: 'icon-7', scrollTo: '#section7'},
            ]
        }];

        this.setMinAndMaxDatesFromDecisionDate();

        this.menuService.store(menu);
    }

    public ngAfterViewInit(): void {
        this.executeCalculation();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
        this.switchUserService.logoutIfSwitched();
    }

    public save(showResult = true, redirect = true, submitting = false): Observable<FolderProjectResource | null> {
        const savedSuccessfully = new Subject<FolderProjectResource | null>();
        this.form.patchValue({tenderSession: this.tenderSession['@id']});
        this.form.patchValue({tenderModel: this.tenderSession.tenderModel});

        const notValidFormButAdmin = !this.form.valid && this.auth.getUser().isStaffPkw;

        if (this.form.valid || notValidFormButAdmin) { // PKW allowed to send anyway.
            this.folderProjectService
                // eslint-disable-next-line
                .save({data: this.form.value}, COMPUTOR_TYPES.project, COMPUTOR_NAMES.computor2023, submitting)
                .subscribe((res: FolderProjectResource) => {
                    this.form.markAsPristine();

                    if (showResult) {
                        this.notificationService.success('toast.data_successfully_saved');
                    }

                    if (redirect && null === this.route.snapshot.paramMap.get('folderId')) {
                        void this.router.navigate(['sessions', IriUtil.extractId(this.tenderSession['@id']),
                            'projects', IriUtil.extractId(res['@id'])]);
                    }

                    savedSuccessfully.next(res);
                }, (error: JsonLdError) => {
                    if (error['@type'] === 'ConstraintViolationList') {
                        this.projectFieldsCheckerService.displayCheckResult(error.violations);
                    }

                    savedSuccessfully.next(null);
                });
        } else {
            FormUtil.validateAllFormFields(this.form);
            this.notificationService.warn('validator.fields.missing');
            savedSuccessfully.next(null);
        }

        return savedSuccessfully.asObservable();
    }

    public check(): void {
        this.form.patchValue({tenderSession: this.tenderSession['@id']});
        this.form.patchValue({tenderModel: this.tenderSession.tenderModel});

        this.folderProjectService.check({data: this.form.value}, COMPUTOR_TYPES.project, COMPUTOR_NAMES.computor2023)
            .subscribe(() => {
                this.isSubmittable = true;
                this.projectFieldsCheckerService.displayCheckResult([]);
            }, () => {
                this.isSubmittable = this.auth.getUser().isStaffPkw; // admin can still send even if project has errors
            });
    }

    public submit(): void {
        this.save(false, false, true).subscribe(folderProject => {
            if (!folderProject) {
                return;
            }

            const nextTransition = this.getNextTransition(folderProject);

            this.folderService.transition(IriUtil.extractId(folderProject['@id']), nextTransition, {}).subscribe(() => {

                this.notificationService.success('toast.project_successfully_sent');

                const updatedProjectSubject = new Subject();

                updatedProjectSubject.pipe(takeUntil(this.destroySubject)).subscribe(() => {
                    this.navigateToShowFolder();
                });

                if (!this.route.snapshot.paramMap.get('folderId')) {
                    this.folderProjectService
                        .find(IriUtil.extractId(folderProject['@id'])).subscribe((res: FolderProjectResource) => {
                            this.folderProject = res;
                            updatedProjectSubject.next();
                        });
                } else {
                    updatedProjectSubject.next();
                }
            }, () => {
                this.isSubmittable = false;
            });
        });
    }

    public exportPdf(): void {
        this.exportPdfCall().subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        }, () => {
            this.notificationService.error('toast.an_error_occurred_while_generating_pdf');
        });
    }

    public delete(): void {
        this.folderProjectService.delete(this.folderProject['@id']).subscribe(() => {
            this.notificationService.success('toast.project_successfully_deleted');
            void this.router.navigate(['dashboard']);
        });
    }

    public copyHolderValues(): void {
        const holderCtrls = (this.form.get('folderHolder') as FormGroup).controls;
        const beneficiaryCtrls = (this.form.get('folderBeneficiary') as FormGroup).controls;
        Object.keys(beneficiaryCtrls).forEach(controlName => {
            if (holderCtrls[controlName] && !beneficiaryCtrls[controlName].value) { // copy if exists & null (no override)
                beneficiaryCtrls[controlName].setValue(holderCtrls[controlName].value);
            }
        });
    }

    private exportPdfCall(): Observable<any> {
        return this.folderProjectService.pdf(this.folderProject.data.id.toString(), this.folderProject.data.currentProject.marking, 'pdf_project_exporter');
    }

    private navigateToShowFolder(): void {
        this.form.markAsPristine();
        this.form.markAsUntouched();

        if (this.switchUserService.logoutIfSwitched()) {
            return;
        }

        void this.router.navigate([
            'sessions',
            IriUtil.extractId(this.tenderSession['@id']),
            'folders',
            IriUtil.extractId(this.folderProject['@id']),
            'states',
            IriUtil.extractId(this.folderProject.data.currentProject['@id'])
        ]);
    }
}
