<ng-container *ngIf="tenderSession.isCurrent">
  <div class="session-calendar d-flex">
    <div class="session-calendar-item p-2">
      <p class="date-description">{{ 'tender_session.' + type + '.submission_end_date' | translate }}</p>
      <p><span class="material-icons">lens</span></p>
      <p>{{ tenderSession.submissionEndDate | date:'dd.MM.yyyy' }}</p>
    </div>
    <div class="session-calendar-item p-2">
      <p class="date-description">{{ 'tender_session.' + type + '.question_round_start_date' | translate }}</p>
      <p><span class="material-icons">lens</span></p>
      <p>{{ tenderSession.questionRoundStartDate | date:'dd.MM.yyyy' }}</p>
    </div>
    <div class="session-calendar-item p-2">
      <p class="date-description">{{ 'tender_session.' + type + '.question_round_end_date' | translate }}</p>
      <p><span class="material-icons">lens</span></p>
      <p>{{ tenderSession.questionRoundEndDate | date:'dd.MM.yyyy' }}</p>
    </div>
    <div class="session-calendar-item p-2">
      <p class="date-description">{{ 'tender_session.' + type + '.decision_date' | translate }}</p>
      <p><span class="material-icons">lens</span></p>
      <p>{{ tenderSession.decisionDate | date:'dd.MM.yyyy' }}</p>
    </div>
    <div class="session-calendar-item p-2">
      <p class="date-description">{{ 'tender_session.' + type + '.max_start_date' | translate }}</p>
      <p><span class="material-icons">lens</span></p>
      <p>{{ tenderSession.maxStartDate | date:'dd.MM.yyyy' }}</p>
    </div>
    <div class="session-calendar-item p-2">
      <p class="date-description">{{ 'tender_session.' + type + '.max_end_date' | translate }}</p>
      <p><span class="material-icons">lens</span></p>
      <p>{{ tenderSession.maxEndDate | date:'dd.MM.yyyy' }}</p>
    </div>
  </div>
</ng-container>
