<app-card cardTitle="title.summary">
    <div class="dataset">
        <div class="indicators-row d-flex justify-content-between align-items-center">
            <div class="indicator-box d-flex align-items-center">
                <div class="indicator">
                    <p>{{ 'Couts fixes réalisés ' | translate }}</p>
                    <p>{{ computedValues.summary.adminTotalContribution | positive | round:2 | currency:'CHF' }} </p>
                </div>
            </div>
            <div class="indicator-box d-flex align-items-center">
                <div class="indicator" *ngIf="validatedComputedValues">
                    <p>{{ 'word.balance' | translate }}</p>
                    <p>{{ validatedComputedValues.summary.adminTotalContribution - computedValues.summary.adminTotalContribution  | positive | round:2 | currency:'CHF' }}</p>
                </div>
                <div class="indicator" *ngIf="!validatedComputedValues">
                    <p>{{ 'word.balance' | translate }}</p>
                    <p>{{ (computedValues.summary.adminAccompanimentBalance + computedValues.summary.adminManagementBalance)| positive | round:2 | currency:'CHF' }} </p>
                </div>
            </div>
            <div class="indicator-box-sm d-flex align-items-center">
                <div class="indicator">

                    <div class="indicator" *ngIf="validatedComputedValues">
                        <p>{{ 'word.balance' | translate }}</p>
                        <p>{{ computedValues.summary.adminTotalContribution / (validatedComputedValues.summary.adminTotalContribution - computedValues.summary.adminTotalContribution)  | percent }}</p>
                    </div>
                    <div class="indicator" *ngIf="!validatedComputedValues">
                        <p>{{ 'word.balance' | translate }}</p>
                        <p>{{ computedValues.summary.adminTotalContribution / (computedValues.summary.adminAccompanimentBalance + computedValues.summary.adminManagementBalance)| percent }} </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="indicators-row d-flex justify-content-between align-items-center">
            <div class="indicator-box d-flex align-items-center">
                <div class="indicator">
                    <p>{{ 'Total couts mesures réalisées (+ analyses)' | translate }}</p>
                    <p>{{ computedValues.summary.totalRealisedMeasures + computedValues.analysisCosts.prokilowattTotalContribution | positive | round:2 | currency:'CHF' }} </p>
                </div>
            </div>
            <div class="indicator-box d-flex align-items-center">
                <div class="indicator">
                    <p>{{ 'word.balance' | translate }}</p>

                        {{ (computedValues.summary.measuresContribution + computedValues.analysisCosts.validatedProkilowattTotalContribution) - (computedValues.summary.totalRealisedMeasures + computedValues.analysisCosts.prokilowattTotalContribution)| positive | round:2 | currency:'CHF' }}
                </div>
            </div>
            <div class="indicator-box-sm d-flex align-items-center">
                <div class="indicator">

                    <p></p>
                    <p>{{ ((computedValues.summary.totalRealisedMeasures + computedValues.analysisCosts.prokilowattTotalContribution) / ((computedValues.summary.measuresContribution - computedValues.analysisCosts.prokilowattTotalContribution) - (computedValues.summary.totalRealisedMeasures + computedValues.summary.totalEngagedMeasures))) | percent }}</p>
                </div>
            </div>
        </div>

        <div class="indicators-row d-flex justify-content-between align-items-center">
            <div class="indicator-box d-flex align-items-center">
                <div class="indicator">
                    <p>{{ 'Total economies mesures réalisées' | translate }}</p>
                    <p>{{ computedValues.summary.realisedTotalEconomy | positive | round:2 }} {{ 'word.kwh' | translate }} </p>
                </div>
            </div>
            <div class="indicator-box d-flex align-items-center">
                <div class="indicator">
                    <p>{{ 'word.balance' | translate }}</p>
                    <p>{{ (computedValues.summary.measureTotalSavings - computedValues.summary.realisedTotalEconomy)| positive | round:2 }} {{ 'word.kwh' | translate }}</p>
                </div>
            </div>
            <div class="indicator-box-sm d-flex align-items-center">
                <div class="indicator">
                    <p></p>
                    <p>{{ (computedValues.summary.realisedTotalEconomy / computedValues.summary.measureTotalSavings) | percent }}</p>
                </div>
            </div>
        </div>

        <div class="indicators-row d-flex justify-content-between align-items-center">
            <div class="indicator-box d-flex align-items-center">
                <div class="indicator">
                    <p>{{ 'Total couts mesures engagées' | translate }}</p>

                    <p>{{ computedValues.summary.totalEngagedMeasures | positive | round:2 | currency:'CHF' }} </p>
                </div>
            </div>
            <div class="indicator-box d-flex align-items-center">
                <div class="indicator">
                    <p>{{ 'word.balance' | translate }}</p>
                    <p>{{ (computedValues.summary.measuresContribution + computedValues.analysisCosts.validatedProkilowattTotalContribution - computedValues.analysisCosts.prokilowattTotalContribution - computedValues.summary.totalEngagedMeasures) | positive | round:2 | currency:'CHF' }} </p>
                </div>
            </div>
            <div class="indicator-box-sm d-flex align-items-center">
                <div class="indicator">
                    <p></p>
                    <p>{{ (computedValues.summary.totalEngagedMeasures / ((computedValues.summary.measuresContribution - computedValues.analysisCosts.prokilowattTotalContribution) - (computedValues.summary.totalRealisedMeasures + computedValues.summary.totalEngagedMeasures))) | percent }}</p>
                </div>
            </div>
        </div>

        <div class="indicators-row d-flex justify-content-between align-items-center">
            <div class="indicator-box d-flex align-items-center">
                <div class="indicator">
                    <p>{{ 'Total economies mesures engagées' | translate }}</p>


                    <p>{{ computedValues.summary.engagedTotalEconomy | positive | round:2 }} {{ 'word.kwh' | translate }} </p>


                </div>
            </div>
            <div class="indicator-box d-flex align-items-center">
                <div class="indicator">
                    <p>{{ 'word.balance' | translate }}</p>
                    <p>{{ (computedValues.summary.measureTotalSavings - computedValues.summary.engagedTotalEconomy)| positive | round:2 }} {{ 'word.kwh' | translate }}</p>
                </div>
            </div>
            <div class="indicator-box-sm d-flex align-items-center">
                <div class="indicator">
                    <p></p>
                    <p>{{ (computedValues.summary.engagedTotalEconomy / computedValues.summary.measureTotalSavings) | percent }}</p>
                </div>
            </div>
        </div>
    </div>


</app-card>
