import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Invoice} from '../../core/models/invoice.model';
import {MediaObject} from '../../core/models/media-object.model';
import {HttpParams, HttpResponse} from '@angular/common/http';
import {DownloaderHelper} from '../../core/utils/download.util';
import {MediaObjectService} from '../../core/services/media-object.service';
import {ProjectReportResource} from '../../core/models/project-report-resource';
import {MatDialog} from '@angular/material/dialog';
import {AddInvoiceDialogComponent} from '../../shared/add-invoice-dialog/add-invoice-dialog.component';
import {ProjectReportService} from '../../core/services/project-report.service';
import {InvoiceService} from '../../core/services/invoice.service';
import {NotificationService} from '../../core/notifications/notification.service';
import {EditInvoiceComponent} from '../../shared/edit-invoice/edit-invoice.component';
import {ProgramReportService} from '../../core/services/program-report.service';
import {MatTable} from '@angular/material/table';
import {UserService} from '../../core/services/user.service';
import {AuthService} from '../../core/services/auth.service';

@Component({
    selector: 'app-invoices-list',
    templateUrl: './invoices-list.component.html',
    styleUrls: ['./invoices-list.component.scss']
})
export class InvoicesListComponent implements OnInit {
    @Input()
    public sectionNumber: number;

    @Input()
    public report: ProjectReportResource;

    @Input()
    public hideControls = false;

    @Input()
    public showStateControls = false;

    @ViewChild(MatTable) table: MatTable<any>;

    displayedColumns = ['name', 'amount', 'amountFix', 'amountVariable', 'receiveDate', 'approvalDate', 'ofenApprovalDate', 'paymentDate', 'state', 'actions'];

    type = 'program'

    constructor(
        private mediaObjectService: MediaObjectService,
        private authService: AuthService,
        private dialog: MatDialog,
        private reportResourceService: ProjectReportService,
        private reportProgramService: ProgramReportService,
        private invoiceService: InvoiceService,
        private notificationService: NotificationService,
    ) {
    }

    ngOnInit() {
        if (this.report['@type'] == 'ProjectReportResource') {

            this.type = 'project'

            if (!this.authService.isAdmin()) {
                this.displayedColumns = ['name', 'amount', 'receiveDate', 'approvalDate', 'ofenApprovalDate', 'paymentDate', 'state', 'actions'];
            }

        } else if (this.report['@type'] == 'ProgramReportResource') {

            this.type = 'program'

            if (!this.authService.isAdmin()) {
                this.displayedColumns = ['name', 'amountFix', 'amountVariable', 'receiveDate', 'approvalDate', 'ofenApprovalDate', 'paymentDate', 'state', 'actions'];
            }

        }
    }


    public addInvoice(): void {
        const dialog = this.dialog.open(AddInvoiceDialogComponent, {
            autoFocus: false,
            data: {
                report: this.report
            }
        });
        dialog.afterClosed().subscribe((data: {invoice: Invoice}) => {
            if (data && data.invoice) {
                this.report.data.invoices.push(data.invoice);
                this.table.renderRows();
            }
        });
    }

    deleteInvoice(invoice: Invoice): void {
        this.invoiceService.deleteInvoice(invoice['@id']).subscribe(() => {
            const toDelete = this.report.data.invoices.findIndex(searched => searched['@id'] === invoice['@id']);
            this.report.data.invoices.splice(toDelete, 1);
            this.table.renderRows();
        });
    }

    downloadMediaObject(mediaObject: MediaObject): void {
        let params = new HttpParams();
        params = params.set('report', String(this.report.data.id));

        this.mediaObjectService.download(mediaObject['@id'], params).subscribe((response: HttpResponse<Blob>) => {
            DownloaderHelper.forceDownload(response);
        }, () => {
            this.notificationService.error('toast.requested_file_no_longer_available');
        });
    }

    passToState(element: Invoice, state: string): void {
        this.invoiceService.changeInvoiceState(element['@id'], state).subscribe((invoice: Invoice) => {
            this.report.data.invoices.find(i => i['@id'] === invoice['@id']).state = invoice.state;
        });
    }

    showEditInvoice(element: Invoice): void {
        const dialog = this.dialog.open(EditInvoiceComponent, {
            data: {
                invoice: element,
                type: this.type
            }
        });
        dialog.afterClosed().subscribe((data: {invoice?: Invoice}) => {
            if (data && data.invoice) {
                const idx = this.report.data.invoices.findIndex(i => i['@id'] === data.invoice['@id']);
                this.report.data.invoices[idx] = data.invoice;
                this.table.renderRows();
            }
        });
    }
}
