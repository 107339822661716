import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PageTitleService } from '../../core/services/page-title.service';
import { AuthService } from '../../core/services/auth.service';
import { Router } from '@angular/router';
import { LoadingService } from '../../core/services/loading.service';
import { MenuService } from '../../core/services/menu.service';
import { FolderStatusService } from '../../core/services/folder-status.service';
import { I18nService } from '../../core/i18n/i18n.service';
import { MatDialog } from '@angular/material/dialog';
import { UserProfileDialogComponent } from '../../shared/user-profile-dialog/user-profile-dialog.component';
import { currentYear } from 'src/app/shared/currentYear';

@Component({
    selector: 'app-dashboard-layout',
    templateUrl: './dashboard-layout.component.html',
    styleUrls: ['./dashboard-layout.component.scss'],
    providers: [FolderStatusService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardLayoutComponent implements OnInit, AfterViewChecked {
    isAdminRoute = false;
    // detect if it is a small device
    public isHandset$: Observable<boolean> = this.breakpointObserver
        .observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    public isLargeAndUp$: Observable<boolean> = this.breakpointObserver
        .observe([Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    public languages = I18nService.languages;
    public title = '';

    public currentLocale;
    public currentYear = currentYear.year;

    constructor(
        public pageTitle: PageTitleService,
        public folderStatusService: FolderStatusService,
        public authService: AuthService,
        public loadingService: LoadingService,
        public menuService: MenuService,
        private breakpointObserver: BreakpointObserver,
        private router: Router,
        private cdr: ChangeDetectorRef,
        public i18n: I18nService,
        public userService: AuthService,
        private dialog: MatDialog
    ) {
        if (this.router.url.indexOf('admin') > -1) {
            this.isAdminRoute = true;
        }
        menuService.createMenuFromUrl();
    }

    ngOnInit(): void {
        this.currentLocale = this.i18n.getCurrentLocale();
    }

    public changeLanguage(lang: string): void {
        this.i18n.useLocale(lang);
        this.currentLocale = lang.toUpperCase();
    }

    public logout(): void {
        this.authService.logout();
        void this.router.navigateByUrl('/');
    }

    public ngAfterViewChecked(): void {
        this.cdr.detectChanges();
    }

    public editProfile(): void {
        this.dialog.open(UserProfileDialogComponent, {
            width: '700px'
        });
    }
}
