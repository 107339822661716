import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ReportService } from '../../core/services/report.service';
import { Report } from '../../core/models/report.model';
import { FolderService } from '../../core/services/folder.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MilestoneService } from '../../core/services/milestone.service';
import { FormUtil } from '../../core/utils/form.util';
import { Milestone } from '../../core/models/milestone.model';
import { NotificationService } from '../../core/notifications/notification.service';
import { LoadingService } from '../../core/services/loading.service';

@Component({
    selector: 'app-admin-add-milestone-dialog',
    templateUrl: './admin-add-milestone-dialog.component.html',
    styleUrls: ['./admin-add-milestone-dialog.component.scss']
})
export class AdminAddMilestoneDialogComponent {
    public TYPE_MILESTONE = 'milestone';
    public TYPE_REPORT = 'report';

    public selectedType = new FormControl(this.TYPE_MILESTONE);

    public newItem = true;

    public form = this.fb.group({
        '@id': [null],
        name: [null, [Validators.required]],
        dueDate: [null, [Validators.required]],
        folder: [null]
    });

    constructor(
        public loading: LoadingService,
        private fb: FormBuilder,
        private reportService: ReportService,
        private milestoneService: MilestoneService,
        private folderService: FolderService,
        private dialogRef: MatDialogRef<AdminAddMilestoneDialogComponent>,
        private notification: NotificationService,
        @Inject(MAT_DIALOG_DATA) private data: {report: Report; milestone: Milestone}
    ) {
        if (data && data.milestone) {
            this.form.patchValue(data.milestone);
            this.selectedType.disable();
            this.newItem = false;
        } else if (data && data.report) {
            this.reportService.find(data.report['@id']).subscribe(report => {
                this.form.patchValue(report);
                this.selectedType.setValue(this.TYPE_REPORT);
                this.selectedType.disable();
                this.newItem = false;
            });
        }
        this.form.get('folder').setValue('/folders/' + folderService.getCurrentResource().data.id.toString());
    }

    public save(): void {
        if (this.form.valid) {
            switch (this.selectedType.value) {
            case this.TYPE_REPORT:
                this.saveAsReport();
                break;
            case this.TYPE_MILESTONE:
                this.saveAsMilestone();
                break;
            }
        } else {
            FormUtil.validateAllFormFields(this.form);
        }
    }

    public saveAsReport(): void {
        const report = this.form.value as Report;
        const tenderModel = this.folderService.getCurrentResource().data.tenderModel;
        this.reportService.createNewReport(report, tenderModel.computorType, tenderModel.computerName).subscribe(() => {
            this.closeWithSave();
        });
    }

    public saveAsMilestone(): void {
        const milestone = this.form.value as Milestone;
        this.milestoneService.create(milestone).subscribe(() => {
            this.closeWithSave();
        });
    }

    public delete(): void {
        const iri = this.form.get('@id').value as string;
        switch (this.selectedType.value) {
        case this.TYPE_MILESTONE:
            this.milestoneService.delete(iri).subscribe(() => this.closeWithSave());
            break;
        }
    }

    private closeWithSave(): void {
        this.dialogRef.close({
            saved: true
        });
    }
}
