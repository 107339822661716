import { Cost } from './cost.model';

export interface FixCost {
    '@id': string;
    '@type': string;
    type: string;
    cost: Cost;
    groupName: string;
    required: boolean;
    computedValues: {
        totalCost: number;
        thirdPartyContribution: number;
        subsidyRate: number;
    };
}

export interface AnalysisCost {
    unitPrice: number;
    units: number;
    requestedContribution: number;
}

export const FIX_COST_TYPE_MANAGEMENT = 'management';
export const FIX_COST_TYPE_ACCOMPANIMENT = 'accompaniment';
