/* eslint-disable */

import {  ComponentFactoryResolver, Inject, Injectable, OnDestroy, ViewContainerRef } from '@angular/core';

import * as computorsComponents from './../computors/computors';
import AbstractComputor from './abstract-computor';
import { NotificationService } from '../core/notifications/notification.service';

const COMPUTOR_COMPONENTS = importToArray(computorsComponents);

// componentName, componentMap (name: component)
const COMPUTOR_COMPONENTS_MAP: any = [];

COMPUTOR_COMPONENTS.forEach((arrayOfComponents) => {
    const components = Object.values(arrayOfComponents);
    components.forEach((component: {computorName: string; name: string}) => {
        if (!COMPUTOR_COMPONENTS_MAP[component.computorName]) {
            COMPUTOR_COMPONENTS_MAP[component.computorName] = new Map();
        }

        COMPUTOR_COMPONENTS_MAP[component.computorName].set(component.name, component);
    });
});

export function getComputor2020Component(componentName: string): any {
    return COMPUTOR_COMPONENTS_MAP.computor_2020.get(componentName);
}

export function getComputor2022Component(componentName: string): any {
    return COMPUTOR_COMPONENTS_MAP.computor_2022.get(componentName);
}

// Old Computor components
initOldComputorComponents();
export function initOldComputorComponents(): void {
    COMPUTOR_COMPONENTS_MAP.old_computor.set('AdminProjectShowComponent', getComputor2020Component('AdminProjectShowComponent'));
    COMPUTOR_COMPONENTS_MAP.old_computor.set('AdminProjectShowStepComponent', getComputor2020Component('AdminProjectShowStepComponent'));
    COMPUTOR_COMPONENTS_MAP.old_computor.set('ReportShowComponent', getComputor2020Component('ReportShowComponent'));
}

initProgram2022LightsComponents();
export function initProgram2022LightsComponents(): void {
    COMPUTOR_COMPONENTS_MAP.computor_2022Lights.set('AdminProgramShowComponent', getComputor2022Component('AdminProgramShowComponent'));
    COMPUTOR_COMPONENTS_MAP.computor_2022Lights.set('AdminProgramShowStepComponent', getComputor2022Component('AdminProgramShowStepComponent'));
    COMPUTOR_COMPONENTS_MAP.computor_2022Lights.set('AdminProgramValidation2Component', getComputor2022Component('AdminProgramValidation2Component'));
    COMPUTOR_COMPONENTS_MAP.computor_2022Lights.set('ProgramShowComponent', getComputor2022Component('ProgramShowComponent'));
    COMPUTOR_COMPONENTS_MAP.computor_2022Lights.set('ProgramShowStepComponent', getComputor2022Component('ProgramShowStepComponent'));
    COMPUTOR_COMPONENTS_MAP.computor_2022Lights.set('ProgramReportShowStepComponent', getComputor2022Component('ProgramReportShowStepComponent'));
}

export function importToArray<Key extends string, PropType>(importObject: Record<Key, PropType>): PropType[] {
    const keys = Object.getOwnPropertyNames(importObject);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return keys.filter((key) => key.indexOf('__') !== 0).map((key) => importObject[key]);
}

@Injectable()
export class ComputorFactory implements OnDestroy {
    private factoryResolver;
    private computorComponent: AbstractComputor;

    constructor(
    @Inject(ComponentFactoryResolver) factoryResolver: ComponentFactoryResolver,
    // eslint-disable-next-line
    private notificationService: NotificationService,
    ) {
        this.factoryResolver = factoryResolver;
    }

    public ngOnDestroy(): void {

    }

    public loadComputorComponent(rootViewContainer: ViewContainerRef, computorName: string, componentName: string): AbstractComputor {
        // eslint-disable-next-line
        const computorComponent = COMPUTOR_COMPONENTS_MAP[computorName].get(componentName);
        if (!computorComponent) {
            this.notificationService.error('Component ' + componentName + ' for Computor ' + computorName  +  ' not found.');
            return;
        }

        // eslint-disable-next-line
        const factory = this.factoryResolver.resolveComponentFactory(computorComponent);

        rootViewContainer.clear();

        const componentRef = rootViewContainer.createComponent(factory);
        this.computorComponent = componentRef.instance as AbstractComputor;

        return this.computorComponent;
    }
}

/* eslint-enable */
