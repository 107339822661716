<div class="row">
    <div class="col-md-12">
        <!-- Project holder -->
        <div class="cs-margin">
            <app-card cardTitle="title.project_holder" cardNumber="2">
                <div class="row">
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.company_name' | translate }}</p>
                        <p>{{ folder.data.folderHolder.companyName | default:'-' }}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.last_name' | translate }}</p>
                        <p>{{ folder.data.folderHolder.lastName | default:'-' }}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.first_name' | translate }}</p>
                        <p>{{ folder.data.folderHolder.firstName | default:'-' }}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.function' | translate }}</p>
                        <p>{{ folder.data.folderHolder.function | default:'-' }}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.email' | translate }}</p>
                        <p>{{ folder.data.folderHolder.email | default:'-' }}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="field-title">{{ 'word.phone' | translate }}</p>
                        <p>{{ folder.data.folderHolder.phone | default:'-' }}</p>
                    </div>
                </div>
            </app-card>

            <!-- General -->
            <app-card cardTitle="title.general" cardNumber="3">
                <div class="row">
                    <div class="col-md-6">
                        <p class="field-title">{{ 'word.project_name' | translate }}</p>
                        <p>{{ folder.data.currentProgram.name }}</p>
                    </div>
                    <div class="col-md-6">
                        <p class="field-title">{{ 'word.acronym' | translate }}</p>
                        <p>{{ folder.data.currentProgram.acronym }}</p>
                    </div>
                    <div class="col-md-6">
                        <p class="field-title">{{ 'word.average_cost_of_electricity' | translate }}</p>
                        <!--                        Divide by 100 because we want cents -->
                        <p>{{ folder.data.currentProgram.dataset.cost | default:'-' }} {{ 'word.cts_kwh' | translate }}</p>
                    </div>
                    <div class="col-md-6">
                        <p class="field-title">{{ 'word.requested_contribution' | translate }}</p>
                        <p>{{ folder.data.currentProgram.dataset.requestedContribution | currency | default:'-' }}</p>
                    </div>
                    <div class="col-md-6">
                        <p class="field-title">{{ 'word.start_date' | translate }}</p>
                        <p>{{ folder.data.currentProgram.startDate | date:'dd.MM.yyyy' | default:'-' }}</p>
                    </div>
                    <div class="col-md-6">
                        <p class="field-title">{{ 'word.end_date' | translate }}</p>
                        <p>{{ folder.data.currentProgram.endDate | date:'dd.MM.yyyy' | default:'-' }}</p>
                    </div>
                    <div class="mt-2 col-md-12">
                        <p class="field-title">{{ 'word.description' | translate }}</p>
                        <p class="text-justify"
                           [innerHTML]="folder.data.currentProgram.description | nl2br | default:'-'"></p>
                    </div>
                </div>
            </app-card>


            <!-- Measures -->
            <app-card cardTitle="title.measures" cardNumber="5">
                <div *ngFor="let measure of measures; let i = index">
                    <div class="dataset-measure fields-group">
                        <div class="header">
                            <h2>{{ 'word.measure_n' | translate:{count: i + 1} }}</h2>
                        </div>
                        <div class="content">
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="field-title">{{ 'word.technical_orientation' | translate }}</p>
                                    <p class="text-justify">
                                    <p>{{ measure.datasetMeasure.technicalOrientation ? measure.datasetMeasure.technicalOrientation.name : '-' }}</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="field-title">{{ 'word.duration_of_use' | translate }}</p>
                                    <p class="text-justify">{{ measure.datasetMeasure.durationOfUse | default:'-' }} {{ 'word.years' | translate }}</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="field-title">{{ 'word.measure_cost' | translate }}</p>
                                    <p class="text-justify">{{ measure.datasetMeasure.cost | currency:'CHF':'symbol':'0.0-2' | default:'-' }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="field-title">{{ 'word.existing_annual_consumption' | translate }}</p>
                                    <p class="text-justify">{{ measure.datasetMeasure.existingAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="field-title">{{ 'word.future_annual_consumption' | translate }}</p>
                                    <p class="text-justify">{{ measure.datasetMeasure.futureAnnualConsumption | number | default: '-': ('word.kwh' | translate) }}</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="field-title">{{ 'word.annual_saving' | translate }}</p>
                                    <p class="text-justify">{{ measure.datasetMeasure.annualSavings | positive | number | default: '-': ('word.kwh' | translate) }}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <p class="field-title">{{ 'word.description' | translate }}</p>
                                    <p class="text-justify"
                                       [innerHTML]="measure.datasetMeasure.description | nl2br | default:'-'"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </app-card>

            <app-documents-list id="section7" [mediaObjects]="mediaObjects" [sectionNumber]="7"
                                [folderUuid]="folder.data.uuid"></app-documents-list>
        </div>
    </div>
</div>

<app-sticky-footer>
    <div>
        <ng-container *ngIf="folder">
            <button mat-flat-button color="primary" appMatButtonLoading (click)="exportPdf()"
                    [loading]="loading.loading$ | async">
                {{ 'action.pdf' | translate }} <i class="icon-pdf-file"></i>
            </button>
        </ng-container>
    </div>
</app-sticky-footer>
