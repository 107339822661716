import { Observable, Subject } from 'rxjs';
import { TechnicalOrientation } from '../../core/models/technical-orientation.model';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import AbstractComputor from '../abstract-computor';
import { SwitchUserService } from '../../core/services/switch-user.service';
import { ProgramForm } from './models/programForm';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
    COMPUTOR_NAMES,
    COMPUTOR_TYPES,
    FolderService,
} from '../../core/services/folder.service';
import { DirtyFormWarnableInterface } from '../../components/form-component/dirty-form.component';
import { map, take, takeUntil } from 'rxjs/operators';
import { MediaObject } from '../../core/models/media-object.model';
import { ActivatedRoute, Router } from '@angular/router';
import { FolderStatusService } from '../../core/services/folder-status.service';
import { TranslateService } from '@ngx-translate/core';
import { FormUtil } from '../../core/utils/form.util';
import { IriUtil } from '../../core/utils/iri.util';
import { NotificationService } from '../../core/notifications/notification.service';
import { FolderProgramService } from '../../core/services/folder-program.service';
import { ProjectFieldsCheckerService } from './services/project-fields-checker.service';
import { AuthService } from '../../core/services/auth.service';
import { ProgramCalculatorService } from './services/program-calculator.service';
import { environment } from '../../../environments/environment';
import { FIX_COST_TYPE_ACCOMPANIMENT, FIX_COST_TYPE_MANAGEMENT } from '../../core/models/fixCost.model';
import { FolderProgramResource } from '../../core/models/folder-program-resource.model';
import { TechnicalOrientationService } from '../../core/services/technical-orientation.service';
import { DownloadAndSendExplanationDialogComponent } from '../../shared/download-and-send-explanation-dialog/download-and-send-explanation-dialog.component';
import { HttpResponse } from '@angular/common/http';
import { DownloaderHelper } from '../../core/utils/download.util';
import { MatDialog } from '@angular/material/dialog';

@Component({
    template: ''
})
export abstract class Abstract2024BigProgramFormTemplateComponent
    extends AbstractComputor implements OnInit, AfterViewInit, DirtyFormWarnableInterface, OnDestroy {

    public form = this.programForm.getProgramForm();

    public computedResume = {...this.programForm.computedResume};

    public lengthValidators = this.programForm.lengthValidators;

    public defaultCurrency: string;

    public legalForms = ProgramForm.LEGAL_FORMS;
    public geographicTargets = ProgramForm.GEOGRAPHIC_TARGETS;
    public electricityCosts = ProgramForm.POSSIBLE_ELECTRICITY_COSTS;
    public sectors = ProgramForm.SECTORS;

    public baseTechnicalOrientations: TechnicalOrientation[] = [];
    public technicalOrientations: TechnicalOrientation[] = [];
    public searchTechnicalOri = new FormControl();

    public destroySubject: Subject<boolean> = new Subject<boolean>();

    protected constructor(
        protected switchUserService: SwitchUserService,
        protected technicalOrientationService: TechnicalOrientationService,
        protected programForm: ProgramForm,
        protected folderProgramService: FolderProgramService,
        protected route: ActivatedRoute,
        protected folderStatusService: FolderStatusService,
        protected fb: FormBuilder,
        protected translateService: TranslateService,
        protected router: Router,
        protected notificationService: NotificationService,
        protected projectFieldsCheckerService: ProjectFieldsCheckerService,
        protected authService: AuthService,
        protected programCalculator: ProgramCalculatorService,
        protected folderService: FolderService,
        protected dialog: MatDialog,
    ) {
        super(switchUserService);

        this.computorType = COMPUTOR_TYPES.program;
        this.computorName = COMPUTOR_NAMES.computor2024Big;

        this.defaultCurrency = environment.defaultCurrency;
    }

    public ngOnInit(): void {
        if (this.route.snapshot.data.folder) {
            this.setFolderProject(this.route.snapshot.data.folder);
        }

        this.programCalculator.setFormReference(this.form);

        this.computorName = this.folderProgram.data.currentProgram['@type'].split('_' + this.computorType)[0];

        this.technicalOrientationService.findAllByTenderModel(this.tenderSession.tenderModel).pipe(
            map((result) => result['hydra:member']),
            take(1)
        ).subscribe( (technicalOrientations: TechnicalOrientation[]) => {
            this.baseTechnicalOrientations = technicalOrientations;
            this.technicalOrientations = technicalOrientations;
            this.programCalculator.setTechnicalOrientationsList(technicalOrientations);

            if (this.folderProgram) {
                const measuresCount = this.folderProgram.data.currentProgram.measures.length;
                for (let i = 1; i < measuresCount; i++) {
                    this.addMeasure();
                }

                this.form.patchValue(this.folderProgram.data);

                this.folderProgram.data.analysisMediaObjects.forEach((mediaObject: MediaObject) => {
                    this.addAnalysisMediaObjectGroup(mediaObject);
                });

                this.folderProgram.data.currentProgram.mediaObjects.forEach((mediaObject: MediaObject) => {
                    this.addMediaObjectGroup(mediaObject);
                });

                // update header status
                this.folderStatusService.folderStatusChanged.emit(this.folderProgram.data.marking);

                this.ngAfterViewInit();
            }
        });

        this.subscribeToTechnicalOriSearch();
    }

    ngOnDestroy(): void {
        this.folderService.clear();
    }

    public ngAfterViewInit(): void {
        this.executeCalculation();
    }

    public save(showResult = true, redirect = true, submitting = false): Observable<FolderProgramResource | null> {
        const savedSuccessfully = new Subject<FolderProgramResource | null>();
        this.form.patchValue({tenderSession: this.tenderSession['@id']});
        this.form.patchValue({tenderModel: this.tenderSession.tenderModel});

        if (this.form.valid) {
            this.setDefaultValueForFixCosts();
            this.setDefaultValueForAnalysis();
            this.folderProgramService.save({data: this.form.value}, this.computorType, this.computorName, submitting)
                .subscribe((res) => {
                    this.form.markAsPristine();

                    if (showResult) {
                        this.notificationService.success('toast.data_successfully_saved');
                    }

                    if (redirect && null === this.route.snapshot.paramMap.get('folderId')) {
                        void this.router.navigate([
                            'sessions', IriUtil.extractId(this.tenderSession['@id']), 'programs', IriUtil.extractId(res['@id'])
                        ]);
                    }

                    savedSuccessfully.next(res);
                }, () => {
                    savedSuccessfully.next(null);
                });
        } else {
            FormUtil.validateAllFormFields(this.form);
            this.notificationService.warn('validator.fields.missing');
            savedSuccessfully.next(null);
        }

        return savedSuccessfully.asObservable();
    }

    public check(): void {
        this.form.patchValue({tenderSession: this.tenderSession['@id']});
        this.form.patchValue({tenderModel: this.tenderSession.tenderModel});

        if (this.form.valid) {
            this.setDefaultValueForFixCosts();
            this.setDefaultValueForAnalysis();
            this.folderProgramService.check({data: this.form.value}, this.computorType, this.computorName)
                .subscribe(() => {
                    this.isSubmittable = true;
                    this.projectFieldsCheckerService.displayCheckResult([]);
                }, () => {
                    this.isSubmittable = this.authService.getUser().isStaffPkw; // admin can still send even if project has errors
                });
        } else {
            FormUtil.validateAllFormFields(this.form);
        }
    }

    public submit(): void {
        this.save(false, false, true).subscribe(folderProgram => {
            if (!folderProgram) {
                return;
            }

            const nextTransition = this.getNextTransition(folderProgram);

            this.folderService.transition(IriUtil.extractId(folderProgram['@id']), nextTransition, {}).subscribe(() => {

                this.notificationService.success('toast.program_successfully_sent');

                const programUpdatedSubject = new Subject();

                programUpdatedSubject.pipe(takeUntil(this.destroySubject)).subscribe(() => {
                    // pdf download modal only for first submission
                    if ('validate' === nextTransition) {
                        this.openDownloadAndSaveDialog();
                    } else if ('validate_answers' === nextTransition) {
                        this.openDownloadAndSaveDialog(true);
                    } else {
                        this.navigateToShowFolder();
                    }

                    this.navigateToShowFolder();
                });

                if (null === this.route.snapshot.paramMap.get('folderId')) {
                    this.folderProgramService.find(IriUtil.extractId(folderProgram['@id'])).subscribe((res: FolderProgramResource) => {
                        this.folderProgram = res;
                        programUpdatedSubject.next();
                    });
                } else {
                    programUpdatedSubject.next();
                }
            }, () => {
                this.isSubmittable = false;
            });
        });
    }

    public executeCalculation(): void {
        this.programCalculator.calculate().pipe(takeUntil(this.destroySubject)).subscribe(() => {
            this.computedResume = this.programCalculator.getResults();
        }, () => {
            this.notificationService.warn(this.programCalculator.getErrorMessage());
        });
    }

    public removeMeasureGroup(i: number): void {
        const measures = this.form.get('currentProgram.measures') as FormArray;

        if (measures.length > 1) {
            measures.removeAt(i);
        } else {
            measures.reset();
        }

        this.executeCalculation();
    }

    public addMediaObjectGroup(media: MediaObject): void {
        const mediaObjects = this.form.get('currentProgram.mediaObjects') as FormArray;
        mediaObjects.push(this.createMediaObjectGroup(media));
    }

    public addMeasure(): void {
        const measures = this.form.get('currentProgram.measures') as FormArray;
        measures.push(this.programForm.createMeasureGroup());
    }

    public getUuid(): string {
        return this.form.get('uuid').value as string;
    }

    public get currentProgramForm(): FormGroup {
        return this.form.get('currentProgram') as FormGroup;
    }

    public get fixCostsForm(): FormArray {
        return this.currentProgramForm.get('fixCosts') as FormArray;
    }

    public get fixCostsManagement(): FormGroup[] {
        const fixCosts = this.fixCostsForm.controls;
        return fixCosts.filter((f, idx) => this.fixCostsForm.at(idx).get('type').value === FIX_COST_TYPE_MANAGEMENT) as FormGroup[];
    }

    public get fixCostsAccompaniment(): FormGroup[] {
        const fixCosts = this.fixCostsForm.controls;
        return fixCosts.filter((f, idx) => this.fixCostsForm.at(idx).get('type').value === FIX_COST_TYPE_ACCOMPANIMENT) as FormGroup[];
    }

    public addAnalysisMediaObjectGroup(media: MediaObject, newMediaObject = false): void {
        const analysisMediaObjects = this.form.get('analysisMediaObjects') as FormArray;
        analysisMediaObjects.push(this.createAnalysisMediaObjectGroup(media));
        if (newMediaObject) {
            this.save();
        }
    }

    protected exportPdfCall(): Observable<any> {
        return this.folderProgramService.pdf(this.folderProgram.data.id.toString(), this.folderProgram.data.currentProgram.marking, 'pdf_program_exporter');
    }

    private createMediaObjectGroup(media: MediaObject): FormGroup {
        return this.fb.group({
            '@id': [media['@id'], []],
            originalName: [media.originalName, []],
        });
    }

    private createAnalysisMediaObjectGroup(media: MediaObject): FormGroup {
        return this.fb.group({
            '@id': [media['@id'], []],
            originalName: [media.originalName, []],
        });
    }

    private subscribeToTechnicalOriSearch(): void {
        this.searchTechnicalOri.valueChanges.pipe(takeUntil(this.destroySubject)).subscribe((value: string) => {
            if (value === '' || !value) {
                this.technicalOrientations = this.baseTechnicalOrientations;
                return;
            }

            this.technicalOrientations = this.technicalOrientations.filter(obj => {
                const text = obj.slug;
                return text.toLowerCase().indexOf(value.toLowerCase()) > -1;
            });
        });
    }

    private navigateToShowFolder(): void {
        this.form.markAsPristine();
        this.form.markAsUntouched();

        if (this.switchUserService.logoutIfSwitched()) {
            return;
        }

        void this.router.navigate([
            'sessions',
            IriUtil.extractId(this.tenderSession['@id']),
            'programs',
            IriUtil.extractId(this.folderProgram['@id']),
            'states',
            IriUtil.extractId(this.folderProgram.data.currentProgram['@id'])
        ]);
    }

    private openDownloadAndSaveDialog(validateAnswers?: boolean): void {
        let title = 'dialog.download_and_send_explanation.title';
        let content = 'dialog.download_and_send_explanation.content';
        let download = 'dialog.download_and_send_explanation.download';

        if (validateAnswers) {
            title = 'dialog.download_and_send_explanation_validate_answers.title';
            content = 'dialog.download_and_send_explanation_validate_answers.content';
            download = 'dialog.download_and_send_explanation_validate_answers.download';
        }

        const downloadAndSendDialog = this.dialog.open(DownloadAndSendExplanationDialogComponent, {
            maxWidth: '500px',
            data: {
                title,
                content,
                download
            }
        });

        downloadAndSendDialog.componentInstance.downloadButtonClicked.pipe(takeUntil(this.destroySubject)).subscribe(() => {
            this.exportPdfCall()
                .pipe(take(1))
                .subscribe((response: HttpResponse<Blob>) => {
                    DownloaderHelper.forceDownload(response);
                    downloadAndSendDialog.close();
                });
        });

        downloadAndSendDialog.afterClosed().subscribe(() => {
            this.navigateToShowFolder();
        });
    }

    private setDefaultValueForFixCosts(): void {
        this.fixCostsForm.controls.map(c => c.get('cost')).forEach((ctrl: FormGroup) => {
            ['units', 'unitPrice', 'requestedContribution'].map(field => this.patchGroupValue(ctrl.get(field) as FormControl));
        });
    }

    private setDefaultValueForAnalysis(): void {
        const analysisGroup = this.currentProgramForm.get('analysisCosts') as FormGroup;
        ['units', 'unitPrice', 'requestedContribution'].map(f => this.patchGroupValue(analysisGroup.get(f) as FormControl));
    }

    private patchGroupValue(field: FormControl): void {
        if (field.value) {
            return;
        }

        field.setValue(0);
    }
}
